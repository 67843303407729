const appActions = {
    SHOW_SIGNUP_SUCCESS: 'SHOW_SIGNUP_SUCCESS',
    SET_CURRENCY_LIST: 'SET_CURRENCY_LIST',
    SET_CURRENCY: 'SET_CURRENCY',
    SET_SHOW_TUTORIAL: 'SET_SHOW_TUTORIAL',
    SET_HIDE_TUTORIAL: 'SET_HIDE_TUTORIAL',
    SET_SHOW_SIGNUP_MODAL: 'SET_SHOW_SIGNUP_MODAL',
    SET_SHOW_MAIN_NAVIGATION_BAR: 'SET_SHOW_MAIN_NAVIGATION_BAR',
    SET_SHOW_ALERT: 'SET_SHOW_ALERT',

    showSuccessSignup: () => {
        return {
            type: appActions.SHOW_SIGNUP_SUCCESS,
        }
    },
    setCurrency: (payload) => {
        return {
            type: appActions.SET_CURRENCY,
            payload: payload,
        }
    },
    setCurrenyList: (payload) => {
        return {
            type: appActions.SET_CURRENCY_LIST,
            payload: payload,
        }
    },
    setShowTutorial: (payload) => {
        return {
            type: appActions.SET_SHOW_TUTORIAL,
            payload: payload,
        }
    },
    setHideTutorial: (payload) => {
        return {
            type: appActions.SET_HIDE_TUTORIAL,
            payload: payload,
        }
    },
    setShowSignupModal: (payload) => {
        return {
            type: appActions.SET_SHOW_SIGNUP_MODAL,
            payload: payload,
        }
    },
    setShowMainNav: (payload) => {
        return {
            type: appActions.SET_SHOW_MAIN_NAVIGATION_BAR,
            payload: payload,
        }
    },
    showAlert: (data) => {
        return {
            type: appActions.SET_SHOW_ALERT,
            payload: { ...data, message: data?.message, type: data?.type },
        }
    },
}

export default appActions
