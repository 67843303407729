import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function CreateJobButton(props) {
    const { activeJobs, setShowPremiumModal, talentSearch, margin, User, noJob } =
        props
    const [talentShowAllowed, setTalentSearchAllowed] = useState(talentSearch)

    useEffect(() => {
        setTalentSearchAllowed(User?.user?.talentSearch)
    }, [User])

    const navigate = useNavigate()
    return (
        <button
            data-tut="explore-step-1"
            className={`btn text-white ${
                margin && 'me-0 me-md-3 mb-2 mb-md-0'
            }`}
            style={{
                backgroundColor: '#009CDE',
                fontSize: 14,
                fontFamily: 'Open Sans',
            }}
            onClick={() => {
                console.log(User?.subscription?.status)
                if (User?.subscription?.status !== 'active' && User?.subscription?.status !== 'trialing') {
                    if (activeJobs?.totalCount < 2) {
                        navigate('/create-job')
                    } else {
                        if (setShowPremiumModal) {
                            setShowPremiumModal(true)
                        }
                    }
                } else {
                    navigate('/create-job')
                }
                if(noJob){
                    navigate('/create-job')
                }
            }}
        >
            + Create a Job
        </button>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
export default connect(mapStateToProps)(CreateJobButton)
