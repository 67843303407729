import { makeStyles } from "@material-ui/core";

export const general_styles = makeStyles((theme) => ({
  centered_x: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  centered_y: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  centered: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bg_primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  color_primary: { color: theme.palette.primary.main },
  color_secondary: { color: theme.palette.secondary.main },
  job_details_modal: {
    [theme.breakpoints.up("xs")]: {
      width: 800,
    },
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    padding: 40,
  },
  info_chip: {
    backgroundColor: theme.palette.secondary.light,
    margin: 1,
  },
}));

const display = makeStyles((theme) => ({
  display_grid: {
    display: "grid",
  },
  display_flex: {
    display: "flex",
  },
  display_block: {
    display: "block",
  },
  diplay_inline_block: {
    display: "inline-block",
  },
  diplay_inline: {
    display: "inline",
  },
  xs_none: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  xs_block: {
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sm_none: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  xs_flex_between: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "end",
    },
  },
}));

const backgroundColor = makeStyles((theme) => ({
  bg_white: {
    backgroundColor: "#FFF!important",
  },
  bg_light: {
    backgroundColor: "#E9EBEC",
  },
  bg_light_2: {
    backgroundColor: "#F6F9FB",
  },
  bg_primary: {
    backgroundColor: "#002E5D",
  },
  transparent: {
    backgroundColor: "transparent!important",
  },
}));

const flexBox = makeStyles((theme) => ({
  justify_content_center: {
    justifyContent: "center!important",
  },
  justify_content_left: {
    justifyContent: "left!important",
  },
  justify_self_left: {
    justifySelf: "left!important",
  },
  justify_content_right: {
    justifyContent: "right!important",
  },
  justify_content_around: {
    justifyContent: "space-around!important",
  },
  justify_content_between: {
    justifyContent: "space-between!important",
  },
  align_items_center: {
    alignItems: "center!important",
  },
  align_items_top: {
    alignItems: "top!important",
  },
  align_items_bottom: {
    alignItems: "bottom!important",
  },
  flex_direction_row: {
    flexDirection: "row!important",
  },
  flex_direction_column: {
    flexDirection: "column!important",
  },
  justify_content_left: {
    justifyContent: "left",
  },
  justify_content_right: {
    justifyContent: "right",
  },
  justify_content_around: {
    justifyContent: "space-around",
  },
  justify_content_between: {
    justifyContent: "space-between",
  },
  align_items_center: {
    alignItems: "center",
  },
  align_items_top: {
    alignItems: "top",
  },
  align_items_bottom: {
    alignItems: "bottom",
  },
  flex_direction_row: {
    flexDirection: "row",
  },
  flex_direction_column: {
    flexDirection: "column",
  },
}));

const border = makeStyles((theme) => ({
  border_none: {
    border: "none!important",
  },
  border_all: {
    border: "1px solid #8080804D",
  },
  border_top: {
    borderTop: "1px solid #8080804D",
  },
  border_bottom: {
    borderBottom: "1px solid #8080804D",
  },
  border_radius_5: {
    borderRadius: "5px",
  },
  border_radius_10: {
    borderRadius: "10px",
  },
  border_radius_15: {
    borderRadius: "15px!important ",
  },
  border_radius_16: {
    borderRadius: "16px!important ",
  },
}));

const shadow = makeStyles((theme) => ({
  shadow_sm: {
    boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
  },
}));

const padding = makeStyles((theme) => ({
  a0: {
    padding: "0!important",
  },
  a1: {
    padding: "0.5rem",
  },
  a2: {
    padding: "1rem",
  },
  a3: {
    padding: "1.5rem",
  },
  a4: {
    padding: "2rem",
  },
  a5: {
    padding: "2.5rem",
  },
  a10: {
    padding: "5rem",
  },
  a6: {
    paddingLeft: "3.5rem!important",
    paddingRight: "3.5rem!important",
  },
  x1: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  x2: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  x3: {
    paddingLeft: "1rem!important",
    paddingRight: "1rem!important",
  },
  x4: {
    paddingLeft: "1.5rem!important",
    paddingRight: "1.5rem!important",
  },
  x5: {
    paddingLeft: "2rem!important",
    paddingRight: "2rem!important",
  },
  x6: {
    paddingLeft: "3.5rem!important",
    paddingRight: "3.5rem!important",
  },
  y1: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  y2: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  y3: {
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  y4: {
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  y5: {
    paddingTop: "2.5rem",
    paddingBottom: "2.5rem",
  },
  b1: {
    paddingBottom: "0.5rem",
  },
  b2: {
    paddingBottom: "1rem",
  },
  b3: {
    paddingBottom: "1.5rem",
  },
  b4: {
    paddingBottom: "2rem",
  },
  b5: {
    paddingBottom: "2.5rem",
  },
}));

const margin = makeStyles((theme) => ({
  x1: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  y1: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  y2: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  y3: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
  },
  y4: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  y5: {
    marginTop: "2.5rem",
    marginBottom: "2.5rem",
  },
  t1: {
    marginTop: "0.5rem",
  },
  t2: {
    marginTop: "1rem",
  },
  t3: {
    marginTop: "1.5rem",
  },
  t4: {
    marginTop: "2rem",
  },
  t5: {
    marginTop: "2.5rem",
  },
  t6: {
    marginTop: "3.5rem",
  },
  b1: {
    marginBottom: "0.5rem",
  },
  b2: {
    marginBottom: "1rem",
  },
  b3: {
    marginBottom: "1.5rem",
  },
  b4: {
    marginBottom: "2rem",
  },
  b5: {
    marginBottom: "2.5rem",
  },
  b6: {
    marginBottom: "3.5rem",
  },
  r1: {
    marginRight: "0.5rem",
  },
  r2: {
    marginRight: "1rem",
  },
  r3: {
    marginRight: "1.5rem",
  },
  l1: {
    marginLeft: "0.5rem",
  },
  l2: {
    marginLeft: "1rem",
  },
  l3: {
    marginLeft: "1.5rem",
  },
}));

const width = makeStyles((theme) => ({
  w100: {
    width: "100%",
  },
}));

const height = makeStyles((theme) => ({
  vh100: {
    height: "100vh!important",
  },
  h100: {
    height: "100%",
  },
  vh92: {
    height: "92vh",
  },
}));

const button = makeStyles((theme) => ({
  primary: {
    backgroundColor: "#002E5D!important",
    color: "#FFFFFF!important",
    textTransform: "none!important",
    fontFamily: "Open Sans!important",
  },
  primary_outline: {
    borderColor: "#002E5D!important",
    color: "#002E5D!important",
    textTransform: "none!important",
    fontFamily: "Open Sans!important",
  },
}));

const cursor = makeStyles((theme) => ({
  pointer: {
    cursor: "pointer",
  },
}));

const text = makeStyles((theme) => ({
  white: {
    color: "#FFFFFF!important",
  },
  secondary: {
    color: "#009CDE!important",
  },
  light: {
    color: "#93989D!important",
  },
  warning: {
    color: '#D7A048!important'
  },
  danger: {
    color: "#EB5757!important",
  },
  center: {
    textAlign: "center!important",
  },
  left: {
    textAlign: "left!important",
  },
  right: {
    textAlign: "right!important",
  },
  capitalize: {
    textTransform: "capitalize!important",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  underline: {
    textDecoration: "underline",
  },
}));

const modal = makeStyles((theme) => ({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
  },
}));

const overflow = makeStyles((theme) => ({
  hidden: {
    overflow: "hidden",
  },
  scroll: {
    overflow: "scroll",
  },
}));

const position = makeStyles((theme) => ({
  absolute: {
    position: "absolute",
  },
  relative: {
    position: "relative",
  },
}));

//always add props value to get theme values
//e.g.   const app_classes = app_styles({...props}) if props are destructured  OR  const app_classes = app_styles(props)
export const app_styles = (props) => {
  return {
    display: { ...display(props) },
    backgroundColor: { ...backgroundColor(props) },
    flexBox: { ...flexBox(props) },
    padding: { ...padding(props) },
    margin: { ...margin(props) },
    width: { ...width(props) },
    height: { ...height(props) },
    shadow: { ...shadow(props) },
    border: { ...border(props) },
    cursor: { ...cursor(props) },
    button: { ...button(props) },
    modal: { ...modal(props) },
    text: { ...text(props) },
    overflow: { ...overflow(props) },
    position: { ...position(props) },
    general: { ...general_styles(props) },
  };
};
