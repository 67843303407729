import React, { useEffect, useState } from 'react'
import { userRequest } from '../../../service/requests'
import TextField from '../../../components/inputs/CustomTextField'

function ChangePassword(props) {
    const { token, navigate } = props

    const [newPassword, setNewPassword] = useState('')
    const [errorNewPassword, setError_NewPassword] = useState(null)

    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [errorConfirmNewPassword, setError_ConfirmNewPassword] =
        useState(null)

    const [successfulChange, setSuccessfulChange] = useState(false)

    useEffect(() => {
        if (newPassword) setError_NewPassword(null)
        if (newPassword) {
            if (newPassword.length < 8) {
                setError_NewPassword(
                    'New password should be atleast 8 characters'
                )
            } else {
                setError_NewPassword(null)
            }
        }
    }, [newPassword])

    useEffect(() => {
        if (confirmNewPassword) setError_ConfirmNewPassword(null)
        if (confirmNewPassword) {
            if (confirmNewPassword != newPassword) {
                setError_ConfirmNewPassword('Passwords do not match')
            } else {
                setError_ConfirmNewPassword(null)
            }
        }
    }, [confirmNewPassword])

    const validate = () => {
        let isValid = []

        if (newPassword) setError_NewPassword(null)
        if (newPassword) {
            if (newPassword.length < 8) {
                setError_NewPassword(
                    'New password should be atleast 8 characters'
                )
                isValid.push(false)
            } else {
                setError_NewPassword(null)
                isValid.push(true)
            }
        }

        if (confirmNewPassword) setError_ConfirmNewPassword(null)
        if (confirmNewPassword) {
            if (confirmNewPassword != newPassword) {
                setError_ConfirmNewPassword('Passwords do not match')
                isValid.push(false)
            } else {
                setError_ConfirmNewPassword(null)
                isValid.push(true)
            }
        }

        return isValid.includes(false) ? false : true
    }

    const resetPassword = () => {
        const valid = validate()

        if (!valid) {
            return
        }

        const isErrorNewPassword = newPassword === null || newPassword === ''
        setError_NewPassword(
            isErrorNewPassword ? 'New password is required' : null
        )

        if (isErrorNewPassword) {
            return
        }

        if (errorNewPassword !== null || errorConfirmNewPassword !== null) {
            return
        }

        userRequest
            .changeResetPassword({
                token: token,
                password: newPassword,
            })
            .then((response) => {
                console.log(response.data)
                setNewPassword('')
                setConfirmNewPassword('')
                if (response?.data?.role === 'APPLICANT') {
                    setSuccessfulChange(true)
                    navigate('/login')
                } else {
                    navigate('/login')
                }
            })
            .catch((err) => {
                console.log(err.response.data)
            })
    }

    return (
        <div className="card login-card">
            {!successfulChange ? (
                <div className="card-body text-center">
                    <h4 className="card-title text-center mb-4">
                        Change password
                    </h4>
                    <p className="card-text mb-2 text-center">
                        Your new password must be <br />
                        different from previously used passwords.
                    </p>
                    <div className="form-group mt-3">
                        <TextField
                            type="password"
                            required
                            name="newPassword"
                            placeholder="New password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="mb-2"
                            value={newPassword}
                            error={errorNewPassword}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <TextField
                            type="password"
                            required
                            name="confirmNewPassword"
                            placeholder="Confirm password"
                            onChange={(e) =>
                                setConfirmNewPassword(e.target.value)
                            }
                            className="mb-2"
                            value={confirmNewPassword}
                            error={errorConfirmNewPassword}
                        />
                    </div>
                    <button
                        className="btn btn-primary mt-3"
                        style={{ width: 200 }}
                        tabIndex="2"
                        id="forgot-password-submit"
                        onClick={() => resetPassword()}
                    >
                        Set new password
                    </button>
                    <p className="text-center mt-3">
                        <p
                            href="#"
                            className="text-primary hand"
                            onClick={() => {
                                navigate('/login')
                            }}
                        >
                            <u>Back to Sign in</u>
                        </p>
                    </p>
                </div>
            ) : (
                <div className="card-body text-center">
                    <h4 className="card-title text-center mb-4">
                        You have successfully changed your password!
                    </h4>
                    <p className="card-text mb-2 text-center">
                        Go back to the mobile app and login!
                    </p>
                </div>
            )}
        </div>
    )
}

export default ChangePassword
