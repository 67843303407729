import { generateRandomChars } from '../../utils/helpers'

export const dummy_data = () => {
    return {
        gender: 'Unknown',
        certsAndAwards: [],
        links: [],
        portfolioAndFiles: [],
        cvORCertificates: [],
        educationHistory: [
            {
                educName: generateRandomChars(
                    Math.floor(Math.random() * 10 + 1)
                ),
                educationType: "Bachelor's Degree",
                educField: generateRandomChars(
                    Math.floor(Math.random() * 10 + 1)
                ),
                educAchievements: generateRandomChars(
                    Math.floor(Math.random() * 10 + 1)
                ),
                educCurrentStudy: false,
            },
        ],
        experienceHistory: [
            {
                companyName: generateRandomChars(
                    Math.floor(Math.random() * 10 + 1)
                ),
                jobTitle: generateRandomChars(
                    Math.floor(Math.random() * 8 + 1)
                ),
                location: 'Makati',
                expWorkingHere: false,
            },
        ],
        softSkills: ['Flexible', 'Jack Of All Trades'],
        skills: ['AAA Video Games', 'ABI Solid Sequencing'],
        importantForYou: [
            'Mentoring',
            'Work From Anywhere',
            'Creative Collaboration',
        ],
        workSetup: ['On-Site'],
        employmentType: ['Full-Time', 'Internship'],
        _id: generateRandomChars(Math.floor(Math.random() * 8 + 1)),
        firstName: generateRandomChars(Math.floor(Math.random() * 10 + 1)),
        lastName: generateRandomChars(Math.floor(Math.random() * 10 + 1)),
        dob: generateRandomChars(Math.floor(Math.random() * 6 + 1)),
        phoneNumber: generateRandomChars(Math.floor(Math.random() * 11 + 1)),
        currentLocation: generateRandomChars(
            Math.floor(Math.random() * 35 + 1)
        ),
        influencerCode: null,
        anonymous: false,
        snooze: false,
        region: 'Metro Manila',
        city: generateRandomChars(Math.floor(Math.random() * 10 + 1)),
        country: generateRandomChars(Math.floor(Math.random() * 9 + 1)),
        __v: 0,
        bio: generateRandomChars(Math.floor(Math.random() * 50 + 1)),
        preferredTitle: generateRandomChars(Math.floor(Math.random() * 6 + 1)),
        employmentStatus: 'Actively Looking',
        salaryCurrency: 'USD',
        salaryMax: 2000,
        salaryMin: 1000,
        jobFunction: generateRandomChars(Math.floor(Math.random() * 20 + 1)),
        id: generateRandomChars(Math.floor(Math.random() * 50 + 1)),
    }
}
