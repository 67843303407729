import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moreVerticalIcon from '../../../assets/img/moreVerticalIcon.svg'
import noJobsIcon from '../../../assets/img/noJobsIcon.svg'
import searchIcon from '../../../assets/img/searchIcon.svg'
import CustomAlert from '../../../components/reusables/Alert/Alert'
import CreateJobButton from '../../../components/reusables/CreateJobButton'
import Stepper from '../../../components/reusables/Stepper'
import { jobsRequest, userRequest } from '../../../service/requests'
import { fromNow, titleCase } from '../../../utils/helpers'
import DataTable from './DataTable'
import Modal from 'react-bootstrap/Modal'
import jobluIconCircle from '../../../assets/img/my-jobs/joblu-icon-circle.png'
import workableIconCircle from '../../../assets/img/my-jobs/workable-icon-circle.png'
import { CSVLink } from 'react-csv'
import csvDownload from 'json-to-csv-export'
import moment from 'moment'
import { Box, Dialog } from '@material-ui/core'
import JobluTypography from '../../../components/Typography'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import premiumBg from '../../../assets/img/createJob/Go_Premium_BG.png'
import CloseWhite from '../../../assets/img/CloseWhite.png'
import { LargeBanner } from '../../../components/adbanner'
const DownloadCSVModal = (props) => {
    const {
        setJobsDataFilter,
        jobsDataFilter,
        handleCloseExportModal,
        showExportModal,
        jobsData,
        isGenerating,
        generateCSV,
        jobsDataLoading,
    } = props

    return (
        <>
            <Modal
                show={showExportModal}
                centered
                onHide={handleCloseExportModal}
            >
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            Download CSV
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center px-3">
                        <div className="row">
                            <div className="col-6 text-start">Select All</div>
                            <div className="col-6 text-end">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (jobsDataFilter.all === 1) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    all: 0,
                                                    published: 0,
                                                    closed: 0,
                                                    paused: 0,
                                                    archived: 0,
                                                    draft: 0,
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    all: 1,
                                                    published: 1,
                                                    closed: 1,
                                                    paused: 1,
                                                    archived: 1,
                                                    draft: 1,
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all === 1 ||
                                            (jobsDataFilter.published == 1 &&
                                                jobsDataFilter.closed == 1 &&
                                                jobsDataFilter.paused == 1 &&
                                                jobsDataFilter.archived == 1 &&
                                                jobsDataFilter.draft == 1)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6 text-start mb-3">Open</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        onChange={() => {
                                            if (
                                                jobsDataFilter.published === 1
                                            ) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    published: 0,
                                                    all: 0,
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    published: 1,
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == 1 ||
                                            jobsDataFilter.published === 1
                                        }
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                    />
                                </div>
                            </div>
                            <div className="col-6 text-start mb-3">Paused</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (jobsDataFilter.paused === 1) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    paused: 0,
                                                    all: 0,
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    paused: 1,
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == 1 ||
                                            jobsDataFilter.paused === 1
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-6 text-start mb-3">Closed</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (jobsDataFilter.closed === 1) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    closed: 0,
                                                    all: 0,
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    closed: 1,
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == 1 ||
                                            jobsDataFilter.closed === 1
                                        }
                                    />
                                </div>
                            </div>
                            {/* <div className="col-6 text-start mb-3">Draft</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                    />
                                </div>
                            </div> */}
                            <div className="col-6 text-start mb-3">Archive</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (jobsDataFilter.archived === 1) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    archived: 0,
                                                    all: 0,
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    archived: 1,
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == 1 ||
                                            jobsDataFilter.archived === 1
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-6 text-start mb-3">Drafts</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (jobsDataFilter.draft === 1) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    draft: 0,
                                                    all: 0,
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    draft: 1,
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == 1 ||
                                            jobsDataFilter.draft === 1
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="d-flex">
                                    <button
                                        className="btn btn-outline-primary me-2 w-100 text-center"
                                        onClick={handleCloseExportModal}
                                    >
                                        Cancel
                                    </button>
                                    {isGenerating ? (
                                        <>
                                            {' '}
                                            <button
                                                className="btn btn-outline-primary me-2 text-center w-100 me-2"
                                                disabled={isGenerating}
                                            >
                                                <p>Generating CSV</p>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={generateCSV}
                                                className={`btn btn-primary text-white w-100 text-center`}
                                                disabled={
                                                    jobsDataFilter.all == 0 &&
                                                    jobsDataFilter.published ==
                                                        0 &&
                                                    jobsDataFilter.closed ==
                                                        0 &&
                                                    jobsDataFilter.paused ==
                                                        0 &&
                                                    jobsDataFilter.archived ==
                                                        0 &&
                                                    jobsDataFilter.draft == 0
                                                }
                                            >
                                                Download
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
function MyJobslanding(props) {
    const { User } = props
    const [jobDrafts, setJobDrafts] = useState([])
    const [draftsCount, setDraftsCount] = useState(0)
    const [draftsPage, setDraftspage] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const [jobsPage, setJobsPage] = useState(1)
    const [totalJobsPage, setTotalJobsPage] = useState(1)
    const [totalDraftJobsPage, setTotalDraftJobsPage] = useState(1)
    const [tab, setSelectedTab] = useState('active')
    const [searchKeyword, setSearchKeyword] = useState('')

    const [activeJobsSettings, setActiveJobsSettings] = useState({
        totalCount: null,
        limit: 20,
    })

    const [activeJobs, setActiveJobs] = useState([])
    const [archivedJobs, setArchivedJobs] = useState([])
    const [closedJobs, setClosedJobs] = useState([])
    const [pausedJobs, setPausedJobs] = useState([])
    const [data, setData] = useState([])
    const [showArchiveDraftPropmt, setShowPromt] = useState(null)
    const [draftsLoading, setDraftsLoading] = useState(false)

    const [alertMessage, setAlertMessage] = useState(null)
    const navigate = useNavigate()
    const [sortBy, setSortBy] = useState('jobTitle')
    const [sortingType, setSortingType] = useState('asc')

    const getJobs = () => {
        setIsLoading(true)
        let payload = {
            offset: !!searchKeyword ? 1 : jobsPage,
            limit: 1,
        }
        if (searchKeyword) {
            payload = { ...payload, search: searchKeyword }
        }

        getPublishedJobs(payload)
        getArchivedJobs(payload)
        getClosedJobs(payload)
        getPausedJobs(payload)
        getDraftJobs(payload)
    }

    const getPublishedJobs = (payload) => {
        jobsRequest.getPublishedJobs(payload).then((res) => {
            let data = res?.data?.jobs
            console.log('hehe', res.data)
            const { totalCount, limit } = data
            setActiveJobsSettings({ totalCount, limit })
            let sorted = []
            if (sortBy === 'applicants') {
                sorted = _.orderBy(
                    data.data,
                    (o) => {
                        return parseInt(
                            o?.jobApplicants?.applicants?.length || 0
                        )
                    },
                    sortingType
                )
            } else {
                sorted = _.orderBy(data.data, sortBy, sortingType)
            }

            data = {
                ...data,
                data: sorted,
            }
            setActiveJobs(data)
            setIsLoading(false)
        })
    }
    // useEffect(() => {
    //     console.log(activeJobs)
    // })
    const getArchivedJobs = (payload) => {
        try {
            jobsRequest.getArchivedJobs(payload).then((res) => {
                //archived
                if (res.data?.jobs) {
                    let sorted = []
                    if (sortBy === 'applicants') {
                        sorted = _.orderBy(
                            res.data.jobs.data,
                            (o) => {
                                return parseInt(
                                    o?.jobApplicants?.applicants?.length || 0
                                )
                            },
                            sortingType
                        )
                    } else {
                        sorted = _.orderBy(
                            res.data.jobs.data,
                            sortBy,
                            sortingType
                        )
                    }
                    setArchivedJobs({
                        totalCount: res.data.jobs.totalCount,
                        data: sorted,
                    })
                } else {
                    setArchivedJobs({
                        totalCount: 0,
                        data: [],
                    })
                }
            })
        } catch (err) {
            console.log(err)
        }
    }

    const getDraftJobs = (payload) => {
        setDraftsLoading(true)
        try {
            jobsRequest.getDraftJobs(payload).then((res) => {
                setDraftsCount(res?.data?.jobs?.totalCount)
                let data = res?.data?.jobs
                const { totalCount, limit } = data
                setActiveJobsSettings({ totalCount, limit })
                let sorted = []
                if (sortBy === 'applicants') {
                    sorted = _.orderBy(
                        data.data,
                        (o) => {
                            return parseInt(
                                o?.jobApplicants?.applicants?.length || 0
                            )
                        },
                        sortingType
                    )
                } else {
                    sorted = _.orderBy(data.data, sortBy, sortingType)
                }

                data = {
                    ...data,
                    data: sorted,
                }

                setJobDrafts(data)
                setDraftsLoading(false)
            })
        } catch (err) {}
    }
    const [draftJob, setDraftJob] = useState(null)
    const [draftJobIndex, setDraftJobIndex] = useState(null)
    const handleShowPrompt = (job, index) => {
        setShowPromt(true)
        setDraftJob(job)
        setDraftJobIndex(index)
    }
    const getPausedJobs = (payload) => {
        try {
            jobsRequest.getPausedJobs(payload).then((res) => {
                if (res.data?.jobs) {
                    let sorted = []
                    if (sortBy === 'applicants') {
                        sorted = _.orderBy(
                            res.data.jobs.data,
                            (o) => {
                                return parseInt(
                                    o?.jobApplicants?.applicants?.length || 0
                                )
                            },
                            sortingType
                        )
                    } else {
                        sorted = _.orderBy(
                            res.data.jobs.data,
                            sortBy,
                            sortingType
                        )
                    }
                    setPausedJobs({
                        totalCount: res.data.jobs.totalCount,
                        data: sorted,
                    })
                } else {
                    setPausedJobs({
                        totalCount: 0,
                        data: [],
                    })
                }
            })
        } catch (err) {}
    }
    const getClosedJobs = (payload) => {
        try {
            jobsRequest.getClosedJobs(payload).then((res) => {
                if (res.data?.jobs) {
                    let sorted = []
                    if (sortBy === 'applicants') {
                        sorted = _.orderBy(
                            res.data.jobs.data,
                            (o) => {
                                return parseInt(
                                    o?.jobApplicants?.applicants?.length || 0
                                )
                            },
                            sortingType
                        )
                    } else {
                        sorted = _.orderBy(
                            res.data.jobs.data,
                            sortBy,
                            sortingType
                        )
                    }
                    setClosedJobs({
                        totalCount: res.data.jobs.totalCount,
                        data: sorted,
                    })
                } else {
                    setClosedJobs({
                        totalCount: 0,
                        data: [],
                    })
                }
            })
        } catch (err) {}
    }
    useEffect(() => {
        getJobs()
    }, [])

    useEffect(() => {
        if (!!searchKeyword || jobsPage) getJobs()
    }, [searchKeyword, jobsPage, sortBy, sortingType, tab])

    useEffect(() => {
        if (tab === 'active') {
            setData(activeJobs)
            const { totalCount, limit } = activeJobsSettings
            let totalPages = activeJobs.totalCount / limit
            totalPages = Math.ceil(totalPages)
            setTotalJobsPage(totalPages)
        }
        if (tab === 'paused') {
            setData(pausedJobs)
            const totalPagess =
                pausedJobs.totalCount / 20 < 1 ? 1 : pausedJobs.totalCount / 20
            setTotalJobsPage(Math.ceil(totalPagess))
        }
        if (tab === 'closed') {
            setData(closedJobs)
            const totalPagess =
                closedJobs.totalCount / 20 < 1 ? 1 : closedJobs.totalCount / 20
            setTotalJobsPage(Math.ceil(totalPagess))
        }
        if (tab === 'archived') {
            setData(archivedJobs)
            const totalPagess =
                archivedJobs.totalCount / 20 < 1
                    ? 1
                    : archivedJobs.totalCount / 20
            setTotalJobsPage(Math.ceil(totalPagess))
        }
        if (tab === 'drafts') {
            setData(jobDrafts)
            const totalPagess =
                jobDrafts.totalCount / 20 < 1 ? 1 : jobDrafts.totalCount / 20
            setTotalJobsPage(Math.ceil(totalPagess))
        }
    }, [tab, activeJobs, archivedJobs, jobDrafts])

    useEffect(() => {
        setJobsPage(1)
    }, [tab])

    const archive_draft = (data) => {
        if (!!data)
            try {
                setShowPromt(null)
                let payload = data
                delete payload.updatedAt
                payload.status = 'archived'
                jobsRequest
                    .updateJob(data?.id, { ...payload })
                    .then((res) => {
                        // setAlertMessage('Job archived!')
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally(() => {
                        // setTimeout(() => {
                        //     setAlertMessage(null)
                        // }, 2000)
                        getJobs()
                    })
            } catch (err) {
                console.log(err)
            }
    }
    const data1 = [
        { firstname: 'Ahmed', lastname: 'Tomi', email: 'ah@smthing.co.com' },
        { firstname: 'Raed', lastname: 'Labes', email: 'rl@smthing.co.com' },
        { firstname: 'Yezzi', lastname: 'Min l3b', email: 'ymin@cocococo.com' },
    ]

    const [showExportModal, setShowExportModal] = useState(false)
    const handleCloseExportModal = () => setShowExportModal(false)
    const handleOpenExportModal = () => {
        setJobsDataFilter({
            ...jobsDataFilter,
            all: 0,
            published: 0,
            closed: 0,
            paused: 0,
            archived: 0,
            draft: 0,
        })
        setShowExportModal(true)
    }
    const [jobsData, setJobsData] = useState([])
    const [jobsDataLoading, setJobsDataLoading] = useState(true)
    const [jobsDataFilter, setJobsDataFilter] = useState({
        all: 0,
        published: 0,
        paused: 0,
        closed: 0,
        archived: 0,
        draft: 0,
    })

    const exportJobs = () => {
        jobsRequest.exportJobs(jobsDataFilter).then((res) => {
            console.log('jobsData', res.data)
            setJobsData(res.data)
        })
    }
    // useEffect(() => {
    //     setJobsDataLoading(true)
    //     jobsRequest.exportJobs(jobsDataFilter).then((res) => {
    //         console.log('jobsData', res.data)
    //         setJobsDataLoading(false)
    //         setJobsData(res.data)
    //     })
    // }, [jobsDataFilter])

    const [isGenerating, setIsGenerating] = useState(false)
    const [talentSearch, setTalentSearch] = useState(true)
    const [showPremiumModal, setShowPremiumModal] = useState(false)
    const [downloadCSVPremium, setDownloadCSVPremium] = useState(false)

    const generateCSV = () => {
        setIsGenerating(true)
        jobsRequest
            .exportJobs(jobsDataFilter)
            .then((res) => {
                console.log('jobsData', res.data)
                setJobsDataLoading(false)
                setJobsData(res.data)
                const dataToConvert = {
                    data: res.data,
                    filename: `DF_Exported Jobs - (${moment().format(
                        'MM-DD-YYYY HHMM'
                    )})`,
                    delimiter: ',',
                    headers: [
                        'Job Title',
                        'Applicants',
                        'Shortlisted',
                        'Interviews',
                        'Archived',
                        'Created At',
                        'Updated At',
                        'Status',
                    ],
                }
                setTimeout(() => {
                    csvDownload(dataToConvert)
                    setIsGenerating(false)
                }, 3000)
            })
            .catch((err) => {
                setIsGenerating(false)
            })
    }

    useEffect(() => {
        if (User?.user) {
            userRequest
                .talentSearchStatus()
                .then((res) => {
                    console.log('taelent search', res.data)
                    setTalentSearch(true)
                })
                .catch(() => {
                    setTalentSearch(false)
                })
        }
    }, [User])

    return (
        <>
            {/* @JUDE DOWNLOAD CSV PREMIUM MODAL*/}
            <Dialog
                open={downloadCSVPremium}
                fullWidth
                maxWidth={'sm'}
                onClose={() => {
                    setDownloadCSVPremium(false)
                }}
                style={{ zIndex: '99999999' }}
            >
                <Box
                    style={{
                        background: `url('${premiumBg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            textAlign: 'right',
                            padding: '0.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={CloseWhite}
                            width={36}
                            onClick={() => {
                                setDownloadCSVPremium(false)
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            padding: '0 3rem 3rem 3rem',
                        }}
                    >
                        <JobluTypography.H1
                            semiBold
                            style={{ marginBottom: '1rem' }}
                        >
                            Want to export data to CSV? Do you want to export to
                            CSV?
                        </JobluTypography.H1>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 0 }}
                        >
                            Don't let missing candidate
                        </JobluTypography.H4>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 20 }}
                        >
                            data hold you back.
                        </JobluTypography.H4>
                        <JobluTypography.H6
                            semiBold
                            style={{ marginBottom: 20, color: '#D7A048' }}
                        >
                            Upgrade to Joblu Premium to export crucial data in
                            CSV form and get the insights you need to make smart
                            hiring decisions.
                        </JobluTypography.H6>

                        <Button
                            variant="contained"
                            style={{
                                background: '#D7A048',
                                color: '#fff',
                                padding: '1rem 3rem',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                navigate('/pricing')
                            }}
                        >
                            Get Premium
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={showPremiumModal}
                fullWidth
                maxWidth={'sm'}
                onClose={() => {
                    setShowPremiumModal(false)
                }}
                style={{ zIndex: '99999999' }}
            >
                <Box
                    style={{
                        background: `url('${premiumBg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            textAlign: 'right',
                            padding: '0.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={CloseWhite}
                            width={36}
                            onClick={() => {
                                setShowPremiumModal(false)
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            padding: '0 3rem 3rem 3rem',
                        }}
                    >
                        <JobluTypography.H1 semiBold>Uh-oh!</JobluTypography.H1>
                        <JobluTypography.H1
                            semiBold
                            style={{ marginBottom: '1rem' }}
                        >
                            Need to post more jobs?
                        </JobluTypography.H1>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 20 }}
                        >
                            With our Joblu Premium plan, you can enjoy the
                            luxury of unlimited job postings & many more!
                        </JobluTypography.H4>
                        <JobluTypography.H6
                            semiBold
                            style={{ marginBottom: 20, color: '#D7A048' }}
                        >
                            Upgrade now and unlock endless possibilities for
                            your hiring needs!
                        </JobluTypography.H6>
                        <Button
                            variant="contained"
                            style={{
                                background: '#D7A048',
                                color: '#fff',
                                padding: '1rem 3rem',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                navigate('/pricing')
                            }}
                        >
                            Discover Joblu Premium
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            
            <DownloadCSVModal
                setJobsDataFilter={setJobsDataFilter}
                jobsDataFilter={jobsDataFilter}
                handleCloseExportModal={handleCloseExportModal}
                showExportModal={showExportModal}
                jobsDataLoading={jobsDataLoading}
                jobsData={jobsData}
                isGenerating={isGenerating}
                generateCSV={generateCSV}
            />
            <div className="col-12 row justify-content-center">
                <div className="border-bottom">
                    <div className="container">
                        <div className="justify-content-between align-items-center row">
                            <div className="col-sm-12 col-md-6 mt-3 mb-0 mb-md-3 ">
                                <h5 className="text-body fw500 mb-2 ">
                                    My Jobs
                                </h5>
                            </div>
                            <div className="col-sm-12 col-md-6 text-right pb-3 pt-3 d-flex flex-md-row flex-column justify-content-md-end">
                                {/* <div className="me-0 me-md-2 mb-2 mb-md-0">
                                   
                                </div> */}
                                <div className="me-0 me-md-2 mb-2 mb-md-0">
                                    <div className="d-grid d-md-block w-100">
                                        <CreateJobButton
                                            activeJobs={activeJobs}
                                            talentSearch={talentSearch}
                                            setShowPremiumModal={
                                                setShowPremiumModal
                                            }
                                            margin={true}
                                        />
                                        <button
                                            className="btn btn-primary text-white"
                                            disabled={
                                                activeJobs?.totalCount < 1 &&
                                                pausedJobs?.totalCount < 1 &&
                                                closedJobs?.totalCount < 1 &&
                                                archivedJobs?.totalCount < 1 &&
                                                jobDrafts?.totalCount < 1
                                                    ? true
                                                    : false
                                            }
                                            data={jobsData}
                                            style={{
                                                fontSize: 14,
                                                fontFamily: 'Open Sans',
                                            }}
                                            onClick={() => {
                                                if (User?.subscription?.status !== 'active' && User?.subscription?.status !== 'trialing') {
                                                    setDownloadCSVPremium(true)
                                                } else {
                                                    handleOpenExportModal()
                                                }
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-cloud-download me-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                            </svg>
                                            Download CSV
                                        </button>{' '}
                                    </div>
                                </div>
                                {(User?.user?.subscription?.status =='active' || User?.user?.subscription?.status =='trialing') && (
                                    <>
                                        <div className="">
                                            <div className="d-grid d-md-block w-100">
                                                <button
                                                    className="btn"
                                                    style={{
                                                        backgroundColor:
                                                            '#D7A048',
                                                        color: '#fff',
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 14,
                                                    }}
                                                    onClick={() => {
                                                        navigate('/pricing')
                                                    }}
                                                >
                                                    Go Premium!
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 30}}/>
                <LargeBanner/>
                <div className="col-12 col-sm-12 col-md-11 row mt-5">
                    <div className="col-12 col-sm-12">
                        <div className="col-12 col-sm-4 d-flex mb-3">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    {isLoading ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-primary m-auto"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </div>
                                    ) : (
                                        <img
                                            className="hw-24"
                                            src={searchIcon}
                                            alt=""
                                        />
                                    )}
                                </span>
                                <input
                                    type="text"
                                    className="form-control px-2"
                                    placeholder="Search for Job Post"
                                    aria-label="location"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) =>
                                        setSearchKeyword(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="d-flex" style={{ overflow: 'auto' }}>
                            <div className="d-flex">
                                <button
                                    className={`btn text-nowrap me-2 ${
                                        tab === 'active'
                                            ? 'btn-primary'
                                            : 'btn-outline-primary'
                                    }`}
                                    onClick={() => setSelectedTab('active')}
                                >
                                    Open{' '}
                                    <span
                                        className={`badge bg-primary ${
                                            tab === 'active' && 'border-white'
                                        }`}
                                    >
                                        {activeJobs?.totalCount}
                                    </span>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button
                                    type="button"
                                    className={`btn text-nowrap me-2 ${
                                        tab === 'paused'
                                            ? 'btn-primary'
                                            : 'btn-outline-primary'
                                    }`}
                                    onClick={() => setSelectedTab('paused')}
                                >
                                    Paused{' '}
                                    <span
                                        className={`badge bg-primary ${
                                            tab === 'paused' && 'border-white'
                                        }`}
                                    >
                                        {pausedJobs?.totalCount}
                                    </span>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button
                                    className={`btn text-nowrap me-2 ${
                                        tab === 'closed'
                                            ? 'btn-primary'
                                            : 'btn-outline-primary'
                                    }`}
                                    onClick={() => setSelectedTab('closed')}
                                >
                                    Closed{' '}
                                    <span
                                        className={`badge bg-primary ${
                                            tab === 'closed' && 'border-white'
                                        }`}
                                    >
                                        {closedJobs?.totalCount}
                                    </span>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button
                                    type="button"
                                    className={`btn text-nowrap me-2 ${
                                        tab === 'archived'
                                            ? 'btn-primary'
                                            : 'btn-outline-primary'
                                    }`}
                                    onClick={() => setSelectedTab('archived')}
                                >
                                    Archived{' '}
                                    <span
                                        className={`badge bg-primary ${
                                            tab === 'archived' && 'border-white'
                                        }`}
                                    >
                                        {archivedJobs?.totalCount}
                                    </span>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button
                                    type="button"
                                    className={`btn text-nowrap me-2 ${
                                        tab === 'drafts'
                                            ? 'btn-primary'
                                            : 'btn-outline-primary'
                                    }`}
                                    onClick={() => setSelectedTab('drafts')}
                                >
                                    {draftsLoading && (
                                        <div
                                            class="spinner-border text-light me-2"
                                            role="status"
                                            style={{ width: 16, height: 16 }}
                                        >
                                            <span class="sr-only"></span>
                                        </div>
                                    )}
                                    Drafts{' '}
                                    <span
                                        className={`badge bg-primary ${
                                            tab === 'dratfs' && 'border-white'
                                        }`}
                                    >
                                        {draftsCount}
                                    </span>
                                </button>
                            </div>
                        </div>
                        {!!data?.data?.length ? (
                            <div className="mt-2 pb-2 mb-5 ">
                                <DataTable
                                    data={data?.data}
                                    jobsPage={jobsPage}
                                    setJobsPage={setJobsPage}
                                    totalPage={totalJobsPage}
                                    // totalDraftPage={totalDraftJobsPage}
                                    getJobs={getJobs}
                                    tab={tab}
                                    setSortBy={setSortBy}
                                    setSortingType={setSortingType}
                                    sortingType={sortingType}
                                    sortBy={sortBy}
                                    loading={isLoading}
                                    setShowPromt={setShowPromt}
                                    handleShowPrompt={handleShowPrompt}
                                    talentSearch={talentSearch}
                                    activeJobs={activeJobs}
                                />
                            </div>
                        ) : (
                            <div className="no-jobs-container mx-auto mt-5 d-flex flex-column">
                                <img
                                    className="no-jobs-img mx-auto mt-5"
                                    alt=" "
                                    src={noJobsIcon}
                                />
                                <h5 className="poppins text-center mt-3">
                                    Post a job candidates will love at first
                                    swipe!
                                </h5>
                                <p className="open-sans-body text-secondary text-center">
                                    Pro tip: Keep your job posts up-to-date and
                                    relevant to attract top candidates.
                                </p>
                            </div>
                        )}
                    </div>
                    {/* DRAFTS */}
                    <div className="col-12 col-sm-3 mt-3 mt-md-0 d-none">
                        <div className="bg-light rounded-1 p-3 border   ">
                            <h6>
                                Drafts{' '}
                                <span className="badge bg-primary text-light">
                                    {draftsCount}
                                </span>{' '}
                            </h6>
                            <hr />
                            {jobDrafts[draftsPage]?.length > 0 ? (
                                <>
                                    <ul
                                        className="list-group"
                                        style={{ background: 'inherit' }}
                                    >
                                        {jobDrafts[draftsPage]?.map(
                                            (draft, index) => (
                                                <li
                                                    className="list-group-item mb-2"
                                                    style={{
                                                        background: 'inherit',
                                                        border: 'none',
                                                    }}
                                                    key={draft?.jobId}
                                                >
                                                    <div className="d-flex justify-content-between ">
                                                        <div className="">
                                                            <p
                                                                className="m-0"
                                                                style={{
                                                                    wordBreak:
                                                                        'break-all',
                                                                }}
                                                            >
                                                                {titleCase(
                                                                    draft?.jobTitle
                                                                )}
                                                            </p>
                                                            <p className="time-ago m-0 text-muted">
                                                                <span className="fs-10">
                                                                    {fromNow(
                                                                        draft?.updatedAt
                                                                    )}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="">
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn"
                                                                    type="button"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                    id="actionBtn"
                                                                >
                                                                    <img
                                                                        src={
                                                                            moreVerticalIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                            width: 4.16,
                                                                            height: 20,
                                                                            marginTop: 10,
                                                                        }}
                                                                        className="m-auto"
                                                                    />
                                                                </button>
                                                                <ul
                                                                    className="dropdown-menu text-start"
                                                                    aria-labelledby="actionBtn"
                                                                    style={{
                                                                        right: 50,
                                                                    }}
                                                                >
                                                                    <li
                                                                        className="dropdown-item"
                                                                        role="button"
                                                                        onClick={() => {
                                                                            navigate(
                                                                                'edit',
                                                                                {
                                                                                    state: {
                                                                                        job: draft,
                                                                                    },
                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </li>
                                                                    <li
                                                                        className="dropdown-item"
                                                                        role="button"
                                                                        onClick={() => {
                                                                            setShowPromt(
                                                                                index
                                                                            )
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {showArchiveDraftPropmt ===
                                                        index && (
                                                        <div
                                                            className="p-2 mt-1 rounded"
                                                            style={{
                                                                background:
                                                                    '#eda6a6',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontSize: 13,
                                                                }}
                                                            >
                                                                You’re about to
                                                                delete this job
                                                                post. Continue?
                                                            </span>
                                                            <div className="d-flex justify-content-end">
                                                                <button
                                                                    className="btn btn-outline-light me-1"
                                                                    onClick={() =>
                                                                        setShowPromt(
                                                                            null
                                                                        )
                                                                    }
                                                                >
                                                                    No
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger"
                                                                    onClick={() => {
                                                                        archive_draft(
                                                                            draft
                                                                        )
                                                                    }}
                                                                >
                                                                    Yes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                    {draftsCount > 5 && (
                                        <Stepper
                                            steps={jobDrafts.length}
                                            onChange={(step) =>
                                                setDraftspage(step)
                                            }
                                            loading={isLoading}
                                        />
                                    )}
                                </>
                            ) : (
                                <div className="text-center">
                                    No drafts yet.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <CustomAlert
                    show={!!alertMessage}
                    body={alertMessage}
                    autoHide
                />
                {/* <Modal
                show={isLoading}
                centered
                contentClassName="bg-transparent border-0"
            >
                <Loading show color="primary" />
            </Modal> */}
                <Modal
                    centered
                    show={showArchiveDraftPropmt}
                    onHide={() => {
                        setShowPromt(false)
                    }}
                >
                    <Modal.Body>
                        <div className="p-4">
                            <h5 className="text-center">
                                Are you sure you want to delete this draft job?
                            </h5>
                            <div className="text-center mt-2">
                                <button
                                    className="btn btn-outline-primary me-2"
                                    onClick={() => {
                                        setShowPromt(false)
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary me-2"
                                    onClick={() => {
                                        archive_draft(draftJob)
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return { User: state.User }
}

export default connect(mapStateToProps, null)(MyJobslanding)
