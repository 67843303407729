import React, { useEffect, useRef } from 'react';
import './custom-style.css'

const LargeBanner = (props) => {
    const { style } = props
    const banner = useRef()
    const atOptions = {
        key: '8de6031a56b680a37a689794a5c8945a',
        format: 'iframe',
        height: 90,
        width: 728,
        params: {},
    }
    useEffect(() => {
        if (banner.current && !banner.current.firstChild) {
            const conf = document.createElement('script')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//www.topcreativeformat.com/${atOptions?.key}/invoke.js`
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`

            banner.current.append(conf)
            banner.current.append(script)
        }
    }, [banner])

    return (
        <div 
        ref={banner}
        className="justify-center items-center text-white text-center"
        >
        </div>
    )
}

export default LargeBanner;
