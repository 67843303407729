import MobileLogin from '../components/mobile-login/MobileLogin'
import CompanyReview from '../views/company-review/CompanyReview'
import DownloadProfileApplicant from '../views/download-profile/DownloadProfileApplicant'
import Promote from '../views/employee-review/Promote'
import Home from '../views/home/Home'
import JobFairRegister from '../views/job-fair/Register'
import Login from '../views/login/Login'
import PasswordReset from '../views/password-reset/PasswordReset'
import RegisterCode from '../views/register-code/RegisterCode'
import SignupApplicant from '../views/signup-applicant/SignupApplicant'
import Signup from '../views/signup/Signup'
import SignUpWithCode from '../views/signup/SignupWithCode'
import VerifyEmail from '../views/verify/VerifyEmail'
const publicRoutes = [
    {
        name: 'Home',
        path: '/',
        component: <Home />,
        excludeFromNav: true,
        index: true,
    },
    { name: 'Login', path: '/login', component: <Login /> },
    {
        name: 'Signup',
        path: '/signup',
        component: <Signup />,
    },
    {
        name: 'invite Code',
        path: '/signup/invite',
        component: <SignUpWithCode />,
        excludeFromNav: true,
    },
    {
        name: 'Password Reset',
        path: '/password-reset',
        component: <PasswordReset />,
        excludeFromNav: true,
    },
    {
        name: 'Download Profile Applicant',
        path: '/downloadprofilepdf',
        component: <DownloadProfileApplicant />,
        excludeFromNav: true,
    },
    {
        name: 'Download Profile Applicant',
        path: '/registercode/:code',
        component: <RegisterCode />,
        excludeFromNav: true,
    },
    {
        name: 'Verify Email',
        path: '/verify',
        component: <VerifyEmail />,
        excludeFromNav: true,
    },
    {
        name: 'Job Fair',
        path: '/job-fair',
        component: <JobFairRegister />,
        excludeFromNav: true,
    },
    {
        name: 'Company Review',
        path: '/showcase/employee-review/:id',
        component: <CompanyReview />,
        excludeFromNav: true,
    },
    {
        name: 'Promote',
        path: '/showcase/video-from-heart/:id',
        component: <Promote />,
        excludeFromNav: true,
    },
    {
        name: 'Applicant Signup',
        path: '/signup/applicant',
        component: <SignupApplicant />,
        excludeFromNav: true,
    },
    {
        name: 'Mobile Login',
        path: '/mobile/:accessToken/:refreshToken/',
        component: <MobileLogin />,
        excludeFromNav: true,
    },
]

export default publicRoutes
