import React, { useEffect, useState } from 'react'
import logoWTag from '../../assets/img/logoWTag.png'
import ChangePassword from './components/ChangePassword'
import ForgotPassword from './components/ForgotPassword'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Joblu from '../../assets/img/joblu-h.png'
import './styles.css'
const PasswordReset = () => {
    const navigate = useNavigate()
    const [page, setPage] = useState('reset')
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState(null)

    useEffect(() => {
        if(searchParams.get("page") === 'change'){
            setPage('change')
            const getToken = searchParams.get("token")
            if(getToken)
                setToken(getToken)
        }
    }, [])

    return (
        <div className="row vh-100 p-0 m-0 reset-bg">
            <div className="col-md-4  p-0 h-100 d-md-flex d-none align-items-start">
                <img src={Joblu} width={200} alt="" className="mt-5 ms-5" />
            </div>
            <div className="col-md-8 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex align d-block d-md-none">
                    <img src={Joblu} width={200} alt="" />
                </div>

                <div className="row w-100 mt-3">
                    <div className="col-md-6 col-sm-12 offset-md-3">
                        {page === 'reset' && <ForgotPassword navigate={navigate} />}
                        {page === 'change' && <ChangePassword token={token} navigate={navigate} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordReset
