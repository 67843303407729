import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import SelectSearch from 'react-select-search'
import CheckEmail from '../../assets/img/checkEmail.svg'
import logoWTag from '../../assets/img/logoWTag.png'
import TextField from '../../components/inputs/CustomTextField'
import PopUpModal from '../../components/reusables/PopUpModal'
import SelectCountry2 from '../../components/reusables/SelectCountry2'
import rootAction from '../../redux/action'
import appActions from '../../redux/app/actions'
import userAction from '../../redux/user/actions'
import { userRequest } from '../../service/requests'
import { validateEmail } from '../../utils/validators'
import { LeftCol } from './components/LeftCol'
import { RightCol } from './components/RightCol'

function Signup(props) {
    const {
        signUp,
        User,
        Generals,
        verifyGoogle,
        softReset,
        createEmployerSuccess,
        resetUserError,
        setShowSignupModal,
    } = props
    const { isSignedIn, isSigningIn, isCreatingEmployer } = User
    const navigate = useNavigate()
    const location = useLocation()
    const countryCodes = require('country-codes-list')
    const [signupWithGoogle, setSignupWithGoogle] = useState(false)
    const [displayOptions, setDisplayOptions] = useState(false)
    const showHide = (onBlur) => {
        if (onBlur) {
            setDisplayOptions(false)
        } else {
            setDisplayOptions(!displayOptions)
        }
    }

    const [employerData, setEmployerData] = useState(null)
    const myCountryCodesObject = countryCodes.customList(
        'countryCode',
        '{countryNameEn}:+{countryCallingCode}'
    )
    const [showModal, setShowModal] = useState(false)
    const [page, setPage] = useState('enterInviteStep1')
    const [currentStep, setCurrentStep] = useState(1)
    const [totalSteps, setTotalSteps] = useState(2)
    const [employerType, setEmployerType] = useState('employer')
    const [hasInviteCode, setHasInviteCode] = useState(false)
    const [inviteCode, setInviteCode] = useState('')
    const [isValidCode, setIsValidCode] = useState(null)
    const [agreetoTerms, setAgreeToTerms] = useState(false)
    const [subscribeToEmail, setSubscribeToEmail] = useState(true)
    const [errors, setErrors] = useState({
        email: null,
        password: null,
        username: null,
        firstName: null,
        lastName: null,
        companyName: null,
        address: null,
        country: null,
        companySize: null,
        agencyRegistrationNo: null,
        phoneNumber: null,
        companyRegistrationNo: null,
        inviteCode: null,
        confirmPassword: null,
        phoneNumber: null,
        name: null,
        company: null,
        currentLocation: null,
    })
    const [countryCodesList, setCountryCodesList] = useState(
        Object.values(myCountryCodesObject).map((item) => {
            var temp = item.split(':')
            return {
                label: `${temp[0]} (${temp[1]})`,
                value: item,
            }
        })
    )
    const [country, setCountry] = useState(null)

    const [requestCodeData, setRequestCodeData] = useState({
        name: null,
        email: null,
        jobTitle: null,
        company: null,
    })

    const [loginDetails, setLoginDetails] = useState({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
    })

    const [companyDetails, setCompanyDetails] = useState({
        companyName: '',
        address: '',
        country: '',
        currentLocation: '',
        companySize: '',
        agencyRegistrationNo: '',
        showCandidatesFromSpecificCountry: false,
        countriesInterested: [''],
        phoneNumber: '',
        employerType,
        companyRegistrationNo: '',
        region: null,
        city: null,
    })

    const [verifiedCaptcha, setVerifiedCaptcha] = useState(false)

    useEffect(() => {
        if (location.state?.googleResponse) {
            const data = location.state.googleResponse
            setSignupWithGoogle(data)
        }
        if (location?.state?.registercode) {
            setInviteCode(location.state.registercode)
        }
    }, [location])

    const enterInvite1 = () => {
        setPage('enterInviteStep1')
    }
    const enterInvite2 = () => {
        if (signupWithGoogle) {
            setPage('enterInviteStep2')
        } else {
            const validated = validateFields()
            if (validated) setPage('enterInviteStep2')
        }
    }

    const verifyCode = async () => {
        if (inviteCode) {
            try {
                const resp = await userRequest.validateInviteCode({
                    inviteCode,
                })
                console.log(resp)
                if (resp) {
                    if (resp?.data?.valid === true) {
                        setIsValidCode(true)
                    } else {
                        setIsValidCode(false)
                    }
                }
            } catch (ex) {
                console.log(ex)
            }
        }
    }

    useEffect(() => {
        if (page === 'enterInviteStep1') {
            setCurrentStep(1)
        }
        if (page === 'enterInviteStep2') {
            setCurrentStep(2)
        }
    }, [page])

    useEffect(() => {
        console.log(companyDetails)
    }, [companyDetails])
    const handleRequestCode = async () => {
        if (!hasInviteCode) {
            const { email, name, jobTitle, company } = requestCodeData
            const isValidated = validateFields()
            if (isValidated) {
                const isValid = validateEmail(email)
                if (isValid && name && jobTitle && company) {
                    let payload = {
                        email,
                        name,
                        company,
                        position: jobTitle,
                    }
                    try {
                        const resp = await userRequest.requestInviteCode(
                            payload
                        )
                        if (resp) {
                            setTimeout(() => {
                                setShowModal(true)
                            }, 1000)
                        }
                    } catch (ex) {
                        console.log(ex)
                    }
                }
            }
        } else {
            //
        }
    }
    useEffect(() => {
        let errorsCopy = errors
        if (loginDetails.firstName) {
            errorsCopy = { ...errorsCopy, firstName: '' }
        }
        if (loginDetails.lastName) {
            errorsCopy = { ...errorsCopy, lastName: '' }
        }
        if (loginDetails.email) {
            errorsCopy = { ...errorsCopy, email: '' }
        }
        if (loginDetails.password) {
            errorsCopy = { ...errorsCopy, password: '' }
        }
        if (loginDetails.confirmPassword) {
            errorsCopy = { ...errorsCopy, confirmPassword: '' }
        }
        if (companyDetails.companyName) {
            errorsCopy = { ...errorsCopy, companyName: '' }
        }
        if (companyDetails.country) {
            errorsCopy = { ...errorsCopy, country: '' }
        }
        if (companyDetails.phoneNumber) {
            errorsCopy = { ...errorsCopy, phoneNphoneNumber: '' }
        }
        setErrors(errorsCopy)
    }, [
        loginDetails.firstName,
        loginDetails.lastName,
        companyDetails.companyName,
        companyDetails.country,
        loginDetails.email,
        loginDetails.password,
        loginDetails.confirmPassword,
        loginDetails.phoneNumber,
    ])
    const validateFields = () => {
        let valid = []
        let errors2 = errors
        if (!!loginDetails.email === false) {
            console.log('email has no length')
            errors2 = { ...errors2, email: 'Email address is required' }
            valid.push(false)
        } else {
            console.log('email has length')
            if (!validateEmail(loginDetails.email)) {
                console.log('err')
                errors2 = { ...errors2, email: 'Email not valid' }
                valid.push(false)
            } else {
                console.log('err2')
                userRequest
                    .validateEmail({
                        email: loginDetails.email,
                    })
                    .then((res) => {
                        errors2 = { ...errors2, email: null }
                        valid.push(true)
                    })
                    .catch((e) => {
                        errors2 = { ...errors2, email: 'Email already exists' }
                        valid.push(false)
                    })
                // errors2 = { ...errors2, email: null }
                // valid.push(true)
            }
        }

        if (
            !!loginDetails.firstName === false ||
            loginDetails.firstName.trim() === ''
        ) {
            errors2 = { ...errors2, firstName: 'First name is required' }
            valid.push(false)
        } else {
            errors2 = { ...errors2, firstName: null }
            console.log(loginDetails.firstName)
            valid.push(true)
        }

        if (
            !!loginDetails.lastName === false ||
            loginDetails.lastName.trim() === ''
        ) {
            errors2 = { ...errors2, lastName: 'Last name is required' }
            valid.push(false)
        } else {
            errors2 = { ...errors2, lastName: null }
            valid.push(true)
        }

        if (!signupWithGoogle) {
            if (
                !!loginDetails.password === false ||
                loginDetails.password?.length < 8
            ) {
                errors2 = {
                    ...errors2,
                    password: 'Password must contain atleast 8 characters',
                }
                valid.push(false)
            } else {
                errors2 = { ...errors2, password: null }

                valid.push(true)
            }
            if (loginDetails.confirmPassword !== loginDetails.password) {
                errors2 = {
                    ...errors2,
                    confirmPassword: 'Passwords do not match!',
                }
                valid.push(false)
            } else {
                errors2 = {
                    ...errors2,
                    confirmPassword: null,
                }
                valid.push(true)
            }
        }

        if (
            !!companyDetails.companyName === false ||
            companyDetails.companyName.trim() === ''
        ) {
            errors2 = { ...errors2, companyName: 'Company name is required' }
            valid.push(false)
        } else {
            errors2 = { ...errors2, companyName: null }
            valid.push(true)
        }

        // if (!!companyDetails.address === false) {
        //     errors2 = { ...errors2, address: 'Required' }
        //     valid.push(false)
        // } else {
        //     errors2 = { ...errors2, address: null }
        //     valid.push(true)
        // }

        if (
            !!companyDetails.currentLocation === false ||
            companyDetails.currentLocation.trim() === ''
        ) {
            errors2 = { ...errors2, currentLocation: 'Location is required' }
            valid.push(false)
        } else {
            if (!companyDetails?.city || !companyDetails?.region) {
                errors2 = {
                    ...errors2,
                    currentLocation: 'Kindly include your city or region',
                }
                valid.push(false)
            } else {
                errors2 = { ...errors2, currentLocation: null }
                valid.push(true)
            }
        }

        // if (!!companyDetails.companySize === false) {
        //     errors2 = { ...errors2, companySize: 'Required' }
        //     valid.push(false)
        // } else {
        //     errors2 = { ...errors2, companySize: null }
        //     valid.push(true)
        // }

        // if (!!companyDetails.companyRegistrationNo === false) {
        //     errors2 = { ...errors2, companyRegistrationNo: 'Required' }
        //     valid.push(false)
        // } else {
        //     errors2 = { ...errors2, companyRegistrationNo: null }
        //     valid.push(true)
        // }

        if (!!companyDetails.phoneNumber === false) {
            errors2 = { ...errors2, phoneNumber: 'Phone Number is required' }
            valid.push(false)
        } else {
            errors2 = { ...errors2, phoneNumber: null }
            valid.push(true)
        }

        // if (companyDetails?.employerType === 'employment_agency') {
        //     if (!!companyDetails.employmentAgencyNo === false) {
        //         errors2 = { ...errors2, employmentAgencyNo: 'Required' }
        //         valid.push(false)
        //     } else {
        //         errors2 = { ...errors2, employmentAgencyNo: null }
        //         valid.push(true)
        //     }
        // }

        setErrors({ ...errors2 })
        console.log(errors2)
        return !valid.includes(false)
    }

    var temp = companyDetails?.country?.split(':')
    const generated_username = `${loginDetails.firstName}${
        loginDetails.lastName
    }${Math.floor(Math.random() * 100)}`

    const handleRegisterViaEmail = () => {
        const isValid = validateFields()
        console.log(isValid)
        if (isValid) {
            let payload = {
                email: loginDetails.email,
                password: loginDetails.password,
                username: generated_username.toLowerCase(),
                firstName: loginDetails.firstName,
                lastName: loginDetails.lastName,
                userRole: 'EMPLOYER',
                companyName: companyDetails.companyName,
                address: companyDetails.address,
                country: companyDetails?.country,
                region: companyDetails?.region || undefined,
                city: companyDetails?.city || undefined,
                currentLocation: companyDetails?.currentLocation || undefined,
                companySize: companyDetails.companySize,
                phoneNumber: `${country?.split(':')[1]}${
                    companyDetails.phoneNumber
                }`,
                employerType,
                companyRegistrationNo: companyDetails.companyRegistrationNo,
                subscribeToEmail: subscribeToEmail,
            }
            if (inviteCode && isValidCode) {
                payload = { ...payload, inviteCode: inviteCode }
            }
            signUp(payload)
        } else {
            //fields not validated
        }
    }

    useEffect(() => {
        if (Generals.showSignupSuccessModal === true) {
            setTimeout(() => {
                setShowModal(true)
            }, 1000)
        }
    }, [Generals])

    const handleCloseModal = () => {
        setShowModal(false)
        setShowSignupModal(false)
        setTimeout(() => {
            navigate('/login')
        }, 500)
    }

    const handleGoogleSignup = (data) => {
        const valid = validateFields()
        if (valid) {
            let payload = {
                email: signupWithGoogle?.profileObj?.email,
                idToken: signupWithGoogle?.tokenId,
                action: 'register',
                deviceToken: data?.token,
                role: 'EMPLOYER',
                platform: 'web',
                inviteCode,
                isCreatingEmployer: true,
                subscribeToEmail: subscribeToEmail,
            }
            if (!inviteCode) delete payload.inviteCode

            if (User?.token === null && valid) {
                let employer_data = {
                    email: loginDetails.email,
                    firstName: loginDetails.firstName,
                    lastName: loginDetails.lastName,
                    userRole: 'EMPLOYER',
                    companyName: companyDetails.companyName,
                    address: companyDetails.address,
                    country: companyDetails?.country,
                    region: companyDetails?.region || undefined,
                    city: companyDetails?.city || undefined,
                    currentLocation:
                        companyDetails?.currentLocation || undefined,
                    phoneNumber: `${country?.split(':')[1]}${
                        companyDetails.phoneNumber
                    }`,
                    employerType,
                    inviteCode: inviteCode,
                    subscribeToEmail: subscribeToEmail,
                }
                if (!inviteCode) delete employer_data.inviteCode
                verifyGoogle({ ...payload, ...employer_data })
            } else {
                console.log('please login')
            }
        }
    }

    useEffect(() => {
        if (isSignedIn) {
            createEmployerSuccess()
            if (isSigningIn === false) {
                navigate('/')
            }
        }
    }, [isSigningIn])

    const handleEmployerSignupViaGoogle = () => {
        const valid = validateFields()
        if (!!User?.token && valid) {
            let employer_data = {
                email: signupWithGoogle?.profileObj?.email,
                firstName: signupWithGoogle?.profileObj?.givenName,
                lastName: signupWithGoogle?.profileObj?.familyName,
                userRole: 'EMPLOYER',
                companyName: companyDetails.companyName,
                address: companyDetails.address,
                country: companyDetails?.country?.split(':')[0],
                companySize: companyDetails.companySize,
                agencyRegistrationNo: companyDetails.agencyRegistrationNo,
                showCandidatesFromSpecificCountry: true,
                countriesInterested: [companyDetails?.country?.split(':')[0]],
                phoneNumber: `${companyDetails?.country?.split(':')[1]}${
                    companyDetails.phoneNumber
                }`,
                employerType,
                companyRegistrationNo: companyDetails.companyRegistrationNo,
                inviteCode: inviteCode,
                subscribeToEmail: subscribeToEmail,
            }
            if (!inviteCode) delete employer_data.inviteCode
            userRequest
                .createEmployer(employer_data)
                .then((res) => {
                    createEmployerSuccess()

                    if (isSigningIn === false) {
                        navigate('/')
                    }
                })
                .catch((err) => {
                    console.log(err.response.data)
                })
        } else {
            console.log('please login')
        }
    }

    useEffect(() => {
        resetUserError()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (inviteCode.length >= 6) verifyCode()
        }, 500)
    }, [inviteCode])

    useEffect(() => {
        if (!!signupWithGoogle) {
            const { profileObj } = signupWithGoogle
            setLoginDetails({
                ...loginDetails,
                firstName: profileObj?.givenName,
                lastName: profileObj?.familyName,
                email: profileObj?.email,
            })
        }
    }, [signupWithGoogle])

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault()
                if (
                    User?.isRegistering ||
                    (inviteCode.length > 0 && !isValidCode) ||
                    !agreetoTerms
                ) {
                    return
                } else {
                    if (signupWithGoogle) {
                        handleGoogleSignup()
                    } else {
                        handleRegisterViaEmail()
                    }
                }
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [signupWithGoogle, handleGoogleSignup, handleRegisterViaEmail])

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    useEffect(() => {
        if (!loginDetails?.email) {
            setErrors({ ...errors, email: null })
        } else {
            const delayDebounceFn = setTimeout(() => {
                userRequest
                    .validateEmail({
                        email: loginDetails?.email,
                    })
                    .then((res) => {
                        setErrors({ ...errors, email: null })
                    })
                    .catch((e) => {
                        setErrors({ email: 'Email already exists' })
                    })
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [loginDetails?.email])

    return (
        <>
            <div style={{ backgroundColor: '#EEF6FD' }} className="h-100">
                <div className="container h-100">
                    <div
                        className="row m-0 align-items-center h-100"
                        style={{ backgroundColor: '#EEF6FD' }}
                    >
                        <div className="col-md-5">
                            <LeftCol />
                        </div>
                        <div className="col-md-7 d-flex align-items-center py-md-5 py-1">
                            <RightCol
                                showModal={showModal}
                                setShowModal={setShowModal}
                                setLoginDetails={setLoginDetails}
                                loginDetails={loginDetails}
                                signupWithGoogle={signupWithGoogle}
                                errors={errors}
                                setCompanyDetails={setCompanyDetails}
                                companyDetails={companyDetails}
                                setSubscribeToEmail={setSubscribeToEmail}
                                subscribeToEmail={subscribeToEmail}
                                handleGoogleSignup={handleGoogleSignup}
                                handleRegisterViaEmail={handleRegisterViaEmail}
                                User={User}
                                inviteCode={inviteCode}
                                isValidCode={isValidCode}
                                agreetoTerms={agreetoTerms}
                                setAgreeToTerms={setAgreeToTerms}
                                setVerifiedCaptcha={setVerifiedCaptcha}
                                verifiedCaptcha={verifiedCaptcha}
                                countryCodesList={countryCodesList}
                                setCountry={setCountry}
                                country={country}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row vh-100 p-0 m-0 d-none">
                <div className="col-md-4 login-bg p-0 h-100 d-md-flex d-none align-items-start">
                    <img src={logoWTag} alt="" className="mt-5 ms-5" />
                </div>
                <div className="col-md-8 p-0 d-flex flex-column align-items-center justify-content-center my-3 my-md-0">
                    <img src={logoWTag} alt="" className="mb-3" />
                    <div className="row w-100 justify-content-center p-0 m-0 my-4 my-md-0">
                        <div className="col-md-8 col-sm-12">
                            <div className="">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mb-3 text-center">
                                            Employer Sign Up
                                        </h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextField
                                                    type="text"
                                                    className=" mb-3"
                                                    placeholder="First Name"
                                                    onChange={(e) =>
                                                        setLoginDetails({
                                                            ...loginDetails,
                                                            firstName:
                                                                e.target.value,
                                                        })
                                                    }
                                                    defaultValue={
                                                        signupWithGoogle
                                                            ? signupWithGoogle
                                                                  ?.profileObj
                                                                  ?.givenName
                                                            : loginDetails?.firstName
                                                    }
                                                    error={errors?.firstName}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    type="text"
                                                    className=" mb-3"
                                                    placeholder="Last Name"
                                                    onChange={(e) =>
                                                        setLoginDetails({
                                                            ...loginDetails,
                                                            lastName:
                                                                e.target.value,
                                                        })
                                                    }
                                                    error={errors?.lastName}
                                                    defaultValue={
                                                        signupWithGoogle
                                                            ? signupWithGoogle
                                                                  ?.profileObj
                                                                  ?.familyName
                                                            : loginDetails?.lastName
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    type="text"
                                                    className=" mb-3"
                                                    placeholder="Company name"
                                                    onChange={(e) =>
                                                        setCompanyDetails({
                                                            ...companyDetails,
                                                            companyName:
                                                                e.target.value,
                                                        })
                                                    }
                                                    error={errors?.companyName}
                                                    defaultValue={
                                                        companyDetails?.companyName
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    type="text"
                                                    className=" mb-3"
                                                    placeholder="Company Registration Number"
                                                    onChange={(e) =>
                                                        setCompanyDetails({
                                                            ...companyDetails,
                                                            companyRegistrationNo:
                                                                e.target.value.replace(
                                                                    /[^a-z0-9]/gi,
                                                                    ''
                                                                ),
                                                        })
                                                    }
                                                    error={
                                                        errors?.companyRegistrationNo
                                                    }
                                                    value={
                                                        companyDetails?.companyRegistrationNo
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <SelectCountry2
                                                    value={
                                                        companyDetails?.country
                                                    }
                                                    onChange={(val) => {
                                                        setCompanyDetails({
                                                            ...companyDetails,
                                                            country: val,
                                                        })
                                                    }}
                                                    placeholder="Country"
                                                    isSignup
                                                    error={errors?.country}
                                                />
                                            </div>

                                            <div
                                                onClick={() => showHide()}
                                                onBlur={() => showHide(true)}
                                                className="col-md-6 mb-3"
                                            >
                                                <SelectSearch
                                                    options={[
                                                        {
                                                            name: 'Employer',
                                                            value: 'employer',
                                                        },
                                                        {
                                                            name: 'Employment Agency',
                                                            value: 'employment_agency',
                                                        },
                                                    ]}
                                                    printOptions={
                                                        displayOptions
                                                            ? 'always'
                                                            : 'never'
                                                    }
                                                    onChange={(val) => {
                                                        setCompanyDetails({
                                                            ...companyDetails,
                                                            employerType: val,
                                                        })
                                                    }}
                                                    value={
                                                        companyDetails?.employerType
                                                    }
                                                />
                                            </div>
                                            {companyDetails?.employerType ===
                                                'employment_agency' && (
                                                <div className="col-md-6">
                                                    <TextField
                                                        type="text"
                                                        className=" mb-3"
                                                        placeholder="Employment Agency Number"
                                                        onChange={(e) =>
                                                            setCompanyDetails({
                                                                ...companyDetails,
                                                                employmentAgencyNo:
                                                                    e.target.value.replace(
                                                                        /[^a-z0-9]/gi,
                                                                        ''
                                                                    ),
                                                            })
                                                        }
                                                        error={
                                                            errors?.employmentAgencyNo
                                                        }
                                                        value={
                                                            companyDetails?.employmentAgencyNo
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div className="col-md-6">
                                                <TextField
                                                    type="text"
                                                    className=" mb-3"
                                                    placeholder="Address"
                                                    onChange={(e) =>
                                                        setCompanyDetails({
                                                            ...companyDetails,
                                                            address:
                                                                e.target.value,
                                                        })
                                                    }
                                                    error={errors?.address}
                                                    defaultValue={
                                                        companyDetails?.address
                                                    }
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <div className=" mb-3 w-100 d-flex">
                                                    {companyDetails?.country && (
                                                        <div
                                                            className="input-group-text"
                                                            id="basic-addon1"
                                                        >
                                                            {companyDetails?.country
                                                                ? companyDetails.country.split(
                                                                      ':'
                                                                  )[1]
                                                                : ''}
                                                        </div>
                                                    )}
                                                    {/* <TextField
                                                        type="text"
                                                        placeholder="Phone No."
                                                        aria-label=""
                                                        aria-describedby="basic-addon1"
                                                        value={
                                                            companyDetails?.phoneNo
                                                        }
                                                        onChange={(e) => {
                                                            setCompanyDetails({
                                                                ...companyDetails,
                                                                phoneNo:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }}
                                                        error={errors?.phoneNo}
                                                        fullWidth
                                                        name="phoneNo"
                                                    /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <TextField
                                                    type="type"
                                                    className=" mb-3"
                                                    placeholder="Email Address"
                                                    onChange={(e) =>
                                                        setLoginDetails({
                                                            ...loginDetails,
                                                            email: e.target
                                                                .value,
                                                        })
                                                    }
                                                    error={errors?.email}
                                                    defaultValue={
                                                        signupWithGoogle
                                                            ? signupWithGoogle
                                                                  ?.profileObj
                                                                  ?.email
                                                            : loginDetails?.email
                                                    }
                                                    disabled={
                                                        !!signupWithGoogle
                                                    }
                                                />
                                            </div>
                                            {!signupWithGoogle && (
                                                <>
                                                    <div className="col-md-12">
                                                        <TextField
                                                            type="password"
                                                            className=" mb-3"
                                                            placeholder="Password"
                                                            onChange={(e) =>
                                                                setLoginDetails(
                                                                    {
                                                                        ...loginDetails,
                                                                        password:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                )
                                                            }
                                                            defaultValue={
                                                                loginDetails?.password
                                                            }
                                                            error={
                                                                errors?.password
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <TextField
                                                            type="password"
                                                            className=" mb-3"
                                                            placeholder="Confirm Password"
                                                            onChange={(e) =>
                                                                setLoginDetails(
                                                                    {
                                                                        ...loginDetails,
                                                                        confirmPassword:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                )
                                                            }
                                                            defaultValue={
                                                                loginDetails?.confirmPassword
                                                            }
                                                            error={
                                                                errors?.confirmPassword
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <h5>Do you have an invite code?</h5>
                                        <p className="mb-3">
                                            Were you introduced to Joblu by a
                                            colleague or a friend? Let us know
                                            by entering their invite code below:
                                        </p>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TextField
                                                    type="text"
                                                    className="mb-3"
                                                    placeholder="Enter invite code (optional)"
                                                    onChange={(e) =>
                                                        setInviteCode(
                                                            e.target.value
                                                        )
                                                    }
                                                    error={
                                                        inviteCode.length >=
                                                            6 &&
                                                        !isValidCode &&
                                                        'Invalid code'
                                                    }
                                                    defaultValue={inviteCode}
                                                    disabled={
                                                        location?.state
                                                            ?.registercode &&
                                                        isValidCode
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="terms"
                                                onClick={() =>
                                                    setAgreeToTerms(
                                                        !agreetoTerms
                                                    )
                                                }
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="terms"
                                            >
                                                I agree to the Joblu{' '}
                                                <a
                                                    href="https://joblu.io/terms-of-use/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Terms of Use
                                                </a>{' '}
                                                and{' '}
                                                <a
                                                    href="https://joblu.io/privacy-policy/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Privacy Policy
                                                </a>
                                                .
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultChecked={
                                                    subscribeToEmail
                                                }
                                                onClick={() =>
                                                    setSubscribeToEmail(
                                                        !subscribeToEmail
                                                    )
                                                }
                                                id="subscribe"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="subscribe"
                                            >
                                                Subscribe to our newsletter and
                                                receive the latest news and
                                                trends delivered right to your
                                                inbox!
                                            </label>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                            <button
                                                className="btn btn-outline-primary text-center"
                                                onClick={() => {
                                                    softReset()
                                                    navigate('/login')
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary text-center"
                                                onClick={
                                                    signupWithGoogle
                                                        ? handleGoogleSignup
                                                        : handleRegisterViaEmail
                                                }
                                                disabled={
                                                    User?.isRegistering ||
                                                    (inviteCode.length > 0 &&
                                                        !isValidCode) ||
                                                    !agreetoTerms
                                                }
                                            >
                                                Create account
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PopUpModal
                    title="Sign Up Successful!"
                    showModal={showModal}
                    setShowModal={() => handleCloseModal()}
                    icon={CheckEmail}
                    onButtonClick={() => {
                        handleCloseModal()
                    }}
                    buttonText="OK"
                >
                    <p className="text-center">
                        Please check the link we sent to your email to verify
                        your registration.
                        <br /> <br />
                        If it isn't there, make sure it's not in your spam
                        folder.
                    </p>
                </PopUpModal>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        loggedUser: state.loggedUser,
        Generals: state.Generals,
    }
}

const mapDispatchToProps = {
    signIn: userAction.signIn,
    signUp: userAction.signUp,
    openApp: rootAction.openApp,
    setLoggedIn: rootAction.setLoggedIn,
    resetVerification: userAction.resetVerification,
    verifyGoogle: userAction.verifyGoogleToken,
    createEmployerSuccess: userAction.createEmployerSuccess,
    softReset: userAction.softReset,
    resetUserError: userAction.resetUserError,
    setShowSignupModal: appActions.setShowSignupModal,
}
export default connect(mapStateToProps, mapDispatchToProps)(Signup)
