import { Button } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Box, Grid, Paper } from '@mui/material'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import userActions from '../../redux/user/actions'
import { paymentRequests } from '../../service/requests'
import { STRIPE_BASE_URL, STRIPE_SECRET } from '../../utils/constants'
import {
    getGeolocation,
    get_pricing_currency,
    thousendSeparator,
} from '../../utils/helpers'
import JobluTypography from '../Typography'
import Loading from '../reusables/Loading'
import { JobluSubscriptions } from './Pricing'

const talent_finder_perks = [
    'Unlimited Candidate Search',
    '100 Superlikes and direct messages to candidates tools',
    'Accessible Candidate Contact Information',
]
const CheckoutForm = (props) => {
    const {
        amount,
        Generals: { currency },
        plan,
        customerId,
        payload,
        getActiveSubscription,
        paymentIntent,
        trialUsed,
        User,
    } = props
    const location = useLocation()
    const stripe = useStripe()
    const elements = useElements()
    const [amountPerMonth, setAmountPerMonth] = useState(0)
    const [totalMonths, setMonths] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const getSubs = async (callback) => {
        let tries = 0
        return await new Promise((resolve) => {
            const interval = setInterval(async () => {
                if (!User?.subscription?.active) {
                    if (tries <= 3) {
                        console.log('still fetching::')
                        await getActiveSubscription()
                        tries += 1
                    }
                } else {
                    //stop
                    console.log('interval stopped ::')
                    clearInterval(interval)
                    // callback && callback()
                    resolve(true)
                }
                if (tries >= 3) {
                    clearInterval(interval)
                    resolve(true)
                }
            }, 3500)
        })
    }
    const handleSubmit = async (event) => {
        console.log('form submitttttt')
        setLoading(true)
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }
        let modal_type = 'payment=confirmed'

        if (trialUsed || plan?.key === 'talentSearchBasic') {
            if (plan?.key === 'talentSearchBasic') {
                modal_type = 'payment=TF'
            }
            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                // confirmParams: {
                //     return_url: `${window.location.origin}/dashboard?${modal_type}`,
                // },
                redirect: 'if_required',
            })

            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                // alert(result.error.message)
                setLoading(false)
                return false
                // window.location.href = `${window.location.origin}/dashboard?${modal_type}`
            } else {
                getSubs().then(() => {
                    setLoading(false)
                    // if (!!User?.subscription?.active) {
                    window.location.href = `${window.location.origin}/dashboard?${modal_type}`
                    // }
                })

                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        } else {
            const result = await stripe.confirmSetup({
                elements,
                // confirmParams: {
                //     return_url: `${window.location.origin}/dashboard?${modal_type}`,
                // },
                redirect: 'if_required',
            })

            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                // alert(result.error.message)

                setLoading(false)
                return false
            } else {
                // setLoading(false)
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }

            const { setupIntent } = result

            paymentRequests
                .subscribeToTrial({
                    ...payload,
                    paymentIntent: paymentIntent,
                    customerId: customerId,
                    setupId: setupIntent.id,
                    paymentId: setupIntent.payment_method,
                })
                .then(() => {
                    getSubs().then(() => {
                        // if (!!User?.subscription?.active) {
                        setLoading(false)
                        window.location.href = `${window.location.origin}/dashboard?${modal_type}&trial=true`
                        // }
                    })
                })

            return
        }
    }

    useEffect(() => {
        if (plan) {
            const { recurring } = plan
            if (recurring) {
                const { interval, interval_count } = recurring
                let months = interval_count
                if (interval === 'year') {
                    months = interval_count * 12
                }
                setMonths(months)
                let per_month = plan.unit_amount / 100 / months
                setAmountPerMonth(thousendSeparator(per_month.toFixed()))
            }
        }
    }, [plan])

    const handlePremiumChange = (event) => {
        setSelectedPremium(event.target.value)
    }

    const product = JobluSubscriptions?.find((item) => item.title === 'PREMIUM')
    console.log('product', product)
    const [premium_options, setPremiumOptions] = useState([])
    const [selected_premium, setSelectedPremium] = useState(plan?.id)
    const [selected_premium_data, setSelectedPremiumData] = useState({})

    useEffect(() => {
        if (selected_premium && premium_options) {
            const data = premium_options?.find(
                (item) => item.id === selected_premium
            )
            // console.log('premium data', data)
            setSelectedPremiumData(data)
        }
    }, [selected_premium, premium_options])

    const [geolocation, setGeoLocation] = useState({})
    useEffect(() => {
        getGeolocation().then((data) => {
            setGeoLocation(data)
        })
    }, [])

    const getPlans = () => {
        let options = []

        let request = paymentRequests.getDollarPlans
        if (geolocation?.country === 'PH')
            request = paymentRequests.getPesoPlans
        if (props.User?.subscription?.data?.currency === 'usd')
            request = paymentRequests.getDollarPlans

        request()
            .then(async (res) => {
                if (res.data && Object.keys(res.data).length > 0) {
                    console.log(res.data)
                    for (let key of Object.keys(res.data)) {
                        await axios
                            .get(`${STRIPE_BASE_URL}prices/${res.data[key]}`, {
                                headers: {
                                    Authorization: 'Bearer ' + STRIPE_SECRET, //the token is a variable which holds the token
                                },
                            })
                            // eslint-disable-next-line no-loop-func
                            .then((res) => {
                                let label = ''
                                if (key === 'monthly') {
                                    label = 'Monthly'
                                }
                                if (key === 'monthly3') {
                                    label = 'Quarterly'
                                }
                                if (key === 'monthly12') {
                                    label = 'Annually'
                                }
                                options.push({
                                    ...res.data,
                                    label,
                                    key,
                                })
                                options = _.uniqBy(options, 'id')
                            })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPremiumOptions(options)
            })
    }
    useEffect(() => {
        if (geolocation?.country) {
            getPlans()
        }
    }, [geolocation])

    const [data, setData] = useState({})

    useEffect(() => {
        if (premium_options && selected_premium) {
            let plan_data = premium_options?.find(
                (item) => item?.id === selected_premium
            )
            let per_month = 0

            if (plan_data?.recurring) {
                const { interval, interval_count } = plan_data?.recurring
                let months = interval_count
                if (interval === 'year') {
                    months = interval_count * 12
                }

                per_month = plan_data.unit_amount / 100 / months
                per_month = per_month.toFixed(0)
                per_month = thousendSeparator(per_month)
            }
            plan_data = { ...plan_data, per_month }
            console.log(
                'plan data',
                plan_data,
                selected_premium,
                premium_options
            )

            setData(plan_data)
        }
    }, [selected_premium, premium_options])

    console.log('plan', plan)

    return (
        <Box style={{ background: '#E4ECF3', height: '100vh' }}>
            <Grid container justifyContent={'center'} padding={3}>
                <Grid
                    item
                    xs={12}
                    md={8}
                    container
                    spacing={3}
                    style={{ marginTop: 50 }}
                >
                    <Grid item xs={12} md={5}>
                        <Paper style={{ height: '100%' }}>
                            <Box
                                style={{
                                    padding: 30,
                                    background: '#fff',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {plan?.key !== 'talentSearchBasic' ? (
                                    <Box>
                                        <JobluTypography.H6 align="center">
                                            {product?.title}
                                        </JobluTypography.H6>
                                        <Box
                                            style={{
                                                borderBottom:
                                                    '1px solid #E9EBEC',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: 20,
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    borderRadius:
                                                        '5px 5px 0px 0px',
                                                    background:
                                                        product?.colorIndicator,
                                                    width: 90,
                                                    height: 5,
                                                }}
                                            ></Box>
                                        </Box>
                                        {/* <Box>
                                        {product?.title === 'PREMIUM'
                                            ? product?.head(
                                                  premium_options,
                                                  handlePremiumChange,
                                                  selected_premium
                                              )
                                            : product?.head}
                                    </Box> */}

                                        <Box
                                            style={{
                                                padding: 10,
                                                border: '1px solid #f3f3f3',
                                                borderRadius: 5,
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}
                                        >
                                            <JobluTypography.H5
                                                align="center"
                                                style={{
                                                    fontWeight: 600,
                                                    color: '#009CDE',
                                                }}
                                            >
                                                {data?.label}
                                            </JobluTypography.H5>
                                            {!!selected_premium_data?.label && (
                                                <JobluTypography.H4
                                                    align="center"
                                                    style={{
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {data?.currency?.toUpperCase()}{' '}
                                                    {data?.per_month}/mo
                                                </JobluTypography.H4>
                                            )}
                                            {data?.unit_amount &&
                                                data?.label !== 'Monthly' &&
                                                data?.label !== 'monthly' && (
                                                    <JobluTypography.H3
                                                        align="center"
                                                        style={{
                                                            fontWeight: 600,
                                                            marginTop: 15,
                                                        }}
                                                        primary
                                                        semiBold
                                                    >
                                                        Total of{' '}
                                                        {data?.currency?.toUpperCase()}{' '}
                                                        {data?.unit_amount &&
                                                            thousendSeparator(
                                                                data?.unit_amount /
                                                                    100
                                                            )}
                                                    </JobluTypography.H3>
                                                )}
                                        </Box>
                                        <Box paddingTop={3}>
                                            {product?.full_fetaures?.map(
                                                (perk) => {
                                                    return (
                                                        <JobluTypography.Body
                                                            primary
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <Check
                                                                style={{
                                                                    color: '#009CDE',
                                                                }}
                                                            />
                                                            {perk}
                                                        </JobluTypography.Body>
                                                    )
                                                }
                                            )}
                                        </Box>
                                        <Box paddingTop={3}>
                                            <JobluTypography.Body
                                                primary
                                                style={{ fontWeight: 700 }}
                                            >
                                                Other Features that you’ll get
                                            </JobluTypography.Body>
                                            {product?.other_features?.map(
                                                (perk) => {
                                                    return (
                                                        <JobluTypography.Body
                                                            primary
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <Check
                                                                style={{
                                                                    color: '#009CDE',
                                                                }}
                                                            />
                                                            {perk}
                                                        </JobluTypography.Body>
                                                    )
                                                }
                                            )}
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box>
                                        <JobluTypography.H5
                                            primary
                                            style={{ fontWeight: 600 }}
                                            align="center"
                                        >
                                            Talent Finder
                                        </JobluTypography.H5>
                                        <Box
                                            style={{
                                                borderBottom:
                                                    '1px solid #E9EBEC',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    width: 98,
                                                    height: 5,
                                                    background: '#009CDE',
                                                    borderRadius:
                                                        '5px 5px 0px 0px',
                                                }}
                                            ></Box>
                                        </Box>
                                        <Box
                                            style={{
                                                padding: 10,
                                                border: '1px solid #f3f3f3',
                                                borderRadius: 5,
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}
                                        >
                                            <JobluTypography.H3
                                                primary
                                                align="center"
                                                style={{ fontWeight: 700 }}
                                            >
                                                <span
                                                    style={{ fontWeight: 400 }}
                                                >
                                                    {get_pricing_currency(
                                                        plan?.currency
                                                    )}
                                                </span>
                                                {thousendSeparator(
                                                    plan?.unit_amount / 100
                                                )}
                                            </JobluTypography.H3>
                                        </Box>
                                        <Box>
                                            {talent_finder_perks?.map(
                                                (perk) => {
                                                    return (
                                                        <Box
                                                            display="flex"
                                                            marginBottom={1}
                                                        >
                                                            <Box display="flex">
                                                                <Check
                                                                    style={{
                                                                        color: '#009CDE',
                                                                        margin: 'auto',
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box
                                                                paddingLeft={1}
                                                            >
                                                                <JobluTypography.Body
                                                                    primary
                                                                    style={{
                                                                        fontWeight: 600,
                                                                        lineHeight:
                                                                            '21px',
                                                                    }}
                                                                >
                                                                    {perk}
                                                                </JobluTypography.Body>
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                            )}
                                        </Box>
                                    </Box>
                                )}
                                {/* <Box style={{ marginTop: 20 }}>
                                    <JobluTypography.Caption align="center">
                                        See full features below
                                    </JobluTypography.Caption>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        style={{
                                            textTransform: 'none',
                                            marginTop: 5,
                                        }}
                                        onClick={() => {
                                            if (product?.title === 'PREMIUM') {
                                                //handleCheckout()
                                            }
                                        }}
                                    >
                                        {product?.defaultButtonText}
                                    </Button>
                                </Box> */}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Paper style={{ padding: 30, height: '100%' }}>
                            <form onSubmit={handleSubmit}>
                                <PaymentElement />
                                <Grid container spacing={2}>
                                    <Grid xs={12} md={6} item>
                                        <Button
                                            variant="outlined"
                                            style={{
                                                color: '#EB5757',
                                                border: '1px solid #EB5757',
                                                textTransform: 'none',
                                            }}
                                            fullWidth
                                            onClick={(e) => {
                                                e.preventDefault()
                                                navigate(-1)
                                            }}
                                        >
                                            Cancel and Go back
                                        </Button>
                                    </Grid>
                                    <Grid xs={12} md={6} item>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ textTransform: 'none' }}
                                            fullWidth
                                            onClick={(e) => {
                                                e.preventDefault()
                                                const payment_element =
                                                    elements.getElement(
                                                        PaymentElement
                                                    )

                                                payment_element.clear()
                                            }}
                                        >
                                            Clear Fields
                                        </Button>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Button
                                            type="submit"
                                            // className="btn btn-primary mt-3 mx-auto"
                                            disabled={!stripe}
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                            }}
                                            color="primary"
                                            variant="contained"
                                        >
                                            {User?.user?.usedTrial || plan?.key === 'talentSearchBasic'  ? (
                                                <>
                                                    Pay &nbsp;
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        {get_pricing_currency(
                                                            plan?.currency
                                                        )}
                                                    </span>
                                                    {amount || ''}
                                                </>
                                            ) : (
                                                'Subscribe to trial'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                            {!!selected_premium_data?.label &&
                                selected_premium_data?.label !== 'Monthly' && (
                                    <Box
                                        style={{
                                            height: '45%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box>
                                            <JobluTypography.H1
                                                align="center"
                                                style={{ color: '#D7A048' }}
                                                gutterBottom={3}
                                            >
                                                Wow, what a boss move!
                                            </JobluTypography.H1>

                                            <JobluTypography.H6
                                                primary
                                                align="center"
                                            >
                                                You're saving{' '}
                                                {selected_premium_data?.label ===
                                                'Quarterly'
                                                    ? '10%'
                                                    : '20%'}{' '}
                                                discount with this order!
                                                {/* That
                                                means you're saving $32.7.
                                                FOR HARLE */}
                                            </JobluTypography.H6>
                                        </Box>
                                    </Box>
                                )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                show={loading}
                centered
                contentClassName="bg-transparent border-0"
            >
                <Loading show color="primary" />
            </Modal>
        </Box>
    )
}
const mapStateToProps = (state) => {
    return {
        ...state,
    }
}
const mapDispatchToProps = {
    getActiveSubscription: userActions.getActiveSubscription,
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
