import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import userAction from '../src/redux/user/actions'
import Header from './components/header/Header'
import rootAction from './redux/action.js'
import appActions from './redux/app/actions'
import jobfairActions from './redux/job-fair/jobfairActions'
import privateRoutes from './routes/private-routes'
import publicRoutes from './routes/public-routes'
import {
    generalSettingsRequest,
    jobFairRequest,
    jobsRequest,
    userRequest,
} from './service/requests'

function App(props) {
    const {
        User,
        getUser,
        setCurrencyList,
        setShowTutorial,
        setChatRooms,
        setToken,
        AppState,
        softReset,
        setJobFairs,
        JobFairs,
        setShowBanner,
        setShowAlert,
    } = props
    const {
        isCreatingEmployer,
        isSignedIn,
        user,
        chatRooms,
        isSigningIn,
        isGoogleSignup,
        error,
        firstTimeLoggedIn
    } = User
    const { showAlert } = AppState
    const [routes, setRoutes] = useState(publicRoutes)
    const location = useLocation()
    const navigate = useNavigate()
    const [noNav, setNoNav] = useState(false)

    useEffect(() => {
        if (Boolean(showAlert)) {
            setTimeout(() => {
                setShowAlert(false)
            }, showAlert?.duration || 3000)
        }
    }, [showAlert])

    useEffect(() => {
        if (!isSignedIn && isGoogleSignup) {
            setRoutes(publicRoutes)
            setTimeout(() => {
                if (location.pathname === '/') {
                    navigate('/signup')
                }
            }, 500)
        } else if (!isCreatingEmployer && !!isSignedIn && !isGoogleSignup) {
            if (location.pathname.includes('/register')) {
                navigate('/dashboard')
            }
            setRoutes(privateRoutes)
            getUser()
            getJobFairs()
            setTimeout(() => {
                if (
                    location.pathname === '/' ||
                    location.pathname === '/login' ||
                    location.pathname === '/signup/applicant' ||
                    location.pathname === '/password-reset'
                ) {
                    // navigate('/dashboard')
                    checkLogin()
                }
            }, 1000)
        } else {
            setRoutes(publicRoutes)
            if (
                location.pathname === '/password-reset' ||
                location.pathname.includes('/register') ||
                location.pathname.includes('/showcase') ||
                location.pathname === '/signup' ||
                location.pathname === '/verify' ||
                location.pathname === '/downloadprofilepdf' ||
                location.pathname === '/job-fair' ||
                location.pathname === '/signup/applicant' ||
                location.pathname?.includes('/mobile')
            ) {
                // navigate(location.pathname)
            } else {
                setTimeout(() => {
                    navigate('/login')
                }, 500)
            }
        }
    }, [isSignedIn, isCreatingEmployer, isGoogleSignup, error, User?.user?.firstTimeLoggedIn])

    useEffect(() => {
        if (
            !!JobFairs.signedInJobFair &&
            !location.pathname.includes('/register')
        ) {
            navigate(`/job-fair/dashboard/${JobFairs.signedInJobFair}`)
        }
    }, [JobFairs])

    const checkLogin = () => {
        if (User?.user) {
            let payload = {
                offset: 1,
                limit: 1,
            }
            jobsRequest.getPublishedJobs(payload).then((res) => {
                let data = res?.data?.jobs
                const { totalCount, limit } = data
                if (totalCount < 1) {
                    navigate('/create-job', {
                        state: { status: 'new' },
                    })
                } else {
                    navigate('/dashboard')
                }
            })
        } else {
            navigate('/dashboard')
        }
    } 

    useEffect(() => {
        const isNoNav = new URLSearchParams(location.search).get('noNav')
        // if (location.pathname !== '/dashboard') {
        const root = document.getElementById('root')
        root.classList.remove('overflow-hidden')
        // }
        isNoNav ? setNoNav(true) : setNoNav(false)
    }, [location])

    useEffect(() => {
        softReset()
    }, [])

    useEffect(() => {
        try {
            generalSettingsRequest.getCurrencies().then((res) => {
                if (res) {
                    let data = res.data
                    let currencies = {}
                    data.currencyTypes?.forEach((item) => {
                        currencies[item.baseCurrency] = {
                            currencies: item.currencies,
                        }
                    })
                    setCurrencyList(currencies)
                } else {
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [isSignedIn])

    const getJobFairs = () => {
        try {
            jobFairRequest.getOngoingJobFairs().then((res) => {
                if (res) {
                    setJobFairs(res?.data)
                    res?.data?.totalCount > 0
                        ? setShowBanner(true)
                        : setShowBanner(false)
                }
            })
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
            <div
                className={`mx-0 position-relative ${
                    location.pathname !== '/login' &&
                    location.pathname !== '/signup' &&
                    location.pathname !== '/create-job' &&
                    location.pathname !== '/jobs/edit' &&
                    location.pathname !== '/profile-wizard' &&
                    location.pathname !== '/pricing' &&
                    !location.pathname.includes('/showcase') &&
                    !location.pathname === '/signup/applicant'
                        ? 'mb50'
                        : ''
                }`}
                id="app-main"
                // style={{ marginBottom: 50 }}
            >
                {/* {!noNav && <ProfileCompletionBanner />} */}
                {!!isSignedIn &&
                    location.pathname !== '/login' &&
                    location.pathname !== '/signup' &&
                    !location.pathname.includes('/job-fair/register') && (
                        // AppState.showNav &&
                        // !noNav &&
                        <Header />
                    )}
                <div>
                    <Routes>
                        {routes.map((route) => (
                            <Route
                                path={route.path}
                                key={route.name}
                                element={route?.component}
                                index={route?.index}
                            >
                                {route?.nested_routes?.map((nested) => {
                                    return (
                                        <Route
                                            key={nested?.name}
                                            path={nested?.path}
                                            element={nested?.component}
                                            index={nested?.index}
                                        />
                                    )
                                })}
                            </Route>
                        ))}
                    </Routes>
                </div>
                {/* <Footer hidden={true}/> */}
                {/* {!!isSignedIn && <ChatBox />} */}
                {/* {location.pathname === '/dashboard' &&
                !AppState.hideTriggerTutorial && (
                    <div
                        className="position-fixed d-none d-md-block"
                        style={{ right: '100px', bottom: '100px' }}
                        onClick={() => setShowTutorial(true)}
                    >
                        <img src={helpIcon} alt="" role="button" />
                    </div>
                )} */}
                {/* <div
                id="dl-container"
                style={{ overflow: 'hidden', height: 0 }}
            ></div> */}
            </div>
            <Snackbar
                open={!!showAlert?.message}
                autoHideDuration={showAlert?.duration || 3000}
                anchorOrigin={{
                    vertical: showAlert?.position || 'top',
                    horizontal: 'right',
                }}
                style={{ zIndex: 100000000 }}
            >
                {showAlert && (
                    <Alert
                        variant="filled"
                        severity={showAlert?.type || 'success'}
                        sx={{ width: '100%' }}
                        style={{ zIndex: 100000000 }}
                    >
                        {showAlert?.message || 'Success'}
                    </Alert>
                )}
            </Snackbar>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
        JobFairs: state.JobFairs,
    }
}
const mapDispatchToProps = {
    signOut: rootAction.signOut,
    getUser: userAction.getUser,
    setCurrencyList: appActions.setCurrenyList,
    setShowTutorial: appActions.setShowTutorial,
    setChatRooms: userAction.setChatRooms,
    setToken: userAction.setToken,
    softReset: userAction.softReset,
    setJobFairs: jobfairActions.setJobFairs,
    setShowBanner: jobfairActions.setShowBanner,
    setShowAlert: appActions.showAlert,
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
