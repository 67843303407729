import React from 'react'
import { useNavigate } from 'react-router-dom'
import LikesImg from '../../assets/img/likes/likesImg.png'

function EmptyState(props) {
    const { emptyImg, title, body, link, linkType, linkTitle, subText } = props
    const navigate = useNavigate()
    return (
        <div className="h-100 w-100 my-5 px-5 w-50">
            <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                {emptyImg && (
                    <img src={LikesImg} width={260} alt="" className="mb-3" />
                )}
                {title && (
                    <h2 className="mb-3 text-center font-weight-bold text-primary mt-4">
                        {title}
                    </h2>
                )}
                {body && (
                    <h5 className="text-primary text-center mb-3">
                        <strong>{body}</strong>
                    </h5>
                )}
                {body && <p className="text-muted mb-3">{subText}</p>}
                {linkType === 'button' ? (
                    <div className="mt-1">
                        <a
                            onClick={() => {
                                navigate(link)
                            }}
                            style={{
                                background: '#D7A048',
                                color: '#ffffff!important',
                                padding: '1rem 2rem',
                            }}
                            className='btn text-white'
                        >
                            {linkTitle || ''}
                        </a>
                    </div>
                ) : (
                    <div className="mt-1 text-white">
                        <a
                            onClick={() => {
                                navigate(`${link}`)
                            }}
                            // style={{
                            //     background: '#D7A048',
                            //     color: '#ffffff!important',
                            //     padding: '1rem 3rem',
                            // }}
                            className="btn btn-primary"
                        >
                            <u style={{color: '#fff'}}>{linkTitle || ''}</u>
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}

export default EmptyState
