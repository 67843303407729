import Fuse from 'fuse.js'
import _ from 'lodash'
import { useEffect } from 'react'
import { useState } from 'react'
import SelectSearch from 'react-select-search'
import { myFuzzySearch } from './SelectJobPost'

function customFuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name', 'groupName', 'items.name'],
        threshold: 0.3,
    })
    return (value) => {
        if (!value.length) {
            return options
        }

        const result = fuse.search(value)?.map((row) => {
            const { item } = row
            const fs = new Fuse(item?.items || [], {
                keys: ['name'],
                threshold: 0.3,
            })
            const result2 = fs.search(value)?.map((items) => items.item)

            return result2
        })
        return result[0] ? result[0] : []
    }
}

function SelectCountry2(props) {
    const {
        onChange,
        value,
        defaultValue,
        name,
        noCellCode,
        isSignup,
        placeholder,
        grouped,
        error,
        noAll,
        wrapperClass,
    } = props
    const [displayOptions, setDisplayOptions] = useState(false)
    const showHide = (onBlur) => {
        if (onBlur) {
            setDisplayOptions(false)
        } else {
            setDisplayOptions(!displayOptions)
        }
    }
    const countryCodes = require('country-codes-list')
    const myCountryCodesObject = countryCodes.customList(
        'countryCode',
        '{countryNameEn}:+{countryCallingCode}'
    )
    const popular = ['Philippines']

    const [sortable, setSortable] = useState({})

    useEffect(() => {
        const sortable1 = Object.fromEntries(
            Object.entries(myCountryCodesObject).sort(([,a],[,b]) => a-b)
        );
        setSortable(sortable1)
    }, [])

    const [countryCodesList, setCountryCodesList] = useState(
        Object.values(myCountryCodesObject)?.map((item) => {
            var temp = item.split(':')
            const category = popular.includes(temp[0].trim())
                ? 'Most Popular Countries'
                : 'Coming Soon'
            if (noCellCode) {
                return {
                    name: `${temp[0]}`,
                    value: `${temp[0]}`,
                    category,
                }
            }
            return {
                name: `${temp[0]} (${temp[1]})`,
                value: item,
                category,
            }
        })
    )

    const byCategory = _.groupBy(countryCodesList, 'category')
    // console.log('byCater', byCategory)
    let options = []
    if (grouped) {
        options = Object.keys(byCategory)?.map((category, index, items) => {
            return {
                type: 'group',
                name: category,
                items: byCategory[category],
            }
        })

        //put asia pacific first
        options = _.sortBy(options, ({ name }) =>
            name === 'Most Popular Countries' ? 0 : 1
        )
    } else {
        options = countryCodesList
    }
    if (!isSignup && !noAll)
        options = [{ name: 'All Countries', value: '' }, ...options]

    return (
        <>
            <div
                onClick={() => showHide()}
                onBlur={() => showHide(true)}
                className={wrapperClass}
            >
                <SelectSearch
                    printOptions={displayOptions ? 'always' : 'never'}
                    options={options}
                    search
                    filterOptions={!grouped ? myFuzzySearch : customFuzzySearch}
                    emptyMessage={() => (
                        <div style={{ textAlign: 'center', fontSize: '0.8em' }}>
                            Not found
                        </div>
                    )}
                    placeholder={placeholder || 'Select Job Country'}
                    onChange={onChange}
                    value={value}
                    defaultValue={defaultValue}
                    renderGroupHeader={(title) => {
                        return (
                            <div className="p-1 px-2 border-start border-light">
                                <p className="text-start text-capitalize text-dark fw-bold">
                                    <strong>{title}</strong>
                                </p>
                            </div>
                        )
                    }}
                    renderValue={(props, sna, className) => {
                        //       console.log(props)
                        return (
                            <input
                                type="text"
                                {...props}
                                className={`${className} ${
                                    !!error && 'border error border-danger'
                                }`}
                            />
                        )
                    }}
                />
            </div>
            {error && <span className="error-helper mt-0">{error}</span>}
        </>
    )
}

export default SelectCountry2
