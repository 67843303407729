import React, { useEffect, useRef } from 'react';
import './custom-style.css'

const SmallBanner = (props) => {
    const { style } = props
    const banner = useRef()
    const atOptions = {
        key: '80819ce26abaa2b7ad0abf9ee1ed9553',
        format: 'iframe',
        height: 50,
        width: 320,
        params: {},
    }
    useEffect(() => {
        if (banner.current && !banner.current.firstChild) {
            const conf = document.createElement('script')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//www.topcreativeformat.com/${atOptions?.key}/invoke.js`
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`

            banner.current.append(conf)
            banner.current.append(script)
        }
    }, [banner])

    return (
    <div style={style} className='ad-container-small'>
            <div 
            ref={banner}
            className="justify-center items-center text-white text-center"
            >
            </div>
    </div>

    )
}

export default SmallBanner;
