import { Box, Button, Dialog, Typography } from '@material-ui/core'
import _, { debounce } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import jobEditSuccess from '../../../assets/img/createJob/job-edit-message.png'
import jobPendingIcon from '../../../assets/img/createJob/job-pending-icon.png'
import CustomGooglePlaces from '../../../components/google-places'
import TextField from '../../../components/inputs/CustomTextField'
import SelectJobFunction from '../../../components/reusables/SelectJobFunction'
import {
    configRequest,
    jobsRequest,
    userRequest,
} from '../../../service/requests'
import { formatDateOnlyv2 } from '../../../utils/helpers'
import { skills_masterlist } from '../../../utils/skills'
import { validateEmail } from '../../../utils/validators'
import '../styles.css'
import premiumBg from '../../../assets/img/createJob/Go_Premium_BG.png'
import CloseWhite from '../../../assets/img/CloseWhite.png'
import JobluTypography from '../../../components/Typography'

function CreateJob(props) {
    const { User, selectedJobId } = props

    const [tempSkill, setTempSkill] = useState('')
    const navigate = useNavigate()

    const location_ = useLocation()
    const [showAlert, setShowAlert] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [jobFunction, setJobFunction] = useState(
        location_?.state?.job?.jobFunction || null
    )
    const [error_JobFunction, setError_JobFunction] = useState(null)
    const [jobTitle, setJobTitle] = useState(
        location_?.state?.job?.jobTitle || null
    )
    const [error_JobTitle, setError_JobTitle] = useState(null)
    const [skills, setSkills] = useState(
        location_?.state?.job?.technicalSkills || []
    )

    const jT = location_?.state?.job?.jobTitle
    const [error_Skills, setError_Skills] = useState(null)
    const [responsibilities, setResponsibilities] = useState(
        location_?.state?.job?.responsibilities || null
    )
    const [error_Responsibilities, setError_Responsibilities] = useState(false)

    const [skill_list, setSkill_List] = useState([
        ...skills_masterlist.sort((a, b) => (a.value < b.value ? -1 : 1)),
    ])

    const handleChange = (selectedOption) => {
        let skillsCopy = []
        selectedOption.map((item) => {
            skillsCopy.push(item.value)
        })
        setSkills(skillsCopy)
    }

    const [skillSearch, setSkillSearch] = useState(null)
    const [isSearcingSkills, setIsSearchingSkill] = useState(false)
    const loadOptions = debounce((inputValue, callback) => {
        if (!!inputValue) {
            configRequest
                .searchSkills({ search: inputValue })
                .then((res) => {
                    console.log(res.data)
                    const selected = skills.map((item) => {
                        return {
                            label: item,
                            value: item,
                        }
                    })
                    const searched = res?.data?.map((item) => {
                        return {
                            label: item,
                            value: item,
                        }
                    })
                    let combined
                    if (searched?.length === 0) {
                        combined = _.uniqBy(
                            [
                                ...selected,
                                { label: inputValue, value: inputValue },
                            ],
                            function (e) {
                                return e.value
                            }
                        )
                    } else {
                        combined = _.uniqBy(
                            [...selected, ...searched],
                            function (e) {
                                return e.value
                            }
                        )
                    }
                    callback(
                        combined.sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setIsSearchingSkill(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsSearchingSkill(false)
                })
        } else {
            const selected = skills.map((item) => {
                return {
                    label: item,
                    value: item,
                }
            })
            const combined = _.uniqBy(
                [...selected, ...skill_list],
                function (e) {
                    return e.value
                }
            )
            if (skills.length > 0) {
                callback(combined.sort((a, b) => (a.value < b.value ? -1 : 1)))
            } else {
                callback(skill_list)
            }
            setIsSearchingSkill(false)
        }
    }, 1000)

    const [skillsCopy2, setSkillsCopy2] = useState(
        skills.map((item) => {
            return {
                label: item,
                value: item,
            }
        })
    )

    // START DATE
    const [startDate, setStartDate] = useState(null)
    // SALARY RANGE
    const [currency, setCurrency] = useState(
        location_?.state?.job?.salaryCurrency || null
    )

    const [salaryMin, setSalaryMin] = useState(
        location_?.state?.job?.salaryMin > 2
            ? location_?.state?.job?.salaryMin
            : null
    )
    const [salaryMax, setSalaryMax] = useState(
        location_?.state?.job?.salaryMax > 2
            ? location_?.state?.job?.salaryMax
            : null
    )

    const [location, setLocation] = useState(
        location_?.state?.job?.location ||
            User?.user?.currentLocation ||
            User?.user?.country ||
            null
    )
    const [error_Location, setError_Location] = useState(null)

    const [country, setCountry] = useState(
        location_?.state?.job?.country || User?.user?.country
    )

    const [experience, setExperience] = useState(
        location_?.state?.job?.totalExperienceMin +
            '-' +
            location_?.state?.job?.totalExperienceMax || null
    )
    const [education, setEducation] = useState(
        location_?.state?.job?.education || []
    )
    const [workSetup, setWorkSetup] = useState(
        location_?.state?.job?.workSetup || []
    )
    const [employmentType, setEmploymentType] = useState(
        location_?.state?.job?.employmentType || []
    )
    const [benefits, setBenefits] = useState(
        location_?.state?.job?.perksAndBenefits || []
    )

    // OLD
    const [companyName, setCompanyName] = useState(
        location_?.state?.job?.companyName || null
    )
    const [error_CompanyName, setError_CompanyName] = useState(null)
    const [experienceRequired, setExperienceRequired] = useState(
        `${location_?.state?.job?.totalExperienceMin}-${location_?.state?.job?.totalExperienceMax}` ||
            '2-2'
    )
    const [error_ExperienceRequired, setError_ExperienceRequired] =
        useState(null)
    const [salaryRange, setSalaryRange] = useState(
        `${location_?.state?.job?.salaryMin}-${location_?.state?.job?.salaryMax}` ||
            null
    )

    console.log('SALARY RANGE::::', location_?.state?.job)

    const [error_SalaryRange, setError_SalaryRange] = useState(null)
    const [food, setFood] = useState(location_?.state?.job?.food || false)
    const [transport, setTransport] = useState(
        location_?.state?.job?.transport || false
    )
    const [housing, setHousing] = useState(
        location_?.state?.job?.housing || false
    )
    const [expectedStartDate, setExpectedStartDate] = useState(
        location_?.state?.job?.expectedStartDate
            ? formatDateOnlyv2(location_?.state?.job?.expectedStartDate)
            : null
    )
    const [error_ExpectedStartDate, setError_ExpectedStartDate] = useState(null)
    const [workShift, setWorkShift] = useState(
        location_?.state?.job?.workShift || null
    )
    const [workWeek, setWorkWeek] = useState(
        location_?.state?.job?.workWeek || null
    )
    const [educationAndExperience, setEducationAndExperience] = useState(
        location_?.state?.job?.educationAndExperience || null
    )

    const [atsMailBox, setAtsMailBox] = useState(
        location_?.state?.job?.atsMailBox
    )
    const [atsMailBoxCopy, setAtsMailBoxCopy] = useState(
        atsMailBox?.map((item) => {
            return {
                label: item,
                value: item,
            }
        })
    )

    const onSelectSalaryRange = (value) => {
        setSalaryRange(value)
        setSalaryMin('')
        setSalaryMax('')
    }

    useEffect(() => {
        console.log(atsMailBoxCopy)
    }, [atsMailBoxCopy])
    // END OF OLD

    useEffect(() => {
        console.log(location_?.state?.job)
    }, [])

    const [currentIndex, setCurrentIndex] = useState(0)
    const [jobFunctions, setJobFunctions] = useState([])

    const [currencyError, setCurrencyError] = useState(false)
    const [salaryMinerror, setSalaryMinError] = useState(false)
    const [salaryMaxerror, setSalaryMaxError] = useState(false)
    const [salaryMaxerror2, setSalaryMaxError2] = useState(false)
    const [workSetupError, setWorkSetupError] = useState(false)
    const [startDateError, setStartDateError] = useState(false)
    const [employmentTypeError, setEmploymentTypeError] = useState(false)
    const [atsError, setAtsError] = useState(false)

    // ERRORS
    const [errors, setErrors] = useState({
        jobTitle: null,
    })

    const [jobFunctionError, setJobFunctionError] = useState(false)
    const [skillsError, setSkillsError] = useState(false)
    const [responsibilitiesError, setResponsibilitiesError] = useState(false)
    const [error, setError] = useState(false)
    useEffect(() => {
        // setSelectedJob(location_?.state?.job)
        const divId = new URLSearchParams(location_.search).get('ref')
        document.getElementById(divId)?.scrollIntoView()
    }, [])

    const validate = () => {
        const isConnect = User?.user?.email === 'connect@joblu.io'

        setError_JobFunction(!jobFunction ? 'Job Function is required' : null)
        setError_JobTitle(!jobTitle ? 'Job Title is required' : null)
        setError_Responsibilities(
            !responsibilities ? 'Responsibilities are required' : null
        )

        if (isConnect)
            // setError_CompanyName(
            //     !companyName ? 'Company name is required' : null
            // )

            setError_Skills(
                skills?.length < 1 ? 'Please enter at least one' : null
            )

        // setError_WorkSetup(!workSetup ? 'Work setup is required' : null)
        // setError_EmploymentType(
        //     !employmentType ? 'Employment type is required' : null
        // )

        // setError_ExperienceRequired(
        //     !experienceRequired ? 'Amount of experience is required' : null
        // )
        // setError_SalaryRange(!salaryRange ? 'Salary range is required' : null)

        if (isConnect) {
            if (
                !jobFunction ||
                skills?.length < 1 ||
                !jobTitle ||
                !responsibilities ||
                !workSetup ||
                !employmentType ||
                !experienceRequired ||
                !salaryRange ||
                error_ExpectedStartDate ||
                !companyName
            ) {
                return false
            } else return true
        } else {
            if (
                !jobFunction ||
                skills?.length < 1 ||
                !jobTitle ||
                !responsibilities ||
                !workSetup ||
                !employmentType ||
                !experienceRequired ||
                !salaryRange ||
                error_ExpectedStartDate
            ) {
                return false
            } else return true
        }
    }

    const validateFields = () => {
        let isValid = []
        if (!jobTitle) {
            setErrors({ ...errors, jobTitle: 'Required' })
            isValid.push(false)
        } else {
            setErrors({ ...errors, jobTitle: false })
            isValid.push(true)
        }
        if (startDate < moment().format('YYYY-MM-DD')) {
            setStartDateError(true)
            isValid.push(false)
        } else {
            setStartDateError(false)
            isValid.push(true)
        }
        if (!jobFunction) {
            setJobFunctionError(true)
            isValid.push(false)
        } else {
            setJobFunctionError(false)
            isValid.push(true)
        }
        if (skills.length < 1) {
            setSkillsError(true)
            isValid.push(false)
        } else {
            setSkillsError(false)
            isValid.push(true)
        }
        if (!responsibilities) {
            setResponsibilitiesError(true)
            isValid.push(false)
        } else {
            setResponsibilitiesError(false)
            isValid.push(true)
        }
        if (!currency) {
            setCurrencyError(true)
            isValid.push(false)
        } else {
            setCurrencyError(false)
            isValid.push(true)
        }
        // if (!salaryMin) {
        //     setSalaryMinError(true)
        //     isValid.push(false)
        // } else {
        //     setSalaryMinError(false)
        //     isValid.push(true)
        // }
        // if (!salaryMax) {
        //     setSalaryMaxError(true)

        //     isValid.push(false)
        // } else {
        //     setSalaryMaxError(false)
        //     isValid.push(true)
        // }
        // if (atsMailBoxCopy.length < 1) {
        //     setAtsError(true)
        //     isValid.push(false)
        // } else {
        //     setAtsError(false)
        //     isValid.push(true)
        // }

        // let sMin
        // let sMax
        // if (salaryMin && salaryMax) {
        //     sMin = removeComma(salaryMin)
        //     sMax = removeComma(salaryMax)
        // }

        // if (sMin > sMax) {
        //     setSalaryMaxError2(true)
        //     isValid.push(false)
        // } else {
        //     setSalaryMaxError2(false)
        //     isValid.push(true)
        // }

        if (!salaryRange) {
            if (salaryMin || salaryMax) {
                setSalaryMinError(!salaryMin ? true : false)
                setSalaryMaxError(!salaryMax ? true : false)
            } else {
                setSalaryMinError(false)
                setSalaryMaxError(false)
                setSalaryRange('2-2')
            }
        } else {
            setSalaryMinError(false)
            setSalaryMaxError(false)
            setSalaryRange('2-2')
        }

        if (
            salaryMin &&
            salaryMax &&
            removeComma(salaryMin) > removeComma(salaryMax)
        ) {
            setSalaryMaxError2(
                'Minimum salary is greater than max salary provided'
            )
            return false
        } else {
            setSalaryMaxError2(false)
        }

        if (workSetup.length < 1) {
            setWorkSetupError(true)
            isValid.push(false)
        } else {
            setWorkSetupError(false)
            isValid.push(true)
        }
        if (employmentType.length < 1) {
            setEmploymentTypeError(true)
            isValid.push(false)
        } else {
            setEmploymentTypeError(false)
            isValid.push(true)
        }
        return isValid.includes(false) ? false : true
    }

    const addCommas = (num) =>
        num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, '')

    const removeComma = (num) => {
        let copy = num?.toString()
        let num1
        if (copy?.indexOf(',') != -1) {
            num1 = parseFloat(num?.replace(/,/g, ''))
            return num1
        } else {
            return parseFloat(copy)
        }
    }

    const handleSalaryMin = (event) => {
        setSalaryMin(addCommas(removeNonNumeric(event.target.value)))
        setSalaryRange(null)
    }

    const handleSalaryMax = (event) => {
        setSalaryMax(addCommas(removeNonNumeric(event.target.value)))
        setSalaryRange(null)
    }

    const [salaryMinTemp, setSalaryMinTemp] = useState(null)
    const [salaryMaxTemp, setSalaryMaxTemp] = useState(null)
    const [saving, setSaving] = useState(false)
    const [publishing, setPublishing] = useState(false)
    const [jobUpdating, setJobUpdating] = useState(false)
    useEffect(() => {
        setSalaryMinTemp(addCommas(salaryMin))
        setSalaryMaxTemp(addCommas(salaryMax))
    }, [salaryMin, salaryMax])

    const [showJobStatus, setShowJobStatus] = useState(null)

    const [showPremiumModal, setShowPremiumModal] = useState(false)
    const saveChanges = (val) => {
        if (User?.subscription?.status !== 'active' && User?.subscription?.status !== 'trialing') {
            // SHOW MODAL HERE
            if (activeJobs.totalCount >= 2) {
                setShowPremiumModal(true)
            } else {
                const isConnect = User?.user?.email === 'connect@joblu.io'
                const draft = location_?.state?.job?.isDraft

                let sMin = removeComma(salaryMin)
                let sMax = removeComma(salaryMax)

                const valid = !val && !!draft ? true : validateFields()

                if (!valid) {
                    console.log('error')
                    setError(true)
                    return
                } else if (val && !validate() && !draft) {
                    console.log('error')
                    setError(true)
                    return
                }

                setIsLoading(true)
                setError(false)

                let salary = salaryRange?.split('-')
                let experience = experienceRequired.split('-')
                let newStartDate = new Date(startDate)

                let payload = {
                    // companyName: User?.user?.companyName,
                    totalExperienceMin: parseInt(experience[0]),
                    totalExperienceMax: parseInt(experience[1]),
                    salaryMin: salaryMin ? sMin : parseInt(salary[0]),
                    salaryMax: salaryMax ? sMax : parseInt(salary[1]),
                    atsMailBox,
                    isDraft: !!val ? false : location_?.state?.job?.isDraft,
                    isPublished: !!val
                        ? true
                        : location_?.state?.job?.isPublished,
                    status: 'active',
                    jobTitle,
                    technicalSkills: skills.filter(
                        (item) => item !== '' && item !== null
                    ),
                    salaryCurrency: currency,
                    employmentType,
                    jobFunction,
                    expectedStartDate: startDate
                        ? newStartDate
                        : expectedStartDate,
                    responsibilities: responsibilities,
                    workSetup,
                    education,
                    perksAndBenefits: benefits,
                    location: location || User?.user?.country,
                    country: country || User?.user?.country,
                }

                if (isConnect) {
                    payload['companyName'] = companyName
                }

                val ? setPublishing(true) : setSaving(true)
                setJobUpdating(true)
                jobsRequest
                    .updateJob(location_?.state?.job?.id, payload)
                    .then((res) => {
                        setShowAlert(true)
                    }, 1000)
                setTimeout(() => {
                    setTimeout(() => {
                        setJobUpdating(false)
                        setIsLoading(false)
                        setShowJobStatus({
                            type: 'under_review',
                            action: () => {
                                navigate('/jobs')
                            },
                        })
                    }, 2000)
                })
                    .catch((e) => {
                        setJobUpdating(false)
                        console.log(e.response.data.message)
                    })
                    .finally(() => {
                        // setTimeout(() => {
                        // setIsLoading(false)
                        // }, 1000)
                    })
            }
        } else {
            const isConnect = User?.user?.email === 'connect@joblu.io'
            const draft = location_?.state?.job?.isDraft

            let sMin = removeComma(salaryMin)
            let sMax = removeComma(salaryMax)

            const valid = !val && !!draft ? true : validateFields()

            if (!valid) {
                console.log('error')
                setError(true)
                return
            } else if (val && !validate() && !draft) {
                console.log('error')
                setError(true)
                return
            }

            setIsLoading(true)
            setError(false)

            let salary = salaryRange?.split('-')
            let experience = experienceRequired.split('-')
            let newStartDate = new Date(startDate)

            let payload = {
                // companyName: User?.user?.companyName,
                totalExperienceMin: parseInt(experience[0]),
                totalExperienceMax: parseInt(experience[1]),
                salaryMin: salaryMin ? sMin : parseInt(salary[0]),
                salaryMax: salaryMax ? sMax : parseInt(salary[1]),
                atsMailBox,
                isDraft: !!val ? false : location_?.state?.job?.isDraft,
                isPublished: !!val ? true : location_?.state?.job?.isPublished,
                status: 'active',
                jobTitle,
                technicalSkills: skills.filter(
                    (item) => item !== '' && item !== null
                ),
                salaryCurrency: currency,
                employmentType,
                jobFunction,
                expectedStartDate: startDate ? newStartDate : expectedStartDate,
                responsibilities: responsibilities,
                workSetup,
                education,
                perksAndBenefits: benefits,
                location: location || User?.user?.country,
                country: country || User?.user?.country,
            }

            if (isConnect) {
                payload['companyName'] = companyName
            }

            val ? setPublishing(true) : setSaving(true)
            setJobUpdating(true)
            jobsRequest
                .updateJob(location_?.state?.job?.id, payload)
                .then((res) => {
                    setTimeout(() => {
                        setShowAlert(true)
                    }, 1000)
                    setTimeout(() => {
                        setIsLoading(false)
                        setJobUpdating(false)
                        setShowJobStatus({
                            type: 'under_review',
                            action: () => {
                                navigate('/jobs')
                            },
                        })
                    }, 2000)
                })
                .catch((e) => {
                    setJobUpdating(false)
                    console.log(e.response.data.message)
                })
                .finally(() => {
                    // setTimeout(() => {
                    // setIsLoading(false)
                    // }, 1000)
                })
        }
    }

    const publishNow = () => {
        const isConnect = User?.user?.email === 'connect@joblu.io'

        if (!validate()) {
            return
        }

        setIsLoading(true)

        let salary = salaryRange.split('-')
        let experience = experienceRequired.split('-')

        let payload = {
            totalExperienceMin: parseInt(experience[0]),
            totalExperienceMax: parseInt(experience[1]),
            salaryMin: parseInt(salary[0]),
            salaryMax: parseInt(salary[1]),
            isDraft: false,
            isPublished: true,
            status: 'active',
            jobTitle: jobTitle,
            skills: skills.filter((item) => item !== '' && item !== null),
            employmentType: employmentType,
            jobFunction: jobFunction,
            responsibilities: responsibilities,
            workSetup: workSetup,
            food: food,
            housing: housing,
            transport: transport,
            workWeek: workWeek,
            workShift: workShift,
            expectedStartDate: expectedStartDate,
            educationAndExperience: educationAndExperience,
            location: location,
        }

        if (isConnect) {
            payload['companyName'] = companyName
        }

        jobsRequest
            .updateJob(location_?.state?.job?.id, payload)
            .then((res) => {
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    navigate('/jobs')
                }, 2000)
            })
            .catch((e) => {
                console.log(e.response.data.message)
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000)
            })
    }

    const addSkill = () => {
        if (!!tempSkill) {
            setSkills([...skills, tempSkill])

            setTempSkill('')
        } else {
            console.log('no skill entered')
            setTempSkill('')
        }
    }

    useEffect(() => {
        if (tempSkill.length === 20) {
            addSkill()
        }
        var input = document.getElementById('skillInputEditjob')

        if (tempSkill.length <= 20 && tempSkill.length >= 2) {
            // Execute a function when the user presses a key on the keyboard
            input.addEventListener('keyup', function (event) {
                // If the user presses the "Enter" key on the keyboard
                if (event.key === 'Enter') {
                    // Cancel the default action, if needed
                    event.preventDefault()
                    // Trigger the button element with a click
                    addSkill()
                }
            })
        } else {
            return
        }
    }, [tempSkill])

    const removeSkill = (idx) => {
        setSkills(skills.filter((item, index) => index !== idx))
    }

    const handleEducationCheck = (e) => {
        var educationCopy = [...education]
        if (e.target.checked) {
            educationCopy = [...education, e.target.value]
        } else {
            educationCopy.splice(education.indexOf(e.target.value), 1)
        }
        setEducation(educationCopy)
    }

    const handleWorkSetupCheck = (e) => {
        var workSetupCopy = [...workSetup]
        if (e.target.checked) {
            workSetupCopy = [...workSetup, e.target.value]
        } else {
            workSetupCopy.splice(workSetup.indexOf(e.target.value), 1)
        }
        setWorkSetup(workSetupCopy)
    }

    const handleEmploymentTypeCheck = (e) => {
        var employmentTypeCopy = [...employmentType]
        if (e.target.checked) {
            employmentTypeCopy = [...employmentType, e.target.value]
        } else {
            employmentTypeCopy.splice(employmentType.indexOf(e.target.value), 1)
        }
        setEmploymentType(employmentTypeCopy)
    }

    const handleBenefitsCheck = (e) => {
        var benefitsCopy = [...benefits]
        if (e.target.checked) {
            benefitsCopy = [...benefits, e.target.value]
        } else {
            benefitsCopy.splice(benefits.indexOf(e.target.value), 1)
        }
        setBenefits(benefitsCopy)
    }

    const createOption = (label, index) => ({
        label,
        value: label,
    })

    const [inputValue, setInputValue] = useState(null)
    const [value, setValue] = useState([])
    const [atsEmailError, setAtsEmailError] = useState(false)
    useEffect(() => {
        if (!inputValue) {
            setAtsEmailError(false)
        }
    }, [inputValue])
    const handleKeyDown = (event) => {
        if (!inputValue) {
            setAtsEmailError(false)
            return
        }
        switch (event.key) {
            case 'Enter':
            case ' ':
                if (!validateEmail(inputValue)) {
                    setAtsEmailError(true)
                    return
                } else {
                    setAtsEmailError(false)
                }
                setAtsMailBoxCopy((prev) => [...prev, createOption(inputValue)])
                setInputValue('')
                event.preventDefault()
        }
    }

    useEffect(() => {
        if (inputValue) {
            const delayDebounceFn = setTimeout(() => {
                if (!validateEmail(inputValue)) {
                    setAtsEmailError(true)
                } else {
                    setAtsEmailError(false)
                    setAtsMailBoxCopy((prev) => [
                        ...prev,
                        createOption(inputValue),
                    ])
                    setInputValue('')
                }
            }, 1000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [inputValue])

    useEffect(() => {
        let dataArray = atsMailBoxCopy.map((a) => a.value)
        setAtsMailBox(_.uniq(dataArray))
    }, [atsMailBoxCopy])

    const [talentSearch, setTalentSearch] = useState(true)

    useEffect(() => {
        if (User?.user) {
            userRequest
                .talentSearchStatus()
                .then((res) => {
                    console.log('taelent search', res.data)
                    setTalentSearch(true)
                })
                .catch(() => {
                    setTalentSearch(false)
                })
        }
    }, [User])

    const [activeJobs, setActiveJobs] = useState([])
    const getActiveJobs = () => {
        let payload = {
            offset: 10,
            limit: 1,
        }
        jobsRequest.getPublishedJobs(payload).then((res) => {
            console.log('aaa', res?.data?.jobs)
            let data = res?.data?.jobs
            // const { totalCount, limit } = data
            setActiveJobs(data)
        })
    }
    useEffect(() => {
        getActiveJobs()
    }, [])
    return (
        <>
            <Dialog
                open={showPremiumModal}
                fullWidth
                maxWidth={'sm'}
                onClose={() => {
                    setShowPremiumModal(false)
                }}
                style={{ zIndex: '99999999' }}
            >
                <Box
                    style={{
                        background: `url('${premiumBg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            textAlign: 'right',
                            padding: '0.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={CloseWhite}
                            width={36}
                            onClick={() => {
                                setShowPremiumModal(false)
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            padding: '0 3rem 3rem 3rem',
                        }}
                    >
                        <JobluTypography.H1 semiBold>Uh-oh!</JobluTypography.H1>
                        <JobluTypography.H1
                            semiBold
                            style={{ marginBottom: '1rem' }}
                        >
                            Need to post more jobs?
                        </JobluTypography.H1>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 20 }}
                        >
                            With our Joblu Premium plan, you can enjoy the
                            luxury of unlimited job postings & many more!
                        </JobluTypography.H4>
                        <JobluTypography.H6
                            semiBold
                            style={{ marginBottom: 20, color: '#D7A048' }}
                        >
                            Upgrade now and unlock endless possibilities for
                            your hiring needs!
                        </JobluTypography.H6>
                        <Button
                            variant="contained"
                            style={{
                                background: '#D7A048',
                                color: '#fff',
                                padding: '1rem 3rem',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                navigate('/pricing')
                            }}
                        >
                            Discover Joblu Premium
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            <div className="create-job-bg" style={{ overFlowY: 'scroll' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="card mt-5 create-job-card">
                                <div className="card-body p-3 mb-md-5">
                                    <div className="mb-3">
                                        <p
                                            className="hand"
                                            onClick={() => {
                                                navigate(
                                                    `/jobs/${location_?.state?.job?.id}`,
                                                    {
                                                        state: {
                                                            job: location_
                                                                ?.state?.job,
                                                        },
                                                    }
                                                )
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-arrow-left me-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                                />
                                            </svg>
                                            {jT}
                                        </p>
                                    </div>
                                    <h4 className="text-muted mb-3">
                                        <strong
                                            onClick={() => {
                                                console.log(
                                                    location_?.state?.job
                                                )
                                            }}
                                        >
                                            Edit Job Post
                                        </strong>
                                    </h4>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Job Title</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>

                                        <TextField
                                            type="text"
                                            required
                                            id="jobTitle"
                                            name="jobTitle"
                                            limit={100}
                                            placeholder="E.g “Graphic Designer for Social Media Marketing”"
                                            onChange={(e) =>
                                                setJobTitle(e.target.value)
                                            }
                                            className="mb-2"
                                            error={errors.jobTitle}
                                            defaultValue={jobTitle}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Job Function</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>
                                        <SelectJobFunction
                                            onChange={(value) => {
                                                setJobFunction(value)
                                            }}
                                            name="jobFunction"
                                            defaultValue={jobFunction}
                                            value={jobFunction}
                                            error={error_JobFunction}
                                            noAll
                                        />
                                        {jobFunctionError && (
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>
                                                What technical skills do you
                                                require?{' '}
                                                <span className="required-tag">
                                                    *
                                                </span>
                                            </strong>
                                            <small className="text-muted d-block">
                                                Search any skills. You can add
                                                up to 10 skills ({skills.length}
                                                /10).
                                            </small>
                                        </label>
                                        <div>
                                            <AsyncSelect
                                                className=""
                                                onChange={handleChange}
                                                isMulti
                                                defaultValue={skillsCopy2}
                                                cacheOptions
                                                defaultOptions
                                                placeholder="Search technical skills..."
                                                loadOptions={loadOptions}
                                                isOptionDisabled={() =>
                                                    skills.length >= 10
                                                }
                                                isLoading={isSearcingSkills}
                                            />
                                        </div>
                                        <div
                                            className={`form-control d-flex flex-wrap d-none ${
                                                error_Skills
                                                    ? 'text-danger border error border-danger'
                                                    : null
                                            } ${
                                                skillsError && 'border-danger'
                                            }`}
                                            id=""
                                        >
                                            {skills?.map((skill, index) => {
                                                return (
                                                    <div className="skills-in-box d-flex p-1">
                                                        <span
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                            className="open-sans-body ps-1"
                                                        >
                                                            {skill}
                                                        </span>
                                                        <div
                                                            className="ms-2 me-1 pe-1 open-sans-body"
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                            role="button"
                                                            onClick={() =>
                                                                removeSkill(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            x
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <textarea
                                                type="text"
                                                className={`flex-grow-1 skills-input `}
                                                onChange={(e) => {
                                                    setTempSkill(e.target.value)
                                                }}
                                                value={tempSkill}
                                                id="skillInputEditjob"
                                                rows={5}
                                            ></textarea>
                                        </div>
                                        {skillsError && (
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Responsibilities</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                            <small className="text-muted d-block">
                                                What does the day-to-day of this
                                                job looks like?
                                            </small>
                                        </label>
                                        <textarea
                                            name=""
                                            className={`form-control br-3 ${
                                                responsibilitiesError &&
                                                'border-danger'
                                            }`}
                                            id=""
                                            cols="30"
                                            rows="5"
                                            onChange={(e) => {
                                                setResponsibilities(
                                                    e.target.value
                                                )
                                            }}
                                            defaultValue={responsibilities}
                                        ></textarea>
                                        {responsibilitiesError && (
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>
                                                Estimated Start Date
                                            </strong>
                                        </label>
                                        <input
                                            type="date"
                                            defaultValue={
                                                expectedStartDate
                                                    ? moment(
                                                          expectedStartDate
                                                      ).format('YYYY-MM-DD')
                                                    : null
                                            }
                                            className={`form-control disabled ${
                                                startDateError &&
                                                'border-danger'
                                            }`}
                                            onChange={(e) => {
                                                setStartDate(e.target.value)
                                            }}
                                            min={moment().format('YYYY-MM-DD')}
                                        />
                                        {startDateError && (
                                            <span className="error-helper">
                                                Invalid date
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Salary (Range)</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-2 mb-4 mb-md-0">
                                                <select
                                                    name=""
                                                    id=""
                                                    className={`form-select br-3 ${
                                                        currencyError &&
                                                        'border-danger'
                                                    }`}
                                                    onChange={(e) => {
                                                        setCurrency(
                                                            e.target.value
                                                        )
                                                    }}
                                                    defaultValue={currency}
                                                >
                                                    <option value="">
                                                        Currency
                                                    </option>
                                                    <option value="PHP">
                                                        PHP
                                                    </option>
                                                    <option value="SGD">
                                                        SGD
                                                    </option>
                                                    <option value="USD">
                                                        USD
                                                    </option>
                                                </select>
                                                {currencyError && (
                                                    <span className="error-helper">
                                                        Required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-5 mb-4 mb-md-0">
                                                <input
                                                    type="text"
                                                    className={`form-control br-3 ${
                                                        salaryMinerror &&
                                                        'border-danger'
                                                    }`}
                                                    placeholder="Minimum"
                                                    onChange={handleSalaryMin}
                                                    value={salaryMinTemp}
                                                />
                                                {salaryMinerror && (
                                                    <span className="error-helper">
                                                        Required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-5">
                                                <input
                                                    type="text"
                                                    className={`form-control br-3 ${
                                                        (salaryMaxerror ||
                                                            salaryMaxerror2) &&
                                                        'border-danger'
                                                    }`}
                                                    placeholder="Maximum"
                                                    onChange={handleSalaryMax}
                                                    value={salaryMaxTemp}
                                                />
                                                {salaryMaxerror && (
                                                    <span className="error-helper">
                                                        Required
                                                    </span>
                                                )}
                                                {salaryMaxerror2 && (
                                                    <span className="error-helper">
                                                        Maximum salary should
                                                        not be lower than the
                                                        minimum.
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            or select one from these options:
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="salaryRange_industry"
                                                        id="2-2"
                                                        value="2-2"
                                                        onChange={(e) => {
                                                            onSelectSalaryRange(
                                                                '2-2'
                                                            )
                                                        }}
                                                        checked={
                                                            salaryRange ===
                                                            '2-2'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="2-2"
                                                    >
                                                        Industry Standard
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="salaryRange_competitive"
                                                        id="1-1"
                                                        value="1-1"
                                                        onChange={(e) => {
                                                            onSelectSalaryRange(
                                                                '1-1'
                                                            )
                                                        }}
                                                        checked={
                                                            salaryRange ===
                                                            '1-1'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="1-1"
                                                    >
                                                        Competitive
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Country or Location</strong>
                                        </label>
                                        {/* {User && (
                                        <SelectCountry2
                                            value={
                                                location
                                                    ? location
                                                    : User?.user.country
                                            }
                                            noCellCode
                                            noAll
                                            placeholder="Select Country"
                                            onChange={(value) => {
                                                setLocation(value)
                                            }}
                                        />
                                    )} */}
                                        <CustomGooglePlaces
                                            onChange={(value) => {
                                                setLocation(value?.location)
                                                setCountry(value?.country)
                                            }}
                                            value={location}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Years of Experience</strong>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="noExp"
                                                        value="0-1"
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                        defaultChecked={
                                                            experience == '0-0'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="noExp"
                                                    >
                                                        0 - 1 year
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="1-3"
                                                        value="1-3"
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                        defaultChecked={
                                                            experience == '1-3'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="1-3"
                                                    >
                                                        1 - 3 years
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="3-5"
                                                        value="3-5"
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                        defaultChecked={
                                                            experience == '3-5'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="3-5"
                                                    >
                                                        3 - 5 years
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="5-7"
                                                        value="5-7"
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                        defaultChecked={
                                                            experience == '5-7'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="5-7"
                                                    >
                                                        5 - 7 years
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="7above"
                                                        value="7-100 "
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                        defaultChecked={
                                                            experience ==
                                                            '7-100'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="7above"
                                                    >
                                                        7 years and above
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Education</strong>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Doctorate (PhD)"
                                                        id="phd"
                                                        defaultChecked={education.includes(
                                                            'Doctorate (PhD)'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="phd"
                                                    >
                                                        Doctorate (PhD)
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Master's Degree"
                                                        id="masters"
                                                        defaultChecked={education.includes(
                                                            "Master's Degree"
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="masters"
                                                    >
                                                        Master’s Degree
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Bachelor's Degree"
                                                        id="bachelors"
                                                        defaultChecked={education.includes(
                                                            "Bachelor's Degree"
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="bachelors"
                                                    >
                                                        Bachelor’s Degree
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Diploma"
                                                        id="diploma"
                                                        defaultChecked={education.includes(
                                                            'Diploma'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="diploma"
                                                    >
                                                        Diploma
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="High School"
                                                        id="highschool"
                                                        defaultChecked={education.includes(
                                                            'High School'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="highschool"
                                                    >
                                                        High School
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Work Setup</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>

                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="On-site"
                                                        id="on-site"
                                                        defaultChecked={workSetup.includes(
                                                            'On-site'
                                                        )}
                                                        onChange={(e) => {
                                                            handleWorkSetupCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="on-site"
                                                    >
                                                        On-Site
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Remote"
                                                        id="remote"
                                                        defaultChecked={workSetup.includes(
                                                            'Remote'
                                                        )}
                                                        onChange={(e) => {
                                                            handleWorkSetupCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="remote"
                                                    >
                                                        Remote
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Hybrid"
                                                        id="hybrid"
                                                        defaultChecked={workSetup.includes(
                                                            'Hybrid'
                                                        )}
                                                        onChange={(e) => {
                                                            handleWorkSetupCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="hybrid"
                                                    >
                                                        Hybrid
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {workSetupError && (
                                            <div>
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Employment Type</strong>
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Full-Time"
                                                        id="full-time"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={employmentType?.includes(
                                                            'Full-Time'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="full-time"
                                                    >
                                                        Full-Time
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Part-Time"
                                                        id="part-time"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={employmentType?.includes(
                                                            'Part-Time'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="part-time"
                                                    >
                                                        Part-Time
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Project-Based"
                                                        id="project-based"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={employmentType?.includes(
                                                            'Project-Based'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="project-based"
                                                    >
                                                        Project-Based
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Freelance"
                                                        id="freelance"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={employmentType?.includes(
                                                            'Freelance'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="freelance"
                                                    >
                                                        Freelance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Internship"
                                                        id="intern"
                                                        defaultChecked={employmentType?.includes(
                                                            'Internship'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="intern"
                                                    >
                                                        Internship
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Highschool Internship"
                                                        id="highschool-intern"
                                                        defaultChecked={employmentType?.includes(
                                                            'Highschool Internship'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="highschool-intern"
                                                    >
                                                        Highschool Internship
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {employmentTypeError && (
                                            <div>
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Perks and Benefits</strong>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Meal Allowance"
                                                        id="meal"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Meal Allowance'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="meal"
                                                    >
                                                        Meal Allowance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Trainings & Seminars"
                                                        id="trainings"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Trainings & Seminars'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="trainings"
                                                    >
                                                        Trainings & Seminars
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Employee Stock Options"
                                                        id="stocks"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Employee Stock Options'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="stocks"
                                                    >
                                                        Employee Stock Options
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Flexible Schedule"
                                                        id="flexible"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Flexible Schedule'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="flexible"
                                                    >
                                                        Flexible Schedule
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Fuel Discount"
                                                        id="fuel"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Fuel Discount'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="fuel"
                                                    >
                                                        Fuel Discount
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Reimbursed Parking Fee"
                                                        id="reimburseParking"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Reimbursed Parking Fee'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="reimburseParking"
                                                    >
                                                        Reimbursed Parking Fee
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Medical Insurance"
                                                        id="medical"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Reimbursed Parking Fee'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="medical"
                                                    >
                                                        Medical Insurance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Gym Membership"
                                                        id="gym"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Gym Membership'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="gym"
                                                    >
                                                        Gym Membership
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Life Insurance"
                                                        id="lifeInsurance"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Life Insurance'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="lifeInsurance"
                                                    >
                                                        Life Insurance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="On-site Parking"
                                                        id="parking"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'On-site Parking'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="parking"
                                                    >
                                                        On-site Parking
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Opportunities for Promotion"
                                                        id="promotion"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Opportunities for Promotion'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="promotion"
                                                    >
                                                        Opportunities for
                                                        Promotion
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Paid Time-Off"
                                                        id="paidOut"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Paid Time-Off'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="paidOut"
                                                    >
                                                        Paid Time-Off
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Internet Allowance"
                                                        id="internet"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Internet Allowance'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="internet"
                                                    >
                                                        Internet Allowance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Government Mandated Benefits"
                                                        id="govertmentBenefits"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Government Mandated Benefits'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="govertmentBenefits"
                                                    >
                                                        Government Mandated
                                                        Benefits
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Transportation Allowance"
                                                        id="transport"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Transportation Allowance'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="transport"
                                                    >
                                                        Transportation Allowance
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Company Car"
                                                        id="companyCar"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Company Car'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="companyCar"
                                                    >
                                                        Company Car
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Performance Bonus"
                                                        id="performanceBonus"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Performance Bonus'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="performanceBonus"
                                                    >
                                                        Performance Bonus
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Paid Training"
                                                        id="paidTraining"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                        defaultChecked={benefits.includes(
                                                            'Paid Training'
                                                        )}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="paidTraining"
                                                    >
                                                        Paid Training
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(User?.subscription?.status == 'active' || User?.subscription?.status == 'trialing') && (
                                        <>
                                            <div className="mb-5">
                                                <label
                                                    htmlFor=""
                                                    className="mb-2"
                                                >
                                                    <strong>
                                                        Job Mail Box
                                                    </strong>
                                                    <small className="text-muted d-block">
                                                        Send applicants directly
                                                        to your ATS Job Mailbox.
                                                    </small>
                                                    <small
                                                        className="text-muted"
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        Press Enter or Spacebar
                                                        to add email
                                                    </small>
                                                </label>
                                                <CreatableSelect
                                                    components={{
                                                        DropdownIndicator: () =>
                                                            null,
                                                    }}
                                                    value={atsMailBoxCopy}
                                                    inputValue={inputValue}
                                                    defaultValue={
                                                        atsMailBoxCopy
                                                    }
                                                    // defaultInputValue={atsMailBoxCopy}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    onChange={(newValue) => {
                                                        setAtsMailBoxCopy(
                                                            newValue
                                                        )
                                                    }}
                                                    onInputChange={(newValue) =>
                                                        setInputValue(newValue)
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                    placeholder="Press Enter or Spacebar to add email"
                                                    className=""
                                                />{' '}
                                                {atsEmailError && (
                                                    <span className="error-helper">
                                                        Please enter valid email
                                                        address.
                                                    </span>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    <div className="">
                                        <div className="d-flex flex-column flex-md-row">
                                            {location_?.state?.job?.isDraft && (
                                                <button
                                                    className="btn btn-primary w-100 mb-2 mb-md-0 me-0 me-md-2 text-center"
                                                    onClick={() => {
                                                        saveChanges('publish')
                                                    }}
                                                >
                                                    {publishing
                                                        ? 'Publishing...'
                                                        : 'Publish'}
                                                </button>
                                            )}
                                            <button
                                                className="btn btn-primary w-100 mb-2 mb-md-0 me-0 me-md-2 text-center"
                                                onClick={() => {
                                                    saveChanges()
                                                }}
                                            >
                                                {saving ? 'Saving...' : 'Save'}
                                            </button>
                                            <button
                                                className="btn btn-outline-primary w-100 text-center"
                                                onClick={() => {
                                                    navigate('/jobs')
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        {error && (
                                            <>
                                                <span className="text-danger small mt-1">
                                                    Saving failed. Please check
                                                    all the fields.
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    {/* <div>
                                    <a className="text-info hand">
                                        Discard this job post.
                                    </a>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={jobUpdating} maxWidth="md">
                    <Box style={{ padding: 100 }}>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 20,
                            }}
                        >
                            <img src={jobPendingIcon} alt="icon" />
                        </Box>
                        <Typography
                            variant="h2"
                            align="center"
                            color="primary"
                            style={{ fontWeight: 600, marginBottom: 30 }}
                        >
                            Your job post is being updated
                        </Typography>
                        <Typography
                            variant="h4"
                            style={{ fontWeight: 400 }}
                            color="primary"
                        >
                            Hang in there! We're already processing your job
                            posting.
                        </Typography>
                    </Box>
                </Dialog>
                <Dialog
                    open={showJobStatus?.type === 'under_review'}
                    maxWidth="md"
                >
                    <Box style={{ padding: 80 }}>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 20,
                            }}
                        >
                            <img src={jobEditSuccess} alt="icon" width={298} />
                        </Box>
                        <Typography
                            variant="h2"
                            align="center"
                            color="primary"
                            style={{ fontWeight: 600, marginBottom: 30 }}
                        >
                            <span style={{ color: '#D7A048' }}> Woohoo!</span>
                            <br />
                            Your job posting is now under review.
                        </Typography>
                        <Typography
                            variant="h4"
                            style={{ fontWeight: 400 }}
                            color="primary"
                            align="center"
                        >
                            It's officially in the books and our team of
                            eagle-eyed experts is giving it the once-over. Sit
                            tight, and we'll let you know as soon as it's live.
                        </Typography>

                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 50,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: 'none',
                                    minWidth: 327,
                                    fontWeight: 600,
                                }}
                                onClick={() => {
                                    showJobStatus?.action()
                                }}
                            >
                                Go to My Jobs
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
export default connect(mapStateToProps, null)(CreateJob)
