import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import notificationActions from '../../redux/notifications/action'
import { userRequest, recommendationsRequest, jobMatchRequest } from '../../service/requests'
import { profilePlaceholder_Applicant } from '../../utils/constants'
import './styles.css'
import RecommendationItem from './components/RecommendationItem'
import FlatList from '../../components/flatlist/FlatList'
import Profile from '../../components/reusables/Profile/Profile'
import { errorSwipeActions } from '../../utils/constants'
import ExploreModal from '../explore/components/ExploreModal'
import DefaultImg from '../../assets/img/defaultImg.png'
import { Modal } from 'react-bootstrap'

const MessageSentModal = (props) => {
    const { showSentModal, handleCloseSentModal } = props
    return (
        <>
            <Modal show={showSentModal} centered onHide={handleCloseSentModal}>
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {/* {modalHeader} */}
                            Candidate has been <strong>Superliked</strong>
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-4  " style={{ fontSize: 20 }}>
                            <strong>
                                <span className="text-primary">
                                    Message Sent!
                                </span>
                            </strong>
                        </p>
                        <div className="mb-4">
                            <p style={{ fontSize: 20 }}>
                                Now let’s just wait for the candidate’s
                                response.
                            </p>
                        </div>

                        <div className="d-flex justify-content-center">
                            <button
                                className={`btn text-center btn-primary w-50`}
                                onClick={() => {
                                    handleCloseSentModal()
                                }}
                            >
                                Okay
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
const SuperLikeModal = (props) => {
    const {
        selectedApplicant,
        handleCloseConfirmModal,
        showConfirmModal,
        sendMessage,
        setMessage,
        sendMessageError,
        messageError,
        message
    } = props
    return (
        <>
            <Modal
                show={showConfirmModal}
                centered
                onHide={handleCloseConfirmModal}
            >
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {/* {modalHeader} */}
                            Superlike Candidate
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-3" style={{ fontSize: 20 }}>
                            You are about to{' '}
                            <strong>
                                <span className="text-primary">Superlike</span>
                            </strong>
                        </p>
                        <div className="mb-3">
                            <>
                                <img
                                    src={
                                        selectedApplicant?.profileUrl ||
                                        DefaultImg
                                    }
                                    alt=""
                                    className="rounded-circle mb-3"
                                    style={{
                                        width: 90,
                                        height: 90,
                                        objectFit: 'cover',
                                    }}
                                />
                            </>
                            <div className="mb-3">
                                <p
                                    className="text-capitalize"
                                    style={{ fontSize: 30, fontWeight: 600 }}
                                >
                                    {selectedApplicant?.firstName}{' '}
                                    {selectedApplicant?.lastName}
                                </p>
                                <>
                                    <p
                                        style={{
                                            fontSize: 20,
                                            color: '#009CDE',
                                        }}
                                    >
                                        {selectedApplicant?.jobFunction}
                                        {/* {selectedJobData?.jobTitle} */}
                                    </p>
                                </>
                            </div>
                        </div>

                        <div className="mb-4 text-start">
                            <label htmlFor="">
                                <small className="text-muted">
                                    Send a message!
                                </small>
                            </label>
                            <textarea
                                name=""
                                className={`form-control ${
                                    messageError && 'border-danger'
                                }`}
                                id=""
                                cols="30"
                                rows="3"
                                value={message || ''}
                                onChange={(e) => {
                                    if(e.target.value.length <= 240){
                                        setMessage(e.target.value)
                                    }
                                }}
                            ></textarea>
                            <div className="text-end">
                                <span className="text-muted"><small>{message?.length || 0}/240</small></span>
                            </div>
                            {messageError && (
                                <>
                                    <span className="error-helper">
                                        Message for applicant is required
                                    </span>
                                </>
                            )}
                            {sendMessageError && (
                                <>
                                    <div
                                        class="alert alert-danger text-center mt-2"
                                        role="alert"
                                    >
                                        <small>
                                            Something went wrong while trying to
                                            send a message.
                                        </small>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="d-flex">
                            <button
                                className={`btn text-center btn-outline-primary w-50 me-2`}
                                onClick={() => {
                                    handleCloseConfirmModal()
                                }}
                            >
                                Cancel
                            </button>

                            <button
                                className="btn btn-primary w-50"
                                onClick={() => {
                                    sendMessage()
                                }}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

function Recommendations(props) {
    const { User } = props
    const [isGettingMoredata, setIsGettingMoreData] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [loadMoreData, setLoadMoreData] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)


    const [recommendations, setRecommendations] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [matchImages, setMatchImages] = useState(null)
    const [applicantName, setApplicantName] = useState(null)

    const [jobTitle, setJobTitle] = useState(null)

    const navigate = useNavigate()

    // const viewProfile = (item) => {
    //     setSelectedUser(item)
    //     setShowProfile(!showProfile)
    //     document.getElementsByTagName('BODY')[0].style.overflow = 'hidden'
    // }

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState(false)
    const [jobId, setJobId] = useState(null)
    const [applicantId, setApplicantId] = useState(null)
    const [sendMessageError, setSendMessageError] = useState(false)

    const [selectedApplicant, setSelectedApplicant] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null)
    const [showSentModal, setShowSentModal] = useState(false)
    const handleCloseSentModal = () => setShowSentModal(false)

    const [selectedJobId, setSelectedJobId] = useState(null)

    const sendMessage = () => {
        if (!message) {
            setMessageError(true)
            return
        }
        let card = document.getElementById(selectedCard)
        setMessageError(false)
        const payload = {
            action: 'superLike',
            applicantId: selectedApplicant.userId,
            jobId: selectedJobId,
            message,
        }
        userRequest
            .employerSuperLike(payload)
            .then((res) => {
                setShowConfirmModal(false)
                setTimeout(() => {
                    card.classList.add('slide-out-top')
                    getRecommendations()
                }, 500)

                setTimeout(() => {
                    card.classList.add('d-none')
                    setShowSentModal(true)
                    setSendMessageError(false)
                    setMessage(null)
                }, 1000)
            })
            .catch((err) => {
                console.log(err)
                setSendMessageError(true)
            })
    }

    const getRecommendations = () => {
        setIsFetching(true)
        recommendationsRequest.getRecommendationRef()
        .then((res) => {
            const data = res?.data?.data
            console.log(res?.data?.totalCount)
            recommendationsRequest.getRecommendationList({
                applicants: data?.map(item => {
                    return item.applicantId
                })
            })
            .then(response => {
                console.log(response?.data)
                // setRecommendations(response?.data?.data)
                const processed = data?.map(item => {
                    const temp = response?.data?.data?.find(obj => obj.userId === item?.applicantId)
                    return {
                        ...item,
                        applicant: temp,
                    }
                })
                setRecommendations(processed)
            })
    
        })
        .catch(e => {
            console.log(e)
            setIsFetching(false)
        })
        .finally(() => {
            setTimeout(() => {
                setIsFetching(false)
            }, 1000)
        })
    }

    useEffect(() => {
        getRecommendations()
     }, [])


    const renderRecommendations = (item, index) => {
        console.log(item)
        return (
            <RecommendationItem
                key={index}
                // handleView={() => viewProfile(item?.applicant)}
                data={item}
                itemId={index}
                hasSwipeActions
                swipeAction={(payload) => swipeAction(payload)}
            />
        )
    }

    

    const swipeAction = (payload) => {
        console.log(payload)
        const { job, applicant, action, itemId } = payload
        let applicant_item = document.getElementById(`${itemId}`)
        setSelectedCard(`${itemId}`)
        setSelectedJobId(job._id)
        jobMatchRequest
            .employerSwipe({
                jobId: job._id,
                applicantId: applicant.userId,
                action: action,
            })
            .then((res) => {
                console.log(res.data)
                if (
                    res.data.message ===
                    errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfLikes')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle('You have run out of likes today!')
                    // setSwipeLimitText('Go back tomorrow to start liking again.')
                    // setShowSwipeLimit(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfMonthlyLikes')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle(
                    //     'You have run out of likes for this month!'
                    // )
                    // setSwipeLimitText('You can like again next month.')
                    // setShowSwipeLimit(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfSuperLikes')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle('You have run out of superlikes!')
                    // setSwipeLimitText('You can superlike again next month.')
                    // setShowSwipeLimit(true)
                } else if (
                    res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED
                ) {
                    setModalVariant('upgradeAccount')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle(
                    //     'Please upgrade account to start swiping!'
                    // )
                    // setSwipeLimitText(
                    //     'You are currently using a FREE account. Upgrade now to like this applicant.'
                    // )
                    // setShowSwipeLimit(true)
                } else {
                    if (res.data == true) {
                        setMessageError(false)
                        setSendMessageError(false)
                        setSelectedApplicant(applicant)
                        setShowConfirmModal(true)
                        setMessage(null)
                        return
                    }
                    
                    if (action === 'not_interested') {
                        applicant_item.classList.add('slide-out-left')
                    } else if (action === 'superLike') {
                        applicant_item.classList.add('slide-out-top')
                    } else if (action === 'like') {
                        applicant_item.classList.add('slide-out-right')
                    }
                    setTimeout(() => {
                        getRecommendations()
                    }, 500)
                }

                if (res.data.matchStatus === 'match') {
                    // alert('MATCHED!!')
                    const images = {
                        employer: res.data.profileUrl_employer,
                        applicant: res.data.profileUrl_applicant,
                    }

                    setMatchImages(images)
                    setModalVariant('matchModal')
                    setModalOpen(true)
                    setApplicantName(
                        applicant.firstName + ' ' + applicant.lastName
                    )
                    setJobTitle(job.jobTitle)
                }
                // navigation.goBack()
            })
            .catch((err) => {
                console.log(err)
            })
    }



    return (
        <>
         <MessageSentModal
                showSentModal={showSentModal}
                handleCloseSentModal={handleCloseSentModal}
            />
            <SuperLikeModal
                showConfirmModal={showConfirmModal}
                handleCloseConfirmModal={handleCloseConfirmModal}
                selectedApplicant={selectedApplicant}
                sendMessage={sendMessage}
                setMessage={setMessage}
                sendMessageError={sendMessageError}
                messageError={messageError}
                message={message}
            />
        <div className="row justify-content-center mx-0">
            <Profile
                showProfile={showProfile}
                setShowProfile={setShowProfile}
                selectedProfile={selectedUser}
                currentTab={'likes'}
            />
            <div className="border-bottom mb-3">
                <div className="container ">
                    <div className="justify-content-between align-items-center row">
                        <div className="col-sm-12 col-md-3 my-3">
                            <h5 className="text-body fw500 mb-2 ">Recommendations</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-9">
                <FlatList
                    renderItem={renderRecommendations}
                    data={recommendations?.filter((item => item?.applicant !== undefined))}
                    loadMoreItems={() => alert('hello')}
                    // hasMoreItems={loadMoreLikedMe}
                    list={'recommendations'}
                />
                {/* {notificationsList?.data?.length <
                    notificationsList?.totalCount &&
                    notificationsList?.data?.length < 100 && (
                        <>
                            {!!notificationsList?.data?.length && (
                                <div className="text-center">
                                    <button
                                        className="btn btn-primary my-3 text-center"
                                        onClick={() => getMoreNotifications()}
                                    >
                                        {isGettingMoredata && (
                                            <span
                                                className="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                        Load More
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                {!notificationsList?.data?.length && (
                    <div className="mt-5 ">
                        <h6 role="button" className="text-center">
                            No notifications
                        </h6>
                    </div>
                )} */}
            </div>
            <ExploreModal
                showModal={modalOpen}
                variant={modalVariant}
                matchImages={matchImages}
                setModalVariant={setModalVariant}
                setShowModal={setModalOpen}
                applicantName={applicantName}
                jobTitle={jobTitle}
            />
        </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
        Notifications: state.Notifications,
    }
}

const mapDispatchToProps = {
    getNotifications: notificationActions.getNotifications,
}

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations)
