import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    Dialog,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import moment from 'moment'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import premiumBg from '../../../assets/img/createJob/Go_Premium_BG.png'
import jobEditSuccess from '../../../assets/img/createJob/job-edit-message.png'
import emptyImg from '../../../assets/img/explore/emptyImg.svg'
import gridActiveIcon from '../../../assets/img/explore/gridActiveIcon.png'
import gridIcon from '../../../assets/img/explore/gridIcon.png'
import listActiveIcon from '../../../assets/img/explore/listActiveIcon.png'
import listIcon from '../../../assets/img/explore/listIcon.png'
import CloseWhite from '../../../assets/img/CloseWhite.png'
import CustomGooglePlaces from '../../../components/google-places'
import TextField from '../../../components/inputs/CustomTextField'
import CreateJobButton from '../../../components/reusables/CreateJobButton'
import SelectJobFunction from '../../../components/reusables/SelectJobFunction'
import SelectJobPost from '../../../components/reusables/SelectJobPost'
import { jobsRequest, userRequest } from '../../../service/requests'
import useWindowDimensions from '../../../utils/hooks'
import Filter from './components/Filter/Filter'
import ExploreGridViewCard2 from './components/GridView/ExploreGridViewV2'
import ExploreListviewCard from './components/ListView/ExploreListView'
import { ProfileModal } from './components/ViewProfile/ProfileModal'
import { SlidingProfile } from './components/ViewProfile/SlidingProfile'
import GridLoader from './components/loaders/GridLoader'
import SpinProgress from './components/loaders/SpinProgress'
import JobluTypography from '../../../components/Typography'
import { LargeBanner, SmallBanner } from '../../../components/adbanner'
const SkillsPill = ({ onDelete, index, label }) => {
    return (
        <span
            style={{ background: '#E9EBEC' }}
            className="pill rounded-pill fs-14 px-2 py-1 open-sans"
        >
            {label}
            {onDelete && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className=" ms-2 bi bi-x-circle"
                    viewBox="0 0 16 16"
                    role="button"
                    onClick={() => onDelete(index)}
                >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            )}
        </span>
    )
}
const ExploreV2 = ({
    jobSeekers,
    setFilters,
    filters,
    handleFilter,
    onSwipeFulfilled,
    handleSetWorkPref,
    handleWorkSetup,
    setJobTitleQuery,
    perPage,
    totalData,
    paginate,
    setPage,
    postsPerPage,
    currentList,
    currentPage,
    setCurrentPage,
    loadMore,
    currencyList,
    setYearsOfExp,
    setSalaryMin,
    setSalaryMax,
    setSalaryCurrency,
    isLoading,
    currency,
    AppState,
    handleSalaryMin,
    handleSalaryMax,
    salaryMin,
    salaryMax,
    User,
    totalCount,
    getJobSeekers,
    scrollerRef,
    handleClearFilters,
}) => {
    const navigate = useNavigate()
    const [isViewProfile, setIsViewProfile] = useState(false)
    const [jobseeker, setJobseeker] = useState(null)
    const [viewType, setViewType] = useState('grid')
    const [currentLists, setCurrentLists] = useState(currencyList)
    const [show, setShow] = useState(false)
    const [allowed_for_talent_search, setAllowTalentSearch] = useState(true)
    const { width } = useWindowDimensions()

    useEffect(() => {
        console.log('isLoading =>', isLoading)
    }, [isLoading])

    useEffect(() => {
        console.log('filters in v2 => ', filters)
    }, [filters])

    useEffect(() => {
        if (User?.user) {
            userRequest
                .talentSearchStatus()
                .then((res) => {
                    console.log('taelent search', res.data)
                    setAllowTalentSearch(true)
                })
                .catch(() => {
                    setAllowTalentSearch(false)
                })
        }
    }, [User])

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    useEffect(() => {
        setCurrentLists(currentList)
    }, [currentList])

    const checkbox_filters = [
        {
            title: 'Work Preference',
            key: 'employementType',
            options: [
                {
                    title: 'Full-Time',
                    value: 'FullTime',
                },
                {
                    title: 'Part-Time',
                    value: 'PartTime',
                },
                {
                    title: 'Project-Based',
                    value: 'Project Based',
                },
                {
                    title: 'Internship',
                    value: 'Internship',
                },
            ],
        },
        {
            title: 'Work Setup',
            key: 'workSetup',
            options: [
                {
                    title: 'On-Site',
                    value: 'On-Site',
                },
                {
                    title: 'Remote',
                    value: 'Remote',
                },
                {
                    title: 'Hybrid',
                    value: 'Hybrid',
                },
            ],
        },
    ]

    const [talent_access_requested, setTalentRequested] = useState(false)
    const [request_loading, setRequestLoading] = useState(false)
    const [allow_send_talent_request, setAllowSendRequest] = useState(true)

    const [isInfiniteScrollLoading, setInfiniteScrollLoading] = useState(false)

    const loadInfinite = () => {
        if (allowed_for_talent_search) {
            if (!isInfiniteScrollLoading && !isLoading) {
                console.log(
                    'laoding more \n ',
                    'total count ',
                    totalCount,
                    '\n total in list ',
                    jobSeekers?.length,
                    currentPage,
                    isLoading
                )
                if (
                    jobSeekers.length < totalCount &&
                    currentPage <= Math.ceil(totalCount / 24)
                ) {
                    setCurrentPage(currentPage + 1)
                    const cleanedFilters = Object.fromEntries(
                        Object.entries(filters).filter(([key, value]) => {
                            if (value === null) return false
                            if (typeof value === 'string' && value.length < 1)
                                return false
                            if (Array.isArray(value) && value.length < 1)
                                return false
                            return true
                        })
                    )

                    let payload = {
                        ...cleanedFilters,
                        offset: String(currentPage + 1),
                    }
                    if (!payload?.jobId) delete payload.jobId
                    setInfiniteScrollLoading(true)
                    getJobSeekers(payload, null, true, () => {
                        console.log('callback is called')
                        setInfiniteScrollLoading(false)
                    })
                }
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem('talent_request')) {
            const expiration_data = JSON.parse(
                localStorage.getItem('talent_request')
            )
            if (
                moment() < moment(expiration_data?.expires_in) &&
                User?.user?._id === expiration_data?.user
            ) {
                console.log('do not allow resend request')
                setAllowSendRequest(false)
            } else {
                setAllowSendRequest(true)
                localStorage.removeItem('talent_request')
            }
        }
    }, [localStorage.getItem('talent_request'), User?.user])

    useEffect(() => {
        console.log('infinite total count', totalCount)
    }, [totalCount])

    const [jobLimitModal, setJobLimitModal] = useState(false)
    const [activeJobs, setActiveJobs] = useState([])
    const getActiveJobs = () => {
        let payload = {
            offset: 10,
            limit: 1,
        }
        jobsRequest.getPublishedJobs(payload).then((res) => {
            let data = res?.data?.jobs
            // const { totalCount, limit } = data
            setActiveJobs(data)
        })
    }
    useEffect(() => {
        getActiveJobs()
    }, [])
    return (
        <>
            <Dialog
                open={jobLimitModal}
                fullWidth
                maxWidth={'sm'}
                onClose={() => {
                    setJobLimitModal(false)
                }}
                style={{ zIndex: '99999999' }}
            >
                <Box
                    style={{
                        background: `url('${premiumBg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            textAlign: 'right',
                            padding: '0.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={CloseWhite}
                            width={36}
                            onClick={() => {
                                setJobLimitModal(false)
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            padding: '0 3rem 3rem 3rem',
                        }}
                    >
                        <JobluTypography.H1 semiBold>Uh-oh!</JobluTypography.H1>
                        <JobluTypography.H1
                            semiBold
                            style={{ marginBottom: '1rem' }}
                        >
                            Need to post more jobs?
                        </JobluTypography.H1>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 20 }}
                        >
                            With our Joblu Premium plan, you can enjoy the
                            luxury of unlimited job postings & many more!
                        </JobluTypography.H4>
                        <JobluTypography.H6
                            semiBold
                            style={{ marginBottom: 20, color: '#D7A048' }}
                        >
                            Upgrade now and unlock endless possibilities for
                            your hiring needs!
                        </JobluTypography.H6>
                        <Button
                            variant="contained"
                            style={{
                                background: '#D7A048',
                                color: '#fff',
                                padding: '1rem 3rem',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                navigate('/pricing')
                            }}
                        >
                            Discover Joblu Premium
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
                isFromExplore={true}
            />

            <div className="row px-0">
                <div className="col-12 row justify-content-center mx-0 px-0 position-relative">
                    {allowed_for_talent_search &&
                        User?.user?.credits_likes < 1 &&
                        User?.user?.credits_superlikes < 1 &&
                        !User?.user?.subscription && (
                            <>
                                <Box
                                    style={{
                                        position: 'absolute',
                                        zIndex: 999,
                                        minHeight: 400,
                                        minWidth: width < 654 ? 200 : 400,
                                        maxWidth: width < 654 ? '90%' : 815,
                                        borderRadius: 30,
                                        marginTop: 150,
                                        padding: width < 654 ? 30 : 80,
                                        background: `url(${premiumBg})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'bottom center',
                                    }}
                                >
                                    <Typography
                                        align="center"
                                        style={{
                                            color: '#fff',
                                            fontWeight: 600,
                                            fontSize: width < 640 ? 40 : 40,
                                            fontFamily: 'Poppins',
                                        }}
                                    >
                                        Out of likes? No problem!
                                    </Typography>
                                    <Typography
                                        variant={`${width < 640 ? 'h6' : 'h6'}`}
                                        align="center"
                                        style={{
                                            color: '#fff',
                                            fontWeight: 700,
                                            marginTop: 30,
                                        }}
                                    >
                                        You've used up all your Like credits,
                                        but the perfect candidate is still out
                                        there! With Joblu Premium, not only you
                                        will enjoy unlimited job postings but
                                        you can also like more talent and get up
                                        to 300 matches per job post.
                                    </Typography>
                                    {/* <Typography
                                    variant={`${width < 640 ? 'h6' : 'h5'}`}
                                    align="center"
                                    style={{
                                        color: '#EBCFA3',
                                        fontWeight: 800,
                                        marginTop: 30,
                                        lineHeight: '37px',
                                    }}
                                >
                                    This offer won't last forever. Grab it
                                    before it's too late!
                                </Typography> */}
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Button
                                            style={{
                                                background: '#D7A048',
                                                fontSize: width < 640 ? 18 : 18,
                                                fontFamily: 'poppins',
                                                borderRadius: 10,
                                                padding: width < 640 ? 10 : 15,
                                                marginTop: 30,
                                                minWidth: 300,
                                                textTransform: 'none',
                                                color: '#fff',
                                            }}
                                            className="shadow-none"
                                            onClick={() => {
                                                navigate('/pricing')
                                            }}
                                        >
                                            {request_loading ? (
                                                <CircularProgress />
                                            ) : !allow_send_talent_request ? (
                                                'Upgrade and Unlock UNLIMITED Likes'
                                            ) : (
                                                'Upgrade and Unlock UNLIMITED Likes'
                                            )}
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    className="blurry d-flex"
                                    style={{
                                        position: 'absolute',

                                        // background:
                                        //     'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 54%, rgba(255,255,255,0.25253851540616246) 100%)',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 998,
                                        // background:
                                        //     talent_access_requested && '#001225',
                                        // mixBlendMode:
                                        //     talent_access_requested && 'multiply',
                                        // opacity: talent_access_requested && 0.5,
                                        // filters: `blur("4px")`,
                                    }}
                                />
                            </>
                        )}
                    {!allowed_for_talent_search && (
                        <>
                            {!talent_access_requested ? (
                                <>
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            zIndex: 999,
                                            minHeight: 400,
                                            minWidth: width < 654 ? 200 : 400,
                                            maxWidth: width < 654 ? '90%' : 815,
                                            borderRadius: 30,
                                            marginTop: 150,
                                            padding: width < 654 ? 30 : 80,
                                            background: `url(${premiumBg})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'bottom center',
                                        }}
                                    >
                                        <Typography
                                            align="center"
                                            style={{
                                                color: '#fff',
                                                fontWeight: 600,
                                                fontSize: width < 640 ? 40 : 50,
                                                fontFamily: 'Poppins',
                                            }}
                                        >
                                            Upgrade Your Hiring Experience
                                        </Typography>
                                        <Typography
                                            variant={`${
                                                width < 640 ? 'h6' : 'h4'
                                            }`}
                                            align="center"
                                            style={{
                                                color: '#fff',
                                                fontWeight: 700,
                                                marginTop: 30,
                                            }}
                                        >
                                            Why pay more for{' '}
                                            <span
                                                style={{
                                                    textDecoration:
                                                        'line-through',
                                                }}
                                            >
                                                the same
                                            </span>{' '}
                                            a better service? Access top
                                            candidates, post unlimited jobs, and
                                            more for a fraction of what others
                                            charge you.
                                        </Typography>
                                        <Typography
                                            variant={`${
                                                width < 640 ? 'h6' : 'h5'
                                            }`}
                                            align="center"
                                            style={{
                                                color: '#EBCFA3',
                                                fontWeight: 800,
                                                marginTop: 30,
                                                lineHeight: '37px',
                                            }}
                                        >
                                            This offer won't last forever. Grab
                                            it before it's too late!
                                        </Typography>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    background: '#D7A048',
                                                    fontSize:
                                                        width < 640 ? 18 : 24,
                                                    fontFamily: 'poppins',
                                                    borderRadius: 10,
                                                    padding:
                                                        width < 640 ? 10 : 15,
                                                    marginTop: 20,
                                                    minWidth: 300,
                                                    textTransform: 'none',
                                                    color: '#fff',
                                                }}
                                                className="shadow-none"
                                                // disabled={
                                                //     !allow_send_talent_request
                                                // }
                                                onClick={() => {
                                                    navigate('/pricing')
                                                    // if (
                                                    //     !request_loading &&
                                                    //     allow_send_talent_request
                                                    // ) {
                                                    //     setRequestLoading(true)
                                                    //     //handle request for access
                                                    //     userRequest
                                                    //         .requestTalentSearch()
                                                    //         .then((res) => {
                                                    //             setTimeout(
                                                    //                 () => {
                                                    //                     setTalentRequested(
                                                    //                         true
                                                    //                     )
                                                    //                     setRequestLoading(
                                                    //                         false
                                                    //                     )
                                                    //                     localStorage.setItem(
                                                    //                         'talent_request',
                                                    //                         JSON.stringify(
                                                    //                             {
                                                    //                                 expires_in:
                                                    //                                     moment().add(
                                                    //                                         1,
                                                    //                                         'day'
                                                    //                                     ),
                                                    //                                 user: User
                                                    //                                     ?.user
                                                    //                                     ?._id,
                                                    //                             }
                                                    //                         )
                                                    //                     )
                                                    //                 },
                                                    //                 3000
                                                    //             )
                                                    //         })
                                                    //         .catch(() => {
                                                    //             setTimeout(
                                                    //                 () => {
                                                    //                     setTalentRequested(
                                                    //                         false
                                                    //                     )
                                                    //                     setRequestLoading(
                                                    //                         false
                                                    //                     )
                                                    //                 },
                                                    //                 3000
                                                    //             )
                                                    //         })
                                                    // }
                                                }}
                                            >
                                                {request_loading ? (
                                                    <CircularProgress />
                                                ) : !allow_send_talent_request ? (
                                                    'Get Premium'
                                                ) : (
                                                    'Get Premium'
                                                )}
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            background:
                                                talent_access_requested &&
                                                '#001225',
                                            mixBlendMode:
                                                talent_access_requested &&
                                                'multiply',
                                            opacity:
                                                talent_access_requested && 0.5,
                                            position: 'absolute',
                                            zIndex: 9001,
                                        }}
                                    />

                                    <Box
                                        style={{
                                            position: 'absolute',
                                            zIndex: 9002,

                                            maxWidth: width < 640 ? '90%' : 815,
                                            borderRadius: 30,
                                            marginTop: 50,
                                            padding:
                                                width < 640
                                                    ? '30px 50px '
                                                    : '50px 80px',
                                            background: '#fff',
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                padding: 20,
                                            }}
                                        >
                                            <img
                                                src={jobEditSuccess}
                                                alt="icon"
                                                width={width < 640 ? 150 : 298}
                                                s
                                            />
                                        </Box>
                                        <Typography
                                            variant="h2"
                                            align="center"
                                            color="primary"
                                            style={{
                                                fontWeight: 600,
                                                marginBottom: 30,
                                            }}
                                        >
                                            <span style={{ color: '#D7A048' }}>
                                                {' '}
                                                Request sent!{' '}
                                            </span>
                                            <br />
                                            We've got it, and we're on it. Sit
                                            tight, we'll help you make <br />{' '}
                                            some great hires!
                                        </Typography>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: 50,
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    textTransform: 'none',
                                                    minWidth: 327,
                                                    fontWeight: 600,
                                                    fontSize: 20,
                                                }}
                                                onClick={() => {
                                                    // showJobStatus?.action()
                                                    setTalentRequested(false)
                                                }}
                                            >
                                                Got it!
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            )}
                            <Box
                                className="blurry d-flex"
                                style={{
                                    position: 'absolute',

                                    // background:
                                    //     'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 54%, rgba(255,255,255,0.25253851540616246) 100%)',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 998,
                                    // background:
                                    //     talent_access_requested && '#001225',
                                    // mixBlendMode:
                                    //     talent_access_requested && 'multiply',
                                    // opacity: talent_access_requested && 0.5,
                                    // filters: `blur("4px")`,
                                }}
                            />
                        </>
                    )}
                    <div
                        className="col-sm-12 row justify-content-center py-2 shadow-sm p-0 mx-0"
                        style={{
                            background: '#fff',
                            position: 'fixed',
                            zIndex: 999,
                        }}
                    >
                        <div
                            className={`${
                                User?.subscription?.status !== 'active' ||
                                User?.subscription?.status !== 'trialing'
                                    ? 'col-6'
                                    : 'col-12 mb-2'
                            }  d-block d-md-none `}
                        >
                            <div class="d-flex">
                                {(User?.subscription?.status !== 'active' &&
                                    User?.subscription?.status !==
                                        'trialing') && (
                                    <>
                                        <Tooltip
                                            title={
                                                <span
                                                    style={{ fontSize: 12 }}
                                                    className="text-center"
                                                >
                                                    Use your like credits to
                                                    signal your interest with
                                                    the candidate's profile and
                                                    qualifications.
                                                </span>
                                            }
                                            placement="bottom"
                                        >
                                            <button
                                                style={{
                                                    fontSize: 18,
                                                    background: 'none',
                                                    border: '1px solid #36D633',
                                                }}
                                                className="w-50 me-2 mb-md-0 btn btn-outline-secondary "
                                            >
                                                <img
                                                    src={require('../../../assets/img/explore/LikeCreditIcon.jpg')}
                                                    width={18}
                                                    alt=""
                                                    className="mb-1 me-2"
                                                />
                                                <span
                                                    style={{
                                                        color: '#36D633',
                                                    }}
                                                >
                                                    {User?.user?.credits_likes}
                                                </span>{' '}
                                            </button>
                                        </Tooltip>
                                    </>
                                )}

                                <Tooltip
                                    title={
                                        <span
                                            style={{ fontSize: 12 }}
                                            className="text-center"
                                        >
                                            Use Superlike credits to instantly
                                            match and start a conversation with
                                            a candidate to speed up the hiring
                                            process.
                                        </span>
                                    }
                                    placement="bottom"
                                >
                                    <button
                                        style={{
                                            fontSize: 14,
                                            background: 'none',
                                            border: '1px solid #D7A048!important',
                                        }}
                                        className={`${
                                            User?.subscription?.status !==
                                                'active' ||
                                            User?.subscription?.status !==
                                                'trialing'
                                                ? 'w-100'
                                                : 'w-5-'
                                        } me-1 mb-md-0 btn btn-secondary superlike-credits`}
                                    >
                                        <img
                                            src={require('../../../assets/img/explore/SuperLikeCreditIcon.jpg')}
                                            width={18}
                                            alt=""
                                            className="mb-1 me-2"
                                        />
                                        <span
                                            style={{
                                                color: '#D7A048',
                                            }}
                                        >
                                            {User?.user?.credits_superLikes}
                                        </span>{' '}
                                    </button> 
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            className={`${
                                User?.subscription?.status !== 'active' ||
                                User?.subscription?.status !== 'trialing'
                                    ? 'col-6'
                                    : 'col-12'
                            } d-block d-md-none`}
                        >
                            
                            <button
                                className="btn text-white me-0 me-md-3 btn mb-md-0 w-100"
                                style={{
                                    backgroundColor: '#D7A048',
                                    fontSize: 14,
                                    fontFamily: 'Open Sans',
                                    border: '1px solid #D7A048!important',
                                }}
                                onClick={() => {
                                    navigate('/pricing', {
                                        state: { talentFinder: true },
                                    })
                                }}
                            >
                                Get more credits
                            </button>
                        </div>
                        <div className="col-sm-12 col-md-10 row mx-0 align-items-end">
                            {width < 645 && (
                                <div className="col-2">
                                    <Tooltip title="Filters">
                                        <IconButton
                                            onClick={() => {
                                                handleShow()
                                            }}
                                        >
                                            <FilterListIcon
                                                style={{
                                                    color: `rgb(0, 156, 222)`,
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                            <div className="col-10 col-sm-12 col-md-12 text-center my-2">
                                <div className="d-grid d-md-flex justify-content-md-end">
                                    {width > 645 &&
                                        (User?.subscription?.status !==
                                            'active' &&
                                            User?.subscription?.status !==
                                                'trialing') && (
                                            <Tooltip
                                                title={
                                                    <span
                                                        style={{ fontSize: 12 }}
                                                        className="text-center"
                                                    >
                                                        Use your like credits to
                                                        signal your interest
                                                        with the candidate's
                                                        profile and
                                                        qualifications.
                                                    </span>
                                                }
                                                placement="bottom"
                                            >
                                                <button
                                                    style={{
                                                        fontSize: 18,
                                                        background: 'none',
                                                    }}
                                                    className=" me-0 me-md-3 mb-2 mb-md-0 btn btn-outline-secondary like-credits"
                                                >
                                                    <img
                                                        src={require('../../../assets/img/explore/LikeCreditIcon.jpg')}
                                                        width={18}
                                                        alt=""
                                                        className="mb-1 me-2"
                                                    />
                                                    <span
                                                        style={{
                                                            color: '#36D633',
                                                        }}
                                                    >
                                                        {
                                                            User?.user
                                                                ?.credits_likes
                                                        }
                                                    </span>{' '}
                                                </button>
                                            </Tooltip>
                                        )}
                                    {width > 645 && (
                                        <Tooltip
                                            title={
                                                <span
                                                    style={{ fontSize: 12 }}
                                                    className="text-center"
                                                >
                                                    Use Superlike credits to
                                                    instantly match and start a
                                                    conversation with a
                                                    candidate to speed up the
                                                    hiring process.
                                                </span>
                                            }
                                            placement="bottom"
                                        >
                                            <button
                                                style={{
                                                    fontSize: 18,
                                                    background: 'none',
                                                }}
                                                className=" me-0 me-md-3 mb-2 mb-md-0 btn btn-secondary superlike-credits"
                                            >
                                                <img
                                                    src={require('../../../assets/img/explore/SuperLikeCreditIcon.jpg')}
                                                    width={18}
                                                    alt=""
                                                    className="mb-1 me-2"
                                                />
                                                <span
                                                    style={{
                                                        color: '#D7A048',
                                                    }}
                                                >
                                                    {
                                                        User?.user
                                                            ?.credits_superLikes
                                                    }
                                                </span>{' '}
                                            </button>
                                        </Tooltip>
                                    )}
                                    {width > 640 && (
                                        <>
                                            <button
                                                data-tut="explore-step-1"
                                                className="btn text-white me-0 me-md-3 mb-2 mb-md-0"
                                                style={{
                                                    backgroundColor: '#D7A048',
                                                    fontSize: 14,
                                                    fontFamily: 'Open Sans',
                                                }}
                                                onClick={() => {
                                                    navigate('/pricing', {
                                                        state: {
                                                            talentFinder: true,
                                                        },
                                                    })
                                                }}
                                            >
                                                Get more credits
                                            </button>
                                        </>
                                    )}
                                    <CreateJobButton
                                        talentSearch={allowed_for_talent_search}
                                        activeJobs={activeJobs}
                                        setShowPremiumModal={setJobLimitModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-11 row position-relative">
                        <div className="d-none d-md-block col-sm-3 pt-3">
                            <div
                                className="card p-3 shadow-sm"
                                style={{
                                    position: 'fixed',
                                    width: '20%',
                                    height: '75vh',
                                    overflowY: 'auto',
                                    top: width < 640 ? 233 : 150,
                                }}
                            >
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        handleFilter(null, false)
                                    }}
                                >
                                    <div className="mb-3">
                                        <p className="text-primary">
                                            <strong>Search by keyword</strong>
                                        </p>
                                        <TextField
                                            placeholder={`Type job title, skills, etc... `}
                                            onChange={(e) => {
                                                setFilters({
                                                    ...filters,
                                                    keyword: e.target.value,
                                                })
                                            }}
                                            value={filters?.keyword}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <p className="text-primary">
                                            <strong>Job Function</strong>
                                        </p>
                                        <SelectJobFunction
                                            value={filters?.jobFunction}
                                            onChange={(val) => {
                                                setFilters({
                                                    ...filters,
                                                    jobFunction: val,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <p className="text-primary">
                                            <strong>Location</strong>
                                        </p>
                                        <CustomGooglePlaces
                                            onChange={(places) => {
                                                setFilters({
                                                    ...filters,
                                                    location: places?.location,
                                                })
                                            }}
                                            value={filters?.location}
                                            onClear={() => {
                                                setFilters({
                                                    ...filters,
                                                    location: null,
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <p className="text-primary">
                                            <strong>Work Preference</strong>
                                        </p>
                                        {/* WORK PREFERENCE */}
                                        <div className="">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value="Full-Time"
                                                    name="workPreference"
                                                    id="Full-Time"
                                                    onChange={(e) => {
                                                        handleSetWorkPref(e)
                                                    }}
                                                    checked={filters?.workPreference?.includes(
                                                        'Full-Time'
                                                    )}
                                                />
                                                <label
                                                    className="form-check-label open-sans ms-3"
                                                    htmlFor="Full-Time"
                                                >
                                                    Full-Time
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value="Part-Time"
                                                    name="workPreference"
                                                    id="Part-Time"
                                                    onChange={(e) => {
                                                        handleSetWorkPref(e)
                                                    }}
                                                    checked={filters?.workPreference?.includes(
                                                        'Part-Time'
                                                    )}
                                                />
                                                <label
                                                    className="form-check-label open-sans ms-3"
                                                    htmlFor="Part-Time"
                                                >
                                                    Part-Time
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value="Project-Based"
                                                    name="workPreference"
                                                    id="Project-Based"
                                                    onChange={(e) => {
                                                        handleSetWorkPref(e)
                                                    }}
                                                    checked={filters?.workPreference?.includes(
                                                        'Project-Based'
                                                    )}
                                                />
                                                <label
                                                    className="form-check-label open-sans ms-3"
                                                    htmlFor="Project-Based"
                                                >
                                                    Project-Based
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value="Internship"
                                                    name="workPreference"
                                                    id="Internship"
                                                    onChange={(e) => {
                                                        handleSetWorkPref(e)
                                                    }}
                                                    checked={filters?.workPreference?.includes(
                                                        'Internship'
                                                    )}
                                                />
                                                <label
                                                    className="form-check-label open-sans ms-3"
                                                    htmlFor="Internship"
                                                >
                                                    Internship
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="text-primary">
                                            <strong>Work Setup</strong>
                                        </p>
                                        {/* WORK SETUP */}
                                        <div className="">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value="On-Site"
                                                    name="workSetup"
                                                    id="On-Site"
                                                    onChange={(e) => {
                                                        handleWorkSetup(e)
                                                    }}
                                                    checked={filters?.workSetup?.includes(
                                                        'On-Site'
                                                    )}
                                                />
                                                <label
                                                    className="form-check-label open-sans ms-3"
                                                    htmlFor="On-Site"
                                                >
                                                    On-Site
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value="Remote"
                                                    name="workSetup"
                                                    id="Remote"
                                                    onChange={(e) => {
                                                        handleWorkSetup(e)
                                                    }}
                                                    checked={filters?.workSetup?.includes(
                                                        'Remote'
                                                    )}
                                                />
                                                <label
                                                    className="form-check-label open-sans ms-3"
                                                    htmlFor="Remote"
                                                >
                                                    Remote
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value="Hybrid"
                                                    name="workSetup"
                                                    id="Hybrid"
                                                    onChange={(e) => {
                                                        handleWorkSetup(e)
                                                    }}
                                                    checked={filters?.workSetup?.includes(
                                                        'Hybrid'
                                                    )}
                                                />
                                                <label
                                                    className="form-check-label open-sans ms-3"
                                                    htmlFor="Hybrid"
                                                >
                                                    Hybrid
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="text-primary">
                                                    <strong>
                                                        Salary Range
                                                    </strong>
                                                </p>
                                            </div>
                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                                <select
                                                    name=""
                                                    id=""
                                                    className="form-select me-2"
                                                    onChange={(e) =>
                                                        setSalaryCurrency(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option
                                                        value="PHP"
                                                        selected={
                                                            currency == 'PHP'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        PHP
                                                    </option>
                                                    <option
                                                        value="SGD"
                                                        selected={
                                                            currency == 'SGD'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        SGD
                                                    </option>
                                                    <option
                                                        value="USD"
                                                        selected={
                                                            currency == 'USD'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        USD
                                                    </option>
                                                </select>
                                                <input
                                                    type="text"
                                                    placeholder="Min"
                                                    className="form-control"
                                                    onChange={handleSalaryMin}
                                                    value={filters?.salaryMin}
                                                />
                                                <span className="mx-1">-</span>
                                                <input
                                                    type="text"
                                                    placeholder="Max"
                                                    className="form-control"
                                                    onChange={handleSalaryMax}
                                                    value={filters?.salaryMax}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="text-primary">
                                            <strong>Experience Required</strong>
                                        </p>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="yearsOfExp"
                                                id="0-1"
                                                value="0-1"
                                                onChange={(e) => {
                                                    setYearsOfExp(
                                                        e.target.value
                                                    )
                                                }}
                                                checked={
                                                    filters?.yearsOfExp ===
                                                    '0-1'
                                                }
                                            />
                                            <label
                                                class="form-check-label"
                                                for="0-1"
                                            >
                                                0-1 Year
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="yearsOfExp"
                                                id="1-3"
                                                value="1-3"
                                                onChange={(e) => {
                                                    setYearsOfExp(
                                                        e.target.value
                                                    )
                                                }}
                                                checked={
                                                    filters?.yearsOfExp ===
                                                    '1-3'
                                                }
                                            />
                                            <label
                                                class="form-check-label"
                                                for="1-3"
                                            >
                                                1-3 Years
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="yearsOfExp"
                                                id="3-5"
                                                value="3-5"
                                                onChange={(e) => {
                                                    setYearsOfExp(
                                                        e.target.value
                                                    )
                                                }}
                                                checked={
                                                    filters?.yearsOfExp ===
                                                    '3-5'
                                                }
                                            />
                                            <label
                                                class="form-check-label"
                                                for="3-5"
                                            >
                                                3-5 Years
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="yearsOfExp"
                                                id="5-7"
                                                value="5-7"
                                                onChange={(e) => {
                                                    setYearsOfExp(
                                                        e.target.value
                                                    )
                                                }}
                                                checked={
                                                    filters?.yearsOfExp ===
                                                    '5-7'
                                                }
                                            />
                                            <label
                                                class="form-check-label"
                                                for="5-7"
                                            >
                                                5-7 Years
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="yearsOfExp"
                                                id="7"
                                                value="7-99"
                                                onChange={(e) => {
                                                    setYearsOfExp(
                                                        e.target.value
                                                    )
                                                }}
                                                checked={
                                                    filters?.yearsOfExp ===
                                                    '7-99'
                                                }
                                            />
                                            <label
                                                class="form-check-label"
                                                for="7"
                                            >
                                                7+ Years
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button
                                            type="button"
                                            className="position-relative btn btn-secondary w-136 border mt-2"
                                            style={{
                                                height: 36,
                                                marginBottom: 10,
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleClearFilters &&
                                                    handleClearFilters()
                                            }}
                                        >
                                            Clear filters
                                        </button>
                                    </div>
                                    <div className="d-grid">
                                        <button
                                            type="submit"
                                            className="position-relative btn btn-primary w-136"
                                            style={{
                                                height: 36,
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleFilter(null, false)
                                            }}
                                        >
                                            Search Job Seekers
                                        </button>
                                    </div>
                                </form>
                                <SmallBanner style={{marginTop: 30}}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-9 pt-3 d-flex  justify-content-center">
                            <div
                                style={{
                                    position: 'fixed',
                                    width: width < 640 ? '80%' : '68%',
                                    zIndex: 998,
                                    top: 60,
                                    paddingTop: 100,
                                    background: '#fff',
                                }}
                            ></div>
                            <div
                                className="card shadow-sm py-4 px-2 px-md-3 d-flex"
                                style={{
                                    position: 'fixed',
                                    width: width < 640 ? '90%' : '68%',
                                    zIndex: 500,
                                    top: width < 640 ? 179 : 150,
                                }}
                            >
                                <div className="d-flex w-100 justify-content-center justify-content-md-between">
                                    <div className="me-md-2 ms-0 me-0">
                                        <p className="text-primary">
                                            <strong>
                                                Shortlist candidates to this job
                                                post
                                            </strong>
                                        </p>

                                        <SelectJobPost
                                            onChange={(val) => {
                                                setFilters({
                                                    ...filters,
                                                    jobId: val,
                                                })
                                            }}
                                            value={filters?.jobId}
                                            className="w-100"
                                        />
                                    </div>
                                    <div className="d-md-flex d-none">
                                        <div>
                                            <img
                                                src={
                                                    viewType === 'grid'
                                                        ? gridActiveIcon
                                                        : gridIcon
                                                }
                                                alt=""
                                                style={{ width: 16 }}
                                                onClick={() =>
                                                    setViewType('grid')
                                                }
                                                className="hand"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <img
                                                src={
                                                    viewType === 'list'
                                                        ? listActiveIcon
                                                        : listIcon
                                                }
                                                alt=""
                                                style={{ width: 16 }}
                                                onClick={() =>
                                                    setViewType('list')
                                                }
                                                className="hand"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* VIEWS */}
                            <div
                                className="w-100 mx-auto"
                                ref={scrollerRef}
                                style={{ paddingTop: width < 640 ? 176 : 140 }}
                            >
                                <div style={{marginTop: 50}}/>
                                <LargeBanner/>
                                <div
                                    id="infinite-scroll-top"
                                    className="w-100 p-1"
                                ></div>
                                <InfiniteScroll
                                    loadMore={() => loadInfinite()}
                                    hasMore={
                                        jobSeekers?.length < totalCount &&
                                        currentPage <=
                                            Math.ceil(totalCount / 24)
                                    }
                                    useWindow={true}
                                    pageStart={1}
                                    threshold={80}
                                    loader={
                                        isInfiniteScrollLoading && (
                                            <SpinProgress size={80} />
                                        )
                                    }
                                >
                                    {currentLists?.length > 0 && !isLoading ? (
                                        <Grid
                                            container
                                            justifyContent="center"
                                            spacing={2}
                                            style={{ marginTop: 20 }}
                                        >
                                            {currentLists?.map(
                                                (seeker, index) => {
                                                    return (
                                                        <>
                                                            {viewType ===
                                                            'list' ? (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    id={`card-list-${seeker?._id}`}
                                                                    key={`card-list-${seeker?._id}`}
                                                                    className={`common-card-${seeker?._id}`}
                                                                >
                                                                    <ExploreListviewCard
                                                                        seeker={
                                                                            seeker
                                                                        }
                                                                        setIsViewProfile={
                                                                            setIsViewProfile
                                                                        }
                                                                        setJobseeker={
                                                                            setJobseeker
                                                                        }
                                                                        onSwipeFulfilled={
                                                                            onSwipeFulfilled
                                                                        }
                                                                        cardIndex={
                                                                            seeker?._id
                                                                        }
                                                                        explore={
                                                                            true
                                                                        }
                                                                        itemIndex={
                                                                            index
                                                                        }
                                                                        isFromExplore={
                                                                            true
                                                                        }
                                                                    />
                                                                </Grid>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={3}
                                                                    lg={3}
                                                                    key={`card-grid-${seeker?._id}-grid-view`}
                                                                    style={{
                                                                        minWidth:
                                                                            '260px',
                                                                        maxWidth:
                                                                            '300px',
                                                                    }}
                                                                    className={`common-card-${seeker?._id}`}
                                                                    id={`card-grid-${seeker?._id}`}
                                                                >
                                                                    <ExploreGridViewCard2
                                                                        itemIndex={
                                                                            index
                                                                        }
                                                                        seeker={
                                                                            seeker
                                                                        }
                                                                        setIsViewProfile={
                                                                            setIsViewProfile
                                                                        }
                                                                        setJobseeker={
                                                                            setJobseeker
                                                                        }
                                                                        onSwipeFulfilled={
                                                                            onSwipeFulfilled
                                                                        }
                                                                        cardID={
                                                                            seeker?._id
                                                                        }
                                                                        currencyList={
                                                                            currencyList
                                                                        }
                                                                        currency={
                                                                            currency
                                                                        }
                                                                        className="w-100"
                                                                        id={`card-grid-${seeker?.id}`}
                                                                        key={`card-grid-${seeker?.id}`}
                                                                        isFromExplore={
                                                                            true
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            )}
                                            {/* {isInfiniteScrollLoading && (
                                                <Box height={150}>
                                                    <SpinProgress />
                                                </Box>
                                            )} */}
                                        </Grid>
                                    ) : (
                                        <>
                                            {!isLoading ? (
                                                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                                    <div className="mt-5 pt-5 text-center">
                                                        <img
                                                            src={emptyImg}
                                                            alt=""
                                                            className="mb-3 text-center"
                                                        />
                                                        <h4 className="text-center">
                                                            You ran out of{' '}
                                                            <br />
                                                            candidates to
                                                            shortlist.
                                                        </h4>
                                                        <p>
                                                            Don’t worry! We
                                                            still have more
                                                            candidate for you.{' '}
                                                            <br />
                                                            Try adjusting your
                                                            filters to see more
                                                            candidates
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Box paddingTop={3}>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        justifyContent="center"
                                                    >
                                                        <GridLoader
                                                            count={12}
                                                        />
                                                    </Grid>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </InfiniteScroll>
                            </div>
                            {/* VIEWS */}
                        </div>
                    </div>
                </div>
            </div>
            <Filter
                handleShow={handleShow}
                handleClose={handleClose}
                show={show}
                setShow={setShow}
                checkbox_filters={checkbox_filters}
                filters={filters}
                setFilters={setFilters}
                handleFilter={handleFilter}
                handleSetWorkPref={handleSetWorkPref}
                handleWorkSetup={handleWorkSetup}
                setJobTitleQuery={setJobTitleQuery}
                setSalaryMin={setSalaryMin}
                setSalaryMax={setSalaryMax}
                setYearsOfExp={setYearsOfExp}
                setSalaryCurrency={setSalaryCurrency}
                currency={currency}
                handleSalaryMin={handleSalaryMin}
                handleSalaryMax={handleSalaryMax}
                salaryMin={salaryMin}
                salaryMax={salaryMax}
                handleClearFilters={handleClearFilters}
            />
            {isViewProfile && (
                <>
                    <SlidingProfile
                        jobseeker={jobseeker}
                        setIsViewProfile={setIsViewProfile}
                        setJobseeker={setJobseeker}
                    />
                </>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return { AppState: state.Generals, User: state.User }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreV2)
