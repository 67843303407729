import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import notificationActions from '../../redux/notifications/action'
import { userRequest } from '../../service/requests'
import { profilePlaceholder_Applicant } from '../../utils/constants'
import './styles.css'
import { images } from '../../utils/constants';

export function MapNotif(props) {
    const { data, size, action } = props
    let fontSize = 14
    let customSize = 'p-3 my-2 notif-bar'

    if (size === 'small') {
        fontSize = 12
        customSize = 'p-3'
    }
    if (!data) return null
    return (
        <>
            {data?.map((notif) => {
                return (
                    <div
                        onClick={() => action(notif)}
                        className={`d-flex  border ${customSize}`}
                        role="button"
                        style={{
                            background: !notif?.isRead ? '#f2f2f2' : '',
                            marginBottom: size === 'small' && 0,
                        }}
                        key={notif?._id}
                    >
                        <img
                            style={{ width: 42, height: 42 }}
                            alt=""
                            className="my-auto img-fit"
                            src={
                                notif?.data?.profileUrl
                                ? notif?.data?.profileUrl
                                : notif?.type === 'recommendation'
                                ? images.JOBLU_ICON
                                :
                                profilePlaceholder_Applicant
                            }
                        />
                        <div className="d-flex flex-column">
                            <span
                                className="notif-content my-auto mx-2"
                                style={{ fontSize: fontSize || 14 }}
                            >
                                {notif?.content}
                            </span>
                            <span
                                className="time-ago my-auto mx-2"
                                style={{ fontSize: fontSize || 14 }}
                            >
                                {moment(notif.createdAt).fromNow()}
                            </span>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
function Notifications(props) {
    const { User, Notifications, getNotifications } = props
    const { notificationsList, isFetching, isFetched } = Notifications
    const [isGettingMoredata, setIsGettingMoreData] = useState(false)

    const navigate = useNavigate()

    const readAll = () => {
        userRequest
            .readAllNotifications()
            .then((res) => {
                getNotifications()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getNotifications()
    }, [])

    const goToNotification = (data) => {
        console.log(data)
        userRequest
            .readNotification({
                notificationId: data._id,
                isRead: true,
            })
            .then((response) => {
                getNotifications()
            })
            .catch((err) => {
                console.log(err)
            })

        if (data.type === 'jobprocess') {
            //go to jobs page
            navigate('/jobs', {
                state: { data },
            })
        } else if (data.type === 'match') {
            // jobsRequest.getSpecificJob({jobId: data?.data?.jobId})
            // .then(response => {
            //   console.log(response?.data)
            // // go to job match or go to specific job view
            // })
            // .catch(e => {
            //     // go to job match or go to specific job view
            // })
            navigate('/matches', {
                state: { data },
            })
        } else if (data.type === 'superlike' || data.type === 'like') {
            // go to likes
            navigate('/likes', {
                state: { tab: 'liked_me', data },
            })
        }
        else if (data.type === 'recommendation'){
            navigate('/recommendations')
        }
    }

    const getMoreNotifications = () => {
        if (!isGettingMoredata) {
            // console.log(notificationsList.totalCount)
            // console.log(notificationsList.data?.length)
            const totalCount = notificationsList?.totalCount
            const currentCount = notificationsList?.data?.length
            const currentPage = notificationsList?.page
            // console.log('PAGE:::',currentPage)
            const limit = notificationsList?.limit
            if (totalCount > currentCount && currentCount < 100) {
                setIsGettingMoreData(true)
                getNotifications({ offset: currentPage + 1 })
                setTimeout(() => {
                    setIsGettingMoreData(false)
                }, 1000)
            }
        }
    }

    return (
        <div className="row justify-content-center mx-0">
            <div className="border-bottom ">
                <div className="container ">
                    <div className="justify-content-between align-items-center row">
                        <div className="col-sm-12 col-md-3 my-3">
                            <h5 className="text-body fw500 mb-2 ">Notifications</h5>
                        </div>
                        {/* <div className="col-sm-12 col-md-3 text-right pb-3"></div> */}
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-9">
                {!!notificationsList?.data?.length && (
                    <div className="col-12 my-3" onClick={() => readAll()}>
                        <div className="d-flex justify-content-end">
                            <p role="button" className="text-end m-0">
                                Mark All As Read
                            </p>
                        </div>
                        <div className="mt-2">
                            <MapNotif
                                data={notificationsList?.data || []}
                                action={goToNotification}
                            />
                        </div>
                    </div>
                )}
                {notificationsList?.data?.length <
                    notificationsList?.totalCount &&
                    notificationsList?.data?.length < 100 && (
                        <>
                            {!!notificationsList?.data?.length && (
                                <div className="text-center">
                                    <button
                                        className="btn btn-primary my-3 text-center"
                                        onClick={() => getMoreNotifications()}
                                    >
                                        {isGettingMoredata && (
                                            <span
                                                className="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                        Load More
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                {!notificationsList?.data?.length && (
                    <div className="mt-5 ">
                        <h6 role="button" className="text-center">
                            No notifications
                        </h6>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
        Notifications: state.Notifications,
    }
}

const mapDispatchToProps = {
    getNotifications: notificationActions.getNotifications,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
