import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import companyInformationIcon from '../../assets/img/companyInformationIcon.png'
import userActions from '../../redux/user/actions'
import { COMPANY_CULTURE_LENGTH } from '../../utils/constants'
import {
    makeUploadCompanyCultureFile,
    makeUploadRequest,
} from '../../utils/uploadFiles'
// import uploadIcon from '../../assets/img/uploadIcon.png'
import _ from 'lodash'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import ReactPlayer from 'react-player'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import CloseIcon from '../../assets/img/closeIcon.png'
import defaultImg from '../../assets/img/defaultImg.png'
import copyIcon from '../../assets/img/editProfile/copyIcon.png'
import deleteCircle from '../../assets/img/editProfile/deleteCircle.svg'
import deleteIconModal from '../../assets/img/editProfile/deleteIconModal.png'
import editCircle from '../../assets/img/editProfile/editCircle.svg'
import uploadPlusIcon from '../../assets/img/editProfile/uploadPlusIcon.png'
import viewIcon from '../../assets/img/eyeWhiteIcon.png'
import playIcon from '../../assets/img/play.svg'
import trashbinIcon from '../../assets/img/trashbinIcon.png'
import videoPlaybackIcon from '../../assets/img/videoPlayback.svg'
import TextField from '../../components/inputs/CustomTextField'
import Hoverable from '../../components/reusables/Hoverable/Hoverable'
import { userRequest } from '../../service/requests'
import {
    createURL,
    getCountryCodeFromNumber,
    trimPhone,
} from '../../utils/helpers'
import { validateURL } from '../../utils/validators'
import JobluVideoPlayer from '../explore/components/VideoPlayer'
import './style.css'
// import deleteBtn2 from '../../assets/img/editProfile/deleteBtn2.svg'
import { Box } from '@material-ui/core'
import Select from 'react-select'
import VideoPlayer from 'simple-react-video-thumbnail'
import defaultCover from '../../assets/img/editProfile/defaultCover.png'
import profilePlayIcon from '../../assets/img/profile/profilePlayIcon.png'
import CustomGooglePlaces from '../../components/google-places'
import useWindowDimensions from '../../utils/hooks'
import './style.css'
const DeleteModal = (props) => {
    const { smShow, setSmShow, primaryModalText, secondaryModalText, action } =
        props
    return (
        <>
            {/* <Button onClick={() => setSmShow(true)} className="me-2">
                Small modal
            </Button> */}
            <Modal
                size="sm"
                show={smShow}
                centered
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="text-center p-3">
                        <img
                            src={deleteIconModal}
                            className=" text-center w-75 mb-3"
                            alt=""
                        />
                        <h5 className="text-center">{primaryModalText}</h5>
                        <p className="text-center">{secondaryModalText}</p>
                        <div className="mt-3">
                            <button
                                className="btn btn-primary me-2"
                                onClick={() => {
                                    action()
                                }}
                            >
                                Delete
                            </button>
                            <button
                                className="btn btn-outline-primary"
                                onClick={() => {
                                    setSmShow(false)
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const SortableItem = SortableElement(({ value, index }) => (
    <div className="col-md-3 mb-3">
        <div className="card shadow-sm h-100" style={{ cursor: 'grab' }}>
            <div className="card-body">
                <div
                    style={{
                        height: 20,
                        background: '#f2f2f2',
                        borderRadius: 8,
                    }}
                ></div>
                <div
                    style={{
                        height: 20,
                        background: '#f2f2f2',
                        borderRadius: 8,
                        width: '50%',
                        marginTop: 5,
                    }}
                ></div>
                <div className="text-center my-3">
                    <img
                        src={value}
                        alt=""
                        style={{ width: 150, height: 150 }}
                    />
                </div>
                <div className="">
                    <div className="w-100 d-flex justify-content-center">
                        <div
                            style={{
                                height: 20,
                                background: '#f2f2f2',
                                borderRadius: 8,
                                width: '50%',
                                marginTop: 5,
                            }}
                        ></div>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <div
                            style={{
                                height: 20,
                                background: '#f2f2f2',
                                borderRadius: 8,
                                width: '80%',
                                marginTop: 5,
                            }}
                        ></div>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <div
                            style={{
                                height: 20,
                                background: '#f2f2f2',
                                borderRadius: 8,
                                width: '60%',
                                marginTop: 5,
                            }}
                        ></div>
                    </div>
                </div>
                <div className="d-flex justify-content-evenly mt-3">
                    <div
                        style={{
                            height: 40,
                            width: 40,
                            borderRadius: '50%',
                            marginTop: 5,
                            border: '3px solid #f2f2f2',
                        }}
                    ></div>
                    <div
                        style={{
                            height: 40,
                            width: 40,
                            borderRadius: '50%',
                            marginTop: 5,
                            border: '3px solid #f2f2f2',
                        }}
                    ></div>
                    <div
                        style={{
                            height: 40,
                            width: 40,
                            borderRadius: '50%',
                            marginTop: 5,
                            border: '3px solid #f2f2f2',
                        }}
                    ></div>
                    <div
                        style={{
                            height: 40,
                            width: 40,
                            borderRadius: '50%',
                            marginTop: 5,
                            border: '3px solid #f2f2f2',
                        }}
                    ></div>
                </div>
            </div>
        </div>

        {/* DO NOT DELETE */}
        {/* <div className="card job-card-bg">
            <div className="d-flex flex-column">
                <div className=" p-2">
                    <p className="text-primary">
                        <strong>Product Designer / Experience Designer</strong>
                    </p>
                    <small style={{ fontSize: '11px' }}>
                        Php 35,000.00 - 50,000.00
                    </small>
                </div>
                <div className="mb-3">
                    <img src={previewImg} alt="" className="img-fluid w-100" />
                </div>
                <div className="mb-2 p-2">
                    <p className="small text-center mb-0">
                        Robinsons Land Center
                    </p>
                    <p className="small text-center mb-0">
                        Makati, Philippines
                    </p>
                    <p className="small text-center mb-0">
                        Full-Time, Remote, Hybrid
                    </p>
                    <p className="small text-center mb-0">7 Years and Above</p>
                    <p className="small text-center mb-0">{index}</p>
                </div>
                <div className="d-flex justify-content-between p-2 align-items-center">
                    <div
                        className="bg-light border rounded-circle"
                        style={{ height: 30, width: 30 }}
                    ></div>
                    <img src={dismissIcon} alt="" className="img-fluid hand" />
                    <img
                        src={superLikeIcon}
                        alt=""
                        className="img-fluid hand"
                    />
                    <img src={likeIcon} alt="" className="img-fluid hand" />
                    <div
                        className="bg-light border rounded-circle"
                        style={{ height: 30, width: 30 }}
                    ></div>
                </div>
            </div>
        </div> */}
    </div>
))

const ALLOWED_VIDEO_URLS = ['www.youtube.com', 'youtube.com', 'vimeo.com']
const SortableList = SortableContainer(({ items }) => {
    return (
        <div className="row">
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${value.link}`}
                    index={index}
                    value={value.link}
                />
            ))}
        </div>
    )
})

function EditProfile(props) {
    const navigate = useNavigate()
    const { User, getUser, onSave } = props
    const containerRef = useRef()
    const { width } = useWindowDimensions()
    // MODAL
    const [smShow, setSmShow] = useState(false)
    const [primaryModalText, setPrimaryModalText] = useState(null)
    const [secondaryModalText, setSecondaryModalText] = useState(null)

    const [profile, setProfile] = useState(User.user)
    const [logoFile, setLogoFile] = useState(null)
    const char_limit = 280
    const [charLeft, setCharLeft] = useState(char_limit)

    const [companyMission, setCompanyMission] = useState(
        profile?.companyMission
    )
    const [productsAndServices, setProductsAndServices] = useState(
        profile?.productsAndServices
    )
    const [cultureDescription, setCultureDescription] = useState(
        profile?.cultureDescription
    )
    const [companyCulture, setCompanyCulture] = useState(null)
    const [companyAwards, setCompanyAwards] = useState([])
    const [employeeReview, setEmployeeReview] = useState([])
    const [cardPreview, setCardPreview] = useState([])
    const [companyName, setCompanyName] = useState(profile?.companyName)
    const [companyWebsite, setCompanyWebsite] = useState(
        profile?.companyWebsite
    )
    const [companyOverview, setCompanyOverview] = useState(
        profile?.companyOverview
    )

    const [currentLocation, setCurrentLocation] = useState(
        profile?.currentLocation || profile?.country
    )
    const [country, setCountry] = useState(profile?.country || null)
    const [region, setRegion] = useState(profile?.region || null)
    const [city, setCity] = useState(profile?.city || null)
    const [companySize, setCompanySize] = useState(profile?.companySize)
    const [yearEstablished, setYearEstablished] = useState(
        profile?.yearEstablished || ''
    )
    const [workSetup, setWorkSetup] = useState(profile?.workSetup || null)
    const [tagline, setTagline] = useState(profile?.tagline || null)
    const [companyVision, setCompanyVision] = useState(
        profile?.companyVision || null
    )
    const [companyValues, setCompanyValues] = useState(
        profile?.companyValues || null
    )
    const [cultureFiles, setCultureFiles] = useState([])

    const [list, setList] = useState([])
    const [awardName, setAwardName] = useState(null)
    const [awardNameError, setAwardNameError] = useState(false)
    const [awardDate, setAwardDate] = useState(null)
    const [awardDateError, setAwardDateError] = useState(false)

    const [yearEstablishedError, setYearEstablishedError] = useState(false)
    const [workSetupError, setWorkSetupError] = useState(false)
    const [videoLink, setVideoLink] = useState(null)
    const [videoLinkCopied, setVideoLinkCopied] = useState(false)
    const [employeeReviewLink, setEmployeeReviewLink] = useState(null)
    const [employeeReviewCopied, setEmployeeReviewCopied] = useState(false)
    const [profileUrl, setProfileUrl] = useState(null)
    const [coverPhotoUrl, setCoverPhotoUrl] = useState(null)
    const [isEditing, setIsEditing] = useState(null)

    const [dragging, setIsDragging] = useState(false)
    const [playVideo, setPlayVideo] = useState(null)
    const [isUploading, setIsUploading] = useState(null)
    const [showAddLink, setShowAddLink] = useState(null)
    const [viewPhoto, setViewPhoto] = useState(null)

    const [isSaving, setIsSaving] = useState(false)
    const [showCaseLinks, setShowcaseLinks] = useState(null)

    const [isSearchable, setIsSearchable] = useState(true)

    const countryCodes = require('country-codes-list')
    const myCountryCodesObject = countryCodes.customList(
        'countryCode',
        '{countryNameEn}:+{countryCallingCode}'
    )
    const [countryCodesList, setCountryCodesList] = useState(
        Object.values(myCountryCodesObject).map((item) => {
            var temp = item.split(':')
            return {
                label: `${temp[0]} (${temp[1]})`,
                value: item,
            }
        })
    )

    const ccLength = profile?.phoneNumber
        ? profile?.phoneNumber?.length - 10
        : 0

    const [country_code, setCountry_Code] = useState(
        profile?.phoneNumber
            ? getCountryCodeFromNumber(
                  countryCodesList,
                  profile?.phoneNumber?.substr(0, ccLength)
              )
            : null
    )

    const [tempCC, setTempCC] = useState(
        profile?.phoneNumber
            ? {
                  value: profile?.phoneNumber
                      ? getCountryCodeFromNumber(
                            countryCodesList,
                            profile?.phoneNumber?.substr(0, ccLength)
                        )
                      : null,
                  label: profile?.phoneNumber
                      ? getCountryCodeFromNumber(
                            countryCodesList,
                            profile?.phoneNumber?.substr(0, ccLength)
                        )
                      : null,
              }
            : null
    )
    useEffect(() => {
        let temp = {
            value: profile?.phoneNumber
                ? getCountryCodeFromNumber(
                      countryCodesList,
                      profile?.phoneNumber?.substr(0, ccLength)
                  )
                : null,
            label: profile?.phoneNumber
                ? getCountryCodeFromNumber(
                      countryCodesList,
                      profile?.phoneNumber?.substr(0, ccLength)
                  )
                : null,
        }
        console.log(temp)
        setTempCC(temp)
    }, [country_code])
    const [error_CountryCode, setError_CountryCode] = useState(null)

    const [phoneNumber, setPhoneNumber] = useState(
        profile.phoneNumber
            ? profile?.phoneNumber?.substr(
                  ccLength,
                  profile?.phoneNumber?.length
              )
            : null
    )
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    useEffect(() => {
        console.log(countryCodesList)
    }, [countryCodesList])
    const validateFields = () => {
        let isValid = []

        if (awardDate > moment().format('YYYY-MM-DD')) {
            setAwardDateError(true)
            isValid.push(false)
            console.log('false')
        } else {
            setAwardDateError(false)
            isValid.push(true)
        }

        return isValid.includes(false) ? false : true
    }

    const addAward = () => {
        const valid = validateFields()
        if (!valid) {
            return
        }

        let copy = companyAwards

        let nd = new Date(awardDate)

        const award = {
            name: awardName,
            date: nd,
        }

        setCompanyAwards([...copy, award])

        setAwardName(null)
        setAwardDate(null)
        document.getElementById('award-name').value = ''
        document.getElementById('award-date').value = ''
    }

    useEffect(() => {
        if (profile?.companyCulture?.length > 0) {
            setCultureFiles(profile?.companyCulture?.sort())
        }
    }, [profile?.companyCulture])

    useEffect(() => {
        userRequest.getShowcaseLinks().then((res) => {
            if (!!res?.data) {
                setShowcaseLinks(res.data)
            }
        })
    }, [])

    useEffect(() => {
        if (profile?.companyCulture?.length > 0) {
            const featured = profile?.companyCulture?.filter((file, index) => {
                if (file?.featured) return true
                return null
            })
            const featuredURLs = featured.map((feat) => feat?.link)
            let cardPrevList1 = [...profile?.cardPreview]
            cardPrevList1 = cardPrevList1.filter((file, index) => {
                return featuredURLs.includes(file?.url)
            })

            let lists = [...cardPrevList1, ...featured]
            lists = _.uniqBy(lists, 'link')
            setList(lists)
        }
    }, [profile?.companyCulture])

    useEffect(() => {
        if (profile?.companyAwards?.length > 0) {
            setCompanyAwards(profile?.companyAwards)
        }
    }, [profile?.companyAwards])

    useEffect(() => {
        if (profile?.companyOverview) {
            const left = char_limit - profile.companyOverview?.length
            setCharLeft(left)
            if (profile?.companyOverview?.length > char_limit) {
                const trimmed = profile?.companyOverview?.substring(
                    0,
                    char_limit
                )
                setProfile({
                    ...profile,
                    companyOverview: trimmed,
                })
                setCharLeft(0)
            }
        }
    }, [profile.companyOverview])

    const [companyNameError, setCompanyNameError] = useState(false)
    const [companyOverviewError, setCompanyOverviewError] = useState(false)
    const [companySizeError, setCompanySizeError] = useState(false)
    const [companySizeErrorMsg, setCompanySizeErrorMsg] = useState(null)
    const [yearEstErrorMsg, setYearEstErrorMsg] = useState(null)
    const [locationError, setLocationError] = useState(null)
    const [error, setError] = useState(false)

    const validate = () => {
        let valid = []
        if (!region || !city) {
            setLocationError('Kindly include your city or region')
            valid.push(false)
        } else {
            setLocationError(null)
            valid.push(true)
        }
        if (!companyName) {
            setCompanyNameError(true)
            valid.push(false)
        } else {
            setCompanyNameError(false)
            valid.push(true)
        }

        if (!companyOverview) {
            setCompanyOverviewError(true)
            valid.push(false)
        } else {
            setCompanyOverviewError(false)
            valid.push(true)
        }

        if (!workSetup) {
            setWorkSetupError(true)
            valid.push(false)
        } else {
            setWorkSetupError(false)
            valid.push(true)
        }
        if (!phoneNumber) {
            setPhoneNumberError(true)
            valid.push(false)
        } else {
            setPhoneNumberError(false)
            valid.push(true)
        }
        if (!yearEstablished) {
            setYearEstablishedError(true)
            setYearEstErrorMsg('Year Established is required')
            valid.push(false)
        } else {
            if (
                yearEstablished.length < 4 ||
                yearEstablished <= 1800 ||
                yearEstablished > parseInt(moment().year())
            ) {
                setYearEstErrorMsg('Invalid Year')
                setYearEstablishedError(true)
                valid.push(false)
            } else {
                setYearEstablishedError(false)
                valid.push(true)
            }
        }
        if (!companySize) {
            setCompanySizeError(true)
            setCompanySizeErrorMsg('Company Size is required')
            valid.push(false)
        } else {
            if (companySize <= 0) {
                setCompanySizeError(true)

                setCompanySizeErrorMsg('Invalid Company Size')
                valid.push(false)
            } else {
                setCompanySizeError(false)
                valid.push(true)
            }
        }

        return valid.includes(false) ? false : true
    }

    const [vidFromHeart, setVidFromHeart] = useState([])
    useEffect(() => {
        setVidFromHeart(profile?.vidFromHeart)
    }, [profile])
    const handleUpdate = async () => {
        let valid = validate()
        if (valid) {
            setError(false)
            setIsSaving(true)
            try {
                let profileUrl = ''
                if (logoFile) {
                    profileUrl = await makeUploadRequest(
                        logoFile,
                        'profileimage'
                    )
                }
                let coverUrlImg = ''
                if (coverPhotoUrl) {
                    coverUrlImg = await makeUploadRequest(
                        coverFile,
                        'profileimage'
                    )
                }
                console.log(coverPhotoUrl)
                console.log(coverUrlImg)

                let payload = {
                    ...profile,
                    coverUrl: coverUrlImg ? coverUrlImg : profile.coverUrl,
                    companyName,
                    companySize: parseInt(companySize),
                    currentLocation: currentLocation
                        ? currentLocation
                        : profile?.country,
                    country: country ? country : profile?.country,
                    region: region || undefined,
                    city: city || undefined,
                    phoneNumber: phoneNumber
                        ? phoneNumber.includes('+')
                            ? phoneNumber
                            : '+' + phoneNumber
                        : profile?.phoneNumber,
                    companyWebsite,
                    companyOverview,
                    companyMission,
                    companyVision,
                    companyValues,
                    tagline,
                    yearEstablished: yearEstablished
                        ? parseInt(yearEstablished)
                        : profile?.yearEstablished,
                    workSetup,
                    productsAndServices,
                    vidFromHeart,
                    companyAwards,
                    profileUrl,
                    countriesInterested: ['Singapore'],
                    companyCulture: cultureFiles?.filter(
                        (item) => item !== null
                    ),
                    cardPreview: _.uniqBy(profile?.cardPreview, 'link'),
                    cultureDescription: cultureDescription || null,
                }

                if (!companyWebsite) {
                    payload.companyWebsite = null
                }

                if (country_code && phoneNumber) {
                    let temp = country_code?.split(':')
                    payload.phoneNumber = `${temp[1]}${phoneNumber}`
                }
                if (!profileUrl) delete payload.profileUrl
                userRequest
                    .updateEmployer(payload)
                    .then((res) => {
                        if (res) {
                            setIsSaving(false)
                            getUser()
                            // navigate('/profile')
                            onSave()
                        }
                    })
                    .catch((err) => {
                        setIsSaving(true)
                        console.log(err)
                    })
            } catch (err) {
                setIsSaving(false)
                console.log(err)
            }
        } else {
            setError(true)
        }
    }

    const handleChange = (e) => {
        const value = e.target.value
        if (
            e.target.name === 'companyRegistrationNo' ||
            e.target.name === 'employmentAgencyNo'
        ) {
            setProfile({
                ...profile,
                [e.target.name]: value.replace(/[^a-z0-9]/gi, ''),
            })
        } else {
            setProfile({
                ...profile,
                [e.target.name]: !!value ? value : null,
            })
        }
    }

    const swapPositions = (array, a, b) => {
        return ([array[a], array[b]] = [array[b], array[a]])
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        swapPositions(list, oldIndex, newIndex)
        setList(list)
    }

    useEffect(() => {
        setProfile({
            ...profile,
            cardPreview: list,
        })
    }, [list])

    const handleSetProfileUrl = (file) => {
        let logo = URL.createObjectURL(file)
        setLogoFile(file)
        setProfileUrl(logo)
    }
    const [coverFile, setCoverFile] = useState(null)
    const handleSetCoverPhotoUrl = (file) => {
        let cover = URL.createObjectURL(file)
        setCoverFile(file)
        setCoverPhotoUrl(cover)
    }

    const handleSetFiles = async (file, index) => {
        setIsUploading(index)

        if (file.type === 'video/mp4' || file.type === 'video/quicktime') {
            let newVideo = await makeUploadCompanyCultureFile(
                file,
                null,
                'video'
            )
            let copy = cultureFiles
            let photoEntry = {
                link: newVideo,
                featured: false,
            }
            copy[index] = photoEntry

            setCultureFiles([...copy])
        } else if (file.type === 'image/jpeg') {
            let newPhoto = await makeUploadCompanyCultureFile(
                file,
                null,
                'image'
            )
            let copy = cultureFiles
            let photoEntry = {
                link: newPhoto,
                featured: false,
            }
            copy[index] = photoEntry

            setCultureFiles([...copy])
        } else {
            alert('The file is not supported. Only accepts jpeg and mp4 files.')
        }
        setIsUploading(null)
    }

    const handleFeatured = (i) => {
        setCultureFiles(
            cultureFiles.map((photo, index) => {
                if (index === i) {
                    photo.featured = !photo.featured
                    return { ...photo, featured: photo.featured }
                } else {
                    return photo
                }
            })
        )
    }

    const handleCopyVideoLink = (link) => {
        navigator.clipboard.writeText(link)
        setVideoLinkCopied(true)
        setTimeout(() => {
            setVideoLinkCopied(false)
        }, 2000)
    }

    const handleCopyEmployeeReview = (link) => {
        navigator.clipboard.writeText(link)
        setEmployeeReviewCopied(true)
        setTimeout(() => {
            setEmployeeReviewCopied(false)
        }, 2000)
    }
    const viewFileOptions = (item, index, isVideo) => {
        return (
            <div
                className="w-100 h-100 position-absolute"
                style={{
                    background: 'hsla(210, 100%, 18%, 0.9)',
                    borderRadius: 8,
                }}
            >
                <input
                    className="form-check-input position-absolute chk"
                    style={{
                        right: 12,
                        top: 10,
                        zIndex: 999,
                    }}
                    type="checkbox"
                    id="checkboxNoLabel"
                    value=""
                    aria-label="..."
                    defaultChecked={item?.featured}
                    onChange={() => {
                        handleFeatured(index)
                    }}
                />
                <div className="border h-100 w-100 d-flex p-3">
                    <div className="m-auto d-flex flex-column w-100">
                        {!isVideo ? (
                            <button
                                className="btn btn-outline-light d-flex justify-content-center mb-1 w-100 "
                                style={{ fontSize: 12 }}
                                onClick={() => setViewPhoto(item?.link)}
                            >
                                <img
                                    className="my-auto me-1"
                                    src={viewIcon}
                                    alt="remove"
                                />{' '}
                                View
                            </button>
                        ) : (
                            <button
                                className="btn btn-outline-light d-flex justify-content-center mb-1 w-100 "
                                style={{ fontSize: 12 }}
                                onClick={() => {
                                    // setPlayVideo(item?.link)
                                    playVid(item?.link)
                                }}
                            >
                                <img
                                    className="my-auto me-1"
                                    src={playIcon}
                                    alt="remove"
                                />{' '}
                                <div className="my-auto">Play</div>
                            </button>
                        )}
                        <button
                            className="btn btn-outline-light d-flex justify-content-center mb-1 w-100 "
                            onClick={() => {
                                let copy = [...cultureFiles]
                                copy[index] = null
                                setCultureFiles([...copy])
                            }}
                            style={{ fontSize: 12 }}
                        >
                            <img
                                className="my-auto me-1"
                                src={trashbinIcon}
                                alt="remove"
                            />{' '}
                            Delete{' '}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const uploadFileOptions = (item, index) => {
        return (
            <div
                className="w-100 h-100 position-absolute"
                style={{
                    background: 'hsla(210, 100%, 18%, 0.9)',
                    borderRadius: 8,
                }}
            >
                <div className="border h-100 w-100 d-flex p-3">
                    <div className="m-auto d-flex flex-column w-100">
                        <button
                            style={{ fontSize: 12 }}
                            className="btn btn-outline-light d-flex justify-content-center mb-1 w-100 "
                            onClick={() => {
                                const ref = document.getElementById(
                                    `file-uploader-${index}`
                                )
                                ref?.click()
                            }}
                            l
                        >
                            Upload from device
                        </button>
                        <button
                            style={{ fontSize: 12 }}
                            className="btn btn-outline-light d-flex justify-content-center mb-1 w-100 "
                            onClick={() => {
                                setShowAddLink({
                                    index: index,
                                    link: null,
                                })
                            }}
                        >
                            From Youtube/Vimeo
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const handleShowEdit = (index) => {
        setIsEditing(index)
    }
    const handleHideEdit = (index) => {
        setIsEditing(null)
    }

    const [currentAward, setCurrentAward] = useState(null)
    const handleAwardDeleteModal = (award, index) => {
        setPrimaryModalText('Are you sure you want to delete this Award?')
        setSecondaryModalText('This will be removed from your profile.')
        setCurrentAward(award)
        setSmShow(true)
    }

    const handleDeleteAward = () => {
        setCompanyAwards(companyAwards?.filter((item) => item !== currentAward))
        setSmShow(false)
    }

    const [updatedAward, setUpdatedAward] = useState(null)
    const [updatedAwardDate, setUpdatedAwardDate] = useState(null)
    const handleUpdateAward = (name, date, i) => {
        console.log(date)
        const newDate = updatedAwardDate
            ? new Date(updatedAwardDate)
            : new Date(date)
        setCompanyAwards(
            companyAwards?.map((award, index) => {
                if (index === i) {
                    return {
                        ...award,
                        name: updatedAward ? updatedAward : name,
                        date: newDate ? newDate : date,
                    }
                } else {
                    return award
                }
            })
        )
        setIsEditing(null)
        setUpdatedAward(null)
        setUpdatedAwardDate(null)
    }

    const handleUploadProfileURL = () => {
        console.log('clked')
        document.getElementById('profileUrl-input')?.click()
    }

    useEffect(() => {
        console.log(profile)
    }, [])

    const handleFeaturedVid = (ind) => {
        let copy = profile?.vidFromHeart?.map((vid, index) => {
            return {
                ...vid,
                featured: index == ind ? true : false,
            }
        })
        setProfile({ ...profile, vidFromHeart: copy })
    }

    const [playingVid, setPlayingVid] = useState([])

    // useEffect(() => {
    //     const vidIndexes = vidFromHeart?.map((vid) => {
    //         return false
    //     })
    //     setPlayingVid(vidIndexes)
    // }, [vidFromHeart])

    const [showPitch, setShowPitch] = useState(true)
    const [currentVideoLink, setCurrentVideoLink] = useState(null)
    const handlePlayVideo = () => {
        setPlayVideo(!playVideo)
    }
    const playVid = (link) => {
        setCurrentVideoLink(link)
        handlePlayVideo()
    }

    const [forceRerender, setForceRerender] = useState(0)
    useEffect(() => {
        if (profile) {
            setForceRerender(0)

            const timer = setTimeout(() => {
                setForceRerender(forceRerender + 1)
            }, 100)
            return () => clearTimeout(timer)
        }
    }, [profile])
    return (
        <div
            // className="edit-profile-bg"
            // style={{ overFlow: 'scroll' }}
            onDrag={() => setIsDragging(true)}
            onDragEnd={() => setIsDragging(false)}
            onMouseDown={() => setIsDragging(false)}
        >
            <div className="mb-5">
                <div className="row position-relative">
                    <div className="col-md-12">
                        <div className="">
                            <div className="card-body p-3 p-md-5">
                                {/* <h4 className="text-muted mb-3">
                                    <strong>Company Profile</strong>
                                </h4> */}
                                <div className="d-flex mb-4">
                                    <img
                                        src={companyInformationIcon}
                                        alt=""
                                        className="me-2"
                                        style={{ height: 20, width: 20 }}
                                    />
                                    <p
                                        className="text-primary"
                                        onClick={() => {
                                            console.log(profile)
                                        }}
                                    >
                                        <strong>Company Information</strong>
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Profile and Cover Photo</strong>{' '}
                                        *
                                    </label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div
                                                style={{
                                                    height: 284,
                                                    border: '1px solid #C9CCCE',
                                                    borderRadius: 10,
                                                    backgroundImage: `url(${
                                                        coverPhotoUrl
                                                            ? coverPhotoUrl
                                                            : profile?.coverUrl
                                                            ? profile?.coverUrl
                                                            : defaultCover
                                                    })`,
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                    backgroundPosition:
                                                        'center',
                                                    backgroundSize: 'cover',
                                                }}
                                                className="d-flex align-items-center justify-content-center position-relative"
                                            >
                                                <div
                                                    className="position-absolute"
                                                    style={{
                                                        background:
                                                            'rgb(0,0,0)',
                                                        background:
                                                            'linear-gradient(0deg, rgba(0,0,0,0.6306897759103641) 0%, rgba(255,255,255,0.25253851540616246) 100%)',
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                    }}
                                                >
                                                    <div className="d-flex w-100 h-100 align-items-end justify-content-end">
                                                        <div className="me-2 mb-2">
                                                            {!coverPhotoUrl ? (
                                                                <>
                                                                    <img
                                                                        src={
                                                                            editCircle
                                                                        }
                                                                        alt=""
                                                                        class={`hand ${
                                                                            profile?.coverUrl &&
                                                                            'me-2'
                                                                        }`}
                                                                        onClick={() => {
                                                                            document
                                                                                .getElementById(
                                                                                    'coverPhotoUrl'
                                                                                )
                                                                                .click()
                                                                        }}
                                                                    />
                                                                    <input
                                                                        type="file"
                                                                        id="coverPhotoUrl"
                                                                        accept="image/png, image/jpeg"
                                                                        hidden
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleSetCoverPhotoUrl(
                                                                                e
                                                                                    .target
                                                                                    .files[0]
                                                                            )
                                                                        }}
                                                                    />
                                                                    {profile?.coverUrl && (
                                                                        <img
                                                                            src={
                                                                                deleteCircle
                                                                            }
                                                                            alt=""
                                                                            class="hand"
                                                                            onClick={() => {
                                                                                setCoverPhotoUrl(
                                                                                    null
                                                                                )
                                                                                setProfile(
                                                                                    {
                                                                                        ...profile,
                                                                                        coverUrl:
                                                                                            null,
                                                                                    }
                                                                                )
                                                                            }}
                                                                        />
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img
                                                                        src={
                                                                            editCircle
                                                                        }
                                                                        alt=""
                                                                        class={`hand me-2`}
                                                                        onClick={() => {
                                                                            document
                                                                                .getElementById(
                                                                                    'coverPhotoUrl'
                                                                                )
                                                                                .click()
                                                                        }}
                                                                    />
                                                                    <input
                                                                        type="file"
                                                                        id="coverPhotoUrl"
                                                                        accept="image/png, image/jpeg"
                                                                        hidden
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleSetCoverPhotoUrl(
                                                                                e
                                                                                    .target
                                                                                    .files[0]
                                                                            )
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={
                                                                            deleteCircle
                                                                        }
                                                                        alt=""
                                                                        class="hand"
                                                                        onClick={() => {
                                                                            setCoverPhotoUrl(
                                                                                null
                                                                            )
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-12"
                                            style={{ zIndex: 100 }}
                                        >
                                            <div
                                                style={{
                                                    height: 167,
                                                    width: 167,
                                                    border: '1px solid #C9CCCE',
                                                    borderRadius: 10,
                                                    // marginTop: '-84px',
                                                    backgroundImage: `url(${
                                                        profileUrl
                                                            ? profileUrl
                                                            : profile?.profileUrl
                                                            ? profile?.profileUrl
                                                            : defaultImg
                                                    })`,
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                    backgroundPosition:
                                                        'center',
                                                    backgroundSize: 'cover',
                                                    marginTop: '-75px',
                                                }}
                                                className="bg-white ms-3 d-flex align-items-end justify-content-end position-relative"
                                            >
                                                <div
                                                    className="position-absolute"
                                                    style={{
                                                        background:
                                                            'rgb(0,0,0)',
                                                        background:
                                                            'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0.01) 100%)',
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                    }}
                                                >
                                                    <div className="d-flex w-100 h-100 align-items-end justify-content-end">
                                                        <div className="me-2 mb-2">
                                                            {!profile?.profileUrl ? (
                                                                !profileUrl ? (
                                                                    <>
                                                                        <img
                                                                            src={
                                                                                editCircle
                                                                            }
                                                                            alt=""
                                                                            class="hand "
                                                                            onClick={() => {
                                                                                handleUploadProfileURL()
                                                                            }}
                                                                        />
                                                                        <input
                                                                            type="file"
                                                                            id="profileUrl-input"
                                                                            accept="image/png, image/jpeg"
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                handleSetProfileUrl(
                                                                                    e
                                                                                        .target
                                                                                        .files[0]
                                                                                )
                                                                            }}
                                                                            hidden
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                src={
                                                                                    deleteCircle
                                                                                }
                                                                                alt=""
                                                                                class="hand"
                                                                                onClick={() => {
                                                                                    setProfileUrl(
                                                                                        null
                                                                                    )
                                                                                    setProfile(
                                                                                        {
                                                                                            ...profile,
                                                                                            profileUrl:
                                                                                                null,
                                                                                        }
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            ) : (
                                                                <>
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                deleteCircle
                                                                            }
                                                                            alt=""
                                                                            class="hand"
                                                                            onClick={() => {
                                                                                setProfileUrl(
                                                                                    null
                                                                                )
                                                                                setProfile(
                                                                                    {
                                                                                        ...profile,
                                                                                        profileUrl:
                                                                                            null,
                                                                                    }
                                                                                )
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="" className="mb-2">
                                                <strong
                                                    onClick={() => {
                                                        console.log(profile)
                                                    }}
                                                >
                                                    Company Name
                                                </strong>{' '}
                                                *
                                            </label>

                                            <input
                                                type="text"
                                                className={`form-control br-3 ${
                                                    companyNameError &&
                                                    'border-danger'
                                                }`}
                                                placeholder="Company Name"
                                                defaultValue={
                                                    profile?.companyName
                                                }
                                                onChange={(e) => {
                                                    setCompanyName(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                            {companyNameError && (
                                                <span className="text-danger">
                                                    <small>
                                                        Company Name is required
                                                    </small>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="" className="mb-2">
                                                <strong>Company Website</strong>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control br-3"
                                                placeholder="https://joblu.io"
                                                defaultValue={
                                                    profile?.companyWebsite
                                                }
                                                onChange={(e) => {
                                                    setCompanyWebsite(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                            {/* {companyWebsiteError && (
                                                <span className="text-danger">
                                                    <small>
                                                        Company Website is
                                                        required
                                                    </small>
                                                </span>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-6 mb-4 mb-md-0">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Location</strong> *
                                        </label>
                                        {/* <SelectCountry2
                                            onChange={(value) => {
                                                setCountry(value)
                                            }}
                                            value={
                                                country
                                                    ? country
                                                    : profile?.country
                                            }
                                            noCellCode
                                            noAll
                                        /> */}
                                        <CustomGooglePlaces
                                            onChange={(value) => {
                                                setCountry(value.country)
                                                setCurrentLocation(
                                                    value.location
                                                )
                                                setRegion(value?.region)
                                                setCity(value?.city)
                                            }}
                                            value={currentLocation}
                                        />
                                        {locationError && (
                                            <p className="text-danger">
                                                {locationError}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Phone Number</strong>
                                        </label>
                                        <div className="d-flex">
                                            {tempCC && (
                                                <Select
                                                    className="basic-single w-100 me-2"
                                                    classNamePrefix="select"
                                                    defaultValue={tempCC}
                                                    isSearchable={isSearchable}
                                                    name="country-code"
                                                    onChange={(e) => {
                                                        // console.log(e.value)
                                                        setCountry_Code(e.value)
                                                    }}
                                                    options={countryCodesList}
                                                />
                                            )}
                                            <select
                                                name=""
                                                className="form-select me-2 d-none"
                                                id=""
                                                onChange={(e) => {
                                                    setCountry_Code(
                                                        e.target.value
                                                    )
                                                }}
                                            >
                                                {countryCodesList.map(
                                                    (item) => {
                                                        return (
                                                            <>
                                                                <option
                                                                    value={
                                                                        item?.value
                                                                    }
                                                                    selected={
                                                                        country_code ==
                                                                        item?.value
                                                                    }
                                                                >
                                                                    {
                                                                        item?.value
                                                                    }
                                                                </option>
                                                            </>
                                                        )
                                                    }
                                                )}
                                            </select>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    className={`form-control br-3 ${
                                                        phoneNumberError &&
                                                        'border-danger'
                                                    }`}
                                                    placeholder="123456789"
                                                    value={phoneNumber}
                                                    pattern="\d{1,5}"
                                                    // value={phoneNumber}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value
                                                                .length <= 14
                                                        ) {
                                                            console.log(
                                                                'trimming'
                                                            )
                                                            setPhoneNumber(
                                                                trimPhone(
                                                                    e.target
                                                                        .value,
                                                                    0
                                                                )
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {phoneNumberError && (
                                            <span className="text-danger">
                                                <small>
                                                    Phone number is required.
                                                </small>
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Company Size</strong> *
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control br-3  ${
                                                companySizeError &&
                                                'border-danger'
                                            }`}
                                            placeholder="Company Size"
                                            defaultValue={profile?.companySize}
                                            onChange={(e) => {
                                                setCompanySize(e.target.value)
                                            }}
                                        />
                                        {companySizeError && (
                                            <span className="text-danger">
                                                <small>
                                                    {companySizeErrorMsg}
                                                </small>
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Year Established</strong> *
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control br-3  ${
                                                yearEstablishedError &&
                                                'border-danger'
                                            }`}
                                            placeholder="Year Established"
                                            defaultValue={yearEstablished}
                                            value={yearEstablished}
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length <= 4
                                                ) {
                                                    setYearEstablished(
                                                        e.target.value
                                                    )
                                                }
                                            }}
                                        />
                                        {yearEstablishedError && (
                                            <span className="text-danger">
                                                <small>{yearEstErrorMsg}</small>
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Work Setup</strong> *
                                        </label>
                                        <select
                                            name=""
                                            onChange={(e) => {
                                                setWorkSetup(e.target.value)
                                            }}
                                            className={`form-select ${
                                                workSetupError &&
                                                'border-danger'
                                            }`}
                                            id=""
                                        >
                                            <option value="" disabled selected>
                                                Select Work Setup
                                            </option>
                                            <option
                                                value="On-site"
                                                selected={
                                                    workSetup == 'On-site'
                                                }
                                            >
                                                On-site
                                            </option>
                                            <option
                                                value="Remote"
                                                selected={workSetup == 'Remote'}
                                            >
                                                Remote
                                            </option>
                                            <option
                                                value="Hybrid"
                                                selected={workSetup == 'Hybrid'}
                                            >
                                                Hybrid
                                            </option>
                                        </select>
                                        {workSetupError && (
                                            <span className="text-danger">
                                                <small>
                                                    Work Setup is required.
                                                </small>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Tagline</strong>
                                        <small className="text-muted d-block">
                                            Share your company tagline.
                                        </small>
                                    </label>
                                    <textarea
                                        name=""
                                        className={`form-control br-3 mb-2`}
                                        id=""
                                        cols="30"
                                        rows="5"
                                        defaultValue={profile?.tagline}
                                        onChange={(e) => {
                                            setTagline(e.target.value)
                                        }}
                                    ></textarea>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>
                                            Short Company Description *
                                        </strong>
                                        <small className="text-muted d-block">
                                            Share a brief history about your
                                            company, what’s your core product
                                            and services and values.
                                        </small>
                                    </label>
                                    <textarea
                                        name=""
                                        className={`form-control br-3 mb-2${
                                            companyOverviewError &&
                                            'border-danger'
                                        }`}
                                        id=""
                                        cols="30"
                                        rows="5"
                                        defaultValue={profile?.companyOverview}
                                        onChange={(e) => {
                                            setCompanyOverview(e.target.value)
                                        }}
                                    ></textarea>
                                    {companyOverviewError && (
                                        <span className="text-danger">
                                            <small>
                                                Short Cmpany Description is
                                                required
                                            </small>
                                        </span>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Company Vision</strong>
                                        <small className="text-muted d-block">
                                            Share you company's mission or
                                            vision to job seekers.
                                        </small>
                                    </label>
                                    <textarea
                                        name=""
                                        className="form-control br-3"
                                        id=""
                                        cols="30"
                                        rows="5"
                                        defaultValue={profile?.companyVision}
                                        onChange={(e) => {
                                            setCompanyVision(e.target.value)
                                        }}
                                    ></textarea>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Company Mission</strong>
                                        <small className="text-muted d-block">
                                            Share you company's mission or
                                            vision to job seekers.
                                        </small>
                                    </label>
                                    <textarea
                                        name=""
                                        className="form-control br-3"
                                        id=""
                                        cols="30"
                                        rows="5"
                                        defaultValue={profile?.companyMission}
                                        onChange={(e) => {
                                            setCompanyMission(e.target.value)
                                        }}
                                    ></textarea>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Company Values</strong>
                                        <small className="text-muted d-block">
                                            Share you company's mission or
                                            vision to job seekers.
                                        </small>
                                    </label>
                                    <textarea
                                        name=""
                                        className="form-control br-3"
                                        id=""
                                        cols="30"
                                        rows="5"
                                        defaultValue={profile?.companyValues}
                                        onChange={(e) => {
                                            setCompanyValues(e.target.value)
                                        }}
                                    ></textarea>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Products and Services</strong>
                                        <small className="text-muted d-block">
                                            Please write down the products and
                                            services your company is offering.
                                        </small>
                                    </label>
                                    <textarea
                                        name=""
                                        className="form-control br-3"
                                        id=""
                                        cols="30"
                                        rows="5"
                                        defaultValue={
                                            profile?.productsAndServices
                                        }
                                        onChange={(e) => {
                                            setProductsAndServices(
                                                e.target.value
                                            )
                                        }}
                                    ></textarea>
                                </div>
                                <div className="d-flex mb-4">
                                    <img
                                        src={companyInformationIcon}
                                        alt=""
                                        style={{ height: 20, width: 20 }}
                                        className="me-2"
                                    />
                                    <p className="text-primary">
                                        <strong>
                                            Make your company standout!
                                        </strong>
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Company Culture</strong>
                                    </label>
                                    <div>
                                        <small className="text-muted d-block">
                                            Description
                                        </small>
                                        <textarea
                                            name=""
                                            className="form-control br-3 mb-4"
                                            id=""
                                            cols="30"
                                            rows="5"
                                            defaultValue={
                                                profile?.cultureDescription
                                            }
                                            onChange={(e) => {
                                                setCultureDescription(
                                                    e.target.value
                                                )
                                            }}
                                        ></textarea>
                                    </div>

                                    <ul>
                                        <li>
                                            <small className="text-muted">
                                                Upload up to 10 photos or videos
                                                showcasing your company’s
                                                culture that job seekers will
                                                see on your job posts.
                                            </small>
                                        </li>
                                        <li>
                                            <small className="text-muted">
                                                Choose which photo or video to
                                                include on your job post by
                                                clicking the image.
                                            </small>
                                        </li>
                                        {/* <li>
                                            <small className="text-muted">
                                                You can use up to ten ({}/10)
                                                photos or videos.
                                            </small>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="mb-4">
                                    <div className="d-flex flex-wrap justify-content-center">
                                        {Array.from(
                                            Array(COMPANY_CULTURE_LENGTH)
                                        ).map((x, index) => {
                                            const item = cultureFiles[index]

                                            const isExternalVideo =
                                                (item?.link &&
                                                    createURL(item?.link)
                                                        ?.hostname ===
                                                        'www.youtube.com') ||
                                                (item?.link &&
                                                    createURL(item?.link)
                                                        ?.hostname ===
                                                        'vimeo.com')

                                            const isVideo =
                                                item?.link
                                                    ?.split('.')
                                                    ?.pop() === 'mp4' ||
                                                isExternalVideo
                                            return (
                                                <div
                                                    className="d-flex"
                                                    style={{ margin: 14 }}
                                                >
                                                    <input
                                                        type="file"
                                                        multiple={false}
                                                        id={`file-uploader-${index}`}
                                                        hidden
                                                        onChange={(e) =>
                                                            handleSetFiles(
                                                                e.target
                                                                    .files[0],
                                                                index
                                                            )
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            border: '1px dashed #002E5D',
                                                            borderRadius: 10,

                                                            backgroundImage: `url(${
                                                                !isVideo &&
                                                                item?.link &&
                                                                item?.link
                                                            })`,
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            backgroundPosition:
                                                                'center',
                                                            backgroundSize:
                                                                'contain',
                                                            width: 167,
                                                            height: 167,
                                                        }}
                                                        className="position-relative d-flex align-items-center justify-content-center flex-column"
                                                    >
                                                        {isUploading ===
                                                        index ? (
                                                            <div>
                                                                {' '}
                                                                <div
                                                                    class="spinner-border"
                                                                    role="status"
                                                                >
                                                                    <span class="visually-hidden"></span>
                                                                </div>
                                                            </div>
                                                        ) : !item?.link ? (
                                                            <Hoverable
                                                                renderOnHover={() =>
                                                                    !dragging &&
                                                                    uploadFileOptions(
                                                                        item,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Dropzone
                                                                    onDrop={(
                                                                        files
                                                                    ) => {
                                                                        handleSetFiles(
                                                                            files[0],
                                                                            index
                                                                        )
                                                                    }}
                                                                >
                                                                    {({
                                                                        getRootProps,
                                                                        getInputProps,
                                                                    }) => {
                                                                        // )
                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    {...getRootProps()}
                                                                                    className="d-flex w-100 h-100 d-flex"
                                                                                >
                                                                                    {!dragging ? (
                                                                                        <img
                                                                                            src={
                                                                                                uploadPlusIcon
                                                                                            }
                                                                                            style={{
                                                                                                width: 20,
                                                                                                height: 20,
                                                                                            }}
                                                                                            className="hand m-auto"
                                                                                            alt=""
                                                                                        />
                                                                                    ) : (
                                                                                        <p>
                                                                                            Drop
                                                                                            here
                                                                                        </p>
                                                                                    )}
                                                                                    <input
                                                                                        {...getInputProps()}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }}
                                                                </Dropzone>
                                                            </Hoverable>
                                                        ) : (
                                                            <>
                                                                <div className="position-absolute w-100 h-100">
                                                                    <Hoverable
                                                                        renderOnHover={() =>
                                                                            viewFileOptions(
                                                                                item,
                                                                                index,
                                                                                isVideo
                                                                            )
                                                                        }
                                                                    >
                                                                        <>
                                                                            <input
                                                                                className="form-check-input position-absolute chk"
                                                                                style={{
                                                                                    right: 12,
                                                                                    top: 10,
                                                                                    zIndex: 999,
                                                                                }}
                                                                                type="checkbox"
                                                                                id="checkboxNoLabel"
                                                                                value=""
                                                                                aria-label="..."
                                                                                defaultChecked={
                                                                                    item?.featured
                                                                                }
                                                                                onChange={() => {
                                                                                    handleFeatured(
                                                                                        index
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </>
                                                                        {isVideo && (
                                                                            <div className="position-relative w-100 h-100">
                                                                                <ReactPlayer
                                                                                    url={
                                                                                        item?.link
                                                                                    }
                                                                                    width={
                                                                                        '100%'
                                                                                    }
                                                                                    height={
                                                                                        '100%'
                                                                                    }
                                                                                />
                                                                                <div
                                                                                    className="position-absolute d-flex"
                                                                                    style={{
                                                                                        top: 0,
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            videoPlaybackIcon
                                                                                        }
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: 50,
                                                                                            height: 50,
                                                                                            zIndex: 100,
                                                                                        }}
                                                                                        className="m-auto"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Hoverable>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                {/* <div className="mb-4">
                                    <p className="mb-2">
                                        <strong>Videos</strong>
                                    </p>
                                    <div className="row">
                                        <div className="col mb-3">
                                            <div
                                                style={{
                                                    border: '1px dashed #002E5D',
                                                    borderRadius: 10,
                                                    height: 160,
                                                }}
                                                className="d-flex align-items-center justify-content-center flex-column"
                                            >
                                                <p className="text-primary hand">
                                                    +
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div
                                                style={{
                                                    border: '1px dashed #002E5D',
                                                    borderRadius: 10,
                                                    height: 160,
                                                }}
                                                className="d-flex align-items-center justify-content-center flex-column"
                                            >
                                                <p className="text-primary hand">
                                                    +
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div
                                                style={{
                                                    border: '1px dashed #002E5D',
                                                    borderRadius: 10,
                                                    height: 160,
                                                }}
                                                className="d-flex align-items-center justify-content-center flex-column"
                                            >
                                                <p className="text-primary hand">
                                                    +
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div
                                                style={{
                                                    border: '1px dashed #002E5D',
                                                    borderRadius: 10,
                                                    height: 160,
                                                }}
                                                className="d-flex align-items-center justify-content-center flex-column"
                                            >
                                                <p className="text-primary hand">
                                                    +
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col mb-3">
                                            <div
                                                style={{
                                                    border: '1px dashed #002E5D',
                                                    borderRadius: 10,
                                                    height: 160,
                                                }}
                                                className="d-flex align-items-center justify-content-center flex-column"
                                            >
                                                <p className="text-primary hand">
                                                    +
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Straight from the Heart</strong>
                                        <small className="text-muted d-block">
                                            We suggest to include video messages
                                            from your leadership to encourage
                                            job seekers to apply to your job
                                            post.
                                        </small>
                                    </label>
                                    <div className="row align-items-end">
                                        <div className="col-md-12 col-lg-6 mb-4 mb-md-0">
                                            <div className="">
                                                <label
                                                    htmlFor=""
                                                    className="small"
                                                >
                                                    Request leadership video
                                                    link.
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <div className="input-group me-2">
                                                        <input
                                                            type="text"
                                                            className="form-control w-auto border-end-0 open-sans"
                                                            placeholder="link here"
                                                            aria-label="link here"
                                                            aria-describedby="basic-addon2"
                                                            // onChange={(e) => {
                                                            //     setVideoLink(
                                                            //         e.target
                                                            //             .value
                                                            //     )
                                                            // }}
                                                            readOnly
                                                            value={
                                                                showCaseLinks?.vidFromHeart
                                                            }
                                                        />
                                                        <div className="input-group-append ">
                                                            <span
                                                                className="input-group-text bg-white h-100"
                                                                id="basic-addon2 "
                                                            >
                                                                <img
                                                                    src={
                                                                        copyIcon
                                                                    }
                                                                    className="hand"
                                                                    alt=""
                                                                    onClick={() => {
                                                                        handleCopyVideoLink(
                                                                            showCaseLinks?.vidFromHeart
                                                                        )
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {videoLinkCopied && (
                                            <div className="col-md-4 col-lg-6 mb-2">
                                                <div>
                                                    <span className="text-success small">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-check2 me-2"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg>
                                                        Copied
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-4 row w-100 d-none">
                                    {profile?.vidFromHeart?.map(
                                        (vid, index) => {
                                            return (
                                                <div
                                                    style={{
                                                        borderRadius: 10,
                                                    }}
                                                    className="col-12 col-sm-3 col-md-3 border shadow-sm m-1"
                                                >
                                                    <div className="w-100">
                                                        <div
                                                            className="mb-3"
                                                            style={{
                                                                height: 200,
                                                            }}
                                                        >
                                                            <div className="position-relative w-100 h-100">
                                                                <ReactPlayer
                                                                    url={
                                                                        vid?.link
                                                                    }
                                                                    width={
                                                                        '100%'
                                                                    }
                                                                    height={
                                                                        '100%'
                                                                    }
                                                                />
                                                                <div
                                                                    className="position-absolute d-flex"
                                                                    style={{
                                                                        top: 0,
                                                                        width: '100%',
                                                                        height: '100%',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            videoPlaybackIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                            width: 50,
                                                                            height: 50,
                                                                            zIndex: 100,
                                                                        }}
                                                                        className="m-auto"
                                                                        onClick={() => {
                                                                            setPlayVideo(
                                                                                vid?.link
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100 px-2 align-items-center py-2">
                                                            <img
                                                                src={
                                                                    trashbinIcon
                                                                }
                                                                alt=""
                                                                onClick={() => {
                                                                    let cpy =
                                                                        profile.vidFromHeart
                                                                    cpy.splice(
                                                                        index,
                                                                        1
                                                                    )

                                                                    setProfile({
                                                                        ...profile,
                                                                        vidFromHeart:
                                                                            cpy,
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                                <div className="mb-4 d-none">
                                    <strong>Job card preview</strong>
                                    <small className="text-muted d-block mb-4">
                                        Rearrange your contents by dragging.
                                    </small>
                                    <div>
                                        <SortableList
                                            items={list}
                                            onSortEnd={onSortEnd}
                                            axis={'xy'}
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 d-none">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Awards</strong>
                                    </label>
                                    <div className="row align-items-end">
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <label htmlFor="" className="small">
                                                Award name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="award-name"
                                                onChange={(e) => {
                                                    setAwardName(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <label htmlFor="" className="small">
                                                Date awarded
                                                {awardDateError && (
                                                    <span className="error-helper ms-2">
                                                        Invalid date
                                                    </span>
                                                )}
                                            </label>
                                            <input
                                                type="date"
                                                className={`form-control ${
                                                    awardDateError &&
                                                    'border-danger'
                                                }`}
                                                id="award-date"
                                                onChange={(e) => {
                                                    setAwardDate(e.target.value)
                                                }}
                                                max={moment().format(
                                                    'YYYY-MM-DD'
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <button
                                                className={`btn text-center w-100 ${
                                                    awardName && awardDate
                                                        ? ' btn-outline-primary'
                                                        : 'disabled btn-outline-secondary'
                                                }`}
                                                onClick={() => {
                                                    addAward()
                                                }}
                                            >
                                                Add award
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* {companyAwards?.length > 0 && (
                                    <div className="mb-4 d-none">
                                        <label htmlFor="" className="mb-2">
                                            <strong>List of Awards</strong>
                                        </label>
                                        <div>
                                            {companyAwards?.map(
                                                (award, index) => {
                                                    return (
                                                        <>
                                                            <div className="card mb-2">
                                                                <div className="card-body p-4 ">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <p>
                                                                                <strong>
                                                                                    {
                                                                                        award?.name
                                                                                    }
                                                                                </strong>
                                                                            </p>
                                                                            <p>
                                                                                {moment(
                                                                                    award?.date
                                                                                ).format(
                                                                                    'MMM DD, YYYY'
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <button
                                                                                className="btn btn-outline-primary me-2"
                                                                                onClick={() => {
                                                                                    handleShowEdit(
                                                                                        index
                                                                                    )
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-outline-primary"
                                                                                onClick={() => {
                                                                                    handleAwardDeleteModal(
                                                                                        award,
                                                                                        index
                                                                                    )
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {isEditing ===
                                                                        index && (
                                                                        <div>
                                                                            <hr />
                                                                            <div>
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            defaultValue={
                                                                                                award.name
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) => {
                                                                                                setUpdatedAward(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <input
                                                                                            type="date"
                                                                                            className="form-control w-100"
                                                                                            defaultValue={moment(
                                                                                                award.date
                                                                                            ).format(
                                                                                                'YYYY-MM-DD'
                                                                                            )}
                                                                                            onChange={(
                                                                                                e
                                                                                            ) => {
                                                                                                setUpdatedAwardDate(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }}
                                                                                            max={moment().format(
                                                                                                'YYYY-MM-DD'
                                                                                            )}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-3 text-end">
                                                                                        <button
                                                                                            className="btn btn-outline-primary me-2"
                                                                                            onClick={() => {
                                                                                                handleUpdateAward(
                                                                                                    award.name,
                                                                                                    award.date,
                                                                                                    index
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            Save
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn btn-outline-primary"
                                                                                            onClick={() => {
                                                                                                handleHideEdit(
                                                                                                    index
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            Cancel
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                )} */}
                                {profile?.vidFromHeart?.length > 0 && (
                                    <>
                                        <div className="mb-4">
                                            {profile?.vidFromHeart?.map(
                                                (vid, index) => {
                                                    return (
                                                        <div
                                                            className="card mb-3"
                                                            key={index}
                                                        >
                                                            <div className="card-body justify-content-center justify-content-md-start d-flex flex-column flex-md-row">
                                                                <div
                                                                    className="mb-3 me-3 w-100"
                                                                    style={{
                                                                        flexBasis:
                                                                            '20%',
                                                                    }}
                                                                >
                                                                    <div className="editProf w-100 position-relative">
                                                                        <div>
                                                                            {forceRerender &&
                                                                                vid?.link && (
                                                                                    <>
                                                                                        <VideoPlayer
                                                                                            videoUrl={
                                                                                                vid?.link
                                                                                            }
                                                                                            snapShotAt={
                                                                                                2
                                                                                            }
                                                                                        />
                                                                                        {/* <VideoThumbnail
                                                                                            videoUrl={
                                                                                                vid?.link
                                                                                            }
                                                                                            width={
                                                                                                30
                                                                                            }
                                                                                        /> */}
                                                                                    </>
                                                                                )}
                                                                        </div>
                                                                        <div
                                                                            className="position-absolute"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#0000004D',
                                                                                top: 0,
                                                                                left: 0,
                                                                                right: 0,
                                                                                bottom: 0,
                                                                            }}
                                                                        ></div>
                                                                        <img
                                                                            src={
                                                                                profilePlayIcon
                                                                            }
                                                                            alt=""
                                                                            className="position-absolute hand"
                                                                            width={
                                                                                30
                                                                            }
                                                                            onClick={() => {
                                                                                playVid(
                                                                                    vid?.link
                                                                                )
                                                                            }}
                                                                            style={{
                                                                                left: '50%',
                                                                                top: '50%',
                                                                                transform:
                                                                                    'translate(-50%, -50%)',
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    {/* <CustomVideoPlayer
                                                            index={index}
                                                            src={vid.link}
                                                            //put resizeMode cover like style
                                                            width="100%"
                                                            height={'auto'}
                                                            play={
                                                                playingVid[
                                                                    index
                                                                ]
                                                            }
                                                            videoIndex={index}
                                                            onPlayPause={(
                                                                videoIndex,
                                                                status
                                                            ) => {
                                                                let copy =
                                                                    playingVid.map(
                                                                        () => {
                                                                            return false
                                                                        }
                                                                    )
                                                                copy[
                                                                    videoIndex
                                                                ] = status
                                                                setPlayingVid(
                                                                    copy
                                                                )
                                                            }}
                                                        /> */}
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-center justify-content-start">
                                                                    <div className="form-check form-switch me-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            checked={
                                                                                vid?.featured
                                                                            }
                                                                            onClick={() => {
                                                                                handleFeaturedVid(
                                                                                    index
                                                                                )
                                                                                // let copy =
                                                                                //     profile

                                                                                // copy.vidFromHeart[
                                                                                //     index
                                                                                // ].featured =
                                                                                //     !profile
                                                                                //         .vidFromHeart[
                                                                                //         index
                                                                                //     ]
                                                                                //         .featured
                                                                                // setProfile(
                                                                                //     {
                                                                                //         ...copy,
                                                                                //     }
                                                                                // )
                                                                            }}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="flexSwitchCheckDefault"
                                                                        >
                                                                            Show
                                                                            on
                                                                            Profile
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <button
                                                                            onClick={() => {
                                                                                let cpy =
                                                                                    profile?.vidFromHeart
                                                                                cpy?.splice(
                                                                                    index,
                                                                                    1
                                                                                )
                                                                                setProfile(
                                                                                    {
                                                                                        ...profile,
                                                                                        vidFromHeart:
                                                                                            cpy,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            className="btn btn-outline-primary"
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </>
                                )}

                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        <strong>Employee Review</strong>
                                        <small className="text-muted d-block">
                                            Add more credibility to your company
                                            by adding employee reviews. You can
                                            ask your employees to give reviews
                                            by sending them a review link.
                                        </small>
                                    </label>
                                    <div className="row align-items-end">
                                        <div className="col-md-8 col-lg-6 mb-4 mb-md-0">
                                            <div className="">
                                                <div className="d-flex align-items-center">
                                                    <div className="input-group me-2">
                                                        <input
                                                            type="text"
                                                            className="form-control w-auto border-end-0"
                                                            placeholder="link here"
                                                            aria-label="link here"
                                                            aria-describedby="basic-addon2"
                                                            readOnly
                                                            value={
                                                                showCaseLinks?.employeeReview
                                                            }
                                                        />
                                                        <div className="input-group-append ">
                                                            <span
                                                                className="input-group-text bg-white h-100"
                                                                id="basic-addon2 "
                                                            >
                                                                <img
                                                                    src={
                                                                        copyIcon
                                                                    }
                                                                    className="hand"
                                                                    alt=""
                                                                    onClick={() => {
                                                                        handleCopyEmployeeReview(
                                                                            showCaseLinks?.employeeReview
                                                                        )
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {employeeReviewCopied && (
                                            <div className="col-md-4 col-lg-6 mb-2">
                                                <div>
                                                    <span className="text-success small">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-check2 me-2"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg>
                                                        Copied
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {profile?.employeeReview?.length > 0 && (
                                    <>
                                        <div className="mb-4">
                                            <label htmlFor="" className="mb-2">
                                                <strong>
                                                    Available Employee Review
                                                </strong>
                                            </label>

                                            {profile?.employeeReview?.map(
                                                (review, index) => {
                                                    return (
                                                        <div
                                                            className="card mb-3"
                                                            key={index}
                                                        >
                                                            <div className="card-body">
                                                                <div className="mb-3">
                                                                    <p>
                                                                        {
                                                                            review?.review
                                                                        }
                                                                    </p>
                                                                    <br />
                                                                    <p>
                                                                        <strong>
                                                                            {
                                                                                review?.name
                                                                            }
                                                                        </strong>
                                                                    </p>
                                                                    <p>
                                                                        {
                                                                            review?.jobTitle
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex align-items-center ">
                                                                    <div className="form-check form-switch me-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            checked={
                                                                                review?.featured
                                                                            }
                                                                            onClick={() => {
                                                                                let copy =
                                                                                    profile

                                                                                copy.employeeReview[
                                                                                    index
                                                                                ].featured =
                                                                                    !profile
                                                                                        .employeeReview[
                                                                                        index
                                                                                    ]
                                                                                        .featured
                                                                                setProfile(
                                                                                    {
                                                                                        ...copy,
                                                                                    }
                                                                                )
                                                                            }}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="flexSwitchCheckDefault"
                                                                        >
                                                                            Show
                                                                            on
                                                                            Profile
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <button
                                                                            onClick={() => {
                                                                                let cpy =
                                                                                    profile.employeeReview
                                                                                cpy.splice(
                                                                                    index,
                                                                                    1
                                                                                )

                                                                                setProfile(
                                                                                    {
                                                                                        ...profile,
                                                                                        employeeReview:
                                                                                            cpy,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            className="btn btn-outline-primary"
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </>
                                )}

                                <div
                                    className="col-md-12 text-center"
                                    style={{ paddingTop: 50 }}
                                >
                                    {/* <button
                                        className="btn btn-outline-primary me-3"
                                        onClick={() => navigate('/profile')}
                                    >
                                        Cancel
                                    </button> */}
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            handleUpdate()
                                        }}
                                    >
                                        {!isSaving ? (
                                            <> Save Changes</>
                                        ) : (
                                            <>
                                                Saving
                                                <span
                                                    class="spinner-border spinner-border-sm ms-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            </>
                                        )}
                                    </button>
                                </div>
                                {error && (
                                    <div className="text-center mt-2">
                                        <span className="text-danger">
                                            <small>
                                                Saving failed. Please check all
                                                the fields.
                                            </small>
                                        </span>
                                    </div>
                                )}
                                {/* <div>
                                    <button className="btn btn-outline-primary text-center">
                                        Preview Job Card and Company Profile
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <JobluVideoPlayer
                src={currentVideoLink}
                play={playVideo}
                setPlayVideo={setPlayVideo}
                culture={true}
                mWidth={width}
            />
            {/* <JobluVideoPlayer
                src={playVideo}
                play={!!playVideo}
                setPlayVideo={setPlayVideo}
                fromCultureFiles
            /> */}
            <Modal show={!!showAddLink} centered>
                <div className="bg-light p-3">
                    <h6 className="open-sans">Enter Youtube or Vimeo link</h6>
                    <TextField
                        type="text"
                        placeholder="https://"
                        className="mb-2"
                        onChange={(e) => {
                            setShowAddLink({
                                ...showAddLink,
                                link: e.target.value,
                            })
                        }}
                    />
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-primary  m-1"
                            onClick={(e) => {
                                let cpy = [...cultureFiles]
                                cpy[showAddLink?.index] = {
                                    link: showAddLink?.link,
                                    featured: false,
                                }
                                setCultureFiles([...cpy])
                                setTimeout(() => {
                                    setShowAddLink(null)
                                }, 500)
                            }}
                            disabled={
                                !validateURL(showAddLink?.link || '') ||
                                (!!validateURL(showAddLink?.link || '') &&
                                    !ALLOWED_VIDEO_URLS.includes(
                                        createURL(showAddLink?.link).hostname
                                    ))
                            }
                        >
                            Save
                        </button>
                        <button
                            className="btn btn-outline-primary m-1"
                            onClick={() => {
                                setShowAddLink(null)
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
            <DeleteModal
                primaryModalText={primaryModalText}
                smShow={smShow}
                setSmShow={setSmShow}
                action={handleDeleteAward}
                secondaryModalText={secondaryModalText}
            />
            <Modal
                show={viewPhoto}
                size="lg"
                centered
                onHide={() => setViewPhoto(null)}
            >
                {/* <div
                    className="position-absolute"
                    style={{ right: -15, top: -14 }}
                >
                    <div
                        className="m-auto bg-dark p-1 "
                        style={{ borderRadius: '50%' }}
                        role="button"
                        onClick={() => setViewPhoto(null)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="white"
                            className="bi bi-x-lg"
                            viewBox="0 0 16 16"
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </div>
                </div> */}
                <Box style={{ position: 'absolute', right: 10, top: 10 }}>
                    <img
                        src={CloseIcon}
                        width={42}
                        className={`hand`}
                        onClick={() => {
                            setViewPhoto(false)
                        }}
                    />
                </Box>
                <img alt="" src={viewPhoto} style={{ width: '100%' }} />
            </Modal>
        </div>
    )
}
const mapDispatchToProps = {
    getUser: userActions.getUser,
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
