import moment from 'moment'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import GoogleLogin from 'react-google-login'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import d_android from '../../assets/btn-download-android.png'
import d_ios from '../../assets/btn-download-ios.png'
import jbHorizontal from '../../assets/joblu-logos/jb-horizontal.png'
import jobluwhitefooter from '../../assets/joblu-logos/joblu-white-footer.png'
import { Footer } from '../../components/auth/Footer'
import { Header } from '../../components/auth/Header'
import TextField from '../../components/inputs/CustomTextField'
import ErrorMessage from '../../components/reusables/ErrorMessage'
import Loading from '../../components/reusables/Loading'
import SuccessMessage from '../../components/reusables/SuccessMessage'
import { isSupported, messaging } from '../../firebase'
import userAction from '../../redux/user/actions'
import { creds } from '../../utils/constants'
import useWindowDimensions from '../../utils/hooks'
import { validateEmail } from '../../utils/validators'
import ExploreModal from '../explore/components/ExploreModal'
import './styles.css'
import GoogleReCaptcha from '../../components/google-captcha'

function Login(props) {
    const { width } = useWindowDimensions()
    const { signIn, User, verifyGoogleToken, resetUserError } = props
    const { isGoogleSignup } = User
    const [deviceToken, setDeviceToken] = useState(null)
    const { error, isSignedIn, isSigningIn } = User
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [googleResponse, setGoogleResponse] = useState(null)
    const [isVerified, setIsVerified] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const [errors, setErrors] = useState({
        email: '',
        password: '',
    })
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [verifiedCaptcha, setVerifiedCaptcha] = useState(false)

    useEffect(() => {
        try {
            isSupported &&
                Notification.requestPermission().then(() => {
                    return messaging
                        .getToken()
                        .then((token) => setDeviceToken(token))
                })
        } catch (e) {
            if (error instanceof TypeError) {
                if (isSupported)
                    Notification.requestPermission(() => {
                        messaging
                            .getToken()
                            .then((token) => setDeviceToken(token))
                    })
            } else {
                throw e
            }
        }
    }, [])

    const handleLogin = (e) => {
        const isValid = validateEmail(email)
        if (!!email) {
            if (!isValid) setErrors({ ...errors, email: 'Email not valid!' })
        }

        if (!password) {
            setErrors({ ...errors, password: 'Please enter your password' })
        } else {
            setErrors({ ...errors, password: null })
        }

        if (email.length === 0) {
            setErrors({ ...errors, email: 'Please enter your email' })
        }

        if (!!password && isValid) {
            setErrors({ password: null, email: null })
            if (isSupported)
                if (Notification.permission !== 'granted') {
                    signIn({ email, password })
                } else {
                    messaging
                        ? messaging.getToken().then((token) => {
                              signIn({ email, password, deviceToken: token })
                          })
                        : signIn({ email, password })
                }
            else signIn({ email, password })
        }
    }

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault()
                if(verifiedCaptcha)
                    handleLogin()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [handleLogin])

    const handleGoogleLogin = async (data) => {
        let payload
        if (isSupported) {
            if (Notification.permission !== 'granted') {
                payload = {
                    email: data?.profileObj?.email,
                    idToken: data?.tokenId,
                    action: 'login',
                    role: 'EMPLOYER',
                    platform: 'web',
                }
            } else {
                await messaging.getToken().then((token) => {
                    payload = {
                        email: data?.profileObj?.email,
                        idToken: data?.tokenId,
                        action: 'login',
                        deviceToken: token,
                        role: 'EMPLOYER',
                        platform: 'web',
                    }
                })
            }
        } else {
            payload = {
                email: data?.profileObj?.email,
                idToken: data?.tokenId,
                action: 'login',
                role: 'EMPLOYER',
                platform: 'web',
            }
        }
        if (payload?.deviceToken) {
            setGoogleResponse({ ...data, token: payload?.deviceToken })
        } else {
            setGoogleResponse(data)
        }
        verifyGoogleToken(payload)
    }

    useEffect(() => {
        resetUserError()
        if (location?.state?.verify) {
            setIsVerified(true)
        }
    }, [])

    useEffect(() => {
        console.log('isGoogleSignup', isGoogleSignup)
        if (!!googleResponse && !isSignedIn && !isSigningIn && isGoogleSignup) {
            setTimeout(() => {
                navigate('/signup', {
                    state: { googleResponse },
                })
            }, 1000)
        }
    }, [googleResponse, isSignedIn, isSigningIn, isGoogleSignup])

    const headerlinks = [
        {
            label: 'FOR JOBSEEKERS',
            url: 'https://joblu.io/jobseekers/',
        },
        {
            label: 'FOR COMPANIES',
            url: 'https://joblu.io/companies/',
        },
        {
            label: 'PARTNERSHIPS',
            url: 'https://joblu.io/partnerships/',
        },
        // {
        //     label: 'RESOURCES',
        //     url: '',
        // },
    ]
    return (
        <>
            <Header />
            <div className={`row  p-0 m-0`} style={{minHeight: width > 640 ? '100vh' : 'auto'}}>
                {/* HEADER */}
                {/* <div
                className="col-12 border py-3 mx-0"
                style={{ background: '#fff', maxHeight: '80px' }}
            >
                <div className="w-100 d-flex justify-content-center justify-content-md-between">
                    <a href="https://joblu.io">
                    <img
                        src={jbHorizontal}
                        alt=""
                        style={{ width: '192.44px' }}
                        className="ms-0 ms-md-5"
                    />
                    </a>

                    <div className="d-none d-sm-flex pe-5">
                        {headerlinks.map((nav) => {
                            return (
                                <div className="d-flex">
                                    <button
                                        className="btn btn-text my-auto"
                                        style={{
                                            color: '#27323C',
                                            fontWeight: 500,
                                            fontFamily: 'poppins',
                                            lineHeight: '24px',
                                            fontSize: 16,
                                        }}
                                        onClick={() => {
                                            window.location.href = nav.url
                                        }}
                                    >
                                        {nav.label}
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div> */}

                <div className="col-12 col-sm-12 row justify-content-end login-bg mx-0" style={{height:'auto'}}>
                    <div className="col-12 col-sm-6 d-flex my-5 h">
                        <div
                            className="card  shadow mx-auto my-5 my-md-auto w-100 "
                            style={{ maxWidth: '640px', borderRadius: 8, minHeight: '400px' }}
                        >
                            <div className="card-body p-5">
                                <p
                                    className="text-center mb-2"
                                    style={{
                                        fontSize: `${
                                            width > 640 ? '35px' : '22px'
                                        }`,
                                        lineHeight: `${
                                            width > 640 ? '50px' : '32px'
                                        }`,
                                        fontFamily: 'poppins',
                                        color: '#002E5D',
                                        fontWeight: 700,
                                    }}
                                >
                                    Sign in to your
                                    <br />
                                    Employer account
                                </p>
                                <p className="text-center mb-2">
                                    <span
                                        style={{
                                            color: '#009CDE',
                                            fontWeight: 700,
                                        }}
                                    >
                                        For Job Seekers
                                    </span>{' '}
                                    ,{' '}
                                    <a href="https://jobs.joblu.io/" target="_blank">
                                        <span className="text-primary hand text-underline">
                                            sign up here
                                        </span>
                                    </a>{' '}
                                    or sign in on the Mobile App
                                </p>
                                <div className="text-center">
                                    <div className="d-flex justify-content-center ">
                                        <div className="m-1 ms-0">
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.joblu&pli=1"
                                                target="_blank"
                                            >
                                                <img src={d_android} alt="" />
                                            </a>
                                        </div>
                                        <div className="m-1">
                                            <a
                                                href="https://apps.apple.com/us/app/joblu/id1598914661"
                                                target="_blank"
                                            >
                                                <img src={d_ios} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-sm-5 mt-4">
                                    {!!error && (
                                        <ErrorMessage show>
                                            {error}
                                        </ErrorMessage>
                                    )}
                                    {isVerified && (
                                        <SuccessMessage show>
                                            <p>
                                                Verification completed! <br />{' '}
                                                Congratulations! You may login
                                                now.
                                                <br />
                                            </p>
                                        </SuccessMessage>
                                    )}
                                    <label style={{ color: '#002E5D' }}>
                                        Email
                                    </label>
                                    <TextField
                                        type="email"
                                        required
                                        id="password"
                                        name="email"
                                        placeholder="juandelacruz@domain.com"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        className="mb-2"
                                        error={errors.email}
                                    />
                                    <label style={{ color: '#002E5D' }}>
                                        Password
                                    </label>
                                    <TextField
                                        type="password"
                                        required
                                        className="mb-4"
                                        id="password"
                                        name="password"
                                        // placeholder="Password"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        error={errors.password}
                                    />

                                    <div className="d-flex justify-content-center justify-content-md-start mb-4">
                                        {/* <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                        />
                                        <label className="form-check-label">
                                            Remember Me
                                        </label>
                                    </div> */}
                                        <div className="text-center text-md-start">
                                            <a
                                                href={'/password-reset'}
                                                className="text-dark"
                                                style={{
                                                    fontFamily: 'poppins',
                                                    fontSize: 16,
                                                    lineHeight: '24px',
                                                }}
                                            >
                                                Forgot Password?
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-3">
                                    <div className="text-center mb-2">
                                        <button
                                            onClick={(e) => handleLogin(e)}
                                            className="btn btn-primary w-136"
                                            style={{ width: 200 }}
                                            disabled={!verifiedCaptcha}
                                        >
                                            SIGN IN
                                        </button>
                                    </div>
                                </div>
                                <p className="text-center">or</p>
                                <div className="d-flex justify-content-center mb-5">
                                    <GoogleLogin
                                        clientId={creds.GOOGLE_CLIENT_ID}
                                        buttonText="Sign in with Google"
                                        onSuccess={(res) => {
                                            handleGoogleLogin(res)
                                        }}
                                        onFailure={(e) => {
                                            console.log(e)
                                        }}
                                        className="shadow-none border w-200"
                                    />
                                </div>
                                <hr />
                                <div className="text-center">
                                    <p className="text-muted">
                                        New to Joblu?
                                        <a
                                            role="button"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setModalOpen(true)
                                                setModalVariant('signUpModal2')
                                            }}
                                            href="#signin"
                                            className="font-weight-bold text-primary ms-1"
                                        >
                                            Create an Account
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 px-0">
                    <Footer />
                </div>
                <div
                    className="modal fade"
                    id="signupModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-sm">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <h6 className="mb-3">
                                    How do you want to sign up?
                                </h6>
                                <a
                                    href={'/signup'}
                                    className="btn btn-primary mb-3"
                                >
                                    Sign up with email
                                </a>
                                <small>
                                    By signing up, you agree to the Joblu
                                    <br />
                                    <a
                                        href="https://joblu.io/terms-of-use/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://joblu.io/privacy-policy/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </small>
                                <p className="mb-3">Already have an account?</p>
                                <a>Sign in here</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={!!User.isSigningIn}
                    centered
                    contentClassName="bg-transparent border-0"
                >
                    <Loading show color="primary" />
                </Modal>
                <ExploreModal
                    showModal={modalOpen}
                    variant={modalVariant}
                    setModalVariant={setModalVariant}
                    setShowModal={setModalOpen}
                    handleGoogleLogin={handleGoogleLogin}
                />
                <GoogleReCaptcha handleVerify={(token) => setVerifiedCaptcha(true)}/>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
const mapDispatchToProps = {
    signIn: userAction.signIn,
    verifyGoogleToken: userAction.verifyGoogleToken,
    resetUserError: userAction.resetUserError,
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
