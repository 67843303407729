import { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import jobIcon from '../../../assets/img/jobIcon.svg'
// import locationIcon from '../../../assets/img/locationIcon.svg'
import { useEffect } from 'react'
import educationIcon from '../../../assets/img/explore/educationIcon.svg'
import experienceIcon from '../../../assets/img/explore/experienceIcon.svg'
import locationIcon from '../../../assets/img/explore/locationIcon.svg'
import playIcon from '../../../assets/img/explore/playIcon.svg'
import salaryIcon from '../../../assets/img/explore/salaryIcon.svg'
import skillsIcon from '../../../assets/img/explore/skillsIcon.png'
import ElevatorPitch from '../../../components/reusables/elevator-pitch/ElevatorPitch'
import { SkeletonLoading } from '../../../components/reusables/Loading'
import PlusIcon from '../../../assets/img/likes/plusIconBlue.png'
import {
    JobApplication_Status,
    profilePlaceholder_Applicant,
    salaryRanges,
} from '../../../utils/constants'
import {
    formatMoney,
    getYearDifference,
    getYearsOfExpCategory,
    titleCase,
} from '../../../utils/helpers'
import JobluVideoPlayer from '../../explore/components/VideoPlayer'
import { ProfileModal } from '../../explore/v2/components/ViewProfile/ProfileModal'
// import { ProfileModal } from '../../explore/v2/components/ViewProfile/ProfileModal'
import moment from 'moment/moment'
import Modal from 'react-bootstrap/Modal'
import {
    calendarRequest,
    jobApplicantRequest,
    jobMatchRequest,
} from '../../../service/requests'
import { ConnectedApps } from '../../settings/Settings'

import Button from 'react-bootstrap/Button'
import Select from '../../../components/inputs/CustomSelect'
import EllipsisV from '../../../assets/img/matches/ellipsisV.png'
import UnmatchIcon from '../../../assets/img/matches/unmatchIcon.png'
import MessageIcon2 from '../../../assets/img/matches/messageIcon2.png'
import AppliedDateIcon from '../../../assets/img/matches/appliedDateIcon.png'
import RestoreToShortlistedIcon from '../../../assets/img/matches/restoreToShortlistedIcon.png'
import { ConfirmModal } from '../../../components/reusables/Modal/ConfirmModal'

const init_interview_details = {
    startDate: null,
    endDate: null,
    summary: null,
    description: null,
    sendNotifications: true,
    provider: null,
    jobMatchId: null,
    selected_date: null,
    reminderMinutesBeforeStart: null,
}

// const ConfirmModal = (props) => {
//     const {
//         showConfirmModal,
//         handleCloseConfirmModal,
//         confirmModalText,
//         confirmAction,
//         restoreToShortlist,
//     } = props
//     return (
//         <Modal
//             show={showConfirmModal}
//             centered
//             onHide={handleCloseConfirmModal}
//         >
//             <Modal.Body>
//                 <div className="p-4">
//                     <div className="text-center">
//                         <h5>{confirmModalText}</h5>
//                     </div>
//                     <div className="mt-4">
//                         <div className="d-flex justify-content-center ">
//                             <Button
//                                 variant="outline-primary"
//                                 className="me-2"
//                                 onClick={handleCloseConfirmModal}
//                             >
//                                 Close
//                             </Button>
//                             <Button
//                                 variant="primary"
//                                 onClick={() => {
//                                     if (
//                                         confirmAction === 'restore_to_shortlist'
//                                     ) {
//                                         restoreToShortlist()
//                                     }
//                                 }}
//                             >
//                                 Confirm
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//             </Modal.Body>
//         </Modal>
//     )
// }

const ScheduleInterviewModal = (props) => {
    const { handleClose, show, job_match, getApplicants } = props
    const [selected_app, setSelectedApp] = useState(null)
    const [interview_details, setInterviewDetails] = useState(
        init_interview_details
    )
    const [errors, setErrors] = useState({})

    useEffect(() => {
        console.log('applicant details', job_match)
        setInterviewDetails({
            ...interview_details,
            summary: `Joblu Interview - ${job_match?.matchJob?.jobTitle} : ${
                job_match?.matchEmployer?.companyName ||
                `${job_match?.matchEmployer?.firstName} ${job_match?.matchEmployer?.lastName}`
            }`,
            jobMatchId: job_match?._id,
        })
    }, [job_match])

    useEffect(() => {
        if (!show) {
            setSelectedApp(null)
            setInterviewDetails(init_interview_details)
        }
    }, [show])

    useEffect(() => {
        setInterviewDetails({
            ...interview_details,
            provider: selected_app?.provider?.toUpperCase(),
        })
    }, [selected_app])

    useEffect(() => {
        console.log(interview_details)
    }, [interview_details])

    const validate_fields = () => {
        let valid = []
        let errors = {}
        Object.keys({ ...interview_details }).forEach((key) => {
            if (!interview_details[key]) {
                valid.push(false)
                errors[key] = `Required`
            } else {
                valid.push(true)
                errors[key] = null
            }
        })
        if (
            moment(interview_details?.startDate).isSameOrAfter(
                moment(interview_details?.endDate)
            )
        ) {
            errors['endDate'] = 'End time is invalid'
            valid.push(false)
        } else {
            errors['endDate'] = null
            valid.push(true)
        }
        setErrors(errors)
        if (valid.includes(false)) return false
        return true
    }
    const handleSubmitInterviewSchedule = () => {
        let payload = { ...interview_details }

        const isValid = validate_fields()

        if (isValid) {
            console.log('interview fields are valid => ', payload)
            delete payload.selected_date
            calendarRequest
                .createEvent(payload)
                .then((res) => {
                    console.log('event', res.data)
                })
                .finally(() => {
                    getApplicants()
                    setTimeout(() => {
                        handleClose()
                    }, 500)
                })
        } else {
            console.log(errors)
        }
    }
    return (
        <>
            <div>
                <Modal show={show} size="lg" onHide={handleClose} centered>
                    <Modal.Body>
                        <div className="p-4">
                            <h4 className="mb-4">Schedule an Interview</h4>
                            <label
                                className="mb-3"
                                style={{ color: errors?.provider && 'red' }}
                            >
                                Select a platform to conduct interview
                            </label>
                            <ConnectedApps
                                isSettings={false}
                                onAppClick={(app) => {
                                    console.log(app)
                                    setSelectedApp(app)
                                }}
                                appSelected={selected_app}
                            />

                            <div className="row mt-4">
                                <div className="col-6">
                                    <label htmlFor="">Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        onChange={(e) => {
                                            setInterviewDetails({
                                                ...interview_details,
                                                selected_date: e.target.value,
                                            })
                                        }}
                                        value={interview_details?.selected_date}
                                        style={{
                                            color:
                                                errors?.selected_date && 'red',
                                        }}
                                    />
                                </div>
                                {interview_details?.selected_date && (
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <label htmlFor="">
                                                    Start Time
                                                </label>
                                                <input
                                                    style={{
                                                        color:
                                                            errors?.startDate &&
                                                            'red',
                                                    }}
                                                    type="time"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value
                                                        const hours =
                                                            value?.split(':')[0]
                                                        const minutes =
                                                            value?.split(':')[1]

                                                        setInterviewDetails({
                                                            ...interview_details,
                                                            startDate: moment(
                                                                interview_details?.selected_date
                                                            )
                                                                .add(
                                                                    'hours',
                                                                    hours
                                                                )
                                                                .add(
                                                                    'minutes',
                                                                    minutes
                                                                )
                                                                .toISOString(
                                                                    true
                                                                ),
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="">
                                                    End Time
                                                </label>
                                                <input
                                                    style={{
                                                        color:
                                                            errors?.endDate &&
                                                            'red',
                                                    }}
                                                    type="time"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value
                                                        const hours =
                                                            value?.split(':')[0]
                                                        const minutes =
                                                            value?.split(':')[1]

                                                        setInterviewDetails({
                                                            ...interview_details,
                                                            endDate: moment(
                                                                interview_details?.selected_date
                                                            )
                                                                .add(
                                                                    'hours',
                                                                    hours
                                                                )
                                                                .add(
                                                                    'minutes',
                                                                    minutes
                                                                )
                                                                .toISOString(
                                                                    true
                                                                ),
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row ">
                                <div className="col-12 col-sm-6 ">
                                    <Select
                                        options={[
                                            { label: '10 mins', value: 10 },
                                            { label: '15 mins', value: 15 },
                                        ]}
                                        className="w-100"
                                    />
                                </div>
                                <div className="col-12 mt-4">
                                    <label
                                        htmlFor=""
                                        style={{
                                            color: errors?.description && 'red',
                                        }}
                                    >
                                        Notes for Job Seekers
                                    </label>
                                    <textarea
                                        style={{
                                            color: errors?.description && 'red',
                                        }}
                                        className="form-control"
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="5"
                                        placeholder="Type here..."
                                        onChange={(e) => {
                                            setInterviewDetails({
                                                ...interview_details,
                                                description: e.target.value,
                                            })
                                        }}
                                    ></textarea>
                                </div>

                                <div className="col-12 text-end mt-4">
                                    <button
                                        className="btn btn-outline-primary me-2 text-center"
                                        onClick={handleClose}
                                        style={{ width: 200 }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary text-center"
                                        style={{ width: 200 }}
                                        onClick={() => {
                                            handleSubmitInterviewSchedule()
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
function getContents(tab) {
    console.log(tab)
    if (tab === 'Applied') {
        return (
            <div>
                <h4 className="mt-5">
                    There are no profiles in your Active candidate list.
                </h4>
                <h6 className="mt-3">
                    Go back to Explore or post a job candidates will love at
                    first swipe!
                </h6>
                <p className="text-muted">
                    Tip: Keep your job posts up-to-date and relevant to attract
                    top candidates.
                </p>
            </div>
        )
    }
    if (tab === 'Shortlisted') {
        return (
            <div>
                <h4 className="mt-5">
                    There are no profiles on your candidate shortlist.
                </h4>
                <h6 className="mt-3">
                    Check out our global talent pool while you wait!
                </h6>
            </div>
        )
    }
    if (tab === 'Interviews') {
        return (
            <div>
                <h4 className="mt-5">
                    There are no profiles on your candidate interviews.
                </h4>
                <h6 className="mt-3">
                    Check out our global talent pool while you wait!
                </h6>
            </div>
        )
    }
    if (tab === 'Archived') {
        return (
            <div>
                <h4 className="mt-5">
                    We'll keep your archived candidates safe here.
                </h4>
                <h6 className="mt-3">
                    In the meantime, check out our talent pool!
                </h6>
            </div>
        )
    }
}
function Applicants(props) {
    const {
        applicants,
        selectedJob,
        tab,
        getApplicants,
        isLoading,
        AppState,
        swipeAction,
    } = props
    const [showProfile, setShowProfile] = useState(false)
    const navigate = useNavigate()

    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    const [selectedApplicant, setSelectedApplicant] = useState(null)
    const [selectedJobData, setSelectedJobData] = useState(null)
    const [modalType, setModalType] = useState(null)
    const [modalHeader, setModalHeader] = useState(null)

    const [applicant, setApplicant] = useState(null)
    const handleView = (data) => {
        // setShowProfile(data ? data : false)
        setApplicant(data)
        setShowProfileModal(true)
    }

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = (applicant) => setShow(applicant)

    useEffect(() => {
        console.log(applicants)
    }, [applicants])

    const [playVideo, setPlayVideo] = useState(false)
    const [videoSrc, setVideoSrc] = useState(null)
    const [showPitch, setShowPitch] = useState(true)
    const handlePlayVideo = (src) => {
        setVideoSrc(src)
        setPlayVideo(!playVideo)
    }

    const handleGoToMessage = (data) => {
        navigate('/messages', {
            state: { data },
        })
    }

    const { currencyList, currency } = AppState
    const currencyOptions = {
        currencyList,
        currency,
    }

    const getYearsOfExp = (item) => {
        if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
            const sorted =
                item?.experienceHistory &&
                item?.experienceHistory?.sort(function (a, b) {
                    return new Date(a.startDate) - new Date(b.startDate)
                })
            const startDate = new Date(sorted[0]?.startDate)
            const diff = getYearDifference(startDate)
            return getYearsOfExpCategory(diff)
        } else {
            return null
        }
    }

    // CONFIRM MODAL
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const [confirmModalText, setConfirmModalText] = useState(null)
    const [confirmModalApplicant, setConfirmModalApplicant] = useState(null)
    const [confirmAction, setConfirmAction] = useState(null)
    const [applicationId, setApplicationId] = useState(null)

    const handleApplicantStatus = (
        action,
        applicant,
        applicationId,
        application
    ) => {
        setConfirmAction(action)
        setApplicationId(applicationId)
        setSelectedJobData(application?.matchJob)

        if (action == 'restore_to_shortlist') {
            setModalHeader('Restore Candidate')
            setModalType('restore')
        }
        if (action == 'unmatch') {
            setModalHeader('Unmatch Candidate')
            setModalType('unmatch')
        }
        setSelectedApplicant(applicant)
        setShowConfirmModal(true)
    }

    const restoreToShortlist = () => {
        jobMatchRequest
            .updateJobMatch(applicationId, JobApplication_Status.SHORTLISTED)
            .then(() => {
                getApplicants()
                setShowConfirmModal(false)
            })
    }

    const unmatchApplicant = () => {
        console.log('unmatch')
        const payload = {
            jobMatchId: applicationId,
        }
        jobApplicantRequest.unmatchApplicant(payload).then(() => {
            getApplicants()
            setShowConfirmModal(false)
        })
    }
    // const [showProfileModal, setShowProfileModal] = useState(false)

    // const handleCloseProfileModal = () => setShowProfileModal(false)
    // const handleShowProfileModal = () => setShowProfileModal(true)

    if (!!isLoading) {
        return <SkeletonLoading count={5} style={{ height: 120 }} />
    }
    if (!applicants?.length) {
        return (
            <div
                className="w-100 h-100 d-flex flex-column justify-content-center"
                style={{ minHeight: 400 }}
            >
                <div className="m-auto text-center">
                    {getContents(tab)}
                    <div className="d-flex mt-5">
                        <button
                            className="mx-auto btn btn-outline-primary text-center"
                            onClick={() => {
                                navigate(`/dashboard?job=${selectedJob}`)
                            }}
                        >
                            Explore
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <JobluVideoPlayer
                src={applicant?.matchApplicant?.elevatorPitch}
                play={playVideo}
                setPlayVideo={setPlayVideo}
            />
            <ConfirmModal
                showConfirmModal={showConfirmModal}
                handleCloseConfirmModal={handleCloseConfirmModal}
                confirmModalText={confirmModalText}
                confirmAction={confirmAction}
                restoreToShortlist={restoreToShortlist}
            />
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
                seeker={applicant}
                tab={tab}
                isFromMatches
                getApplicants={getApplicants}
                swipeAction
            />
            <ScheduleInterviewModal
                show={!!show}
                job_match={show}
                handleClose={handleClose}
                setShow={setShow}
                getApplicants={getApplicants}
            />
            <div id="archive" role="tabpanel" aria-labelledby="archive-tab">
                {!!applicants &&
                    applicants.map((applicant, index) => {
                        return (
                            <>
                                <div
                                    className="row  border-bottom py-3 hand"
                                    key={applicant?.matchApplicant?.id}
                                    id={index}
                                    onClick={() => {
                                        handleView(applicant?.matchApplicant)
                                    }}
                                >
                                    <div
                                        className={`col-md-7 d-flex flex-column flex-md-row`}
                                    >
                                        <>
                                            <div
                                                className={`me-4`}
                                                style={{ flexBasis: '15%' }}
                                            >
                                                <div
                                                    className="position-relative"
                                                    style={{
                                                        width: 80,
                                                        height: 80,
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            applicant
                                                                ?.matchApplicant
                                                                ?.profileUrl ||
                                                            profilePlaceholder_Applicant
                                                        }
                                                        style={{
                                                            width: 80,
                                                            height: 80,
                                                        }}
                                                        alt="profile"
                                                        className="rounded-circle mb-3"
                                                    />
                                                    {applicant?.matchApplicant
                                                        ?.elevatorPitch && (
                                                        <>
                                                            <div
                                                                className="position-absolute"
                                                                style={{
                                                                    bottom: -6,
                                                                    right: -6,
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        playIcon
                                                                    }
                                                                    alt=""
                                                                    className="hand"
                                                                    onClick={() =>
                                                                        handlePlayVideo(
                                                                            applicant
                                                                                ?.matchApplicant
                                                                                ?.elevatorPitch
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="me-4"
                                                style={{ flexBasis: '35%' }}
                                            >
                                                <div>
                                                    {applicant?.matchStatus ===
                                                        'waiting' && (
                                                        <span
                                                            className="text-nowrap"
                                                            style={{
                                                                backgroundColor:
                                                                    '#ffffff',
                                                                borderRadius:
                                                                    '12px',
                                                                padding:
                                                                    '3px 16px',
                                                                color: '#002e5d',
                                                                border: '1px solid #002e5d',
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            Awaiting Match
                                                        </span>
                                                    )}
                                                    <h5
                                                        className="text-primary mt-2 mb-0 hand text-nowrap"
                                                        onClick={() =>
                                                            handleView(
                                                                applicant?.matchApplicant
                                                            )
                                                        }
                                                    >
                                                        {titleCase(
                                                            `${applicant?.matchApplicant?.firstName} ${applicant?.matchApplicant?.lastName}`
                                                        )}
                                                    </h5>
                                                    {applicant?.matchApplicant
                                                        ?.preferredTitle && (
                                                        <p className="mb-0">
                                                            <strong>
                                                                {
                                                                    applicant
                                                                        ?.matchApplicant
                                                                        ?.preferredTitle
                                                                }
                                                            </strong>
                                                        </p>
                                                    )}
                                                    <p className="text-muted mb-2">
                                                        {
                                                            applicant
                                                                ?.matchApplicant
                                                                ?.jobFunction
                                                        }
                                                    </p>
                                                </div>
                                                {applicant?.matchApplicant
                                                    ?.educationHistory?.length >
                                                    0 && (
                                                    <>
                                                        <p className="small">
                                                            <img
                                                                src={
                                                                    educationIcon
                                                                }
                                                                alt=""
                                                                className="me-2"
                                                                style={{
                                                                    width: 22,
                                                                }}
                                                            />{' '}
                                                            {applicant
                                                                ?.matchApplicant
                                                                ?.educationHistory &&
                                                                applicant?.matchApplicant?.educationHistory
                                                                    ?.sort(
                                                                        function (
                                                                            a,
                                                                            b
                                                                        ) {
                                                                            return (
                                                                                new Date(
                                                                                    b.educStartDate
                                                                                ) -
                                                                                new Date(
                                                                                    a.educStartDate
                                                                                )
                                                                            )
                                                                        }
                                                                    )
                                                                    .map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => {
                                                                            return index ===
                                                                                0
                                                                                ? item?.educName
                                                                                : null
                                                                        }
                                                                    )}
                                                        </p>
                                                    </>
                                                )}
                                                {applicant?.matchApplicant
                                                    ?.currentLocation && (
                                                    <>
                                                        <p className="small">
                                                            <img
                                                                src={
                                                                    locationIcon
                                                                }
                                                                alt=""
                                                                className="me-2"
                                                                style={{
                                                                    width: 22,
                                                                }}
                                                            />{' '}
                                                            {
                                                                applicant
                                                                    ?.matchApplicant
                                                                    ?.currentLocation
                                                            }
                                                        </p>
                                                    </>
                                                )}
                                                {getYearsOfExp(applicant) &&
                                                    getYearsOfExp(applicant) !==
                                                        null && (
                                                        <p className="small">
                                                            <img
                                                                src={
                                                                    experienceIcon
                                                                }
                                                                alt=""
                                                                className="me-2"
                                                                style={{
                                                                    width: 22,
                                                                }}
                                                            />{' '}
                                                            {`${getYearsOfExp(
                                                                applicant
                                                            )} Years of Experience`}
                                                        </p>
                                                    )}
                                                {applicant?.matchApplicant
                                                    ?.salaryMin &&
                                                    applicant?.matchApplicant
                                                        ?.salaryMin !==
                                                        null && (
                                                        <p className="small">
                                                            <img
                                                                src={salaryIcon}
                                                                alt=""
                                                                className="me-2"
                                                                style={{
                                                                    width: 22,
                                                                }}
                                                            />{' '}
                                                            {applicant
                                                                ?.matchApplicant
                                                                ?.salaryMin ===
                                                                1 &&
                                                            applicant
                                                                ?.matchApplicant
                                                                ?.salaryMax ===
                                                                1
                                                                ? salaryRanges.COMPETIIVE_SALARY
                                                                : applicant
                                                                      ?.matchApplicant
                                                                      ?.salaryMin ===
                                                                      2 &&
                                                                  applicant
                                                                      ?.matchApplicant
                                                                      ?.salaryMax ===
                                                                      2
                                                                ? salaryRanges.INDUSTRY_STANDARD
                                                                : `${formatMoney(
                                                                      applicant
                                                                          ?.matchApplicant
                                                                          ?.salaryMin,
                                                                      currencyOptions,
                                                                      applicant
                                                                          ?.matchApplicant
                                                                          ?.salaryCurrency ||
                                                                          'SGD'
                                                                  )} - ${formatMoney(
                                                                      applicant
                                                                          ?.matchApplicant
                                                                          ?.salaryMax,
                                                                      currencyOptions,
                                                                      applicant
                                                                          ?.matchApplicant
                                                                          ?.salaryCurrency ||
                                                                          'SGD'
                                                                  )}`}
                                                        </p>
                                                    )}
                                            </div>
                                            <div style={{ flexBasis: '60%' }}>
                                                {applicant?.matchApplicant
                                                    ?.skills?.length > 0 && (
                                                    <>
                                                        <div className="mb-3 mt-3">
                                                            <div className="d-flex">
                                                                <div className="me-3">
                                                                    <img
                                                                        src={
                                                                            skillsIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                            width: '24px',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <h6 className="mb-2 text-primary">
                                                                        Technical
                                                                        Skills
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-wrap">
                                                                {applicant?.matchApplicant?.skills
                                                                    ?.slice(
                                                                        0,
                                                                        5
                                                                    )
                                                                    .map(
                                                                        (
                                                                            skill
                                                                        ) => {
                                                                            return (
                                                                                <span
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#009CDE',
                                                                                        borderRadius:
                                                                                            '12px',
                                                                                        padding:
                                                                                            '0 16px',
                                                                                        color: '#ffffff',
                                                                                        fontSize: 12,
                                                                                    }}
                                                                                    className="me-2 mb-1 text-nowrap"
                                                                                >
                                                                                    {
                                                                                        skill
                                                                                    }
                                                                                </span>
                                                                            )
                                                                        }
                                                                    )}
                                                                {applicant
                                                                    ?.matchApplicant
                                                                    ?.skills
                                                                    ?.length >
                                                                    5 && (
                                                                    <>
                                                                        <span
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#009CDE',
                                                                                borderRadius:
                                                                                    '12px',
                                                                                padding:
                                                                                    '0 16px',
                                                                                color: '#ffffff',
                                                                                fontSize: 12,
                                                                            }}
                                                                            className="me-2 mb-1 text-nowrap"
                                                                        >
                                                                            +{' '}
                                                                            {applicant
                                                                                ?.matchApplicant
                                                                                ?.skills
                                                                                ?.length -
                                                                                5}{' '}
                                                                            more
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <span
                                                                className="hand"
                                                                onClick={() => {
                                                                    handleView(
                                                                        applicant?.matchApplicant
                                                                    )
                                                                }}
                                                                style={{
                                                                    color: '#009CDE!important',
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                            >
                                                                <small>
                                                                    See More
                                                                </small>
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    </div>
                                    <div className="col-md-5">
                                        {tab !== 'Applied' && (
                                            <>
                                                <div className="h-100 d-flex justify-content-between flex-column">
                                                    <div className="dropdown d-none d-md-block">
                                                        <div className="text-end">
                                                            <span
                                                                className="hand text-end"
                                                                type="button"
                                                                id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <img
                                                                    src={
                                                                        EllipsisV
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation()
                                                                    }}
                                                                />
                                                            </span>
                                                            <ul
                                                                class="dropdown-menu dropdown-menu-end"
                                                                style={{
                                                                    width: 250,
                                                                }}
                                                                aria-labelledby="dropdownMenuButton1"
                                                            >
                                                                <li className="text-center">
                                                                    <span class="dropdown-header text-primary">
                                                                        <strong>
                                                                            Actions
                                                                        </strong>
                                                                    </span>
                                                                </li>

                                                                <li>
                                                                    <a
                                                                        class={`dropdown-item hand ${
                                                                            applicant?.matchStatus ===
                                                                                'waiting' &&
                                                                            'disabled text-muted'
                                                                        }`}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation()
                                                                            handleApplicantStatus(
                                                                                'restore_to_shortlist',
                                                                                applicant?.matchApplicant,
                                                                                applicant?._id
                                                                            )
                                                                        }}
                                                                        href="#"
                                                                        style={{
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                RestoreToShortlistedIcon
                                                                            }
                                                                            className={`me-2 ${
                                                                                applicant?.matchStatus ===
                                                                                    'waiting' &&
                                                                                'opacity-25'
                                                                            }`}
                                                                            style={{
                                                                                width: 40,
                                                                                height: 40,
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                        Restore
                                                                        to
                                                                        Shortlisted
                                                                    </a>
                                                                </li>
                                                                {applicant?.matchStatus !=
                                                                    'waiting' && (
                                                                    <>
                                                                        <li>
                                                                            <a
                                                                                class={`dropdown-item hand ${
                                                                                    applicant?.matchStatus ===
                                                                                        'waiting' &&
                                                                                    'disabled text-muted'
                                                                                }`}
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation()
                                                                                    handleGoToMessage(
                                                                                        applicant
                                                                                    )
                                                                                }}
                                                                                href="#"
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        MessageIcon2
                                                                                    }
                                                                                    className={`me-2 ${
                                                                                        applicant?.matchStatus ===
                                                                                            'waiting' &&
                                                                                        'opacity-25'
                                                                                    }`}
                                                                                    style={{
                                                                                        width: 40,
                                                                                        height: 40,
                                                                                    }}
                                                                                    alt=""
                                                                                />
                                                                                Send
                                                                                Message
                                                                            </a>
                                                                        </li>
                                                                    </>
                                                                )}

                                                                {applicant?.applicationStatus &&
                                                                    applicant?.applicationStatus !=
                                                                        'hired' && (
                                                                        <>
                                                                            <li
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    handleApplicantStatus(
                                                                                        'unmatch',
                                                                                        applicant?.matchApplicant,
                                                                                        applicant?._id,
                                                                                        applicant
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    class={`dropdown-item hand ${
                                                                                        (!applicant?.applicationStatus ||
                                                                                            applicant?.applicationStatus ==
                                                                                                'hired') &&
                                                                                        'disabled text-muted'
                                                                                    }`}
                                                                                    href="#"
                                                                                    style={{
                                                                                        fontSize: 12,
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            UnmatchIcon
                                                                                        }
                                                                                        className={`me-2 ${
                                                                                            (!applicant?.applicationStatus ||
                                                                                                applicant?.applicationStatus ==
                                                                                                    'hired') &&
                                                                                            'opacity-25'
                                                                                        }`}
                                                                                        style={{
                                                                                            width: 40,
                                                                                            height: 40,
                                                                                        }}
                                                                                        alt=""
                                                                                    />
                                                                                    Unmatch
                                                                                </span>
                                                                            </li>
                                                                        </>
                                                                    )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {tab !== 'Interviews' &&
                                                        tab !== 'Hired' && (
                                                            <>
                                                                <div className="d-none d-md-block mt-2 text-end">
                                                                    <img
                                                                        src={
                                                                            AppliedDateIcon
                                                                        }
                                                                        className="me-2"
                                                                        style={{
                                                                            width: 24,
                                                                        }}
                                                                        alt=""
                                                                    />

                                                                    <span
                                                                        className="text-muted"
                                                                        style={{
                                                                            fontSize: 14,
                                                                        }}
                                                                    >
                                                                        {applicant?.matchStatus ==
                                                                        'waiting' ? (
                                                                            <>
                                                                                Applied:{' '}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Shortlisted:{' '}
                                                                            </>
                                                                        )}
                                                                        {moment(
                                                                            applicant?.createdAt
                                                                        ).format(
                                                                            'MMM DD, YYYY'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className="text-end d-block d-md-none mt-2 text-end"
                                                                    style={{
                                                                        bottom: 0,
                                                                        right: 0,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            AppliedDateIcon
                                                                        }
                                                                        className="me-2"
                                                                        style={{
                                                                            width: 24,
                                                                        }}
                                                                        alt=""
                                                                    />

                                                                    <span
                                                                        className="text-muted"
                                                                        style={{
                                                                            fontSize: 14,
                                                                        }}
                                                                    >
                                                                        {applicant?.matchStatus ==
                                                                        'waiting' ? (
                                                                            <>
                                                                                Applied:{' '}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Shortlisted:{' '}
                                                                            </>
                                                                        )}
                                                                        {moment(
                                                                            applicant?.createdAt
                                                                        ).format(
                                                                            'MMM DD, YYYY'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                            </>
                                        )}
                                        {/* {tab === 'Archived' && (
                                            <>
                                                <div className="d-flex flex-column justify-content-end">
                                                    <div className="text-center text-md-end mt-2 mt-md-0">
                                                        <button
                                                            className="btn btn-outline-primary nh me-2 mb-2 text-center"
                                                            style={{}}
                                                            onClick={() => {
                                                                handleApplicantStatus(
                                                                    'restore_to_shortlist',
                                                                    applicant?.matchApplicant,
                                                                    applicant?._id
                                                                )
                                                            }}
                                                        >
                                                            <div className=" d-flex align-items-center">
                                                                <div>
                                                                    {' '}
                                                                    <img
                                                                        src={
                                                                            PlusIcon
                                                                        }
                                                                        className="me-2"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    Restore to
                                                                    Shortlisted
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )} */}
                                    </div>
                                    <div className="col-md-6 col-sm-12 d-flex d-none">
                                        <div>
                                            <ElevatorPitch
                                                jobseeker={applicant}
                                                hasSwipeActions={true}
                                            />
                                        </div>
                                        <div className="ms-3">
                                            <h5 className="text-primary mb-0 ">
                                                {
                                                    applicant?.matchApplicant
                                                        ?.firstName
                                                }{' '}
                                                {
                                                    applicant?.matchApplicant
                                                        ?.lastName
                                                }
                                            </h5>
                                            {applicant?.matchApplicant
                                                ?.preferredTitle && (
                                                <p className=" mb-0">
                                                    <strong>
                                                        {
                                                            applicant
                                                                ?.matchApplicant
                                                                ?.preferredTitle
                                                        }
                                                    </strong>
                                                </p>
                                            )}
                                            <p className="text-muted mb-0 mt-2">
                                                {
                                                    applicant?.matchApplicant
                                                        ?.jobFunction
                                                }
                                            </p>
                                            <div>
                                                {applicant?.matchApplicant
                                                    ?.overseasExp && (
                                                    <span className="badge rounded-pill text-dark fs13 px-2 me-1">
                                                        Has Overseas Experience
                                                    </span>
                                                )}
                                                {applicant?.matchApplicant
                                                    ?.singaporeExp && (
                                                    <span className="badge rounded-pill text-dark fs13 px-2 me-1">
                                                        Has Singapore Experience
                                                    </span>
                                                )}
                                                {applicant?.matchApplicant
                                                    ?.multiSkills && (
                                                    <span className="badge rounded-pill text-dark fs13 px-2 me-1">
                                                        Has Multi Skills
                                                    </span>
                                                )}
                                            </div>
                                            <div className="mt-3 d-flex flex-column flex-md-row mb-3 mb-md-0">
                                                <div className="d-flex align-items-center me-4">
                                                    <div>
                                                        <img
                                                            src={locationIcon}
                                                            className="h-24 me-2"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <p className="small mb-0">
                                                            Based in{' '}
                                                            {
                                                                applicant
                                                                    ?.matchApplicant
                                                                    ?.currentLocation
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <img
                                                            src={jobIcon}
                                                            className="h-24 me-2"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <p className="small mb-0">
                                                            {applicant
                                                                ?.matchApplicant
                                                                ?.totalExperienceMin ||
                                                            applicant
                                                                ?.matchApplicant
                                                                ?.totalExperienceMax ? (
                                                                <>
                                                                    {
                                                                        applicant
                                                                            ?.matchApplicant
                                                                            ?.totalExperienceMin
                                                                    }{' '}
                                                                    -{' '}
                                                                    {
                                                                        applicant
                                                                            ?.matchApplicant
                                                                            ?.totalExperienceMax
                                                                    }{' '}
                                                                    Years of
                                                                    Experience.
                                                                </>
                                                            ) : (
                                                                <span>
                                                                    No work
                                                                    experience
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 d-none"></div>
                                </div>
                            </>
                        )
                    })}
            </div>
            <ConfirmModal
                selectedApplicant={selectedApplicant}
                showConfirmModal={showConfirmModal}
                handleCloseConfirmModal={handleCloseConfirmModal}
                modalHeader={modalHeader}
                modalType={modalType}
                unmatchApplicant={unmatchApplicant}
                selectedJob={selectedJob}
                selectedJobData={selectedJobData}
                restoreToShortlist={restoreToShortlist}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
    }
}
export default connect(mapStateToProps, null)(Applicants)

// export default Applicants
