import { Button, DialogTitle } from '@material-ui/core'
import { ArrowBack, Check, Close } from '@material-ui/icons'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Skeleton } from '@material-ui/lab'
import { Box, CircularProgress, Dialog, Grid } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a
import { useLocation, useNavigate } from 'react-router-dom'
import userActions from '../../redux/user/actions'
import { paymentRequests } from '../../service/requests'
import {
    STRIPE_BASE_URL,
    STRIPE_SECRET,
    product_pricing_features,
} from '../../utils/constants'
import { getGeolocation, thousendSeparator } from '../../utils/helpers'
import useWindowDimensions from '../../utils/hooks'
import JobluTypography from '../Typography'
import Loading from '../reusables/Loading'
import './styles.css'

const price_buttons = {
    monthly12: {
        label: (price, curr) => (
            <div>
                {curr} {price} for 1 year billed monthly
            </div>
        ),
    },
    monthly6: {
        label: (price, curr) => (
            <div className="text-light">
                {curr} {price} for 6 months billed monthly{' '}
                <span className="font-accent-color">(Save 50%)</span>
            </div>
        ),
        best: true,
        badge: <div className="price-badge p-badge-gold">Best Value!</div>,
    },
    monthly: {
        label: (price, curr) => (
            <div>
                {curr} {price} - Monthly
            </div>
        ),
        badge: <div className="price-badge p-badge-blue">Special Offer</div>,
    },
}

export const render_premium_options = (opts = [], onChange, value) => {
    let options = opts?.filter((item) => item?.label !== '')
    return (
        <Box
            style={{
                padding: 10,
                marginTop: 20,
                border: '1px solid #f2f2f2',
                borderRadius: '5px',
            }}
        >
            {/* <Select size="small" fullWidth onChange={onChange} value={value}>
                {options?.map((plan) => {
                    return <MenuItem value={plan?.id}>{plan?.label}</MenuItem>
                })}
            </Select> */}
            <Carousel
                emulateTouch
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                onChange={(index) => {
                    onChange && onChange(options[index]?.id)
                }}
                selectedItem={options.findIndex((item) => item?.id === value)}
                renderArrowNext={(click, hasNext) => {
                    if (hasNext) {
                        return (
                            <NavigateNextIcon
                                style={{
                                    position: 'absolute',
                                    top: '40%',
                                    right: 0,
                                }}
                                onClick={() => click()}
                            />
                        )
                    }
                }}
                renderArrowPrev={(click, hasPrev, label) => {
                    if (hasPrev) {
                        return (
                            <NavigateBeforeIcon
                                style={{
                                    position: 'absolute',
                                    top: '40%',
                                    left: 0,
                                    zIndex: 1001,
                                }}
                                onClick={() => {
                                    console.log('here')
                                    click()
                                }}
                            />
                        )
                    }
                }}

                // renderArrowPrev={(click) => {
                //     return <ArrowBackIosIcon onClick={() => click()} />
                // }}
            >
                {options?.map((plan) => {
                    let data = plan
                    let per_month = 0

                    if (data?.recurring) {
                        const { interval, interval_count } = data?.recurring
                        let months = interval_count
                        if (interval === 'year') {
                            months = interval_count * 12
                        }

                        per_month = data.unit_amount / 100 / months
                        per_month = per_month.toFixed(0)
                        per_month = thousendSeparator(per_month)
                    }
                    data = { ...data, per_month }

                    return (
                        <Box style={{ iwdth: '100%' }} value={plan?.id}>
                            <JobluTypography.H5 style={{ color: '#009CDE' }}>
                                {plan?.label}
                            </JobluTypography.H5>
                            <JobluTypography.H4
                                primary
                                style={{ fontWeight: 600 }}
                            >
                                {data?.currency?.toUpperCase()} {per_month}/mo
                            </JobluTypography.H4>
                            {plan?.label == 'Quarterly' && (
                                <>
                                    <JobluTypography.H6
                                        secondary
                                        style={{
                                            marginBottom: 8,
                                            marginTop: 8,
                                        }}
                                    >
                                        save 10%
                                    </JobluTypography.H6>
                                    <JobluTypography.H6
                                        secondary
                                        style={{ color: '#6E88A3' }}
                                    >
                                        {/* FOR HARLE */}
                                        {/* Billed $109 quarterly */}
                                    </JobluTypography.H6>
                                </>
                            )}
                            {plan?.label == 'Annually' && (
                                <>
                                    <JobluTypography.H6
                                        secondary
                                        style={{
                                            marginBottom: 8,
                                            marginTop: 8,
                                        }}
                                    >
                                        save 20%
                                    </JobluTypography.H6>
                                    <JobluTypography.H6
                                        secondary
                                        style={{ color: '#6E88A3' }}
                                    >
                                        {/* FOR HARLE */}
                                        {/* Billed $109 quarterly */}
                                    </JobluTypography.H6>
                                </>
                            )}
                        </Box>
                    )
                })}
            </Carousel>
        </Box>
    )
}
const products = [
    {
        title: 'FREE',
        colorIndicator: '#6E88A3',
        head: (
            <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
                <JobluTypography.H4
                    align="center"
                    style={{ fontWeight: 600, fontSize: 30 }}
                >
                    $0/mo
                </JobluTypography.H4>
            </Box>
        ),
        perks: [
            '2 Job Posts',
            // '1 User Seat',
            // 'Talent Finder: Add-on',
            'Candidate Matching',
            'Workspace Spotlight',
        ],
        section: null,
        defaultButtonText: 'Get Started',
    },
    {
        title: 'PREMIUM',
        colorIndicator: '#009CDE',
        head: render_premium_options,
        perks: [
            'Unlimited  Job Postings',
            // '3 User Seats',
            'Basic Talent Finder',
            'Basic ATS Integration',
        ],
        full_fetaures: [
            'Unlimited  Job Postings',
            // '3 User Seats',
            'Basic Talent Finder',
            'Basic ATS Integration',
            'Up to 300 Matches PER Job Post',
            'Up to 300 Candidate Messaging',
            'In-app & email Instant Alerts',
            '1 social media Post on Joblu’s pages + community',
        ],
        other_features: [
            'Candidate Matching',
            'Job Mailbox',
            'Instant Data Exporter',
            'Exclusive Recruitment Drive',
            'Dedicated Account Manager',
        ],

        // section: (product) => {
        //     let data = product
        //     let per_month = 0

        //     if (data?.recurring) {
        //         const { interval, interval_count } = data?.recurring
        //         let months = interval_count
        //         if (interval === 'year') {
        //             months = interval_count * 12
        //         }

        //         per_month = data.unit_amount / 100 / months
        //         per_month = per_month.toFixed(0)
        //         per_month = thousendSeparator(per_month)
        //     }
        //     data = { ...data, per_month }

        //     return (
        //         <Box
        //             style={{
        //                 padding: 10,
        //                 border: '1px solid #f3f3f3',
        //                 borderRadius: 5,
        //                 marginTop: 10,
        //                 marginBottom: 10,
        //             }}
        //         >
        //             <JobluTypography.H5
        //                 align="center"
        //                 style={{
        //                     fontWeight: 600,
        //                     color: '#009CDE',
        //                 }}
        //             >
        //                 {data?.label}
        //             </JobluTypography.H5>
        //             <JobluTypography.H3
        //                 align="center"
        //                 style={{
        //                     fontWeight: 600,
        //                 }}
        //             >
        //                 {data?.currency?.toUpperCase()} {data?.per_month}/mo
        //             </JobluTypography.H3>
        //         </Box>
        //     )
        // },
        defaultButtonText: 'Upgrade Now',
    },
    {
        title: 'ENTERPRISE',
        colorIndicator: '#009CDE',
        head: (
            <Box
                style={{
                    padding: 10,
                    border: '1px solid #f3f3f3',
                    borderRadius: 5,
                    marginTop: 10,
                    marginBottom: 10,
                }}
            >
                <JobluTypography.H4
                    align="center"
                    style={{
                        fontWeight: 600,
                        color: '#009CDE',
                    }}
                >
                    Custom Pricing
                </JobluTypography.H4>
            </Box>
        ),
        perks: [
            'Unlimited  Job Postings',
            'Customizable User Seats',
            'Advanced Talent Finder',
            'Advanced ATS Integration',
        ],
        section: null,
        defaultButtonText: 'Book a Call',
    },
]

export const SubscriptionButtons = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [subscriptionList, setList] = useState({})
    const [prices, setPrices] = useState([])

    const [geolocation, setGeoLocation] = useState({})
    useEffect(() => {
        getGeolocation().then((data) => {
            setGeoLocation(data)
        })
    }, [])

    const getPricing = () => {
        paymentRequests
            .getPlans()
            .then((res) => {
                setList(_.reverse(res.data))
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (geolocation) {
            console.log('test:::', geolocation)
            getPricing()
        }
    }, [geolocation])

    useEffect(() => {
        if (subscriptionList && Object.keys(subscriptionList).length > 0) {
            Object.keys(subscriptionList).forEach(async (key, index) => {
                await axios
                    .get(`${STRIPE_BASE_URL}prices/${subscriptionList[key]}`, {
                        headers: {
                            Authorization: 'Bearer ' + STRIPE_SECRET, //the token is a variable which holds the token
                        },
                    })
                    .then((res) => {
                        let price = prices
                        price.push(res.data)
                        price = _.uniqBy(price, 'id')
                        setPrices([...price])
                    })
            })
        }
    }, [subscriptionList])

    const handleSelectPlan = (plan) => {
        let curr = 'usd'
        if (geolocation === 'PH') curr = 'php'

        if (plan) {
            setLoading(true)
            const payload = {
                priceId: plan.id,
                currency: curr,
                amount: plan?.unit_amount,
            }
            paymentRequests
                .subscribeToPlan(payload)
                .then((res) => {
                    navigate('/plan/checkout', {
                        state: {
                            intent: {
                                ...res.data,
                                plan: plan,
                            },
                        },
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <>
            <div className="row justify-content-center mx-0 mt-3">
                {Object.keys(subscriptionList).length > 0 &&
                    Object.keys(subscriptionList)

                        .reverse()
                        .map((key) => {
                            const price = _.find(prices, {
                                id: subscriptionList[key],
                            })
                            let curr = 'php'
                            if (geolocation === 'PH') curr = 'php'
                            if (geolocation === 'SG') curr = 'sgd'
                            if (geolocation === 'US') curr = 'usd'

                            let per_month
                            if (price?.recurring) {
                                const { interval, interval_count } =
                                    price?.recurring
                                let months = interval_count
                                if (interval === 'year') {
                                    months = interval_count * 12
                                }

                                per_month = price.unit_amount / 100 / months
                                per_month = per_month.toFixed(0)
                                per_month = thousendSeparator(per_month)
                            }

                            const isBest = price_buttons[key]?.best
                            return (
                                <div className="col-12 col-md-4 my-1 px-1">
                                    <div className="position-relative h-100">
                                        {price_buttons[key].badge}
                                        <button
                                            className={`btn w-100 h-100 text-center fs-12 ${
                                                isBest
                                                    ? 'btn-primary'
                                                    : 'btn-outline-primary '
                                            }`}
                                            style={{ minWidth: 300 }}
                                            onClick={() => {
                                                handleSelectPlan(price)
                                            }}
                                        >
                                            {price_buttons[key].label(
                                                per_month,
                                                curr.toUpperCase()
                                            )}
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
            </div>
            <Modal
                show={loading}
                centered
                contentClassName="bg-transparent border-0"
            >
                <Loading show color="primary" />
            </Modal>
        </>
    )
}

const Pricing = ({ show, User, getActiveSubcription }) => {
    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const _location = useLocation()

    useEffect(() => {
        console.log('tas',_location)
        if (_location?.state?.talentFinder) {
            console.log("TaS")
            document.getElementById('TalentFinder').scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        }
    }, [_location])
    const [loading, setLoading] = useState(false)
    const [showConfirmModal, setShowConfirmationModal] = useState(false)
    const [cancellation_loading, setLoadingCancel] = useState(false)
    const [cancel_step, setCancelStep] = useState(1)

    const handlePremiumChange = (price_id) => {
        setSelectedPremium(price_id)
    }
    const [premium_options, setPremiumOptions] = useState([])
    const [selected_premium, setSelectedPremium] = useState(null)
    const [talentSearchBasic, setTalentSearchBasic] = useState(null)
    const [plans_loading, setPlansLoading] = useState(true)
    const [geolocation, setGeoLocation] = useState({})
    const [talent_search_details, setTalentSearchDetails] = useState({})

    useEffect(() => {
        getGeolocation().then((data) => {
            console.log('test:::', data)
            setGeoLocation(data)
        })
    }, [])

    const handleTalentSearchPayment = () => {
        setLoading(true)
        const payload = {
            priceId: talent_search_details?.id,
            currency: talent_search_details?.currency,
            amount: talent_search_details?.unit_amount,
        }
        paymentRequests
            .payTalentSearch(payload)
            .then((res) => {
                navigate('/plan/checkout', {
                    state: {
                        intent: {
                            ...res.data,
                            plan: {
                                ...talent_search_details,
                                key: 'talentSearchBasic',
                            },
                        },
                    },
                })
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 3000)
            })
    }

    const getTalentSearchPrice = () => {
        let curr = 'usd'
        if (geolocation?.country === 'PH') curr = 'php'
        if (User?.subscription?.data) curr = User?.subscription?.data?.currency
        paymentRequests.getTalentSearchPriceId(curr).then((res) => {
            axios
                .get(
                    `${STRIPE_BASE_URL}prices/${res.data['talentSearchBasic']}`,
                    {
                        headers: {
                            Authorization: 'Bearer ' + STRIPE_SECRET, //the token is a variable which holds the token
                        },
                    }
                )
                // eslint-disable-next-line no-loop-func
                .then((res) => {
                    setTalentSearchDetails(res.data)
                })
        })
    }
    const getPlans = () => {
        setPlansLoading(true)
        let curr = 'usd'
        if (geolocation?.country === 'PH') curr = 'php'
        let request = paymentRequests.getDollarPlans
        if (User?.subscription?.data) curr = User?.subscription?.data?.currency
        console.log('geolocation => ', geolocation, curr)
        if (curr === 'php') request = paymentRequests.getPesoPlans
        let options = []

        request()
            .then(async (res) => {
                console.log(res?.data)
                if (res.data && Object.keys(res.data).length > 0) {
                    for (let key of Object.keys(res.data)) {
                        await axios
                            .get(`${STRIPE_BASE_URL}prices/${res.data[key]}`, {
                                headers: {
                                    Authorization: 'Bearer ' + STRIPE_SECRET, //the token is a variable which holds the token
                                },
                            })
                            // eslint-disable-next-line no-loop-func
                            .then((res) => {
                                let label = ''
                                if (key === 'monthly') {
                                    label = 'Monthly'
                                }
                                if (key === 'monthly3') {
                                    label = 'Quarterly'
                                }
                                if (key === 'monthly12') {
                                    label = 'Annually'
                                }
                                if (key === 'talentSearchBasic') {
                                    // label = 'TalentSearch_Basic'
                                    setTalentSearchBasic(res?.data?.id)
                                }
                                options.push({
                                    ...res.data,
                                    label,
                                    key,
                                })
                                options = _.uniqBy(options, 'id')
                            })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                console.log(options)
                setPremiumOptions(options)
                setSelectedPremium(
                    User?.subscription?.data?.id || options[2]?.id
                )
                setPlansLoading(false)
            })
    }
    useEffect(() => {
        if (geolocation?.country) {
            getTalentSearchPrice()
            getPlans()
        }
    }, [geolocation])

    const handleCheckout = () => {
        let plan = premium_options?.find((item) => item.id === selected_premium)
        let curr = 'usd'
        if (geolocation?.country === 'PH') curr = 'php'
        if (plan) {
            setLoading(true)
            const payload = {
                priceId: plan.id,
                currency: plan?.currency,
                amount: plan?.unit_amount,
            }
            paymentRequests
                .subscribeToPlan(payload)
                .then((res) => {
                    navigate('/plan/checkout', {
                        state: {
                            intent: {
                                ...res.data,
                                plan: plan,
                            },
                            payload: payload,
                        },
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const features = [
        {
            label: 'Free',
            color: '#6E88A3',
            features: [
                {
                    icon: <Check color="secondary" />,
                    label: '2 Job Postings',
                },
                // {
                //     icon: <Check color="secondary" />,
                //     label: '1 User Seat',
                // },
                {
                    icon: <Check color="secondary" />,
                    label: 'Candidate Matching: Up to 50 matches',
                },
                {
                    icon: <Close color="error" />,
                    label: (
                        <span style={{ color: '#6E88A3' }}>
                            Candidate Unmatching
                        </span>
                    ),
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Applicant Management',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Workplace Spotlight',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Interview Scheduler',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Candidate Messaging',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Job Blast',
                },
                {
                    icon: <Close color="error" />,
                    label: (
                        <span style={{ color: '#6E88A3' }}>Job Mailbox</span>
                    ),
                },
                {
                    icon: <Close color="error" />,
                    label: (
                        <span style={{ color: '#6E88A3' }}>
                            Instant Data Exporter
                        </span>
                    ),
                },
                {
                    icon: <Close color="error" />,
                    label: (
                        <span style={{ color: '#6E88A3' }}>
                            Exclusive Recruitment Drive
                        </span>
                    ),
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'In-app (only) Instant Alert',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Talent Finder: Add-on',
                },
                {
                    icon: <Close color="error" />,
                    label: (
                        <span style={{ color: '#6E88A3' }}>
                            ATS Integration
                        </span>
                    ),
                },
                {
                    icon: <Close color="error" />,
                    label: (
                        <span style={{ color: '#6E88A3' }}>
                            Marketing Promotions
                        </span>
                    ),
                },
                {
                    icon: <Close color="error" />,
                    label: (
                        <span style={{ color: '#6E88A3' }}>
                            Dedicated Account Manager
                        </span>
                    ),
                },
            ],
        },
        {
            label: 'Premium',
            color: '#009CDE',
            features: [
                {
                    icon: <Check color="secondary" />,
                    label: 'Unlimited Job Postings',
                },
                // {
                //     icon: <Check color="secondary" />,
                //     label: '3 User Seats',
                // },
                {
                    icon: <Check color="secondary" />,
                    label: 'Candidate Matching: Up to 300 matches per jobs post',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Candidate Unmatching',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Applicant Management',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Workplace Spotlight',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Interview Scheduler',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Candidate Messaging: Up to 300',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Job Blast',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Job Mailbox',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Instant Data Exporter',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Exclusive Recruitment Drive',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'In-app & Email Instant Alert',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Talent Finder: 100 Superlikes',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'BASIC ATS Integration',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Marketing Promotions: 1 Social Media post on Joblu’s pages + community',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Dedicated Account Manager',
                },
            ],
        },
        {
            label: 'Enterprise',
            color: '#009CDE',
            features: [
                {
                    icon: <Check color="secondary" />,
                    label: 'Unlimited Job Postings',
                },
                // {
                //     icon: <Check color="secondary" />,
                //     label: 'Customizable User Seats',
                // },
                {
                    icon: <Check color="secondary" />,
                    label: 'Candidate Matching: Unlimited',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Candidate Unmatching',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Applicant Management',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Workplace Spotlight',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Interview Scheduler',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Unlimited Candidate Messaging',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Job Blast',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Job Mailbox',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Instant Data Exporter',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Exclusive Recruitment Drive',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'In-app & Email Instant Alert',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Talent Finder: Customizable',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'ADVANCED ATS Integration',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Marketing Promotions: Customizable',
                },
                {
                    icon: <Check color="secondary" />,
                    label: 'Dedicated Account Manager',
                },
            ],
        },
    ]
    const handleCheckout_TF = () => {
        let plan = premium_options?.find(
            (item) => item.id === talentSearchBasic
        )
        console.log(plan)
        let curr = 'usd'
        if (geolocation?.country === 'PH') curr = 'php'
        if (plan) {
            setLoading(true)
            const payload = {
                priceId: plan.id,
                currency: plan?.currency,
                amount: plan?.unit_amount,
            }
            paymentRequests
                .subscribeToPlan(payload)
                .then((res) => {
                    navigate('/plan/checkout', {
                        state: {
                            intent: {
                                ...res.data,
                                plan: plan,
                            },
                        },
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    return (
        <>
            <Grid
                container
                justifyContent="center"
                padding={3}
                style={{
                    background: '#E4ECF3',
                    paddingBottom: 100,
                }}
            >
                <Grid item xs={12} md={11} container>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<ArrowBack />}
                            color="primary"
                            style={{ textTransform: 'none' }}
                            onClick={() => navigate(-1)}
                        >
                            Go back
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <JobluTypography.H2
                            primary
                            align="center"
                            style={{ fontWeight: 700, fontSize: 50 }}
                            gutterBottom={3}
                        >
                            Hire Smarter, Not Harder with Joblu
                        </JobluTypography.H2>
                        <JobluTypography.H6
                            gutterBottom={5}
                            align="center"
                            primary
                        >
                            From startups to corporations, Joblu has a plan for
                            every business need. Unlock premium features <br />
                            and find your dream candidates now!
                        </JobluTypography.H6>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ paddingBottom: 50, paddingTop: 50 }}>
                            <JobluTypography.H2
                                primary
                                align="center"
                                style={{ fontWeight: 600 }}
                            >
                                Plans & Pricing
                            </JobluTypography.H2>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        spacing={2}
                    >
                        {!plans_loading
                            ? products?.map((plan) => {
                                  return (
                                      <Grid
                                          xs={12}
                                          md={3}
                                          item
                                          style={
                                              {
                                                  //   height: '100%',
                                              }
                                          }
                                      >
                                          <Box
                                              style={{
                                                  padding: 30,
                                                  background: '#fff',
                                                  height: '100%',
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  justifyContent:
                                                      'space-between',
                                                  position: 'relative',
                                              }}
                                          >
                                              {plan?.title === 'PREMIUM' && (
                                                  <Box
                                                      style={{
                                                          position: 'absolute',
                                                          right: 20,
                                                          top: 10,
                                                      }}
                                                  >
                                                      <img
                                                          src={require('../../assets/img/pricing-page/v2/popular.png')}
                                                          alt=""
                                                      />
                                                  </Box>
                                              )}
                                              <Box>
                                                  <JobluTypography.H6 align="center">
                                                      {plan?.title}
                                                  </JobluTypography.H6>
                                                  <Box
                                                      style={{
                                                          borderBottom:
                                                              '1px solid #E9EBEC',
                                                          width: '100%',
                                                          display: 'flex',
                                                          justifyContent:
                                                              'center',
                                                          marginTop: 20,
                                                      }}
                                                  >
                                                      <Box
                                                          style={{
                                                              borderRadius:
                                                                  '5px 5px 0px 0px',
                                                              background:
                                                                  plan?.colorIndicator,
                                                              width: 90,
                                                              height: 5,
                                                          }}
                                                      ></Box>
                                                  </Box>
                                                  <Box>
                                                      {plan?.title === 'PREMIUM'
                                                          ? plan?.head(
                                                                premium_options,
                                                                handlePremiumChange,
                                                                selected_premium
                                                            )
                                                          : plan?.head}
                                                  </Box>
                                                  {plan?.section && (
                                                      <Box>
                                                          {plan?.title ===
                                                          'PREMIUM'
                                                              ? plan?.section(
                                                                    premium_options?.find(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item?.id ===
                                                                            selected_premium
                                                                    )
                                                                )
                                                              : plan?.section}
                                                      </Box>
                                                  )}
                                                  <Box
                                                      style={{
                                                          marginTop: '1rem',
                                                      }}
                                                  >
                                                      {plan?.perks?.map(
                                                          (perk) => {
                                                              return (
                                                                  <JobluTypography.Body
                                                                      primary
                                                                      style={{
                                                                          fontWeight: 600,
                                                                      }}
                                                                  >
                                                                      <Check
                                                                          color="secondary"
                                                                          style={{
                                                                              marginRight:
                                                                                  '.5rem',
                                                                              marginBottom:
                                                                                  '.5rem',
                                                                          }}
                                                                      />
                                                                      {perk}
                                                                  </JobluTypography.Body>
                                                              )
                                                          }
                                                      )}
                                                  </Box>
                                              </Box>
                                              <Box style={{ marginTop: 20 }}>
                                                  <JobluTypography.Caption align="center">
                                                      See full features below
                                                  </JobluTypography.Caption>
                                                  {plan?.title === 'PREMIUM' &&
                                                  User?.subscription
                                                      ?.productId ===
                                                      selected_premium &&
                                                  (User?.subscription
                                                      ?.status === 'active' ||
                                                      User?.subscription
                                                          ?.status ===
                                                          'trialing') ? (
                                                      <Button
                                                          fullWidth
                                                          variant="outlined"
                                                          style={{
                                                              background:
                                                                  '#EB5757',
                                                              color: '#fff',
                                                              textTransform:
                                                                  'none',
                                                          }}
                                                          onClick={() => {
                                                              setShowConfirmationModal(
                                                                  true
                                                              )
                                                          }}
                                                      >
                                                          Cancel Plan
                                                      </Button>
                                                  ) : plan?.title === 'FREE' &&
                                                    User?.subscription
                                                        ?.status ===
                                                        'active' ? null : (
                                                      <Button
                                                          color="primary"
                                                          variant="contained"
                                                          fullWidth
                                                          style={{
                                                              textTransform:
                                                                  'none',
                                                              marginTop: 5,
                                                          }}
                                                          onClick={() => {
                                                              if (
                                                                  User
                                                                      ?.subscription
                                                                      ?.modeOfPayment ===
                                                                      'JobluPromo' &&
                                                                  plan?.title ===
                                                                      'PREMIUM'
                                                              ) {
                                                                  navigate(
                                                                      '/dashboard'
                                                                  )
                                                              } else {
                                                                  if (
                                                                      plan?.title ===
                                                                      'FREE'
                                                                  ) {
                                                                      navigate(
                                                                          '/jobs'
                                                                      )
                                                                  }
                                                                  if (
                                                                      plan?.title ===
                                                                      'PREMIUM'
                                                                  ) {
                                                                      handleCheckout()
                                                                  }
                                                                  if (
                                                                      plan?.title ===
                                                                      'ENTERPRISE'
                                                                  ) {
                                                                      window.open(
                                                                          'https://meetings.hubspot.com/camille-padua'
                                                                      )
                                                                  }
                                                              }
                                                          }}
                                                      >
                                                          {User?.subscription
                                                              ?.modeOfPayment ===
                                                              'JobluPromo' &&
                                                          plan?.title ===
                                                              'PREMIUM'
                                                              ? 'Get Started'
                                                              : plan?.title ==
                                                                'PREMIUM'
                                                              ? User?.user
                                                                    ?.usedTrial
                                                                  ? plan?.defaultButtonText
                                                                  : 'Start your free 7-day Trial'
                                                              : plan?.defaultButtonText}
                                                      </Button>
                                                  )}
                                              </Box>
                                          </Box>
                                      </Grid>
                                  )
                              })
                            : Array.from({ length: 3 }).map(() => {
                                  return (
                                      <Grid
                                          xs={12}
                                          md={3}
                                          item
                                          style={
                                              {
                                                  //   height: '100%',
                                              }
                                          }
                                      >
                                          <Skeleton
                                              style={{
                                                  minHeight: 400,
                                                  width: '100%',
                                              }}
                                          />
                                      </Grid>
                                  )
                              })}
                    </Grid>
                </Grid>
                <Dialog
                    open={loading}
                    style={{ padding: 10 }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            minHeight: 100,
                            minWidth: 100,
                            display: 'flex',
                            justifyContent: 'center',
                        },
                    }}
                >
                    <CircularProgress />
                </Dialog>
            </Grid>

            {/* FOR CED */}
            {width < 640 ? (
                <>
                    <Grid
                        container
                        justifyContent={'center'}
                        spacing={2}
                        style={{
                            background: '#27323C',
                        }}
                    >
                        {features?.map((feat) => {
                            return (
                                <Grid item xs={11}>
                                    <Box
                                        style={{
                                            backgroundColor: '#fff',
                                            borderRadius: '5px',
                                            padding: '2.5rem',
                                        }}
                                    >
                                        <Box style={{ textAlign: 'center' }}>
                                            <JobluTypography.H6>
                                                {feat.label}
                                            </JobluTypography.H6>
                                        </Box>
                                        <Box
                                            style={{
                                                borderBottom:
                                                    '1px solid #E9EBEC',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: 20,
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    borderRadius:
                                                        '5px 5px 0px 0px',
                                                    background: feat.color,
                                                    width: 90,
                                                    height: 5,
                                                }}
                                            ></Box>
                                        </Box>
                                        {/* CEED */}
                                        <Box>
                                            <Box
                                                style={{
                                                    textAlign: 'center',
                                                    marginTop: 10,
                                                    marginBottom: 10,
                                                }}
                                            >
                                                <JobluTypography.H6>
                                                    Full list of features
                                                </JobluTypography.H6>
                                            </Box>
                                            {feat.features?.map((item) => {
                                                return (
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom:
                                                                '.5rem',
                                                        }}
                                                    >
                                                        <Box
                                                            style={{
                                                                marginRight:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            {item.icon}
                                                        </Box>

                                                        <JobluTypography.Body
                                                            primary
                                                            semiBold
                                                            fontSize={14}
                                                        >
                                                            {item.label}
                                                        </JobluTypography.Body>
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                        <Box></Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </>
            ) : (
                <>
                    {' '}
                    <Grid
                        container
                        justifyContent="center"
                        padding={3}
                        style={{
                            background: '#27323C',
                            paddingBottom: 100,
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={11}
                            container
                            style={{ overFlowX: 'auto' }}
                        >
                            <Grid item xs={12}>
                                <Box padding={3}>
                                    <JobluTypography.H2
                                        align="center"
                                        style={{
                                            color: '#FFFFFF',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Features
                                    </JobluTypography.H2>
                                </Box>
                            </Grid>
                            <Grid item xs={0} style={{ width: '100%' }}>
                                <Box
                                    padding={3}
                                    style={{
                                        background: '#fff',
                                        overflowX: 'auto',
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} container>
                                            <Grid item xs={4}>
                                                Features
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    textAlign: 'center',
                                                    whiteSpace: 'nowrap',
                                                    width: '100%',
                                                }}
                                            >
                                                Free
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    textAlign: 'center',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Premium
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    textAlign: 'center',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Enterprise
                                            </Grid>
                                            {/* <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    textAlign: 'center',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <JobluTypography.H6>
                                                    {perks?.enterprise}
                                                </JobluTypography.H6>
                                            </Grid> */}
                                        </Grid>

                                        {product_pricing_features?.map(
                                            (perks) => {
                                                return (
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        style={{
                                                            marginBottom: 20,
                                                        }}
                                                    >
                                                        <Grid item xs={4}>
                                                            <JobluTypography.H6
                                                                primary
                                                                semiBold
                                                            >
                                                                {perks?.label}
                                                            </JobluTypography.H6>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            style={{
                                                                textAlign:
                                                                    'center',

                                                                width: '100%',
                                                            }}
                                                        >
                                                            {perks?.tooltip}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            <JobluTypography.H6>
                                                                {perks?.free}
                                                            </JobluTypography.H6>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            <JobluTypography.H6>
                                                                {perks?.premium}
                                                            </JobluTypography.H6>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            <JobluTypography.H6>
                                                                {
                                                                    perks?.enterprise
                                                                }
                                                            </JobluTypography.H6>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            <Box
                style={{
                    paddingTop: 50,
                    background: '#27323C',
                    paddingBottom: 100,
                }}
                id="TalentFinder"
            >
                <Grid container justifyContent={'center'}>
                    <Grid item xs={10}>
                        <Box style={{ marginBottom: '2rem' }}>
                            <JobluTypography.H2
                                align="center"
                                style={{ color: '#fff' }}
                            >
                                More profiles, more options, more success with{' '}
                                <br />
                                the{' '}
                                <span style={{ fontWeight: 800 }}>
                                    Talent Finder
                                </span>
                            </JobluTypography.H2>
                        </Box>
                        <Box style={{ marginBottom: '2rem' }}>
                            <JobluTypography.H5
                                align="center"
                                style={{ color: '#fff' }}
                            >
                                Why limit yourself to a few job candidates when
                                you can have access to unlimited profiles of
                                <br />
                                quality talent? With our Talent Finder add-on,
                                you can search through over 100,000 candidate{' '}
                                <br />
                                profiles without any limits.
                                <br />
                                And don't worry about running out of Superlikes
                                credits—you can top up anytime and keep <br />
                                connecting with top candidates effortlessly.
                            </JobluTypography.H5>
                        </Box>
                    </Grid>
                </Grid>

                <Box>
                    <Grid container justifyContent={'center'}>
                        <Grid container item xs={11} md={3}>
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        backgroundColor: '#fff',
                                        borderRadius: '5px',
                                        padding: '2.5rem',
                                    }}
                                >
                                    <Grid
                                        style={{
                                            marginTop: '1.5rem',
                                        }}
                                        container
                                        justifyContent={'center'}
                                    >
                                        <Grid item xs={9}>
                                            <Box marginBottom={3}>
                                                <JobluTypography.H4
                                                    primary
                                                    style={{ fontWeight: 600 }}
                                                    align="center"
                                                >
                                                    TALENT FINDER
                                                </JobluTypography.H4>
                                                <Box
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #E9EBEC',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            width: 98,
                                                            height: 5,
                                                            background:
                                                                '#009CDE',
                                                            borderRadius:
                                                                '5px 5px 0px 0px',
                                                        }}
                                                    ></Box>
                                                </Box>
                                                <Box
                                                    style={{
                                                        border: '2px solid #f1f1f1',
                                                        borderRadius: 8,
                                                        marginTop: 20,
                                                        padding: 20,
                                                    }}
                                                >
                                                    {talent_search_details?.unit_amount ? (
                                                        <JobluTypography.H2
                                                            primary
                                                            semiBold
                                                            style={{
                                                                fontWeight: 700,
                                                            }}
                                                            align="center"
                                                        >
                                                            {talent_search_details?.currency ===
                                                            'usd' ? (
                                                                '$'
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        fontWeight: 400,
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    &#8369;
                                                                </span>
                                                            )}
                                                            {talent_search_details.unit_amount &&
                                                                thousendSeparator(
                                                                    talent_search_details.unit_amount /
                                                                        100
                                                                )}
                                                        </JobluTypography.H2>
                                                    ) : (
                                                        <Skeleton />
                                                    )}
                                                </Box>
                                            </Box>

                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: '.5rem',
                                                }}
                                            >
                                                {' '}
                                                <Box
                                                    style={{
                                                        marginRight: '1rem',
                                                    }}
                                                >
                                                    <Check color="secondary" />
                                                </Box>
                                                <JobluTypography.Body
                                                    primary
                                                    semiBold
                                                >
                                                    Unlimited Candidate Search
                                                </JobluTypography.Body>
                                            </Box>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: '.5rem',
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        marginRight: '1rem',
                                                    }}
                                                >
                                                    <Check color="secondary" />
                                                </Box>

                                                <JobluTypography.Body
                                                    primary
                                                    semiBold
                                                >
                                                    100 superlikes (direct
                                                    messages to candidates)
                                                </JobluTypography.Body>
                                            </Box>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: '.5rem',
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        marginRight: '1rem',
                                                    }}
                                                >
                                                    <Check color="secondary" />
                                                </Box>

                                                <JobluTypography.Body
                                                    primary
                                                    semiBold
                                                >
                                                    Accessible candidate contact
                                                    information
                                                </JobluTypography.Body>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '1rem' }}>
                                <Button
                                    onClick={() => {
                                        handleTalentSearchPayment()
                                    }}
                                    variant="contained"
                                    fullWidth
                                    style={{
                                        padding: '0.5rem',
                                        color: '#27323C',
                                        textTransform: 'none',
                                        backgroundColor: '#FBBC04',
                                    }}
                                >
                                    <JobluTypography.Body semiBold>
                                        Upgrade Now
                                    </JobluTypography.Body>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Dialog
                open={showConfirmModal}
                maxWidth={cancel_step === 1 ? 'xs' : 'sm'}
            >
                <DialogTitle style={{ background: '#002E5D' }}>
                    <JobluTypography.H6
                        align="center"
                        style={{ color: '#fff' }}
                    >
                        {cancel_step === 3 ? 'Plan Cancelled' : 'Cancel Plan'}
                    </JobluTypography.H6>
                </DialogTitle>
                {!cancellation_loading ? (
                    <Box padding={5}>
                        {cancel_step === 1 ? (
                            <>
                                <Box marginBottom={3}>
                                    <JobluTypography.H5 primary align="center">
                                        Are you sure you want to cancel your
                                        subscription?
                                    </JobluTypography.H5>
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            style={{
                                                // color: '#EB5757',
                                                // border: '1px solid #EB5757',
                                                textTransform: 'none',
                                            }}
                                            onClick={() => {
                                                setCancelStep(2)
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            style={{ textTransform: 'none' }}
                                            onClick={() => {
                                                setShowConfirmationModal(false)
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : cancel_step === 2 ? (
                            <Box>
                                <JobluTypography.H5
                                    align="center"
                                    gutterBottom={3}
                                    primary
                                    style={{ fontWeight: 600 }}
                                >
                                    We'll be sad to see you go
                                </JobluTypography.H5>
                                <JobluTypography.Body
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 18,
                                    }}
                                    primary
                                >
                                    Canceling your subscription means missing
                                    out on top talent and exclusive features.
                                    <br />
                                    <br />
                                    We're the ultimate hiring platform for a
                                    reason, and we want you to experience the
                                    best. So, are you absolutely sure?
                                </JobluTypography.Body>
                                <Grid container justifyContent="center">
                                    <Grid item xs={8} container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                style={{
                                                    color: '#fff',
                                                    background: '#EB5757',
                                                    textTransform: 'none',
                                                    marginTop: 20,
                                                }}
                                                onClick={() => {
                                                    setLoadingCancel(true)
                                                    paymentRequests
                                                        .cancelSubscription({
                                                            subscriptionId:
                                                                User
                                                                    ?.subscription
                                                                    ?.transactionId,
                                                        })
                                                        .then((res) => {
                                                            setCancelStep(3)
                                                            setTimeout(() => {
                                                                getActiveSubcription()
                                                            }, 500)
                                                        })
                                                        .catch(() => {
                                                            alert(
                                                                'An error occured'
                                                            )
                                                            setShowConfirmationModal(
                                                                false
                                                            )
                                                        })
                                                        .finally(() => {
                                                            setTimeout(() => {
                                                                setLoadingCancel(
                                                                    false
                                                                )
                                                                // setShowConfirmationModal(
                                                                //     false
                                                                // )
                                                                //setCancelStep(1)
                                                            }, 4000)
                                                        })
                                                }}
                                            >
                                                Cancel my subscription
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                color="primary"
                                                variant="contained"
                                                style={{
                                                    textTransform: 'none',
                                                }}
                                                onClick={() => {
                                                    setCancelStep(1)
                                                    setShowConfirmationModal(
                                                        false
                                                    )
                                                }}
                                            >
                                                No, I changed my mind
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : (
                            <>
                                <JobluTypography.H6>
                                    Your Plan has been cancelled
                                </JobluTypography.H6>
                                <Button
                                    onClick={() => {
                                        setShowConfirmationModal(false)
                                        setTimeout(() => {
                                            setCancelStep(1)
                                        }, 500)
                                    }}
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    style={{
                                        textTransform: 'none',
                                        marginTop: 20,
                                    }}
                                >
                                    Okay
                                </Button>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box padding={5}>
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                        <JobluTypography.Body align="center">
                            Please wait while we process your transaction...
                        </JobluTypography.Body>
                    </Box>
                )}
            </Dialog>
        </>
    )
}
export const JobluSubscriptions = products
const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
const mapDispatchToProps = {
    getActiveSubcription: userActions.getActiveSubscription,
}
export default connect(mapStateToProps, mapDispatchToProps)(Pricing)
