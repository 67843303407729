import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import TextField from '../../components/inputs/CustomTextField'
import CustomAlert from '../../components/reusables/Alert/Alert'
import { calendarRequest, userRequest } from '../../service/requests'
import General from './components/General'
import PaymentAndCredits from './components/PaymentAndCredits'
import Preferences from './components/Preferences'
import './styles.css'

import axios from 'axios'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import MeetLogo from '../../assets/img/settings/meetLogo.png'
import MSoft from '../../assets/img/settings/microsoft.png'
import SkypeLogo from '../../assets/img/settings/skype.png'
import TeamsLogo from '../../assets/img/settings/teams.png'
import ZoomLogo from '../../assets/img/settings/zoomLogo.png'
import Hoverable from '../../components/reusables/Hoverable/Hoverable'
import rootAction from '../../redux/action'
import { store } from '../../redux/store'
import { baseURL, BEARER } from '../../utils/constants'

export const APP_TYPES = {
    google: {
        name: 'Google Meet',
        provider: 'google',
        icon: MeetLogo,
        id: 'google',
    },
    microsoft: {
        icon: MSoft,
        name: 'Microsoft',
        provider: 'microsoft',
        id: 'microsoft',
    },
    zoom: {
        name: 'Zoom ',
        provider: 'zoom',
        icon: ZoomLogo,
        id: 'zoom',
    },
    teams: {
        name: 'Microsoft Teams',
        provider: 'microsoft',
        icon: TeamsLogo,
        id: 'teams',
    },
    skype: {
        name: 'Skype',
        provider: 'microsoft',
        icon: SkypeLogo,
        id: 'skype',
    },
}
export const ConnectedApps = ({
    isSettings = true,
    onAppClick,
    appSelected,
}) => {
    const [authURLS, setAuthUrls] = useState({})
    const [selected_app_details, setSelectedApp] = useState(null)
    const [verified_apps, setVerifiedApps] = useState([])
    const [available_provider, setAvailableApps] = useState({
        google: false,
        microsoft: false,
        zoom: false,
    })
    const [connected_apps, setConnectedApps] = useState({
        google: false,
        microsoft: false,
        teams: false,
        skype: false,
        zoom: false,
    })
    const [showRemove, setShowRemove] = useState(null)
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        let available_for_user = []
        Object.keys(APP_TYPES).forEach((key) => {
            const app = APP_TYPES[key]

            if (!isSettings) {
                // not profile settings
                if (connected_apps?.microsoft && app?.id === 'microsoft') {
                } else {
                    if (
                        connected_apps?.[app.id] &&
                        app.id === 'skype' &&
                        connected_apps?.teams === true
                    ) {
                        //do not push skype
                    }
                    if (connected_apps?.[app.id]) {
                        available_for_user.push(app)
                    }

                    if (
                        !connected_apps[app?.id] &&
                        available_provider[app?.provider]
                    ) {
                        console.log('provider not connected')
                        if (app.id !== 'teams' && app.id !== 'skype') {
                            available_for_user.push(app)
                        } else {
                        }
                    }
                }
            } else {
                //SETTINGS CONFIG
                if (
                    connected_apps?.[app.id] &&
                    app.id === 'skype' &&
                    connected_apps?.teams === true
                ) {
                    //do not push skype
                }
                if (connected_apps?.[app.id]) {
                    available_for_user.push(app)
                }

                if (
                    !connected_apps[app?.id] &&
                    available_provider[app?.provider]
                ) {
                    console.log('provider not connected')
                    if (app.id !== 'teams' && app.id !== 'skype') {
                        available_for_user.push(app)
                    } else {
                    }
                }
            }
        })

        if (connected_apps.teams === false && connected_apps.skype === false) {
            available_for_user.push(APP_TYPES.microsoft)
        }
        let temp = _.uniqBy(available_for_user, 'id')
        setVerifiedApps(temp)
    }, [connected_apps, available_provider])

    useEffect(() => {
        if (
            connected_apps.teams === false &&
            connected_apps.skype === false &&
            connected_apps.microsoft === true
        ) {
            setConnectedApps({ ...connected_apps, microsoft: false })
        }
    }, [connected_apps])

    const get_connected_apps = () => {
        calendarRequest.getCalendarConnections().then((res) => {
            console.log('connections', res.data)
            setConnectedApps(res.data)
            //setConnectedApps({ ...res.data, skype: false, teams: false }) // test both skype and teams as false
        })
    }
    useEffect(() => {
        calendarRequest.getCalendarAuthUrls().then((res) => {
            console.log(res.data)
            setAuthUrls(res.data)
        })
        calendarRequest.getAvailableProvider().then((res) => {
            console.log('available', res.data)
            setAvailableApps(res.data)
        })
        get_connected_apps()
    }, [])

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code')
        //const scope = new URLSearchParams(location.search).get('scope')
        setSelectedApp({ code, provider: params?.type?.toUpperCase() })
    }, [params, location])

    useEffect(() => {
        console.log('selected_app_details', selected_app_details)

        if (selected_app_details?.code && selected_app_details?.provider) {
            calendarRequest
                .connectCalendarIntegration(selected_app_details)
                .then((res) => {
                    console.log(res.data)
                    window.close()
                })
        }
    }, [selected_app_details])

    const handleDisconnect = () => {
        calendarRequest
            .removeCalendarIntegration(showRemove?.provider?.toUpperCase())
            .then(() => {
                get_connected_apps()
            })
            .finally(() => {
                setShowRemove(null)
            })
    }

    const handleClick = (thrid_party_app = null) => {
        if (isSettings) {
            if (connected_apps?.[thrid_party_app?.provider] === false) {
                const win = window
                const h = 700
                const w = 500
                const y = win.top.innerHeight / 2 + win.top.screenY - h / 2
                const x = win.screen.availWidth / 2 + win.top.screenX - w / 2
                const test = window.open(
                    authURLS?.[
                        ['skype', 'microsoft', 'teams'].includes(
                            thrid_party_app?.provider
                        )
                            ? 'outlook'
                            : thrid_party_app?.provider
                    ],
                    'connect',
                    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
                )
                const timer = setInterval(() => {
                    if (test.closed) {
                        clearInterval(timer)
                        get_connected_apps()
                    }
                }, 500)
            } else {
                console.log('show app settings')
            }
        } else {
            if (connected_apps?.[thrid_party_app.provider]) {
                onAppClick && onAppClick(thrid_party_app)
            } else {
                const win = window
                const h = 700
                const w = 500
                const y = win.top.innerHeight / 2 + win.top.screenY - h / 2
                const x = win.screen.availWidth / 2 + win.top.screenX - w / 2
                const test = window.open(
                    authURLS?.[
                        ['skype', 'microsoft', 'teams'].includes(
                            thrid_party_app?.provider
                        )
                            ? 'outlook'
                            : thrid_party_app?.provider
                    ],
                    'connect',
                    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
                )
                const timer = setInterval(() => {
                    if (test.closed) {
                        clearInterval(timer)
                        get_connected_apps()
                    }
                }, 500)
            }
        }
    }
    return (
        <>
            <div className="card">
                {isSettings && (
                    <div className="card-header bg-white p-4">
                        <h6 className="">Connected Apps</h6>
                    </div>
                )}
                <div className="card-body p-4">
                    <div className="d-flex">
                        {verified_apps.map((thrid_party_app, index) => {
                            return (
                                <div
                                    className="me-3 text-center hand"
                                    onClick={() => {
                                        handleClick(thrid_party_app)
                                    }}
                                    style={{
                                        border:
                                            appSelected === thrid_party_app &&
                                            '3px solid #002E5D',
                                        borderRadius:
                                            appSelected === thrid_party_app &&
                                            '8px',
                                        padding: '8px',
                                    }}
                                >
                                    <Hoverable
                                        renderOnHover={() => {
                                            if (
                                                !connected_apps?.[
                                                    thrid_party_app.provider
                                                ]
                                            ) {
                                                return null
                                            }
                                            if (
                                                thrid_party_app?.id ===
                                                    'skype' ||
                                                thrid_party_app?.id === 'teams'
                                            )
                                                return null
                                            if (!isSettings) return null
                                            return (
                                                <div
                                                    className="position-absolute"
                                                    style={{
                                                        right: 0,
                                                        top: -15,
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        fill="red"
                                                        class="bi bi-x-circle-fill"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => {
                                                            setShowRemove(
                                                                thrid_party_app
                                                            )
                                                        }}
                                                    >
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                    </svg>
                                                </div>
                                            )
                                        }}
                                        maintainChildren
                                    >
                                        <img
                                            src={thrid_party_app.icon}
                                            class="mb-1"
                                            alt=""
                                            style={{
                                                width: isSettings ? 70 : 60,
                                                height: isSettings ? 70 : 60,
                                                opacity:
                                                    !connected_apps?.[
                                                        thrid_party_app.provider
                                                    ] ||
                                                    (thrid_party_app?.id ===
                                                        'microsoft' &&
                                                        connected_apps.teams ===
                                                            false &&
                                                        connected_apps.skype ===
                                                            false &&
                                                        available_provider.microsoft)
                                                        ? 0.5
                                                        : 1,
                                            }}
                                        />
                                        <p>{thrid_party_app?.name}</p>
                                    </Hoverable>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* {
            !isSettings && (!connected_apps?.google && !connected_apps?.microsoft)
            &&  <a href="#" style={{marginLeft: 20, marginTop: -20}} onClick={() => navigate('/settings')}><h6 className="">Connect Apps</h6></a>
            } */}
            </div>

            <Modal
                centered
                show={showRemove}
                onHide={() => {
                    setShowRemove(null)
                }}
            >
                <div className="px-4 py-4 open-sans">
                    <div className="d-flex justify-content-center mb-3">
                        <img
                            src={showRemove?.icon}
                            alt=""
                            style={{ width: 70, height: 70 }}
                        />
                    </div>
                    <h4 className="text-center mb-5">
                        {' '}
                        Are you sure you want to disconnect {
                            showRemove?.name
                        }{' '}
                        from Joblu?
                    </h4>

                    <div className="d-flex justify-content-center">
                        <button
                            className="btn btn-primary"
                            style={{ width: 100, marginRight: 20 }}
                            onClick={() => {
                                setShowRemove(null)
                            }}
                        >
                            {' '}
                            No{' '}
                        </button>
                        <button
                            className="btn btn-outline-primary text-center"
                            style={{ width: 100 }}
                            onClick={() => {
                                handleDisconnect()
                            }}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

function Settings(props) {
    const { User, signOut } = props
    const navigate = useNavigate()
    const [page, pageState] = useState('general')
    const handlePage = (val) => {
        console.log(val)
        pageState(val)
    }

    const handleLogout = () => {
        try {
            userRequest
                .signOut({ token: User?.token })
                .then((res) => {
                    signOut()
                })
                .finally(() => {
                    setTimeout(() => {
                        navigate('/login')
                    }, 500)
                })
        } catch (err) {
            console.log(err)
        }
    }

    const [isUpdating, setIsUpdating] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const [currentPassword, setCurrentPassword] = useState(null)
    const [errorCurrentPassword, setError_CurrentPassword] = useState(null)

    const [newPassword, setNewPassword] = useState(null)
    const [errorNewPassword, setError_NewPassword] = useState(null)

    const [confirmNewPassword, setConfirmNewPassword] = useState(null)
    const [errorConfirmNewPassword, setError_ConfirmNewPassword] =
        useState(null)

    const [alert, setAlert] = useState(null)

    useEffect(() => {
        if (currentPassword) setError_CurrentPassword(null)
        if (currentPassword) {
            if (currentPassword.length < 8) {
                setError_CurrentPassword(
                    'Current password should be atleast 8 characters'
                )
            } else {
                setError_CurrentPassword(null)
            }
        }
    }, [currentPassword])

    useEffect(() => {
        if (newPassword) setError_NewPassword(null)
        if (newPassword) {
            if (newPassword.length < 8) {
                setError_NewPassword(
                    'New password should be atleast 8 characters'
                )
            } else {
                setError_NewPassword(null)
            }
        }
    }, [newPassword])

    useEffect(() => {
        if (confirmNewPassword) setError_ConfirmNewPassword(null)
        if (confirmNewPassword) {
            if (confirmNewPassword != newPassword) {
                setError_ConfirmNewPassword('Passwords do not match')
            } else {
                setError_ConfirmNewPassword(null)
            }
        }
    }, [confirmNewPassword])

    const validateFields = () => {
        let isValid = []

        if (currentPassword) setError_CurrentPassword(null)
        if (currentPassword) {
            if (currentPassword.length < 8) {
                setError_CurrentPassword(
                    'Current password should be atleast 8 characters'
                )
                isValid.push(false)
            } else {
                setError_CurrentPassword(null)
                isValid.push(true)
            }
        }

        if (newPassword) setError_NewPassword(null)
        if (newPassword) {
            if (newPassword.length < 8) {
                setError_NewPassword(
                    'New password should be atleast 8 characters'
                )
                isValid.push(false)
            } else {
                setError_NewPassword(null)
                isValid.push(true)
            }
        }
        if (confirmNewPassword) setError_ConfirmNewPassword(null)
        if (confirmNewPassword) {
            if (confirmNewPassword != newPassword) {
                setError_ConfirmNewPassword('Passwords do not match')
                isValid.push(false)
            } else {
                setError_ConfirmNewPassword(null)
                isValid.push(true)
            }
        }

        if (confirmNewPassword != newPassword) {
            setError_ConfirmNewPassword('Passwords do not match')
            isValid.push(false)
        } else {
            setError_ConfirmNewPassword(null)
            isValid.push(true)
        }
        return isValid.includes(false) ? false : true
    }
    const changePassword = () => {
        const valid = validateFields()

        if (!valid) {
            return
        }

        const isErrorCurrentPassword =
            currentPassword === null || currentPassword === ''
        setError_CurrentPassword(
            isErrorCurrentPassword ? 'Current password is required' : null
        )

        const isErrorNewPassword = newPassword === null || newPassword === ''
        setError_NewPassword(
            isErrorNewPassword ? 'New password is required' : null
        )

        const isErrorNewPassord_ = currentPassword === newPassword
        setError_NewPassword(
            isErrorNewPassord_ ? 'You cannot use your old password' : null
        )

        if (
            isErrorCurrentPassword ||
            isErrorNewPassword ||
            isErrorNewPassord_
        ) {
            return
        }

        if (
            errorCurrentPassword !== null ||
            errorNewPassword !== null ||
            errorConfirmNewPassword !== null
        ) {
            return
        }

        // console.log('ASDASDASD')

        // return;
        setIsUpdating(true)

        const token = store.getState().User.token

        axios
            .post(
                `${baseURL}/users/change-password`,
                {
                    oldPassword: currentPassword,
                    newPassword: newPassword,
                },
                { headers: { Authorization: BEARER + token } }
            )
            .then((res) => {
                setCurrentPassword('')
                setNewPassword('')
                setConfirmNewPassword('')
                setIsSuccessful(true)
                setIsUpdating(false)
                setAlert('Successfully changed password!')
                setTimeout(() => {
                    setIsSuccessful(false)
                    handleLogout()
                }, 2000)
            })
            .catch((err) => {
                if (err.response.data.message === '401001: Invalid credentials')
                    setError_CurrentPassword('Invalid current password')
                setIsUpdating(false)
            })

        // userRequest
        //     .changePassword({
        //         oldPassword: currentPassword,
        //         newPassword: newPassword,
        //     })
        //     .then((response) => {
        //         setCurrentPassword('')
        //         setNewPassword('')
        //         setConfirmNewPassword('')
        //         setIsSuccessful(true)
        //         setIsUpdating(false)
        //         setAlert('Successfully changed password!')
        //         setTimeout(() => {
        //             setIsSuccessful(false)
        //             handleLogout()
        //         }, 2000)
        //     })
        //     .catch((err) => {
        //         console.log(err.response.data)
        //         if (err.response.data.message === '401001: Invalid credentials')
        //             setError_CurrentPassword('Invalid current password')
        //         setIsUpdating(false)
        //     })
    }

    return (
        <div className="">
            <CustomAlert show={isSuccessful} body={alert} />
            <div className="border-bottom ">
                <div className="container ">
                    <div className="justify-content-between align-items-center row">
                        <div className="col-sm-12 col-md-3 my-3">
                            <h5 className="text-body fw500 mb-2 ">Settings</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12">
                        <div className="card mb-3">
                            <div className="card-header bg-white p-4">
                                <h6 className="">Change Password</h6>
                            </div>
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 mb-2">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <label htmlFor="">
                                                        Old Password
                                                    </label>
                                                    <TextField
                                                        onChange={(e) =>
                                                            setCurrentPassword(
                                                                e?.target?.value
                                                            )
                                                        }
                                                        value={currentPassword}
                                                        className="mb-3"
                                                        type={'password'}
                                                        error={
                                                            errorCurrentPassword
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <label htmlFor="">
                                                        New Password
                                                    </label>
                                                    <TextField
                                                        onChange={(e) =>
                                                            setNewPassword(
                                                                e?.target?.value
                                                            )
                                                        }
                                                        value={newPassword}
                                                        className="mb-3"
                                                        type={'password'}
                                                        error={errorNewPassword}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <label htmlFor="">
                                                        Confirm Password
                                                    </label>
                                                    <TextField
                                                        onChange={(e) =>
                                                            setConfirmNewPassword(
                                                                e?.target?.value
                                                            )
                                                        }
                                                        value={
                                                            confirmNewPassword
                                                        }
                                                        className="mb-3"
                                                        type={'password'}
                                                        error={
                                                            errorConfirmNewPassword
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    onClick={() =>
                                                        changePassword()
                                                    }
                                                    className="btn btn-primary mt-2 px-4"
                                                    id="savechanges"
                                                >
                                                    Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ConnectedApps />
                    </div>
                    <div className="col-md-3 d-none col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <h6>
                                        <a
                                            href="#"
                                            className="fs18 text-decoration-none text-primary"
                                            onClick={() =>
                                                handlePage('general')
                                            }
                                        >
                                            General Settings
                                        </a>
                                    </h6>
                                </div>
                                <div className="mb-3">
                                    <h6>
                                        <a
                                            href="#"
                                            className="fs18 text-decoration-none text-primary"
                                            onClick={() =>
                                                handlePage(
                                                    'payment-and-credits'
                                                )
                                            }
                                        >
                                            Payment and Credits
                                        </a>
                                    </h6>
                                </div>
                                <div className="">
                                    <h6>
                                        <a
                                            href="#"
                                            className="text-decoration-none text-primary"
                                            onClick={() =>
                                                handlePage('preferences')
                                            }
                                        >
                                            Preferences
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 d-none">
                        {page === 'general' && <General />}
                        {page === 'payment-and-credits' && (
                            <PaymentAndCredits />
                        )}
                        {page === 'preferences' && <Preferences />}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}

const mapDispatchToProps = {
    signOut: rootAction.signOut,
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings)
