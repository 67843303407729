import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import './index.css'
import { persistor, store } from './redux/store'
import * as serviceWorker from './serviceWorker'

if (process.env.REACT_APP_NODE_ENV !== 'development') {
    console.log = () => {}
    console.error = () => {}
    console.warn = () => {}
}

const container = document.getElementById('root')
const root = createRoot(container)
let theme = createTheme({
    palette: {
        primary: {
            main: '#002E5D',
        },
        secondary: {
            main: '#009CDE',
        },
        warning: {
            main: '#D7A048',
        },
    },
    typography: {
        fontFamily: 'Open sans',
        h1: {
            fontFamily: 'Poppins',
        },
        h2: {
            fontFamily: 'Poppins',
        },
        h3: {
            fontFamily: 'Poppins',
        },
        h4: {
            fontFamily: 'Poppins',
        },
        h5: {
            fontFamily: 'Poppins',
        },
        h6: {
            fontFamily: 'Poppins',
        },
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "16px",
            },
        },
    },
})
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
