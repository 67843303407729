import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Typography,
} from '@material-ui/core'
import _, { debounce } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import jobEditSuccess from '../../assets/img/createJob/job-edit-message.png'
import jobPendingIcon from '../../assets/img/createJob/job-pending-icon.png'
import CustomGooglePlaces from '../../components/google-places'
import TextField from '../../components/inputs/CustomTextField'
import SelectJobFunction from '../../components/reusables/SelectJobFunction'
import { configRequest, jobsRequest, userRequest } from '../../service/requests'
import useWindowDimensions from '../../utils/hooks'
import { skills_masterlist } from '../../utils/skills'
import { validateEmail } from '../../utils/validators'
import './style.css'
import { LargeBanner, MediumBanner } from '../../components/adbanner'
function CreateJob(props) {
    const { width } = useWindowDimensions()
    const { User, AppState } = props
    const navigate = useNavigate()
    const location_ = useLocation()
    const { currency } = AppState
    const [currentIndex, setCurrentIndex] = useState(0)
    const [jobFunctions, setJobFunctions] = useState([])

    const [jobTitle, setJobTitle] = useState(null)
    const [jobFunction, setJobFunction] = useState(null)
    const [skills, setSkills] = useState([])
    const [skillsets, setSkillsets] = useState([])
    const [responsibilities, setResponsibilities] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [currency2, setCurrency2] = useState(currency)
    const [country, setCountry] = useState(User?.user.country || null)
    const [location, setLocation] = useState(
        User?.user?.currentLocation || User?.user?.country || null
    )
    const [experienceRequired, setExperienceRequired] = useState(null)
    const [education, setEducation] = useState([])
    const [workSetup, setWorkSetup] = useState([])
    const [employmentType, setEmploymentType] = useState([])
    const [benefits, setBenefits] = useState([])
    const [tempSkill, setTempSkill] = useState('')
    const [error_Skills, setError_Skills] = useState(null)
    const [atsMailBox, setAtsMailBox] = useState([])
    const [salaryRange, setSalaryRange] = useState('2-2')
    const [error_SalaryRange, setError_SalaryRange] = useState(null)
    const [salaryMin, setSalaryMin] = useState(null)
    const [salaryMax, setSalaryMax] = useState(null)
    const [isSearcingSkills, setIsSearchingSkill] = useState(false)
    const [showJobStatus, setShowJobStatus] = useState(null)
    const [testSkills, setTestSkills] = useState(
        skills.map((item) => {
            return {
                label: item,
                value: item,
            }
        })
    )
    const [skill_list, setSkill_List] = useState([
        ...skills_masterlist.sort((a, b) => (a.value < b.value ? -1 : 1)),
    ])
    const [skillSearch, setSkillSearch] = useState(null)

    const onSelectSalaryRange = (value) => {
        setSalaryRange(value)
        setSalaryMin('')
        setSalaryMax('')
        setSalaryMaxError(false)
        setSalaryMaxError2(false)
        setSalaryMinError(false)
    }

    const handleChange = (selectedOption) => {
        let skillsCopy = []
        selectedOption.map((item) => {
            skillsCopy.push(item.value)
        })
        console.log(skillsCopy)
        setSkills(skillsCopy)
    }

    const loadOptions = debounce((inputValue, callback) => {
        if (!!inputValue) {
            configRequest
                .searchSkills({ search: inputValue })
                .then((res) => {
                    console.log(res.data)
                    const selected = skills.map((item) => {
                        return {
                            label: item,
                            value: item,
                        }
                    })
                    const searched = res?.data?.map((item) => {
                        return {
                            label: item,
                            value: item,
                        }
                    })
                    let combined
                    if (searched?.length === 0) {
                        combined = _.uniqBy(
                            [
                                ...selected,
                                { label: inputValue, value: inputValue },
                            ],
                            function (e) {
                                return e.value
                            }
                        )
                    } else {
                        combined = _.uniqBy(
                            [...selected, ...searched],
                            function (e) {
                                return e.value
                            }
                        )
                    }
                    callback(
                        combined.sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setIsSearchingSkill(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsSearchingSkill(false)
                })
        } else {
            const selected = skills.map((item) => {
                return {
                    label: item,
                    value: item,
                }
            })
            const combined = _.uniqBy(
                [...selected, ...skill_list],
                function (e) {
                    return e.value
                }
            )
            if (skills.length > 0) {
                callback(combined.sort((a, b) => (a.value < b.value ? -1 : 1)))
            } else {
                callback(skill_list)
            }
            setIsSearchingSkill(false)
        }
        // setTimeout(() => {
        //     callback(skill_list)
        // }, 1000)
    }, 1000)

    // ERRORS
    const [errors, setErrors] = useState({
        jobTitle: null,
    })

    useEffect(() => {
        if (currency == 'PHP') {
            setCurrency2('PHP')
        } else if (currency == 'SGD') {
            setCurrency2('SGD')
        } else if (currency == 'USD') {
            setCurrency2('USD')
        } else {
            setCurrency2('PHP')
        }
    }, [])

    useEffect(() => {
        if (currency == 'PHP') {
            setCurrency2('PHP')
        } else if (currency == 'SGD') {
            setCurrency2('SGD')
        } else if (currency == 'USD') {
            setCurrency2('USD')
        } else {
            setCurrency2('PHP')
        }
    }, [currency])
    const [jobFunctionError, setJobFunctionError] = useState(false)
    const [skillsError, setSkillsError] = useState(false)
    const [responsibilitiesError, setResponsibilitiesError] = useState(false)
    const [currencyError, setCurrencyError] = useState(false)
    const [salaryMinerror, setSalaryMinError] = useState(false)
    const [salaryMaxerror, setSalaryMaxError] = useState(false)
    const [salaryMaxerror2, setSalaryMaxError2] = useState(false)
    const [error_JobFunction, setError_JobFunction] = useState(null)
    const [workSetupError, setWorkSetupError] = useState(false)
    const [startDateError, setStartDateError] = useState(false)
    const [employmentTypeError, setEmploymentTypeError] = useState(false)
    const [atsError, setAtsError] = useState(false)
    const [error, setError] = useState(false)
    const validate = () => {
        let isValid = []
        if (!jobTitle) {
            setErrors({ ...errors, jobTitle: 'Required' })
            isValid.push(false)
        } else {
            setErrors({ ...errors, jobTitle: false })
            isValid.push(true)
        }
        if (!jobFunction) {
            setJobFunctionError(true)
            isValid.push(false)
        } else {
            setJobFunctionError(false)
            isValid.push(true)
        }
        if (skills.length < 1) {
            setSkillsError(true)
            isValid.push(false)
        } else {
            setSkillsError(false)
            isValid.push(true)
        }
        if (!responsibilities) {
            setResponsibilitiesError(true)
            isValid.push(false)
        } else {
            setResponsibilitiesError(false)
            isValid.push(true)
        }
        if (startDate < moment().format('YYYY-MM-DD')) {
            setStartDateError(true)
            isValid.push(false)
        } else {
            setStartDateError(false)
            isValid.push(true)
        }
        if (!currency2) {
            setCurrencyError(true)
            isValid.push(false)
        } else {
            setCurrencyError(false)
            isValid.push(true)
        }
        // if (!salaryMin) {
        //     setSalaryMinError(true)
        //     isValid.push(false)
        // } else {
        //     setSalaryMinError(false)
        //     isValid.push(true)
        // }
        // if (!salaryMax) {
        //     setSalaryMaxError(true)

        //     isValid.push(false)
        // } else {
        //     setSalaryMaxError(false)
        //     isValid.push(true)
        // }

        if (!salaryRange) {
            if (salaryMin || salaryMax) {
                setSalaryMinError(!salaryMin ? true : false)
                setSalaryMaxError(!salaryMax ? true : false)
            } else {
                setSalaryMinError(false)
                setSalaryMaxError(false)
                setSalaryRange('2-2')
            }
        } else {
            setSalaryMinError(false)
            setSalaryMaxError(false)
            setSalaryRange('2-2')
        }

        if (
            salaryMin &&
            salaryMax &&
            removeComma(salaryMin) > removeComma(salaryMax)
        ) {
            setSalaryMaxError2(
                'Minimum salary is greater than max salary provided'
            )
            return false
        } else {
            setSalaryMaxError2(false)
        }

        // if (atsMailBox.length < 1) {
        //     setAtsError(true)
        //     isValid.push(false)
        // } else {
        //     setAtsError(false)
        //     isValid.push(true)
        // }
        // let sMin
        // let sMax
        // if (salaryMin && salaryMax) {
        //     sMin = removeComma(salaryMin)
        //     sMax = removeComma(salaryMax)
        // }

        // if (sMin > sMax) {
        //     setSalaryMaxError2(true)
        //     isValid.push(false)
        // } else {
        //     setSalaryMaxError2(false)
        //     isValid.push(true)
        // }
        if (workSetup.length < 1) {
            setWorkSetupError(true)
            isValid.push(false)
        } else {
            setWorkSetupError(false)
            isValid.push(true)
        }
        if (employmentType.length < 1) {
            setEmploymentTypeError(true)
            isValid.push(false)
        } else {
            setEmploymentTypeError(false)
            isValid.push(true)
        }
        console.log(isValid)
        return isValid.includes(false) ? false : true
    }
    // GET JOB FUNCTIONS LIST
    useEffect(() => {
        configRequest.getJobFunctions().then((response) => {
            setJobFunctions(response?.data?.data || [])
        })
    }, [])

    const addCommas = (num) =>
        num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, '')

    const removeComma = (num) => {
        let copy = num?.toString()
        let num1
        if (copy?.indexOf(',') != -1) {
            num1 = parseFloat(num?.replace(/,/g, ''))
            return num1
        } else {
            return parseFloat(copy)
        }
    }

    const handleSalaryMin = (event) => {
        setSalaryMin(addCommas(removeNonNumeric(event.target.value)))
        if (event?.target?.value) {
            setSalaryRange(null)
        }
    }

    const handleSalaryMax = (event) => {
        setSalaryMax(addCommas(removeNonNumeric(event.target.value)))
        setSalaryRange(null)
    }

    // CREATE JOB FUNCTION
    const createJob = async (val) => {
        // setShowJobStatus({ type: 'submitting' })
        let salary = salaryRange?.split('-')
        let sMin = removeComma(salaryMin)
        let sMax = removeComma(salaryMax)
        const valid = val === 'isDraft' ? true : validate()
        console.log(valid)
        if (valid) {
            setSubmitting(true)
            // if (val == 'isDraft') {
            setError(false)
            // }
            const expiration = moment().add(6, 'months')
            let experience = experienceRequired?.split('-')
            let newStartDate = new Date(startDate)

            let payload = {
                totalExperienceMin: experienceRequired
                    ? parseInt(experience[0])
                    : null,
                totalExperienceMax: experienceRequired
                    ? parseInt(experience[1])
                    : null,
                salaryCurrency: currency2,
                salaryMin: salaryMin ? sMin : parseInt(salary[0]),
                salaryMax: salaryMax ? sMax : parseInt(salary[1]),
                atsMailBox,
                isDraft: val !== 'isPublish' ? true : false,
                isPublished: val !== 'isPublish' ? false : true,
                status: 'active',
                location: location ? location : User?.user?.country,
                country: country ? country : User?.user?.country,
                education,
                jobExpiry: expiration,
                expectedStartDate: startDate ? newStartDate : null,
                jobTitle,
                technicalSkills: skills,
                employmentType,
                jobFunction,
                responsibilities,
                workSetup,
                perksAndBenefits: benefits,
            }
            try {
                const response = await jobsRequest.createJob(payload)
                if (response) {
                    console.log(response)

                    setTimeout(() => {
                        setSubmitting(false)
                    }, 1500)
                    if (val === 'isEdit') {
                        console.log('edit')
                        const noNav = new URLSearchParams(location_.search).get(
                            'noNav'
                        )
                        if (val !== 'isDraft') {
                            setTimeout(() => {
                                setShowJobStatus({
                                    type: 'under_review',
                                    action: () => {
                                        !noNav
                                            ? navigate(`/jobs/edit?ref=other`, {
                                                  state: { job: response.data },
                                              })
                                            : navigate(
                                                  `/jobs/edit?ref=other&noNav=1`,
                                                  {
                                                      state: {
                                                          job: response.data,
                                                      },
                                                  }
                                              )
                                    },
                                })
                            }, 2000)
                        }
                    } else {
                        console.log('not edit')
                        if(val !== 'isDraft'){

                            setTimeout(() => {
                                setShowJobStatus({
                                    type: 'under_review',
                                    action: () => {
                                        navigate('/jobs')
                                    },
                                })
                            }, 2000)
                        } else {
                            setTimeout(() => {
                                navigate('/jobs')
                            }, 2000);
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            setError(true)
        }
    }

    const addSkill = () => {
        if (!!tempSkill) {
            setSkills([...skills, tempSkill])

            setTempSkill('')
        } else {
            console.log('no skill entered')
        }
    }

    useEffect(() => {
        if (tempSkill.length === 20) {
            addSkill()
        }
        var input = document.getElementById('skillInputEditjob')

        if (tempSkill.length <= 20 && tempSkill.length >= 2) {
            // Execute a function when the user presses a key on the keyboard
            input.addEventListener('keyup', function (event) {
                // If the user presses the "Enter" key on the keyboard
                if (event.key === 'Enter') {
                    // Cancel the default action, if needed
                    event.preventDefault()
                    // Trigger the button element with a click
                    addSkill()
                }
            })
        } else {
            return
        }
    }, [tempSkill])

    const removeSkill = (idx) => {
        setSkills(skills.filter((item, index) => index !== idx))
    }

    const handleEducationCheck = (e) => {
        var educationCopy = [...education]
        if (e.target.checked) {
            educationCopy = [...education, e.target.value]
        } else {
            educationCopy.splice(education.indexOf(e.target.value), 1)
        }
        setEducation(educationCopy)
    }

    const handleWorkSetupCheck = (e) => {
        var workSetupCopy = [...workSetup]
        if (e.target.checked) {
            workSetupCopy = [...workSetup, e.target.value]
        } else {
            workSetupCopy.splice(workSetup.indexOf(e.target.value), 1)
        }
        setWorkSetup(workSetupCopy)
    }

    const handleEmploymentTypeCheck = (e) => {
        var employmentTypeCopy = [...employmentType]
        if (e.target.checked) {
            employmentTypeCopy = [...employmentType, e.target.value]
        } else {
            employmentTypeCopy.splice(employmentType.indexOf(e.target.value), 1)
        }
        setEmploymentType(employmentTypeCopy)
    }

    const handleBenefitsCheck = (e) => {
        var benefitsCopy = [...benefits]
        if (e.target.checked) {
            benefitsCopy = [...benefits, e.target.value]
        } else {
            benefitsCopy.splice(benefits.indexOf(e.target.value), 1)
        }
        setBenefits(benefitsCopy)
    }
    useEffect(() => {
        console.log(startDate)
    }, [startDate])

    const createOption = (label, index) => ({
        label,
        value: label,
    })

    const isOptionUnique = (prop) => {
        const { option, options, valueKey, labelKey } = prop
        return !options.find((opt) => option[valueKey] === opt[valueKey])
    }
    const [inputValue, setInputValue] = useState(null)
    const [value, setValue] = useState([])
    const [atsEmailError, setAtsEmailError] = useState(false)
    const handleKeyDown = (event) => {
        if (!inputValue) return
        switch (event.key) {
            case 'Enter':
            case ' ':
                if (!validateEmail(inputValue)) {
                    setAtsEmailError(true)
                    return
                } else {
                    setAtsEmailError(false)
                }
                setValue((prev) => [...prev, createOption(inputValue)])
                setInputValue('')
                event.preventDefault()
        }
    }

    useEffect(() => {
        if (inputValue) {
            const delayDebounceFn = setTimeout(() => {
                if (!validateEmail(inputValue)) {
                    setAtsEmailError(true)
                } else {
                    setAtsEmailError(false)
                    setValue((prev) => [...prev, createOption(inputValue)])
                    setInputValue('')
                }
            }, 1000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [inputValue])

    useEffect(() => {
        let dataArray = value.map((a) => a.value)
        setAtsMailBox(_.uniq(dataArray))
    }, [value])

    const [showModal, setShowModal] = useState(
        location_?.state?.status == 'new' ? true : false
    )
    const [submitting, setSubmitting] = useState(false)

    const [talentSearch, setTalentSearch] = useState(true)

    useEffect(() => {
        if (User?.user) {
            userRequest
                .talentSearchStatus()
                .then((res) => {
                    console.log('taelent search', res.data)
                    setTalentSearch(true)
                })
                .catch(() => {
                    setTalentSearch(false)
                })
        }
    }, [User])

    return (
        <>
            <Dialog
                open={showModal}
                fullWidth
                maxWidth={'md'}
                onClose={() => {
                    setShowModal(false)
                }}
                style={{ zIndex: '99999999' }}
            >
                <Box style={{ padding: 80, textAlign: 'center' }}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 20,
                        }}
                    >
                        <img src={jobEditSuccess} alt="icon" width={200} />
                    </Box>
                    <Typography
                        variant="h3"
                        align="center"
                        color="primary"
                        style={{ fontWeight: 600, marginBottom: 30 }}
                    >
                        <span style={{ color: '#D7A048' }}>
                            Welcome to Joblu!
                        </span>
                        <br />
                        Thanks for signing up and joining
                        <br />
                        our community.
                    </Typography>
                    <Typography
                        variant="h5"
                        style={{ fontWeight: 400 }}
                        color="primary"
                        align="center"
                    >
                        Let's get cracking and create your first job post.
                        <br />
                        It only takes a minute!
                    </Typography>

                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 50,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                textTransform: 'none',
                                minWidth: 327,
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                setShowModal(false)
                            }}
                        >
                            Post Your Job
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <div className="create-job-bg" style={{ overFlowY: 'scroll' }}>
                <div className="container">
                    <div className="row">
                        <LargeBanner/>
                        <div className="col-md-8 offset-md-2">
                            <div className="card mt-5 create-job-card p-4">
                                <div className="card-body p-3 mb-md-5">
                                    <h4 className="text-muted mb-3">
                                        <strong>Create a Job Post</strong>
                                    </h4>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Job Title</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>
                                        <TextField
                                            type="text"
                                            required
                                            id="jobTitle"
                                            name="jobTitle"
                                            placeholder="E.g “Graphic Designer for Social Media Marketing”"
                                            // value={jobTitle}
                                            limit={100}
                                            defaultValue={jobTitle}
                                            onChange={(e) =>
                                                setJobTitle(e.target.value)
                                            }
                                            className="mb-2"
                                            error={errors.jobTitle}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Job Function</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>
                                        <SelectJobFunction
                                            onChange={(value) => {
                                                setJobFunction(value)
                                            }}
                                            name="jobFunction"
                                            defaultValue={jobFunction}
                                            value={jobFunction}
                                            error={error_JobFunction}
                                            noAll
                                        />
                                        {jobFunctionError && (
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>
                                                What technical skills do you
                                                require?{' '}
                                                <span className="required-tag">
                                                    *
                                                </span>
                                            </strong>
                                            <small className="text-muted d-block">
                                                Search any skills. You can add
                                                up to 10 skills ({skills.length}
                                                /10).
                                            </small>
                                        </label>
                                        <div>
                                            <AsyncSelect
                                                className=""
                                                onChange={handleChange}
                                                placeholder="Search technical skills..."
                                                isMulti
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={loadOptions}
                                                isOptionDisabled={() =>
                                                    skills.length >= 10
                                                }
                                                isLoading={isSearcingSkills}
                                            />
                                        </div>
                                        <div
                                            className={`form-control d-flex flex-wrap d-none ${
                                                error_Skills
                                                    ? 'text-danger border error border-danger'
                                                    : null
                                            } ${
                                                skillsError && 'border-danger'
                                            }`}
                                            id=""
                                        >
                                            {skills?.map((skill, index) => {
                                                return (
                                                    <div
                                                        className="skills-in-box d-flex p-1"
                                                        key={`${skill}-${index}`}
                                                    >
                                                        <span
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                            className="open-sans-body ps-1"
                                                        >
                                                            {skill}
                                                        </span>
                                                        <div
                                                            className="ms-2 me-1 pe-1 open-sans-body"
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                            role="button"
                                                            onClick={() =>
                                                                removeSkill(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            x
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <textarea
                                                type="text"
                                                className={`flex-grow-1 skills-input `}
                                                onChange={(e) => {
                                                    setTempSkill(e.target.value)
                                                }}
                                                value={tempSkill}
                                                id="skillInputEditjob"
                                                rows={5}
                                            ></textarea>
                                        </div>
                                        {skillsError && (
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Responsibilities</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                            <small className="text-muted d-block">
                                                What does the day-to-day of this
                                                job looks like?
                                            </small>
                                        </label>
                                        <textarea
                                            name=""
                                            className={`form-control br-3 ${
                                                responsibilitiesError &&
                                                'border-danger'
                                            }`}
                                            id=""
                                            cols="30"
                                            rows="5"
                                            onChange={(e) => {
                                                setResponsibilities(
                                                    e.target.value
                                                )
                                            }}
                                        ></textarea>
                                        {responsibilitiesError && (
                                            <span className="error-helper">
                                                Required
                                            </span>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>
                                                Estimated Start Date
                                            </strong>
                                        </label>
                                        <input
                                            type="date"
                                            className={`form-control disabled ${
                                                startDateError &&
                                                'border-danger'
                                            }`}
                                            onChange={(e) => {
                                                setStartDate(e.target.value)
                                            }}
                                            min={moment().format('YYYY-MM-DD')}
                                        />
                                        {startDateError && (
                                            <span className="error-helper">
                                                Invalid date
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Salary (Range)</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-2 mb-2 mb-md-0">
                                                <select
                                                    name=""
                                                    id=""
                                                    className={`form-select br-3 ${
                                                        currencyError &&
                                                        'border-danger'
                                                    }`}
                                                    onChange={(e) => {
                                                        setCurrency2(
                                                            e.target.value
                                                        )
                                                    }}
                                                >
                                                    <option
                                                        value="PHP"
                                                        selected={
                                                            currency == 'PHP'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        PHP
                                                    </option>
                                                    <option
                                                        value="SGD"
                                                        selected={
                                                            currency == 'SGD'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        SGD
                                                    </option>
                                                    <option
                                                        value="USD"
                                                        selected={
                                                            currency == 'USD'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        USD
                                                    </option>
                                                </select>
                                                {currencyError && (
                                                    <span className="error-helper">
                                                        Required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-5 mb-4 mb-md-0">
                                                <input
                                                    type="text"
                                                    className={`form-control br-3 ${
                                                        salaryMinerror &&
                                                        'border-danger'
                                                    }`}
                                                    placeholder="Minimum"
                                                    value={salaryMin}
                                                    onChange={handleSalaryMin}
                                                />

                                                {salaryMinerror && (
                                                    <span className="error-helper">
                                                        Required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-5">
                                                <input
                                                    type="text"
                                                    className={`form-control br-3 ${
                                                        (salaryMaxerror ||
                                                            salaryMaxerror2) &&
                                                        'border-danger'
                                                    }`}
                                                    placeholder="Maximum"
                                                    value={salaryMax}
                                                    onChange={handleSalaryMax}
                                                />

                                                {salaryMaxerror && (
                                                    <span className="error-helper">
                                                        Required
                                                    </span>
                                                )}
                                                {salaryMaxerror2 && (
                                                    <span className="error-helper">
                                                        Maximum salary should
                                                        not be lower than the
                                                        minimum.
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            or select one from these options:
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="salaryRange_industry"
                                                        id="2-2"
                                                        value="2-2"
                                                        onChange={(e) => {
                                                            onSelectSalaryRange(
                                                                '2-2'
                                                            )
                                                        }}
                                                        checked={
                                                            salaryRange ===
                                                            '2-2'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="2-2"
                                                    >
                                                        Industry Standard
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="salaryRange_competitive"
                                                        id="1-1"
                                                        value="1-1"
                                                        onChange={(e) => {
                                                            onSelectSalaryRange(
                                                                '1-1'
                                                            )
                                                        }}
                                                        checked={
                                                            salaryRange ===
                                                            '1-1'
                                                        }
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="1-1"
                                                    >
                                                        Competitive
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Country or Location</strong>
                                        </label>
                                        {/* {User && (
                                        <SelectCountry2
                                            value={country}
                                            noCellCode
                                            noAll
                                            placeholder="Select Country"
                                            onChange={(value) => {
                                                setCountry(value)
                                            }}
                                        />
                                    )} */}
                                        <CustomGooglePlaces
                                            onChange={(value) => {
                                                setCountry(value.country)
                                                setLocation(value.location)
                                            }}
                                            value={location}
                                        />
                                        {/* <input
                                        type="text"
                                        className="form-control br-3"
                                        placeholder="E.g. “Makati, Philippines”"
                                        onChange={(e) => {
                                            setCountry(e.target.value)
                                        }}
                                    /> */}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Years of Experience</strong>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="noExp"
                                                        value="0-1"
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="noExp"
                                                    >
                                                        0 - 1 year
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="1-3"
                                                        value="1-3"
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="1-3"
                                                    >
                                                        1 - 3 years
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="3-5"
                                                        value="3-5"
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="3-5"
                                                    >
                                                        3 - 5 years
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="5-7"
                                                        value="5-7"
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="5-7"
                                                    >
                                                        5 - 7 years
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="experienceRequired"
                                                        id="7above"
                                                        value="7-100 "
                                                        onChange={(e) => {
                                                            setExperienceRequired(
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="7above"
                                                    >
                                                        7 years and above
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Education</strong>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Doctorate (PhD)"
                                                        id="phd"
                                                        defaultChecked={education.includes(
                                                            'phd'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="phd"
                                                    >
                                                        Doctorate (PhD)
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Master's Degree"
                                                        id="masters"
                                                        defaultChecked={education.includes(
                                                            'masters'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="masters"
                                                    >
                                                        Master’s Degree
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Bachelor's Degree"
                                                        id="bachelors"
                                                        defaultChecked={education.includes(
                                                            'bachelors'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="bachelors"
                                                    >
                                                        Bachelor’s Degree
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Diploma"
                                                        id="diploma"
                                                        defaultChecked={education.includes(
                                                            'diploma'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="diploma"
                                                    >
                                                        Diploma
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="High School"
                                                        id="highschool"
                                                        defaultChecked={education.includes(
                                                            'highschool'
                                                        )}
                                                        onChange={(e) => {
                                                            handleEducationCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="highschool"
                                                    >
                                                        High School
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Work Setup</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>

                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="On-site"
                                                        id="on-site"
                                                        defaultChecked={workSetup.includes(
                                                            'on-site'
                                                        )}
                                                        onChange={(e) => {
                                                            handleWorkSetupCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="on-site"
                                                    >
                                                        On-Site
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Remote"
                                                        id="remote"
                                                        defaultChecked={workSetup.includes(
                                                            'Remote'
                                                        )}
                                                        onChange={(e) => {
                                                            handleWorkSetupCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="remote"
                                                    >
                                                        Remote
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Hybrid"
                                                        id="hybrid"
                                                        defaultChecked={workSetup.includes(
                                                            'hybrid'
                                                        )}
                                                        onChange={(e) => {
                                                            handleWorkSetupCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="hybrid"
                                                    >
                                                        Hybrid
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {workSetupError && (
                                            <div>
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Employment Type</strong>{' '}
                                            <span className="required-tag">
                                                *
                                            </span>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Full-Time"
                                                        id="full-time"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="full-time"
                                                    >
                                                        Full-Time
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Part-Time"
                                                        id="part-time"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="part-time"
                                                    >
                                                        Part-Time
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Project-Based"
                                                        id="project-based"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="project-based"
                                                    >
                                                        Project-Based
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Freelance"
                                                        id="freelance"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="freelance"
                                                    >
                                                        Freelance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Internship"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                        id="intern"
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="intern"
                                                    >
                                                        Internship
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Highschool Internship"
                                                        id="highschool-intern"
                                                        onChange={(e) => {
                                                            handleEmploymentTypeCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="highschool-intern"
                                                    >
                                                        Highschool Internship
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {employmentTypeError && (
                                            <div>
                                                <span className="error-helper">
                                                    Required
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="" className="mb-2">
                                            <strong>Perks and Benefits</strong>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Meal Allowance"
                                                        id="meal"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="meal"
                                                    >
                                                        Meal Allowance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Trainings & Seminars"
                                                        id="trainings"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="trainings"
                                                    >
                                                        Trainings & Seminars
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Employee Stock Options"
                                                        id="stocks"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="stocks"
                                                    >
                                                        Employee Stock Options
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Flexible Schedule"
                                                        id="flexible"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="flexible"
                                                    >
                                                        Flexible Schedule
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Fuel Discount"
                                                        id="fuel"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="fuel"
                                                    >
                                                        Fuel Discount
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Reimbursed Parking Fee"
                                                        id="reimburseParking"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="reimburseParking"
                                                    >
                                                        Reimbursed Parking Fee
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Medical Insurance"
                                                        id="medical"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="medical"
                                                    >
                                                        Medical Insurance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Gym Membership"
                                                        id="gym"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="gym"
                                                    >
                                                        Gym Membership
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Life Insurance"
                                                        id="lifeInsurance"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="lifeInsurance"
                                                    >
                                                        Life Insurance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="On-site Parking"
                                                        id="parking"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="parking"
                                                    >
                                                        On-site Parking
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Promotion to Permanent Employee"
                                                        id="promotion"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="promotion"
                                                    >
                                                        Promotion to Permanent
                                                        Employee
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Paid Time-Off"
                                                        id="paidOut"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="paidOut"
                                                    >
                                                        Paid Time-Off
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Internet Allowance"
                                                        id="internet"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="internet"
                                                    >
                                                        Internet Allowance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Government Mandated Benefits"
                                                        id="govertmentBenefits"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="benefigovertmentBenefitsts"
                                                    >
                                                        Government Mandated
                                                        Benefits
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Transportation Allowance"
                                                        id="transport"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="transport"
                                                    >
                                                        Transportation Allowance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Company Car"
                                                        id="companyCar"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="companyCar"
                                                    >
                                                        Company Car
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Performance Bonus"
                                                        id="performanceBonus"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="performanceBonus"
                                                    >
                                                        Performance Bonus
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="Paid Training"
                                                        id="paidTraining"
                                                        onChange={(e) => {
                                                            handleBenefitsCheck(
                                                                e
                                                            )
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        htmlFor="paidTraining"
                                                    >
                                                        Paid Training
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {(User?.subscription?.status == 'active' || User?.subscription?.status == 'trialing') && (
                                        <>
                                            <div className="mb-5">
                                                <label
                                                    htmlFor=""
                                                    className="mb-2"
                                                >
                                                    <strong>
                                                        Job Mail Box
                                                    </strong>
                                                    <small className="text-muted  mb-0 pb-0 d-block">
                                                        Send applicants directly
                                                        to your ATS Job Mailbox.
                                                    </small>
                                                    <small
                                                        className="text-muted"
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        Press Enter or Spacebar
                                                        to add email
                                                    </small>
                                                </label>
                                                <CreatableSelect
                                                    isOptionUnique={
                                                        isOptionUnique
                                                    }
                                                    components={{
                                                        DropdownIndicator: () =>
                                                            null,
                                                    }}
                                                    inputValue={inputValue}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    onChange={(newValue) =>
                                                        setValue(newValue)
                                                    }
                                                    onInputChange={(newValue) =>
                                                        setInputValue(newValue)
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                    placeholder="Press Enter or Spacebar to add email"
                                                    value={value}
                                                    className=""
                                                />
                                                {atsEmailError && (
                                                    <span className="error-helper">
                                                        Please enter valid email
                                                        address.
                                                    </span>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    <div className="mb-4">
                                        <div className="d-flex flex-column flex-md-row">
                                            <button
                                                className="btn btn-primary w-100 mb-2 mb-md-0 text-center"
                                                onClick={() => {
                                                    createJob('isPublish')
                                                }}
                                            >
                                                Create Job Post
                                            </button>
                                            <button
                                                className="btn btn-outline-primary w-100 mb-2 mb-md-0 ms-0 ms-md-2 text-center"
                                                onClick={() =>
                                                    createJob('isDraft')
                                                }
                                            >
                                                Save as Draft
                                            </button>
                                            {/* <button className="btn btn-outline-primary w-100 text-center">
                                            Preview Job Post
                                        </button> */}
                                        </div>
                                        {error && (
                                            <>
                                                <span className="text-danger small mt-1">
                                                    Saving failed. Please check
                                                    all the fields.
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <LargeBanner/>
                                    <div>
                                        <a
                                            className="text-info hand"
                                            onClick={() => {
                                                navigate('/jobs')
                                            }}
                                        >
                                            Discard this job post.
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={submitting} fullWidth maxWidth="md">
                    <Box style={{ padding: 100, textAlign: 'center' }}>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 20,
                            }}
                        >
                            <img src={jobPendingIcon} alt="icon" />
                        </Box>
                        <Box>
                            <CircularProgress />
                        </Box>
                        <Typography
                            variant="h2"
                            align="center"
                            color="primary"
                            style={{ fontWeight: 600, marginBottom: 30 }}
                        >
                            Your job post is being created
                        </Typography>
                        <Typography
                            variant="h4"
                            style={{ fontWeight: 400 }}
                            color="primary"
                        >
                            Hang in there! We're already processing your job
                            posting.
                        </Typography>
                    </Box>
                </Dialog>
                <Dialog
                    open={showJobStatus?.type === 'under_review'}
                    maxWidth="md"
                    fullWidth
                    style={{ zIndex: '999999999' }}
                >
                    <Box style={{ padding: 80, textAlign: 'center' }}>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 20,
                            }}
                        >
                            <img src={jobEditSuccess} alt="icon" width={200} />
                        </Box>

                        <Typography
                            variant="h3"
                            align="center"
                            color="primary"
                            style={{ fontWeight: 600, marginBottom: 30 }}
                        >
                            <span style={{ color: '#D7A048' }}> Woohoo!</span>
                            <br />
                            Your job posting is now under review.
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{ fontWeight: 400 }}
                            color="primary"
                            align="center"
                        >
                            It's officially in the books and our team of
                            eagle-eyed experts is giving it the once-over. Sit
                            tight, and we'll let you know as soon as it's live.
                        </Typography>

                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 50,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: 'none',
                                    minWidth: 327,
                                    fontWeight: 600,
                                }}
                                onClick={() => {
                                    showJobStatus?.action()
                                }}
                            >
                                Go to My Jobs
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
    }
}

export default connect(mapStateToProps, null)(CreateJob)
