import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { JobluIconsV2 } from '../../assets'
import NoMsgImg from '../../assets/img/noMsgImg.png'
import FlatList from '../../components/flatlist/FlatList'
import CustomChip from '../../components/reusables/Chip'
import DownloadProfileButton from '../../components/reusables/DownloadProfileButton'
import EmptyState from '../../components/reusables/EmptyState'
import Profile from '../../components/reusables/Profile/Profile'
import SelectJobPost from '../../components/reusables/SelectJobPost'
import { db } from '../../firebase'
import appActions from '../../redux/app/actions'
import userAction from '../../redux/user/actions'
import { userRequest } from '../../service/requests'
import { profilePlaceholder_Applicant } from '../../utils/constants'
import { convertFirebaseDate } from '../../utils/helpers'
import useWindowDimensions from '../../utils/hooks'
import { ProfileModal } from '../explore/v2/components/ViewProfile/ProfileModal'
import './styles.css'

const GroupedMessages = ({
    messages,
    scrollToMessage,
    loading,
    user,
    isLast,
    groupId,
    isToday,
}) => {
    return messages.map((msg, index) => {
        const elmID = `chat-${index}-${groupId}`
        const isMe = msg.user._id === user.userId

        if (messages?.length === index + 1) {
            scrollToMessage(elmID)
        }
        return (
            <>
                <div
                    className={`mt-3
                ${isMe ? 'justify-content-end' : ''}
                ${loading ? 'd-none' : 'd-flex'}
            `}
                    id={elmID}
                    key={elmID}
                >
                    <div
                        className={`convo ${loading ? '' : 'convo-expand'} ${
                            isMe ? 'sent-by-me' : 'not-me'
                        }`}
                    >
                        <p
                            className={`open-sans fs-12 m-0`}
                            style={{ wordBreak: 'break-all' }}
                        >
                            {msg.text}
                        </p>
                    </div>
                </div>
                <p className={`open-sans mt-0 ${isMe && 'text-end'}`}>
                    <span style={{ fontSize: 12 }}>
                        {/* {isToday && moment(msg.createdAt)} */}
                        {isToday && moment(msg.createdAt).format('LT')}
                    </span>
                </p>
            </>
        )
    })
}
export const Convo = (props) => {
    const {
        room,
        isChatBox,
        loading,
        setLoading,
        user,
        setShowInfo,
        showInfo,
        setSelectedRoom,
        isMobile,
    } = props
    const [message, setMessages] = useState([])
    const [applicantInfo, setApplicantInfo] = useState(null)
    const [inputText, setInputText] = useState('')

    const scrollToMessage = (id) => {
        // const cht = document.getElementById(id)
        // cht?.scrollIntoView()
        //setLoading(false)
    }

    useEffect(() => {
        if (room?.latestMessage.lastSender === 'applicant') {
            db.collection('rooms')
                .doc(room?.jobId)
                .collection('applicants')
                .doc(room?._id)
                .update({
                    latestMessage: {
                        createdAt: room?.latestMessage.createdAt,
                        text: room?.latestMessage.text,
                        isRead: true,
                    },
                })
        }

        userRequest.getApplicantDetails(room._id).then((response) => {
            const applicant = response.data.applicantProfiles
            setApplicantInfo(applicant)
            const messagesListener = db
                .collection('messages')
                .doc(room?.jobId)
                .collection('applicants')
                .doc(room?._id)
                .collection('threads')
                .orderBy('createdAt')
                .onSnapshot((querySnapshot) => {
                    const messages = querySnapshot.docs.map((doc) => {
                        const firebaseData = doc.data()

                        const data = {
                            _id: doc.id,
                            text: firebaseData.text,
                            createdAt: convertFirebaseDate(
                                firebaseData.createdAt
                            ),
                        }

                        if (!firebaseData.system) {
                            data.user = {
                                ...firebaseData.user,
                                avatar:
                                    firebaseData.user._id === user.userId
                                        ? user.profileUrl
                                        : applicant.profileUrl,
                            }
                        }

                        return data
                    })

                    const grouped = _.groupBy(messages, (obj) => {
                        return moment(obj.createdAt).format('ddd MMM DD, YYYY')
                    })

                    setMessages(grouped)
                })

            // Stop listening for updates whenever the component unmount
            return () => messagesListener()
        })
    }, [room])

    const handleSend = async () => {
        const text = inputText
        if (!text) {
            return
        }
        const senderDetails = {
            _id: user.userId,
            email: user.email,
        }

        senderDetails.name = room.employerName

        const messageDetails = {
            text,
            createdAt: new Date(),
            user: senderDetails,
        }

        await db
            .collection('messages')
            .doc(room.jobId)
            .collection('applicants')
            .doc(room._id)
            .collection('threads')
            .add(messageDetails)

        await db
            .collection('rooms')
            .doc(room.jobId)
            .collection('applicants')
            .doc(room._id)
            .set(
                {
                    latestMessage: {
                        text,
                        createdAt: new Date(),
                        isRead: false,
                        lastSender: 'employer',
                    },
                },
                { merge: true }
            )
        setSelectedRoom({
            ...room,
            latestMessage: {
                text,
                createdAt: new Date(),
                isRead: false,
                lastSender: 'employer',
            },
        })
        // console.log('test',chatRoomInfo)
        userRequest
            .sendNotification({
                title: room.employerName,
                content: text,
                receiverId: applicantInfo.userId,
                senderId: user.userId,
                type: 'message',
                data: room,
            })
            .then((response) => {
                // console.log(response.data)
            })
        setInputText('')
    }

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                handleSend()
            }
        }
        document.addEventListener('keyup', listener)
        return () => {
            document.removeEventListener('keyup', listener)
        }
    }, [handleSend])

    useEffect(() => {
        setLoading(false)
        setTimeout(() => {
            const objDiv = document.getElementById('convo-scroll')
            objDiv.scrollTop = objDiv.scrollHeight
        }, 1000)
    }, [])

    return (
        <div
            id="convoId"
            className="col border px-0 d-flex flex-column justify-content-around mx-0 position-relative"
        >
            {/* <EmptyState
                emptyImg={NoMsgImg}
                title={'See your conversations here.'}
            /> */}
            <div
                className={`d-flex p-1 align-items-center border-bottom mt-0 ${
                    isMobile && 'sticky-top bg-light'
                }`}
            >
                {isMobile && (
                    <p
                        className="me-2 h4"
                        onClick={() => setSelectedRoom(null)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                            />
                        </svg>
                    </p>
                )}
                <img
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 5,
                    }}
                    src={
                        room?.applicant_profileUrl ||
                        profilePlaceholder_Applicant
                    }
                    alt=""
                    className="m-auto"
                />
                <div className="w-100 px-3 d-flex">
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between w-100">
                            <p className="open-sans semi-bold-600 primary my-0">
                                {room?.applicantName}
                            </p>
                        </div>
                        <div>
                            <p
                                className="open-sans my-0"
                                style={{ fontSize: 12 }}
                            >
                                {room?.roomName}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="d-flex px-2">
                    <img
                        style={{
                            width: 25,
                            height: 25,
                            // borderRadius: 5,
                        }}
                        src={JobluIconsV2.toggleMenu}
                        alt=""
                        className="m-auto"
                        role="button"
                        onClick={() => setShowInfo(!showInfo)}
                    />
                </div>
                {/* <div className="py-1 px-3 border-bottom">
                <button className="btn p-0 fs-12">
                    Review Job Post
                </button>
            </div> */}
            </div>
            <div className="d-flex flex-column justify-content-end h-100">
                {/* CONVERSATION CONTAINER */}
                <div
                    className="flex-fill justify-content-end px-3 pb-4"
                    style={{
                        overflowY: 'scroll',
                        maxHeight: isChatBox ? 300 : 635,
                        paddingTop: 15,
                        paddingBottom: 15,
                    }}
                    id="convo-scroll"
                >
                    {/* <div style={{ height: '60vh' }}>
                <Loading show={loading} />
            </div> */}

                    {Object.keys(message).map((key, index) => {
                        const isLast = message.length - 1 === index
                        const groupId = key.trim()
                        const isToday =
                            moment(key).format('DD/MM/YYY') ===
                            moment().format('DD/MM/YYY')
                        return (
                            <div key={key} className="mt-5">
                                <p className="text-center">
                                    {isToday ? 'Today' : key}
                                </p>
                                <GroupedMessages
                                    messages={message[key]}
                                    scrollToMessage={scrollToMessage}
                                    loading={loading}
                                    user={user}
                                    isLast={isLast}
                                    groupId={groupId}
                                    isToday={isToday}
                                />
                            </div>
                        )
                    })}
                </div>
                {/* END CONVERSATION CONTAINER */}

                {/* BOTTOM ACTIONS */}
                <div
                    className={`border-top d-flex p-2 w-100 ${
                        isMobile && 'position-fixed bottom-0'
                    }`}
                >
                    <input
                        type="text"
                        className="form-control flex-fill open-sans"
                        id="inputPassword2"
                        placeholder="Type here..."
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                    />
                    <button
                        onClick={() => handleSend()}
                        className="btn btn-primary open-sans"
                    >
                        Send
                    </button>
                </div>
                {/* END BOTTOM ACTIONS */}
            </div>
        </div>
    )
}

const Messages = (props) => {
    const { User, setChatRooms, setShowNav } = props
    const location = useLocation()
    const { chatRooms } = User
    const [selectedRoom, setSelectedRoom] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [showInfo, setShowInfo] = useState(false)
    const [loading, setLoading] = useState(true)

    const [limitRoom, setLimitRoom] = useState(10)
    const [rooms, setRooms] = useState([])
    const [totalRooms, setTotalRooms] = useState(chatRooms?.length || 9999)

    const [searchRoom, setSearchRoom] = useState(null)
    const [selectedJob, setSelectedJob] = useState(null)

    const [showProfile, setShowProfile] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const { height, width } = useWindowDimensions()

    useEffect(() => {
        setRooms(chatRooms?.filter((item, index) => index < limitRoom))
    }, [chatRooms])

    useEffect(() => {
        let threadId = document.getElementById('threadId')
        let convoId = document.getElementById('convoId')

        if (isMobile) {
            if (showInfo) {
                convoId?.classList?.add('d-none')
                threadId?.classList?.add('d-none')
            } else {
                convoId?.classList?.remove('d-none')
                if (selectedRoom) {
                    threadId?.classList?.add('d-none')
                } else if (!selectedRoom) {
                    threadId?.classList?.remove('d-none')
                }
            }
        }
        // else {
        //     threadId?.classList?.remove('d-none')
        // }
    }, [selectedRoom, isMobile, selectedUser, showInfo])

    useEffect(() => {
        isMobile && !!selectedRoom ? setShowNav(false) : setShowNav(true)
    }, [isMobile, selectedRoom])

    useEffect(() => {
        if (width < 640) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            searchFilterFunction(searchRoom)
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }, [searchRoom])

    useEffect(() => {
        const data = location?.state?.data
        if (data) {
            const { applicantId, jobId } = data
            const chatRoom = _.find(chatRooms, {
                jobId,
                applicantId,
            })
            if (chatRoom) setSelectedRoom(chatRoom)
        }
    }, [location])

    const searchFilterFunction = () => {
        // Check if searched text is not blank
        if (searchRoom) {
            const newData = chatRooms.filter(function (item) {
                const jobTitleData = item?.roomName?.toUpperCase()
                const itemData = item.applicantName
                    ? item.applicantName.toUpperCase()
                    : ''.toUpperCase()
                const textData = searchRoom.toUpperCase()
                // console.log(itemData,textData)
                if (!selectedJob)
                    return (
                        itemData.indexOf(textData) > -1 ||
                        jobTitleData.indexOf(textData) > -1
                    )
                else
                    return (
                        itemData.indexOf(textData) > -1 &&
                        jobTitleData === selectedJob?.name.toUpperCase()
                    )
            })
            setTotalRooms(newData?.length)
            setRooms(newData?.filter((item, index) => index <= limitRoom))
        } else {
            // Inserted text is blank
            // Update FilteredDataSource with masterDataSource
            setLimitRoom(10)
            if (!selectedJob) {
                setTotalRooms(chatRooms?.length)
                setRooms(chatRooms?.filter((item, index) => index <= limitRoom))
            } else {
                const newData = chatRooms?.filter(
                    (item) =>
                        item?.roomName?.toUpperCase() ===
                        selectedJob?.name?.toUpperCase()
                )
                setTotalRooms(newData?.length)
                setRooms(newData?.filter((item, index) => index <= limitRoom))
            }
        }
    }

    const filterRoomByJob = (job) => {
        // Check if searched text is not blank
        const text = job?.name
        const value = job?.value
        setSelectedJob(job)
        if (text) {
            const newData = chatRooms.filter(function (item) {
                const jobTitleData = item?.roomName?.toUpperCase()
                const textData = text.toUpperCase()
                // console.log(itemData,textData)
                return jobTitleData === textData
            })
            setTotalRooms(newData?.length)
            setLimitRoom(10)
            setRooms(newData?.filter((item, index) => index <= limitRoom))
        } else {
            // Inserted text is blank
            // Update FilteredDataSource with masterDataSource
            setLimitRoom(10)
            setTotalRooms(chatRooms?.length || 9999)
            setRooms(chatRooms?.filter((item, index) => index <= limitRoom))
        }
    }

    const renderItem = (room, index) => {
        const isSelected = selectedRoom === room
        let storedDate
        let dateTimeAgo
        try {
            dateTimeAgo = moment(
                convertFirebaseDate(room?.latestMessage?.createdAt)
            ).fromNow()
        } catch (e) {
            console.log(e)
            console.log('error computing timestamp')
        }
        return (
            <div
                className={`d-flex mb-2 border-bottom pb-2 px-1 ${
                    room?.latestMessage.lastSender !== 'employer'
                        ? !room.latestMessage.isRead
                            ? 'bold'
                            : null
                        : null
                }`}
                key={index}
                role="button"
                style={{
                    background: isSelected ? '#f2f2f2' : '',
                }}
                onClick={() => setSelectedRoom(room)}
            >
                <div className="d-flex">
                    <img
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 5,
                        }}
                        src={
                            room?.applicant_profileUrl ||
                            profilePlaceholder_Applicant
                        }
                        alt=""
                        className="m-auto"
                    />
                </div>
                <div className="w-100 h-100 ps-1 py-1">
                    <div className="d-flex justify-content-between w-100 mt-1 my-0 pe-1">
                        <span
                            className="open-sans semi-bold-600 primary my-0"
                            style={{ fontSize: 14 }}
                        >
                            {room?.applicantName}
                        </span>
                        <span
                            className={`open-sans-body my-0 ${
                                room?.latestMessage.lastSender !== 'employer'
                                    ? !room.latestMessage.isRead
                                        ? 'semi-bold-600 text-black'
                                        : 'text-muted'
                                    : null
                            }`}
                            style={{ fontSize: 12 }}
                        >
                            {dateTimeAgo}
                        </span>
                    </div>
                    <div
                        className={`py-0 d-flex justify-content-between ${
                            room?.latestMessage.lastSender !== 'employer'
                                ? !room.latestMessage.isRead
                                    ? 'semi-bold-600'
                                    : null
                                : null
                        }`}
                    >
                        <span
                            className="open-sans my-0 py-0"
                            style={{ fontSize: 13 }}
                        >
                            {room?.roomName}
                        </span>
                        {room?.latestMessage.lastSender !== 'employer' ? (
                            !room.latestMessage.isRead ? (
                                <span
                                    className="bg-warning mt-2"
                                    style={{
                                        height: 10,
                                        width: 10,
                                        borderRadius: 100,
                                    }}
                                ></span>
                            ) : null
                        ) : null}
                    </div>
                    <div className="w-100">
                        <div
                            className={`open-sans my-0  ${
                                room?.latestMessage.lastSender !== 'employer'
                                    ? !room.latestMessage.isRead
                                        ? 'semi-bold-600 text-black'
                                        : 'text-muted'
                                    : null
                            }`}
                            style={{
                                fontSize: 10,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'wrap',
                            }}
                        >
                            {room?.latestMessage?.text.length > 50
                                ? `${room?.latestMessage?.text.substring(
                                      0,
                                      50
                                  )}...`
                                : room?.latestMessage?.text}
                        </div>
                        {/* {room?.latestMessage.lastSender !== 'employer' ? (
                            !room.latestMessage.isRead ? (
                                <span>UNREAD</span>
                            ) : null
                        ) : null} */}
                    </div>
                </div>
            </div>
        )
    }

    const getMoreRooms = () => {
        const newLimit = limitRoom + 10
        setLimitRoom(newLimit)
        if (!selectedJob) {
            setRooms(chatRooms?.filter((item, index) => index < newLimit))
        } else {
            if (searchRoom) searchFilterFunction()
            else {
                const text = selectedJob?.name
                const newData = chatRooms.filter(function (item) {
                    const jobTitleData = item?.roomName?.toUpperCase()
                    const textData = text.toUpperCase()
                    // console.log(itemData,textData)
                    return jobTitleData === textData
                })
                setTotalRooms(newData?.length)
                setRooms(newData?.filter((item, index) => index <= newLimit))
            }
        }
    }

    useEffect(() => {
        try {
            userRequest
                .getApplicantDetails(selectedRoom.applicantId)
                .then((res) => {
                    if (res) {
                        const applicant = res?.data?.applicantProfiles
                        console.log(applicant)
                        setSelectedUser(applicant)
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }, [selectedRoom])

    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    const handleViewProfile = () => {
        handleShowProfileModal()
        // console.log('view')
        // setShowProfile(!showProfile)
        //
    }
    return (
        <div className="row justify-content-center mx-0" id="message-container">
            {/* <Profile
                selectedProfile={selectedUser}
                showProfile={showProfile}
                setShowProfile={setShowProfile}
                setShowInfo={setShowInfo}
            /> */}
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
                match={{matchStatus: 'match', matchUser: selectedUser}}
                seeker={selectedUser}
            />

            <div
                className="col-12 col-sm-11 row mt-sm-3 mx-0 px-0 border"
                style={{ borderRadius: 5, minHeight: 750 }}
            >
                <div
                    id="threadId"
                    className="convo-container col-md-3 col-sm-12 border px-0 mx-0"
                >
                    <div className="px-2">
                        <div className="input-group my-2">
                            {/* <span className="input-group-text" id="basic-addon1">
                            @
                        </span> */}
                            <input
                                type="text"
                                className="form-control px-1 open-sans"
                                placeholder="Search message"
                                aria-label="location"
                                aria-describedby="basic-addon1"
                                onChange={(e) => setSearchRoom(e?.target.value)}
                            />
                        </div>
                        <div>
                            <SelectJobPost
                                onChange={(value, item) => {
                                    filterRoomByJob(item)
                                }}
                                value={selectedJob?.value}
                            />
                        </div>
                    </div>
                    <div className="w-100 mt-3">
                        <FlatList
                            renderItem={renderItem}
                            data={rooms || []}
                            list="message"
                            // loadMoreItems={() => setLimitRoom(limitRoom + 10)}
                            // hasMoreItems={() => false}
                        />
                        {rooms?.length < totalRooms && (
                            <div className="text-center">
                                <button
                                    className="btn btn-primary my-3 text-center"
                                    onClick={() => getMoreRooms()}
                                >
                                    {/* {loadingLikedCandidates && (
                                     <span
                                         className="spinner-border spinner-border-sm me-2"
                                         role="status"
                                         aria-hidden="true"
                                     ></span>
                                 )} */}
                                    Load More
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {chatRooms?.length === 0 && !isMobile && (
                    <div className="col">
                        <EmptyState
                            emptyImg={NoMsgImg}
                            title={'See your conversations here.'}
                            link={'/dashboard'}
                            linkType={'link'}
                            linkTitle={'Find candidates'}
                        />
                    </div>
                )}
                {!!chatRooms?.length && selectedRoom && (
                    <Convo
                        isMobile={isMobile}
                        room={selectedRoom}
                        loading={loading}
                        setLoading={setLoading}
                        user={User.user}
                        setShowInfo={setShowInfo}
                        showInfo={showInfo}
                        setSelectedRoom={setSelectedRoom}
                    />
                )}
                {!!chatRooms?.length && !selectedRoom && (
                    <div className="col">
                        <EmptyState
                            emptyImg={NoMsgImg}
                            title={'See your conversations here.'}
                            link={'/dashboard'}
                            linkType={'link'}
                            linkTitle={'Find candidates'}
                        />
                    </div>
                )}

                <div
                    className={`info-container col border-top border-bottom border-end ${
                        showInfo
                            ? 'contact-info-expanded'
                            : 'contact-info-shrinked'
                    }`}
                    style={{ maxWidth: !isMobile ? 300 : '100%' }}
                >
                    <div style={{ width: '100%' }}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                <img
                                    className={` my-auto contact-profile-img me-2 ${
                                        showInfo
                                            ? 'contact-profile-img-expand'
                                            : ''
                                    }`}
                                    src={
                                        selectedUser?.profileUrl ||
                                        profilePlaceholder_Applicant
                                    }
                                    alt=""
                                    role="button"
                                    onClick={() => setShowInfo(!showInfo)}
                                />
                                <div>
                                    <p className="open-sans mx-1">
                                        <strong>
                                            {selectedRoom?.applicantName}
                                        </strong>
                                    </p>
                                    <p className="open-sans mx-1">
                                        <strong>
                                            <small>
                                                {selectedUser?.preferredTitle}
                                            </small>
                                        </strong>
                                    </p>
                                    <p className="open-sans mx-1">
                                        <small>
                                            {selectedUser?.jobFunction}
                                        </small>
                                    </p>
                                </div>
                            </div>

                            <div>
                                <img
                                    style={{
                                        width: 19,
                                        height: 19,
                                        borderRadius: 5,
                                    }}
                                    src={JobluIconsV2.closeIcon}
                                    alt=""
                                    role="button"
                                    onClick={() => setShowInfo(!showInfo)}
                                />
                            </div>
                        </div>
                        <div className="my-1">
                            <button
                                className="text-center btn btn-outline-primary w-100 my-2"
                                onClick={() => {
                                    handleViewProfile()
                                }}
                            >
                                View Profile
                            </button>

                            {/* <DownloadProfileButton
                                userId={selectedRoom?.applicantId}
                                className="mt-2 mb-2 w-100"
                            /> */}

                            <hr />
                            <div className="py-1">
                                <h5 className="fw-bold">
                                    Additional Information
                                </h5>
                                <p className="open-sans-body mb-3">
                                    Age:{' '}
                                    {moment().diff(
                                        moment(selectedUser?.dob),
                                        'years'
                                    )}
                                </p>
                                <p className="open-sans-body mb-3">
                                    Country: {selectedUser?.currentLocation}
                                </p>
                                <p className="open-sans-body mb-1">About Me:</p>
                                <p className="open-sans-body mb-1">
                                    {selectedUser?.bio}
                                </p>

                                <p className="open-sans-body mt-3">Skills:</p>
                                <CustomChip data={selectedUser?.skills} />

                                <p className="open-sans-body mt-3">Values:</p>
                                <CustomChip
                                    data={selectedUser?.importantForYou}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
const mapDispatchToProps = {
    setChatRooms: userAction.setChatRooms,
    setShowNav: appActions.setShowMainNav,
}
export default connect(mapStateToProps, mapDispatchToProps)(Messages)
