import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import useGeoLocation from 'react-ipgeolocation'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import EmailIcon from '../../assets/img/emailIcon.svg'
import LocationIcon from '../../assets/img/locationIcon.svg'
import numberOfApplicants from '../../assets/img/numberOfApplicants.svg'
import passIcon from '../../assets/img/passIcon.svg'
import cancel_avatar from '../../assets/img/pricing-page/cancel_avatar.png'
import pricing_poster from '../../assets/img/pricing-page/pricing-poster-2.png'
import TelIcon from '../../assets/img/telIcon.svg'
import webIcon from '../../assets/joblu-2.0/img/new-icons/nationality.svg'
import userActions from '../../redux/user/actions'
import { paymentRequests } from '../../service/requests'
import { CustomVideoPlayer } from '../explore/components/VideoPlayer'
import CloseIcon from '../../assets/img/closeIcon.png'
import {
    premium_features,
    profilePlaceholder_Employer,
    STRIPE_BASE_URL,
    STRIPE_SECRET,
} from '../../utils/constants'
import { thousendSeparator, createURL } from '../../utils/helpers'
import './styles.css'
import ProfileBg from '../../assets/img/profile/profileBg.png'
import ValuesIcon from '../../assets/img/profile/valuesIcon.png'
import MissionIcon from '../../assets/img/profile/missionIcon.png'
import WorkSetupIcon from '../../assets/img/profile/workSetupIcon.png'
import MembersIcon from '../../assets/img/profile/membersIcon.png'
import VisionIcon from '../../assets/img/profile/visionIcon.png'
import profilePlayIcon from '../../assets/img/profile/profilePlayIcon.png'
import Carousel from 'react-grid-carousel'
import useWindowDimensions from '../../utils/hooks'
import JobluVideoPlayer from '../explore/components/VideoPlayer'
import VideoThumbnail from 'react-video-thumbnail'
import ProfileCover from '../../assets/img/profile/profileBg.png'
import EditIcon from '../../assets/img/my-jobs/EditIcon.png'
import { Box, Dialog } from '@material-ui/core'
import { app_styles } from '../../general.styles'

import VideoPlayer from 'simple-react-video-thumbnail'
import ReactPlayer from 'react-player'
function MyAccount(props) {
    const { User, subscription, getSubscription } = props

    const { width } = useWindowDimensions()
    const app_classes = app_styles()
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)
    const [amountPerMonth, setAmountPerMonth] = useState(0)
    const [totalMonths, setMonths] = useState(null)
    const [showCancel, setShowCancel] = useState(false)
    const [successModal, setShowSuccessModal] = useState(false)
    const location = useLocation()
    const features = _.chunk(premium_features, 6)
    const [monthly6Price, setMonthly6Price] = useState(0)
    const geolocation = useGeoLocation()
    let curr = 'php'
    if (geolocation === 'PH') curr = 'php'
    if (geolocation === 'SG') curr = 'sgd'
    if (geolocation === 'US') curr = 'usd'

    useEffect(() => {
        const intent = new URLSearchParams(location.search).get(
            'payment_intent'
        )

        if (intent) {
            setShowSuccessModal(true)
        }
    }, [location])

    useEffect(() => {
        if (successModal) {
            setTimeout(() => {
                setShowSuccessModal(false)
                getSubscription()
            }, 3000)

            navigate('/profile')
        }
    }, [successModal])

    useEffect(() => {
        if (!!subscription && Object.keys(subscription).length > 0) {
            axios
                .get(
                    `${STRIPE_BASE_URL}subscriptions/${subscription?.transactionId}`,
                    {
                        headers: {
                            Authorization: 'Bearer ' + STRIPE_SECRET, //the token is a variable which holds the token
                        },
                    }
                )
                .then((res) => {
                    setSubscriptionDetails(res?.data)
                    const { plan } = res.data
                    if (plan) {
                        const { interval, interval_count } = res.data?.plan
                        let months = interval_count
                        if (interval === 'year') {
                            months = interval_count * 12
                        }
                        setMonths(months)
                        let per_month = plan.amount / 100 / months
                        setAmountPerMonth(
                            thousendSeparator(per_month.toFixed())
                        )
                    }
                })
        }
    }, [subscription])

    const handleCancelSub = () => {
        const id = subscription.transactionId

        try {
            paymentRequests
                .cancelSubscription({
                    subscriptionId: id,
                })
                .then((res) => {
                    if (res) {
                        getSubscription()
                    }
                })
                .then(() => {
                    setShowCancel(false)
                    navigate('/subscription/cancelled')
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getSubscription()

        paymentRequests.getPlans().then((res) => {
            if (res.data?.monthly6) {
                axios
                    .get(`${STRIPE_BASE_URL}prices/${res.data?.monthly6}`, {
                        headers: {
                            Authorization: 'Bearer ' + STRIPE_SECRET, //the token is a variable which holds the token
                        },
                    })
                    .then((res) => {
                        if (res.data?.unit_amount) {
                            let monthly6 = res.data?.unit_amount
                            monthly6 = monthly6 / 100 / 6
                            monthly6 = monthly6.toFixed()
                            setMonthly6Price(monthly6)
                        }
                    })
            }
        })
    }, [])

    const navigate = useNavigate()

    const [companyCulture, setCompanyCulture] = useState([])

    useEffect(() => {
        let copy = []
        console.log(User?.user?.companyCulture)
        User?.user?.companyCulture?.map((item) => {
            console.log(item)
            if (!!item?.featured) {
                const isExternalVideo =
                    (item?.link &&
                        createURL(item?.link)?.hostname ===
                            'www.youtube.com') ||
                    (item?.link &&
                        createURL(item?.link)?.hostname === 'vimeo.com')

                const isVideo =
                    item?.link?.split('.')?.pop() === 'mp4' || isExternalVideo
                item.isVideo = isVideo
                copy.push(item)
            }
        })

        setCompanyCulture(copy)
    }, [User])

    const [forceRerender, setForceRerender] = useState(0)
    useEffect(() => {
        if (User) {
            setForceRerender(0)

            const timer = setTimeout(() => {
                setForceRerender(forceRerender + 1)
            }, 100)
            return () => clearTimeout(timer)
        }
    }, [User])

    const [currentVideoLink, setCurrentVideoLink] = useState(null)
    const [playVideo, setPlayVideo] = useState(null)
    const handlePlayVideo = () => {
        setPlayVideo(!playVideo)
    }
    const playVid = (link) => {
        setCurrentVideoLink(link)
        handlePlayVideo()
    }

    const [currentImg, setCurrentImg] = useState(null)
    const [imgOpen, setImgOpen] = useState(false)
    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={imgOpen}
                onClose={() => {
                    setImgOpen(false)
                }}
                style={{ zIndex: 9999999999 }}
            >
                <Box style={{ position: 'relative' }}>
                    <Box style={{ position: 'absolute', right: 10, top: 10 }}>
                        <img
                            src={CloseIcon}
                            width={42}
                            className={`${app_classes.cursor.pointer}`}
                            onClick={() => {
                                setImgOpen(false)
                            }}
                        />
                    </Box>
                    <img src={currentImg || null} style={{ width: '100%' }} />
                </Box>
            </Dialog>

            <div className="row justify-content-center gx-0">
                <div className="col-md-12">
                    <div className="card pb-5">
                        <div className="text-center">
                            <div
                                className="profile-banner"
                                style={{
                                    height: 300,
                                    backgroundImage: `url(${
                                        User?.user?.coverUrl || ProfileCover
                                    })`,
                                }}
                            ></div>
                            <img
                                className="rounded-circle mb-3"
                                style={{
                                    height: 200,
                                    width: 200,
                                    objectFit: 'cover',
                                    border: '10px solid white',
                                    marginTop: '-100px',

                                    backgroundColor: '#ffffff',
                                }}
                                src={
                                    User?.user?.profileUrl ||
                                    profilePlaceholder_Employer
                                }
                                alt=""
                            />
                            <h2 className="mb-3">{User?.user?.companyName}</h2>
                            <h6 className="text-muted mb-3">
                                {User?.user?.country}
                            </h6>
                            {User?.user?.tagline && (
                                <>
                                    <h6 className="text-muted mb-3">
                                        "{User?.user?.tagline}"
                                    </h6>
                                </>
                            )}
                            {/* <button
                                className="btn btn-primary text-white"
                                onClick={() => navigate('/settings')}
                            >
                                <img
                                    src={EditIcon}
                                    width={22}
                                    className="me-2 mb-1"
                                />
                                <span>Edit profile</span>
                            </button> */}
                        </div>
                        <div className="px-5 mt-5">
                            <div className="row mb-5">
                                <div
                                    className={`${
                                        User?.user?.vidFromHeart?.length > 0 &&
                                        User?.user?.vidFromHeart?.filter(
                                            (item) => item?.featured === true
                                        ).length > 0
                                            ? 'col-md-4 mt-4 col-sm-12 mb-3 mb-md-0'
                                            : 'd-none'
                                    }`}
                                >
                                    {User?.user?.vidFromHeart?.length > 0 &&
                                        User?.user?.vidFromHeart?.filter(
                                            (item) => item?.featured === true
                                        ).length > 0 && (
                                            <>
                                                <div>
                                                    {User?.user.vidFromHeart?.map(
                                                        (item) => {
                                                            if (item.featured) {
                                                                return (
                                                                    <>
                                                                        <div className="w-100 empProf position-relative">
                                                                            {item?.link && (
                                                                                <div>
                                                                                    <ReactPlayer
                                                                                        url={
                                                                                            item?.link
                                                                                        }
                                                                                        width={
                                                                                            '100%'
                                                                                        }
                                                                                        height={
                                                                                            '100%'
                                                                                        }
                                                                                    />
                                                                                    {/* <VideoPlayer
                                                                                        videoUrl={
                                                                                            item.link
                                                                                        }
                                                                                        snapshotAt={
                                                                                            4
                                                                                        }
                                                                                    /> */}
                                                                                    {/* <VideoThumbnail
                                                                                        videoUrl={
                                                                                            item.link
                                                                                        }
                                                                                    /> */}
                                                                                </div>
                                                                            )}
                                                                            <div
                                                                                className="position-absolute"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        '#0000004D',
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    right: 0,
                                                                                    bottom: 0,
                                                                                }}
                                                                            ></div>
                                                                            <img
                                                                                src={
                                                                                    profilePlayIcon
                                                                                }
                                                                                alt=""
                                                                                className="position-absolute hand"
                                                                                width={
                                                                                    70
                                                                                }
                                                                                onClick={() => {
                                                                                    playVid(
                                                                                        item.link
                                                                                    )
                                                                                }}
                                                                                style={{
                                                                                    left: '50%',
                                                                                    top: '50%',
                                                                                    transform:
                                                                                        'translate(-50%, -50%)',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            </>
                                        )}
                                </div>
                                <div
                                    className={`${
                                        User?.user?.vidFromHeart?.length > 0 &&
                                        User?.user?.vidFromHeart?.filter(
                                            (item) => item?.featured === true
                                        ).length > 0
                                            ? 'col-md-8 col-sm-12'
                                            : 'col-md-12'
                                    } my-4`}
                                >
                                    <h4 className="mb-2 text-primary">
                                        About Us
                                    </h4>
                                    <h6
                                        className=""
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {User?.user?.companyOverview || 'N/A'}
                                    </h6>
                                </div>
                            </div>
                            <div className="row mb-5 justify-content-center">
                                <div className="col-12 mb-3 mb-md-0 col-md-4 d-flex justify-content-center  flex-column flex-md-row align-items-center">
                                    <div className="me-0 me-md-3">
                                        <img
                                            src={MembersIcon}
                                            style={{ width: 40 }}
                                            alt=""
                                        />
                                    </div>
                                    <div className="text-center text-md-start">
                                        <h4>
                                            {User?.user?.companySize || 'N/A'}
                                        </h4>
                                        <h6>Team Members</h6>
                                    </div>
                                </div>
                                <div className="col-12 mb-3 mb-md-0 col-md-4 d-flex justify-content-center flex-column flex-md-row align-items-center">
                                    <div className="me-0 me-md-3">
                                        <img
                                            src={WorkSetupIcon}
                                            style={{ width: 40 }}
                                            alt=""
                                        />
                                    </div>
                                    <div className="text-center text-md-start">
                                        <h4>
                                            {User?.user?.workSetup || 'N/A'}
                                        </h4>
                                        <h6>Work Setup</h6>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 d-flex justify-content-center  flex-column flex-md-row align-items-center">
                                    <div className="me-0 me-md-3">
                                        <img
                                            src={MembersIcon}
                                            style={{ width: 40 }}
                                            alt=""
                                        />
                                    </div>
                                    <div className="text-center text-md-start">
                                        <h4>
                                            {User?.user?.yearEstablished ||
                                                'N/A'}
                                        </h4>
                                        <h6>Year Established</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5">
                                <div className="mb-5">
                                    <div className="mb-4 d-flex align-items-center">
                                        <div className="me-3">
                                            <img
                                                src={MissionIcon}
                                                className="profile-icon"
                                                width={40}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <p>Our</p>
                                            <h4 className="mb-0">Mission</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 style={{ whiteSpace: 'pre-wrap' }}>
                                            {User?.user?.companyMission ||
                                                'N/A'}
                                        </h6>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="mb-4 d-flex align-items-center">
                                        <div className="me-3">
                                            <img
                                                src={VisionIcon}
                                                className="profile-icon"
                                                width={40}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <p>Our</p>
                                            <h4 className="mb-0">Vision</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 style={{ whiteSpace: 'pre-wrap' }}>
                                            {User?.user?.companyVision || 'N/A'}
                                        </h6>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="mb-4 d-flex align-items-center">
                                        <div className="me-3">
                                            <img
                                                src={ValuesIcon}
                                                className="profile-icon"
                                                width={40}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <p>Our</p>
                                            <h4 className="mb-0">Values</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 style={{ whiteSpace: 'pre-wrap' }}>
                                            {User?.user?.companyValues || 'N/A'}
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            {(companyCulture?.length > 0 || User?.user?.cultureDescription) && (
                                <>
                                    <div className="mb-5">
                                        <h4 className="text-primary mb-5">
                                            Our Company Culture
                                        </h4>
                                        <div className='mb-5'>
                                            <h6
                                                style={{
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                            >
                                                {User?.user?.cultureDescription }
                                            </h6>
                                        </div>

                                        <div className="row">
                                            {companyCulture?.map(
                                                (item, index) => {
                                                    return (
                                                        <div className="col-12 col-md-4 mb-3">
                                                            {companyCulture[
                                                                index
                                                            ]?.isVideo ? (
                                                                <>
                                                                    <div className="w-100 compShowcase position-relative h-100">
                                                                        {companyCulture[
                                                                            index
                                                                        ]
                                                                            ?.link && (
                                                                            <div className="h-100">
                                                                                <div className="position-relative w-100 h-100">
                                                                                    <ReactPlayer
                                                                                        url={
                                                                                            companyCulture[
                                                                                                index
                                                                                            ]
                                                                                                ?.link
                                                                                        }
                                                                                        width={
                                                                                            '100%'
                                                                                        }
                                                                                        height={
                                                                                            '100%'
                                                                                        }
                                                                                    />

                                                                                    {/* <div
                                                                                    className="position-absolute d-flex"
                                                                                    style={{
                                                                                        top: 0,
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            videoPlaybackIcon
                                                                                        }
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: 50,
                                                                                            height: 50,
                                                                                            zIndex: 100,
                                                                                        }}
                                                                                        className="m-auto"
                                                                                    />
                                                                                </div> */}
                                                                                </div>
                                                                                {/* <VideoPlayer videoUrl={ companyCulture[
                                                                                            index
                                                                                        ]
                                                                                            ?.link} snapshotAt={4} /> */}
                                                                                {/* <VideoThumbnail
                                                                                    videoUrl={
                                                                                        companyCulture[
                                                                                            index
                                                                                        ]
                                                                                            ?.link
                                                                                    }
                                                                                /> */}
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            className="position-absolute"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#0000004D',
                                                                                top: 0,
                                                                                left: 0,
                                                                                right: 0,
                                                                                bottom: 0,
                                                                            }}
                                                                        ></div>
                                                                        <img
                                                                            src={
                                                                                profilePlayIcon
                                                                            }
                                                                            alt=""
                                                                            className="position-absolute hand"
                                                                            width={
                                                                                70
                                                                            }
                                                                            onClick={() => {
                                                                                playVid(
                                                                                    companyCulture[
                                                                                        index
                                                                                    ]
                                                                                        ?.link
                                                                                )
                                                                            }}
                                                                            style={{
                                                                                left: '50%',
                                                                                top: '50%',
                                                                                transform:
                                                                                    'translate(-50%, -50%)',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        companyCulture[
                                                                            index
                                                                        ]?.link
                                                                    }
                                                                    className="img-fluid rounded-3 h-100 hand"
                                                                    onClick={() => {
                                                                        setCurrentImg(
                                                                            companyCulture[
                                                                                index
                                                                            ]
                                                                                ?.link
                                                                        )
                                                                        setImgOpen(
                                                                            true
                                                                        )
                                                                    }}
                                                                    style={{
                                                                        objectFit:
                                                                            'cover',
                                                                        backgroundPosition:
                                                                            'center center',
                                                                    }}
                                                                    alt=""
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {User?.user?.employeeReview?.length > 0 &&
                            User?.user?.employeeReview?.filter(
                                (e) => e.featured === true
                            ).length > 0 ? (
                                <>
                                    <div className="mt-5">
                                        <h5 className="text-primary">
                                            What it's like
                                        </h5>
                                        <h1 className="mb-5">
                                            Working with us
                                        </h1>

                                        <Carousel
                                            cols={3}
                                            rows={1}
                                            gap={10}
                                            loop
                                        >
                                            {User?.user?.employeeReview?.map(
                                                (review) => {
                                                    if (!!review.featured) {
                                                        return (
                                                            <Carousel.Item>
                                                                {!!review.featured && (
                                                                    <>
                                                                        <div
                                                                            className="card h-100"
                                                                            style={{
                                                                                borderRadius: 15,
                                                                            }}
                                                                        >
                                                                            <div className="card-body d-flex justify-content-between flex-column p-5">
                                                                                <div>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize:
                                                                                                width <
                                                                                                640
                                                                                                    ? 14
                                                                                                    : 20,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            review.review
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className="text-end"
                                                                                        style={{
                                                                                            color: '#002E5D',
                                                                                            fontSize: 20,
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            {
                                                                                                review.name
                                                                                            }
                                                                                        </strong>
                                                                                    </p>
                                                                                    <p
                                                                                        className="text-end text-info"
                                                                                        style={{
                                                                                            color: '#009CDE',
                                                                                            fontSize: 18,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            review.jobTitle
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Carousel.Item>
                                                        )
                                                    }
                                                }
                                            )}
                                        </Carousel>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row  gx-0 justify-content-center pb-5 d-none"
                style={{ backgroundColor: '#F6F9FB' }}
            >
                <div
                    className="col-12 profile-banner"
                    style={{
                        height: 300,
                        backgroundImage: `url(${
                            User?.user?.coverUrl || ProfileCover
                        })`,
                    }}
                ></div>
                <div
                    className="col-12 text-center bg-primary-blue"
                    style={{ height: 840 }}
                >
                    <img
                        className="rounded-circle mb-3"
                        style={{
                            height: 200,
                            width: 200,
                            objectFit: 'cover',
                            border: '10px solid white',
                            marginTop: '-100px',
                        }}
                        src={
                            User?.user?.profileUrl ||
                            profilePlaceholder_Employer
                        }
                        alt=""
                    />
                    <h1 className="text-white mb-4">{User.user.companyName}</h1>
                    <h6 className="text-white mb-5">{User?.user?.country}</h6>
                    {User?.user?.tagline && (
                        <>
                            <h4 className="text-white mb-5">
                                "{User?.user?.tagline}"
                            </h4>
                        </>
                    )}
                    <button
                        className="btn btn-outline-primary text-white"
                        style={{ borderColor: '#fff' }}
                        // onClick={() => navigate('/edit-profile')}
                        onClick={() => navigate('/settings')}
                    >
                        Edit profile
                    </button>
                </div>
                <div className="col-10" style={{ marginTop: '-300px' }}>
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="row align-items-center">
                                <div
                                    className={`${
                                        User?.user?.vidFromHeart?.length > 0 &&
                                        User?.user?.vidFromHeart?.filter(
                                            (item) => item?.featured === true
                                        ).length > 0
                                            ? 'col-md-5 col-sm-12 mb-3 mb-md-0'
                                            : 'd-none'
                                    }`}
                                >
                                    {User?.user?.vidFromHeart?.length > 0 &&
                                        User?.user?.vidFromHeart?.filter(
                                            (item) => item?.featured === true
                                        ).length > 0 && (
                                            <>
                                                <div>
                                                    {User?.user.vidFromHeart?.map(
                                                        (item) => {
                                                            if (item.featured) {
                                                                return (
                                                                    <>
                                                                        <div className="w-100 empProf position-relative">
                                                                            {item?.link && (
                                                                                <div>
                                                                                    <ReactPlayer
                                                                                        url={
                                                                                            item?.link
                                                                                        }
                                                                                        width={
                                                                                            '100%'
                                                                                        }
                                                                                        height={
                                                                                            '100%'
                                                                                        }
                                                                                    />
                                                                                    {/* <VideoPlayer videoUrl={ item.link} snapshotAt={4} /> */}
                                                                                    {/* <VideoThumbnail
                                                                                        videoUrl={
                                                                                            item.link
                                                                                        }
                                                                                    /> */}
                                                                                </div>
                                                                            )}
                                                                            <div
                                                                                className="position-absolute"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        '#0000004D',
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    right: 0,
                                                                                    bottom: 0,
                                                                                }}
                                                                            ></div>
                                                                            <img
                                                                                src={
                                                                                    profilePlayIcon
                                                                                }
                                                                                alt=""
                                                                                className="position-absolute hand"
                                                                                width={
                                                                                    70
                                                                                }
                                                                                onClick={() => {
                                                                                    playVid(
                                                                                        item.link
                                                                                    )
                                                                                }}
                                                                                style={{
                                                                                    left: '50%',
                                                                                    top: '50%',
                                                                                    transform:
                                                                                        'translate(-50%, -50%)',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            </>
                                        )}
                                </div>
                                <div
                                    className={`${
                                        User?.user?.vidFromHeart?.length > 0 &&
                                        User?.user?.vidFromHeart?.filter(
                                            (item) => item?.featured === true
                                        ).length > 0
                                            ? 'col-md-7 col-sm-12'
                                            : 'col-md-12'
                                    }`}
                                >
                                    <h5 className="text-primary">Know more</h5>
                                    <h1 className="mb-5">About Us</h1>
                                    <h4 className="mb-5">
                                        {User?.user?.companyOverview || 'N/A'}
                                    </h4>
                                </div>
                            </div>
                            {(User?.user?.companySize ||
                                User?.user?.workSetup ||
                                User?.user?.yearEstablished) && (
                                <hr className="mb-4" />
                            )}
                            <div className="row mt-5 justify-content-center">
                                <div className="col-12 mb-3 mb-md-0 col-md-4 d-flex justify-content-center  flex-column flex-md-row align-items-center">
                                    <div className="me-0 me-md-3">
                                        <img
                                            src={MembersIcon}
                                            style={{ width: 80 }}
                                            alt=""
                                        />
                                    </div>
                                    <div className="text-center text-md-start">
                                        <h5>Team Members</h5>
                                        <h2
                                            onClick={() => {
                                                console.log(User?.user)
                                            }}
                                        >
                                            {User?.user?.companySize || 'N/A'}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-12 mb-3 mb-md-0 col-md-4 d-flex justify-content-center flex-column flex-md-row align-items-center">
                                    <div className="me-0 me-md-3">
                                        <img
                                            src={WorkSetupIcon}
                                            style={{ width: 80 }}
                                            alt=""
                                        />
                                    </div>
                                    <div className="text-center text-md-start">
                                        <h5>Work Setup</h5>
                                        <h2>
                                            {User?.user?.workSetup || 'N/A'}
                                        </h2>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 d-flex justify-content-center  flex-column flex-md-row align-items-center">
                                    <div className="me-0 me-md-3">
                                        <img
                                            src={MembersIcon}
                                            style={{ width: 80 }}
                                            alt=""
                                        />
                                    </div>
                                    <div className="text-center text-md-start">
                                        <h5>Year Established</h5>
                                        <h2>
                                            {User?.user?.yearEstablished ||
                                                'N/A'}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10  mt-5 mb-5">
                    <h5 className="text-primary">Our Company's</h5>
                    <h1 className="mb-5">Work Culture</h1>
                    <div className="d-flex mb-5 ">
                        <div className="me-4">
                            {' '}
                            <img
                                src={MissionIcon}
                                className="profile-icon"
                                width={100}
                                alt=""
                            />
                        </div>
                        <div>
                            <h5 className="text-primary mb-0">Our Company's</h5>
                            <h3 className="mb-3">
                                <strong>Mission</strong>
                            </h3>
                            <h6>{User?.user?.companyMission || 'N/A'}</h6>
                        </div>
                    </div>
                    <div className="d-flex mb-5 ">
                        <div className="me-4">
                            <img
                                src={VisionIcon}
                                className="profile-icon"
                                width={100}
                                alt=""
                            />
                        </div>
                        <div>
                            <h5 className="text-primary mb-0">Our</h5>
                            <h3 className="mb-3">
                                <strong>Vision</strong>
                            </h3>
                            <h6>{User?.user?.companyVision || 'N/A'}</h6>
                        </div>
                    </div>
                    <div className="d-flex mb-5">
                        <div className="me-4">
                            {' '}
                            <img
                                src={ValuesIcon}
                                className="profile-icon"
                                width={100}
                                alt=""
                            />
                        </div>
                        <div className="d">
                            <h5 className="text-primary mb-0">Our</h5>
                            <h3 className="mb-3">
                                <strong>Values</strong>
                            </h3>
                            <h6>{User?.user?.companyValues || 'N/A'}</h6>
                        </div>
                    </div>
                </div>
                {companyCulture?.length > 0 && (
                    <>
                        <div className="col-10 mt- mb-5">
                            <div className="row">
                                <div
                                    className="col-6 col-md-5 d-none d-md-block"
                                    style={{ minHeight: 200 }}
                                >
                                    {companyCulture?.map((item, index) => {
                                        return (
                                            <div className="col-4 col-md-4 mb-3">
                                                {companyCulture[index]
                                                    ?.isVideo ? (
                                                    <>
                                                        <div className="w-100 compShowcase position-relative h-100">
                                                            {companyCulture[
                                                                index
                                                            ]?.link && (
                                                                <div className="h-100">
                                                                    <VideoPlayer
                                                                        videoUrl={
                                                                            companyCulture[
                                                                                index
                                                                            ]
                                                                                ?.link
                                                                        }
                                                                        snapshotAt={
                                                                            4
                                                                        }
                                                                    />
                                                                    {/* <VideoThumbnail
                                                                        videoUrl={
                                                                            companyCulture[
                                                                                index
                                                                            ]
                                                                                ?.link
                                                                        }
                                                                    /> */}
                                                                </div>
                                                            )}
                                                            <div
                                                                className="position-absolute"
                                                                style={{
                                                                    backgroundColor:
                                                                        '#0000004D',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                }}
                                                            ></div>
                                                            <img
                                                                src={
                                                                    profilePlayIcon
                                                                }
                                                                alt=""
                                                                className="position-absolute hand"
                                                                width={70}
                                                                onClick={() => {
                                                                    playVid(
                                                                        companyCulture[
                                                                            index
                                                                        ]?.link
                                                                    )
                                                                }}
                                                                style={{
                                                                    left: '50%',
                                                                    top: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <img
                                                        src={
                                                            companyCulture[
                                                                index
                                                            ]?.link
                                                        }
                                                        className="img-fluid rounded-3 h-100 hand"
                                                        onClick={() => {
                                                            setCurrentImg(
                                                                companyCulture[
                                                                    index
                                                                ]?.link
                                                            )
                                                            setImgOpen(true)
                                                        }}
                                                        style={{
                                                            objectFit: 'cover',
                                                            backgroundPosition:
                                                                'center center',
                                                        }}
                                                        alt=""
                                                    />
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {User?.user?.employeeReview?.length > 0 &&
                User?.user?.employeeReview?.filter((e) => e.featured === true)
                    .length > 0 ? (
                    <>
                        <div className="col-10  mt-5">
                            <h5 className="text-primary">What it's like</h5>
                            <h1 className="mb-5">Working with us</h1>

                            <Carousel cols={3} rows={1} gap={10} loop>
                                {User?.user?.employeeReview?.map((review) => {
                                    if (!!review.featured) {
                                        return (
                                            <Carousel.Item>
                                                {!!review.featured && (
                                                    <>
                                                        <div
                                                            className="card h-100"
                                                            style={{
                                                                borderRadius: 15,
                                                            }}
                                                        >
                                                            <div className="card-body d-flex justify-content-between flex-column p-5">
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                width <
                                                                                640
                                                                                    ? 14
                                                                                    : 20,
                                                                        }}
                                                                    >
                                                                        {
                                                                            review.review
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        className="text-end"
                                                                        style={{
                                                                            color: '#002E5D',
                                                                            fontSize: 20,
                                                                        }}
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                review.name
                                                                            }
                                                                        </strong>
                                                                    </p>
                                                                    <p
                                                                        className="text-end text-info"
                                                                        style={{
                                                                            color: '#009CDE',
                                                                            fontSize: 18,
                                                                        }}
                                                                    >
                                                                        {
                                                                            review.jobTitle
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </Carousel.Item>
                                        )
                                    }
                                })}
                            </Carousel>
                        </div>
                    </>
                ) : (
                    <></>
                )}

                <div className="col-12 col-md-10 row mt-5 d-none">
                    <div className="col-12 col-md-8 offset-md-2">
                        <h6>Profile</h6>
                    </div>
                    <div className="col-12 col-md-8 offset-md-2  p-0">
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 d-flex">
                                        <div>
                                            <img
                                                src={
                                                    User?.user?.profileUrl ||
                                                    profilePlaceholder_Employer
                                                }
                                                width="90"
                                                height="90"
                                                alt=""
                                                className="me-3 img-fit"
                                            />
                                        </div>
                                        <div>
                                            <h5>{User.user.companyName}</h5>
                                            <p>
                                                {' '}
                                                {User.user.firstName}{' '}
                                                {User.user.lastName}
                                            </p>
                                            {User?.user?.email && (
                                                <p className="text-muted">
                                                    <small>
                                                        {' '}
                                                        {User?.user?.email}
                                                    </small>
                                                </p>
                                            )}
                                            {User?.user?.address && (
                                                <p className="text-muted">
                                                    <small>
                                                        {' '}
                                                        {User?.user?.address}
                                                    </small>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 text-end">
                                        <button
                                            className="btn btn-primary mt-3 mt-md-0"
                                            onClick={() =>
                                                navigate('/settings')
                                            }
                                        >
                                            Edit profile
                                        </button>
                                    </div>
                                    <div className="col-md-12 col-sm-12 mt-3">
                                        <div className="d-flex flex-column flex-md-row">
                                            <div className=" d-flex p-0 me-3 mb-3 mb-md-0">
                                                <img
                                                    style={{
                                                        height: 24,
                                                        marginRight: 5,
                                                    }}
                                                    alt=""
                                                    src={LocationIcon}
                                                    className="my-auto"
                                                />
                                                <p className="m-0 my-auto open-sans-body">
                                                    {User?.user?.country}
                                                </p>
                                            </div>
                                            {User?.user?.phoneNo && (
                                                <div className=" d-flex p-0  me-3 mb-3 mb-md-0">
                                                    <img
                                                        style={{
                                                            height: 24,
                                                            marginRight: 5,
                                                        }}
                                                        alt=""
                                                        src={TelIcon}
                                                        className="my-auto"
                                                    />
                                                    <p className="m-0 my-auto open-sans-body">
                                                        {User?.user?.phoneNo}
                                                    </p>
                                                </div>
                                            )}
                                            {User?.user?.email && (
                                                <div className=" d-flex p-0  mb-3 mb-md-0">
                                                    <img
                                                        style={{
                                                            height: 24,
                                                            marginRight: 5,
                                                        }}
                                                        alt=""
                                                        src={EmailIcon}
                                                        className="my-auto"
                                                    />
                                                    <p className="m-0 my-auto open-sans-body me-2">
                                                        {User?.user?.email}
                                                    </p>
                                                </div>
                                            )}
                                            {User?.user?.companyWebsite && (
                                                <div className=" d-flex p-0 mb-3 mb-md-0">
                                                    <img
                                                        style={{
                                                            height: 24,
                                                            marginRight: 5,
                                                        }}
                                                        alt=""
                                                        src={webIcon}
                                                        className="my-auto"
                                                    />
                                                    <p className="m-0 my-auto open-sans-body">
                                                        <a
                                                            href={`${User?.user?.companyWebsite}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {
                                                                User?.user
                                                                    ?.companyWebsite
                                                            }
                                                        </a>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h5>Company Profile</h5>
                                {User.user.companyOverview && (
                                    <>
                                        <div className="mb-3">
                                            <p>
                                                <strong>
                                                    About the company
                                                </strong>
                                            </p>
                                            <p
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {User.user.companyOverview}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {User?.user?.companyMission && (
                                    <>
                                        <div className="mb-3">
                                            <p>
                                                <strong>Company values</strong>
                                            </p>
                                            <p
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {User.user.companyMission}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {User.user.productsAndServices && (
                                    <>
                                        <div className="mb-3">
                                            <p>
                                                <strong>
                                                    Products & Services
                                                </strong>
                                            </p>
                                            <p
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {User.user.productsAndServices}
                                            </p>
                                        </div>
                                    </>
                                )}
                                <div className="row">
                                    {User.user.companyRegistrationNo && (
                                        <div className="col-md-4">
                                            <div className=" d-flex">
                                                <img
                                                    src={passIcon}
                                                    alt=" "
                                                    style={{
                                                        width: 19,
                                                        height: 19,
                                                    }}
                                                    className="my-auto"
                                                />
                                                <div className="d-flex flex-column  mx-2 my-auto">
                                                    <p className="open-sans-body m-0">
                                                        Registration No.
                                                    </p>
                                                    <p className="open-sans-body m-0 fw-bold">
                                                        {
                                                            User.user
                                                                .companyRegistrationNo
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-md-4">
                                        <div className="d-flex">
                                            <img
                                                src={numberOfApplicants}
                                                alt=" "
                                                style={{
                                                    width: 19,
                                                    height: 19,
                                                }}
                                                className="my-auto"
                                            />
                                            <div className="d-flex flex-column  mx-2 my-auto">
                                                <p className="open-sans-body m-0">
                                                    Company Size
                                                </p>
                                                <p className="open-sans-body m-0 fw-bold">
                                                    {User?.user?.companySize
                                                        ? User?.user
                                                              ?.companySize
                                                        : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!!subscription &&
                        typeof subscription === 'object' &&
                        Object.keys(subscription).length === 0 ? (
                            <div className="card mt-3 rounded d-none">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-2">
                                            <img
                                                src={pricing_poster}
                                                alt=""
                                                style={{ width: 182 }}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <h5>Joblu Premium</h5>
                                            <div className="mt-3">
                                                <strong>
                                                    Pay for success.
                                                </strong>
                                                <p>
                                                    Save thousands in
                                                    recruitment cost and source
                                                    the right candidate the
                                                    fast, fun, and fabulous way!{' '}
                                                </p>
                                                <strong>
                                                    {curr.toUpperCase()}{' '}
                                                    {thousendSeparator(
                                                        monthly6Price
                                                    )}
                                                    /mo for 6 months billed
                                                    monthly (Save 50%)
                                                </strong>
                                            </div>
                                            <button
                                                className="btn btn-primary mt-3"
                                                onClick={() => {
                                                    navigate('/pricing')
                                                }}
                                            >
                                                View Plans
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="card mt-3 rounded d-none">
                                <div className="card-body pb-4">
                                    <h5 className="text-primary mb-3">
                                        Joblu Premium
                                    </h5>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            {/* <h5 className="">
                                            {' '}
                                            {subscriptionDetails?.plan?.currency.toUpperCase()}
                                            {amountPerMonth}/mo
                                        </h5>{' '} */}
                                            <p className="mt-0">
                                                {subscription?.type
                                                    ? subscription?.type ===
                                                      'monthly'
                                                        ? 'Monthly Plan'
                                                        : subscription?.type ===
                                                          'monthly6'
                                                        ? '6 Months Plan'
                                                        : subscription?.type ===
                                                          'monthly12'
                                                        ? 'Yearly Plan'
                                                        : null
                                                    : null}
                                            </p>
                                            <p>
                                                Next Billing:
                                                {moment(
                                                    subscription?.expiryDate
                                                ).format(' MMM DD, YYYY ')}
                                            </p>
                                            <br />
                                            <button
                                                className="btn btn-outline-primary open-sans"
                                                onClick={() => {
                                                    setShowCancel(true)
                                                }}
                                            >
                                                Cancel Subscription
                                            </button>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <strong>Premium Features:</strong>

                                            <ul className="open-sans">
                                                {features[0].map((feat) => (
                                                    <li>{feat}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <ul className="mt-4 open-sans">
                                                {features[1].map((feat) => (
                                                    <li>{feat}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="bg-light d-none">
                            <div className="">
                                <div className="p-3">
                                    <div className="d-flex">
                                        <img
                                            className=""
                                            src={
                                                User?.user?.profileUrl ||
                                                profilePlaceholder_Employer
                                            }
                                            style={{ width: 90, height: 90 }}
                                            alt=""
                                        />

                                        <div className=" py-2 w-100">
                                            <div className="w-100 d-flex justify-content-between px-2">
                                                <div className="">
                                                    <h5 className="poppins mb-0">
                                                        {User.user.companyName}
                                                    </h5>
                                                    <p className="m-0 open-sans fw-bold">
                                                        {User.user.firstName}{' '}
                                                        {User.user.lastName}
                                                    </p>
                                                    <p className="m-0 open-sans-body text-secondary">
                                                        {User?.user?.email}
                                                    </p>
                                                    <p className="m-0 open-sans-body text-secondary">
                                                        {User?.user?.address}
                                                    </p>
                                                </div>
                                                <div className="">
                                                    <button
                                                        className="btn btn-primary me-2 mt-0"
                                                        onClick={() =>
                                                            navigate(
                                                                '/settings'
                                                            )
                                                        }
                                                    >
                                                        Edit Profile
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className=" d-flex p-0">
                                            <img
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                    marginRight: 5,
                                                }}
                                                alt=""
                                                src={LocationIcon}
                                                className="my-auto"
                                            />
                                            <p className="m-0 my-auto open-sans-body">
                                                {User.user.country}
                                            </p>
                                        </div>
                                        <div className=" d-flex p-0 mx-2">
                                            <img
                                                style={{
                                                    width: 24,
                                                    height: 22,
                                                    marginRight: 5,
                                                }}
                                                alt=""
                                                src={TelIcon}
                                                className="my-auto"
                                            />
                                            <p className="m-0 my-auto open-sans-body">
                                                {User.user.phoneNo}
                                            </p>
                                        </div>
                                        <div className=" d-flex p-0 ">
                                            <img
                                                style={{
                                                    width: 24,
                                                    marginRight: 5,
                                                }}
                                                alt=""
                                                src={EmailIcon}
                                                className="my-auto"
                                            />
                                            <p className="m-0 my-auto open-sans-body me-2">
                                                {User.user.email}
                                            </p>
                                        </div>
                                        {User?.user?.companyWebsite && (
                                            <div className=" d-flex p-0 mx-2">
                                                <img
                                                    style={{
                                                        width: 24,
                                                        marginRight: 5,
                                                    }}
                                                    alt=""
                                                    src={webIcon}
                                                    className="my-auto"
                                                />
                                                <p className="m-0 my-auto open-sans-body">
                                                    {User.user.companyWebsite}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={showCancel} size="sm">
                    <div
                        className="card rounded p-3"
                        style={{ width: '339px' }}
                    >
                        <div className="d-flex">
                            <img
                                src={cancel_avatar}
                                alt=""
                                style={{ width: '183.21px', height: '240px' }}
                                className="mx-auto"
                            />
                        </div>
                        <h5 className="text-center fw-bold mt-2">
                            Are you sure you want to <br />
                            cancel your Joblu Premium <br />
                            Subscription?
                        </h5>
                        <p className="text-center">
                            You can still use Joblu but with very limited
                            features.
                        </p>
                        <button
                            className="btn btn-primary open-sans mt-4"
                            onClick={() => {
                                handleCancelSub()
                            }}
                        >
                            Yes, cancel my subscription
                        </button>
                        <button
                            className="btn btn-outline-primary text-center open-sans mt-2"
                            onClick={() => {
                                setShowCancel(false)
                            }}
                        >
                            Keep my subscription
                        </button>
                    </div>
                </Modal>
                <Modal
                    size="sm"
                    centered
                    show={successModal}
                    onHide={() => {
                        setShowSuccessModal(false)
                    }}
                >
                    <div className="card p-5">
                        <div className="d-flex">
                            <div className="m-auto">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="60"
                                    height="60"
                                    fill="green"
                                    class="bi bi-cart-check"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                            </div>
                        </div>
                        <h6 className="text-center text-primary mt-3">
                            Payment Success <br />
                            Thank You! <br />
                        </h6>
                        {/* <p>
                        You can now use <br />
                        your Premium account
                    </p> */}
                    </div>
                </Modal>
            </div>
            <JobluVideoPlayer
                style={{ zIndex: 1 }}
                src={currentVideoLink}
                play={playVideo}
                setPlayVideo={setPlayVideo}
                vidHeight={700}
                culture={true}
                mWidth={width}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    return { User: state.User, subscription: state.User.subscription }
}
const mapDispatchToProps = {
    getSubscription: userActions.getActiveSubscription,
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
