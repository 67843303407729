import { userRequest } from '../service/requests'

export const makeUploadRequest = (file, fileType) => {
    const data = new FormData()
    data.append('document', file, fileType)
    try {
        return userRequest
            .uploadFile(data)
            .then((response) => {
                console.log(response.data)
                return response.data.documents
            })
            .catch((err) => {
                console.log(err)
            })
    } catch (e) {
        console.log('error when uploading document', e.response)
    }
}
export const makeUploadCompanyCultureFile = (file, fileType, type) => {
    //type param =  "photo" | "video" to be appended to api endpoint
    const data = new FormData()
    data.append('document', file, fileType)
    try {
        return userRequest
            .companyCultureUploadFile(data, type)
            .then((response) => {
                console.log(response.data)
                return response.data.documents
            })
            .catch((err) => {
                console.log(err)
            })
    } catch (e) {
        console.log('error when uploading document', e.response)
    }
}
