import { useEffect, useState } from 'react'
import SelectSearch from 'react-select-search'
import { configRequest } from '../../service/requests'
import { myFuzzySearch } from './SelectJobPost'

function SelectJobFunction(props) {
    const { 
        onChange,
        value,
        defaultValue,
        name,
        placeholder,
        noAll
    } = props
    
    const [jobFunctions, setJobFunctions] = useState([])
    const [displayOptions, setDisplayOptions] = useState(false)
    const showHide = (onBlur) => {
        if (onBlur) {
            setDisplayOptions(false)
        } else {
            setDisplayOptions(!displayOptions)
        }
    }

    useEffect(() => {
        configRequest.getJobFunctions().then((response) => {
            setJobFunctions(response?.data?.data || [])
        })
    }, [])
    let options = jobFunctions.map((job) => ({
        name: job?.name,
        value: job?.name,
    }))
    if(!noAll)
        options = [
            {
                name: 'All Job Functions',
                value: '',
            },
            ...options,
        ]
        
    return (
        <div onClick={() => showHide()} onBlur={() => showHide(true)}>
            <SelectSearch
                printOptions={displayOptions ? 'always' : 'never'}
                options={options}
                search
                filterOptions={myFuzzySearch}
                emptyMessage={() => (
                    <div style={{ textAlign: 'center', fontSize: '0.8em' }}>
                        {!!options ? 'Not found' : 'No job Function'}
                    </div>
                )}
                placeholder={placeholder || 'Select Job Function'}
                onChange={onChange}
                value={value}
                style={{ width: 100 }}
            />
        </div>
    )
}

export default SelectJobFunction
