import { useEffect, useState } from 'react'
// import arrowIcon from '../../assets/img/arrowIcon.svg'
import './styles.css'

function Stepper(props) {
    const { steps, onChange, id, loading } = props
    const [currentStep, setCurrentStep] = useState(1)
    const [totalSteps, setTotalSteps] = useState(1)
    const focusIndicator = (dir) => {
        const ind = document.getElementById(`step-indicator-${currentStep}`)
        if (!loading) {
            const indOffset = ind?.clientWidth
            const step_container = document.getElementById('stepper-container')
            if (dir === 'next') {
                step_container.scrollLeft += indOffset
            }
            if (dir === 'prev') {
                step_container.scrollLeft -= indOffset
            } else {
                ind?.scrollIntoViewIfNeeded({
                    inline: 'center',
                    block: 'start',
                })
            }
        }
    }

    useEffect(() => {
        onChange && onChange(currentStep - 1)
    }, [currentStep])

    useEffect(() => {
        if (id) setCurrentStep(1)
    }, [id])

    useEffect(() => {
        if (!!steps && steps >= 1 && !isNaN(steps) && isFinite(steps)) {
            setTotalSteps(steps)
        } else {
            setTotalSteps(1)
        }
        console.log('totalSteps', totalSteps)
        console.log('totalSteps', steps)

    }, [steps])

    return (
        <div className="w-100 d-flex justify-content-center mt-5">
            <div>
                {currentStep !== 1 && (
                    <button
                        onClick={() => {
                            setCurrentStep(currentStep - 1)
                            focusIndicator('prev')
                        }}
                        className="btn hand me-2"
                        style={{ backgroundColor: '#27323C' }}
                        disabled={currentStep === 1}
                    >
                        {/* <img src={arrowIcon} alt="" className="rotate-180" /> */}
                        <svg
                            style={{ color: '#ffffff' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-left text-white"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                            />
                        </svg>
                        {/* Previous */}
                    </button>
                )}
            </div>
            <div className="d-flex stepper-indicator" id="stepper-container">
                {Array?.from(Array(totalSteps <= 0 ? 1 : totalSteps))?.map(
                    (step, index) => {
                        return (
                            <div
                                className={`py-2 px-3 ${
                                    currentStep === index + 1 ? 'bg-primary' : ''
                                }`}
                                id={`step-indicator-${index + 1}`}
                                style={{
                                    color:
                                        currentStep === index + 1
                                            ? '#fff'
                                            : 'grey',
                                }}
                                role="button"
                                onClick={() => {
                                    setCurrentStep(index + 1)
                                    focusIndicator('scrolltoview')
                                }}
                            >
                                {index + 1}
                            </div>
                        )
                    }
                )}
            </div>
            <div className="">
                <button
                    className="btn hand ms-2"
                    onClick={() => {
                        setCurrentStep(currentStep + 1)
                        focusIndicator('next')
                    }}
                    style={{ backgroundColor: '#27323C' }}
                    disabled={currentStep === steps || totalSteps === 1}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        style={{ color: '#ffffff' }}
                        fill="currentColor"
                        class="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                    </svg>
                    {/* Next */}
                </button>
            </div>
        </div>
    )
}

export default Stepper
