/* * * * * * * * * * * * * * * *
 *  ACTION TYPE naming convention
 *  VERB + '_REQUEST'
 *  VERB + '_SUCCESS'
 *  VERB + '_FAILURE'
 *
 *  ACTION naming convention
 *  verb : type
 * * * * * * * * * * * * * * * */
const action = {
    SIGN_OUT: 'SIGN_OUT',
    SET_OTP: 'SET_OTP',
    OPEN_APP: 'OPEN_APP',
    OPEN_TUTORIAL: 'OPEN_TUTORIAL',
    SET_LOGGED_IN: 'SET_LOGGED_IN',
    SET_SESSION_TIME: 'SET_SESSION_TIME',
    SET_LANGUAGE: 'SET_LANGUAGE',

    setOTP: (otp, otpDate) => ({
        type: action.SET_OTP,
        payload: { otp: otp, otpDate: otpDate },
    }),
    signOut: () => ({
        type: action.SIGN_OUT,
    }),
    openApp: () => ({
        type: action.OPEN_APP,
    }),
    openTutorial: () => ({
        type: action.OPEN_TUTORIAL,
    }),
    setLoggedIn: (data) => ({
        type: action.SET_LOGGED_IN,
        payload: data,
    }),
    setSessionTime: (data) => ({
        type: action.SET_SESSION_TIME,
        payload: data,
    }),
    setLanguage: (data) => ({
        type: action.SET_LANGUAGE,
        payload: data,
    }),
}

export default action
