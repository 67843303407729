import StripeCheckout from '../components/payment-method/PaymentProvider'
import Pricing from '../components/payment-method/Pricing'
import SubscriptionCancelled from '../components/payment-method/SubscriptionCancelled'
import Archived from '../views/archived/Archived'
import CompanyReview from '../views/company-review/CompanyReview'
import CreateJob from '../views/create-job/CreateJob'
import DownloadProfileApplicant from '../views/download-profile/DownloadProfileApplicant'
import EditProfile from '../views/edit-profile/EditProfile'
import Promote from '../views/employee-review/Promote'
import Explore from '../views/explore/Explore'
import JobFairEvents from '../views/job-fair/Events'
import { default as JobFairExplore } from '../views/job-fair/Explore'
import JobFairIndex from '../views/job-fair/JobfairIndex'
import JobFairRegister from '../views/job-fair/Register'
import Likes from '../views/likes/Likes'
import Matches from '../views/matches/Matches'
import Messages from '../views/messages/Messages'
import MyAccount from '../views/my-account/MyAccount'
import EditJob from '../views/my-jobs/components/EditJob'
import MyJobsinfo from '../views/my-jobs/components/MyJobInfo'
import MyJobslanding from '../views/my-jobs/components/MyJobsLanding'
import MyJobs from '../views/my-jobs/MyJobs'
import Notifications from '../views/notifications/Notifications'
import PasswordReset from '../views/password-reset/PasswordReset'
import PaymentSuccess from '../views/payments/PaymentSuccess'
import ProfileWizard from '../views/profile-wizard/ProfileWizard'
import Recommendations from '../views/recommendations/Recommendations'
import Settings from '../views/settings'
import ViewApplicant from '../views/view-applicant/ViewApplicant'

const privateRoutes = [
    {
        name: 'Explore',
        path: '/dashboard',
        component: <Explore />,
    },

    {
        name: 'My Jobs',
        path: '/jobs',
        component: <MyJobs />,
        nested_routes: [
            {
                name: 'Landing',
                path: '',
                component: <MyJobslanding />,
                index: true,
            },
            {
                name: 'View My Job',
                path: ':jobID',
                component: <MyJobsinfo />,
            },
            {
                name: 'Edit Job',
                path: 'edit',
                component: <EditJob />,
            },
        ],
    },

    {
        name: 'Likes',
        path: '/likes',
        component: <Likes />,
    },
    {
        name: 'Matches',
        path: '/matches',
        component: <Matches />,
    },
    {
        name: 'Archived',
        path: '/archived',
        component: <Archived />,
    },
    {
        name: 'Notifications',
        path: '/notifications',
        component: <Notifications />,
        excludeFromNav: true,
    },
    {
        name: 'Settings',
        path: '/settings/:type',
        component: <Settings />,
        excludeFromNav: true,
    },
    {
        name: 'Settings',
        path: '/settings/',
        component: <Settings />,
        excludeFromNav: true,
    },
    {
        name: 'Create Job',
        path: '/create-job',
        component: <CreateJob />,
        excludeFromNav: true,
    },
    {
        name: 'Edt Profile',
        path: '/edit-profile',
        component: <EditProfile />,
        excludeFromNav: true,
    },
    {
        name: 'My Account',
        path: '/profile',
        component: <MyAccount />,
        excludeFromNav: true,
    },
    {
        name: 'Messages',
        path: '/messages',
        component: <Messages />,
        excludeFromNav: true,
    },
    {
        name: 'Profile Wizard',
        path: '/profile-wizard',
        component: <ProfileWizard />,
        excludeFromNav: true,
    },
    {
        name: 'Profile View',
        path: '/applicant/:userId',
        component: <ViewApplicant />,
        excludeFromNav: true,
    },
    {
        name: 'Download Profile Applicant',
        path: '/downloadprofilepdf',
        component: <DownloadProfileApplicant />,
        excludeFromNav: true,
    },
    {
        name: 'Password Reset',
        path: '/password-reset',
        component: <PasswordReset />,
        excludeFromNav: true,
    },

    {
        name: 'My Jobs',
        path: '/job-fair',
        component: <JobFairIndex />,
        nested_routes: [
            {
                name: 'Job fair Index',
                path: 'dashboard/:jobfair_id',
                component: <JobFairExplore />,
            },
            {
                name: 'Job Fair Register',
                path: 'register/:id',
                component: <JobFairRegister />,
                index: true,
            },
            {
                name: 'Job Fair Events',
                path: 'events',
                component: <JobFairEvents />,
            },
        ],
    },
    {
        name: 'Recommendations',
        path: '/recommendations',
        component: <Recommendations />,
    },

    //payments
    {
        name: 'Payment Success',
        path: '/payments/success',
        component: <PaymentSuccess />,
        excludeFromNav: true,
    },
    {
        name: 'Payment Cancelled',
        path: '/payments/cancelled',
        component: <>Payment cancelled</>,
        excludeFromNav: true,
    },
    {
        name: 'Pricing',
        path: '/pricing',
        component: <Pricing />,
        excludeFromNav: true,
    },
    {
        name: 'Checkout',
        path: '/plan/checkout',
        component: <StripeCheckout />,
        excludeFromNav: true,
    },
    {
        name: 'Cancelled',
        path: '/subscription/cancelled',
        component: <SubscriptionCancelled />,
        excludeFromNav: true,
    },
    {
        name: 'Company Review',
        path: '/showcase/employee-review/:id',
        component: <CompanyReview />,
        excludeFromNav: true,
    },
    {
        name: 'Promote',
        path: '/showcase/video-from-heart/:id',
        component: <Promote />,
        excludeFromNav: true,
    },
]
export default privateRoutes
