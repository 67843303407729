import { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import jobIcon from '../../../assets/img/jobIcon.svg'
// import locationIcon from '../../../assets/img/locationIcon.svg'
import { mdiTools } from '@mdi/js'
import Icon from '@mdi/react'
import { useEffect } from 'react'
import educationIcon from '../../../assets/img/explore/educationIcon.svg'
import premiumBg from '../../../assets/img/createJob/Go_Premium_BG.png'
import experienceIcon from '../../../assets/img/explore/experienceIcon.svg'
import locationIcon from '../../../assets/img/explore/locationIcon.svg'
import playIcon from '../../../assets/img/explore/playIcon.svg'
import salaryIcon from '../../../assets/img/explore/salaryIcon.svg'
import DeleteIcon from '../../../assets/img/likes/deleteIcon.png'
import UnionIcon from '../../../assets/img/likes/unionIcon.png'
import AppliedDateIcon from '../../../assets/img/matches/appliedDateIcon.png'
import ArchiveIcon2 from '../../../assets/img/matches/archiveIcon2.png'
import CancelInterviewIcon from '../../../assets/img/matches/cancelInterviewIcon.png'
import EllipsisV from '../../../assets/img/matches/ellipsisV.png'
import HireIcon from '../../../assets/img/matches/hireIcon.png'
import InterviewIcon from '../../../assets/img/matches/interviewIcon.png'
import JoinIcon from '../../../assets/img/matches/joinIcon.png'
import LinkIcon2 from '../../../assets/img/matches/linkIcon2.png'
import MessageIcon2 from '../../../assets/img/matches/messageIcon2.png'
import UnmatchIcon from '../../../assets/img/matches/unmatchIcon.png'
import DismissIcon from '../../../assets/img/profilePreview/DismissIcon.png'
import LikeIcon from '../../../assets/img/profilePreview/LikeIcon.png'
import SuperLikeIcon from '../../../assets/img/profilePreview/SuperLikeIcon.png'
import ElevatorPitch from '../../../components/reusables/elevator-pitch/ElevatorPitch'
import { SkeletonLoading } from '../../../components/reusables/Loading'
import {
    defaultSwipeActions,
    JobApplication_Status,
    profilePlaceholder_Applicant,
    salaryRanges,
} from '../../../utils/constants'
import {
    formatMoney,
    getYearDifference,
    getYearsOfExpCategory,
    titleCase,
} from '../../../utils/helpers'
import JobluVideoPlayer from '../../explore/components/VideoPlayer'
import { ProfileModal } from '../../explore/v2/components/ViewProfile/ProfileModal'

import CloseWhite from '../../../assets/img/CloseWhite.png'
// import { ProfileModal } from '../../explore/v2/components/ViewProfile/ProfileModal'
import FlatList from 'flatlist-react'
import moment from 'moment/moment'
import Modal from 'react-bootstrap/Modal'
import ArchiveIcon from '../../../assets/img/matches/archiveIcon.png'
import CloseIcon from '../../../assets/img/matches/closeIcon.png'
import DateIcon from '../../../assets/img/matches/dateIcon.png'
import LinkIcon from '../../../assets/img/matches/linkIcon.png'
import MessageIcon from '../../../assets/img/matches/messageIcon.png'
import PhoneIcon from '../../../assets/img/matches/phoneIcon.png'
import TimeIcon from '../../../assets/img/matches/timeIcon.png'
import Select from '../../../components/inputs/CustomSelect'
import { ConfirmModal } from '../../../components/reusables/Modal/ConfirmModal'
import {
    calendarRequest,
    jobApplicantRequest,
    jobMatchRequest,
} from '../../../service/requests'
import { APP_TYPES, ConnectedApps } from '../../settings/Settings'
import { Box, Button, Dialog } from '@material-ui/core'
import JobluTypography from '../../../components/Typography'

const init_interview_details = {
    startDate: null,
    endDate: null,
    summary: null,
    description: null,
    sendNotifications: true,
    provider: null,
    jobMatchId: null,
    selected_date: null,
    reminderMinutesBeforeStart: 10,
}

// const ConfirmModal = (props) => {
//     const {
//         showConfirmModal,
//         handleCloseConfirmModal,
//         confirmModalText,
//         confirmAction,
//         archiveApplicant,
//         cancelInterview,
//         applicant,
//         selectedApplicant,
//     } = props
//     return (
//         <Modal
//             show={showConfirmModal}
//             centered
//             onHide={handleCloseConfirmModal}
//         >
//             <Modal.Header className="bg-primary justify-content-center">
//                 <div>
//                     <h5 className="text-center text-white mb-0">
//                         Archive Candidate
//                     </h5>
//                 </div>
//             </Modal.Header>
//             <Modal.Body>
//                 <div className="p-4 text-center">
//                     <p className="mb-3">
//                         You are about to{' '}
//                         <span className="text-danger">archive,</span>
//                     </p>
//                     <div className="mb-5">
//                         {selectedApplicant?.profileUrl && (
//                             <>
//                                 <img
//                                     src={selectedApplicant?.profileUrl}
//                                     alt=""
//                                     className="rounded-circle mb-3"
//                                     style={{
//                                         width: 90,
//                                         height: 90,
//                                         objectFit: 'cover',
//                                     }}
//                                 />
//                             </>
//                         )}
//                         <p
//                             className="mb-4"
//                             style={{ fontSize: 30, fontWeight: 600 }}
//                         >
//                             {selectedApplicant?.firstName}{' '}
//                             {selectedApplicant?.lastName}
//                         </p>
//                         <p className="">would you like to proceed?</p>
//                     </div>

//                     <div className="d-flex">
//                         <button
//                             className="btn btn-outline-danger w-50 me-2"
//                             onClick={() => {
//                                 handleCloseConfirmModal()
//                             }}
//                         >
//                             No
//                         </button>
//                         <button className="btn btn-danger w-50">Yes</button>
//                     </div>
//                 </div>
//                 <div className="p-4 d-none">
//                     <div className="text-center">
//                         <h5>{confirmModalText}</h5>
//                     </div>
//                     <div className="mt-4">
//                         <div className="d-flex justify-content-center ">
//                             <Button
//                                 variant="outline-primary"
//                                 className="me-2"
//                                 onClick={handleCloseConfirmModal}
//                             >
//                                 Close
//                             </Button>
//                             <Button
//                                 variant="primary"
//                                 onClick={() => {
//                                     if (confirmAction === 'archive_applicant') {
//                                         archiveApplicant()
//                                     }
//                                     if (confirmAction === 'cancel_interview') {
//                                         cancelInterview()
//                                     }
//                                 }}
//                             >
//                                 Confirm
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//             </Modal.Body>
//         </Modal>
//     )
// }

const ScheduleInterviewModal = (props) => {
    const { handleClose, show, job_match, getApplicants } = props
    const [isLoading, setIsLoading] = useState(false)
    const [selected_app, setSelectedApp] = useState(null)
    const [interview_details, setInterviewDetails] = useState(
        init_interview_details
    )
    const [errors, setErrors] = useState({})

    useEffect(() => {
        console.log('applicant details', job_match)
        setInterviewDetails({
            ...interview_details,
            summary: `Joblu Interview - ${job_match?.matchJob?.jobTitle} : ${
                job_match?.matchEmployer?.companyName ||
                `${job_match?.matchEmployer?.firstName} ${job_match?.matchEmployer?.lastName}`
            }`,
            jobMatchId: job_match?._id,
        })
    }, [job_match])

    useEffect(() => {
        if (!show) {
            setSelectedApp(null)
            setInterviewDetails(init_interview_details)
        }
    }, [show])

    useEffect(() => {
        setInterviewDetails({
            ...interview_details,
            provider: selected_app?.provider?.toUpperCase(),
        })
    }, [selected_app])

    const validate_fields = () => {
        let valid = []
        let errors = {}
        Object.keys({ ...interview_details }).forEach((key) => {
            if (!interview_details[key]) {
                valid.push(false)
                errors[key] = `Required`
            } else {
                valid.push(true)
                errors[key] = null
            }
        })
        if (
            moment(interview_details?.startDate).isSameOrAfter(
                moment(interview_details?.endDate)
            )
        ) {
            errors['endDate'] = 'End time is invalid'
            valid.push(false)
        } else {
            errors['endDate'] = null
            valid.push(true)
        }
        setErrors(errors)
        if (valid.includes(false)) return false
        return true
    }
    const handleSubmitInterviewSchedule = () => {
        let payload = { ...interview_details }

        const isValid = validate_fields()

        if (isValid) {
            setIsLoading(true)
            payload.reminderMinutesBeforeStart = parseInt(
                payload.reminderMinutesBeforeStart
            )
            console.log('interview fields are valid => ', payload)
            delete payload.selected_date
            calendarRequest
                .createEvent(payload)
                .then((res) => {
                    getApplicants()
                    setTimeout(() => {
                        setIsLoading(false)
                        handleClose()
                    }, 1500)
                })
                .finally(() => {})
        } else {
            console.log(errors)
        }
    }
    return (
        <>
            <div>
                <Modal show={show} size="lg" onHide={handleClose} centered>
                    <Modal.Body className="position-relative">
                        {isLoading && (
                            <div
                                className="position-absolute w-100 h-100 border-danger d-flex"
                                style={{
                                    background: 'hsla(0,00%, 50%, 0.5)',
                                    zIndex: 1000,
                                    top: 0,
                                    left: 0,
                                }}
                            >
                                <div className="m-auto">
                                    <div
                                        class="spinner-border text-primary"
                                        role="status"
                                    >
                                        <span class="visually-hidden">
                                            Loading...
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="p-4">
                            <h4 className="mb-4">Schedule an Interview</h4>
                            <label
                                className="mb-3"
                                style={{ color: errors?.provider && 'red' }}
                            >
                                Select a platform to conduct interview
                            </label>
                            <ConnectedApps
                                isSettings={false}
                                onAppClick={(app) => {
                                    setErrors({
                                        ...errors,
                                        provider: null,
                                    })
                                    setSelectedApp(app)
                                }}
                                appSelected={selected_app}
                            />

                            <div className="row mt-4">
                                <div className="col-6">
                                    <label htmlFor="">Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        onChange={(e) => {
                                            setErrors({
                                                ...errors,
                                                selected_date: null,
                                            })
                                            setInterviewDetails({
                                                ...interview_details,
                                                selected_date: e.target.value,
                                            })
                                        }}
                                        value={interview_details?.selected_date}
                                        style={{
                                            color:
                                                errors?.selected_date && 'red',
                                        }}
                                    />
                                </div>
                                {interview_details?.selected_date && (
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <label htmlFor="">
                                                    Start Time
                                                </label>
                                                <input
                                                    style={{
                                                        color:
                                                            errors?.startDate &&
                                                            'red',
                                                    }}
                                                    type="time"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        setErrors({
                                                            ...errors,
                                                            startDate: null,
                                                        })
                                                        const value =
                                                            e.target.value
                                                        const hours =
                                                            value?.split(':')[0]
                                                        const minutes =
                                                            value?.split(':')[1]

                                                        setInterviewDetails({
                                                            ...interview_details,
                                                            startDate: moment(
                                                                interview_details?.selected_date
                                                            )
                                                                .add(
                                                                    'hours',
                                                                    hours
                                                                )
                                                                .add(
                                                                    'minutes',
                                                                    minutes
                                                                )
                                                                .toISOString(
                                                                    true
                                                                ),
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="">
                                                    End Time
                                                </label>
                                                <input
                                                    style={{
                                                        color:
                                                            errors?.endDate &&
                                                            'red',
                                                    }}
                                                    type="time"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        setErrors({
                                                            ...errors,
                                                            endDate: null,
                                                        })
                                                        const value =
                                                            e.target.value
                                                        const hours =
                                                            value?.split(':')[0]
                                                        const minutes =
                                                            value?.split(':')[1]

                                                        setInterviewDetails({
                                                            ...interview_details,
                                                            endDate: moment(
                                                                interview_details?.selected_date
                                                            )
                                                                .add(
                                                                    'hours',
                                                                    hours
                                                                )
                                                                .add(
                                                                    'minutes',
                                                                    minutes
                                                                )
                                                                .toISOString(
                                                                    true
                                                                ),
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-12 col-sm-6 my-2">
                                    <label
                                        htmlFor=""
                                        style={{
                                            color: errors?.reminder && 'red',
                                        }}
                                    >
                                        Reminder
                                    </label>
                                    <Select
                                        options={[
                                            { label: '10 minutes', value: 10 },
                                            { label: '15 minutes', value: 15 },
                                            { label: '20 minutes', value: 20 },
                                            { label: '25 minutes', value: 25 },
                                            { label: '30 minutes', value: 30 },
                                            { label: '35 minutes', value: 35 },
                                            { label: '40 minutes', value: 40 },
                                            { label: '45 minutes', value: 45 },
                                        ]}
                                        value={
                                            interview_details?.reminderMinutesBeforeStart
                                        }
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            setInterviewDetails({
                                                ...interview_details,
                                                reminderMinutesBeforeStart:
                                                    e.target.value,
                                            })
                                        }}
                                        className="w-100"
                                    />
                                </div>
                                <div className="col-12 mt-4">
                                    <label
                                        htmlFor=""
                                        style={{
                                            color: errors?.description && 'red',
                                        }}
                                    >
                                        Notes for Job Seekers
                                    </label>
                                    <textarea
                                        style={{
                                            color: errors?.description && 'red',
                                        }}
                                        className="form-control"
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="5"
                                        placeholder="Type here..."
                                        onChange={(e) => {
                                            setErrors({
                                                ...errors,
                                                description: null,
                                            })
                                            setInterviewDetails({
                                                ...interview_details,
                                                description: e.target.value,
                                            })
                                        }}
                                    ></textarea>
                                </div>
                                <div className="col-12 text-end mt-4">
                                    <button
                                        className="btn btn-outline-primary me-2 text-center"
                                        onClick={handleClose}
                                        style={{ width: 200 }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary text-center"
                                        style={{ width: 200 }}
                                        onClick={() => {
                                            handleSubmitInterviewSchedule()
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
function getContents(tab) {
    if (tab === 'Applied') {
        return (
            <div>
                <h4 className="mt-5">
                    There are no profiles in your Active candidate list.
                </h4>
                <h6 className="mt-3">
                    Go back to Explore or post a job candidates will love at
                    first swipe!
                </h6>
                <p className="text-muted">
                    Tip: Keep your job posts up-to-date and relevant to attract
                    top candidates.
                </p>
            </div>
        )
    }
    if (tab === 'Shortlisted') {
        return (
            <div>
                <h4 className="mt-5">
                    There are no profiles on your candidate shortlist.
                </h4>
                <h6 className="mt-3">
                    Check out our global talent pool while you wait!
                </h6>
            </div>
        )
    }
    if (tab === 'Interviews') {
        return (
            <div>
                <h4 className="mt-5">
                    There are no profiles on your candidate interviews.
                </h4>
                <h6 className="mt-3">
                    Check out our global talent pool while you wait!
                </h6>
            </div>
        )
    }
    if (tab === 'Archived') {
        return (
            <div>
                <h4 className="mt-5">
                    We'll keep your archived candidates safe here.
                </h4>
                <h6 className="mt-3">
                    In the meantime, check out our talent pool!
                </h6>
            </div>
        )
    }
    if (tab === 'Hired') {
        return (
            <div>
                <h4 className="mt-5">There are no hired candidates yet.</h4>
                <h6 className="mt-3">
                    Check out our global talent pool while you wait!
                </h6>
            </div>
        )
    }
}
function Applicants(props) {
    const {
        talentSearch,
        applicants,
        selectedJob,
        tab,
        getApplicants,
        isLoading,
        AppState,
        swipeAction,
        hasMoreItems,
        loadMoreItems,
        jobTitle,
        isFromExplore,
        User
    } = props
    const [showProfile, setShowProfile] = useState(false)
    const navigate = useNavigate()

    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    const [match, setMatch] = useState(null)
    const [applicant, setApplicant] = useState(null)
    const [itemId, setItemId] = useState(null)
    const handleView = (data, index) => {
        // setShowProfile(data ? data : false)
        setApplicant(data?.matchApplicant)
        setMatch(data)
        setShowProfileModal(true)
        setItemId(index)
    }

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = (applicant) => setShow(applicant)

    // CONFIRM MODAL
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const [confirmModalText, setConfirmModalText] = useState(null)
    const [confirmModalApplicant, setConfirmModalApplicant] = useState(null)
    const [confirmAction, setConfirmAction] = useState(null)
    const [applicationId, setApplicationId] = useState(null)
    const [selectedApplicant, setSelectedApplicant] = useState(null)
    const [selectedJobData, setSelectedJobData] = useState(null)
    const [modalType, setModalType] = useState(null)
    const [modalHeader, setModalHeader] = useState(null)

    const [showPremiumModal, setShowPremiumModal] = useState(false)

    const handleApplicantStatus = (
        action,
        applicant,
        applicationId,
        application
    ) => {
        setConfirmAction(action)
        setApplicationId(applicationId)
        setSelectedJobData(application?.matchJob)
        console.log(application?.matchJob)
        if (action == 'archive_applicant') {
            setModalHeader('Archive Candidate')
            setModalType('archive')
            setConfirmModalText(
                `Are you sure you want to archive ${
                    applicant.firstName + ' ' + applicant.lastName
                }`
            )
        }

        if (action == 'scheduled_interview') {
            setModalHeader('Move to Interview')
            setModalType('move_to_interview')
            setConfirmModalText(
                `Move ${
                    applicant.firstName + ' ' + applicant.lastName
                } to interview without schedule?`
            )
        }
        if (action == 'cancel_interview') {
            setModalHeader('Cancel Interview?')
            setModalType('cancel_interview')
            setConfirmModalText(
                `Are you sure you want to cancel the interview of ${
                    applicant.firstName + ' ' + applicant.lastName
                }`
            )
        }

        if (action == 'hire') {
            setModalHeader('Hire Candidate')
            setModalType('hire')
        }

        if (action == 'unmatch') {
            if (
                User?.subscription?.status !== 'active' &&
                User?.subscription?.status !== 'trialing'
            ) {
                setShowPremiumModal(true)
                return
            } else {
                setModalHeader('Unmatch Candidate')
                setModalType('unmatch')
            }
        }

        setSelectedApplicant(applicant)
        setShowConfirmModal(true)
    }

    const archiveApplicant = () => {
        jobMatchRequest
            .updateJobMatch(applicationId, JobApplication_Status.ARCHIVED)
            .then(() => {
                getApplicants()
                setShowConfirmModal(false)
            })
    }

    const cancelInterview = () => {
        calendarRequest
            .cancelInterviewEvent(applicationId)
            .then((res) => {
                console.log('cancelled')
            })
            .finally(() => {
                getApplicants()
                setShowConfirmModal(false)
            })
    }

    const copyLink = (data) => {
        navigator.clipboard.writeText(data)
    }
    const hireApplicant = () => {
        jobMatchRequest
            .updateJobMatch(applicationId, JobApplication_Status.HIRED)
            .then(() => {
                getApplicants()
                setShowConfirmModal(false)
            })
    }

    useEffect(() => {
        console.log(User)
    }, [])
    const unmatchApplicant = () => {
        console.log('unmatch')
        const payload = {
            jobMatchId: applicationId,
        }
        jobApplicantRequest.unmatchApplicant(payload).then(() => {
            getApplicants()
            setShowConfirmModal(false)
        })
    }

    const moveToInterview = () => {
        console.log('unmatch')
        const payload = {
            jobMatchId: applicationId,
            applicationStatus: 'scheduled_interview',
        }
        jobApplicantRequest.updateJobMatchStatus(payload).then(() => {
            getApplicants()
            setShowConfirmModal(false)
        })
    }

    const [playVideo, setPlayVideo] = useState(false)
    const [videoSrc, setVideoSrc] = useState(null)
    const [showPitch, setShowPitch] = useState(true)
    const handlePlayVideo = (src) => {
        setVideoSrc(src)
        setPlayVideo(!playVideo)
    }

    const handleGoToMessage = (data) => {
        navigate('/messages', {
            state: { data },
        })
    }

    const { currencyList, currency } = AppState
    const currencyOptions = {
        currencyList,
        currency,
    }

    const getYearsOfExp = (item) => {
        if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
            const sorted =
                item?.experienceHistory &&
                item?.experienceHistory?.sort(function (a, b) {
                    return new Date(a.startDate) - new Date(b.startDate)
                })
            const startDate = new Date(sorted[0]?.startDate)
            const diff = getYearDifference(startDate)
            return getYearsOfExpCategory(diff)
        } else {
            return null
        }
    }

    useEffect(() => {
        console.log('aaa', selectedJob)
    }, [selectedJob])

    const renderApplicants = (applicant, index) => {
        return (
            <>
                <div
                    className="row border-top border-bottom py-3 mx-0 mb-2 shadow-sm border mx-0 hand"
                    key={applicant?.matchApplicant?.id}
                    id={index}
                    style={{ borderRadius: 15, minHeight: 200 }}
                    onClick={() => {
                        handleView(applicant, index)
                    }}
                >
                    <div className="col-md-9 d-flex flex-column flex-md-row">
                        <div
                            className={`me-3 mb-2 mb-md-0 position-relative`}
                            style={{ flexBasis: '10%' }}
                        >
                            <div
                                className="position-relative"
                                style={{
                                    width: 80,
                                    height: 80,
                                }}
                            >
                                <img
                                    src={
                                        applicant?.matchApplicant?.profileUrl ||
                                        profilePlaceholder_Applicant
                                    }
                                    style={{
                                        width: 80,
                                        height: 80,
                                    }}
                                    alt="profile"
                                    className="rounded-circle mb-3"
                                />
                                {applicant?.matchApplicant?.elevatorPitch && (
                                    <>
                                        <div
                                            className="position-absolute"
                                            style={{
                                                bottom: -6,
                                                right: -6,
                                            }}
                                        >
                                            <img
                                                src={playIcon}
                                                alt=""
                                                className="hand"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                     handlePlayVideo(
                                                        applicant
                                                            ?.matchApplicant
                                                            ?.elevatorPitch
                                                    )
                                                }
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className="position-absolute"
                                style={{ right: 0, top: 0 }}
                            >
                                {' '}
                                <div className="dropdown d-block d-md-none">
                                    <div className="text-end">
                                        <span
                                            className="hand text-end"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src={EllipsisV}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                            />
                                        </span>
                                        <ul
                                            class="dropdown-menu dropdown-menu-end"
                                            style={{ width: 250 }}
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            <li className="text-center">
                                                <span class="dropdown-header text-primary">
                                                    <strong>Actions</strong>
                                                </span>
                                            </li>

                                            {tab === 'Interviews' && (
                                                <>
                                                    <li
                                                        style={{
                                                            display: applicant
                                                                ?.matchInterview
                                                                ?.data
                                                                ? 'block'
                                                                : 'none',
                                                        }}
                                                    >
                                                        <a
                                                            class={`dropdown-item hand`}
                                                            href={
                                                                applicant
                                                                    ?.matchInterview
                                                                    ?.data
                                                                    ?.hangoutLink ||
                                                                applicant
                                                                    ?.matchInterview
                                                                    ?.data
                                                                    ?.htmlLink
                                                            }
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            style={{
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            <img
                                                                src={JoinIcon}
                                                                className={`me-2`}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                }}
                                                                alt=""
                                                            />
                                                            Join Call
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span
                                                            class={`dropdown-item hand`}
                                                            href="#"
                                                            style={{
                                                                fontSize: 12,
                                                                display:
                                                                    applicant
                                                                        ?.matchInterview
                                                                        ?.data
                                                                        ? 'block'
                                                                        : 'none',
                                                            }}
                                                            onClick={() => {
                                                                copyLink(
                                                                    applicant
                                                                        ?.matchInterview
                                                                        ?.data
                                                                        ?.hangoutLink ||
                                                                        applicant
                                                                            ?.matchInterview
                                                                            ?.data
                                                                            ?.htmlLink
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={LinkIcon2}
                                                                className={`me-2`}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                }}
                                                                alt=""
                                                            />
                                                            Copy Link
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span
                                                            class={`dropdown-item hand`}
                                                            href="#"
                                                            style={{
                                                                fontSize: 12,
                                                            }}
                                                            onClick={() =>
                                                                handleApplicantStatus(
                                                                    'cancel_interview',
                                                                    applicant?.matchApplicant,
                                                                    applicant?._id
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    CancelInterviewIcon
                                                                }
                                                                className={`me-2`}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                }}
                                                                alt=""
                                                            />
                                                            Cancel Interview
                                                        </span>
                                                    </li>
                                                </>
                                            )}
                                            {applicant?.matchStatus !=
                                                'waiting' && (
                                                <>
                                                    <li>
                                                        <a
                                                            class={`dropdown-item hand ${
                                                                applicant?.matchStatus ===
                                                                    'waiting' &&
                                                                'disabled text-muted'
                                                            }`}
                                                            onClick={() =>
                                                                handleGoToMessage(
                                                                    applicant
                                                                )
                                                            }
                                                            href="#"
                                                            style={{
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    MessageIcon2
                                                                }
                                                                className={`me-2 ${
                                                                    applicant?.matchStatus ===
                                                                        'waiting' &&
                                                                    'opacity-25'
                                                                }`}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                }}
                                                                alt=""
                                                            />
                                                            Send Message
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                            {applicant?.matchStatus !=
                                                'waiting' &&
                                                applicant?.applicationStatus !=
                                                    'hired' &&
                                                tab !== 'Interviews' && (
                                                    <>
                                                        <li>
                                                            <span
                                                                class={`dropdown-item hand ${
                                                                    (applicant?.matchStatus ===
                                                                        'waiting' ||
                                                                        applicant?.applicationStatus ==
                                                                            'hired') &&
                                                                    'disabled text-muted'
                                                                }`}
                                                                href="#"
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                                onClick={() =>
                                                                    handleShow(
                                                                        applicant
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        InterviewIcon
                                                                    }
                                                                    className={`me-2 ${
                                                                        (applicant?.matchStatus ===
                                                                            'waiting' ||
                                                                            applicant?.applicationStatus ==
                                                                                'hired') &&
                                                                        'opacity-25'
                                                                    }`}
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                    alt=""
                                                                />
                                                                Interview
                                                            </span>
                                                        </li>
                                                    </>
                                                )}

                                            {applicant?.matchStatus !==
                                                'waiting' &&
                                                applicant?.applicationStatus !==
                                                    'hired' && (
                                                    <>
                                                        <li
                                                            onClick={() => {
                                                                handleApplicantStatus(
                                                                    'hire',
                                                                    applicant?.matchApplicant,
                                                                    applicant?._id,
                                                                    applicant
                                                                )
                                                            }}
                                                        >
                                                            <span
                                                                class={`dropdown-item hand ${
                                                                    (applicant?.matchStatus ===
                                                                        'waiting' ||
                                                                        applicant?.applicationStatus ==
                                                                            'hired') &&
                                                                    'disabled text-muted'
                                                                }`}
                                                                href="#"
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        HireIcon
                                                                    }
                                                                    className={`me-2 ${
                                                                        (applicant?.matchStatus ===
                                                                            'waiting' ||
                                                                            applicant?.applicationStatus ==
                                                                                'hired') &&
                                                                        'opacity-25'
                                                                    }`}
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                    alt=""
                                                                />
                                                                Hire
                                                            </span>
                                                        </li>
                                                    </>
                                                )}

                                            {applicant?.matchStatus !==
                                                'waiting' && (
                                                <>
                                                    <li
                                                        onClick={() => {
                                                            handleApplicantStatus(
                                                                'archive_applicant',
                                                                applicant?.matchApplicant,
                                                                applicant?._id,
                                                                applicant
                                                            )
                                                        }}
                                                    >
                                                        <span
                                                            class={`dropdown-item hand ${
                                                                applicant?.matchStatus ===
                                                                    'waiting' &&
                                                                'disabled text-muted'
                                                            }`}
                                                            href="#"
                                                            style={{
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    ArchiveIcon2
                                                                }
                                                                className={`me-2 ${
                                                                    applicant?.matchStatus ===
                                                                        'waiting' &&
                                                                    'opacity-25'
                                                                }`}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                }}
                                                                alt=""
                                                            />
                                                            Archive
                                                        </span>
                                                    </li>
                                                </>
                                            )}

                                            {talentSearch && (
                                                <>
                                                    {applicant?.applicationStatus &&
                                                        applicant?.applicationStatus !=
                                                            'hired' && (
                                                            <>
                                                                <li
                                                                    onClick={() => {
                                                                        handleApplicantStatus(
                                                                            'unmatch',
                                                                            applicant?.matchApplicant,
                                                                            applicant?._id,
                                                                            applicant
                                                                        )
                                                                    }}
                                                                >
                                                                    <span
                                                                        class={`dropdown-item hand ${
                                                                            (!applicant?.applicationStatus ||
                                                                                applicant?.applicationStatus ==
                                                                                    'hired') &&
                                                                            'disabled text-muted'
                                                                        }`}
                                                                        href="#"
                                                                        style={{
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                UnmatchIcon
                                                                            }
                                                                            className={`me-2 ${
                                                                                (!applicant?.applicationStatus ||
                                                                                    applicant?.applicationStatus ==
                                                                                        'hired') &&
                                                                                'opacity-25'
                                                                            }`}
                                                                            style={{
                                                                                width: 40,
                                                                                height: 40,
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                        Unmatch
                                                                    </span>
                                                                </li>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="me-0 me-md-3 mb-2 mb-md-0"
                            style={{ flexBasis: '25%' }}
                        >
                            <div className="position-relative">
                                <h5
                                    className="text-primary mt-2 mb-0 hand"
                                    onClick={() => handleView(applicant, index)}
                                >
                                    {titleCase(
                                        `${applicant?.matchApplicant?.firstName} ${applicant?.matchApplicant?.lastName}`
                                    )}
                                </h5>
                                {applicant?.matchApplicant?.preferredTitle && (
                                    <p className="mb-0">
                                        <strong>
                                            {
                                                applicant?.matchApplicant
                                                    ?.preferredTitle
                                            }
                                        </strong>
                                    </p>
                                )}
                                <p className="text-muted mb-2">
                                    {applicant?.matchApplicant?.jobFunction}
                                </p>
                                <div className=" mt-3" style={{ bottom: 0 }}>
                                    {applicant?.matchStatus === 'waiting' && (
                                        <span
                                            onClick={() => {
                                                console.log(applicant)
                                            }}
                                            className="text-nowrap"
                                            style={{
                                                backgroundColor: '#ffffff',
                                                borderRadius: '12px',
                                                padding: '3px 16px',
                                                color: '#6E88A3',
                                                border: '1px solid #6E88A3',
                                                fontSize: 12,
                                            }}
                                        >
                                            Awaiting Match
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className="me-0 me-md-3 mb-2 mb-md-0"
                            style={{ flexBasis: '25%' }}
                        >
                            {applicant?.matchApplicant?.educationHistory
                                ?.length > 0 && (
                                <>
                                    <p className="small">
                                        <img
                                            src={educationIcon}
                                            alt=""
                                            className="me-2"
                                            style={{
                                                width: 22,
                                            }}
                                        />{' '}
                                        {
                                            applicant?.matchApplicant?.educationHistory?.sort(
                                                function (a, b) {
                                                    return (
                                                        new Date(
                                                            b.educStartDate
                                                        ) -
                                                        new Date(
                                                            a.educStartDate
                                                        )
                                                    )
                                                }
                                            )[0]?.educName
                                        }
                                    </p>
                                </>
                            )}
                            {applicant?.matchApplicant?.currentLocation && (
                                <>
                                    <p className="small">
                                        <img
                                            src={locationIcon}
                                            alt=""
                                            className="me-2"
                                            style={{
                                                width: 22,
                                            }}
                                        />{' '}
                                        {
                                            applicant?.matchApplicant
                                                ?.currentLocation
                                        }
                                    </p>
                                </>
                            )}
                            {getYearsOfExp(applicant) &&
                                getYearsOfExp(applicant) !== null && (
                                    <p className="small">
                                        <img
                                            src={experienceIcon}
                                            alt=""
                                            className="me-2"
                                            style={{
                                                width: 22,
                                            }}
                                        />{' '}
                                        {`${getYearsOfExp(
                                            applicant
                                        )} Years of Experience`}
                                    </p>
                                )}
                            {applicant?.matchApplicant?.salaryMin &&
                                applicant?.matchApplicant?.salaryMin !==
                                    null && (
                                    <p className="small">
                                        <img
                                            src={salaryIcon}
                                            alt=""
                                            className="me-2"
                                            style={{
                                                width: 22,
                                            }}
                                        />{' '}
                                        {applicant?.matchApplicant
                                            ?.salaryMin === 1 &&
                                        applicant?.matchApplicant?.salaryMax ===
                                            1
                                            ? salaryRanges.COMPETIIVE_SALARY
                                            : applicant?.matchApplicant
                                                  ?.salaryMin === 2 &&
                                              applicant?.matchApplicant
                                                  ?.salaryMax === 2
                                            ? salaryRanges.INDUSTRY_STANDARD
                                            : `${formatMoney(
                                                  applicant?.matchApplicant
                                                      ?.salaryMin,
                                                  currencyOptions,
                                                  applicant?.matchApplicant
                                                      ?.salaryCurrency || 'SGD'
                                              )} - ${formatMoney(
                                                  applicant?.matchApplicant
                                                      ?.salaryMax,
                                                  currencyOptions,
                                                  applicant?.matchApplicant
                                                      ?.salaryCurrency || 'SGD'
                                              )}`}
                                    </p>
                                )}
                            {applicant?.matchApplicant?.skills?.length < 1 && (
                                <>
                                    <span
                                        className="hand"
                                        onClick={() => {
                                            handleView(applicant, index)
                                        }}
                                        style={{
                                            color: '#009CDE!important',
                                            textDecoration: 'underline',
                                            // fontSize: 12,
                                        }}
                                    >
                                        <small>See More</small>
                                    </span>
                                </>
                            )}
                        </div>
                        <div style={{ flexBasis: '40%' }}>
                            {applicant?.matchApplicant?.skills?.length > 0 && (
                                <>
                                    <div className="mb-3">
                                        <div className="d-flex mb-2">
                                            <div className="me-2">
                                                <Icon
                                                    path={mdiTools}
                                                    size={0.8}
                                                    color={'#d7a048'}
                                                />
                                            </div>
                                            <div>
                                                <span
                                                    className="mb-2 text-primary"
                                                    style={{ fontSize: 14 }}
                                                >
                                                    <strong>
                                                        Technical Skills
                                                    </strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {applicant?.matchApplicant?.skills
                                                ?.slice(0, 5)
                                                .map((skill) => {
                                                    return (
                                                        <span
                                                            style={{
                                                                backgroundColor:
                                                                    '#009CDE',
                                                                borderRadius:
                                                                    '12px',
                                                                padding:
                                                                    '0 16px',
                                                                color: '#ffffff',
                                                                fontSize: 12,
                                                            }}
                                                            className="me-2 mb-1 text-nowrap text-capitalize"
                                                        >
                                                            {skill}
                                                        </span>
                                                    )
                                                })}
                                            {applicant?.matchApplicant?.skills
                                                ?.length > 5 && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                '#009CDE',
                                                            borderRadius:
                                                                '12px',
                                                            padding: '0 16px',
                                                            color: '#ffffff',
                                                            fontSize: 12,
                                                        }}
                                                        className="me-2 mb-1 text-nowrap"
                                                    >
                                                        +{' '}
                                                        {applicant
                                                            ?.matchApplicant
                                                            ?.skills?.length -
                                                            5}{' '}
                                                        more
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                        <span
                                            className="hand"
                                            onClick={() => {
                                                handleView(applicant, index)
                                            }}
                                            style={{
                                                color: '#009CDE!important',
                                                textDecoration: 'underline',
                                                // fontSize: 12,
                                            }}
                                        >
                                            <small>See More</small>
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-md-3">
                        {tab === 'Applied' && (
                            <div className="d-flex justify-content-between flex-column h-100">
                                <div className="text-center text-md-end d-md-block">
                                    <img
                                        src={DismissIcon}
                                        width={40}
                                        alt="icon"
                                        role="button"
                                        style={{ width: 40 }}
                                        className="me-2"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            swipeAction({
                                                job: applicant?.matchJob,
                                                applicant:
                                                    applicant?.matchApplicant,
                                                action: defaultSwipeActions.left,
                                                itemId: index,
                                            })
                                        }}
                                    />

                                    {/* <img
                                        src={SuperLikeIcon}
                                        alt="icon"
                                        role="button"
                                        style={{ width: 40 }}
                                        className="mx-2"
                                        onClick={() => {
                                            swipeAction({
                                                job: applicant?.matchJob,
                                                applicant:
                                                    applicant?.matchApplicant,
                                                action: defaultSwipeActions.top,
                                                itemId: index,
                                            })
                                        }}
                                    /> */}
                                    <img
                                        src={LikeIcon}
                                        width={40}
                                        alt="icon"
                                        role="button"
                                        style={{ width: 40 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            swipeAction({
                                                job: applicant?.matchJob,
                                                applicant:
                                                    applicant?.matchApplicant,
                                                action: defaultSwipeActions.right,
                                                itemId: index,
                                            })
                                        }}
                                    />
                                </div>
                                <div className="d-none d-md-block mt-2 text-end">
                                    <img
                                        src={AppliedDateIcon}
                                        className="me-2"
                                        style={{ width: 24 }}
                                        alt=""
                                    />

                                    <span
                                        className="text-muted"
                                        style={{ fontSize: 14 }}
                                    >
                                        Applied:{' '}
                                        {moment(applicant?.createdAt).format(
                                            'MMM DD, YYYY'
                                        )}
                                    </span>
                                </div>
                                <div
                                    className="text-end d-block d-md-none mt-2 text-end justify-self-end"
                                    style={{ bottom: 0, right: 0 }}
                                >
                                    <img
                                        src={AppliedDateIcon}
                                        className="me-2"
                                        style={{ width: 24 }}
                                        alt=""
                                    />

                                    <span
                                        className="text-muted"
                                        style={{ fontSize: 14 }}
                                    >
                                        Applied:{' '}
                                        {moment(applicant?.createdAt).format(
                                            'MMM DD, YYYY'
                                        )}
                                    </span>
                                </div>
                            </div>
                        )}
                        {tab !== 'Applied' && (
                            <>
                                <div className="h-100 d-flex justify-content-between flex-column">
                                    <div className="dropdown d-none d-md-block">
                                        <div className="text-end">
                                            <span
                                                className="hand text-end"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src={EllipsisV}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                    }}
                                                />
                                            </span>
                                            <ul
                                                class="dropdown-menu dropdown-menu-end"
                                                style={{ width: 250 }}
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li className="text-center">
                                                    <span class="dropdown-header text-primary">
                                                        <strong>Actions</strong>
                                                    </span>
                                                </li>

                                                {tab === 'Interviews' && (
                                                    <>
                                                        <li
                                                            style={{
                                                                display:
                                                                    applicant
                                                                        ?.matchInterview
                                                                        ?.data
                                                                        ? 'block'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            <a
                                                                class={`dropdown-item hand`}
                                                                href={
                                                                    applicant
                                                                        ?.matchInterview
                                                                        ?.provider ===
                                                                    'GOOGLE'
                                                                        ? applicant
                                                                              ?.matchInterview
                                                                              ?.data
                                                                              ?.hangoutLink
                                                                        : applicant
                                                                              ?.matchInterview
                                                                              ?.provider ===
                                                                              'MICROSOFT' ||
                                                                          applicant
                                                                              ?.matchInterview
                                                                              ?.provider ===
                                                                              'SKYPE'
                                                                        ? applicant
                                                                              ?.matchInterview
                                                                              ?.data
                                                                              ?.onlineMeeting
                                                                              ?.joinUrl
                                                                        : applicant
                                                                              ?.matchInterview
                                                                              ?.data
                                                                              ?.joinUrl
                                                                }
                                                                rel="noreferrer"
                                                                target="_blank"
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                                alt=""
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        JoinIcon
                                                                    }
                                                                    className={`me-2`}
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                />
                                                                Join Call
                                                            </a>
                                                        </li>
                                                        <li
                                                            style={{
                                                                display:
                                                                    applicant
                                                                        ?.matchInterview
                                                                        ?.data
                                                                        ? 'block'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            <span
                                                                class={`dropdown-item hand`}
                                                                href="#"
                                                                style={{
                                                                    fontSize: 12,
                                                                    display:
                                                                        applicant
                                                                            ?.matchInterview
                                                                            ?.data
                                                                            ? 'block'
                                                                            : 'none',
                                                                }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                    copyLink(
                                                                        applicant
                                                                            ?.matchInterview
                                                                            ?.provider ===
                                                                            'GOOGLE'
                                                                            ? applicant
                                                                                  ?.matchInterview
                                                                                  ?.data
                                                                                  ?.hangoutLink
                                                                            : applicant
                                                                                  ?.matchInterview
                                                                                  ?.provider ===
                                                                                  'MICROSOFT' ||
                                                                              applicant
                                                                                  ?.matchInterview
                                                                                  ?.provider ===
                                                                                  'SKYPE'
                                                                            ? applicant
                                                                                  ?.matchInterview
                                                                                  ?.data
                                                                                  ?.onlineMeeting
                                                                                  ?.joinUrl
                                                                            : applicant
                                                                                  ?.matchInterview
                                                                                  ?.data
                                                                                  ?.joinUrl
                                                                        // applicant
                                                                        //     ?.matchInterview
                                                                        //     ?.data
                                                                        //     ?.hangoutLink ||
                                                                        //     applicant
                                                                        //         ?.matchInterview
                                                                        //         ?.data
                                                                        //         ?.htmlLink
                                                                    )
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        LinkIcon2
                                                                    }
                                                                    className={`me-2`}
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                    alt=""
                                                                />
                                                                Copy Link
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span
                                                                class={`dropdown-item hand`}
                                                                href="#"
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                    handleApplicantStatus(
                                                                        'cancel_interview',
                                                                        applicant?.matchApplicant,
                                                                        applicant?._id
                                                                    )
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        CancelInterviewIcon
                                                                    }
                                                                    className={`me-2`}
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                    alt=""
                                                                />
                                                                Cancel Interview
                                                            </span>
                                                        </li>
                                                    </>
                                                )}
                                                {applicant?.matchStatus !=
                                                    'waiting' && (
                                                    <>
                                                        <li>
                                                            <a
                                                                class={`dropdown-item hand ${
                                                                    applicant?.matchStatus ===
                                                                        'waiting' &&
                                                                    'disabled text-muted'
                                                                }`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                    handleGoToMessage(
                                                                        applicant
                                                                    )
                                                                }}
                                                                href="#"
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        MessageIcon2
                                                                    }
                                                                    className={`me-2 ${
                                                                        applicant?.matchStatus ===
                                                                            'waiting' &&
                                                                        'opacity-25'
                                                                    }`}
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                    alt=""
                                                                />
                                                                Send Message
                                                            </a>
                                                        </li>
                                                    </>
                                                )}
                                                {applicant?.matchStatus !=
                                                    'waiting' &&
                                                    applicant?.applicationStatus !=
                                                        'hired' &&
                                                    tab !== 'Interviews' && (
                                                        <>
                                                            <li>
                                                                <span
                                                                    class={`dropdown-item hand ${
                                                                        (applicant?.matchStatus ===
                                                                            'waiting' ||
                                                                            applicant?.applicationStatus ==
                                                                                'hired') &&
                                                                        'disabled text-muted'
                                                                    }`}
                                                                    href="#"
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation()
                                                                        handleShow(
                                                                            applicant
                                                                        )
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            InterviewIcon
                                                                        }
                                                                        className={`me-2 ${
                                                                            (applicant?.matchStatus ===
                                                                                'waiting' ||
                                                                                applicant?.applicationStatus ==
                                                                                    'hired') &&
                                                                            'opacity-25'
                                                                        }`}
                                                                        style={{
                                                                            width: 40,
                                                                            height: 40,
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                    Schedule
                                                                    Interview
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span
                                                                    class={`dropdown-item hand ${
                                                                        (applicant?.matchStatus ===
                                                                            'waiting' ||
                                                                            applicant?.applicationStatus ==
                                                                                'hired') &&
                                                                        'disabled text-muted'
                                                                    }`}
                                                                    href="#"
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation()
                                                                        handleApplicantStatus(
                                                                            'scheduled_interview',
                                                                            applicant?.matchApplicant,
                                                                            applicant?._id
                                                                        )
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            InterviewIcon
                                                                        }
                                                                        className={`me-2 ${
                                                                            (applicant?.matchStatus ===
                                                                                'waiting' ||
                                                                                applicant?.applicationStatus ==
                                                                                    'hired') &&
                                                                            'opacity-25'
                                                                        }`}
                                                                        style={{
                                                                            width: 40,
                                                                            height: 40,
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                    Move to
                                                                    Interview
                                                                </span>
                                                            </li>
                                                        </>
                                                    )}

                                                {applicant?.matchStatus !==
                                                    'waiting' &&
                                                    applicant?.applicationStatus !==
                                                        'hired' && (
                                                        <>
                                                            <li
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                    handleApplicantStatus(
                                                                        'hire',
                                                                        applicant?.matchApplicant,
                                                                        applicant?._id,
                                                                        applicant
                                                                    )
                                                                }}
                                                            >
                                                                <span
                                                                    class={`dropdown-item hand ${
                                                                        (applicant?.matchStatus ===
                                                                            'waiting' ||
                                                                            applicant?.applicationStatus ==
                                                                                'hired') &&
                                                                        'disabled text-muted'
                                                                    }`}
                                                                    href="#"
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            HireIcon
                                                                        }
                                                                        className={`me-2 ${
                                                                            (applicant?.matchStatus ===
                                                                                'waiting' ||
                                                                                applicant?.applicationStatus ==
                                                                                    'hired') &&
                                                                            'opacity-25'
                                                                        }`}
                                                                        style={{
                                                                            width: 40,
                                                                            height: 40,
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                    Hire
                                                                </span>
                                                            </li>
                                                        </>
                                                    )}

                                                {applicant?.matchStatus !==
                                                    'waiting' && (
                                                    <>
                                                        <li
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                handleApplicantStatus(
                                                                    'archive_applicant',
                                                                    applicant?.matchApplicant,
                                                                    applicant?._id,
                                                                    applicant
                                                                )
                                                            }}
                                                        >
                                                            <span
                                                                class={`dropdown-item hand ${
                                                                    applicant?.matchStatus ===
                                                                        'waiting' &&
                                                                    'disabled text-muted'
                                                                }`}
                                                                href="#"
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        ArchiveIcon2
                                                                    }
                                                                    className={`me-2 ${
                                                                        applicant?.matchStatus ===
                                                                            'waiting' &&
                                                                        'opacity-25'
                                                                    }`}
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                    }}
                                                                    alt=""
                                                                />
                                                                Archive
                                                            </span>
                                                        </li>
                                                    </>
                                                )}

                                                {applicant?.applicationStatus &&
                                                    applicant?.applicationStatus !=
                                                        'hired' && (
                                                        <>
                                                            <li
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                    handleApplicantStatus(
                                                                        'unmatch',
                                                                        applicant?.matchApplicant,
                                                                        applicant?._id,
                                                                        applicant
                                                                    )
                                                                }}
                                                            >
                                                                <span
                                                                    class={`dropdown-item hand ${
                                                                        (!applicant?.applicationStatus ||
                                                                            applicant?.applicationStatus ==
                                                                                'hired') &&
                                                                        'disabled text-muted'
                                                                    }`}
                                                                    href="#"
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            UnmatchIcon
                                                                        }
                                                                        className={`me-2 ${
                                                                            (!applicant?.applicationStatus ||
                                                                                applicant?.applicationStatus ==
                                                                                    'hired') &&
                                                                            'opacity-25'
                                                                        }`}
                                                                        style={{
                                                                            width: 40,
                                                                            height: 40,
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                    Unmatch
                                                                </span>
                                                            </li>
                                                        </>
                                                    )}
                                            </ul>
                                        </div>
                                    </div>
                                    {tab !== 'Interviews' && tab !== 'Hired' && (
                                        <>
                                            <div className="d-none d-md-block mt-2 text-end">
                                                <img
                                                    src={AppliedDateIcon}
                                                    className="me-2"
                                                    style={{ width: 24 }}
                                                    alt=""
                                                />

                                                <span
                                                    className="text-muted"
                                                    style={{ fontSize: 14 }}
                                                >
                                                    {applicant?.matchStatus ==
                                                    'waiting' ? (
                                                        <>Applied: </>
                                                    ) : (
                                                        <>Shortlisted: </>
                                                    )}
                                                    {moment(
                                                        applicant?.createdAt
                                                    ).format('MMM DD, YYYY')}
                                                </span>
                                            </div>
                                            <div
                                                className="text-end d-block d-md-none mt-2 text-end"
                                                style={{ bottom: 0, right: 0 }}
                                            >
                                                <img
                                                    src={AppliedDateIcon}
                                                    className="me-2"
                                                    style={{ width: 24 }}
                                                    alt=""
                                                />

                                                <span
                                                    className="text-muted"
                                                    style={{ fontSize: 14 }}
                                                >
                                                    {applicant?.matchStatus ==
                                                    'waiting' ? (
                                                        <>Applied: </>
                                                    ) : (
                                                        <>Shortlisted: </>
                                                    )}
                                                    {moment(
                                                        applicant?.createdAt
                                                    ).format('MMM DD, YYYY')}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        <div className="d-none">
                            {tab === 'Interviews' && (
                                <div className="">
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <div>
                                                <img
                                                    src={DateIcon}
                                                    alt=""
                                                    className="me-2"
                                                />
                                                {moment(
                                                    applicant?.matchInterview
                                                        ?.data?.start?.dateTime
                                                ).format('MMM DD, YYYY')}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <div>
                                                <img
                                                    src={TimeIcon}
                                                    alt=""
                                                    className="me-2"
                                                />
                                                {moment(
                                                    applicant?.matchInterview
                                                        ?.data?.start?.dateTime
                                                ).format('hh:mm A')}{' '}
                                                -{' '}
                                                {moment(
                                                    applicant?.matchInterview
                                                        ?.data?.end?.dateTime
                                                ).format('hh:mm A')}
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="">
                                                <img
                                                    src={
                                                        APP_TYPES?.[
                                                            applicant?.matchInterview?.provider?.toLowerCase()
                                                        ]?.icon
                                                    }
                                                    alt=""
                                                    className="me-2"
                                                    style={{
                                                        width: 30,
                                                        height: 30,
                                                    }}
                                                />
                                                {
                                                    APP_TYPES?.[
                                                        applicant?.matchInterview?.provider?.toLowerCase()
                                                    ]?.name
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <a
                                                href={
                                                    applicant?.matchInterview
                                                        ?.data?.hangoutLink ||
                                                    applicant?.matchInterview
                                                        ?.data?.htmlLink
                                                }
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <button className="btn btn-sm btn-primary me-2 w-100 rounded">
                                                    <img
                                                        src={PhoneIcon}
                                                        alt=""
                                                        style={{
                                                            width: 15,
                                                            height: 15,
                                                        }}
                                                    />{' '}
                                                    <span
                                                        className="ms-2"
                                                        style={{
                                                            fontSize: 15,
                                                        }}
                                                    >
                                                        {' '}
                                                        Join Call
                                                    </span>
                                                </button>
                                            </a>
                                        </div>
                                        <div className="col-12 d-flex mt-2 w-100">
                                            <button className="btn btn-sm btn-primary me-2 w-50">
                                                <img
                                                    src={LinkIcon}
                                                    alt=""
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                />
                                            </button>

                                            <div className="dropdown w-100">
                                                <button
                                                    className="btn btn-sm dropdown-toggle text-primary w-50"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    style={{
                                                        backgroundColor:
                                                            '#E9EBEC',
                                                    }}
                                                ></button>
                                                <ul class="dropdown-menu dropdown-menu-end">
                                                    <li>
                                                        <button
                                                            class="dropdown-item"
                                                            type="button"
                                                            onClick={() => {
                                                                handleApplicantStatus(
                                                                    'cancel_interview',
                                                                    applicant?.matchApplicant,
                                                                    applicant?._id
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={CloseIcon}
                                                                className="me-2"
                                                                alt=""
                                                            />{' '}
                                                            Cancel interview
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <hr class="dropdown-divider mx-2" />
                                                    </li>
                                                    <li>
                                                        <button
                                                            class="dropdown-item"
                                                            type="button"
                                                            onClick={() => {
                                                                handleApplicantStatus(
                                                                    'archive_applicant',
                                                                    applicant?.matchApplicant,
                                                                    applicant?._id
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    ArchiveIcon
                                                                }
                                                                className="me-2"
                                                                alt=""
                                                            />{' '}
                                                            Archive Candidate
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {tab === 'Interviews' && applicant?.matchInterview?.data && (
                        <>
                            <div className="col-12 d-flex align-items-center">
                                <div className="me-3">
                                    <div>
                                        <img
                                            src={DateIcon}
                                            alt=""
                                            className="me-2"
                                        />
                                        {moment(
                                            applicant?.matchInterview?.data
                                                ?.start?.dateTime
                                        ).format('MMM DD, YYYY')}
                                    </div>
                                </div>
                                <div className="me-3">
                                    <div>
                                        <img
                                            src={TimeIcon}
                                            alt=""
                                            className="me-2"
                                        />
                                        {moment(
                                            applicant?.matchInterview?.data
                                                ?.start?.dateTime
                                        ).format('hh:mm A')}{' '}
                                        -{' '}
                                        {moment(
                                            applicant?.matchInterview?.data?.end
                                                ?.dateTime
                                        ).format('hh:mm A')}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="">
                                        <img
                                            src={
                                                APP_TYPES?.[
                                                    applicant?.matchInterview?.provider?.toLowerCase()
                                                ]?.icon
                                            }
                                            alt=""
                                            className="me-2"
                                            style={{
                                                width: 30,
                                                height: 30,
                                            }}
                                        />
                                        {
                                            APP_TYPES?.[
                                                applicant?.matchInterview?.provider?.toLowerCase()
                                            ]?.name
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div
                        className={`col-md-7 d-flex flex-column flex-md-row d-none`}
                    >
                        <>
                            <div
                                className={`me-4`}
                                style={{ flexBasis: '15%' }}
                            >
                                <div
                                    className="position-relative"
                                    style={{
                                        width: 80,
                                        height: 80,
                                    }}
                                >
                                    <img
                                        src={
                                            applicant?.matchApplicant
                                                ?.profileUrl ||
                                            profilePlaceholder_Applicant
                                        }
                                        style={{
                                            width: 80,
                                            height: 80,
                                        }}
                                        alt="profile"
                                        className="rounded-circle mb-3"
                                    />
                                    {applicant?.matchApplicant
                                        ?.elevatorPitch && (
                                        <>
                                            <div
                                                className="position-absolute"
                                                style={{
                                                    bottom: -6,
                                                    right: -6,
                                                }}
                                            >
                                                <img
                                                    src={playIcon}
                                                    alt=""
                                                    className="hand"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handlePlayVideo(
                                                            applicant
                                                                ?.matchApplicant
                                                                ?.elevatorPitch
                                                        )
                                                    }
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="me-4" style={{ flexBasis: '35%' }}>
                                <div>
                                    {applicant?.matchStatus === 'waiting' && (
                                        <span
                                            onClick={() => {
                                                console.log(applicant)
                                            }}
                                            className="text-nowrap"
                                            style={{
                                                backgroundColor: '#ffffff',
                                                borderRadius: '12px',
                                                padding: '3px 16px',
                                                color: '#002e5d',
                                                border: '1px solid #002e5d',
                                                fontSize: 12,
                                            }}
                                        >
                                            Awaiting Match
                                        </span>
                                    )}
                                    <h5
                                        className="text-primary mt-2 mb-0 hand"
                                        onClick={() =>
                                            handleView(applicant, index)
                                        }
                                    >
                                        {titleCase(
                                            `${applicant?.matchApplicant?.firstName} ${applicant?.matchApplicant?.lastName}`
                                        )}
                                    </h5>
                                    {applicant?.matchApplicant
                                        ?.preferredTitle && (
                                        <p className="mb-0">
                                            <strong>
                                                {
                                                    applicant?.matchApplicant
                                                        ?.preferredTitle
                                                }
                                            </strong>
                                        </p>
                                    )}
                                    <p className="text-muted mb-2">
                                        {applicant?.matchApplicant?.jobFunction}
                                    </p>
                                </div>
                                {applicant?.matchApplicant?.educationHistory
                                    ?.length > 0 && (
                                    <>
                                        <p className="small">
                                            <img
                                                src={educationIcon}
                                                alt=""
                                                className="me-2"
                                                style={{
                                                    width: 22,
                                                }}
                                            />{' '}
                                            {
                                                applicant?.matchApplicant?.educationHistory?.sort(
                                                    function (a, b) {
                                                        return (
                                                            new Date(
                                                                b.educStartDate
                                                            ) -
                                                            new Date(
                                                                a.educStartDate
                                                            )
                                                        )
                                                    }
                                                )[0]?.educName
                                            }
                                        </p>
                                    </>
                                )}
                                {applicant?.matchApplicant?.currentLocation && (
                                    <>
                                        <p className="small">
                                            <img
                                                src={locationIcon}
                                                alt=""
                                                className="me-2"
                                                style={{
                                                    width: 22,
                                                }}
                                            />{' '}
                                            {
                                                applicant?.matchApplicant
                                                    ?.currentLocation
                                            }
                                        </p>
                                    </>
                                )}
                                {getYearsOfExp(applicant) &&
                                    getYearsOfExp(applicant) !== null && (
                                        <p className="small">
                                            <img
                                                src={experienceIcon}
                                                alt=""
                                                className="me-2"
                                                style={{
                                                    width: 22,
                                                }}
                                            />{' '}
                                            {`${getYearsOfExp(
                                                applicant
                                            )} Years of Experience`}
                                        </p>
                                    )}
                                {applicant?.matchApplicant?.salaryMin &&
                                    applicant?.matchApplicant?.salaryMin !==
                                        null && (
                                        <p className="small">
                                            <img
                                                src={salaryIcon}
                                                alt=""
                                                className="me-2"
                                                style={{
                                                    width: 22,
                                                }}
                                            />{' '}
                                            {applicant?.matchApplicant
                                                ?.salaryMin === 1 &&
                                            applicant?.matchApplicant
                                                ?.salaryMax === 1
                                                ? salaryRanges.COMPETIIVE_SALARY
                                                : applicant?.matchApplicant
                                                      ?.salaryMin === 2 &&
                                                  applicant?.matchApplicant
                                                      ?.salaryMax === 2
                                                ? salaryRanges.INDUSTRY_STANDARD
                                                : `${formatMoney(
                                                      applicant?.matchApplicant
                                                          ?.salaryMin,
                                                      currencyOptions,
                                                      applicant?.matchApplicant
                                                          ?.salaryCurrency ||
                                                          'SGD'
                                                  )} - ${formatMoney(
                                                      applicant?.matchApplicant
                                                          ?.salaryMax,
                                                      currencyOptions,
                                                      applicant?.matchApplicant
                                                          ?.salaryCurrency ||
                                                          'SGD'
                                                  )}`}
                                        </p>
                                    )}
                            </div>
                            <div style={{ flexBasis: '60%' }}>
                                {applicant?.matchApplicant?.skills?.length >
                                    0 && (
                                    <>
                                        <div className="mb-3 mt-3">
                                            <div className="d-flex mb-2">
                                                <div className="me-3">
                                                    <Icon
                                                        path={mdiTools}
                                                        size={1.2}
                                                        color={'#d7a048'}
                                                    />
                                                </div>
                                                <div>
                                                    <h6 className="mb-2 text-primary">
                                                        Technical Skills
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                {applicant?.matchApplicant?.skills
                                                    ?.slice(0, 5)
                                                    .map((skill) => {
                                                        return (
                                                            <span
                                                                style={{
                                                                    backgroundColor:
                                                                        '#009CDE',
                                                                    borderRadius:
                                                                        '12px',
                                                                    padding:
                                                                        '0 16px',
                                                                    color: '#ffffff',
                                                                    fontSize: 12,
                                                                }}
                                                                className="me-2 mb-1 text-nowrap"
                                                            >
                                                                {skill}
                                                            </span>
                                                        )
                                                    })}
                                                {applicant?.matchApplicant
                                                    ?.skills?.length > 5 && (
                                                    <>
                                                        <span
                                                            style={{
                                                                backgroundColor:
                                                                    '#009CDE',
                                                                borderRadius:
                                                                    '12px',
                                                                padding:
                                                                    '0 16px',
                                                                color: '#ffffff',
                                                                fontSize: 12,
                                                            }}
                                                            className="me-2 mb-1 text-nowrap"
                                                        >
                                                            +{' '}
                                                            {applicant
                                                                ?.matchApplicant
                                                                ?.skills
                                                                ?.length -
                                                                5}{' '}
                                                            more
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                            <span
                                                className="hand"
                                                onClick={() => {
                                                    handleView(applicant, index)
                                                }}
                                                style={{
                                                    color: '#009CDE!important',
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                <small>See More</small>
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    </div>
                    <div className="col-md-5  d-none">
                        {tab === 'Applied' && (
                            <div className="text-center text-md-end d-md-block">
                                <img
                                    src={DismissIcon}
                                    alt="icon"
                                    role="button"
                                    onClick={() => {
                                        swipeAction({
                                            job: applicant?.matchJob,
                                            applicant:
                                                applicant?.matchApplicant,
                                            action: defaultSwipeActions.left,
                                            itemId: index,
                                        })
                                    }}
                                />

                                <img
                                    src={SuperLikeIcon}
                                    alt="icon"
                                    role="button"
                                    className="mx-3"
                                    onClick={() => {
                                        swipeAction({
                                            job: applicant?.matchJob,
                                            applicant:
                                                applicant?.matchApplicant,
                                            action: defaultSwipeActions.top,
                                            itemId: index,
                                        })
                                    }}
                                />
                                <img
                                    src={LikeIcon}
                                    alt="icon"
                                    role="button"
                                    onClick={() => {
                                        swipeAction({
                                            job: applicant?.matchJob,
                                            applicant:
                                                applicant?.matchApplicant,
                                            action: defaultSwipeActions.right,
                                            itemId: index,
                                        })
                                    }}
                                />
                            </div>
                        )}

                        {tab === 'Interviews' && (
                            <div className="">
                                <div className="row">
                                    <div className="col-6">
                                        <div>
                                            <img
                                                src={DateIcon}
                                                alt=""
                                                className="me-2"
                                            />
                                            {moment(
                                                applicant?.matchInterview?.data
                                                    ?.start?.dateTime
                                            ).format('MMM DD, YYYY')}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <img
                                                src={TimeIcon}
                                                alt=""
                                                className="me-2"
                                            />
                                            {moment(
                                                applicant?.matchInterview?.data
                                                    ?.start?.dateTime
                                            ).format('hh:mm A')}{' '}
                                            -{' '}
                                            {moment(
                                                applicant?.matchInterview?.data
                                                    ?.end?.dateTime
                                            ).format('hh:mm A')}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="">
                                            <img
                                                src={
                                                    APP_TYPES?.[
                                                        applicant?.matchInterview?.provider?.toLowerCase()
                                                    ]?.icon
                                                }
                                                alt=""
                                                className="me-2"
                                                style={{
                                                    width: 30,
                                                    height: 30,
                                                }}
                                            />
                                            {
                                                APP_TYPES?.[
                                                    applicant?.matchInterview?.provider?.toLowerCase()
                                                ]?.name
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 d-flex">
                                        <a
                                            href={
                                                applicant?.matchInterview?.data
                                                    ?.hangoutLink ||
                                                applicant?.matchInterview?.data
                                                    ?.htmlLink
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <button className="btn btn-sm btn-primary me-2 px-5 rounded">
                                                <img
                                                    src={PhoneIcon}
                                                    alt=""
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                />{' '}
                                                <span
                                                    className="ms-2"
                                                    style={{
                                                        fontSize: 15,
                                                    }}
                                                >
                                                    {' '}
                                                    Join Call
                                                </span>
                                            </button>
                                        </a>

                                        <button className="btn btn-sm btn-primary me-2">
                                            <img
                                                src={LinkIcon}
                                                alt=""
                                                style={{
                                                    width: 15,
                                                    height: 15,
                                                }}
                                            />
                                        </button>

                                        <div className="dropdown">
                                            <button
                                                className="btn btn-sm dropdown-toggle text-primary"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{
                                                    backgroundColor: '#E9EBEC',
                                                }}
                                            ></button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button
                                                        class="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            handleApplicantStatus(
                                                                'cancel_interview',
                                                                applicant?.matchApplicant,
                                                                applicant?._id
                                                            )
                                                        }}
                                                    >
                                                        <img
                                                            src={CloseIcon}
                                                            className="me-2"
                                                            alt=""
                                                        />{' '}
                                                        Cancel interview
                                                    </button>
                                                </li>
                                                <li>
                                                    <hr class="dropdown-divider mx-2" />
                                                </li>
                                                <li>
                                                    <button
                                                        class="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            handleApplicantStatus(
                                                                'archive_applicant',
                                                                applicant?.matchApplicant,
                                                                applicant?._id
                                                            )
                                                        }}
                                                    >
                                                        <img
                                                            src={ArchiveIcon}
                                                            className="me-2"
                                                            alt=""
                                                        />{' '}
                                                        Archive Candidate
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {tab === 'Shortlisted' && (
                            <>
                                <div className="d-flex flex-column justify-content-end">
                                    <div className="text-center text-md-end mt-2 mt-md-0">
                                        <button
                                            className="btn btn-primary me-2 mb-2 text-center"
                                            style={{
                                                width: 220,
                                            }}
                                            onClick={() =>
                                                handleShow(applicant)
                                            }
                                            disabled={
                                                applicant?.matchStatus ===
                                                'waiting'
                                            }
                                        >
                                            <img
                                                src={UnionIcon}
                                                className="img-fluid me-2"
                                                alt=""
                                            />{' '}
                                            Schedule Interview
                                        </button>
                                    </div>
                                    <div className="text-center text-md-end mt-2 mt-md-0">
                                        <button
                                            className="btn btn-outline-primary nh mb-2 me-2 text-center"
                                            style={{
                                                width: 220,
                                                color: '#002e5d',
                                            }}
                                            onClick={() =>
                                                handleGoToMessage(applicant)
                                            }
                                            disabled={
                                                applicant?.matchStatus ===
                                                'waiting'
                                            }
                                        >
                                            <img
                                                src={MessageIcon}
                                                className="img-fluid me-2"
                                                alt=""
                                            />
                                            Send Messages
                                        </button>
                                    </div>
                                    <div className="text-center text-md-end mt-mt-md-0">
                                        <button
                                            className="btn btn-outline-danger me-2 mb-2 text-center"
                                            style={{
                                                width: 220,
                                            }}
                                            onClick={() => {
                                                handleApplicantStatus(
                                                    'archive_applicant',
                                                    applicant?.matchApplicant,
                                                    applicant?._id
                                                )
                                            }}
                                            disabled={
                                                applicant?.matchStatus ===
                                                'waiting'
                                            }
                                        >
                                            <img
                                                src={DeleteIcon}
                                                className="img-fluid me-2"
                                                alt=""
                                                style={{
                                                    height: 19,
                                                }}
                                            />{' '}
                                            Archive Candidate
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex d-none">
                        <div>
                            <ElevatorPitch
                                jobseeker={applicant}
                                hasSwipeActions={true}
                            />
                        </div>
                        <div className="ms-3">
                            <h5 className="text-primary mb-0">
                                {applicant?.matchApplicant?.firstName}{' '}
                                {applicant?.matchApplicant?.lastName}
                            </h5>
                            {applicant?.matchApplicant?.preferredTitle && (
                                <p className=" mb-0">
                                    <strong>
                                        {
                                            applicant?.matchApplicant
                                                ?.preferredTitle
                                        }
                                    </strong>
                                </p>
                            )}
                            <p className="text-muted mb-0 mt-2">
                                {applicant?.matchApplicant?.jobFunction}
                            </p>
                            <div>
                                {applicant?.matchApplicant?.overseasExp && (
                                    <span className="badge rounded-pill text-dark fs13 px-2 me-1">
                                        Has Overseas Experience
                                    </span>
                                )}
                                {applicant?.matchApplicant?.singaporeExp && (
                                    <span className="badge rounded-pill text-dark fs13 px-2 me-1">
                                        Has Singapore Experience
                                    </span>
                                )}
                                {applicant?.matchApplicant?.multiSkills && (
                                    <span className="badge rounded-pill text-dark fs13 px-2 me-1">
                                        Has Multi Skills
                                    </span>
                                )}
                            </div>
                            <div className="mt-3 d-flex flex-column flex-md-row mb-3 mb-md-0">
                                <div className="d-flex align-items-center me-4">
                                    <div>
                                        <img
                                            src={locationIcon}
                                            className="h-24 me-2"
                                            alt=""
                                        />
                                    </div>
                                    <div className="">
                                        <p className="small mb-0">
                                            Based in{' '}
                                            {
                                                applicant?.matchApplicant
                                                    ?.currentLocation
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <img
                                            src={jobIcon}
                                            className="h-24 me-2"
                                            alt=""
                                        />
                                    </div>
                                    <div className="">
                                        <p className="small mb-0">
                                            {applicant?.matchApplicant
                                                ?.totalExperienceMin ||
                                            applicant?.matchApplicant
                                                ?.totalExperienceMax ? (
                                                <>
                                                    {
                                                        applicant
                                                            ?.matchApplicant
                                                            ?.totalExperienceMin
                                                    }{' '}
                                                    -{' '}
                                                    {
                                                        applicant
                                                            ?.matchApplicant
                                                            ?.totalExperienceMax
                                                    }{' '}
                                                    Years of Experience.
                                                </>
                                            ) : (
                                                <span>No work experience</span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 d-none"></div>
                </div>
            </>
        )
    }
    return (
        <>
            <ConfirmModal
                selectedApplicant={selectedApplicant}
                showConfirmModal={showConfirmModal}
                handleCloseConfirmModal={handleCloseConfirmModal}
                modalHeader={modalHeader}
                modalType={modalType}
                archiveApplicant={archiveApplicant}
                cancelInterview={cancelInterview}
                hireApplicant={hireApplicant}
                unmatchApplicant={unmatchApplicant}
                selectedJob={selectedJob}
                jobTitle={jobTitle}
                selectedJobData={selectedJobData}
                moveToInterview={moveToInterview}
            />

            {/* @JUDE PREMIUM MODAL*/}
            <Dialog
                open={showPremiumModal}
                fullWidth
                maxWidth={'sm'}
                onClose={() => {
                    setShowPremiumModal(false)
                }}
                style={{ zIndex: '99999999' }}
            >
                <Box
                    style={{
                        background: `url('${premiumBg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            textAlign: 'right',
                            padding: '0.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={CloseWhite}
                            width={36}
                            onClick={() => {
                                setShowPremiumModal(false)
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            padding: '0 3rem 3rem 3rem',
                        }}
                    >
                        <JobluTypography.H1
                            semiBold
                            style={{ marginBottom: '1rem' }}
                        >
                            Do you want to unmatch?
                        </JobluTypography.H1>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 20 }}
                        >
                            Our premium plan offers candidate unmatching!
                        </JobluTypography.H4>
                        <JobluTypography.H6
                            semiBold
                            style={{ marginBottom: 20, color: '#D7A048' }}
                        >
                            Purchase a plan now and start unmatching applicants!
                        </JobluTypography.H6>
                        <Button
                            variant="contained"
                            style={{
                                background: '#D7A048',
                                color: '#fff',
                                padding: '1rem 3rem',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                navigate('/pricing')
                            }}
                        >
                            Get Premium
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            <JobluVideoPlayer
                src={videoSrc}
                play={playVideo}
                setPlayVideo={setPlayVideo}
            />
            {/* <ConfirmModal
                showConfirmModal={showConfirmModal}
                handleCloseConfirmModal={handleCloseConfirmModal}
                confirmModalText={confirmModalText}
                confirmAction={confirmAction}
                archiveApplicant={archiveApplicant}
                cancelInterview={cancelInterview}
                selectedApplicant={selectedApplicant}
            /> */}
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
                seeker={applicant}
                tab={tab}
                match={match}
                data={match}
                isFromMatches
                getApplicants={getApplicants}
                defaultSwipeActions={defaultSwipeActions}
                itemId={itemId}
                swipeAction={swipeAction}
                hasSwipeActions={tab == 'Applied' ? true : false}
                // isFromExplore={isFromExplore}
            />
            <ScheduleInterviewModal
                show={!!show}
                job_match={show}
                handleClose={handleClose}
                setShow={setShow}
                getApplicants={getApplicants}
            />
            <div
                id="archive"
                role="tabpanel"
                aria-labelledby="archive-tab"
                className="slide-wrapper-bottom"
            >
                <FlatList
                    list={applicants}
                    renderItem={renderApplicants}
                    renderWhenEmpty={() =>
                        !isLoading && (
                            <div
                                className="w-100 h-100 d-flex flex-column justify-content-center"
                                style={{ minHeight: 400 }}
                            >
                                <div className="m-auto text-center">
                                    {getContents(tab)}
                                    <div className="d-flex mt-5">
                                        <button
                                            className="mx-auto btn btn-outline-primary text-center"
                                            onClick={() => {
                                                navigate(
                                                    `/dashboard?job=${selectedJob}`
                                                )
                                            }}
                                        >
                                            Explore
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    pagination={{
                        loadingIndicator: (
                            <div
                                className="w-100"
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <SkeletonLoading
                                    count={1}
                                    style={{
                                        height: 5,
                                        background: '#cfcfcf',
                                    }}
                                />
                            </div>
                        ),
                    }}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
    }
}
export default connect(mapStateToProps, null)(Applicants)
