import {
    Accordion,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
} from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CheckIcon from '@material-ui/icons/Check'
import { AccordionDetails } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DefaultImg from '../../assets/img/defaultImg.png'
import AccessLevelIcon from '../../assets/img/settings/AccessLevelIcon.png'
import BillingIcon from '../../assets/img/settings/BillingIcon.png'
import ContactIcon from '../../assets/img/settings/ContactIcon.png'
import DocumentIcon from '../../assets/img/settings/DocumentIcon.png'
import EditIcon from '../../assets/img/settings/EditIcon.png'
import EmailIcon from '../../assets/img/settings/EmailIcon.png'
import LogoutIcon from '../../assets/img/settings/LogoutIcon.png'
import PricingIcon from '../../assets/img/settings/PricingIcon.png'
import MeetLogo from '../../assets/img/settings/meetLogo.png'
import MSoft from '../../assets/img/settings/microsoft.png'
import SkypeLogo from '../../assets/img/settings/skype.png'
import TeamsLogo from '../../assets/img/settings/teams.png'
import ZoomLogo from '../../assets/img/settings/zoomLogo.png'
import JobluTypography from '../../components/Typography'
import TextField from '../../components/inputs/CustomTextField'
import Hoverable from '../../components/reusables/Hoverable/Hoverable'
import { app_styles } from '../../general.styles'
import rootAction from '../../redux/action'
import { store } from '../../redux/store'
import userActions from '../../redux/user/actions'
import {
    calendarRequest,
    paymentRequests,
    userRequest,
} from '../../service/requests'
import { BEARER, baseURL } from '../../utils/constants'
import {
    calculateSubscriptionPerMonth,
    get_pricing_currency,
    thousendSeparator,
    titleCase,
} from '../../utils/helpers'
import useWindowDimensions from '../../utils/hooks'
import ContactUs from '../contact-us'
import EditProfile from '../edit-profile/EditProfile'
import MyAccount from '../my-account/MyAccount'

export const APP_TYPES = {
    google: {
        name: 'Google Meet',
        provider: 'google',
        icon: MeetLogo,
        id: 'google',
    },
    microsoft: {
        icon: MSoft,
        name: 'Microsoft',
        provider: 'microsoft',
        id: 'microsoft',
    },
    zoom: {
        name: 'Zoom ',
        provider: 'zoom',
        icon: ZoomLogo,
        id: 'zoom',
    },
    teams: {
        name: 'Microsoft Teams',
        provider: 'microsoft',
        icon: TeamsLogo,
        id: 'teams',
    },
    skype: {
        name: 'Skype',
        provider: 'microsoft',
        icon: SkypeLogo,
        id: 'skype',
    },
}

export const ConnectedApps = ({
    isSettings = true,
    onAppClick,
    appSelected,
}) => {
    const { width } = useWindowDimensions()
    const [authURLS, setAuthUrls] = useState({})
    const [selected_app_details, setSelectedApp] = useState(null)
    const [verified_apps, setVerifiedApps] = useState([])
    const [available_provider, setAvailableApps] = useState({
        google: false,
        microsoft: false,
        zoom: false,
    })
    const [connected_apps, setConnectedApps] = useState({
        google: false,
        microsoft: false,
        teams: false,
        skype: false,
        zoom: false,
    })
    const [showRemove, setShowRemove] = useState(null)
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        let available_for_user = []
        Object.keys(APP_TYPES).forEach((key) => {
            const app = APP_TYPES[key]

            if (!isSettings) {
                // not profile settings
                if (connected_apps?.microsoft && app?.id === 'microsoft') {
                } else {
                    if (
                        connected_apps?.[app.id] &&
                        app.id === 'skype' &&
                        connected_apps?.teams === true
                    ) {
                        //do not push skype
                    }
                    if (connected_apps?.[app.id]) {
                        available_for_user.push(app)
                    }

                    if (
                        !connected_apps[app?.id] &&
                        available_provider[app?.provider]
                    ) {
                        console.log('provider not connected')
                        if (app.id !== 'teams' && app.id !== 'skype') {
                            available_for_user.push(app)
                        } else {
                        }
                    }
                }
            } else {
                //SETTINGS CONFIG
                if (
                    connected_apps?.[app.id] &&
                    app.id === 'skype' &&
                    connected_apps?.teams === true
                ) {
                    //do not push skype
                }
                if (connected_apps?.[app.id]) {
                    available_for_user.push(app)
                }

                if (
                    !connected_apps[app?.id] &&
                    available_provider[app?.provider]
                ) {
                    console.log('provider not connected')
                    if (app.id !== 'teams' && app.id !== 'skype') {
                        available_for_user.push(app)
                    } else {
                    }
                }
            }
        })

        if (connected_apps.teams === false && connected_apps.skype === false) {
            available_for_user.push(APP_TYPES.microsoft)
        }
        let temp = _.uniqBy(available_for_user, 'id')
        setVerifiedApps(temp)
    }, [connected_apps, available_provider])

    useEffect(() => {
        if (
            connected_apps.teams === false &&
            connected_apps.skype === false &&
            connected_apps.microsoft === true
        ) {
            setConnectedApps({ ...connected_apps, microsoft: false })
        }
    }, [connected_apps])

    const get_connected_apps = () => {
        calendarRequest.getCalendarConnections().then((res) => {
            console.log('connections', res.data)
            setConnectedApps(res.data)
            //setConnectedApps({ ...res.data, skype: false, teams: false }) // test both skype and teams as false
        })
    }
    useEffect(() => {
        calendarRequest.getCalendarAuthUrls().then((res) => {
            console.log(res.data)
            setAuthUrls(res.data)
        })
        calendarRequest.getAvailableProvider().then((res) => {
            console.log('available', res.data)
            setAvailableApps(res.data)
        })
        get_connected_apps()
    }, [])

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code')
        //const scope = new URLSearchParams(location.search).get('scope')
        setSelectedApp({ code, provider: params?.type?.toUpperCase() })
    }, [params, location])

    useEffect(() => {
        console.log('selected_app_details', selected_app_details)

        if (selected_app_details?.code && selected_app_details?.provider) {
            calendarRequest
                .connectCalendarIntegration(selected_app_details)
                .then((res) => {
                    console.log(res.data)
                    window.close()
                })
        }
    }, [selected_app_details])

    const handleDisconnect = () => {
        calendarRequest
            .removeCalendarIntegration(showRemove?.provider?.toUpperCase())
            .then(() => {
                get_connected_apps()
            })
            .finally(() => {
                setShowRemove(null)
            })
    }

    const handleClick = (thrid_party_app = null) => {
        if (isSettings) {
            if (connected_apps?.[thrid_party_app?.provider] === false) {
                const win = window
                const h = 700
                const w = 500
                const y = win.top.innerHeight / 2 + win.top.screenY - h / 2
                const x = win.screen.availWidth / 2 + win.top.screenX - w / 2
                const test = window.open(
                    authURLS?.[
                        ['skype', 'microsoft', 'teams'].includes(
                            thrid_party_app?.provider
                        )
                            ? 'outlook'
                            : thrid_party_app?.provider
                    ],
                    'connect',
                    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
                )
                const timer = setInterval(() => {
                    if (test.closed) {
                        clearInterval(timer)
                        get_connected_apps()
                    }
                }, 500)
            } else {
                console.log('show app settings')
            }
        } else {
            if (connected_apps?.[thrid_party_app.provider]) {
                onAppClick && onAppClick(thrid_party_app)
            } else {
                const win = window
                const h = 700
                const w = 500
                const y = win.top.innerHeight / 2 + win.top.screenY - h / 2
                const x = win.screen.availWidth / 2 + win.top.screenX - w / 2
                const test = window.open(
                    authURLS?.[
                        ['skype', 'microsoft', 'teams'].includes(
                            thrid_party_app?.provider
                        )
                            ? 'outlook'
                            : thrid_party_app?.provider
                    ],
                    'connect',
                    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
                )
                const timer = setInterval(() => {
                    if (test.closed) {
                        clearInterval(timer)
                        get_connected_apps()
                    }
                }, 500)
            }
        }
    }
    return (
        <>
            <div className="">
                <div className="p-4">
                    <div className="d-flex flex-wrap ">
                        {verified_apps.map((thrid_party_app, index) => {
                            return (
                                <div
                                    className={`me-3 text-center hand border p-5 mb-3 ${
                                        width < 640 ? 'w-100' : 'w-auto'
                                    }`}
                                    onClick={() => {
                                        handleClick(thrid_party_app)
                                    }}
                                    style={{
                                        border:
                                            appSelected === thrid_party_app &&
                                            '3px solid #002E5D',
                                        borderRadius: '5px',
                                        padding: '8px',
                                    }}
                                >
                                    <Hoverable
                                        renderOnHover={() => {
                                            if (
                                                !connected_apps?.[
                                                    thrid_party_app.provider
                                                ]
                                            ) {
                                                return null
                                            }
                                            if (
                                                thrid_party_app?.id ===
                                                    'skype' ||
                                                thrid_party_app?.id === 'teams'
                                            )
                                                return null
                                            if (!isSettings) return null
                                            return (
                                                <div
                                                    className="position-absolute"
                                                    style={{
                                                        right: -5,
                                                        top: -15,
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        fill="red"
                                                        class="bi bi-x-circle-fill"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => {
                                                            setShowRemove(
                                                                thrid_party_app
                                                            )
                                                        }}
                                                    >
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                    </svg>
                                                </div>
                                            )
                                        }}
                                        maintainChildren
                                    >
                                        <img
                                            src={thrid_party_app.icon}
                                            class="mb-1"
                                            alt=""
                                            style={{
                                                width: isSettings ? 70 : 60,
                                                height: isSettings ? 70 : 60,
                                                objectFit: 'contain',
                                                opacity:
                                                    !connected_apps?.[
                                                        thrid_party_app.provider
                                                    ] ||
                                                    (thrid_party_app?.id ===
                                                        'microsoft' &&
                                                        connected_apps.teams ===
                                                            false &&
                                                        connected_apps.skype ===
                                                            false &&
                                                        available_provider.microsoft)
                                                        ? 0.5
                                                        : 1,
                                            }}
                                        />
                                        <p>{thrid_party_app?.name}</p>
                                    </Hoverable>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* {
            !isSettings && (!connected_apps?.google && !connected_apps?.microsoft)
            &&  <a href="#" style={{marginLeft: 20, marginTop: -20}} onClick={() => navigate('/settings')}><h6 className="">Connect Apps</h6></a>
            } */}
            </div>

            <Modal
                centered
                show={showRemove}
                onHide={() => {
                    setShowRemove(null)
                }}
            >
                <div className="px-4 py-4 open-sans">
                    <div className="d-flex justify-content-center mb-3">
                        <img
                            src={showRemove?.icon}
                            alt=""
                            style={{ width: 70, height: 70 }}
                        />
                    </div>
                    <h4 className="text-center mb-5">
                        {' '}
                        Are you sure you want to disconnect {
                            showRemove?.name
                        }{' '}
                        from Joblu?
                    </h4>

                    <div className="d-flex justify-content-center">
                        <button
                            className="btn btn-primary"
                            style={{ width: 100, marginRight: 20 }}
                            onClick={() => {
                                setShowRemove(null)
                            }}
                        >
                            {' '}
                            No{' '}
                        </button>
                        <button
                            className="btn btn-outline-primary text-center"
                            style={{ width: 100 }}
                            onClick={() => {
                                handleDisconnect()
                            }}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const UserEdit = () => {
    const classes = app_styles()
    return (
        <>
            <Box>
                <Grid container justifyContent="center">
                    <Grid xs={8} item className={`${classes.margin.y5} `}>
                        <Box className={`${classes.text.center}`}>
                            <img
                                className="mb-5"
                                src={DefaultImg}
                                width={150}
                                height={150}
                                style={{
                                    objectFit: 'cover',
                                    borderRadius: '100px',
                                    border: '5px solid #009CDE',
                                }}
                            />
                        </Box>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <JobluTypography.Body primary semiBold>
                                        First Name
                                    </JobluTypography.Body>
                                    <TextField
                                        type="text"
                                        className=" mb-3"
                                        placeholder="First Name"
                                        // onChange={(e) =>
                                        //     setLoginDetails({
                                        //         ...loginDetails,
                                        //         lastName:
                                        //             e.target.value,
                                        //     })
                                        // }
                                        // error={errors?.lastName}
                                        // defaultValue={
                                        //     signupWithGoogle
                                        //         ? signupWithGoogle
                                        //               ?.profileObj
                                        //               ?.familyName
                                        //         : loginDetails?.lastName
                                        // }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <JobluTypography.Body primary semiBold>
                                        Last Name
                                    </JobluTypography.Body>
                                    <TextField
                                        type="text"
                                        className=" mb-3"
                                        placeholder="Last Name"
                                        // onChange={(e) =>
                                        //     setLoginDetails({
                                        //         ...loginDetails,
                                        //         lastName:
                                        //             e.target.value,
                                        //     })
                                        // }
                                        // error={errors?.lastName}
                                        // defaultValue={
                                        //     signupWithGoogle
                                        //         ? signupWithGoogle
                                        //               ?.profileObj
                                        //               ?.familyName
                                        //         : loginDetails?.lastName
                                        // }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <JobluTypography.Body primary semiBold>
                                        Email Address
                                    </JobluTypography.Body>
                                    <TextField
                                        type="text"
                                        className=" mb-3"
                                        placeholder="Email Address"
                                        // onChange={(e) =>
                                        //     setLoginDetails({
                                        //         ...loginDetails,
                                        //         lastName:
                                        //             e.target.value,
                                        //     })
                                        // }
                                        // error={errors?.lastName}
                                        // defaultValue={
                                        //     signupWithGoogle
                                        //         ? signupWithGoogle
                                        //               ?.profileObj
                                        //               ?.familyName
                                        //         : loginDetails?.lastName
                                        // }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <JobluTypography.Body primary semiBold>
                                        User Role
                                    </JobluTypography.Body>
                                    <TextField
                                        type="text"
                                        className=" mb-3"
                                        placeholder="User Role"
                                        // onChange={(e) =>
                                        //     setLoginDetails({
                                        //         ...loginDetails,
                                        //         lastName:
                                        //             e.target.value,
                                        //     })
                                        // }
                                        // error={errors?.lastName}
                                        // defaultValue={
                                        //     signupWithGoogle
                                        //         ? signupWithGoogle
                                        //               ?.profileObj
                                        //               ?.familyName
                                        //         : loginDetails?.lastName
                                        // }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <JobluTypography.Body primary semiBold>
                                        Contact Number
                                    </JobluTypography.Body>
                                    <TextField
                                        type="text"
                                        className=" mb-3"
                                        placeholder="Contact Number"
                                        // onChange={(e) =>
                                        //     setLoginDetails({
                                        //         ...loginDetails,
                                        //         lastName:
                                        //             e.target.value,
                                        //     })
                                        // }
                                        // error={errors?.lastName}
                                        // defaultValue={
                                        //     signupWithGoogle
                                        //         ? signupWithGoogle
                                        //               ?.profileObj
                                        //               ?.familyName
                                        //         : loginDetails?.lastName
                                        // }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className={`${classes.text.right}`}
                                >
                                    <Box
                                        className={` ${classes.display.display_flex} ${classes.flexBox.justify_content_right} ${classes.flexBox.align_items_center}`}
                                    >
                                        <Switch
                                            // checked={state.checkedB}
                                            // onChange={handleChange}
                                            color="primary"
                                            name="checkedB"
                                        />
                                        <JobluTypography.Body primary semiBold>
                                            Activate User
                                        </JobluTypography.Body>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={`${classes.text.right} mt-5`}>
                            <Button
                                variant="outlined"
                                color="error"
                                className="me-2"
                                style={{
                                    color: '#EB5757',
                                    borderColor: '#EB5757',
                                    textTransform: 'none',
                                }}
                            >
                                Delete User
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className="me-2"
                                style={{ textTransform: 'none' }}
                            >
                                Change Password
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className="me-2"
                                style={{ textTransform: 'none' }}
                            >
                                Revert Changes
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: 'none' }}
                            >
                                Save Changes
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const AppIntegrations = (props) => {
    const { User } = props
    return (
        <>
            <Box className="p-4">Test</Box>
        </>
    )
}

const ChangePassword = (props) => {
    const { User, signOut } = props

    const navigate = useNavigate()

    const handleLogout = () => {
        try {
            userRequest
                .signOut({ token: User?.token })
                .then((res) => {
                    signOut()
                })
                .finally(() => {
                    setTimeout(() => {
                        navigate('/login')
                    }, 500)
                })
        } catch (err) {
            console.log(err)
        }
    }

    const [isUpdating, setIsUpdating] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const [currentPassword, setCurrentPassword] = useState(null)
    const [errorCurrentPassword, setError_CurrentPassword] = useState(null)

    const [newPassword, setNewPassword] = useState(null)
    const [errorNewPassword, setError_NewPassword] = useState(null)

    const [confirmNewPassword, setConfirmNewPassword] = useState(null)
    const [errorConfirmNewPassword, setError_ConfirmNewPassword] =
        useState(null)

    const [alert, setAlert] = useState(null)

    useEffect(() => {
        if (currentPassword) setError_CurrentPassword(null)
        if (currentPassword) {
            if (currentPassword.length < 8) {
                setError_CurrentPassword(
                    'Current password should be atleast 8 characters'
                )
            } else {
                setError_CurrentPassword(null)
            }
        }
    }, [currentPassword])

    useEffect(() => {
        if (newPassword) setError_NewPassword(null)
        if (newPassword) {
            if (newPassword.length < 8) {
                setError_NewPassword(
                    'New password should be atleast 8 characters'
                )
            } else {
                setError_NewPassword(null)
            }
        }
    }, [newPassword])

    useEffect(() => {
        if (confirmNewPassword) setError_ConfirmNewPassword(null)
        if (confirmNewPassword) {
            if (confirmNewPassword != newPassword) {
                setError_ConfirmNewPassword('Passwords do not match')
            } else {
                setError_ConfirmNewPassword(null)
            }
        }
    }, [confirmNewPassword])

    const validateFields = () => {
        let isValid = []

        if (currentPassword) setError_CurrentPassword(null)
        if (currentPassword) {
            if (currentPassword.length < 8) {
                setError_CurrentPassword(
                    'Current password should be atleast 8 characters'
                )
                isValid.push(false)
            } else {
                setError_CurrentPassword(null)
                isValid.push(true)
            }
        }

        if (newPassword) setError_NewPassword(null)
        if (newPassword) {
            if (newPassword.length < 8) {
                setError_NewPassword(
                    'New password should be atleast 8 characters'
                )
                isValid.push(false)
            } else {
                setError_NewPassword(null)
                isValid.push(true)
            }
        }
        if (confirmNewPassword) setError_ConfirmNewPassword(null)
        if (confirmNewPassword) {
            if (confirmNewPassword != newPassword) {
                setError_ConfirmNewPassword('Passwords do not match')
                isValid.push(false)
            } else {
                setError_ConfirmNewPassword(null)
                isValid.push(true)
            }
        }

        if (confirmNewPassword != newPassword) {
            setError_ConfirmNewPassword('Passwords do not match')
            isValid.push(false)
        } else {
            setError_ConfirmNewPassword(null)
            isValid.push(true)
        }
        return isValid.includes(false) ? false : true
    }
    const changePassword = () => {
        const valid = validateFields()

        if (!valid) {
            return
        }

        const isErrorCurrentPassword =
            currentPassword === null || currentPassword === ''
        setError_CurrentPassword(
            isErrorCurrentPassword ? 'Current password is required' : null
        )

        const isErrorNewPassword = newPassword === null || newPassword === ''
        setError_NewPassword(
            isErrorNewPassword ? 'New password is required' : null
        )

        const isErrorNewPassord_ = currentPassword === newPassword
        setError_NewPassword(
            isErrorNewPassord_ ? 'You cannot use your old password' : null
        )

        if (
            isErrorCurrentPassword ||
            isErrorNewPassword ||
            isErrorNewPassord_
        ) {
            return
        }

        if (
            errorCurrentPassword !== null ||
            errorNewPassword !== null ||
            errorConfirmNewPassword !== null
        ) {
            return
        }

        setIsUpdating(true)

        const token = store.getState().User.token

        axios
            .post(
                `${baseURL}/users/change-password`,
                {
                    oldPassword: currentPassword,
                    newPassword: newPassword,
                },
                { headers: { Authorization: BEARER + token } }
            )
            .then((res) => {
                setCurrentPassword('')
                setNewPassword('')
                setConfirmNewPassword('')
                setIsSuccessful(true)
                setIsUpdating(false)
                setAlert('Successfully changed password!')

                setTimeout(() => {
                    setIsSuccessful(false)
                    // handleLogout()
                }, 2000)
            })
            .catch((err) => {
                if (err.response.data.message === '401001: Invalid credentials')
                    setError_CurrentPassword('Invalid current password')
                setIsUpdating(false)
            })
    }
    return (
        <>
            <Box className="my-5">
                <Grid container justifyContent="center">
                    <Grid item xs={10} md={4}>
                        <Box className="mb-3">
                            <JobluTypography.Body primary semiBold>
                                Current Password
                            </JobluTypography.Body>
                            <TextField
                                onChange={(e) =>
                                    setCurrentPassword(e?.target?.value)
                                }
                                value={currentPassword}
                                className="mb-3"
                                type={'password'}
                                error={errorCurrentPassword}
                            />
                        </Box>
                        <Box className="mb-3">
                            <JobluTypography.Body primary semiBold>
                                New Password
                            </JobluTypography.Body>
                            <TextField
                                onChange={(e) =>
                                    setNewPassword(e?.target?.value)
                                }
                                value={newPassword}
                                className="mb-3"
                                type={'password'}
                                error={errorNewPassword}
                            />
                        </Box>
                        <Box className="mb-3">
                            <JobluTypography.Body primary semiBold>
                                Confirm Password
                            </JobluTypography.Body>
                            <TextField
                                onChange={(e) =>
                                    setConfirmNewPassword(e?.target?.value)
                                }
                                value={confirmNewPassword}
                                className="mb-3"
                                type={'password'}
                                error={errorConfirmNewPassword}
                            />
                        </Box>
                        <Box className="text-center">
                            <button
                                onClick={() => changePassword()}
                                className="btn btn-primary mt-2 px-4"
                                id="savechanges"
                            >
                                Save changes
                            </button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
const AccessLevel = () => {
    return (
        <>
            <Box className="p-5">
                <JobluTypography.H6>
                    Access Level & User Roles
                </JobluTypography.H6>
            </Box>
        </>
    )
}

const BillingInformation = ({ User, getActiveSubcription, getUser }) => {
    const classes = app_styles()
    const { width } = useWindowDimensions()
    const navigate = useNavigate()
    const [showConfirmModal, setShowConfirmationModal] = useState(false)
    const [cancellation_loading, setLoadingCancel] = useState(false)
    const [cancel_step, setCancelStep] = useState(1)
    const [expandPlan, setExpandPlan] = useState(true)

    return (
        <>
            <Box className="p-4">
                <Box className={`${classes.margin.b3}`}>
                    <JobluTypography.H5 primary>
                        Your current plan
                    </JobluTypography.H5>
                </Box>
                {User?.subscription?.status === 'active' ||
                User?.subscription?.status === 'trialing' ? (
                    <>
                        <Box
                            style={{
                                width: '100%',
                            }}
                        >
                            <Box className={`${classes.text.center}`}>
                                <JobluTypography.H4
                                    primary
                                    semiBold
                                    style={{
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    premium{' '}
                                    {User?.subscription?.status === 'trialing'
                                        ? 'trial'
                                        : ''}
                                </JobluTypography.H4>
                            </Box>
                            <Box
                                style={{
                                    borderBottom: '1px solid #E4ECF3',
                                    marginBottom: 20,
                                }}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <Box
                                    style={{
                                        background: '#009CDE',
                                        width: 98,
                                        height: 5,
                                        border: '5px 5px 0px 0px',
                                    }}
                                ></Box>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    background: '#E4ECF3',
                                }}
                            >
                                {User?.subscription?.modeOfPayment ===
                                'JobluPromo' ? null : (
                                    <Box
                                        className={`${classes.padding.a5} ${classes.margin.b3}`}
                                        style={{ border: '1px solid #E4ECF3' }}
                                        padding={3}
                                    >
                                        <Box
                                            className={`${classes.text.center} ${classes.margin.b1}`}
                                        >
                                            <JobluTypography.H4
                                                secondary
                                                semiBold
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: 30,
                                                }}
                                            >
                                                {titleCase(
                                                    User?.subscription?.type ===
                                                        'monthly12'
                                                        ? 'Annually'
                                                        : User?.subscription
                                                              ?.type ===
                                                          'monthly3'
                                                        ? 'Quarterly'
                                                        : 'Monthly'
                                                )}
                                            </JobluTypography.H4>
                                        </Box>
                                        <Box
                                            className={`${classes.text.center} `}
                                        >
                                            <JobluTypography.H3
                                                primary
                                                semiBold
                                            >
                                                <span
                                                    style={{ fontWeight: 400 }}
                                                >
                                                    {get_pricing_currency(
                                                        User?.subscription?.data
                                                            ?.currency
                                                    )}
                                                </span>
                                                {`${calculateSubscriptionPerMonth(
                                                    User?.subscription?.data
                                                )}`}
                                                /mo
                                            </JobluTypography.H3>
                                            <Grid
                                                container
                                                sapcing={3}
                                                style={{
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <Grid item xs={12} sm={6}>
                                                    <Box
                                                        padding={2}
                                                        minHeight={100}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <JobluTypography.H6
                                                                    style={{
                                                                        color: '#6E88A3',
                                                                    }}
                                                                >
                                                                    {User
                                                                        ?.subscription
                                                                        ?.status ===
                                                                    'active'
                                                                        ? 'Total Amount To Pay'
                                                                        : 'Free Trial'}
                                                                </JobluTypography.H6>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <JobluTypography.H5
                                                                    primary
                                                                    semiBold
                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 400,
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        {get_pricing_currency(
                                                                            User
                                                                                ?.subscription
                                                                                ?.data
                                                                                ?.currency
                                                                        )}
                                                                    </span>
                                                                    {User
                                                                        ?.subscription
                                                                        ?.status ===
                                                                    'active'
                                                                        ? thousendSeparator(
                                                                              User
                                                                                  ?.subscription
                                                                                  ?.amount
                                                                          )
                                                                        : '0'}
                                                                </JobluTypography.H5>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box
                                                        padding={2}
                                                        minHeight={100}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <JobluTypography.H6
                                                                    style={{
                                                                        color: '#6E88A3',
                                                                    }}
                                                                >
                                                                    {User
                                                                        ?.subscription
                                                                        ?.status ===
                                                                    'active'
                                                                        ? ' Plan '
                                                                        : ' Trial '}
                                                                    Ends In
                                                                </JobluTypography.H6>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <JobluTypography.H5
                                                                    primary
                                                                    semiBold
                                                                >
                                                                    {moment(
                                                                        User
                                                                            ?.subscription
                                                                            ?.status ===
                                                                            'active'
                                                                            ? User
                                                                                  ?.subscription
                                                                                  ?.expiryDate
                                                                            : User
                                                                                  ?.subscription
                                                                                  ?.trialEndDate
                                                                    ).diff(
                                                                        moment(),
                                                                        'days'
                                                                    )}{' '}
                                                                    days
                                                                </JobluTypography.H5>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box
                                                        padding={2}
                                                        minHeight={100}
                                                    >
                                                        <JobluTypography.H6
                                                            style={{
                                                                color: '#6E88A3',
                                                            }}
                                                        >
                                                            Next Billing Date
                                                        </JobluTypography.H6>
                                                        <JobluTypography.H5
                                                            primary
                                                            semiBold
                                                        >
                                                            {moment(
                                                                User
                                                                    ?.subscription
                                                                    ?.expiryDate
                                                            ).format(
                                                                'MMMM DD, YYYY'
                                                            )}
                                                        </JobluTypography.H5>
                                                    </Box>
                                                    <Box
                                                        padding={2}
                                                        minHeight={100}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <JobluTypography.H6
                                                                    style={{
                                                                        color: '#6E88A3',
                                                                    }}
                                                                >
                                                                    Remaining
                                                                    Superlike
                                                                    credits
                                                                </JobluTypography.H6>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <JobluTypography.H5
                                                                    primary
                                                                    semiBold
                                                                >
                                                                    {
                                                                        User
                                                                            ?.user
                                                                            ?.credits_superLikes
                                                                    }
                                                                </JobluTypography.H5>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box
                            style={{ background: '#E4ECF3' }}
                            padding={3}
                            marginTop={3}
                        >
                            <Grid container justifyContent="center">
                                <Grid item xs={12} md={9}>
                                    <Accordion
                                        elevation={0}
                                        style={{ border: 'none' }}
                                        defaultExpanded
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            See Features
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box
                                                style={{
                                                    backgroundColor: '#F6F9FB',
                                                }}
                                                className="p-5"
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12} md={12}>
                                                        <Box>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Unlimited
                                                                                Job
                                                                                Postings
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    {/* <Box
                                                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                >
                                                                    <Box
                                                                        className={`${classes.margin.r2}`}
                                                                    >
                                                                        <CheckIcon
                                                                            color="secondary"
                                                                            fontSize="large"
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            3
                                                                            User
                                                                            Seats
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box> */}
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Basic
                                                                                Talent
                                                                                Finder
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Basic
                                                                                ATS
                                                                                Integration
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Up
                                                                                to
                                                                                300
                                                                                Matches
                                                                                PER
                                                                                Job
                                                                                Post
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Up
                                                                                to
                                                                                300
                                                                                Candidate
                                                                                Messaging
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                In-app
                                                                                &
                                                                                email
                                                                                Instant
                                                                                Alerts
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                1
                                                                                Social
                                                                                Media
                                                                                Post
                                                                                on
                                                                                Joblu’s
                                                                                pages
                                                                                +
                                                                                community
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <Box>
                                                                        <Box
                                                                            className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                        >
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                semiBold
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Other
                                                                                Features
                                                                                that
                                                                                you’ll
                                                                                get
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Candidate
                                                                                Matching
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Job
                                                                                Mailbox
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Instant
                                                                                Data
                                                                                Exporter
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Exclusive
                                                                                Recruitment
                                                                                Drive
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <Box
                                                                            className={`${classes.margin.r2}`}
                                                                        >
                                                                            <CheckIcon
                                                                                color="secondary"
                                                                                fontSize="large"
                                                                            />
                                                                        </Box>
                                                                        <Box>
                                                                            <JobluTypography.H5
                                                                                primary
                                                                                style={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                }}
                                                                            >
                                                                                Dedicated
                                                                                Account
                                                                                Manager
                                                                            </JobluTypography.H5>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Box>
                        {User?.subscription?.modeOfPayment ===
                        'JobluPromo' ? null : (
                            <Grid
                                container
                                justifyContent="center"
                                className={`${classes.margin.t3}`}
                            >
                                <Grid item xs={12} md={4}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="error"
                                        style={{
                                            textTransform: 'none',
                                            backgroundColor: '#EB5757',
                                            color: '#FFFFFF',
                                        }}
                                        onClick={() => {
                                            setShowConfirmationModal(true)
                                        }}
                                    >
                                        Cancel Plan
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </>
                ) : (
                    // <JobluTypography.Body>
                    //     You have no subscription.
                    //     <Link to={'/pricing'}> Get premium?</Link>
                    // </JobluTypography.Body>
                    <>
                        <Box
                            style={{
                                width: '100%',
                            }}
                        >
                            <Box className={`${classes.text.center}`}>
                                <JobluTypography.H4
                                    primary
                                    semiBold
                                    style={{
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    Free
                                    {/* {User?.subscription?.status === 'trialing'
                                        ? 'trial'
                                        : ''} */}
                                </JobluTypography.H4>
                            </Box>
                            <Box
                                style={{
                                    borderBottom: '1px solid #E4ECF3',
                                    marginBottom: 20,
                                }}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <Box
                                    style={{
                                        background: '#009CDE',
                                        width: 98,
                                        height: 5,
                                        border: '5px 5px 0px 0px',
                                    }}
                                ></Box>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    background: '#E4ECF3',
                                }}
                            >
                                {User?.subscription?.modeOfPayment ===
                                'JobluPromo' ? null : (
                                    <Box
                                        className={`${classes.padding.a5} ${classes.margin.b3}`}
                                        style={{ border: '1px solid #E4ECF3' }}
                                        padding={3}
                                    >
                                        <Box
                                            className={`${classes.text.center} ${classes.margin.b1}`}
                                        >
                                            <JobluTypography.H4
                                                secondary
                                                semiBold
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: 30,
                                                }}
                                            >
                                                {/* {titleCase(
                                                    User?.subscription?.type ===
                                                        'monthly12'
                                                        ? 'Annually'
                                                        : User?.subscription
                                                              ?.type ===
                                                          'monthly3'
                                                        ? 'Quarterly'
                                                        : 'Monthly'
                                                )} */}
                                            </JobluTypography.H4>
                                        </Box>
                                        <Box
                                            className={`${classes.text.center} `}
                                        >
                                            <JobluTypography.H3
                                                primary
                                                semiBold
                                            >
                                                <span
                                                    style={{ fontWeight: 400 }}
                                                >
                                                    {get_pricing_currency(
                                                        User?.subscription?.data
                                                            ?.currency
                                                    )}
                                                </span>
                                                0/mo
                                            </JobluTypography.H3>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={9}>
                                <Accordion
                                    elevation={0}
                                    style={{ border: 'none' }}
                                    expanded={expandPlan}
                                >
                                    <AccordionSummary
                                        expandIcon={
                                            <ArrowDropDownIcon
                                                onClick={() => {
                                                    setExpandPlan(!expandPlan)
                                                }}
                                            />
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        See Features
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box
                                            style={{
                                                backgroundColor: '#F6F9FB',
                                            }}
                                            className="p-5"
                                        >
                                            <Grid
                                                container
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12} md={12}>
                                                    <Box>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                            >
                                                                <Box
                                                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                >
                                                                    <Box
                                                                        className={`${classes.margin.r2}`}
                                                                    >
                                                                        <CheckIcon
                                                                            color="secondary"
                                                                            fontSize="large"
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            2
                                                                            Job
                                                                            Postings
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box>
                                                                {/* <Box
                                                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                >
                                                                    <Box
                                                                        className={`${classes.margin.r2}`}
                                                                    >
                                                                        <CheckIcon
                                                                            color="secondary"
                                                                            fontSize="large"
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            1
                                                                            User
                                                                            Seat
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box> */}
                                                                <Box
                                                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                >
                                                                    <Box
                                                                        className={`${classes.margin.r2}`}
                                                                    >
                                                                        <CheckIcon
                                                                            color="secondary"
                                                                            fontSize="large"
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            Up
                                                                            to
                                                                            50
                                                                            Matches
                                                                            PER
                                                                            Job
                                                                            Post
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                >
                                                                    <Box
                                                                        className={`${classes.margin.r2}`}
                                                                    >
                                                                        <CheckIcon
                                                                            color="secondary"
                                                                            fontSize="large"
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            Up
                                                                            to
                                                                            50
                                                                            Candidate
                                                                            Messaging
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                >
                                                                    <Box
                                                                        className={`${classes.margin.r2}`}
                                                                    >
                                                                        <CheckIcon
                                                                            color="secondary"
                                                                            fontSize="large"
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            In-app
                                                                            Alerts
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                            >
                                                                <Box>
                                                                    <Box
                                                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                    >
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            semiBold
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            Other
                                                                            Features
                                                                            that
                                                                            you’ll
                                                                            get
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                >
                                                                    <Box
                                                                        className={`${classes.margin.r2}`}
                                                                    >
                                                                        <CheckIcon
                                                                            color="secondary"
                                                                            fontSize="large"
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            Candidate
                                                                            Matching
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.margin.b2}`}
                                                                >
                                                                    <Box
                                                                        className={`${classes.margin.r2}`}
                                                                    >
                                                                        <CheckIcon
                                                                            color="secondary"
                                                                            fontSize="large"
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H5
                                                                            primary
                                                                            style={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                            }}
                                                                        >
                                                                            Workspace
                                                                            Spotlight
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="center"
                            className={`${classes.margin.t3}`}
                        >
                            <Grid item xs={12} md={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    style={{
                                        textTransform: 'none',
                                        backgroundColor: '#D7A048',
                                        color: '#FFFFFF',
                                    }}
                                    onClick={() => {
                                        navigate('/pricing')
                                    }}
                                >
                                    Upgrade Plan
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Box
                    className={`${classes.margin.t3}`}
                    style={{ display: 'none' }}
                >
                    <Box className={`${classes.margin.b2}`}>
                        <JobluTypography.H4>
                            Company Information
                        </JobluTypography.H4>
                    </Box>
                    <Box className={`${classes.margin.b3}`}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    Currency{' '}
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Currency'} />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                className={{
                                    display: width < 640 ? 'none' : 'block',
                                }}
                            ></Grid>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    Company Name
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Company Name'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    Company Size
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Company Size'} />
                            </Grid>
                            <Grid item xs={12}>
                                <JobluTypography.Body primary semiBold>
                                    Address
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Address'} />
                            </Grid>
                            <Grid item xs={12}>
                                <JobluTypography.Body primary semiBold>
                                    Extended Address
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Extended Address'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    City
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'City'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    Zip Code
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Zip Code'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    Country
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Country'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    State/Region
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'State/Region'} />
                            </Grid>
                            <Grid item xs={12}>
                                <JobluTypography.Body primary semiBold>
                                    Business ID (GST/VAT)
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Address'} />
                                <JobluTypography.Caption>
                                    Your Business ID (max 20 characters) will be
                                    included on all your Joblu invoices to show
                                    tax exemption.
                                </JobluTypography.Caption>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box
                    className={`${classes.margin.t3}`}
                    style={{ display: 'none' }}
                >
                    <Box className={`${classes.margin.b2}`}>
                        <JobluTypography.H4>Billing Contact</JobluTypography.H4>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    First Name
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'First Name'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    Last Name
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Last Name'} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    Email
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Email'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <JobluTypography.Body primary semiBold>
                                    Phone
                                    <span
                                        className={`${classes.text.secondary}`}
                                    >
                                        *
                                    </span>
                                </JobluTypography.Body>
                                <TextField placeholder={'Phone'} />
                            </Grid>
                        </Grid>
                        <Box className={`${classes.margin.t3}`}>
                            <Box
                                className={`${classes.display.display_flex} ${classes.flexBox.justify_content_center}`}
                            >
                                <Box className={`${classes.margin.r2}`}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ textTransform: 'none' }}
                                    >
                                        Clear Fields
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ textTransform: 'none' }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={showConfirmModal}
                maxWidth={cancel_step === 1 ? 'xs' : 'sm'}
            >
                <DialogTitle style={{ background: '#002E5D' }}>
                    <JobluTypography.H6
                        align="center"
                        style={{ color: '#fff' }}
                    >
                        {cancel_step === 3 ? 'Plan Cancelled' : 'Cancel Plan'}
                    </JobluTypography.H6>
                </DialogTitle>
                {!cancellation_loading ? (
                    <Box padding={5}>
                        {cancel_step === 1 ? (
                            <>
                                <Box marginBottom={3}>
                                    <JobluTypography.H5 primary align="center">
                                        Are you sure you want to cancel your
                                        subscription?
                                    </JobluTypography.H5>
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            style={{
                                                // color: '#EB5757',
                                                // border: '1px solid #EB5757',
                                                textTransform: 'none',
                                            }}
                                            onClick={() => {
                                                setCancelStep(2)
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            style={{ textTransform: 'none' }}
                                            onClick={() => {
                                                setShowConfirmationModal(false)
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : cancel_step === 2 ? (
                            <Box>
                                <JobluTypography.H5
                                    align="center"
                                    gutterBottom={3}
                                    primary
                                    style={{ fontWeight: 600 }}
                                >
                                    We'll be sad to see you go
                                </JobluTypography.H5>
                                <JobluTypography.Body
                                    style={{
                                        fontSize: 18,
                                    }}
                                    primary
                                    align="center"
                                >
                                    Canceling your subscription means missing
                                    out on top talent and exclusive features.
                                    <br />
                                    <br />
                                    We're the ultimate hiring platform for a
                                    reason, and we want you to experience the
                                    best. So, are you absolutely sure?
                                </JobluTypography.Body>
                                <Grid container justifyContent="center">
                                    <Grid item xs={8} container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                style={{
                                                    color: '#fff',
                                                    background: '#EB5757',
                                                    textTransform: 'none',
                                                    marginTop: 20,
                                                }}
                                                onClick={() => {
                                                    setLoadingCancel(true)
                                                    paymentRequests
                                                        .cancelSubscription({
                                                            subscriptionId:
                                                                User
                                                                    ?.subscription
                                                                    ?.transactionId,
                                                        })
                                                        .then((res) => {
                                                            setCancelStep(3)
                                                            setTimeout(() => {
                                                                getActiveSubcription()
                                                            }, 500)
                                                        })
                                                        .catch(() => {
                                                            alert(
                                                                'An error occured'
                                                            )
                                                            setShowConfirmationModal(
                                                                false
                                                            )
                                                        })
                                                        .finally(() => {
                                                            setTimeout(() => {
                                                                setLoadingCancel(
                                                                    false
                                                                )
                                                                // setShowConfirmationModal(
                                                                //     false
                                                                // )
                                                                //setCancelStep(1)
                                                            }, 4000)
                                                        })
                                                }}
                                            >
                                                Cancel my subscription
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                color="primary"
                                                variant="contained"
                                                style={{
                                                    textTransform: 'none',
                                                }}
                                                onClick={() => {
                                                    setCancelStep(1)
                                                    setShowConfirmationModal(
                                                        false
                                                    )
                                                }}
                                            >
                                                No, I changed my mind
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : (
                            <>
                                <JobluTypography.H6>
                                    Your Plan has been cancelled
                                </JobluTypography.H6>
                                <Button
                                    onClick={() => {
                                        getUser()
                                        setTimeout(() => {
                                            setShowConfirmationModal(false)
                                            navigate('/pricing')
                                        }, 500)

                                        //setCancelStep(1)
                                    }}
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    style={{
                                        textTransform: 'none',
                                        marginTop: 20,
                                    }}
                                >
                                    Okay
                                </Button>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box padding={5}>
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                        <JobluTypography.Body align="center">
                            Please wait while we process your transaction...
                        </JobluTypography.Body>
                    </Box>
                )}
            </Dialog>
        </>
    )
}

const PlansAndPricing = () => {
    return (
        <>
            <Box className="p-3">
                <JobluTypography.H6>Plans and Pricing</JobluTypography.H6>
            </Box>
        </>
    )
}

function Settings(props) {
    const { User, signOut, getActiveSubcription, getUser } = props
    const { width } = useWindowDimensions()
    const _location = useLocation()
    const classes = app_styles()
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(
        _location?.state?.selected || 'editProfile'
    )

    const location = useLocation()
    const tab = new URLSearchParams(location?.search).get('tab')
    useEffect(() => {
        if (tab) {
            if (tab === 'plans') setCurrentPage('billing')
            if (tab === 'integrations') setCurrentPage('appInteg')
            if (tab === 'edit-profile') setCurrentPage('editProfile')
        }
    }, [tab])
    const handleLogout = () => {
        try {
            userRequest
                .signOut({ token: User?.token })
                .then((res) => {
                    signOut()
                })
                .finally(() => {
                    setTimeout(() => {
                        navigate('/login')
                    }, 500)
                })
        } catch (err) {
            console.log(err)
        }
    }

    const [isMobile, setIsMobile] = useState(width < 640 ? true : false)
    const [isPageActive, setIsPageActive] = useState(false)

    const handleChangePage = (page) => {
        setCurrentPage(page)

        setIsPageActive(true)
    }

    const handleClosePage = () => {
        setIsPageActive(false)
    }

    const [previewProfile, setPreviewProfile] = useState(false)

    return (
        <>
            <Dialog
                open={previewProfile}
                fullWidth
                maxWidth={'lg'}
                style={{ zIndex: '9999' }}
                onClose={() => {
                    setPreviewProfile(false)
                }}
            >
                <MyAccount />{' '}
                <div
                    className="position-absolute d-flex"
                    style={{
                        borderRadius: '50%',
                        width: 50,
                        height: 50,
                        right: 5,
                        top: 5,
                        zIndex: 999999,
                        background: 'hsla(0, 100%, 0%, 0.5)',
                    }}
                    role="button"
                    onClick={() => {
                        setPreviewProfile(false)
                    }}
                >
                    <div className="m-auto">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="white"
                            className="bi bi-x-lg"
                            viewBox="0 0 16 16"
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </div>
                </div>
            </Dialog>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={11}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            className="p-3"
                            style={{
                                display:
                                    width < 640 && isPageActive
                                        ? 'none'
                                        : 'block',
                            }}
                        >
                            {/* USERS */}
                            <Box className="bg-light p-3 mb-3">
                                <Box
                                    className={`${classes.display.display_flex} ${classes.flexBox.justify_content_between} ${classes.flexBox.align_items_center}`}
                                >
                                    <Box
                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center}`}
                                        style={{ flexGrow: 1 }}
                                    >
                                        <Box>
                                            <img
                                                src={
                                                    User?.user?.profileUrl ||
                                                    DefaultImg
                                                }
                                                width={80}
                                                height={80}
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: '100px',
                                                    marginRight: '10px',
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Box>
                                                <JobluTypography.H6
                                                    primary
                                                    semiBold
                                                >
                                                    {User?.user?.companyName}
                                                </JobluTypography.H6>
                                            </Box>
                                            <Box>
                                                <JobluTypography.Caption
                                                    primary
                                                >
                                                    {
                                                        User?.user
                                                            ?.currentLocation
                                                    }
                                                </JobluTypography.Caption>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <img
                                            className="hand"
                                            src={EditIcon}
                                            width={24}
                                            height={24}
                                            style={{ objectFit: 'contain' }}
                                            onClick={() => {
                                                handleChangePage('editProfile')
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="bg-light p-3 mb-3 d-none">
                                <Box
                                    className={`${classes.display.display_flex} ${classes.flexBox.justify_content_between} ${classes.flexBox.align_items_center}`}
                                >
                                    <Box
                                        className={`${classes.display.display_flex} ${classes.flexBox.align_items_center}`}
                                        style={{ flexGrow: 1 }}
                                    >
                                        <Box>
                                            <img
                                                src={DefaultImg}
                                                width={80}
                                                height={80}
                                                style={{
                                                    objectFit: 'cover',
                                                    borderRadius: '100px',
                                                    marginRight: '10px',
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Box>
                                                <JobluTypography.H6
                                                    primary
                                                    semiBold
                                                >
                                                    User
                                                </JobluTypography.H6>
                                            </Box>
                                            <Box>
                                                <JobluTypography.Caption
                                                    primary
                                                >
                                                    Admin
                                                </JobluTypography.Caption>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <img
                                            className="hand"
                                            src={EditIcon}
                                            width={24}
                                            height={24}
                                            style={{ objectFit: 'contain' }}
                                            onClick={() => {
                                                handleChangePage('userEdit')
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            {/* END OF USERS */}
                            {/* SECURITY */}
                            <Box>
                                <Box className="bg-light p-3">
                                    <JobluTypography.Body primary semiBold>
                                        Security
                                    </JobluTypography.Body>
                                </Box>
                                {/* <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    onClick={() => {
                                        setCurrentPage('accessLevel')
                                    }}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={AccessLevelIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Access Level & User Roles
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box> */}
                                <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    style={{
                                        background:
                                            currentPage == 'changePw' &&
                                            '#CCEBF8',
                                    }}
                                    onClick={() => {
                                        handleChangePage('changePw')
                                    }}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={AccessLevelIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Change Password
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box>
                            </Box>
                            {/* END OF SECURITY */}
                            {/* PLANS AND BILLINGS */}
                            <Box>
                                <Box className="bg-light p-3">
                                    <JobluTypography.Body primary semiBold>
                                        Current Plan
                                    </JobluTypography.Body>
                                </Box>
                                <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    onClick={() => {
                                        handleChangePage('billing')
                                    }}
                                    style={{
                                        background:
                                            currentPage == 'billing' &&
                                            '#CCEBF8',
                                    }}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={BillingIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Current Plan
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box>
                                <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    onClick={() => {
                                        navigate('/pricing')
                                    }}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={PricingIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Plans & Pricing
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box>
                            </Box>
                            {/* END OF PLANS AND BILLINGS */}
                            {/* INTEGRATIONS */}
                            <Box>
                                <Box className="bg-light p-3">
                                    <JobluTypography.Body primary semiBold>
                                        Integrations
                                    </JobluTypography.Body>
                                </Box>
                                {/* <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={VideoIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Video Conference
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box> */}
                                <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    onClick={() => {
                                        handleChangePage('appInteg')
                                    }}
                                    style={{
                                        background:
                                            currentPage == 'appInteg' &&
                                            '#CCEBF8',
                                    }}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={EmailIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            App Integrations
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box>
                            </Box>
                            {/* INTEGRATIONS */}
                            {/* INTEGRATIONS */}
                            <Box>
                                <Box className="bg-light p-3">
                                    <JobluTypography.Body primary semiBold>
                                        Help
                                    </JobluTypography.Body>
                                </Box>
                                <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    onClick={() => {
                                        handleChangePage('contact')
                                    }}
                                    style={{
                                        background:
                                            currentPage == 'contact' &&
                                            '#CCEBF8',
                                    }}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={ContactIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Contact Us
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box>
                                <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    onClick={() => {
                                        window.open(
                                            'https://joblu.io/terms-of-use/'
                                        )
                                    }}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={DocumentIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Terms and Conditions
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box>
                                <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    onClick={() => {
                                        window.open(
                                            'https://joblu.io/terms-of-use/'
                                        )
                                    }}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={DocumentIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Privacy Policy
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box>
                                <Box
                                    className={`${classes.display.display_flex} p-3 hand`}
                                    onClick={() => handleLogout()}
                                >
                                    <Box
                                        className={`${classes.margin.l3} ${classes.margin.r3}`}
                                    >
                                        <img
                                            src={LogoutIcon}
                                            width={22}
                                            height={22}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Box>
                                    <Box>
                                        <JobluTypography.Caption primary>
                                            Logout
                                        </JobluTypography.Caption>
                                    </Box>
                                </Box>
                            </Box>
                            {/* INTEGRATIONS */}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={9}
                            // className={`${classes.margin.b1}`}
                            style={{
                                borderLeft: '1px solid #8080804D',
                                display:
                                    width < 640 && isPageActive
                                        ? 'block'
                                        : width > 640
                                        ? 'block'
                                        : 'none',
                            }}
                        >
                            <Box
                                className="p-4"
                                style={{ borderBottom: '1px solid #8080804D' }}
                            >
                                <Box
                                    className={`${classes.display.display_flex} ${classes.flexBox.align_items_center}`}
                                >
                                    {width < 640 && (
                                        <>
                                            <Box className="me-2">
                                                <Box
                                                    onClick={() => {
                                                        setIsPageActive(false)
                                                    }}
                                                >
                                                    <ArrowBackIcon className="me-1" />
                                                </Box>
                                            </Box>
                                        </>
                                    )}

                                    <>
                                        <Box
                                            style={{ width: '100%' }}
                                            className={`${classes.display.display_flex} ${classes.flexBox.align_items_center} ${classes.flexBox.justify_content_between}`}
                                        >
                                            <Box>
                                                {' '}
                                                <JobluTypography.H6 primary>
                                                    {currentPage ==
                                                        'editProfile' &&
                                                        'Edit Profile'}
                                                    {currentPage ==
                                                        'userEdit' &&
                                                        'User Information'}
                                                    {currentPage ==
                                                        'accessLevel' &&
                                                        'Access Level & User Roles'}
                                                    {currentPage ==
                                                        'changePw' &&
                                                        'Change Password'}
                                                    {currentPage == 'billing' &&
                                                        'Plans and Billing'}
                                                    {currentPage == 'contact' &&
                                                        'Contact Us'}
                                                    {currentPage ==
                                                        'appInteg' &&
                                                        'App Integrations'}
                                                </JobluTypography.H6>
                                            </Box>
                                            {currentPage == 'editProfile' && (
                                                <>
                                                    <Box>
                                                        <Button
                                                            variant={
                                                                'contained'
                                                            }
                                                            color={'primary'}
                                                            style={{
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                            onClick={() => {
                                                                // setPreviewProfile(
                                                                //     true
                                                                // )
                                                                window.open(
                                                                    `https://jobs.joblu.io/company-showcase/${User?.user?.userName}`
                                                                )
                                                            }}
                                                        >
                                                            Preview Profile
                                                        </Button>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    maxHeight: '80vh',
                                }}
                            >
                                {currentPage == 'editProfile' && (
                                    <>
                                        <EditProfile
                                            onSave={() => {
                                                setPreviewProfile(true)
                                            }}
                                        />
                                    </>
                                )}
                                {currentPage == 'userEdit' && (
                                    <>
                                        <UserEdit />
                                    </>
                                )}
                                {currentPage == 'accessLevel' && (
                                    <>
                                        <AccessLevel />
                                    </>
                                )}
                                {currentPage == 'changePw' && (
                                    <>
                                        <ChangePassword />
                                    </>
                                )}
                                {currentPage == 'billing' && (
                                    <>
                                        <BillingInformation
                                            User={User}
                                            getActiveSubcription={
                                                getActiveSubcription
                                            }
                                            getUser={getUser}
                                        />
                                    </>
                                )}
                                {currentPage == 'appInteg' && (
                                    <>
                                        <ConnectedApps />
                                    </>
                                )}
                                {currentPage == 'contact' && (
                                    <>
                                        <ContactUs />
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}

const mapDispatchToProps = {
    signOut: rootAction.signOut,
    getActiveSubcription: userActions.getActiveSubscription,
    getUser: userActions.getUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
