import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import './joblu-loader/loader.css'
const SpinProgress = ({ size = 80 }) => {
    const [progress, setProgress] = useState(100)
    const [progress_medium, setProgressM] = useState(0)
    const [progress_small, setProgressS] = useState(0)
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress <= 0 ? 100 : prevProgress - 10
            )
        }, 800)

        const time2 = setInterval(() => {
            setProgressM((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 10
            )
        }, 1000)

        const time3 = setInterval(() => {
            setProgressS((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 20
            )
        }, 1200)

        return () => {
            clearInterval(timer)
            clearInterval(time2)
            clearInterval(time3)
        }
    }, [])

    return (
        <Box display="flex" justifyContent="center" height={200} padding={3}>
            <Box
                position="relative"
                width={size}
                height={size}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <div id="loader" class="loader1">
                    <div class="loading"></div>
                </div>
                <div id="loader" class="loader2">
                    <div class="load"></div>
                </div>
                <div id="loader" class="loader3">
                    <div class="logo"></div>
                </div>
            </Box>
        </Box>
    )
}

export default SpinProgress
