import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import {
    Box,
    Button,
    Chip,
    ClickAwayListener,
    Grid,
    Tooltip,
} from '@material-ui/core'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DefaultImg from '../../assets/img/defaultImg.png'
import BirthdayIcon from '../../assets/img/profilePreview/BirthdayIcon.png'
import EducationIcon from '../../assets/img/profilePreview/EducationIcon.png'
import EmailIcon from '../../assets/img/profilePreview/EmailIcon.png'
import ExperienceIcon from '../../assets/img/profilePreview/ExperienceIcon.png'
import LocationIcon from '../../assets/img/profilePreview/LocationIcon.png'
import NoDocument from '../../assets/img/profilePreview/NoDocument.png'
import PhoneIcon from '../../assets/img/profilePreview/PhoneIcon.png'
import SalaryIcon from '../../assets/img/profilePreview/SalaryIcon.png'
import { app_styles } from '../../general.styles'
import {
    formatDateRange2,
    formatMoney,
    generateRandomChars,
    getYearDifference,
    getYearsOfExpCategory,
} from '../../utils/helpers'
import useWindowDimensions from '../../utils/hooks'
import JobluTypography from '../Typography'
import ElevatorPitchV2 from '../reusables/elevator-pitch/ElevatorPitchV2'
import { profile_preview_styles } from './styles'
import { LargeBanner, MediumBanner, NativeBanner, SmallBanner, SquareBanner } from '../adbanner'

function ProfilePreview(props) {
    const {
        AppState,
        current_user,
        nextStep,
        isFromCreateProfile,
        borderRadius,
        isFromExplore,
        match,
    } = props
    const app_classes = app_styles()
    const classes = profile_preview_styles()
    const navigate = useNavigate()
    const { width } = useWindowDimensions()

    const { currencyList, currency } = AppState
    const currencyOptions = {
        currencyList,
        currency,
    }

    const [applicationStatus, setApplicationStatus] = useState(
        match?.applicationStatus ? true : false
    )
    const [fromExplore, setFromExplore] = useState(isFromExplore ? true : false)
    console.log('isFromExplore', fromExplore, !applicationStatus)
    // console.log('isFromExplore', isFromExplore)

    const filesList = !isFromExplore
        ? current_user?.portfolioAndFiles
        : current_user?.filesPreview || []

    const getYearsOfExp = (item) => {
        if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
            const sorted =
                item?.experienceHistory &&
                item?.experienceHistory?.sort(function (a, b) {
                    return new Date(a.startDate) - new Date(b.startDate)
                })
            const startDate = new Date(sorted[0]?.startDate)
            const diff = getYearDifference(startDate)
            return getYearsOfExpCategory(diff)
        } else {
            return null
        }
    }

    const [activeTab, setActiveTab] = useState(
        filesList?.length > 0 ? 'docs' : 'profile'
    )

    const copyToClip = (data, type) => {
        navigator.clipboard.writeText(data)
    }

    const [openEmailTooltip, setOpenEmailTooltip] = useState(false)
    const handleEmailTooltipOpen = (data, type) => {
        setOpenEmailTooltip(true)
        copyToClip(data, type)
    }
    const handleEmailTooltipClose = () => {
        setOpenEmailTooltip(false)
    }

    const [openPhoneTooltip, setOpenPhoneTooltip] = useState(false)
    const handlePhoneTooltipOpen = (data, type) => {
        setOpenPhoneTooltip(true)
        copyToClip(data, type)
    }
    const handlePhoneTooltipClose = () => {
        setOpenPhoneTooltip(false)
    }

    useEffect(() => {
        console.log(current_user?.experienceHistory?.length > 0)
    }, [current_user])

    return (
        <>
            <Box style={{ minHeight: '90vh' }}>
                {isFromCreateProfile && (
                    <>
                        <Box
                            className={`${app_classes.text.center} ${app_classes.margin.y4}`}
                        >
                            <Box className={`${app_classes.margin.b1}`}>
                                <JobluTypography.H4
                                    onClick={() => {
                                        // console.log(User?.user);
                                    }}
                                    className={`${app_classes.text.warning}`}
                                >
                                    Congratulations!
                                </JobluTypography.H4>
                            </Box>
                            <Box className={`${app_classes.margin.b3}`}>
                                <JobluTypography.H4 primary>
                                    This is how potential employers will see
                                    your profile.
                                </JobluTypography.H4>
                            </Box>
                            <Box>
                                <JobluTypography.H5 primary>
                                    <span
                                        className={`${app_classes.text.warning}`}
                                    >
                                        Tip:
                                    </span>{' '}
                                    Employers are 10x most likely to shortlist
                                    you if your <br />
                                    profile is complete.
                                </JobluTypography.H5>
                            </Box>
                        </Box>
                    </>
                )}
                <Box
                    style={{
                        backgroundColor: '#F6F9FB',
                        borderRadius: borderRadius && '10px',
                    }}
                    className={`${app_classes.padding.a2} ${app_classes.margin.b4}`}
                >
                    <Box
                        style={{ backgroundColor: '#FFFFFF' }}
                        className={`${app_classes.padding.a2} ${app_classes.margin.b3}`}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                justifyContent=""
                                className={`${
                                    app_classes.display.display_flex
                                } ${
                                    width < 640
                                        ? app_classes.flexBox
                                              .flex_direction_column
                                        : app_classes.flexBox.flex_direction_row
                                }`}
                            >
                                <Box
                                    className={`${app_classes.margin.r2} ${app_classes.text.center}`}
                                >
                                    <img
                                        src={
                                            current_user?.profileUrl ||
                                            DefaultImg
                                        }
                                        style={{
                                            borderRadius: '100px',
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'cover',
                                            border: '5px solid #E4ECF3',
                                            backgroundColor: '#ffffff',
                                        }}
                                        className={`${app_classes.margin.b1}`}
                                    />
                                    {current_user?.elevatorPitch ? (
                                        <>
                                            <Box
                                                className={` ${app_classes.margin.b1} ${app_classes.cursor.pointer}`}
                                            >
                                                <ElevatorPitchV2
                                                    jobseeker={current_user}
                                                />
                                            </Box>
                                        </>
                                    ) : null}
                                </Box>
                                <Box
                                    className={`${
                                        width < 640
                                            ? app_classes.text.center
                                            : app_classes.text.left
                                    }`}
                                >
                                    <JobluTypography.H4
                                        primary
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {current_user?.firstName}{' '}
                                        {current_user?.lastName}
                                    </JobluTypography.H4>
                                    {current_user?.preferedTitle && (
                                        <>
                                            <JobluTypography.H6 secondary>
                                                {current_user?.preferedTitle}
                                            </JobluTypography.H6>
                                        </>
                                    )}
                                    {current_user?.jobFunction && (
                                        <>
                                            <Box
                                                className={`${app_classes.margin.b1}`}
                                            >
                                                <JobluTypography.Body>
                                                    {current_user?.jobFunction}
                                                </JobluTypography.Body>
                                            </Box>
                                        </>
                                    )}

                                    {current_user?.employmentStatus && (
                                        <>
                                            <Chip
                                                style={{
                                                    backgroundColor:
                                                        '#009CDE4D',
                                                    width: 188,
                                                }}
                                                className={`${app_classes.margin.b1}`}
                                                label={
                                                    <JobluTypography.Caption
                                                        className={`${app_classes.text.secondary}`}
                                                    >
                                                        {
                                                            current_user?.employmentStatus
                                                        }
                                                    </JobluTypography.Caption>
                                                }
                                            />
                                        </>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        {current_user?.educationHistory
                                            ?.length > 0 && (
                                            <>
                                                <Box
                                                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                                >
                                                    <Box
                                                        className={`${app_classes.margin.r1}`}
                                                    >
                                                        <img
                                                            src={EducationIcon}
                                                            width={16}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <JobluTypography.Body
                                                            primary
                                                        >
                                                            {
                                                                current_user?.educationHistory?.sort(
                                                                    function (
                                                                        a,
                                                                        b
                                                                    ) {
                                                                        return (
                                                                            new Date(
                                                                                b.educStartDate
                                                                            ) -
                                                                            new Date(
                                                                                a.educStartDate
                                                                            )
                                                                        )
                                                                    }
                                                                )[0]?.educName
                                                            }
                                                        </JobluTypography.Body>
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                        {current_user?.currentLocation && (
                                            <>
                                                <Box
                                                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                                >
                                                    <Box
                                                        className={`${app_classes.margin.r1}`}
                                                    >
                                                        <img
                                                            src={LocationIcon}
                                                            width={16}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <JobluTypography.Body
                                                            primary
                                                        >
                                                            {
                                                                current_user?.currentLocation
                                                            }
                                                        </JobluTypography.Body>
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                        {current_user?.experienceHistory
                                            ?.length > 0 ? (
                                            <>
                                                <Box
                                                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                                >
                                                    <Box
                                                        className={`${app_classes.margin.r1}`}
                                                    >
                                                        <img
                                                            src={ExperienceIcon}
                                                            width={16}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <JobluTypography.Body
                                                            primary
                                                        >
                                                            {getYearsOfExp(
                                                                current_user
                                                            ) &&
                                                                getYearsOfExp(
                                                                    current_user
                                                                ) !== null &&
                                                                `${getYearsOfExp(
                                                                    current_user
                                                                )} Years of Experience`}
                                                        </JobluTypography.Body>
                                                    </Box>
                                                </Box>
                                            </>
                                        ) : null}
                                        {current_user?.salaryMin &&
                                        current_user?.salaryMax ? (
                                            <>
                                                <Box
                                                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                                >
                                                    <Box
                                                        className={`${app_classes.margin.r1}`}
                                                    >
                                                        <img
                                                            src={SalaryIcon}
                                                            width={16}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <JobluTypography.Body
                                                            primary
                                                        >
                                                            {`${formatMoney(
                                                                current_user?.salaryMin,
                                                                currencyOptions,
                                                                current_user?.salaryCurrency ||
                                                                    'SGD'
                                                            )} - ${formatMoney(
                                                                current_user?.salaryMax,
                                                                currencyOptions,
                                                                current_user?.salaryCurrency ||
                                                                    'SGD'
                                                            )}`}
                                                        </JobluTypography.Body>
                                                    </Box>
                                                </Box>
                                            </>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {/* FOR HARLE */}
                                        {/* {!isFromExplore && ( */}
                                        <>
                                            {(current_user?.user?.email ||
                                                current_user?.email) && (
                                                <>
                                                    <Box
                                                        className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                                    >
                                                        <Box
                                                            className={`${app_classes.margin.r1}`}
                                                        >
                                                            <img
                                                                src={EmailIcon}
                                                                width={16}
                                                            />
                                                        </Box>
                                                        <Box
                                                            className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                                        >
                                                            <Box
                                                                className={`${app_classes.margin.r1}`}
                                                                style={{
                                                                    filter:
                                                                        (fromExplore ||
                                                                            !applicationStatus) &&
                                                                        'blur(2px)',
                                                                }}
                                                            >
                                                                <JobluTypography.Body
                                                                    primary
                                                                >
                                                                    {' '}
                                                                    {fromExplore ||
                                                                    !applicationStatus
                                                                        ? ` ${generateRandomChars(
                                                                              8
                                                                          )?.toLowerCase()}@mail.com`
                                                                        : current_user
                                                                              ?.user
                                                                              ?.email ||
                                                                          current_user?.email}
                                                                </JobluTypography.Body>
                                                            </Box>
                                                            {!fromExplore &&
                                                                applicationStatus && (
                                                                    <>
                                                                        <ClickAwayListener
                                                                            onClickAway={
                                                                                handleEmailTooltipClose
                                                                            }
                                                                        >
                                                                            <div>
                                                                                <Tooltip
                                                                                    PopperProps={{
                                                                                        disablePortal: true,
                                                                                    }}
                                                                                    onClose={
                                                                                        handleEmailTooltipClose
                                                                                    }
                                                                                    open={
                                                                                        openEmailTooltip
                                                                                    }
                                                                                    disableFocusListener
                                                                                    disableHoverListener
                                                                                    disableTouchListener
                                                                                    title="Copied"
                                                                                >
                                                                                    <svg
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            display:
                                                                                                isFromExplore &&
                                                                                                'none',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            handleEmailTooltipOpen(
                                                                                                current_user
                                                                                                    ?.user
                                                                                                    ?.email ||
                                                                                                    current_user?.email,
                                                                                                'email'
                                                                                            )
                                                                                        }}
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16"
                                                                                        height="16"
                                                                                        fill="currentColor"
                                                                                        class="bi bi-clipboard"
                                                                                        viewBox="0 0 16 16"
                                                                                    >
                                                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                                                    </svg>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </ClickAwayListener>
                                                                    </>
                                                                )}
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                            {current_user?.phoneNumber && (
                                                <>
                                                    <Box
                                                        className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                                    >
                                                        <Box
                                                            className={`${app_classes.margin.r1}`}
                                                        >
                                                            <img
                                                                src={PhoneIcon}
                                                                width={16}
                                                                height={16}
                                                                style={{
                                                                    objectFit:
                                                                        'contain',
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box
                                                            className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center}`}
                                                        >
                                                            <Box
                                                                className={`${app_classes.margin.r1}`}
                                                                style={{
                                                                    filter:
                                                                        (fromExplore ||
                                                                            !applicationStatus) &&
                                                                        'blur(2px)',
                                                                }}
                                                            >
                                                                <JobluTypography.Body
                                                                    primary
                                                                >
                                                                    {fromExplore ||
                                                                    !applicationStatus
                                                                        ? generateRandomChars(
                                                                              13,
                                                                              true
                                                                          )
                                                                        : current_user?.phoneNumber}
                                                                </JobluTypography.Body>
                                                            </Box>
                                                            {!fromExplore &&
                                                            applicationStatus ? (
                                                                <>
                                                                    <ClickAwayListener
                                                                        onClickAway={
                                                                            handlePhoneTooltipClose
                                                                        }
                                                                    >
                                                                        <div>
                                                                            <Tooltip
                                                                                PopperProps={{
                                                                                    disablePortal: true,
                                                                                }}
                                                                                onClose={
                                                                                    handlePhoneTooltipClose
                                                                                }
                                                                                open={
                                                                                    openPhoneTooltip
                                                                                }
                                                                                disableFocusListener
                                                                                disableHoverListener
                                                                                disableTouchListener
                                                                                title="Copied"
                                                                            >
                                                                                <svg
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        display:
                                                                                            fromExplore &&
                                                                                            'none',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handlePhoneTooltipOpen(
                                                                                            current_user
                                                                                                ?.user
                                                                                                ?.email,
                                                                                            'phone'
                                                                                        )
                                                                                    }}
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="16"
                                                                                    height="16"
                                                                                    fill="currentColor"
                                                                                    class="bi bi-clipboard"
                                                                                    viewBox="0 0 16 16"
                                                                                >
                                                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                                                </svg>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </ClickAwayListener>
                                                                </>
                                                            ) : null}
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                        </>
                                        {/* )} */}
                                        {current_user?.dob && (
                                            <>
                                                <Box
                                                    className={`${app_classes.display.display_flex} ${app_classes.flexBox.align_items_center} `}
                                                >
                                                    <Box
                                                        className={`${app_classes.margin.r1}`}
                                                    >
                                                        <img
                                                            src={BirthdayIcon}
                                                            width={16}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <JobluTypography.Body
                                                            primary
                                                        >
                                                            {moment(
                                                                current_user?.dob
                                                            ).format('LL')}
                                                        </JobluTypography.Body>
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <LargeBanner/>
                    <Box className={`${app_classes.display.display_flex}`}>
                        <Box
                            className={`${app_classes.padding.a2} ${
                                app_classes.cursor.pointer
                            } ${classes.tabs} ${
                                activeTab == 'docs' &&
                                app_classes.backgroundColor.bg_white
                            }`}
                            style={{
                                borderBottom:
                                    activeTab == 'docs' && '3px solid #002E5D',
                            }}
                            onClick={() => {
                                setActiveTab('docs')
                            }}
                        >
                            <JobluTypography.Body primary>
                                <strong>Documents</strong>
                            </JobluTypography.Body>
                        </Box>
                        <Box
                            className={`${app_classes.padding.a2} ${
                                app_classes.cursor.pointer
                            } ${classes.tabs} ${
                                activeTab == 'profile' &&
                                app_classes.backgroundColor.bg_white
                            }`}
                            style={{
                                borderBottom:
                                    activeTab == 'profile' &&
                                    '3px solid #002E5D',
                            }}
                            onClick={() => {
                                setActiveTab('profile')
                            }}
                        >
                            <JobluTypography.Body primary>
                                <strong>Profile</strong>
                            </JobluTypography.Body>
                        </Box>
                    </Box>
                    <Box style={{ backgroundColor: '#FFFFFF' }}>
                        {activeTab == 'docs' && (
                            <>
                                <Box
                                    className={`${app_classes.padding.a1}`}
                                    style={{ minHeight: '50vh' }}
                                >
                                    {!filesList || filesList?.length < 1 ? (
                                        <Box
                                            style={{ minHeight: '50vh' }}
                                            className={`${app_classes.display.display_flex} ${app_classes.flexBox.flex_direction_column} ${app_classes.flexBox.align_items_center} ${app_classes.flexBox.justify_content_center}`}
                                        >
                                            <Box
                                                className={`${app_classes.margin.b2}`}
                                            >
                                                <JobluTypography.Caption
                                                    style={{ fontSize: 30 }}
                                                >
                                                    <img
                                                        src={NoDocument}
                                                        width={90}
                                                    />
                                                </JobluTypography.Caption>
                                            </Box>
                                            <Box
                                                className={`${app_classes.margin.b2}`}
                                            >
                                                <JobluTypography.Caption
                                                    style={{ fontSize: 30 }}
                                                >
                                                    The job seeker you're
                                                    viewing hasn't added
                                                </JobluTypography.Caption>
                                            </Box>
                                            <Box>
                                                <JobluTypography.Caption
                                                    style={{ fontSize: 30 }}
                                                >
                                                    their resume to their
                                                    profile.
                                                </JobluTypography.Caption>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <DocViewer
                                            style={{ minHeight: '50vh' }}
                                            documents={
                                                !fromExplore &&
                                                applicationStatus
                                                    ? current_user?.portfolioAndFiles?.map(
                                                          (item) => {
                                                              return {
                                                                  uri: item?.link,
                                                                  fileName: `${current_user?.firstName}_${current_user?.lastName}_Joblu.pdf`,
                                                              }
                                                          }
                                                      )
                                                    : current_user?.filesPreview?.map(
                                                          (item) => {
                                                              return {
                                                                  uri: item?.link,
                                                                  fileName: `${current_user?.firstName}_${current_user?.lastName}_Joblu.pdf`,
                                                              }
                                                          }
                                                      )
                                            }
                                            pluginRenderers={DocViewerRenderers}
                                            prefetchMethod="GET"
                                            config={{
                                                header: {
                                                    disableHeader: false,
                                                    disableFileName: true,
                                                    retainURLParams: true,
                                                },
                                                csvDelimiter: ',', // "," as default,
                                                pdfZoom: {
                                                    defaultZoom: 1, // 1 as default,
                                                    zoomJump: 0.1, // 0.1 as default,
                                                },
                                                pdfVerticalScrollByDefault: false,
                                            }}
                                        />
                                    )}
                                </Box>
                            </>
                        )}
                        {activeTab == 'profile' && (
                            <>
                                <Grid container>
                                    <Grid item xs={12} md={7}>
                                        <Box
                                            className={`${
                                                width < 640
                                                    ? app_classes.padding.a3
                                                    : app_classes.padding.a5
                                            }`}
                                        >
                                            <>
                                                <Box
                                                    className={`${app_classes.margin.b5}`}
                                                >
                                                    <Box
                                                        className={`${app_classes.margin.b2}`}
                                                    >
                                                        <JobluTypography.H4
                                                            primary
                                                        >
                                                            About{' '}
                                                            {
                                                                current_user?.firstName
                                                            }
                                                        </JobluTypography.H4>
                                                    </Box>
                                                    <Box>
                                                        <JobluTypography.H6
                                                            primary
                                                        >
                                                            {current_user?.bio ||
                                                                'No information provided'}
                                                        </JobluTypography.H6>
                                                    </Box>
                                                </Box>
                                            </>

                                            {current_user?.educationHistory
                                                ?.length > 0 ? (
                                                <>
                                                    <Box
                                                        className={`${app_classes.margin.b5}`}
                                                    >
                                                        <Box
                                                            className={`${app_classes.margin.b2}`}
                                                        >
                                                            <JobluTypography.H4
                                                                primary
                                                            >
                                                                Education
                                                            </JobluTypography.H4>
                                                        </Box>
                                                        <Box>
                                                            {current_user?.educationHistory
                                                                ?.sort(
                                                                    function (
                                                                        a,
                                                                        b
                                                                    ) {
                                                                        return (
                                                                            new Date(
                                                                                b.educStartDate
                                                                            ) -
                                                                            new Date(
                                                                                a.educStartDate
                                                                            )
                                                                        )
                                                                    }
                                                                )
                                                                ?.map(
                                                                    (
                                                                        educ,
                                                                        educ_index
                                                                    ) => {
                                                                        return (
                                                                            <>
                                                                                <Box
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#E4ECF3',
                                                                                        borderRadius:
                                                                                            '10px',
                                                                                    }}
                                                                                    className={`${
                                                                                        app_classes
                                                                                            .padding
                                                                                            .a3
                                                                                    } ${
                                                                                        current_user
                                                                                            ?.educationHistory
                                                                                            ?.length >
                                                                                            1 &&
                                                                                        app_classes
                                                                                            .margin
                                                                                            .b2
                                                                                    }`}
                                                                                >
                                                                                    <Box>
                                                                                        <JobluTypography.H6
                                                                                            secondary
                                                                                        >
                                                                                            {
                                                                                                educ?.educField
                                                                                            }
                                                                                        </JobluTypography.H6>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <JobluTypography.Body>
                                                                                            {
                                                                                                educ?.educationType
                                                                                            }
                                                                                        </JobluTypography.Body>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <JobluTypography.Body>
                                                                                            {
                                                                                                educ?.educName
                                                                                            }
                                                                                        </JobluTypography.Body>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <JobluTypography.Body>
                                                                                            {formatDateRange2(
                                                                                                educ?.educStartDate,
                                                                                                educ?.educEndDate
                                                                                            )}
                                                                                        </JobluTypography.Body>
                                                                                    </Box>
                                                                                </Box>
                                                                            </>
                                                                        )
                                                                    }
                                                                )}
                                                        </Box>
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Box width={'100%'}>
                                                        <Box
                                                            className={`${app_classes.margin.b2}`}
                                                        >
                                                            <JobluTypography.H4
                                                                primary
                                                            >
                                                                Education
                                                            </JobluTypography.H4>
                                                        </Box>
                                                        <Box
                                                            width={'100%'}
                                                            style={{
                                                                backgroundColor:
                                                                    '#E4ECF3',
                                                                borderRadius:
                                                                    '10px',
                                                            }}
                                                            className={`${
                                                                app_classes
                                                                    .padding.a3
                                                            } ${
                                                                current_user
                                                                    ?.experienceHistory
                                                                    ?.length >
                                                                    1 &&
                                                                app_classes
                                                                    .margin.b2
                                                            }`}
                                                        >
                                                            <Box>
                                                                <JobluTypography.H6>
                                                                    No
                                                                    information
                                                                    provided.
                                                                </JobluTypography.H6>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                            {current_user?.experienceHistory
                                                ?.length > 0 ? (
                                                <>
                                                    <Box>
                                                        <Box
                                                            className={`${app_classes.margin.b2}`}
                                                        >
                                                            <JobluTypography.H4
                                                                primary
                                                            >
                                                                Experience
                                                            </JobluTypography.H4>
                                                        </Box>
                                                        {current_user?.experienceHistory
                                                            ?.sort(function (
                                                                a,
                                                                b
                                                            ) {
                                                                return (
                                                                    new Date(
                                                                        b.startDate
                                                                    ) -
                                                                    new Date(
                                                                        a.startDate
                                                                    )
                                                                )
                                                            })
                                                            .map(
                                                                (
                                                                    exp,
                                                                    exp_index
                                                                ) => {
                                                                    return (
                                                                        <>
                                                                            <Box
                                                                                width={
                                                                                    '100%'
                                                                                }
                                                                            >
                                                                                <Box
                                                                                    width={
                                                                                        '100%'
                                                                                    }
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#E4ECF3',
                                                                                        borderRadius:
                                                                                            '10px',
                                                                                    }}
                                                                                    className={`${
                                                                                        app_classes
                                                                                            .padding
                                                                                            .a3
                                                                                    } ${
                                                                                        current_user
                                                                                            ?.experienceHistory
                                                                                            ?.length >
                                                                                            1 &&
                                                                                        app_classes
                                                                                            .margin
                                                                                            .b2
                                                                                    }`}
                                                                                >
                                                                                    <Box>
                                                                                        <JobluTypography.H6
                                                                                            secondary
                                                                                        >
                                                                                            {
                                                                                                exp?.companyName
                                                                                            }
                                                                                        </JobluTypography.H6>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <JobluTypography.Body>
                                                                                            {
                                                                                                exp?.jobTitle
                                                                                            }
                                                                                        </JobluTypography.Body>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <JobluTypography.Body>
                                                                                            {
                                                                                                exp?.location
                                                                                            }
                                                                                        </JobluTypography.Body>
                                                                                    </Box>
                                                                                    <Box
                                                                                        className={`${app_classes.margin.b2}`}
                                                                                    >
                                                                                        <JobluTypography.Body>
                                                                                            {formatDateRange2(
                                                                                                exp?.startDate,
                                                                                                exp?.endDate
                                                                                            )}
                                                                                        </JobluTypography.Body>
                                                                                    </Box>
                                                                                    {exp?.responsibilities && (
                                                                                        <>
                                                                                            <Box>
                                                                                                <JobluTypography.Body>
                                                                                                    Responsibilities:
                                                                                                </JobluTypography.Body>
                                                                                            </Box>
                                                                                            <Box
                                                                                                className={`${
                                                                                                    exp?.achievements &&
                                                                                                    app_classes
                                                                                                        .margin
                                                                                                        .b2
                                                                                                }`}
                                                                                            >
                                                                                                <JobluTypography.Body
                                                                                                    style={{
                                                                                                        whiteSpace:
                                                                                                            'pre-wrap',
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        exp?.responsibilities
                                                                                                    }
                                                                                                </JobluTypography.Body>
                                                                                            </Box>
                                                                                        </>
                                                                                    )}
                                                                                    {exp?.achievements && (
                                                                                        <>
                                                                                            <Box>
                                                                                                <JobluTypography.Body>
                                                                                                    Achievements:
                                                                                                </JobluTypography.Body>
                                                                                            </Box>
                                                                                            <Box
                                                                                                className={`${app_classes.margin.b2}`}
                                                                                            >
                                                                                                <JobluTypography.Body
                                                                                                    style={{
                                                                                                        whiteSpace:
                                                                                                            'pre-wrap',
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        exp?.achievements
                                                                                                    }
                                                                                                </JobluTypography.Body>
                                                                                            </Box>
                                                                                        </>
                                                                                    )}
                                                                                </Box>
                                                                            </Box>
                                                                        </>
                                                                    )
                                                                }
                                                            )}
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Box width={'100%'}>
                                                        <Box
                                                            className={`${app_classes.margin.b2}`}
                                                        >
                                                            <JobluTypography.H4
                                                                primary
                                                            >
                                                                Experience
                                                            </JobluTypography.H4>
                                                        </Box>
                                                        <Box
                                                            width={'100%'}
                                                            style={{
                                                                backgroundColor:
                                                                    '#E4ECF3',
                                                                borderRadius:
                                                                    '10px',
                                                            }}
                                                            className={`${app_classes.padding.a3}`}
                                                        >
                                                            <Box>
                                                                <JobluTypography.H6>
                                                                    No
                                                                    information
                                                                    provided.
                                                                </JobluTypography.H6>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        style={{
                                            borderLeft:
                                                width < 640
                                                    ? 'none'
                                                    : '1px solid #8080804D',
                                        }}
                                    >
                                        <Box
                                            className={`${app_classes.padding.a5}`}
                                        >
                                            {(current_user?.employmentType
                                                ?.length > 0 ||
                                                current_user?.workSetup
                                                    ?.length > 0) && (
                                                <>
                                                    <Box
                                                        className={`${app_classes.margin.b5}`}
                                                    >
                                                        <Box
                                                            className={`${app_classes.margin.b2}`}
                                                        >
                                                            <JobluTypography.H4
                                                                primary
                                                            >
                                                                Preference
                                                            </JobluTypography.H4>
                                                        </Box>

                                                        {current_user?.workSetup
                                                            ?.length > 0 && (
                                                            <>
                                                                <Box
                                                                    className={`${app_classes.margin.b3}`}
                                                                >
                                                                    <Box
                                                                        className={`${app_classes.margin.b1}`}
                                                                    >
                                                                        <JobluTypography.H5>
                                                                            Work
                                                                            Setup
                                                                        </JobluTypography.H5>
                                                                    </Box>
                                                                    <Box>
                                                                        <JobluTypography.H6
                                                                            style={{
                                                                                color: '#6E88A3',
                                                                                fontSize:
                                                                                    '16px',
                                                                            }}
                                                                        >
                                                                            {current_user?.workSetup?.join(
                                                                                ', '
                                                                            )}
                                                                        </JobluTypography.H6>
                                                                    </Box>
                                                                </Box>
                                                            </>
                                                        )}
                                                        {current_user
                                                            ?.employmentType
                                                            ?.length > 0 && (
                                                            <>
                                                                <Box
                                                                    className={`${app_classes.margin.b1}`}
                                                                >
                                                                    <JobluTypography.H5>
                                                                        Employment
                                                                        Type
                                                                    </JobluTypography.H5>
                                                                </Box>
                                                                <Box>
                                                                    <JobluTypography.H6
                                                                        style={{
                                                                            color: '#6E88A3',
                                                                            fontSize:
                                                                                '16px',
                                                                        }}
                                                                    >
                                                                        {current_user?.employmentType?.join(
                                                                            ', '
                                                                        )}
                                                                    </JobluTypography.H6>
                                                                </Box>
                                                            </>
                                                        )}
                                                    </Box>
                                                </>
                                            )}
                                            {current_user?.skills?.length >
                                                0 && (
                                                <>
                                                    <Box
                                                        className={`${app_classes.margin.b5}`}
                                                    >
                                                        <Box
                                                            className={`${app_classes.margin.b2}`}
                                                        >
                                                            <JobluTypography.H4
                                                                primary
                                                            >
                                                                Technical Skills
                                                            </JobluTypography.H4>
                                                        </Box>
                                                        <Box>
                                                            {current_user?.skills?.map(
                                                                (skill) => {
                                                                    return (
                                                                        <Chip
                                                                            className={`${classes.chip_active}`}
                                                                            label={
                                                                                <JobluTypography.Caption
                                                                                    className={`${app_classes.text.white}`}
                                                                                >
                                                                                    {
                                                                                        skill
                                                                                    }
                                                                                </JobluTypography.Caption>
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                            {current_user?.softSkills?.length >
                                                0 && (
                                                <>
                                                    <Box
                                                        className={`${app_classes.margin.b5}`}
                                                    >
                                                        <Box
                                                            className={`${app_classes.margin.b2}`}
                                                        >
                                                            <JobluTypography.H4
                                                                primary
                                                            >
                                                                Soft Skills
                                                            </JobluTypography.H4>
                                                        </Box>

                                                        {current_user?.softSkills?.map(
                                                            (skill) => {
                                                                return (
                                                                    <Chip
                                                                        className={`${classes.chip_active}`}
                                                                        label={
                                                                            <JobluTypography.Caption
                                                                                className={`${app_classes.text.white}`}
                                                                            >
                                                                                {
                                                                                    skill
                                                                                }
                                                                            </JobluTypography.Caption>
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </Box>{' '}
                                                </>
                                            )}{' '}
                                            {current_user?.importantForYou
                                                ?.length > 0 && (
                                                <>
                                                    <Box
                                                        className={`${app_classes.margin.b5}`}
                                                    >
                                                        <Box
                                                            className={`${app_classes.margin.b2}`}
                                                        >
                                                            <JobluTypography.H4
                                                                primary
                                                            >
                                                                What I value
                                                                most
                                                            </JobluTypography.H4>
                                                        </Box>

                                                        {current_user?.importantForYou?.map(
                                                            (value) => {
                                                                return (
                                                                    <Chip
                                                                        className={`${classes.chip_active}`}
                                                                        label={
                                                                            <JobluTypography.Caption
                                                                                className={`${app_classes.text.white}`}
                                                                            >
                                                                                {
                                                                                    value
                                                                                }
                                                                            </JobluTypography.Caption>
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>
                </Box>
                {nextStep && (
                    <>
                        <Box className={`${app_classes.margin.b3}`}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} md={3}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        onClick={() => {
                                            navigate('/dashboard')
                                        }}
                                        style={{ textTransform: 'none' }}
                                    >
                                        Take me to Jobs
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={nextStep}
                                        style={{ textTransform: 'none' }}
                                    >
                                        Add more details
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
            </Box>
        </>
    )
}

const mapDispatchToProps = (state) => {
    return { AppState: state.Generals }
}

export default connect(mapDispatchToProps)(ProfilePreview)
