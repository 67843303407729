import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    TextField as MUITextField,
} from '@material-ui/core'
import TextField from '../../components/inputs/CustomTextField'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
//   import PageTitle from "../../components/page-title";
import JobluTypography from '../../components/Typography'
import { app_styles } from '../../general.styles'
import appActions from '../../redux/app/actions'
import userAction from '../../redux/user/actions'
import { userRequest } from '../../service/requests'
import zIndex from '@material-ui/core/styles/zIndex'

const ContactUs = (props) => {
    const app_classes = app_styles(props)
    const { User, setShowAlert, navigation } = props
    const { user } = User
    const navigate = useNavigate()

    const [isUpdating, setIsUpdating] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const [name, setName] = useState(null)
    const [error_Name, setError_Name] = useState(null)

    // const [email, setEmail] = useState(null)
    // const [error_Email, setError_Email] = useState(null)

    const [subject, setSubject] = useState(null)
    const [error_Subject, setError_Subject] = useState(null)

    const [content, setContent] = useState(null)
    const [error_Content, setError_Content] = useState(null)

    const [showSuccess, setShowSuccess] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const sendContactUs = () => {
        // const isNameError = name === null || name === ''
        // setError_Name(isNameError ? 'Name is required' : null)

        // const isEmailError = email === null || email === ''
        // setError_Email(isEmailError ? 'Email is required' : !isEmailPatternValid(email) ? 'Email is invalid' : null)

        const isSubjectError = subject === null || subject === ''
        setError_Subject(isSubjectError ? 'Subject is required' : null)

        const isContentError = content === null || content === ''
        setError_Content(isContentError ? 'Content is required' : null)

        if (isSubjectError || isContentError) {
            return
        }
        setSubmitting(true)
        setIsUpdating(true)
        userRequest
            .sendContactUs({
                name: `${user?.firstName} ${user?.lastName}`,
                email: user?.email,
                subject: subject,
                content: content,
            })
            .then((response) => {
                setTimeout(() => {
                    // setEmail(null)
                    // setSubject('')
                    // setName(null)
                    // setContent('')
                    setSubmitting(false)
                    setShowSuccess(true)
                    setIsSuccessful(true)
                    // setShowAlert({
                    //     message:
                    //         "Thanks for contacting us. We'll be in touch soon!",
                    //     type: 'success',
                    // })
                    setIsUpdating(false)
                }, 1000)
            })
            .catch((err) => {
                setShowSuccess(false)
                setSubmitting(false)
                // console.log(err.response.data)
                setIsUpdating(false)
            })
    }

    return (
        <>
            {/* <PageTitle pageTitle="Settings" /> */}
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={showSuccess}
                style={{ zIndex: '99999999' }}
            >
                <Box style={{ padding: '2rem', textAlign: 'center' }}>
                    <Box style={{ paddingBottom: '1rem' }}>
                        <JobluTypography.H5 alig>
                            Thanks for contacting us. We'll be in touch soon!
                        </JobluTypography.H5>
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ textTransform: 'none' }}
                            onClick={() => setShowSuccess(false)}
                        >
                            Okay
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Grid
                container
                justifyContent="center"
                className={`${app_classes.margin.t3}`}
            >
                <Grid item xs={11} sm={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <JobluTypography.H5 primary semiBold>
                                    Contact Information
                                </JobluTypography.H5>
                            </Box>
                            <Box>
                                <JobluTypography.Body primary semiBold>
                                    Address:
                                </JobluTypography.Body>
                            </Box>
                            <Box className={`${app_classes.margin.b3}`}>
                                <JobluTypography.Body primary>
                                    #22-01 Oxley Towers, 138 Robinson Road,
                                    Singapore 068906
                                </JobluTypography.Body>
                            </Box>
                            <Box>
                                <JobluTypography.Body primary semiBold>
                                    Email Address:
                                </JobluTypography.Body>
                            </Box>
                            <Box className={`${app_classes.margin.b3}`}>
                                <JobluTypography.Body primary>
                                    tech-support@joblu.io
                                </JobluTypography.Body>
                            </Box>
                            <Box>
                                <JobluTypography.H5
                                    primary
                                    semiBold
                                    style={{ marginBottom: 20 }}
                                >
                                    Contact Us
                                </JobluTypography.H5>
                            </Box>
                            <Box>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            className={`${app_classes.margin.b3}`}
                                        >
                                            <Box>
                                                <TextField
                                                    size="small"
                                                    value={subject}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Subject"
                                                    onChange={(e) => {
                                                        setSubject(
                                                            e?.target?.value
                                                        )
                                                    }}
                                                    error={error_Subject}
                                                    helperText={error_Subject}
                                                    nputProps={{
                                                        style: {
                                                            paddingLeft:
                                                                '10px!important',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={`${app_classes.margin.b3}`}>
                                <Box>
                                    <Box>
                                        <textarea
                                            rows={5}
                                            value={content}
                                            className={`w-100 form-control ${
                                                error_Content && 'border-danger'
                                            }`}
                                            placeholder="Say something about your concern"
                                            onChange={(e) => {
                                                setContent(e?.target?.value)
                                            }}
                                        />
                                        {error_Content && (
                                            <span className="error-helper mt-0">
                                                Content is required
                                            </span>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                className={`${app_classes.display.display_flex} ${app_classes.flexBox.justify_content_center}`}
                            >
                                <Box className={`${app_classes.margin.r1}`}>
                                    <Button
                                        onClick={() => navigate('/settings')}
                                        variant="outlined"
                                        className={`${app_classes.button.primary_outline} `}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        variant="contained"
                                        endIcon={
                                            isUpdating && (
                                                <CircularProgress
                                                    size={18}
                                                    color="white"
                                                />
                                            )
                                        }
                                        onClick={sendContactUs}
                                        className={`${app_classes.button.primary}`}
                                    >
                                        {submitting ? 'Sending' : 'Send'}
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}

const mapDispatchToProps = {
    getUser: userAction.getUser,
    setShowAlert: appActions?.showAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
