import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import GoogleLogin from 'react-google-login'
import { connect } from 'react-redux'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import appleBtn from '../../assets/img/jobseeker-register/apple-btn.png'
import gplayBtn from '../../assets/img/jobseeker-register/gplay-btn.png'
import img1 from '../../assets/img/jobseeker-register/img1.png'
import jobluLogo from '../../assets/img/jobseeker-register/logo.png'
import TextField from '../../components/inputs/CustomTextField'
import SelectCountry2 from '../../components/reusables/SelectCountry2'
import rootAction from '../../redux/action'
import appActions from '../../redux/app/actions'
import userAction from '../../redux/user/actions'
import { userRequest } from '../../service/requests'
import { creds } from '../../utils/constants'
import { validateEmail } from '../../utils/validators'
import './styles.css'
import GoogleReCaptcha from '../../components/google-captcha'
const SuccessModal = (props) => {
    const { showSuccessModal, setShowSuccessModal, setShowSignupModal } = props

    const handleClose = () => {
        window.location.href = 'https://joblu.io/'
        setShowSignupModal(false)
    }
    return (
        <>
            <Modal
                show={showSuccessModal}
                centered
                // onHide={() => setShowSuccessModal(false)}
            >
                <Modal.Body>
                    <div className="float-end">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            class="bi bi-x-lg hand"
                            viewBox="0 0 16 16"
                            onClick={handleClose}
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </div>
                    <div className="text-center p-5">
                        <h2 className="mb-4">
                            <strong>You're all set!</strong>
                        </h2>
                        <h5>
                            Let’s get started on your job-seeking journey.
                            Download the app to find new opportunities.
                        </h5>
                        <div className="d-flex justify-content-center mt-4">
                            <div className="me-2">
                                <a href="https://play.google.com/store/apps/details?id=com.joblu">
                                    <img
                                        src={gplayBtn}
                                        alt=""
                                        className="img-fluid"
                                        style={{ height: 50 }}
                                    />
                                </a>
                            </div>
                            <div>
                                <a href="https://apps.apple.com/us/app/joblu/id1598914661">
                                    <img
                                        src={appleBtn}
                                        alt=""
                                        className="img-fluid"
                                        style={{ height: 50 }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
const OnLoadModal = (props) => {
    const { setSmShow, smShow, handleGoogleResponse } = props
    return (
        <>
            <Modal
                size="md"
                show={smShow}
                centered
                backdrop="static"
                keyboard={false}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Body>
                    <div className="text-center py-5">
                        <h6 className="mb-3">
                            How do you want to
                            <br />
                            sign up?
                        </h6>
                        <div className=" mb-3">
                            <button
                                className="btn btn-primary mx-0"
                                onClick={() => setSmShow(false)}
                                style={{ width: 200 }}
                            >
                                Sign up with email
                            </button>
                        </div>
                        <p className="text-center my-2">or</p>
                        <div className="mb-2">
                            <GoogleLogin
                                clientId={creds.GOOGLE_CLIENT_ID}
                                buttonText="Sign up with Google"
                                onSuccess={(res) => {
                                    handleGoogleResponse(res)
                                }}
                                onFailure={(e) => {
                                    console.log(e)
                                }}
                                className="shadow-none border w-200"
                            />
                        </div>
                        <small>
                            By signing up, you agree to the Joblu
                            <br />{' '}
                            <a
                                href="https://joblu.io/terms-of-use/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of Use
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://joblu.io/privacy-policy/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                            .
                        </small>
                        {/* <hr /> */}
                        {/* <p>Already have an account?</p> */}
                        {/* <a href="#" onClick={handleClose}>
                            Sign in here
                        </a> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
const ErrorModal = (props) => {
    const { setShowErrorModal, showErrorModal } = props
    return (
        <Modal show={showErrorModal} centered onHide={()=>{setShowErrorModal(false)}}>
            <Modal.Body className="text-center">
            <div className="float-end">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            class="bi bi-x-lg hand"
                            viewBox="0 0 16 16"
                            onClick={()=>{setShowErrorModal(false)}}
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </div>
                    <div className="p-5">
                        
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-x-circle text-danger mb-4"
                    viewBox="0 0 16 16"
                >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                <h4 className="">Sign up Failed</h4>
                    </div>
            </Modal.Body>
        </Modal>
    )
}
function SignUpApplicant(props) {
    const { signUp, verifyGoogle, User, Generals, setShowSignupModal } = props
    const { isGoogleSignup, isSignedIn, isSigningIn } = User
    const [searchParams, setSearchParams] = useSearchParams()

    const fName = searchParams.get('firstName')
    const lName = searchParams.get('lastName')
    const yearGraduated = searchParams.get('yearGraduated')
    const userEmail = searchParams.get('email')
    const code = searchParams.get('influencerCode')

    const [googleResponse, setGoogleResponse] = useState(null)
    const [googleLoading, setGoogleLaoding] = useState(false)

    const [firstName, setFirstName] = useState(fName || null)
    const [lastName, setLastName] = useState(lName || null)
    const [dateOfBirth, setDateOfBirth] = useState(null)
    const [gender, setGender] = useState(null)
    const [country, setCountry] = useState(null)
    const [mobile, setMobile] = useState(null)
    const [email, setEmail] = useState(userEmail || null)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [influencerCode, setInfluencerCode] = useState(code || null)

    //const [signupWithGoogle, setSignupWithGoogle] = useState(false)
    const [agreetoTerms, setAgreeToTerms] = useState(userEmail ? true : false)
    const [subscribeToEmail, setSubscribeToEmail] = useState(true)

    const [smShow, setSmShow] = useState(userEmail ? false : true)
    const [showErrorModal, setShowErrorModal] = useState(false)

    const [verifiedCaptcha, setVerifiedCaptcha] = useState(false)

    useEffect(() => {
        if (googleResponse?.profileObj) {
            setFirstName(googleResponse?.profileObj?.givenName)
            setLastName(googleResponse?.profileObj?.familyName)
            setEmail(googleResponse?.profileObj?.email)
        }
    }, [googleResponse])

    useEffect(() => {
        if (Generals?.showSignupSuccessModal === true) {
            setShowSuccessModal(true)
        } else {
            setShowSuccessModal(false)
        }
    }, [Generals])

    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const [errors, setErrors] = useState({
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        // gender: null,
        country: null,
        email: null,
        mobile: null,
        password: null,
        influencerCode: null,
    })

    useEffect(() => {
        console.log(agreetoTerms)
    }, [agreetoTerms])
    useEffect(() => {
        let errorsCopy = errors
        if (firstName) {
            errorsCopy = { ...errorsCopy, firstName: '' }
        }
        if (lastName) {
            errorsCopy = { ...errorsCopy, lastName: '' }
        }
        if (email) {
            errorsCopy = { ...errorsCopy, email: '' }
        }
        if (!googleResponse) {
            if (password) {
                errorsCopy = { ...errorsCopy, password: '' }
            }
            if (confirmPassword) {
                errorsCopy = { ...errorsCopy, confirmPassword: '' }
            }
        }
        // if (gender) {
        //     errorsCopy = { ...errorsCopy, gender: '' }
        // }
        if (dateOfBirth) {
            errorsCopy = { ...errorsCopy, dateOfBirth: '' }
        }
        if (country) {
            errorsCopy = { ...errorsCopy, country: '' }
        }
        if (mobile) {
            errorsCopy = { ...errorsCopy, mobile: '' }
        }
        if (influencerCode) {
            errorsCopy = { ...errorsCopy, influencerCode: '' }
        }
        setErrors(errorsCopy)
    }, [
        firstName,
        lastName,
        dateOfBirth,
        country,
        email,
        // gender,
        password,
        confirmPassword,
        mobile,
        influencerCode,
    ])

    const validateFields = async () => {
        let valid = []
        let errors2 = errors

        if (!agreetoTerms) {
            errors2 = {
                ...errors2,
                terms: 'Check to proceed',
            }
        } else if (!!agreetoTerms) {
            errors2 = {
                ...errors2,
                terms: null,
            }
        }
        if (!!influencerCode) {
            await userRequest
                .validateInfluencerCode({ influencerCode })
                .then((res) => {
                    console.log('inf resp', res.data)
                    if (res.data?.valid === true) {
                        errors2 = {
                            ...errors2,
                            influencerCode: null,
                        }
                        valid.push(true)
                    } else {
                        errors2 = {
                            ...errors2,
                            influencerCode:
                                res.data?.error?.message?.split(':')[1],
                        }
                        valid.push(false)
                    }
                })
        } else if (!influencerCode) {
            errors2 = {
                ...errors2,
                influencerCode: null,
            }
            valid.push(true)
        }

        if (!!email === false) {
            console.log('email has no length')
            errors2 = { ...errors2, email: 'Email address is required' }
            valid.push(false)
        } else {
            console.log('email has length')
            if (!validateEmail(email)) {
                console.log('err')
                errors2 = { ...errors2, email: 'Email not valid' }
                valid.push(false)
            } else {
                console.log('err2')
                userRequest
                    .validateEmail({
                        email: email,
                    })
                    .then((res) => {
                        errors2 = { ...errors2, email: null }
                        valid.push(true)
                    })
                    .catch((e) => {
                        errors2 = { ...errors2, email: 'Email already exists' }
                        valid.push(false)
                    })
            }
        }
        if (!!firstName === false || firstName.trim() === '') {
            errors2 = { ...errors2, firstName: 'First name is required' }
            valid.push(false)
        } else {
            errors2 = { ...errors2, firstName: null }
            console.log(firstName)
            valid.push(true)
        }

        if (!!lastName === false || lastName.trim() === '') {
            errors2 = { ...errors2, lastName: 'Last name is required' }
            valid.push(false)
        } else {
            errors2 = { ...errors2, lastName: null }
            valid.push(true)
        }

        if (!googleResponse) {
            if (!!password === false || password?.length < 8) {
                errors2 = {
                    ...errors2,
                    password: 'Password must contain atleast 8 characters',
                }
                valid.push(false)
            } else {
                errors2 = { ...errors2, password: null }

                valid.push(true)
            }
            if (confirmPassword !== password) {
                errors2 = {
                    ...errors2,
                    confirmPassword: 'Passwords do not match!',
                }
                valid.push(false)
            } else {
                errors2 = {
                    ...errors2,
                    confirmPassword: null,
                }
                valid.push(true)
            }
        }

        if (!!country === false || country.trim() === '') {
            errors2 = { ...errors2, country: 'Country is required' }
            valid.push(false)
        } else {
            errors2 = { ...errors2, country: null }
            valid.push(true)
        }

        if (!!mobile === false || mobile.trim() === '') {
            errors2 = { ...errors2, mobile: 'Mobile number is required' }
            valid.push(false)
        } else {
            errors2 = { ...errors2, mobile: null }
            valid.push(true)
        }

        if (!!dateOfBirth === false || dateOfBirth.trim() === '') {
            errors2 = { ...errors2, dateOfBirth: 'Date of birth is required' }
            valid.push(false)
        } else {
            let age = moment().diff(dateOfBirth, 'years')
            if (age < 18) {
                errors2 = {
                    ...errors2,
                    dateOfBirth: 'You must be 18 years old or above to sign up',
                }
                valid.push(false)
            } else {
                errors2 = { ...errors2, dateOfBirth: null }
                valid.push(true)
            }
        }

        setErrors({ ...errors2 })
        console.log(errors2)
        return !valid.includes(false)
    }

    const handleRegisterViaEmail = async () => {
        const isValid = await validateFields()
        if (isValid) {
            let payload = {
                email,
                firstName,
                lastName,
                yearGraduated,
                country: country?.split(':')?.[0],
                currentLocation: country?.split(':')?.[0],
                dob: dateOfBirth,
                // gender,
                phoneNumber: `${country?.split(':')?.[1]}${mobile}`,
                password,
                influencerCode,
                userRole: 'APPLICANT',
                subscribeToEmail: subscribeToEmail
            }
            console.log('payload', payload)
            // signUp(payload)
            userRequest
                .signUp(payload)
                .then((res) => {
                    console.log(res.data)
                    setShowSuccessModal(true)
                })
                .catch((err) => {
                    console.log(err)
                    setShowErrorModal(true)
                })
        }
    }

    const handleGoogleSignup = async () => {
        const valid = await validateFields()
        if (valid) {
            let payload = {
                email: googleResponse?.profileObj?.email,
                firstName,
                lastName,
                yearGraduated,
                country: country?.split(':')?.[0],
                currentLocation: country?.split(':')?.[0],
                dob: dateOfBirth,
                // gender,
                phoneNumber: `${country?.split(':')?.[1]}${mobile}`,
                password,
                influencerCode,
                role: 'APPLICANT',

                //google
                action: 'register',
                platform: 'web',
                idToken: googleResponse?.tokenId,
                deviceToken: googleResponse?.accessToken,
                subscribeToEmail: subscribeToEmail
                //profileUrl: googleResponse?.profileObj?.imageUrl,
            }
            if (!influencerCode) delete payload.influencerCode
            console.log('payload', valid, payload)
            verifyGoogle({ ...payload })
        }
    }

    const handleGoogleResponse = (data) => {
        console.log(data)
        setGoogleResponse(data)
    }

    useEffect(() => {
        if (!!googleResponse) {
            setTimeout(() => {
                setSmShow(false)
            }, 1000)
        }
    }, [googleResponse])

    const [previewMobile, setPreviewMobile] = useState(false)

    useEffect(() => {
        if (previewMobile === true) {
            setTimeout(() => {
                setPreviewMobile(false)
            }, 3000)
        }
    }, [previewMobile])

    useEffect(() => {
        if (!email) {
            setErrors({ ...errors, email: null })
        } else {
            const delayDebounceFn = setTimeout(() => {
                userRequest
                    .validateEmail({
                        email: email,
                    })
                    .then((res) => {
                        setErrors({ ...errors, email: null })
                    })
                    .catch((e) => {
                        setErrors({ email: 'Email already exists' })
                    })
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [email])

    const agreeRef = useRef(null)
    return (
        <>
            <ErrorModal
                showErrorModal={showErrorModal}
                setShowErrorModal={setShowErrorModal}
            />
            <OnLoadModal
                setSmShow={setSmShow}
                smShow={smShow}
                handleGoogleResponse={handleGoogleResponse}
            />
            <SuccessModal
                showSuccessModal={showSuccessModal}
                setShowSuccessModal={setShowSuccessModal}
                setShowSignupModal={setShowSignupModal}
            />
            <div
                style={{ backgroundColor: '#EEF6FD', minHeight: '110vh' }}
                className="mb-0"
            >
                <div className="container h-100 mb-0 py-5">
                    <div
                        className="row gx-5 h-100 mb-0"
                        style={{ backgroundColor: '#EEF6FD' }}
                    >
                        <div className="col-md-5 col-sm-12 ">
                            <div className="d-flex justify-content-center h-100 mt-0 align-items-center">
                                <div className="text-center mt-5 mt-md-0">
                                    <img
                                        src={jobluLogo}
                                        alt=""
                                        className="mb-4 mt-md-0"
                                    />
                                    <div className="d-none d-md-block">
                                        <h1 className="text-primary">
                                            Find your{' '}
                                            <strong>
                                                next great adventure
                                            </strong>{' '}
                                            here
                                        </h1>
                                    </div>
                                    <div className="d-block d-md-none">
                                        <h5 className="text-primary">
                                            Find your{' '}
                                            <strong>
                                                next great adventure
                                            </strong>{' '}
                                            here
                                        </h5>
                                    </div>
                                    <div className="d-none d-md-block text-center">
                                        <img
                                            src={img1}
                                            alt=""
                                            className="mt-3 img-fluid"
                                            style={{
                                                // maxWidth: '496px',
                                                // maxHeight: '496px',
                                                
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7 d-flex align-items-center">
                            <div
                                className="card shadow py-2"
                                style={{ borderRadius: 10 }}
                            >
                                <div className="card-body p-4">
                                    <h3
                                        className="mb-5 text-center text-md-start"
                                        style={{
                                            color: '#27323C',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Job Seeker Registration
                                    </h3>
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <h5
                                                style={{
                                                    color: '#27323C',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Personal Information
                                            </h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <label htmlFor="" className="ms-1">
                                                First Name
                                            </label>
                                            <TextField
                                                type="text"
                                                className="mb-4"
                                                placeholder="First Name"
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                                defaultValue={
                                                    googleResponse
                                                        ? googleResponse
                                                              ?.profileObj
                                                              ?.givenName
                                                        : firstName
                                                }
                                                error={errors?.firstName}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <label htmlFor="" className="ms-1">
                                                Last Name
                                            </label>
                                            <TextField
                                                type="text"
                                                className="mb-4"
                                                placeholder="Last Name"
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                                defaultValue={
                                                    googleResponse
                                                        ? googleResponse
                                                              ?.profileObj
                                                              ?.familyName
                                                        : lastName
                                                }
                                                error={errors?.lastName}
                                            />
                                        </div>
                                        <div
                                            className={`col-sm-12 col-md-6  mb-4 `}
                                        >
                                            <label htmlFor="" className="ms-1">
                                                Birthday
                                            </label>
                                            <input
                                                type="date"
                                                max={moment().format(
                                                    'YYYY-MM-DD'
                                                )}
                                                className={`form-control ${
                                                    errors?.dateOfBirth &&
                                                    'border-danger'
                                                }`}
                                                onChange={(e) => {
                                                    setDateOfBirth(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                            {errors?.dateOfBirth && (
                                                <span className="error-helper mt-0">
                                                    {errors?.dateOfBirth}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            {/* <label htmlFor="" className="ms-1">
                                                Gender
                                            </label>
                                            <select
                                                name=""
                                                className="form-select mb-4"
                                                id=""
                                                onChange={(e) => {
                                                    setGender(e.target.value)
                                                }}
                                            >
                                                <option>Select Gender</option>
                                                <option value={'male'}>
                                                    Male
                                                </option>
                                                <option value="female">
                                                    Female
                                                </option>
                                            </select> */}
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <label htmlFor="" className="ms-1 ">
                                                Country Code
                                            </label>
                                            <SelectCountry2
                                                className=""
                                                value={country}
                                                onChange={(val) => {
                                                    console.log(val)
                                                    setCountry(val)
                                                }}
                                                placeholder="Country"
                                                isSignup
                                                error={errors?.country}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <label htmlFor="" className="ms-1">
                                                Mobile Number
                                            </label>
                                            <TextField
                                                disabled={!country}
                                                type="number"
                                                className="mb-4"
                                                placeholder="Mobile Number"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value.length <=
                                                        12
                                                    ) {
                                                        setPreviewMobile(true)
                                                        setMobile(
                                                            e.target.value
                                                        )
                                                    }
                                                }}
                                                value={mobile}
                                                max={999999999999999}
                                                defaultValue={mobile}
                                                error={errors?.mobile}
                                                prefix={
                                                    country?.split(':')?.[1]
                                                }
                                            />
                                            {previewMobile && (
                                                <span
                                                    style={{ fontSize: 12 }}
                                                >{`Preview: ${
                                                    `${
                                                        country?.split(':')?.[1]
                                                    }` || ''
                                                }${mobile || ''}`}</span>
                                            )}
                                        </div>
                                        <div className="col-12 mt-4 mb-3">
                                            <h5
                                                style={{
                                                    color: '#27323C',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Login Information
                                            </h5>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="" className="ms-1">
                                                Email Address
                                            </label>
                                            <TextField
                                                type="type"
                                                className=" mb-3"
                                                placeholder="Email Address"
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                error={errors?.email}
                                                defaultValue={
                                                    googleResponse
                                                        ? googleResponse
                                                              ?.profileObj
                                                              ?.email
                                                        : email
                                                }
                                                value={email}
                                                disabled={!!googleResponse}
                                            />
                                        </div>
                                        {!googleResponse && (
                                            <>
                                                <div className="col-sm-12 col-md-6">
                                                    <label
                                                        htmlFor=""
                                                        className="ms-1"
                                                    >
                                                        Password
                                                    </label>
                                                    <TextField
                                                        type="password"
                                                        className=" mb-3"
                                                        placeholder="Password"
                                                        onChange={(e) =>
                                                            setPassword(
                                                                e.target.value
                                                            )
                                                        }
                                                        defaultValue={password}
                                                        error={errors?.password}
                                                    />
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <label
                                                        htmlFor=""
                                                        className="ms-1"
                                                    >
                                                        Confirm Password
                                                    </label>
                                                    <TextField
                                                        type="password"
                                                        className=" mb-3"
                                                        placeholder="Confirm Password"
                                                        onChange={(e) =>
                                                            setConfirmPassword(
                                                                e.target.value
                                                            )
                                                        }
                                                        defaultValue={
                                                            confirmPassword
                                                        }
                                                        error={
                                                            errors?.confirmPassword
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <label htmlFor="" className="ms-1">
                                                Influencer Code
                                            </label>
                                            <TextField
                                                disabled={code ? true : false}
                                                type="text"
                                                className=" mb-3"
                                                placeholder="Influencer Code"
                                                onChange={(e) =>
                                                    setInfluencerCode(
                                                        e.target.value
                                                    )
                                                }
                                                defaultValue={influencerCode}
                                                error={errors?.influencerCode}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <div className="form-check mb-4">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="terms"
                                                    defaultChecked={
                                                        agreetoTerms
                                                    }
                                                    onClick={() => {
                                                        setErrors({
                                                            ...errors,
                                                            terms: null,
                                                        })
                                                        setAgreeToTerms(
                                                            !agreetoTerms
                                                        )
                                                    }}
                                                    ref={agreeRef}
                                                />
                                                <label
                                                    className={`${
                                                        errors?.terms &&
                                                        'text-danger'
                                                    } form-check-label`}
                                                    htmlFor="terms"
                                                >
                                                    I agree to the Joblu{' '}
                                                    <a
                                                        href="https://joblu.io/terms-of-use/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className={`${
                                                            errors?.terms &&
                                                            'text-danger'
                                                        } `}
                                                    >
                                                        Terms of Use
                                                    </a>{' '}
                                                    and{' '}
                                                    <a
                                                        href="https://joblu.io/privacy-policy/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className={`${
                                                            errors?.terms &&
                                                            'text-danger'
                                                        } `}
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                    .
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultChecked={
                                                        subscribeToEmail
                                                    }
                                                    onClick={() =>
                                                        setSubscribeToEmail(
                                                            !subscribeToEmail
                                                        )
                                                    }
                                                    id="subscribe"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="subscribe"
                                                >
                                                    Subscribe to our newsletter
                                                    and receive the latest news
                                                    and trends delivered right
                                                    to your inbox!
                                                </label>
                                            </div>
                                        </div>
                                        <GoogleReCaptcha handleVerify={(token) => setVerifiedCaptcha(true)}/>
                                        <div className="col-12 d-flex align-items-center mt-4 flex-column flex-md-row justify-content-between mb-4">
                                            <button
                                                className={`btn btn-primary btn-lg me-0 me-md-3 mb-3 mb-md-0 text-white ${
                                                    !agreetoTerms &&
                                                    'btn-create'
                                                }`}
                                                disabled={!agreetoTerms || !verifiedCaptcha}
                                                onClick={() =>
                                                    googleResponse
                                                        ? handleGoogleSignup()
                                                        : handleRegisterViaEmail()
                                                }
                                            >
                                                Create Job Seeker Account
                                            </button>
                                            <a href="https://joblu.io/" className='text-uppercase'>
                                                Cancel Account Creation
                                            </a>
                                        </div>
                                        <div className='text-center'>
                                            <p style={{fontSize: 16}}>Already have an account? <a href='https://onelink.to/fsapmr' className='text-primary text-underline'>Sign in here</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
        loggedUser: state.loggedUser,
        Generals: state.Generals,
    }
}

const mapDispatchToProps = {
    signIn: userAction.signIn,
    signUp: userAction.signUp,
    openApp: rootAction.openApp,
    setLoggedIn: rootAction.setLoggedIn,
    resetVerification: userAction.resetVerification,
    verifyGoogle: userAction.verifyGoogleToken,
    createEmployerSuccess: userAction.createEmployerSuccess,
    softReset: userAction.softReset,
    resetUserError: userAction.resetUserError,
    setShowSignupModal: appActions.setShowSignupModal,
}
export default connect(mapStateToProps, mapDispatchToProps)(SignUpApplicant)
