import { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import SelectJobPost from '../../components/reusables/SelectJobPost'
import { jobApplicantRequest, jobMatchRequest } from '../../service/requests'
import Applicants from './components/Applicants'
import { errorSwipeActions } from '../../utils/constants'
import './styles.css'
import ExploreModal from '../explore/components/ExploreModal'
import ArchiveIcon from '../../assets/img/matches/archiveIcon.png'

function Archived(props) {
    const navigate = useNavigate()
    const [selectedJobPost, setSelectedJobPost] = useState('')
    const [currentTab, setCurrentTab] = useState('Archived')
    const [appliedApplicants, setAppliedApplicants] = useState([])
    const [shortListedApplicants, setShortListedApplicants] = useState([])
    const [archivedApplicants, setArchivedApplicants] = useState([])
    const [interviewApplicants, setInterviewApplicants] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const tabs = ['Archived']

    const [jobTitle, setJobTitle] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [matchImages, setMatchImages] = useState(null)
    const [applicantName, setApplicantName] = useState(null)

    useEffect(() => {
        if (location?.state?.tab) {
            setCurrentTab(location.state.tab)
            console.log(location.state.tab)
        }
    }, [location])

    useEffect(() => {
        const jobId = new URLSearchParams(location.search).get('job')
        const jobIdFromLocation = location?.state?.data?.data?.jobId
        if (!!jobId) {
            setSelectedJobPost(jobId)
        } else if (!!jobIdFromLocation) {
            setSelectedJobPost(jobIdFromLocation)
        }
    }, [location])

    const getApplicants = () => {
        if (!!selectedJobPost) {
            setIsLoading(true)
            const payload = { jobId: selectedJobPost }
            try {
                if (currentTab === 'Applied') {
                    jobApplicantRequest
                        .getJobApplicants_Applied(payload)
                        .then((res) => {
                            console.log(res?.data)
                            setAppliedApplicants(res?.data?.data)
                            setIsLoading(false)
                        })
                }
                if (currentTab === 'Shortlisted') {
                    jobApplicantRequest
                        .getJobApplicants_Shortlisted(payload)
                        .then((res) => {
                            console.log(res.data)
                            setShortListedApplicants(res?.data?.data)
                            setIsLoading(false)
                        })
                }
                if (currentTab === 'Interviews') {
                    jobApplicantRequest
                        .getJobApplicants_Interview(payload)
                        .then((res) => {
                            console.log(res.data)
                            setInterviewApplicants(res?.data?.data)
                            setIsLoading(false)
                        })
                }
                if (currentTab === 'Archived') {
                    console.log('archived')
                    jobApplicantRequest
                        .getJobApplicants_Archived(payload)
                        .then((res) => {
                            console.log('archived fetched', res?.data?.data)
                            setArchivedApplicants(res?.data?.data)
                            setIsLoading(false)
                        })
                }
            } catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        getApplicants()
    }, [selectedJobPost, currentTab])

    const swipeAction = (payload) => {
        const { job, applicant, action, itemId } = payload
        let applicant_item = document.getElementById(`${itemId}`)

        jobMatchRequest
            .employerSwipe({
                jobId: job._id,
                applicantId: applicant.userId,
                action: action,
            })
            .then((res) => {
                console.log(res.data)
                if (
                    res.data.message ===
                    errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfMonthlyLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfSuperLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED
                ) {
                    setModalVariant('upgradeAccount')
                    setModalOpen(true)
                } else {
                    if (action === 'not_interested') {
                        applicant_item.classList.add('slide-out-left')
                    } else if (action === 'superLike') {
                        applicant_item.classList.add('slide-out-top')
                    } else if (action === 'like') {
                        applicant_item.classList.add('slide-out-right')
                    }
                    setTimeout(() => {
                        getApplicants()
                    }, 500)
                }

                if (res.data.matchStatus === 'match') {
                    // alert('MATCHED!!')
                    const images = {
                        employer: res.data.profileUrl_employer,
                        applicant: res.data.profileUrl_applicant,
                    }

                    setMatchImages(images)
                    setModalVariant('matchModal')
                    setModalOpen(true)
                    setApplicantName(
                        applicant.firstName + ' ' + applicant.lastName
                    )
                    setJobTitle(job.jobTitle)
                }
                // navigation.goBack()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            <div className="row justify-content-center p-0 mx-0">
                <div className="border-bottom ">
                    <div className="container ">
                        <div className="justify-content-between align-items-center row">
                            <div className="col-sm-12 col-md-3 my-3">
                                <h5 className="text-body fw500 mb-2 ">
                                    Archived Applicants
                                </h5>
                            </div>
                            {/* <div className="col-sm-12 col-md-3 text-right pb-3"></div> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-8 d-flex px-0">
                    {' '}
                    <p
                        className="hand p-3"
                        onClick={() => {
                            navigate(`/matches?job=${selectedJobPost}`, {
                                state: {
                                    tab: 'Applied',
                                },
                            })
                        }}

                        style={{ fontSize: 16 }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-left me-2"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                            />
                        </svg>{' '}
                        Back to Applicants{' '}
                    </p>
                </div>
                <div className="col-12 col-md-8 d-flex my-2 px-0  align-items-center">
                    <SelectJobPost
                        onChange={(value) => setSelectedJobPost(value)}
                        value={selectedJobPost}
                        placeholder="Select job post to view applicants"
                    />
                    {/* <div>
                        <img src={ArchiveIcon} alt="" />
                    </div> */}
                </div>
                <div className="col-12 col-md-8 border mt-2 mb-5">
                    {/* <div className="mt-3">
                        <ul
                            className="nav nav-pills nav-fill my-3 m-0"
                            id="myTab"
                            role="tablist"
                        >
                            {tabs.map((tab) => (
                                <li
                                    className="nav-item"
                                    role="presentation"
                                    key={tab}
                                >
                                    <button
                                        className={`nav-link ${
                                            tab === currentTab && 'active'
                                        }`}
                                        data-bs-toggle="tab"
                                        data-bs-target="#active"
                                        type="button"
                                        role="tab"
                                        aria-controls="active"
                                        aria-selected="true"
                                        onClick={() => setCurrentTab(tab)}
                                    >
                                        {tab}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div> */}

                    <div className="mt-1 mx-1 border-0">
                        <Tabs activeKey={currentTab} className="archive-tab">
                            {tabs.map((tab) => {
                                let data = archivedApplicants
                                return (
                                    <Tab eventKey={tab} key={tab}>
                                        <Applicants
                                            applicants={data}
                                            selectedJob={selectedJobPost}
                                            tab={tab}
                                            getApplicants={getApplicants}
                                            isLoading={isLoading}
                                            swipeAction={swipeAction}
                                        />
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div>
                </div>
                <ExploreModal
                    showModal={modalOpen}
                    variant={modalVariant}
                    matchImages={matchImages}
                    setModalVariant={setModalVariant}
                    setShowModal={setModalOpen}
                    applicantName={applicantName}
                    jobTitle={jobTitle}
                />
            </div>
        </>
    )
}
export default Archived
