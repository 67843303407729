import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import editIcon from '../../../assets/img/editIcon.svg'
import employmentTypeIcon from '../../../assets/img/employmentTypeIcon.svg'
import foodAllowanceIcon from '../../../assets/img/foodAllowanceIcon.svg'
import housingIcon from '../../../assets/img/housingIcon.svg'
import { default as JobIcon } from '../../../assets/img/jobIcon.svg'
import locationIcon from '../../../assets/img/locationIcon.svg'
import startDateIcon from '../../../assets/img/startDateIcon.svg'
import transportAllowanceIcon from '../../../assets/img/transportAllowanceIcon.svg'
import workSetupIcon from '../../../assets/img/workSetupIcon.svg'
import workShiftIcon, {
    default as skillsIcon,
} from '../../../assets/img/workShiftIcon.svg'
import CustomAlert from '../../../components/reusables/Alert/Alert'
import CustomChip from '../../../components/reusables/Chip'
import CreateJobButton from '../../../components/reusables/CreateJobButton'
import { jobsRequest } from '../../../service/requests'
import {
    profilePlaceholder_Employer,
    salaryRanges,
} from '../../../utils/constants'
import { formatMoney, titleCase } from '../../../utils/helpers'
import '../styles.css'
import { getContent, JobModal } from './DataTable'
import WorkSetupIcon from '../../../assets/img/my-jobs/WorkSetupIcon.png'
import EducationIcon from '../../../assets/img/my-jobs/EducationIcon.png'
import EmploymentTypeIcon from '../../../assets/img/my-jobs/EmploymentTypeIcon.png'
import ExperienceIcon from '../../../assets/img/my-jobs/ExperienceIcon.png'
import IndustryIcon from '../../../assets/img/my-jobs/IndustryIcon.png'
import LocationIcon from '../../../assets/img/my-jobs/LocationIcon.png'
import SalaryIcon from '../../../assets/img/my-jobs/SalaryIcon.png'
import DefaultCover from '../../../assets/img/my-jobs/DefaultCover.png'
import ExploreIcon from '../../../assets/img/my-jobs/ExploreIcon.png'
import ApplicantsIcon from '../../../assets/img/my-jobs/ApplicantsIcon.png'
import ShareButton from '../../../assets/img/my-jobs/ShareButton.png'
import EditIcon from '../../../assets/img/my-jobs/EditIcon.png'
import CopyLinkIcon from '../../../assets/img/my-jobs/CopyLinkIcon.png'
import ShareIcon from '../../../assets/img/my-jobs/ShareIcon.png'
import DefaultImg from '../../../assets/img/defaultImg.png'
import { Modal } from 'react-bootstrap'
import { Box, Dialog, Button } from '@material-ui/core'
import JobluTypography from '../../../components/Typography'
import premiumBg from '../../../assets/img/createJob/Go_Premium_BG.png'
import CloseWhite from '../../../assets/img/CloseWhite.png'

function MyJobsinfo(props) {
    const { User, AppState } = props
    const location = useLocation()
    const [selectedJob, setSelectJob] = useState({})
    const navigate = useNavigate()
    useEffect(() => {
        if (location.state?.job) {
            setSelectJob(location.state.job)
        }
    }, [location])

    useEffect(() => {
        console.log(selectedJob)
        console.log(User)
    }, [selectedJob, User])

    const [showModal, setShowModal] = useState(false)
    const [showAlert, setShowAlert] = useState(null)

    const handleProceedModal = () => {
        try {
            if (!!showModal) {
                let payload = showModal?.data
                let status = showModal?.status
                delete payload.updatedAt
                console.log(status)
                console.log(getContent(showModal)?.alert)
                if (status === 'repost') {
                    delete payload.createdAt
                    delete payload.status
                    jobsRequest
                        .createJob(payload)
                        .then((res) => {
                            setShowAlert(`${getContent(showModal)?.alert}`)
                            setTimeout(() => {
                                setShowModal(null)
                            }, 1000)
                            setTimeout(() => {
                                setShowAlert(null)
                                navigate(`/jobs/${res?.data?.id}`, {
                                    state: {
                                        job: res.data,
                                    },
                                })
                            }, 3000)
                        })
                        .catch((err) => {
                            console.log('ERROR when reposting job', err)
                        })
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const { currencyList, currency } = AppState
    const currencyOptions = {
        currencyList,
        currency,
    }

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [copied, setCopied] = useState(false)
    const copyLink = (data) => {
        navigator.clipboard.writeText(data)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }
    const [showPremiumModal, setShowPremiumModal] = useState(false)
    return (
        <>
            <Dialog
                open={showPremiumModal}
                fullWidth
                maxWidth={'sm'}
                onClose={() => {
                    setShowPremiumModal(false)
                }}
                style={{ zIndex: '99999999' }}
            >
                <Box
                    style={{
                        background: `url('${premiumBg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            textAlign: 'right',
                            padding: '0.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={CloseWhite}
                            width={36}
                            onClick={() => {
                                setShowPremiumModal(false)
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            padding: '0 3rem 3rem 3rem',
                        }}
                    >
                        <JobluTypography.H1 semiBold>Uh-oh!</JobluTypography.H1>
                        <JobluTypography.H1
                            semiBold
                            style={{ marginBottom: '1rem' }}
                        >
                            Need to post more jobs?
                        </JobluTypography.H1>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 20 }}
                        >
                            With our Joblu Premium plan, you can enjoy the
                            luxury of unlimited job postings & many more!
                        </JobluTypography.H4>
                        <JobluTypography.H6
                            semiBold
                            style={{ marginBottom: 20, color: '#D7A048' }}
                        >
                            Upgrade now and unlock endless possibilities for
                            your hiring needs!
                        </JobluTypography.H6>
                        <Button
                            variant="contained"
                            style={{
                                background: '#D7A048',
                                color: '#fff',
                                padding: '1rem 3rem',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                navigate('/pricing')
                            }}
                        >
                            Discover Joblu Premium
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Modal show={show} centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <h5 className="mb-0">Share this Job</h5>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <input
                        type="text"
                        className="w-100 text-center mb-3"
                        disabled
                        value={`https://jobs.joblu.io/job/${selectedJob?.referenceId}`}
                    />
                    <div className="d-flex justify-content-between">
                        <div>
                            <button
                                className="btn btn-outline-info nh me-3"
                                style={{
                                    border: '1px solid #009CDE',
                                    color: '#009CDE',
                                }}
                                onClick={() => {
                                    copyLink(
                                        `https://jobs.joblu.io/job/${selectedJob?.referenceId}`
                                    )
                                }}
                            >
                                <img
                                    src={CopyLinkIcon}
                                    width={24}
                                    className="me-2"
                                />
                                Copy Link
                            </button>
                            {copied && (
                                <small className="text-success">
                                    Link Copied!
                                </small>
                            )}
                        </div>
                        <button
                            className="btn btn-primary nh"
                            onClick={handleClose}
                        >
                            Done
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="row">
                <CustomAlert
                    show={!!showAlert}
                    body={showAlert}
                    position="bottom-end"
                />
                <div className=" col-12 row shadow-sm d-flex justify-content-center px-0 py-3">
                    <div className="col-12 col-sm-9 row ">
                        <div className="row p-0 m-0 p-0">
                            <div className="col-12 col-sm-6 d-flex flex-column">
                                <nav
                                    aria-label="breadcrumb"
                                    className="breadcrumb-con p-0"
                                >
                                    <ol className="breadcrumb m-0 d-flex p-auto ">
                                        <li className="breadcrumb-item my-auto">
                                            <Link
                                                to="/jobs"
                                                className=" py-auto text-decoration-none text-secondary breadcrumb-text"
                                            >
                                                My Jobs
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item my-auto">
                                            <Link
                                                to="/jobs"
                                                className="text-decoration-none text-dark   breadcrumb-text breadcrumb-text"
                                            >
                                                View
                                            </Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-12 col-sm-6 d-flex justify-content-end p-0">
                                <CreateJobButton
                                    setShowPremiumModal={setShowPremiumModal}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center my-5">
                <div className="col-sm-11 col-md-9">
                    <div className="card">
                        <div
                            className="header"
                            style={{
                                backgroundImage: `url('${
                                    User?.user?.coverUrl || DefaultCover
                                }')`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                height: '300px',
                            }}
                        ></div>
                        <div className="row ">
                            <div className="col-sm-12 col-md-7 p-5">
                                <div className="text-center mb-4">
                                    {User?.user?.email ===
                                    'connect@joblu.io' ? (
                                        <img
                                            src={
                                                selectedJob?.companyLogo ||
                                                DefaultImg
                                            }
                                            className={`rounded-circle mb-4`}
                                            width={150}
                                            height={150}
                                            style={{
                                                objectFit: 'cover',
                                                marginTop: '-120px',
                                                border: '10px solid #C9CCCE',
                                                background: '#FFF',
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={
                                                User?.user?.profileUrl ||
                                                DefaultImg
                                            }
                                            className={`rounded-circle mb-4`}
                                            width={150}
                                            height={150}
                                            style={{
                                                objectFit: 'cover',
                                                marginTop: '-120px',
                                                border: '10px solid #C9CCCE',
                                                background: '#FFF',
                                            }}
                                        />
                                    )}
                                    <h4> {titleCase(selectedJob?.jobTitle)}</h4>
                                    <span
                                        class="badge mb-3 rounded-pill "
                                        style={{
                                            backgroundColor: '#009CDE',
                                            border: 0,
                                        }}
                                    >
                                        <p className="mb-0 text-white px-2">
                                            {selectedJob?.companyName ||
                                                User?.user?.companyName}
                                        </p>
                                    </span>
                                    <p className="text-muted mb-2 text-uppercase">
                                        JOB ID: {selectedJob?.referenceId}
                                    </p>
                                    {User?.user?.companyWebsite && (
                                        <a
                                            href={User?.user?.companyWebsite}
                                            target="_blank"
                                            style={{
                                                color: '#009CDE!important',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#009CDE!important',
                                                }}
                                            >
                                                Visit their website
                                            </span>
                                        </a>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <h5>Responsibilities</h5>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>
                                        {' '}
                                        {selectedJob?.responsibilities}
                                    </p>
                                </div>
                                {!selectedJob?.comapnyName && (
                                    <>
                                        <div className="">
                                            <h5>
                                                About {User?.user?.companyName}
                                            </h5>
                                            <p
                                                style={{
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                            >
                                                {' '}
                                                {User?.user?.companyOverview}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className="col-sm-12 col-md-5 p-5"
                                style={{ borderLeft: '1px solid #C9CCCE' }}
                            >
                                <div className="mb-4 d-none d-md-block">
                                    <div className="d-flex justify-content-end flex-column flex-md-row mb-3">
                                        <button
                                            className="btn mb-2 mb-md-0 text-center btn-outline-primary nh btn-sm py-2 rounded-pill me-2"
                                            onClick={() => {
                                                navigate(
                                                    `/dashboard?job=${selectedJob?.id}`
                                                )
                                            }}
                                        >
                                            <img
                                                width={24}
                                                className="me-2"
                                                src={ExploreIcon}
                                            />
                                            Explore Candidates
                                        </button>
                                        <button
                                            className="btn mb-2 mb-md-0 text-center btn-outline-primary nh btn-sm py-2 rounded-pill me-2"
                                            onClick={() => {
                                                navigate(
                                                    `/matches?job=${selectedJob?.id}`
                                                )
                                            }}
                                        >
                                            <img
                                                width={24}
                                                className="me-2"
                                                src={ApplicantsIcon}
                                            />
                                            View Applicants
                                        </button>
                                        <img
                                            src={ShareButton}
                                            className={'hand'}
                                            width={40}
                                            height={40}
                                            style={{ objectFit: 'contain' }}
                                            onClick={handleShow}
                                        />
                                    </div>
                                    <div className="d-grid">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => {
                                                navigate(`/jobs/edit`, {
                                                    state: {
                                                        job: selectedJob,
                                                    },
                                                })
                                            }}
                                        >
                                            <img
                                                width={24}
                                                className="me-2"
                                                src={EditIcon}
                                            />
                                            Edit Job
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <h5 className="mb-4">Job Summary</h5>
                                    <div>
                                        <div className="d-flex mb-4">
                                            <div className="me-3">
                                                <img
                                                    src={LocationIcon}
                                                    width={33}
                                                />
                                            </div>
                                            <div className="">
                                                <h6 className="mb-0">
                                                    {selectedJob?.location}
                                                </h6>
                                                <p className="small">
                                                    Location
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="me-3">
                                                <img
                                                    src={IndustryIcon}
                                                    width={33}
                                                />
                                            </div>
                                            <div className="">
                                                <h6 className="mb-0">
                                                    {selectedJob?.jobFunction}
                                                </h6>
                                                <p className="small">
                                                    Industry
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <h5 className="mb-4">What We Offer</h5>
                                    <div>
                                        {selectedJob?.salaryMin &&
                                            selectedJob.salaryMax && (
                                                <>
                                                    <div className="d-flex mb-4">
                                                        <div className="me-3">
                                                            <img
                                                                src={SalaryIcon}
                                                                width={33}
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <h6 className="mb-0">
                                                                {' '}
                                                                {selectedJob?.salaryMin &&
                                                                    selectedJob.salaryMax && (
                                                                        <>
                                                                            {selectedJob?.salaryMin ===
                                                                                1 &&
                                                                            selectedJob?.salaryMax ===
                                                                                1
                                                                                ? salaryRanges.COMPETIIVE_SALARY
                                                                                : selectedJob?.salaryMin ===
                                                                                      2 &&
                                                                                  selectedJob?.salaryMax ===
                                                                                      2
                                                                                ? salaryRanges.INDUSTRY_STANDARD
                                                                                : `${formatMoney(
                                                                                      selectedJob?.salaryMin,
                                                                                      currencyOptions,
                                                                                      selectedJob?.salaryCurrency ||
                                                                                          'SGD'
                                                                                  )} - ${formatMoney(
                                                                                      selectedJob?.salaryMax,
                                                                                      currencyOptions,
                                                                                      selectedJob?.salaryCurrency ||
                                                                                          'SGD'
                                                                                  )}`}
                                                                        </>
                                                                    )}
                                                            </h6>
                                                            <p className="small">
                                                                Compensation -
                                                                Salary
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        {selectedJob?.employmentType?.length >
                                            0 && (
                                            <>
                                                <div className="d-flex mb-4">
                                                    <div className="me-3">
                                                        <img
                                                            src={
                                                                EmploymentTypeIcon
                                                            }
                                                            width={33}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <h6 className="mb-0">
                                                            {selectedJob?.employmentType?.join(
                                                                ', '
                                                            )}
                                                        </h6>
                                                        <p className="small">
                                                            Employment Type
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {selectedJob?.workSetup?.length > 0 && (
                                            <>
                                                <div className="d-flex">
                                                    <div className="me-3">
                                                        <img
                                                            src={WorkSetupIcon}
                                                            width={33}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <h6 className="mb-0">
                                                            {selectedJob?.workSetup?.join(
                                                                ', '
                                                            )}
                                                        </h6>
                                                        <p className="small">
                                                            Work Setup
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <h5 className="mb-4">Requirements</h5>
                                    <div>
                                        {selectedJob?.education?.length > 0 && (
                                            <>
                                                <div className="d-flex mb-4">
                                                    <div className="me-3">
                                                        <img
                                                            src={EducationIcon}
                                                            width={33}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <h6 className="mb-0">
                                                            {selectedJob?.education?.join(
                                                                ', '
                                                            )}
                                                        </h6>
                                                        <p className="small">
                                                            Education
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {selectedJob?.totalExperienceMin !==
                                            null &&
                                            selectedJob?.totalExperienceMax !==
                                                null && (
                                                <>
                                                    <div className="d-flex">
                                                        <div className="me-3">
                                                            <img
                                                                src={
                                                                    ExperienceIcon
                                                                }
                                                                width={33}
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <h6 className="mb-0">
                                                                {selectedJob?.totalExperienceMin ===
                                                                    0 &&
                                                                selectedJob?.totalExperienceMax ===
                                                                    1 ? (
                                                                    selectedJob?.totalExperienceMin +
                                                                    ' - ' +
                                                                    selectedJob?.totalExperienceMax +
                                                                    ' Year'
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            selectedJob?.totalExperienceMin
                                                                        }{' '}
                                                                        -{' '}
                                                                        {
                                                                            selectedJob?.totalExperienceMax
                                                                        }{' '}
                                                                        Years
                                                                    </>
                                                                )}
                                                            </h6>
                                                            <p className="small">
                                                                Work Experience
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                </div>
                                {selectedJob?.technicalSkills?.length > 0 && (
                                    <>
                                        <div className="mb-4">
                                            <h5 className="mb-4">
                                                Required Skills & Expertise
                                            </h5>

                                            <div>
                                                <CustomChip
                                                    data={
                                                        selectedJob?.technicalSkills
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {selectedJob?.perksAndBenefits?.length > 0 && (
                                    <>
                                        <div className="mb-4">
                                            <h5 className="mb-4">
                                                Perks and Benefits
                                            </h5>
                                            <div>
                                                <CustomChip
                                                    data={
                                                        selectedJob?.perksAndBenefits
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="mb-4 d-block d-md-none">
                                    <div className="d-flex justify-content-end flex-column flex-md-row mb-3">
                                        <button
                                            className="btn mb-2 mb-md-0 text-center text-md-start btn-outline-primary nh btn-sm py-2 rounded-pill me-2"
                                            onClick={() => {
                                                navigate(
                                                    `/dashboard?job=${selectedJob?.id}`
                                                )
                                            }}
                                        >
                                            <img
                                                width={24}
                                                className="me-2"
                                                src={ExploreIcon}
                                            />
                                            Explore Candidates
                                        </button>
                                        <button
                                            className="btn mb-2 mb-md-0 text-center text-md-start btn-outline-primary nh btn-sm py-2 rounded-pill me-2"
                                            onClick={() => {
                                                navigate(
                                                    `/matches?job=${selectedJob?.id}`
                                                )
                                            }}
                                        >
                                            <img
                                                width={24}
                                                className="me-2"
                                                src={ApplicantsIcon}
                                            />
                                            View Applicants
                                        </button>
                                        <button
                                            className="btn mb-2 mb-md-0 text-center text-md-start btn-outline-info nh btn-sm py-2 rounded-pill me-2"
                                            onClick={handleShow}
                                        >
                                            <img
                                                width={24}
                                                className="me-2"
                                                src={ShareIcon}
                                            />
                                            Share Job
                                        </button>
                                        {/* <img
                                            src={ShareButton}
                                            className={'hand'}
                                            width={40}
                                            height={40}
                                            style={{objectFit: 'contain'}}
                                            onClick={handleShow}
                                        /> */}
                                    </div>
                                    <div className="d-grid">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => {
                                                navigate(`/jobs/edit`, {
                                                    state: {
                                                        job: selectedJob,
                                                    },
                                                })
                                            }}
                                        >
                                            <img
                                                width={24}
                                                className="me-2"
                                                src={EditIcon}
                                            />
                                            Edit Job
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center d-none">
                <div className=" col-12 col-sm-9 row mt-5">
                    <div className="col-md-8">
                        <div className="card mb-3">
                            <div className="card-body row">
                                <div className="col-md-12 d-flex flex-column flex-md-row align-items-center w-100">
                                    <div
                                        // style="text-align: center"
                                        className="me-2 text-center"
                                    >
                                        {User?.user?.email ===
                                        'connect@joblu.io' ? (
                                            <img
                                                src={
                                                    selectedJob?.companyLogo ||
                                                    profilePlaceholder_Employer
                                                }
                                                alt=""
                                                style={{
                                                    width: 90,
                                                    height: 90,
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={
                                                    User?.user?.profileUrl ||
                                                    profilePlaceholder_Employer
                                                }
                                                alt=""
                                                style={{
                                                    width: 90,
                                                    height: 90,
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="ml-1 w-100">
                                        <div className="float-none float-md-end text-center text-md-end">
                                            {selectedJob?.salaryMin && (
                                                <h5 className="fs20 fw600">
                                                    {selectedJob?.salaryMin &&
                                                        selectedJob.salaryMax && (
                                                            <>
                                                                {selectedJob?.salaryMin ===
                                                                    1 &&
                                                                selectedJob?.salaryMax ===
                                                                    1
                                                                    ? salaryRanges.COMPETIIVE_SALARY
                                                                    : selectedJob?.salaryMin ===
                                                                          2 &&
                                                                      selectedJob?.salaryMax ===
                                                                          2
                                                                    ? salaryRanges.INDUSTRY_STANDARD
                                                                    : `${formatMoney(
                                                                          selectedJob?.salaryMin,
                                                                          currencyOptions,
                                                                          selectedJob?.salaryCurrency ||
                                                                              'SGD'
                                                                      )} - ${formatMoney(
                                                                          selectedJob?.salaryMax,
                                                                          currencyOptions,
                                                                          selectedJob?.salaryCurrency ||
                                                                              'SGD'
                                                                      )}`}
                                                            </>
                                                        )}
                                                    {/* {formatMoney(
                                                    selectedJob?.salaryMin
                                                )}{' '}
                                                -{' '}
                                                {formatMoney(
                                                    selectedJob.salaryMax
                                                )} */}
                                                    {/* OLD */}
                                                    {/* {selectedJob.salaryMin === 1 &&
                                                selectedJob?.salaryMax === 1
                                                    ? salaryRanges.COMPETIIVE_SALARY
                                                    : selectedJob.salaryMin ===
                                                          2 &&
                                                      selectedJob?.salaryMax ===
                                                          2
                                                    ? salaryRanges.INDUSTRY_STANDARD
                                                    : selectedJob.salaryMin ===
                                                      1
                                                    ? `Less than ${formatMoney(
                                                          selectedJob?.salaryMax
                                                      )}`
                                                    : selectedJob.salaryMin <
                                                      3000
                                                    ? `${formatMoney(
                                                          selectedJob.salaryMin
                                                      )} - ${formatMoney(
                                                          selectedJob.salaryMax
                                                      )}`
                                                    : `More than ${formatMoney(
                                                          selectedJob.salaryMin
                                                      )}`} */}
                                                </h5>
                                            )}
                                            <p className="text-grey-50 text-center text-md-end">
                                                Salary Range
                                            </p>
                                        </div>
                                        <h4 className="text-primary text-center text-md-start mb-0 fw500">
                                            {titleCase(selectedJob?.jobTitle)}
                                        </h4>
                                        <p className="text-primary text-center text-md-start mb-0 fw600">
                                            <strong>
                                                {titleCase(
                                                    selectedJob?.jobFunction
                                                )}
                                            </strong>
                                        </p>

                                        {selectedJob.companyName && (
                                            <p className="text-grey-50 text-center text-md-start">
                                                Company:{' '}
                                                {selectedJob?.companyName}
                                            </p>
                                        )}

                                        <p className="text-grey-50 text-center text-md-start mb-3">
                                            JOB ID:{' '}
                                            {selectedJob?.referenceId?.toUpperCase()}
                                        </p>
                                        <div className="d-flex flex-column flex-md-row">
                                            <div className="d-flex align-items-center mb-2 mb-md-0 justify-content-center justify-content-md-start">
                                                <div>
                                                    <img
                                                        src={locationIcon}
                                                        style={{
                                                            height: 24,
                                                        }}
                                                        className="h-24 me-2"
                                                        alt=""
                                                    />
                                                </div>
                                                <div>
                                                    <p className="fs14 fw400">
                                                        <p>
                                                            {selectedJob?.location ||
                                                                User?.user
                                                                    ?.country}
                                                        </p>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3 d-block d-md-none">
                            <div className="card-body">
                                <div className="d-flex justify-content-around">
                                    {selectedJob.status === 'closed' && (
                                        <div>
                                            <div className="text-center">
                                                <a
                                                    href="#repost"
                                                    className="view-anchor text-decoration-none"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-view-list"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z" />
                                                    </svg>
                                                    <p className="small">
                                                        Repost this job
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {selectedJob.status === 'active' && (
                                        <div>
                                            <div className="text-center">
                                                <a
                                                    href=""
                                                    className="view-anchor text-decoration-none"
                                                    onClick={() => {
                                                        navigate(
                                                            `/dashboard?job=${selectedJob?.id}`
                                                        )
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        fill="currentColor"
                                                        className="bi bi-view-list"
                                                        viewBox="0 0 16 16"
                                                        role="link"
                                                    >
                                                        <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z" />
                                                    </svg>
                                                    <p className="small">
                                                        Explore candidates
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {selectedJob.status !== 'archived' && (
                                        <div>
                                            <div className="text-center">
                                                <a
                                                    href=""
                                                    className="view-anchor text-decoration-none"
                                                    onClick={() => {
                                                        navigate(
                                                            `/matches?job=${selectedJob?.id}`
                                                        )
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        fill="currentColor"
                                                        className="bi bi-people mx-auto"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                                    </svg>
                                                    <p className="small text-nowrap">
                                                        View applicants
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="mt-3">
                                    <button
                                        className="btn btn-primary w-100 d-flex justify-content-center"
                                        onClick={() => {
                                            navigate(`/jobs/edit`, {
                                                state: {
                                                    job: selectedJob,
                                                },
                                            })
                                        }}
                                    >
                                        <img
                                            src={editIcon}
                                            alt=""
                                            style={{ width: 20, height: 20 }}
                                            className="my-auto"
                                        />
                                        <span className="m-0 mx-1 my-auto">
                                            Edit
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="mb-3">
                                    <h6 className="fs18">Job Description</h6>
                                </div>
                                <div className="mb-3">
                                    <p className="fwb">Responsibilities</p>
                                    <p style={{ whiteSpace: 'pre-line' }}>
                                        {selectedJob?.responsibilities}
                                    </p>
                                </div>
                                <div className="mb-3">
                                    <p className="fwb">
                                        Requirements and Educational
                                        Qualifications
                                    </p>
                                    <p
                                        style={{ whiteSpace: 'pre-line' }}
                                        className="text-capitalize"
                                    >
                                        {selectedJob?.education?.join(', ')}
                                    </p>
                                </div>
                                <div className="mb-3">
                                    <p className="fwb mb-3">What We Offer</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex mb-3 align-items-center">
                                                <div>
                                                    <img
                                                        src={employmentTypeIcon}
                                                        className="h-24 me-3"
                                                        alt=""
                                                    />
                                                </div>
                                                <div>
                                                    <p className="fwn fs14">
                                                        Employment Type
                                                    </p>
                                                    <p className="text-capitalize">
                                                        {selectedJob?.employmentType?.join(
                                                            ', '
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex mb-3 align-items-center">
                                                <div>
                                                    <img
                                                        src={workSetupIcon}
                                                        className="h-24 me-3"
                                                        alt=""
                                                    />
                                                </div>
                                                <div>
                                                    <p className="fwn fs14">
                                                        Work Setup
                                                    </p>
                                                    <p className="text-capitalize">
                                                        {selectedJob?.workSetup &&
                                                            selectedJob?.workSetup?.join(
                                                                ', '
                                                            )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {selectedJob?.workShift && (
                                            <div className="col-md-6">
                                                <div className="d-flex mb-3 align-items-center">
                                                    <div>
                                                        <img
                                                            src={workShiftIcon}
                                                            className="h-24 me-3"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="fwn fs14">
                                                            Work Shift
                                                        </p>
                                                        <p>
                                                            {selectedJob?.workShift ||
                                                                'None'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selectedJob?.workWeek && (
                                            <div className="col-md-6">
                                                <div className="d-flex mb-3 align-items-center">
                                                    <div>
                                                        <img
                                                            src={workShiftIcon}
                                                            className="h-24 me-3"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="fwn fs14">
                                                            Work Week
                                                        </p>

                                                        <p>
                                                            {selectedJob?.workWeek ||
                                                                'None'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selectedJob?.expectedStartDate && (
                                            <div className="col-md-6">
                                                <div className="d-flex mb-3 align-items-center">
                                                    <div>
                                                        <img
                                                            src={startDateIcon}
                                                            className="h-24 me-3"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="fwn fs14">
                                                            Expected Start Date
                                                        </p>
                                                        <p>
                                                            {selectedJob?.expectedStartDate
                                                                ? moment(
                                                                      selectedJob?.expectedStartDate
                                                                  ).format(
                                                                      'MMM DD, YYYY'
                                                                  )
                                                                : 'None'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <p className="fwb mb-3">Other Benefits</p>
                                    <CustomChip
                                        data={selectedJob?.perksAndBenefits}
                                    />
                                    {/* <p className='text-capitalize'>{selectedJob?.perksAndBenefits?.join(', ')}</p> */}
                                </div>
                            </div>
                        </div>
                        {selectedJob?.food ||
                            selectedJob?.housing ||
                            (selectedJob?.transport && (
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <p className="fw600 mb-3">
                                            Additional Information
                                        </p>
                                        <div className="row">
                                            {selectedJob?.food && (
                                                <div className="col-md-3">
                                                    <div className="d-flex mb-3 align-items-center">
                                                        <div>
                                                            <img
                                                                src={
                                                                    foodAllowanceIcon
                                                                }
                                                                alt=" "
                                                                style={{
                                                                    height: 24,
                                                                }}
                                                                className="my-auto me-3"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="fwn fs14">
                                                                Food Allowance
                                                            </p>

                                                            <p className=" ">
                                                                {' '}
                                                                {selectedJob?.food
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedJob?.transport && (
                                                <div className="col-md-3">
                                                    <div className="d-flex mb-3 align-items-center">
                                                        <div>
                                                            <img
                                                                src={
                                                                    transportAllowanceIcon
                                                                }
                                                                alt=" "
                                                                style={{
                                                                    height: 24,
                                                                }}
                                                                className="my-auto me-3"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="fwn fs14">
                                                                Transport
                                                            </p>
                                                            <p className="">
                                                                {selectedJob?.transport
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedJob?.housing && (
                                                <div className="col-md-3">
                                                    <div className="d-flex mb-3 align-items-center">
                                                        <div>
                                                            <img
                                                                src={
                                                                    housingIcon
                                                                }
                                                                alt=" "
                                                                style={{
                                                                    height: 24,
                                                                }}
                                                                className="my-auto me-3"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="fwn fs14">
                                                                Housing
                                                            </p>
                                                            <p className="">
                                                                {selectedJob?.housing
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="col-md-4">
                        <div className="card mb-3 d-none d-md-block">
                            <div className="card-body">
                                <div className=" d-flex justify-content-around">
                                    {selectedJob.status === 'closed' && (
                                        <div>
                                            <div className="text-center">
                                                <a
                                                    href="#repost"
                                                    className="view-anchor text-decoration-none"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setShowModal({
                                                            data: selectedJob,
                                                            status: 'repost',
                                                        })
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        fill="currentColor"
                                                        class="bi bi-view-list"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z" />
                                                    </svg>
                                                    <p
                                                        className="small"
                                                        style={{ marginTop: 1 }}
                                                    >
                                                        Repost this job
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {selectedJob.status === 'active' && (
                                        <div>
                                            <div className="text-center">
                                                <a
                                                    href=""
                                                    className="view-anchor text-decoration-none"
                                                    onClick={() => {
                                                        navigate(
                                                            `/dashboard?job=${selectedJob?.id}`
                                                        )
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        fill="currentColor"
                                                        className="bi bi-view-list"
                                                        viewBox="0 0 16 16"
                                                        role="link"
                                                    >
                                                        <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z" />
                                                    </svg>
                                                    <p className="small">
                                                        Explore candidates
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {selectedJob.status !== 'archived' && (
                                        <div>
                                            <div className="text-center">
                                                <a
                                                    href=""
                                                    className="view-anchor text-decoration-none"
                                                    onClick={() => {
                                                        navigate(
                                                            `/matches?job=${selectedJob?.id}`
                                                        )
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        fill="currentColor"
                                                        className="bi bi-people mx-auto"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                                    </svg>
                                                    <p className="small text-nowrap">
                                                        View applicants
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="mt-3">
                                    <button
                                        className="btn btn-primary w-100 d-flex justify-content-center"
                                        onClick={() => {
                                            navigate(`/jobs/edit`, {
                                                state: {
                                                    job: selectedJob,
                                                },
                                            })
                                        }}
                                    >
                                        <img
                                            src={editIcon}
                                            alt=""
                                            style={{ width: 20, height: 20 }}
                                            className="my-auto"
                                        />
                                        <span className="m-0 mx-1 my-auto">
                                            Edit
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="fs18 mb-3">
                                            Preferences
                                        </h6>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="d-flex mb-3 align-items-center">
                                            <div>
                                                <img
                                                    style={{
                                                        height: 24,
                                                    }}
                                                    alt=""
                                                    src={JobIcon}
                                                    className="my-auto me-3"
                                                />
                                            </div>
                                            <div>
                                                <p className="fwn fs14">
                                                    Experience
                                                </p>
                                                <p>
                                                    {selectedJob?.totalExperienceMin ===
                                                        0 &&
                                                    selectedJob?.totalExperienceMax ===
                                                        1 ? (
                                                        selectedJob?.totalExperienceMin +
                                                        ' - ' +
                                                        selectedJob?.totalExperienceMax +
                                                        ' Year of Experience'
                                                    ) : (
                                                        <>
                                                            {
                                                                selectedJob?.totalExperienceMin
                                                            }{' '}
                                                            -{' '}
                                                            {
                                                                selectedJob?.totalExperienceMax
                                                            }{' '}
                                                            Years of Experience
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="d-flex">
                                            <div>
                                                <img
                                                    alt=""
                                                    src={skillsIcon}
                                                    style={{ height: 24 }}
                                                    className="me-3"
                                                />
                                            </div>
                                            <div>
                                                <p className="fs14">
                                                    Skills and Expertise
                                                </p>
                                                <CustomChip
                                                    data={
                                                        selectedJob?.technicalSkills
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!!showModal && (
                    <JobModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        handleProceedModal={handleProceedModal}
                    />
                )}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
    }
}
export default connect(mapStateToProps, null)(MyJobsinfo)
