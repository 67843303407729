import { get_url_extension, handle_file_download } from '../../../utils/helpers'
import resumeIcon from '../../../assets/img/resumeIcon.svg'

const UserFiles = ({ jobseeker }) => {
    return (
        <>
            {jobseeker?.portfolioAndFiles?.length > 0 && (
                <div className="card mb-3">
                    <div className="card-body">
                        <h6>Other Files</h6>
                        <p>Resume</p>
                        <div className='d-flex'>
                            {jobseeker?.portfolioAndFiles?.map((file) => {
                                let filename = `${jobseeker?.firstName} ${
                                    jobseeker?.lastName
                                }.${get_url_extension(file)}`
                                filename = filename.split(' ').join('_')

                                return (
                                    <div
                                        onClick={() =>
                                            handle_file_download(file, filename)
                                        }
                                    >
                                       <img src={resumeIcon} alt="" className='cpointer mt-2 me-2' style={{width:30}}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default UserFiles
