import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import userActions from '../../redux/user/actions';

function MobileLogin(props) {

    const { signInMobile } = props;
    // const location = useLocation()
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(params.accessToken && params.refreshToken){
            const { accessToken , refreshToken } = params;
            signInMobile({
                accessToken,
                refreshToken,
                role: "EMPLOYER",
                doNotShowTips: false
            });
            navigate('/pricing');
        }
    }, [])

    return (
        <React.Fragment />
    );
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
const mapDispatchToProps = {
    signInMobile: userActions.signInMobile,

}
export default connect(mapStateToProps, mapDispatchToProps)(MobileLogin)
