import { Box, IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import CustomGooglePlaces from '../../../../../components/google-places'
import TextField from '../../../../../components/inputs/CustomTextField'
import SelectJobFunction from '../../../../../components/reusables/SelectJobFunction'

const Filter = (props) => {
    const {
        show,
        setShow,
        handleClose,
        handleShow,
        checkbox_filters,
        filters,
        setFilters,
        handleFilter,
        handleSetWorkPref,
        handleWorkSetup,
        setJobTitleQuery,
        setSalaryMin,
        setSalaryMax,
        setSalaryCurrency,
        setYearsOfExp,
        currency,
        handleSalaryMin,
        handleSalaryMax,
        salaryMin,
        salaryMax,
        handleClearFilters,
    } = props

    const submitFilter = () => {
        handleFilter(null, false)
        handleClose()
    }
    return (
        <>
            <Modal show={show} centered onHide={handleClose}>
                <Modal.Body>
                    <Box display="flex" justifyContent="end" width="100%">
                        <IconButton onClick={() => handleClose()}>
                            <HighlightOffIcon style={{ fontSize: 30 }} />
                        </IconButton>
                    </Box>
                    <div className="mb-3">
                        <p className="text-primary">
                            <strong>Search by keyword</strong>
                        </p>
                        <TextField
                            placeholder={`Type job title, skills, etc... `}
                            onChange={(e) => {
                                setFilters({
                                    ...filters,
                                    keyword: e.target.value,
                                })
                            }}
                            value={filters?.keyword}
                        />
                    </div>
                    <div className="mb-3">
                        <p className="text-primary">
                            <strong>Job Function</strong>
                        </p>
                        <SelectJobFunction
                            value={filters?.jobFunction}
                            onChange={(val) => {
                                setFilters({
                                    ...filters,
                                    jobFunction: val,
                                })
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <p className="text-primary">
                            <strong>Location</strong>
                        </p>
                        <CustomGooglePlaces
                            onChange={(places) => {
                                setFilters({
                                    ...filters,
                                    location: places?.location,
                                })
                            }}
                            value={filters?.location}
                            onClear={() => {
                                setFilters({
                                    ...filters,
                                    location: null,
                                })
                            }}
                        />
                    </div>

                    <div className="mb-3">
                        <p className="text-primary">
                            <strong>Work Preference</strong>
                        </p>
                        {/* WORK PREFERENCE */}
                        <div className="">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value="Full-Time"
                                    name="workPreference"
                                    id="Full-Time"
                                    onChange={(e) => {
                                        handleSetWorkPref(e)
                                    }}
                                    checked={filters?.workPreference?.includes(
                                        'Full-Time'
                                    )}
                                />
                                <label
                                    className="form-check-label open-sans ms-3"
                                    htmlFor="Full-Time"
                                >
                                    Full-Time
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value="Part-Time"
                                    name="workPreference"
                                    id="Part-Time"
                                    onChange={(e) => {
                                        handleSetWorkPref(e)
                                    }}
                                    checked={filters?.workPreference?.includes(
                                        'Part-Time'
                                    )}
                                />
                                <label
                                    className="form-check-label open-sans ms-3"
                                    htmlFor="Part-Time"
                                >
                                    Part-Time
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value="Project-Based"
                                    name="workPreference"
                                    id="Project-Based"
                                    onChange={(e) => {
                                        handleSetWorkPref(e)
                                    }}
                                    checked={filters?.workPreference?.includes(
                                        'Project-Based'
                                    )}
                                />
                                <label
                                    className="form-check-label open-sans ms-3"
                                    htmlFor="Project-Based"
                                >
                                    Project-Based
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value="Internship"
                                    name="workPreference"
                                    id="Internship"
                                    onChange={(e) => {
                                        handleSetWorkPref(e)
                                    }}
                                    checked={filters?.workPreference?.includes(
                                        'Internship'
                                    )}
                                />
                                <label
                                    className="form-check-label open-sans ms-3"
                                    htmlFor="Internship"
                                >
                                    Internship
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <p className="text-primary">
                            <strong>Work Setup</strong>
                        </p>
                        {/* WORK SETUP */}
                        <div className="">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value="On-Site"
                                    name="workSetup"
                                    id="On-Site"
                                    onChange={(e) => {
                                        handleWorkSetup(e)
                                    }}
                                    checked={filters?.workSetup?.includes(
                                        'On-Site'
                                    )}
                                />
                                <label
                                    className="form-check-label open-sans ms-3"
                                    htmlFor="On-Site"
                                >
                                    On-Site
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value="Remote"
                                    name="workSetup"
                                    id="Remote"
                                    onChange={(e) => {
                                        handleWorkSetup(e)
                                    }}
                                    checked={filters?.workSetup?.includes(
                                        'Remote'
                                    )}
                                />
                                <label
                                    className="form-check-label open-sans ms-3"
                                    htmlFor="Remote"
                                >
                                    Remote
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value="Hybrid"
                                    name="workSetup"
                                    id="Hybrid"
                                    onChange={(e) => {
                                        handleWorkSetup(e)
                                    }}
                                    checked={filters?.workSetup?.includes(
                                        'Hybrid'
                                    )}
                                />
                                <label
                                    className="form-check-label open-sans ms-3"
                                    htmlFor="Hybrid"
                                >
                                    Hybrid
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-12">
                                <p className="text-primary">
                                    <strong>Salary Range</strong>
                                </p>
                            </div>
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <select
                                    name=""
                                    id=""
                                    className="form-select me-2"
                                    onChange={(e) =>
                                        setSalaryCurrency(e.target.value)
                                    }
                                >
                                    <option
                                        value="PHP"
                                        selected={
                                            currency == 'PHP' ? true : false
                                        }
                                    >
                                        PHP
                                    </option>
                                    <option
                                        value="SGD"
                                        selected={
                                            currency == 'SGD' ? true : false
                                        }
                                    >
                                        SGD
                                    </option>
                                    <option
                                        value="USD"
                                        selected={
                                            currency == 'USD' ? true : false
                                        }
                                    >
                                        USD
                                    </option>
                                </select>
                                <input
                                    type="text"
                                    placeholder="Min"
                                    className="form-control"
                                    onChange={handleSalaryMin}
                                    value={filters?.salaryMin}
                                />
                                <span className="mx-1">-</span>
                                <input
                                    type="text"
                                    placeholder="Max"
                                    className="form-control"
                                    onChange={handleSalaryMax}
                                    value={filters?.salaryMax}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <p className="text-primary">
                            <strong>Experience Required</strong>
                        </p>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="yearsOfExp"
                                id="0-1"
                                value="0-1"
                                onChange={(e) => {
                                    setYearsOfExp(e.target.value)
                                }}
                                checked={filters?.yearsOfExp === '0-1'}
                            />
                            <label class="form-check-label" for="0-1">
                                0-1 Year
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="yearsOfExp"
                                id="1-3"
                                value="1-3"
                                onChange={(e) => {
                                    setYearsOfExp(e.target.value)
                                }}
                                checked={filters?.yearsOfExp === '1-3'}
                            />
                            <label class="form-check-label" for="1-3">
                                1-3 Years
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="yearsOfExp"
                                id="3-5"
                                value="3-5"
                                onChange={(e) => {
                                    setYearsOfExp(e.target.value)
                                }}
                                checked={filters?.yearsOfExp === '3-5'}
                            />
                            <label class="form-check-label" for="3-5">
                                3-5 Years
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="yearsOfExp"
                                id="5-7"
                                value="5-7"
                                onChange={(e) => {
                                    setYearsOfExp(e.target.value)
                                }}
                                checked={filters?.yearsOfExp === '5-7'}
                            />
                            <label class="form-check-label" for="5-7">
                                5-7 Years
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="yearsOfExp"
                                id="7"
                                value="7-99"
                                onChange={(e) => {
                                    setYearsOfExp(e.target.value)
                                }}
                                checked={filters?.yearsOfExp === '7-99'}
                            />
                            <label class="form-check-label" for="7">
                                7+ Years
                            </label>
                        </div>
                    </div>
                    <div className="d-grid">
                        <button
                            className="position-relative btn btn-secondary mb-2 btn- w-136 border mt-2"
                            style={{
                                height: 36,
                            }}
                            onClick={() => {
                                handleClearFilters && handleClearFilters()
                                handleClose()
                            }}
                        >
                            Clear filters
                        </button>
                    </div>
                    <div className="d-grid">
                        <button
                            className="position-relative btn btn-primary w-136"
                            style={{
                                height: 36,
                            }}
                            onClick={() => {
                                handleFilter(null, false)
                                handleClose()
                            }}
                        >
                            Search Job Seekers
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Filter
