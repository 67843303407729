import _ from 'lodash'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import salaryIcon from '../../assets/img/salaryIcon.svg'
import { JobluIconsV2 } from '../../assets/index'
import { profilePlaceholder_Employer } from '../../utils/constants'
// import JobluBranding from '../../assets/joblu-2.0/img/new-branding/joblu.svg'
import {
    Avatar,
    Box,
    CircularProgress,
    IconButton,
    Tooltip,
} from '@material-ui/core'
import { db, messaging } from '../../firebase'
import rootAction from '../../redux/action'
import appActions from '../../redux/app/actions'
import notificationActions from '../../redux/notifications/action'
import userAction from '../../redux/user/actions'
import { userRequest } from '../../service/requests'
import { computeProfileCompletion } from '../../utils/helpers'
import useWindowDimensions from '../../utils/hooks'
import { MapNotif } from '../../views/notifications/Notifications'
import JobluTypography from '../Typography'
import CustomToast from '../reusables/Toast/Toast'
import './styles.css'

const currencyList = ['INR', 'PHP', 'MYR', 'SGD', 'USD']

function Header(props) {
    const {
        User,
        signOut,
        AppState,
        setCurrency,
        setChatRooms,
        getNotifications,
        Notifications,
        JobFairs,
        getSubscription,
    } = props
    const [profilePercentage, setProfilePercentage] = useState(100)

    useEffect(() => {
        if (Object.keys(User.user || {}).length > 0) {
            const percent = computeProfileCompletion(User?.user)
            setProfilePercentage(percent)
        } else {
            setProfilePercentage(0)
        }
    }, [User?.user])

    const { notificationsList, isFetching, isFetched } = Notifications
    const { width } = useWindowDimensions()
    const { currency } = AppState
    const { chatRooms } = User
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = location

    const [sideNav, setSideNav] = useState(false)
    const [notifStack, setNotifStack] = useState([])

    const handleLogout = () => {
        try {
            userRequest
                .signOut({ token: User?.token })
                .then((res) => {
                    signOut()
                })
                .finally(() => {
                    setTimeout(() => {
                        navigate('/login')
                    }, 500)
                })
        } catch (err) {
            console.log(err)
        }
    }

    const chatSound = new Audio(
        require('../../assets/sounds/joblu-chat-sound.mp3')
    )

    const [unReadMessages, setUnReadMessages] = useState(
        chatRooms?.filter((item) => {
            return (
                item.latestMessage.lastSender === 'applicant' &&
                item.latestMessage.isRead === false
            )
        }).length
    )

    const [currentReadMessages, setCurrentReadMessages] = useState(
        chatRooms?.filter((item) => {
            return (
                item.latestMessage.lastSender === 'applicant' &&
                item.latestMessage.isRead === false
            )
        }).length
    )

    useEffect(() => {
        setCurrentReadMessages(unReadMessages)
        const delayDebounceFn = setTimeout(() => {
            setUnReadMessages(
                chatRooms.filter((item) => {
                    return (
                        item.latestMessage.lastSender === 'applicant' &&
                        item.latestMessage.isRead === false
                    )
                }).length
            )
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [chatRooms])

    useEffect(() => {
        console.log('CURRENT:::', currentReadMessages)
        console.log('INCOMING:::', unReadMessages)
        if (unReadMessages > 0) {
            if (currentReadMessages < unReadMessages) chatSound.play()
        }
    }, [unReadMessages])

    useEffect(() => {
        getNotifications()
        getSubscription()
    }, [])

    const goToNotification = (data) => {
        console.log(data)
        userRequest
            .readNotification({
                notificationId: data._id,
                isRead: true,
            })
            .then((response) => {
                getNotifications()
            })
            .catch((err) => {
                console.log(err)
            })

        if (data.type === 'jobprocess') {
            //go to jobs page
            navigate('/jobs', {
                state: { data },
            })
        } else if (data.type === 'match') {
            // jobsRequest.getSpecificJob({jobId: data?.data?.jobId})
            // .then(response => {
            //   console.log(response?.data)
            // // go to job match or go to specific job view
            // })
            // .catch(e => {
            //     // go to job match or go to specific job view
            // })
            navigate('/matches', {
                state: { data },
            })
        } else if (data.type === 'superlike' || data.type === 'like') {
            // go to likes
            navigate('/likes', {
                state: { tab: 'liked_me', data },
            })
        } else if (data.type === 'recommendation') {
            navigate('/recommendations')
        }
    }

    const fetchEmployerRoomsList = () => {
        const roomListMessage = []
        const roomInfoListener = db
            ?.collectionGroup('applicants')
            ?.where('employerId', '==', User.user.userId)
            ?.orderBy('latestMessage.createdAt', 'desc')
            ?.get()
            ?.then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    if (doc.data().roomName !== undefined) {
                        const info = {
                            _id: doc.id,
                            ...doc.data(),
                            profileUrl: User.user.profileUrl,
                        }
                        roomListMessage.push(info)
                    }
                })
                if (roomListMessage.length > 0) {
                    setChatRooms(roomListMessage)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    useEffect(() => {
        if (User?.user?.userId) {
            const roomListener = db
                .collectionGroup('applicants')
                ?.where('employerId', '==', User.user.userId)
                .orderBy('latestMessage.createdAt', 'desc')
                .onSnapshot((querySnapshot) => {
                    fetchEmployerRoomsList()
                })

            const roomListMessage = []
            const roomInfoListener = db
                ?.collectionGroup('applicants')
                ?.where('employerId', '==', User.user.userId)
                ?.orderBy('latestMessage.createdAt', 'desc')
                ?.get()
                ?.then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().roomName !== undefined) {
                            const info = {
                                _id: doc.id,
                                ...doc.data(),
                                profileUrl: User.user.profileUrl,
                            }
                            roomListMessage.push(info)
                        }
                    })
                    if (roomListMessage.length > 0) {
                        setChatRooms(roomListMessage)
                    }
                })
                .catch((e) => {
                    console.log(e)
                })

            return () => roomListener()
        }
    }, [])

    messaging &&
        messaging.onMessage((payload) => {
            console.log('Message received. ', payload)

            if (payload?.data?.type !== 'message') {
                setNotifStack([...notifStack, payload])
                getNotifications()
                //handle notif alert (toastr boxes??) in lower right side for notifications received
            }
        })

    const handleNavigate = (url) => {
        navigate(url)
        setSideNav(false)
    }

    console.log(User)

    return (
        <>
            <CustomToast data={notifStack} />
            <nav
                className="navbar navbar-expand-lg nav-box-shadow px-2 sticky-top"
                style={{
                    background: '#fff',
                    zIndex: 9998,
                    position: 'fixed',
                    width: '100%',
                }}
            >
                <div className="container-fluid">
                    <Box className="navbar-brand text-primary px-0 px-lg-5 d-flex">
                        <a href="/dashboard" className="my-auto">
                            <img
                                className="img-fluid"
                                src={require('../../assets/joblu-logos/jb-horizontal.png')}
                                alt="img"
                                style={{ width: '150px' }}
                            />
                        </a>
                        {width > 640 && (
                            <Box
                                display="flex"
                                paddingLeft={2}
                                style={{ borderLeft: '1px solid #6E88A3' }}
                                marginLeft={2}
                            >
                                <Tooltip
                                    title={
                                        profilePercentage !== 100 ? (
                                            <span>
                                                Your company profile is{' '}
                                                {profilePercentage}% complete!
                                                Candidates are more likely to
                                                apply to your jobs if your
                                                profile is complete. Click to
                                                update your profile!
                                            </span>
                                        ) : (
                                            'Awesome, your company profile is now fully complete! '
                                        )
                                    }
                                >
                                    <Box
                                        position="relative"
                                        role="button"
                                        onClick={() => {
                                            navigate('/settings')
                                        }}
                                    >
                                        <CircularProgress
                                            style={{
                                                position: 'absolute',
                                                width: 60,
                                                height: 60,
                                                top: -5,
                                                left: -5,
                                            }}
                                            variant="determinate"
                                            value={profilePercentage}
                                            color="secondary"
                                            thickness={3}
                                        />
                                        <Avatar
                                            src={
                                                User?.user?.profileUrl ||
                                                profilePlaceholder_Employer
                                            }
                                            style={{
                                                width: 50,
                                                borderRadius: '50%',
                                                height: 50,
                                                backgroundColor: '#ffffff',
                                            }}
                                            alt="company logo"
                                        />
                                    </Box>
                                </Tooltip>
                                <Box paddingLeft={2}>
                                    <JobluTypography.H6 primary bold>
                                        {User?.user?.companyName}
                                    </JobluTypography.H6>
                                    <JobluTypography.Body primary>
                                        {User?.user?.country}
                                    </JobluTypography.Body>
                                </Box>
                                <Box display="flex" paddingLeft={2}>
                                    {User?.subscription?.status === 'active' ||
                                    User?.subscription?.status ===
                                        'trialing' ? (
                                        <img
                                            s
                                            style={{
                                                margin: 'auto',
                                                width: 76,
                                                cursor: 'pointer',
                                            }}
                                            alt="premium"
                                            onClick={() =>
                                                navigate('/settings?tab=plans')
                                            }
                                            src={require('../../assets/premium/premium-badge.png')}
                                        />
                                    ) : (
                                        <img
                                            s
                                            style={{
                                                margin: 'auto',
                                                width: 76,
                                                cursor: 'pointer',
                                            }}
                                            alt="premium"
                                            onClick={() =>
                                                navigate('/settings?tab=plans')
                                            }
                                            src={require('../../assets/premium/free-badge.png')}
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>

                    <button className="navbar-toggler" type="button">
                        <span className="navbar-toggler-icon d-none d-lg-block">
                            <img
                                src={JobluIconsV2.toggleMenu}
                                alt=""
                                className=""
                            />
                        </span>
                        <span
                            className="navbar-toggler-icon d-block d-lg-none"
                            onClick={() => {
                                setSideNav(true)
                            }}
                        >
                            <img
                                src={JobluIconsV2.toggleMenu}
                                alt=""
                                className=""
                            />
                        </span>
                    </button>
                    {/* <!--toggle btn END-->
                
                <!--nav bar--> */}
                    <div
                        className="collapse navbar-collapse d-flex justify-content-end d-none d-lg-block"
                        id="navbarSupportedContent"
                    >
                        {/* <!--search bar-->
                    {{-- <input className="form-control mr-sm-2 br-3 w-25" type="text" placeholder="Search for Candidates" id="quickSearchBox" aria-label="Search"> --}}
                    <!--search bar END--> */}

                        <ul className="navbar-nav right-0" id="navbar-links">
                            <li className="nav-item text-center w-100 nav-item-active">
                                <div
                                    className="me-2  nav-link d-flex flex-column align-items-center"
                                    href="/dashboard"
                                    role="button"
                                    onClick={() => {
                                        if (!!JobFairs.signedInJobFair) {
                                            navigate(
                                                `/job-fair/dashboard/${JobFairs.signedInJobFair}`
                                            )
                                        } else {
                                            navigate('/dashboard')
                                        }
                                    }}
                                >
                                    <img
                                        src={
                                            pathname === '/dashboard'
                                                ? JobluIconsV2.dashboardFilled
                                                : JobluIconsV2.cards
                                        }
                                        alt=""
                                        style={{ height: 24 }}
                                    />

                                    <p>Explore</p>
                                </div>
                            </li>
                            <li className="nav-item text-center w-100 nav-item-active">
                                <div
                                    className="me-2 nav-link d-flex flex-column align-items-center justify-content-between"
                                    href="/dashboard"
                                    role="button"
                                    onClick={() => navigate('/jobs')}
                                >
                                    <img
                                        src={
                                            pathname === '/jobs' ||
                                            pathname === '/matches'
                                                ? JobluIconsV2.jobFilled
                                                : JobluIconsV2.jobs
                                        }
                                        alt=""
                                        style={{ height: 24 }}
                                    />

                                    <p className="text-nowrap">My Jobs</p>
                                </div>
                            </li>
                            <li className="nav-item text-center w-100 nav-item-active">
                                <div
                                    className="me-2  nav-link d-flex flex-column align-items-center"
                                    onClick={() => navigate('/likes')}
                                    role="button"
                                >
                                    <img
                                        src={
                                            pathname === '/likes'
                                                ? JobluIconsV2.likesFilled
                                                : JobluIconsV2.likesicon
                                        }
                                        alt=""
                                        style={{ height: 24 }}
                                    />
                                    <p className="text-nowrap">Likes</p>
                                </div>
                            </li>
                            <li className="nav-item dropdown dropdown2 text-center w-100 position-relative nav-item-active d-none">
                                <div
                                    className="me-2  nav-link d-flex flex-column align-items-center no-arrow  my-dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    id="notif-link"
                                    onClick={() => handleNavigate('/matches')}
                                >
                                    <img
                                        src={
                                            pathname === '/matches'
                                                ? JobluIconsV2.resumeFilled
                                                : JobluIconsV2.resume
                                        }
                                        alt=""
                                        style={{ height: 24 }}
                                    />
                                    <p className="text-nowrap">Matches</p>
                                </div>
                                <div
                                    className="dropdown-menu dropdown-center"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <p
                                        className="dropdown-item "
                                        onClick={() =>
                                            navigate('/matches', {
                                                state: { tab: 'Active' },
                                            })
                                        }
                                        role="button"
                                    >
                                        Active
                                    </p>
                                    <p
                                        className="dropdown-item "
                                        onClick={() =>
                                            navigate('/matches', {
                                                state: { tab: 'Shortlisted' },
                                            })
                                        }
                                        role="button"
                                    >
                                        Shortlisted
                                    </p>
                                    <p
                                        className="dropdown-item "
                                        onClick={() =>
                                            navigate('/matches', {
                                                state: { tab: 'Archived' },
                                            })
                                        }
                                        role="button"
                                    >
                                        Archived
                                    </p>
                                </div>
                            </li>
                            <li className="nav-item text-center w-100 nav-item-active">
                                <div
                                    className="me-2  nav-link d-flex flex-column align-items-center"
                                    onClick={() => navigate('/messages')}
                                    role="button"
                                >
                                    <div className="position-relative">
                                        <img
                                            src={
                                                pathname === '/messages'
                                                    ? JobluIconsV2.messagesFilled
                                                    : JobluIconsV2.message
                                            }
                                            alt=""
                                            style={{ height: 24 }}
                                        />
                                        <span className="position-absolute top-0 text-dark start-100 translate-middle badge rounded-pill bg-warning border ">
                                            {unReadMessages
                                                ? unReadMessages
                                                : null}
                                        </span>
                                    </div>
                                    <p>Messages</p>
                                </div>
                            </li>
                            <li className="nav-item dropdown text-center w-100 position-relative nav-item-active">
                                <div
                                    className="me-2 nav-link d-flex flex-column align-items-center no-arrow  my-dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    id="notif-link"
                                >
                                    <div className="position-relative">
                                        <img
                                            src={
                                                pathname === '/notifications'
                                                    ? JobluIconsV2.notificationsFilled
                                                    : JobluIconsV2.notifications
                                            }
                                            alt=""
                                            style={{ height: 24 }}
                                        />
                                        <span className="position-absolute top-0 text-dark start-100 translate-middle badge rounded-pill bg-warning border ">
                                            {notificationsList.totalUnread
                                                ? notificationsList.totalUnread
                                                : null}
                                        </span>
                                    </div>
                                    <p>Notifications</p>
                                </div>

                                {/* <!--notification dropdown--> */}
                                <div
                                    className="dropdown-menu dropdown-menu-right mx-auto mx-md-0 py-0 notif-dropdown"
                                    id="notif-dropdown"
                                    aria-labelledby="navbarDropdown"
                                    style={{ width: 318, left: -100 }}
                                >
                                    <div
                                        className="text-right mr-2 mark-all-as-read"
                                        style={{ display: 'none' }}
                                    >
                                        <a
                                            className="nav-link small text-decoration-none"
                                            href="/notifications/readAll"
                                        >
                                            Mark All as Read
                                        </a>
                                    </div>
                                    <div className="dropdown-divider top-divider mb-1 mt-0"></div>
                                    {isFetching && (
                                        <div className="loading">
                                            <p className="small pt-5 pb-5 text-center">
                                                Loading...
                                            </p>
                                        </div>
                                    )}
                                    {notificationsList?.data?.length === 0 && (
                                        <div
                                            className="no-notifications"
                                            style={{ display: 'none' }}
                                        >
                                            <p className="small pt-5 pb-5 text-center">
                                                No notifications yet.
                                            </p>
                                        </div>
                                    )}
                                    {notificationsList?.data?.length > 0 && (
                                        <MapNotif
                                            data={_.take(
                                                notificationsList?.data,
                                                5
                                            )}
                                            size="small"
                                            action={goToNotification}
                                        />
                                    )}
                                    {notificationsList?.data?.length > 0 ? (
                                        <>
                                            <div className="view-all-divider dropdown-divider m-0"></div>
                                            <div
                                                className="view-all dropdown-item small text-center"
                                                onClick={() =>
                                                    navigate('/notifications')
                                                }
                                                role="button"
                                            >
                                                View All
                                            </div>
                                        </>
                                    ) : (
                                        <div className="text-center p-2">
                                            <p>No notifications yet.</p>
                                        </div>
                                    )}
                                </div>
                                {/* <!--notification dropdown END--> */}
                            </li>

                            <li className="nav-item dropdown text-center  w-100 position-relative nav-item-active text-nowrap">
                                <div
                                    className="me-2 nav-link d-flex align-items-center dropdown-toggle h-100 justify-content-center"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    id="notif-link"
                                >
                                    <p
                                        style={{ verticalAlign: 'middle' }}
                                        className="m-0"
                                    >
                                        {currency}
                                    </p>
                                </div>
                                <div
                                    className="dropdown-menu dropdown-center currency-dropdown-container"
                                    aria-labelledby="navbarDropdown"
                                    style={{
                                        width: 50,
                                        left: -50,
                                        maxHeight: 300,
                                        overflowY: 'auto',
                                    }}
                                >
                                    {currencyList.map((cur, index) => {
                                        return (
                                            <div
                                                key={cur}
                                                className="dropdown-item text-center hand"
                                                // role="button"
                                                onClick={() => setCurrency(cur)}
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {cur}
                                            </div>
                                        )
                                    })}
                                </div>
                            </li>

                            {/* <!--Sub Menu--> */}
                            <li className="nav-item dropdown mb-0 text-center w-100 d-flex">
                                <a
                                    className="nav-link py-auto dropdown-toggle d-flex align-items-center justify-content-center"
                                    href="/"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        className="account-img-1 img-fit border"
                                        src={
                                            User?.user?.profileUrl ||
                                            profilePlaceholder_Employer
                                        }
                                        alt="img"
                                        style={{
                                            width: 45,
                                            height: 45,
                                            borderRadius: '50%',
                                        }}
                                    />
                                    {/* <Avatar
                                        style={{
                                            background: '#FFEED2',
                                            objectFit: 'contain',
                                        }}
                                        src={
                                            User?.user?.profileUrl ||
                                            profilePlaceholder_Employer
                                        }
                                    >
                                        <span style={{ color: '#BD872F' }}>
                                            {User?.user?.firstName?.charAt(0)}
                                        
                                    </Avatar> */}
                                </a>
                                <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="navbarDropdown"
                                    style={{
                                        width: 280,
                                        left: -200,
                                        zIndex: 99999,
                                    }}
                                >
                                    <div
                                        style={{
                                            textDecoration: 'none',
                                            background: '#F4F5F5',
                                            borderRadius: 5,
                                            padding: 10,
                                        }}
                                        className="mx-1"
                                    >
                                        <div className="">
                                            <div className="">
                                                <Box
                                                    width={'100%'}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Box display={'flex'}>
                                                        <Box>
                                                            <img
                                                                className="img-fluid br5 account-img-2 me-2 img-fit border"
                                                                src={
                                                                    User?.user
                                                                        ?.profileUrl ||
                                                                    profilePlaceholder_Employer
                                                                }
                                                                alt="Login Logo"
                                                                style={{
                                                                    width: 50,
                                                                    height: 50,
                                                                    borderRadius:
                                                                        '100px',
                                                                }}
                                                                onClick={() => {
                                                                    navigate(
                                                                        '/settings?tab=edit-profile'
                                                                    )
                                                                }}
                                                                role="button"
                                                            />
                                                        </Box>
                                                        <Box
                                                            onClick={() => {
                                                                navigate(
                                                                    '/settings?tab=edit-profile'
                                                                )
                                                            }}
                                                            role="button"
                                                        >
                                                            <JobluTypography.H6
                                                                primary
                                                            >
                                                                {
                                                                    User?.user
                                                                        ?.companyName
                                                                }
                                                            </JobluTypography.H6>

                                                            <JobluTypography.Body className="small text-primary text-capitalize hand">
                                                                {
                                                                    User?.user
                                                                        ?.country
                                                                }
                                                            </JobluTypography.Body>
                                                        </Box>
                                                    </Box>

                                                    <Box display="flex">
                                                        <IconButton
                                                            style={{
                                                                margin: 'auto',
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                navigate(
                                                                    '/settings?tab=edit-profile'
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                alt="edit"
                                                                src={require('../../assets/shortcuts/edit.png')}
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="dropdown-divider mx-3"></div> */}
                                    <div
                                        className="dropdown-item fs14 py-3"
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => navigate('/pricing')}
                                        role="button"
                                    >
                                        <Box display="flex">
                                            <Box minWidth={40}>
                                                <img
                                                    src={require('../../assets/img/header/PricingIcon.png')}
                                                    className="me-2 h-24"
                                                    alt=""
                                                />
                                            </Box>
                                            <Box>Plans and Pricing</Box>
                                        </Box>
                                    </div>
                                    <div
                                        className="dropdown-item fs14 py-3"
                                        style={{ textDecoration: 'none' }}
                                        onClick={() =>
                                            navigate('/settings?tab=plans')
                                        }
                                        role="button"
                                    >
                                        <Box display="flex">
                                            <Box minWidth={40}>
                                                <img
                                                    src={require('../../assets/img/header/BillingIcon.png')}
                                                    className="me-2 h-24"
                                                    alt=""
                                                />
                                            </Box>
                                            <Box>Current Plan</Box>
                                        </Box>
                                    </div>
                                    <div
                                        className="dropdown-item fs14 py-3"
                                        style={{ textDecoration: 'none' }}
                                        onClick={() =>
                                            navigate(
                                                '/settings?tab=integrations'
                                            )
                                        }
                                        role="button"
                                    >
                                        <Box display="flex">
                                            <Box minWidth={40}>
                                                <img
                                                    src={require('../../assets/img/header/IntegrationIcon.png')}
                                                    className="me-2 h-24"
                                                    alt=""
                                                />
                                            </Box>
                                            <Box>Integrations</Box>
                                        </Box>
                                    </div>
                                    <div
                                        className="dropdown-item fs14 py-3"
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => navigate('/settings')}
                                        role="button"
                                    >
                                        <Box display="flex">
                                            <Box minWidth={40}>
                                                <img
                                                    src={JobluIconsV2.settings2}
                                                    className="me-2 h-24"
                                                    alt=""
                                                />
                                            </Box>
                                            <Box>Settings</Box>
                                        </Box>
                                    </div>
                                    <div
                                        className="dropdown-item fs14 py-3"
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => handleLogout()}
                                        role="button"
                                    >
                                        <Box display="flex">
                                            <Box minWidth={40}>
                                                <img
                                                    src={JobluIconsV2.logout}
                                                    className="me-2 h-24"
                                                    alt=""
                                                />{' '}
                                            </Box>
                                            <Box>Logout</Box>
                                        </Box>
                                    </div>
                                </div>
                            </li>
                            {/* <!--Sub Menu END--> */}
                        </ul>
                    </div>
                    {/* <!--nav bar END--> */}
                </div>
                {/* <!--nav bar END--> */}
                {sideNav && (
                    <div
                        className="position-fixed"
                        style={{
                            backgroundColor: '#00122580',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1100,
                        }}
                    >
                        <div id="sideNav" className="bg-white p-3">
                            <div className="float-end">
                                <span
                                    className="cpointer"
                                    onClick={() => {
                                        setSideNav(false)
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-x-lg"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                    </svg>
                                </span>
                            </div>
                            <div className="d-flex">
                                <div>
                                    <img
                                        className="img-fluid br5 account-img-2 img-fit border"
                                        src={
                                            User?.user?.profileUrl ||
                                            profilePlaceholder_Employer
                                        }
                                        alt="Login Logo"
                                        style={{
                                            width: 50,
                                            height: 50,
                                        }}
                                    />
                                </div>
                                <div className="px-1">
                                    <p className="fs14 text-body fw600">
                                        {User?.user?.firstName}
                                    </p>
                                    <a
                                        onClick={() =>
                                            navigate(
                                                '/settings?tab=edit-profile'
                                            )
                                        }
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <p className="small text-primary text-capitalize">
                                            {User?.user?.employerType}
                                        </p>
                                    </a>
                                </div>
                            </div>
                            <hr />
                            <div
                                className="row mb-3"
                                onClick={() => {
                                    setSideNav(false)
                                    if (!!JobFairs.signedInJobFair) {
                                        navigate(
                                            `/job-fair/dashboard/${JobFairs.signedInJobFair}`
                                        )
                                    } else {
                                        navigate('/dashboard')
                                    }
                                }}
                            >
                                <div className="col-1">
                                    <img
                                        src={
                                            pathname === '/dashboard'
                                                ? JobluIconsV2.dashboardFilled
                                                : JobluIconsV2.cards
                                        }
                                        alt=""
                                        style={{ width: 24 }}
                                    />
                                </div>
                                <div className="col-11">Explore</div>
                            </div>
                            <div
                                className="row mb-3"
                                onClick={() => handleNavigate('/jobs')}
                            >
                                <div className="col-1">
                                    <img
                                        src={
                                            pathname === '/jobs'
                                                ? JobluIconsV2.jobFilled
                                                : JobluIconsV2.jobs
                                        }
                                        alt=""
                                        style={{ width: 24 }}
                                    />
                                </div>
                                <div className="col-11">My Jobs</div>
                            </div>
                            <div
                                className="row mb-3"
                                onClick={() => handleNavigate('/likes')}
                            >
                                <div className="col-1">
                                    <img
                                        src={
                                            pathname === '/likes'
                                                ? JobluIconsV2.likesFilled
                                                : JobluIconsV2.likesicon
                                        }
                                        alt=""
                                        style={{ width: 24 }}
                                    />
                                </div>
                                <div className="col-11">Likes</div>
                            </div>
                            {/* <div
                                className="row mb-3"
                                onClick={() => handleNavigate('/matches')}
                            >
                                <div className="col-1">
                                    <img
                                        src={
                                            pathname === '/matches'
                                                ? JobluIconsV2.resumeFilled
                                                : JobluIconsV2.resume
                                        }
                                        alt=""
                                        style={{ width: 24 }}
                                    />
                                </div>
                                <div className="col-11">Matches</div>
                            </div> */}
                            <div
                                className="row mb-3"
                                onClick={() => handleNavigate('/messages')}
                            >
                                <div className="col-1">
                                    <img
                                        src={
                                            pathname === '/messages'
                                                ? JobluIconsV2.messagesFilled
                                                : JobluIconsV2.message
                                        }
                                        alt=""
                                        style={{ width: 24 }}
                                    />
                                </div>
                                <div className="col-11">
                                    Messages
                                    <span className="text-dark ms-2 badge rounded-pill bg-warning border ">
                                        {unReadMessages ? unReadMessages : null}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mb-3"
                                onClick={() => handleNavigate('/notifications')}
                            >
                                <div className="col-1">
                                    <img
                                        src={
                                            pathname === '/notifications'
                                                ? JobluIconsV2.notificationsFilled
                                                : JobluIconsV2.notifications
                                        }
                                        alt=""
                                        style={{ width: 24 }}
                                    />
                                </div>
                                <div className="col-11">
                                    Notifications
                                    <span className="text-dark ms-2 mt-0 badge rounded-pill bg-warning border ">
                                        {notificationsList.totalUnread
                                            ? notificationsList.totalUnread
                                            : null}
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div className="row mb-3">
                                <div className="col-1">
                                    <img
                                        src={salaryIcon}
                                        className="me-2 h-24"
                                        alt=""
                                    />
                                </div>
                                <div className="col-11">
                                    Currency:
                                    <div class="btn-group dropdown ms-2">
                                        <span
                                            class="dropdown-toggle text-dark cpointer"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {currency}
                                        </span>
                                        <ul class="dropdown-menu p-2">
                                            {currencyList.map((cur, index) => {
                                                console.log(cur)
                                                return (
                                                    <li
                                                        key={cur}
                                                        className="dropdown-item text-center "
                                                        role="button"
                                                        onClick={() =>
                                                            setCurrency(cur)
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {cur}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row mb-3"
                                onClick={() => handleNavigate('/settings')}
                            >
                                <div className="col-1">
                                    <img
                                        src={JobluIconsV2.settings2}
                                        className="me-2 h-24"
                                        alt=""
                                    />
                                </div>
                                <div className="col-11">Settings</div>
                            </div>
                            <div
                                className="row mb-3"
                                onClick={() => handleLogout()}
                            >
                                <div className="col-1">
                                    <img
                                        src={JobluIconsV2.logout}
                                        className="me-2 h-24"
                                        alt=""
                                    />
                                </div>
                                <div className="col-11">Logout</div>
                            </div>
                        </div>
                    </div>
                )}
            </nav>
            <div style={{ paddingTop: 75 }}></div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
        Notifications: state.Notifications,
        JobFairs: state.JobFairs,
    }
}

const mapDispatchToProps = {
    signOut: rootAction.signOut,
    setCurrency: appActions.setCurrency,
    setChatRooms: userAction.setChatRooms,
    getNotifications: notificationActions.getNotifications,
    getSubscription: userAction.getActiveSubscription,
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
