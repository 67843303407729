import jobIcon from '../../../assets/img/jobIcon.svg'
import locationIcon from '../../../assets/img/explore/locationIcon.svg'
import ElevatorPitch from '../../../components/reusables/elevator-pitch/ElevatorPitch'
import './styles.css'
import playIcon from '../../../assets/img/explore/playIcon.svg'
import { useState } from 'react'
import educationIcon from '../../../assets/img/explore/educationIcon.svg'
import experienceIcon from '../../../assets/img/explore/experienceIcon.svg'

import DismissIcon from '../../../assets/img/editProfile/dismissIcon.png'
import LikeIcon from '../../../assets/img/editProfile/likeIcon.png'
import SuperLikeIcon from '../../../assets/img/editProfile/superLikeIcon.png'
import {
    formatMoney,
    getYearDifference,
    getYearsOfExpCategory,
    titleCase,
} from '../../../utils/helpers'
import Icon from '@mdi/react'
import { mdiTools } from '@mdi/js'
import salaryIcon from '../../../assets/img/explore/salaryIcon.svg'
import {
    defaultSwipeActions,
    JobApplication_Status,
    profilePlaceholder_Applicant,
    salaryRanges,
} from '../../../utils/constants'
import { connect } from 'react-redux'
import { ProfileModal } from '../../explore/v2/components/ViewProfile/ProfileModal'

const RecommendationItem = (props) => {
    const { AppState, hasSwipeActions, data, swipeAction, itemId } = props
    const [playVideo, setPlayVideo] = useState(false)
    const [videoSrc, setVideoSrc] = useState(null)
    const [showPitch, setShowPitch] = useState(true)

    const handlePlayVideo = (src) => {
        setVideoSrc(src)
        setPlayVideo(!playVideo)
    }

    const [match, setMatch] = useState(null)
    const [applicant, setApplicant] = useState(null)
    const [itemIndex, setItemIndex] = useState(null)

    const [showProfileModal, setShowProfileModal] = useState(false)

    const handleCloseProfileModal = () => setShowProfileModal(false)
    const handleShowProfileModal = () => setShowProfileModal(true)

    const handleView = (data, index) => {
        // setShowProfile(data ? data : false)
        setApplicant(data?.matchApplicant)
        setMatch(data)
        setShowProfileModal(true)
    }

    const getYearsOfExp = (item) => {
        if (item?.experienceHistory && item?.experienceHistory?.length > 0) {
            const sorted =
                item?.experienceHistory &&
                item?.experienceHistory?.sort(function (a, b) {
                    return new Date(a.startDate) - new Date(b.startDate)
                })
            const startDate = new Date(sorted[0]?.startDate)
            const diff = getYearDifference(startDate)
            return getYearsOfExpCategory(diff)
        } else {
            return null
        }
    }

    const { currencyList, currency } = AppState
    const currencyOptions = {
        currencyList,
        currency,
    }
    return (
        <>
            <ProfileModal
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                handleCloseProfileModal={handleCloseProfileModal}
                handleShowProfileModal={handleShowProfileModal}
                seeker={data?.applicant}
                match={match}
                data={match}
                defaultSwipeActions={defaultSwipeActions}
                itemId={itemIndex}
                swipeAction={swipeAction}
            />
            <div
                className="row border-top border-bottom py-3 mx-0 mb-2 shadow-sm border mx-0"
                id={itemId}
            >
                <div className="col-md-9 d-flex flex-column flex-md-row">
                    <div
                        className={`me-3 mb-2 mb-md-0 position-relative`}
                        style={{ flexBasis: '10%' }}
                    >
                        <div
                            className="position-relative"
                            style={{
                                width: 80,
                                height: 80,
                            }}
                        >
                            <img
                                src={
                                    data?.applicant?.profileUrl ||
                                    profilePlaceholder_Applicant
                                }
                                style={{
                                    width: 80,
                                    height: 80,
                                }}
                                alt="profile"
                                className="rounded-circle mb-3"
                            />
                            {data?.applicant?.elevatorPitch && (
                                <>
                                    <div
                                        className="position-absolute"
                                        style={{
                                            bottom: -6,
                                            right: -6,
                                        }}
                                    >
                                        <img
                                            src={playIcon}
                                            alt=""
                                            className="hand"
                                            onClick={() =>
                                                handlePlayVideo(
                                                    data?.applicant
                                                        ?.elevatorPitch
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className="me-0 me-md-3 mb-2 mb-md-0"
                        style={{ flexBasis: '25%' }}
                    >
                        <div className="position-relative">
                            <h5
                                className="text-primary mt-2 mb-0 hand"
                                onClick={() =>
                                    handleView(data?.applicant, itemId)
                                }
                            >
                                {titleCase(
                                    `${data?.applicant?.firstName} ${data?.applicant?.lastName}`
                                )}
                            </h5>
                            {data?.applicant?.preferredTitle && (
                                <p className="mb-0">
                                    <strong>
                                        {data?.applicant?.preferredTitle}
                                    </strong>
                                </p>
                            )}
                            <p className="text-muted mb-2">
                                {data?.applicant?.jobFunction}
                            </p>
                        </div>
                    </div>
                    <div
                        className="me-0 me-md-3 mb-2 mb-md-0"
                        style={{ flexBasis: '25%' }}
                    >
                        {data?.applicant?.educationHistory?.length > 0 && (
                            <>
                                <p className="small">
                                    <img
                                        src={educationIcon}
                                        alt=""
                                        className="me-2"
                                        style={{
                                            width: 22,
                                        }}
                                    />{' '}
                                    {
                                        data?.applicant?.educationHistory?.sort(
                                            function (a, b) {
                                                return (
                                                    new Date(b.educStartDate) -
                                                    new Date(a.educStartDate)
                                                )
                                            }
                                        )[0]?.educName
                                    }
                                </p>
                            </>
                        )}
                        {data?.applicant?.currentLocation && (
                            <>
                                <p className="small">
                                    <img
                                        src={locationIcon}
                                        alt=""
                                        className="me-2"
                                        style={{
                                            width: 22,
                                        }}
                                    />{' '}
                                    {data?.applicant?.currentLocation}
                                </p>
                            </>
                        )}
                        {getYearsOfExp(data?.applicant) &&
                            getYearsOfExp(data?.applicant) !== null && (
                                <p className="small">
                                    <img
                                        src={experienceIcon}
                                        alt=""
                                        className="me-2"
                                        style={{
                                            width: 22,
                                        }}
                                    />{' '}
                                    {`${getYearsOfExp(
                                        data?.applicant
                                    )} Years of Experience`}
                                </p>
                            )}
                        {data?.applicant?.salaryMin &&
                            data?.applicant?.salaryMin !== null && (
                                <p className="small">
                                    <img
                                        src={salaryIcon}
                                        alt=""
                                        className="me-2"
                                        style={{
                                            width: 22,
                                        }}
                                    />{' '}
                                    {data?.applicant?.salaryMin === 1 &&
                                    data?.applicant?.salaryMax === 1
                                        ? salaryRanges.COMPETIIVE_SALARY
                                        : data?.applicant?.salaryMin === 2 &&
                                          data?.applicant?.salaryMax === 2
                                        ? salaryRanges.INDUSTRY_STANDARD
                                        : `${formatMoney(
                                              data?.applicant?.salaryMin,
                                              currencyOptions,
                                              data?.applicant?.salaryCurrency ||
                                                  'SGD'
                                          )} - ${formatMoney(
                                              data?.applicant?.salaryMax,
                                              currencyOptions,
                                              data?.applicant?.salaryCurrency ||
                                                  'SGD'
                                          )}`}
                                </p>
                            )}
                    </div>
                    <div style={{ flexBasis: '40%' }}>
                        {data?.applicant?.skills?.length > 0 && (
                            <>
                                <div className="mb-3">
                                    <div className="d-flex mb-2">
                                        <div className="me-2">
                                            <Icon
                                                path={mdiTools}
                                                size={0.8}
                                                color={'#d7a048'}
                                            />
                                        </div>
                                        <div>
                                            <span
                                                className="mb-2 text-primary"
                                                style={{ fontSize: 14 }}
                                            >
                                                <strong>
                                                    Technical Skills
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        {data?.applicant?.skills
                                            ?.slice(0, 5)
                                            .map((skill) => {
                                                return (
                                                    <span
                                                        style={{
                                                            backgroundColor:
                                                                '#009CDE',
                                                            borderRadius:
                                                                '12px',
                                                            padding: '0 16px',
                                                            color: '#ffffff',
                                                            fontSize: 12,
                                                        }}
                                                        className="me-2 mb-1 text-nowrap text-capitalize"
                                                    >
                                                        {skill}
                                                    </span>
                                                )
                                            })}
                                        {data?.applicant?.skills?.length >
                                            5 && (
                                            <>
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            '#009CDE',
                                                        borderRadius: '12px',
                                                        padding: '0 16px',
                                                        color: '#ffffff',
                                                        fontSize: 12,
                                                    }}
                                                    className="me-2 mb-1 text-nowrap"
                                                >
                                                    +{' '}
                                                    {data?.applicant?.skills
                                                        ?.length - 5}{' '}
                                                    more
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <span
                                        className="hand"
                                        onClick={() => {
                                            handleView(data?.applicant, itemId)
                                        }}
                                        style={{
                                            color: '#009CDE!important',
                                            textDecoration: 'underline',
                                            fontSize: 12,
                                        }}
                                    >
                                        <small>See More</small>
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="d-flex justify-content-between flex-column h-100">
                        <div className="text-center text-md-end d-md-block">
                            <img
                                src={DismissIcon}
                                alt="icon"
                                role="button"
                                style={{ width: 40 }}
                                className=""
                                onClick={() => {
                                    console.log(data?.applicant)
                                    swipeAction({
                                        job: data?.job,
                                        applicant: data?.applicant,
                                        action: defaultSwipeActions.left,
                                        itemId: itemId,
                                    })
                                }}
                            />

                            <img
                                src={SuperLikeIcon}
                                alt="icon"
                                role="button"
                                style={{ width: 40 }}
                                className="mx-2"
                                onClick={() => {
                                    swipeAction({
                                        job: data?.job,
                                        applicant: data?.applicant,
                                        action: defaultSwipeActions.top,
                                        itemId: itemId,
                                    })
                                }}
                            />
                            <img
                                src={LikeIcon}
                                alt="icon"
                                role="button"
                                style={{ width: 40 }}
                                onClick={() => {
                                    swipeAction({
                                        job: data?.job,
                                        applicant: data?.applicant,
                                        action: defaultSwipeActions.right,
                                        itemId: itemId,
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row border-top border-bottom py-3 applicant-card d-none"
                id={itemId}
            >
                <div className="col-md-6 col-sm-12 d-flex">
                    <div
                        className="text-center position-relative"
                        style={{ height: '100px' }}
                    >
                        <ElevatorPitch
                            jobseeker={data?.applicant}
                            width={100}
                            height={100}
                            hasSwipeActions={!hasSwipeActions}
                        />
                    </div>
                    <div className="ms-3">
                        <h5 className="text-primary mb-0">
                            {data?.applicant?.anonymous
                                ? 'Job Seeker'
                                : titleCase(
                                      `${data?.applicant?.firstName} ${data?.applicant?.lastName}`
                                  )}
                        </h5>
                        {data?.applicant?.preferredTitle && (
                            <p className="small text-muted mb-0">
                                <strong>
                                    {data?.applicant?.preferredTitle}
                                </strong>
                            </p>
                        )}
                        <p className="text-wrap mb-0">
                            {titleCase(data?.job?.jobTitle)}
                        </p>
                        <p className="text-muted text-wrap mb-0">
                            {data?.applicant?.jobFunction}
                        </p>
                        <div className="mt-3 d-flex flex-column flex-md-row mb-3 mb-md-0">
                            <div className="d-flex align-items-center me-4">
                                <div>
                                    <img
                                        src={locationIcon}
                                        className="h-24 me-2"
                                        alt=""
                                    />
                                </div>
                                <div className="">
                                    <p className="small mb-0">
                                        Based in{' '}
                                        {data?.applicant?.currentLocation}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                {data?.applicant?.totalExperienceMin ===
                                    undefined ||
                                data?.applicant?.totalExperienceMin === null ? (
                                    <>
                                        <div>
                                            <img
                                                src={jobIcon}
                                                className="h-24 me-2"
                                                alt=""
                                            />
                                        </div>
                                        <div className="">
                                            <p className="small mb-0">
                                                No work experience
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <img
                                                src={jobIcon}
                                                className="h-24 me-2"
                                                alt=""
                                            />
                                        </div>
                                        <div className="">
                                            <p className="small mb-0">
                                                {data?.applicant
                                                    ?.totalExperienceMin ===
                                                    undefined ||
                                                data?.applicant
                                                    ?.totalExperienceMin ===
                                                    null
                                                    ? 'No Work Experience'
                                                    : data?.applicant
                                                          ?.totalExperienceMin ===
                                                      data?.applicant
                                                          ?.totalExperienceMax
                                                    ? `${data?.applicant?.totalExperienceMin} Years of Experience`
                                                    : data?.applicant
                                                          ?.totalExperienceMin ===
                                                      20
                                                    ? `More than ${data?.applicant?.totalExperienceMin} Years of Experience`
                                                    : `${data?.applicant?.totalExperienceMin} - ${data?.applicant?.totalExperienceMax} Years of Experience`}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="text-center text-md-end mt-2 mt-md-0 d-grid d-md-block">
                        {data?.employer?.action === 'superLike' && (
                            <span className="me-3 mb-2 mb-md-0">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-star-fill me-2 text-warning"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                                Super Liked
                            </span>
                        )}
                        <button
                            className="btn btn-primary text-center mb-2"
                            onClick={handleView}
                        >
                            View Profile
                        </button>
                    </div>
                    {hasSwipeActions ? (
                        <div className="text-center text-md-end d-grid d-md-block">
                            <button
                                onClick={() =>
                                    swipeAction({
                                        job: data?.job,
                                        applicant: data?.applicant,
                                        action: defaultSwipeActions.left,
                                        itemId: itemId,
                                    })
                                }
                                className="btn btn-outline-primary  mb-2 mb-md-0 me-0 me-md-2 text-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                                Dismiss
                            </button>
                            <button
                                onClick={() =>
                                    swipeAction({
                                        job: data?.job,
                                        applicant: data?.applicant,
                                        action: defaultSwipeActions.top,
                                        itemId: itemId,
                                    })
                                }
                                className="btn btn-outline-warning mb-2 mb-md-0 text-black me-0 me-md-2 text-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-star me-2"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                </svg>
                                Super Like
                            </button>
                            <button
                                onClick={() =>
                                    swipeAction({
                                        job: data?.job,
                                        applicant: data?.applicant,
                                        action: defaultSwipeActions.right,
                                        itemId: itemId,
                                    })
                                }
                                className="btn btn-outline-primary mb-2 mb-md-0 text-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-heart me-2"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                </svg>
                                Like
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
    }
}
export default connect(mapStateToProps, null)(RecommendationItem)
