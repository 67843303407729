import {
    mdiAccountCircleOutline,
    mdiBriefcaseCheckOutline,
    mdiBriefcaseClockOutline,
    mdiFileDocumentOutline,
    mdiHandHeartOutline,
    mdiSchoolOutline,
    mdiTools,
} from '@mdi/js'
import Icon from '@mdi/react'
import moment from 'moment'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import defaultImg from '../../../../../assets/img/defaultImg.png'
import SuperLikeIcon from '../../../../../assets/img/editProfile/superLikeIcon.png'
import DismissIcon from '../../../../../assets/img/profilePreview/DismissIcon.png'
import LikeIcon from '../../../../../assets/img/profilePreview/LikeIcon.png'
import ProfilePreview from '../../../../../components/profile-preview'
import CustomAlert from '../../../../../components/reusables/Alert/Alert'
import ElevatorPitchV2 from '../../../../../components/reusables/elevator-pitch/ElevatorPitchV2'
import { jobApplicantRequest } from '../../../../../service/requests'
import { SLIDE_CLASSES, defaultActions } from '../../../Explore'

export const ProfileModal = (props) => {
    const {
        showProfileModal,
        match,
        setShowProfileModal,
        handleCloseProfileModal,
        handleShowProfileModal,
        seeker,
        handleAction,
        explore,
        swipeAction,
        setShowSubcription,
        hasSubs,
        data,
        defaultSwipeActions,
        itemId,
        isFromMatches,
        tab,
        getApplicants,
        hasSwipeActions,
        isFromExplore,
    } = props

    const application = {
        ADD_TO_SHORT_LIST: 'ADD_TO_SHORT_LIST',
        REMOVE_FROM_SHORT_LIST: 'REMOVE_FROM_SHORT_LIST',
        DID_NOT_QQUALIFY: 'DID_NOT_QQUALIFY',
        REMOVE_FROM_ARCHIVE: 'REMOVE_FROM_ARCHIVE',
    }

    const [modalData, setModaldata] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)
    const clearAlert = () => {
        setModaldata(null)
        setTimeout(() => {
            setAlertMessage(null)
            getApplicants()
        }, 3000)
    }

    const [emailCopied, setEmailCopied] = useState(null)
    const [phoneCopied, setPhoneCopied] = useState(null)
    const copyEmail = (data) => {
        setPhoneCopied(false)
        setEmailCopied(true)
        navigator.clipboard.writeText(data)
        setTimeout(() => {
            setEmailCopied(false)
        }, 3000)
    }

    const copyPhone = (data) => {
        setEmailCopied(false)
        setPhoneCopied(true)
        navigator.clipboard.writeText(data)
        setTimeout(() => {
            setPhoneCopied(false)
        }, 3000)
    }

    const addToShortlist = () => {
        if (!!seeker) {
            let payload = {
                status: 'assessment',
                processingType: 'applicant_shortlisted',
            }
            try {
                jobApplicantRequest
                    .jobProcessing(seeker.jobId, seeker.userId, payload)
                    .then((res) => {
                        if (res) {
                            setAlertMessage('Applicant added to Shortlist')
                            clearAlert()
                        }
                    })
            } catch (err) {
                console.log(err)
            }
        }
    }
    const removeFromShortlist = () => {
        if (!!seeker) {
            let payload = {
                status: 'active',
                processingType: 'acknowledge_application',
            }
            try {
                jobApplicantRequest
                    .jobProcessing(seeker.jobId, seeker.userId, payload)
                    .then((res) => {
                        if (res) {
                            setAlertMessage('Applicant removed from Shortlist')

                            clearAlert()
                        }
                    })
            } catch (err) {
                console.log(err)
            }
        }
    }
    const didNotQualify = () => {
        if (!!seeker) {
            let payload = {
                status: 'assessment',
                processingType: 'applicant_did_not_qualify',
            }
            try {
                jobApplicantRequest
                    .jobProcessing(seeker.jobId, seeker.userId, payload)
                    .then((res) => {
                        if (res) {
                            setAlertMessage('Applicant moved to Archive')

                            clearAlert()
                        }
                    })
            } catch (err) {
                console.log(err)
            }
        }
    }
    const removeFromArchive = () => {
        if (!!seeker) {
            let payload = {
                status: 'active',
                processingType: 'acknowledge_application',
            }
            try {
                jobApplicantRequest
                    .jobProcessing(seeker.jobId, seeker.userId, payload)
                    .then((res) => {
                        if (res) {
                            setAlertMessage('Applicant removed from Archive')

                            clearAlert()
                        }
                    })
            } catch (err) {
                console.log(err)
            }
        }
    }
    const handleShowModal = (type) => {
        if (type === application.ADD_TO_SHORT_LIST) {
            setModaldata({
                title: 'Application Update',
                message: `You are going to update the application status for ${seeker?.firstName} ${seeker?.lastName} `,
                buttons: (
                    <div className="w-100 d-flex justify-content-center">
                        <button
                            className="btn btn-outline-primary text-center me-1"
                            onClick={() => setModaldata(null)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-center"
                            onClick={() => addToShortlist()}
                        >
                            Proceed
                        </button>
                    </div>
                ),
            })
        }
        if (type === application.REMOVE_FROM_SHORT_LIST) {
            setModaldata({
                title: 'Application Update',
                message: `You are going to update the application status for ${seeker?.firstName} ${seeker?.lastName} `,
                buttons: (
                    <div className="w-100 d-flex justify-content-center">
                        <button
                            className="btn btn-outline-primary text-center me-1"
                            onClick={() => setModaldata(null)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-center"
                            onClick={() => removeFromShortlist()}
                        >
                            Proceed
                        </button>
                    </div>
                ),
            })
        }
        if (type === application.DID_NOT_QQUALIFY) {
            setModaldata({
                title: 'Application Update',
                message: `You are going to update the application status for ${seeker?.firstName} ${seeker?.lastName} `,
                buttons: (
                    <div className="w-100 d-flex justify-content-center">
                        <button
                            className="btn btn-outline-primary text-center me-1"
                            onClick={() => setModaldata(null)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-center"
                            onClick={() => didNotQualify()}
                        >
                            Proceed
                        </button>
                    </div>
                ),
            })
        }
        if (type === application.REMOVE_FROM_ARCHIVE) {
            setModaldata({
                title: 'Application Update',
                message: `You are going to update the application status for ${seeker?.firstName} ${seeker?.lastName} `,
                buttons: (
                    <div className="w-100 d-flex justify-content-center">
                        <button
                            className="btn btn-outline-primary text-center me-1"
                            onClick={() => setModaldata(null)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-center"
                            onClick={() => removeFromArchive()}
                        >
                            Proceed
                        </button>
                    </div>
                ),
            })
        }
    }

    return (
        <>
            <Modal
                show={showProfileModal}
                onHide={handleCloseProfileModal}
                centered
                scrollable
                size="xl"
                className=""
            >
                <Modal.Body
                    className=" position-relative"
                    style={{
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        // background: `url(${TilePatternBg})`,
                    }}
                >
                    <CustomAlert
                        variant="success"
                        show={!!alertMessage}
                        body={alertMessage}
                        position="bottom-end"
                    />

                    <ProfilePreview
                        isFromExplore={isFromExplore}
                        current_user={{ ...seeker, ...match?.matchUser }}
                        match={match}
                    />
                    <div
                        className="position-absolute d-flex"
                        style={{
                            borderRadius: '50%',
                            width: 50,
                            height: 50,
                            right: 5,
                            top: 5,
                            zIndex: 999999,
                            background: 'hsla(0, 100%, 0%, 0.5)',
                        }}
                        role="button"
                        onClick={() => {
                            handleCloseProfileModal()
                        }}
                    >
                        <div className="m-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="white"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>
                    <div className="position-relative d-none">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-3">
                                    <div className="card-body d-flex justify-content-between">
                                        <div className="d-flex flex-column flex-md-row w-100">
                                            <div className="me-0 me-md-4 d-flex align-items-center flex-column">
                                                <img
                                                    src={
                                                        seeker?.profileUrl
                                                            ? seeker?.profileUrl
                                                            : defaultImg
                                                    }
                                                    style={{
                                                        borderRadius: 3,
                                                        width: 100,
                                                        height: 100,
                                                        objectFit: 'cover',
                                                    }}
                                                    alt=""
                                                />
                                                <div className="mb-2 mt-3">
                                                    <ElevatorPitchV2
                                                        jobseeker={seeker}
                                                        withSwipeAction={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between">
                                                <div className="text-center text-md-start">
                                                    <h5 className="text-primary text-capitalize text-nowrap">
                                                        <strong>
                                                            {seeker?.firstName}{' '}
                                                            {!explore
                                                                ? seeker?.lastName
                                                                : ''}
                                                        </strong>
                                                    </h5>
                                                    {match?.matchStatus &&
                                                        match?.matchUser
                                                            ?.email && (
                                                            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                                                                <p className="mb-0 me-1  text-primary">
                                                                    {
                                                                        match
                                                                            ?.matchUser
                                                                            ?.email
                                                                    }
                                                                </p>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-clipboard mb-0 hand text-primary"
                                                                    viewBox="0 0 16 16"
                                                                    onClick={() => {
                                                                        copyEmail(
                                                                            match
                                                                                ?.matchUser
                                                                                ?.email
                                                                        )
                                                                    }}
                                                                >
                                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                                </svg>
                                                                {emailCopied && (
                                                                    <span
                                                                        className="ms-2 text-success"
                                                                        style={{
                                                                            fontSize: 11,
                                                                        }}
                                                                    >
                                                                        <small>
                                                                            Copied!
                                                                        </small>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    {match?.matchStatus &&
                                                        seeker?.phoneNumber && (
                                                            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                                                                <p className="mb-2 me-1 text-primary">
                                                                    {
                                                                        seeker?.phoneNumber
                                                                    }
                                                                </p>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-clipboard mb-0 hand text-primary"
                                                                    viewBox="0 0 16 16"
                                                                    onClick={() => {
                                                                        copyPhone(
                                                                            seeker?.phoneNumber
                                                                        )
                                                                    }}
                                                                >
                                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                                </svg>
                                                                {phoneCopied && (
                                                                    <span
                                                                        className="ms-2 text-success"
                                                                        style={{
                                                                            fontSize: 11,
                                                                        }}
                                                                    >
                                                                        <small>
                                                                            Copied!
                                                                        </small>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}

                                                    <p className="">
                                                        <strong>
                                                            {
                                                                seeker?.preferredTitle
                                                            }
                                                        </strong>
                                                    </p>
                                                    <p>{seeker?.jobFunction}</p>
                                                    <p>
                                                        {
                                                            seeker?.currentLocation
                                                        }
                                                    </p>
                                                    <p>{seeker?.education}</p>
                                                    <div className="mb-2 mt-3">
                                                        <ElevatorPitchV2
                                                            seeker={seeker}
                                                            withSwipeAction={
                                                                true
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-none">
                                                    <p>
                                                        This candidate liked
                                                        your job post.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {match?.matchStatus == 'match' && (
                                            <>
                                                {seeker && (
                                                    <div>
                                                        <DownloadProfileButton
                                                            userId={
                                                                seeker?.userId
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )} */}

                                        {/* HIDDEN */}
                                        <div className="d-none">
                                            <div className="border border-warning rounded-pill px-4 py-1">
                                                <p className="text-warning">
                                                    Premium Candidate
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {seeker?.bio && (
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h5 className="mb-2">
                                                <strong>ABOUT ME</strong>
                                            </h5>
                                            <p>{seeker?.bio}</p>
                                        </div>
                                    </div>
                                )}
                                {/* HIDDEN */}
                                {seeker?.experienceHistory?.length > 0 && (
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="me-4">
                                                    <Icon
                                                        path={
                                                            mdiBriefcaseClockOutline
                                                        }
                                                        size={1.2}
                                                        color={'#d7a048'}
                                                    />
                                                </div>
                                                <div>
                                                    <h5 className="mb-3">
                                                        <strong>
                                                            EXPERIENCE
                                                        </strong>
                                                    </h5>
                                                    {seeker?.experienceHistory
                                                        ?.sort(function (a, b) {
                                                            return (
                                                                new Date(
                                                                    b.startDate
                                                                ) -
                                                                new Date(
                                                                    a.startDate
                                                                )
                                                            )
                                                        })
                                                        .map((exp) => {
                                                            return (
                                                                <>
                                                                    <div
                                                                        className={`${
                                                                            seeker
                                                                                ?.experienceHistory
                                                                                ?.length >
                                                                                1 &&
                                                                            'mb-3 border-bottom pb-2 me-4'
                                                                        }`}
                                                                    >
                                                                        <h6>
                                                                            <strong>
                                                                                {
                                                                                    exp.companyName
                                                                                }
                                                                            </strong>
                                                                        </h6>
                                                                        <p>
                                                                            {
                                                                                exp.jobTitle
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {
                                                                                exp.location
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            {moment(
                                                                                exp.startDate
                                                                            ).format(
                                                                                'MMM YYYY'
                                                                            )}{' '}
                                                                            -{' '}
                                                                            {!exp.endDate
                                                                                ? 'Present'
                                                                                : moment(
                                                                                      exp.endDate
                                                                                  ).format(
                                                                                      'MMM YYYY'
                                                                                  )}
                                                                        </p>
                                                                        <p className="mt-2">
                                                                            <strong>
                                                                                Responsibilities
                                                                            </strong>
                                                                        </p>
                                                                        <p className="w-75">
                                                                            {
                                                                                exp.responsibilities
                                                                            }
                                                                        </p>
                                                                        {exp.achievements && (
                                                                            <>
                                                                                <p className="mt-3">
                                                                                    <strong>
                                                                                        Awards
                                                                                    </strong>
                                                                                </p>
                                                                                <p className="w-75">
                                                                                    {
                                                                                        exp.achievements
                                                                                    }
                                                                                </p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    <div className="d-none">
                                                        <a href="#">
                                                            <strong>
                                                                See all 6
                                                                experience
                                                            </strong>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* HIDDEN */}
                            </div>
                            <div className="col-md-12">
                                {(seeker?.softSkills?.length > 0 ||
                                    seeker?.skills?.length > 0) && (
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="me-4">
                                                    <Icon
                                                        path={mdiTools}
                                                        size={1.2}
                                                        color={'#d7a048'}
                                                    />
                                                </div>
                                                {seeker?.skills?.length > 0 && (
                                                    <div>
                                                        <h5 className="mb-2">
                                                            <strong>
                                                                Technical Skills
                                                            </strong>
                                                        </h5>
                                                        <div className="d-flex flex-wrap">
                                                            {seeker?.skills?.map(
                                                                (skill) => {
                                                                    return (
                                                                        <p className="skills-pill me-2 mb-2">
                                                                            {
                                                                                skill
                                                                            }
                                                                        </p>
                                                                    )
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {seeker?.softSkills?.length > 0 && (
                                                <div className="d-flex mt-3">
                                                    <div className="me-4">
                                                        <Icon
                                                            path={
                                                                mdiAccountCircleOutline
                                                            }
                                                            size={1.2}
                                                            color={'#d7a048'}
                                                        />
                                                    </div>
                                                    <div>
                                                        <h5 className="mb-2">
                                                            <strong>
                                                                Soft Skills
                                                            </strong>
                                                        </h5>
                                                        <div className="d-flex flex-wrap">
                                                            {seeker?.softSkills?.map(
                                                                (softSkill) => {
                                                                    return (
                                                                        <p className="skills-pill me-2 mb-2">
                                                                            {
                                                                                softSkill
                                                                            }
                                                                        </p>
                                                                    )
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {seeker?.importantForYou?.length > 0 && (
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="d-flex mt-3">
                                                <div className="me-4">
                                                    <Icon
                                                        path={
                                                            mdiHandHeartOutline
                                                        }
                                                        size={1.2}
                                                        color={'#d7a048'}
                                                    />
                                                </div>
                                                <div>
                                                    <h5 className="mb-2">
                                                        <strong>
                                                            What I Value Most
                                                        </strong>
                                                    </h5>
                                                    <div className="d-flex flex-wrap">
                                                        {seeker?.importantForYou?.map(
                                                            (value) => {
                                                                return (
                                                                    <p className="skills-pill me-2 mb-2">
                                                                        {value}
                                                                    </p>
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* HIDDEN */}
                                {(seeker?.employmentStatus?.length > 0 ||
                                    seeker?.workSetup?.length > 0) && (
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="d-flex mt-3">
                                                <div className="me-4">
                                                    <Icon
                                                        path={
                                                            mdiBriefcaseCheckOutline
                                                        }
                                                        size={1.2}
                                                        color={'#d7a048'}
                                                    />
                                                </div>
                                                <div>
                                                    {seeker?.employmentStatus && (
                                                        <div className="mb-3">
                                                            <h5 className="mb-2">
                                                                <strong>
                                                                    Employment
                                                                    Status
                                                                </strong>
                                                            </h5>
                                                            <div className="d-flex flex-wrap">
                                                                <p className="skills-pill me-2 mb-2">
                                                                    {
                                                                        seeker?.employmentStatus
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {seeker?.employmentType
                                                        ?.length > 0 && (
                                                        <div className="mb-3">
                                                            <h5 className="mb-2">
                                                                <strong>
                                                                    Employment
                                                                    Type
                                                                </strong>
                                                            </h5>
                                                            <div className="d-flex flex-wrap">
                                                                {seeker?.employmentType?.map(
                                                                    (value) => {
                                                                        return (
                                                                            <p className="skills-pill me-2 mb-2">
                                                                                {
                                                                                    value
                                                                                }
                                                                            </p>
                                                                        )
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {seeker?.workSetup?.length >
                                                        0 && (
                                                        <div className="mb-3">
                                                            <h5 className="mb-2">
                                                                <strong>
                                                                    Work Setup
                                                                </strong>
                                                            </h5>
                                                            <div className="d-flex flex-wrap">
                                                                {seeker?.workSetup?.map(
                                                                    (value) => {
                                                                        return (
                                                                            <p className="skills-pill me-2 mb-2">
                                                                                {
                                                                                    value
                                                                                }
                                                                            </p>
                                                                        )
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {seeker?.educationHistory?.length > 0 && (
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="d-flex mt-3">
                                                <div className="me-4">
                                                    <Icon
                                                        path={mdiSchoolOutline}
                                                        size={1.2}
                                                        color={'#d7a048'}
                                                    />
                                                </div>
                                                <div className="w-100 me-4">
                                                    <div>
                                                        <h5 className="mb-3">
                                                            <strong>
                                                                Education
                                                            </strong>
                                                        </h5>
                                                        {seeker?.educationHistory
                                                            ?.sort(function (
                                                                a,
                                                                b
                                                            ) {
                                                                return (
                                                                    new Date(
                                                                        b.educStartDate
                                                                    ) -
                                                                    new Date(
                                                                        a.educStartDate
                                                                    )
                                                                )
                                                            })
                                                            .map((educ) => {
                                                                return (
                                                                    <>
                                                                        <div
                                                                            className={`${
                                                                                seeker
                                                                                    ?.educationHistory
                                                                                    ?.length >
                                                                                    1 &&
                                                                                'mb-3 border-bottom pb-2 me-4 w-100'
                                                                            }`}
                                                                        >
                                                                            <h6>
                                                                                <strong>
                                                                                    {
                                                                                        educ?.educField
                                                                                    }
                                                                                </strong>
                                                                            </h6>
                                                                            <p>
                                                                                {
                                                                                    educ?.educationType
                                                                                }
                                                                            </p>
                                                                            <p>
                                                                                {
                                                                                    educ?.educName
                                                                                }
                                                                            </p>
                                                                            <p>
                                                                                {moment(
                                                                                    educ?.educStartDate
                                                                                ).format(
                                                                                    'MMM YYYY'
                                                                                )}{' '}
                                                                                -{' '}
                                                                                {educ?.educEndDate
                                                                                    ? moment(
                                                                                          educ?.educEndDate
                                                                                      ).format(
                                                                                          'MMM YYYY'
                                                                                      )
                                                                                    : 'Present'}
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(match?.matchStatus == 'match' ||
                                    match?.applicationStatus ===
                                        'shortlisted') && (
                                    <>
                                        {seeker?.portfolioAndFiles?.length >
                                            0 && (
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex mt-3">
                                                        <div className="me-4">
                                                            <Icon
                                                                path={
                                                                    mdiFileDocumentOutline
                                                                }
                                                                size={1.2}
                                                                color={
                                                                    '#d7a048'
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <h5 className="mb-2">
                                                                <strong>
                                                                    Resume &
                                                                    Portfolio
                                                                </strong>
                                                            </h5>
                                                            <div className="">
                                                                {seeker?.portfolioAndFiles.map(
                                                                    (file) => {
                                                                        return (
                                                                            <div>
                                                                                {' '}
                                                                                <a
                                                                                    href={
                                                                                        file.link
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            defaultImg
                                                                                        }
                                                                                        width={
                                                                                            30
                                                                                        }
                                                                                        height={
                                                                                            30
                                                                                        }
                                                                                        className="me-2 mb-2"
                                                                                        style={{
                                                                                            objectFit:
                                                                                                'cover',
                                                                                            borderRadius: 5,
                                                                                        }}
                                                                                        alt=""
                                                                                    />

                                                                                    {
                                                                                        file.title
                                                                                    }
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {hasSwipeActions && (
                    <Modal.Footer>
                        <div className="w-100">
                            <div className="">
                                <div className="d-flex my-auto w-100  px-3 justify-content-center">
                                    <div>
                                        <img
                                            src={DismissIcon}
                                            width={52}
                                            alt="icon"
                                            role="button"
                                            className={`${!explore && 'me-2'}`}
                                            onClick={() =>
                                                !swipeAction
                                                    ? handleAction(
                                                          seeker,
                                                          defaultActions.left,
                                                          SLIDE_CLASSES.SLIDE_LEFT
                                                      )
                                                    : swipeAction({
                                                          job: data?.matchJob,
                                                          applicant:
                                                              data?.matchApplicant,
                                                          action: defaultSwipeActions.left,
                                                          itemId: itemId,
                                                      })
                                            }
                                        />
                                    </div>
                                    {explore && (
                                        <div className="mx-3">
                                            <img
                                                src={SuperLikeIcon}
                                                width={52}
                                                alt="icon"
                                                role="button"
                                                onClick={() =>
                                                    explore
                                                        ? handleAction(
                                                              seeker,
                                                              defaultActions.top,
                                                              SLIDE_CLASSES.SLIDE_TOP
                                                          )
                                                        : swipeAction({
                                                              job: data?.matchJob,
                                                              applicant:
                                                                  data?.matchApplicant,
                                                              action: defaultSwipeActions.top,
                                                              itemId: itemId,
                                                          })
                                                }
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <img
                                            src={LikeIcon}
                                            alt="icon"
                                            width={52}
                                            role="button"
                                            onClick={() =>
                                                explore
                                                    ? handleAction(
                                                          seeker,
                                                          defaultActions.right,
                                                          SLIDE_CLASSES.SLIDE_RIGHT
                                                      )
                                                    : swipeAction({
                                                          job: data?.matchJob,
                                                          applicant:
                                                              data?.matchApplicant,
                                                          action: defaultSwipeActions.right,
                                                          itemId: itemId,
                                                      })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                )}
                {!!isFromMatches && (
                    <div className="py-3 d-none">
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center h-100 mx-2 mx-md-0">
                            {tab === 'Archived' && (
                                <button
                                    className="btn btn-outline-primary mx-2 my-1 my-md-0 btn-block btn-md-auto text-nowrap"
                                    id="remove_from_archive"
                                    onClick={() =>
                                        handleShowModal(
                                            application.REMOVE_FROM_ARCHIVE
                                        )
                                    }
                                >
                                    <span>Remove from Archive</span>
                                </button>
                            )}
                            {(tab === 'Active' || tab === 'Shortlisted') && (
                                <button
                                    className="btn btn-outline-primary mx-2  my-1 my-md-0 btn-block btn-md-auto text-nowrap"
                                    id="applicant_did_not_qualify"
                                    onClick={() =>
                                        handleShowModal(
                                            application.DID_NOT_QQUALIFY
                                        )
                                    }
                                >
                                    <span>Applicant Did Not Qualify</span>
                                </button>
                            )}
                            {tab === 'Shortlisted' && (
                                <button
                                    className="btn btn-primary mx-2 my-1 my-md-0 btn-block btn-md-auto text-nowrap"
                                    id="remove_from_shortlist"
                                    onClick={() =>
                                        handleShowModal(
                                            application.REMOVE_FROM_SHORT_LIST
                                        )
                                    }
                                >
                                    <span>
                                        Remove from Application Shortlist
                                    </span>
                                </button>
                            )}
                            {(tab === 'Active' || tab === 'Archived') && (
                                <button
                                    className="btn btn-primary mx-2  my-1 my-md-0 btn-block btn-md-auto text-nowrap"
                                    id="applicant_shortlisted"
                                    onClick={() =>
                                        handleShowModal(
                                            application.ADD_TO_SHORT_LIST
                                        )
                                    }
                                >
                                    <span>Add to Interview Shortlist</span>
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </Modal>
            <Modal show={!!modalData} centered>
                <Modal.Header className="bg-primary">
                    <h6 className="text-center text-light w-100">
                        {modalData?.title}
                    </h6>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-2">
                        <h6 className="open-sans text-center">
                            {modalData?.message}
                        </h6>
                    </div>
                </Modal.Body>
                <div className="w-100 py-2 my-1">{modalData?.buttons}</div>
            </Modal>
        </>
    )
}
