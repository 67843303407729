import { Box, Button, Dialog } from '@material-ui/core'
import { TourProvider, useTour } from '@reactour/tour'
import disableScroll from 'disable-scroll'
import _, { uniqBy } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import helpIcon from '../../assets//joblu-2.0/img/new-branding/help-icon.svg'
import CloseWhite from '../../assets/img/CloseWhite.png'
import premiumBg from '../../assets/img/createJob/Go_Premium_BG.png'
import DefaultImg from '../../assets/img/defaultImg.png'
import filterIcon from '../../assets/img/filterIcon.svg'
import multiplefair from '../../assets/multipleJobfairBadge.png'
import JobluTypography from '../../components/Typography'
import appActions from '../../redux/app/actions'
import jobfairActions from '../../redux/job-fair/jobfairActions'
import userActions from '../../redux/user/actions'
import {
    jobApplicantRequest,
    jobFairRequest,
    jobMatchRequest,
    jobsRequest,
    userRequest,
} from '../../service/requests'
import { errorSwipeActions } from '../../utils/constants'
import useWindowDimensions from '../../utils/hooks'
import { JobFairBanner } from '../job-fair/Banners'
import { LeaveModal } from '../job-fair/JobFairModals'
import { SubscriptionPopup } from '../likes/components/ApplicantItem'
import ExpandedExplore from './components/ExpandedExplore'
import ExploreModal from './components/ExploreModal'
import ShrinkedExplore from './components/ShrinkedExplore'
import WelcomeTourModal from './components/WelcomeTourModal'
import { dummy_data } from './dummy-data'
import './slide.css'
import tourSteps, { doArrow } from './tour-steps'
import ExploreV2 from './v2/Explorev2'

const MessageSentModal = (props) => {
    const { showSentModal, handleCloseSentModal } = props
    return (
        <>
            <Modal show={showSentModal} centered onHide={handleCloseSentModal}>
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {/* {modalHeader} */}
                            Candidate has been <strong>Superliked</strong>
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-4  " style={{ fontSize: 20 }}>
                            <strong>
                                <span className="text-primary">
                                    Message Sent!
                                </span>
                            </strong>
                        </p>
                        <div className="mb-4">
                            <p style={{ fontSize: 20 }}>
                                Now let’s just wait for the candidate’s
                                response.
                            </p>
                        </div>

                        <div className="d-flex justify-content-center">
                            <button
                                className={`btn text-center btn-primary w-50`}
                                onClick={() => {
                                    handleCloseSentModal()
                                }}
                            >
                                Okay
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
const SuperLikeModal = (props) => {
    const {
        selectedApplicant,
        handleCloseConfirmModal,
        showConfirmModal,
        sendMessage,
        setMessage,
        sendMessageError,
        messageError,
        message,
    } = props
    return (
        <>
            <Modal
                show={showConfirmModal}
                centered
                onHide={handleCloseConfirmModal}
            >
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {/* {modalHeader} */}
                            Superlike Candidate
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-3" style={{ fontSize: 20 }}>
                            You are about to{' '}
                            <strong>
                                <span className="text-primary">Superlike</span>
                            </strong>
                        </p>
                        <div className="mb-3">
                            <>
                                <img
                                    src={
                                        selectedApplicant?.profileUrl ||
                                        DefaultImg
                                    }
                                    alt=""
                                    className="rounded-circle mb-3"
                                    style={{
                                        width: 90,
                                        height: 90,
                                        objectFit: 'cover',
                                    }}
                                />
                            </>
                            <div className="mb-3">
                                <p
                                    className="text-capitalize"
                                    style={{ fontSize: 30, fontWeight: 600 }}
                                >
                                    {selectedApplicant?.firstName}{' '}
                                    {selectedApplicant?.lastName}
                                </p>
                                <>
                                    <p
                                        style={{
                                            fontSize: 20,
                                            color: '#009CDE',
                                        }}
                                    >
                                        {selectedApplicant?.jobFunction}
                                        {/* {selectedJobData?.jobTitle} */}
                                    </p>
                                </>
                            </div>
                        </div>

                        <div className="mb-4 text-start">
                            <label htmlFor="">
                                <small className="text-muted">
                                    Send a message!
                                </small>
                            </label>
                            <textarea
                                name=""
                                className={`form-control ${
                                    messageError && 'border-danger'
                                }`}
                                id=""
                                cols="30"
                                rows="3"
                                value={message}
                                onChange={(e) => {
                                    if (e.target.value.length <= 240) {
                                        setMessage(e.target.value)
                                    }
                                }}
                            ></textarea>
                            <div className="text-end">
                                <span className="text-muted">
                                    <small>{message?.length || 0}/240</small>
                                </span>
                            </div>
                            {messageError && (
                                <>
                                    <span className="error-helper">
                                        Message for applicant is required
                                    </span>
                                </>
                            )}
                            {sendMessageError && (
                                <>
                                    <div
                                        class="alert alert-danger text-center mt-2"
                                        role="alert"
                                    >
                                        <small>
                                            Something went wrong while trying to
                                            send a message.
                                        </small>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="d-flex">
                            <button
                                className={`btn text-center btn-outline-primary w-50 me-2`}
                                onClick={() => {
                                    handleCloseConfirmModal()
                                }}
                            >
                                Cancel
                            </button>

                            <button
                                className="btn btn-primary w-50"
                                onClick={() => {
                                    sendMessage()
                                }}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export function TourButtons({ isLast }) {
    const { setIsOpen, setCurrentStep, currentStep, enableBody, steps } =
        useTour()
    const handleNext = () => {
        setCurrentStep(currentStep + 1)
        disableScroll.off()
        setTimeout(() => {
            if (!isLast) {
                disableScroll.on()
            }
        }, 1000)
        if (currentStep === steps.length - 1) {
            enableBody()
            setIsOpen(false)
        }
    }
    const handlePrev = () => {
        setCurrentStep(currentStep - 1)
        disableScroll.off()
        setTimeout(() => {
            if (!isLast) disableScroll.on()
        }, 1000)
    }

    return (
        <>
            <div className="d-flex justify-content-between mt-3">
                <div className="w-100 d-flex">
                    <div className="d-flex my-auto">
                        {Array.from(Array(tourSteps.length)).map(
                            (row, index) => {
                                return (
                                    <div
                                        className="border"
                                        style={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            marginRight: 2,
                                            background:
                                                index <= currentStep
                                                    ? '#000'
                                                    : '#f2f2f2',
                                        }}
                                    >
                                        {' '}
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
                <div className="d-flex ">
                    {currentStep !== 0 && (
                        <button
                            className="btn me-1"
                            onClick={() => handlePrev()}
                        >
                            Prev
                        </button>
                    )}
                    <button
                        className="btn btn-primary"
                        onClick={() => handleNext()}
                    >
                        {isLast ? 'Finish' : 'Next'}
                    </button>
                </div>
            </div>
        </>
    )
}
export const defaultActions = {
    right: 'like',
    left: 'not_interested',
    top: 'superLike',
}

export const SLIDE_CLASSES = {
    SLIDE_LEFT: 'slide-out-left',
    SLIDE_RIGHT: 'slide-out-right',
    SLIDE_TOP: 'slide-out-top',
}

function ExploreViews(props) {
    const {
        expanded,
        setExpanded,
        appState,
        tourOpen,
        setShowTutorial,
        filters,
        setFilters,
        handleFilter,
        setDoNotShowTips,
        User,
        isFirstTimeUser,
    } = props
    const tour = useTour()
    const { setIsOpen, isOpen, currentStep, steps } = tour
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)

    useEffect(() => {
        setIsOpen(tourOpen)
    }, [tourOpen, appState.showTutorial])

    const { width } = useWindowDimensions()

    const toggleTutorial = () => {
        setShowTutorial(true)
        setExpanded(false)
    }
    useEffect(() => {
        if (currentStep === steps.length) {
            if (isFirstTimeUser) {
                setDoNotShowTips(true)
                //update doNotShowTips from db
                let payload = {
                    userId: User?.user?.userId,
                    data: {
                        doNotShowTips: true,
                    },
                }
                try {
                    userRequest.updateUserStatus(payload).then((res) => {
                        //updated sucessfully
                    })
                } catch (err) {
                    console.log(err)
                }
            }
            setTimeout(() => {
                window.location.reload()
            }, 500)
        }
    }, [currentStep])
    return (
        <>
            {/* FOR MOBILE */}
            <div
                className={`d-flex  my-3 d-md-none align-items-center px-4 ${
                    appState.hideTriggerTutorial
                        ? 'justify-content-end'
                        : 'justify-content-between'
                }`}
            >
                {!appState.hideTriggerTutorial && (
                    <div>
                        <img
                            src={helpIcon}
                            style={{ width: 35, height: 35 }}
                            alt=""
                            onClick={() => {
                                toggleTutorial()
                            }}
                            className={width < 640 && 'explore-tour-step-3'}
                        />
                    </div>
                )}
                <div>
                    <img
                        src={filterIcon}
                        alt=""
                        onClick={() => {
                            setModalOpen(true)
                            setModalVariant('exploreFilter')
                        }}
                    />
                </div>
            </div>
            {!expanded ? (
                <ShrinkedExplore {...props} />
            ) : (
                <ExpandedExplore {...props} />
            )}
            <ExploreModal
                showModal={modalOpen}
                variant={modalVariant}
                setModalVariant={setModalVariant}
                setShowModal={setModalOpen}
                setFilters={setFilters}
                filters={filters}
                handleFilter={handleFilter}
            />
        </>
    )
}

function TourButton({ setTourOpen, setIsNoJobs }) {
    const tour = useTour()
    return (
        <p
            role="button"
            className="text-center text-decoration-underline open-sans-body w-100"
            onClick={() => {
                tour.setIsOpen(true)
                setIsNoJobs(false)
                setTourOpen(true)
            }}
        >
            Explore Tutorials
        </p>
    )
}
function Explore(props) {
    const {
        User,
        AppState,
        setShowTutorial,
        setDoNotShowTips,
        setHideTutorial,
        JobFairs,
        setSignedInJobFair,
        setShowBanner,
        getUser,
        showAlert,
    } = props

    const [jobSeekers, setJobSeekers] = useState([])
    const [expanded, setExpanded] = useState(false)
    const [tourOpen, setTourOpen] = useState(false)
    const [noJobs, setIsNoJobs] = useState(false)
    const [jobsList, setJobsList] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [modalHeaderTitle, setModalHeaderTitle] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const screen = document.getElementById('root')
    const location = useLocation()
    const [url_params, setSearchParams] = useSearchParams()
    const scrollerRef = useRef()
    const [showPremiumModal, setShowPremiumModal] = useState(false)
    const [showOutOfSuperLike, setShowOutOfSuperLike] = useState(false)
    const [showOutOfLike, setShowOutOfLike] = useState(false)
    const [page, setPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(20)
    const [totalData, setTotalData] = useState(0)
    const [offset, setOffset] = useState('1')
    const { currency } = AppState
    const paginate = (pageNumber) => setCurrentPage(pageNumber)
    const [salaryRange, setSalaryRange] = useState(null)
    const [salaryMin, setSalaryMin] = useState(null)
    const [salaryMax, setSalaryMax] = useState(null)
    const [salaryCurrency, setSalaryCurrency] = useState(null)
    const [yearsOfExp, setYearsOfExp] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const [showSentModal, setShowSentModal] = useState(false)
    const handleCloseSentModal = () => setShowSentModal(false)
    const [selectedApplicant, setSelectedApplicant] = useState(null)
    const [isFirstTimeUser, setFirstTimeUser] = useState(false)
    const [jobTitle, setJobTitle] = useState(null)
    const [matchImages, setMatchImages] = useState(null)
    const [applicantName, setApplicantName] = useState(null)
    const { isMobile } = useWindowDimensions()
    const [jobTitleQuery, setJobTitleQuery] = useState(null)
    const [jobfairData, setJobFairData] = useState(null)
    const [showbackToDashboard, setShowBackToDashboad] = useState(false)
    const [currPage, setCurrPage] = useState(null)
    const [allowTalentSearch, setAllowTalentSearch] = useState(false)
    const [showFreeCreditsModal, setShowFreeCreditsModal] = useState(false)

    useEffect(() => {
        console.log('zxc', User)
    }, [])
    useEffect(() => {
        setShowFreeCreditsModal(User?.user?.firstTimeLoggedIn)
    }, [User?.user?.firstTimeLoggedIn])

    useEffect(() => {
        screen?.classList?.add('overflow-hidden')
    }, [])

    const [message, setMessage] = useState(null)
    const [messageError, setMessageError] = useState(false)
    const [jobId, setJobId] = useState(null)
    const [applicantId, setApplicantId] = useState(null)
    const [sendMessageError, setSendMessageError] = useState(false)
    const sendMessage = () => {
        if (!message) {
            setMessageError(true)

            return
        }
        let card = document.getElementById(selectedCard)
        setMessageError(false)
        const payload = {
            action: 'superLike',
            applicantId: selectedApplicant.userId,
            jobId: filters.jobId,
            message,
        }
        userRequest
            .employerSuperLike(payload)
            .then((res) => {
                setShowConfirmModal(false)
                let seekers = jobSeekers
                setTimeout(() => {
                    card.classList.add('slide-out-top')

                    seekers.splice(cardIndex, 1)
                }, 500)

                setTimeout(() => {
                    card.classList.add('d-none')
                    setJobSeekers(seekers)
                    setShowSentModal(true)
                    setSendMessageError(false)
                    setMessage(null)
                    getUser()
                }, 1000)
            })
            .catch((err) => {
                console.log(err)
                setSendMessageError(true)
            })
    }

    useEffect(() => {
        if (!!AppState.showTutorial) {
            setExpanded(false)
        }
    }, [AppState.showTutorial])

    useEffect(() => {
        if (User?.login_response?.doNotShowTips === false) {
            setFirstTimeUser(true)
        }

        if (User?.user) {
            userRequest
                .talentSearchStatus()
                .then((res) => {
                    console.log('taelent search', res.data)
                    // setAllowTalentSearch(true)
                })
                .catch(() => {
                    // setAllowTalentSearch(false)
                    const dummies = Array.from({ length: 8 }).map(() => {
                        return dummy_data()
                    })
                    setJobSeekers(dummies)
                })
        }
    }, [User])

    const { jobfair_id } = useParams()

    useEffect(() => {
        if (jobfair_id && !!JobFairs.jobfairs) {
            const jf_data = _.find(JobFairs?.jobfairs.data, { _id: jobfair_id })
            console.log(jf_data)
            setJobFairData(jf_data)
        }
    }, [jobfair_id, JobFairs])

    useEffect(() => {
        if (!!jobfairData && !!Object.keys(jobfairData).length) getJobSeekers()
    }, [jobfairData])

    useEffect(() => {
        if (isFirstTimeUser) {
            setTourOpen(true)
        }
    }, [isFirstTimeUser])

    const [filters, setFilters] = useState({
        jobId: null,
        jobFunction: null,
        location: null,
        jobTitle: null,
        offset: null,
        limit: null,
        salaryRange,
        salaryCurrency,
        yearsOfExp,
    })

    const handleClearFilters = () => {
        setWorkPref([])
        setSalaryMax(null)
        setSalaryMin(null)
        setWorkSetup([])
        setYearsOfExp(null)
        setFilters({
            jobId: null,
            jobFunction: null,
            jobTitle: null,
            offset: null,
            limit: null,
            salaryRange,
            salaryCurrency,
            yearsOfExp,
            keyword: '',
            location: '',
            salaryMin: '',
            salaryMax: '',
        })

        setCurrentPage(1)

        setTimeout(() => {
            getJobSeekers()
        }, 500)
    }

    useEffect(() => {
        console.log('filter effect', filters)
    }, [filters])

    useEffect(() => {
        if (currency == 'PHP') {
            setSalaryCurrency('PHP')
        } else if (currency == 'SGD') {
            setSalaryCurrency('SGD')
        } else if (currency == 'USD') {
            setSalaryCurrency('USD')
        } else {
            setSalaryCurrency('PHP')
        }
    }, [])

    useEffect(() => {
        if (currency == 'PHP') {
            setSalaryCurrency('PHP')
        } else if (currency == 'SGD') {
            setSalaryCurrency('SGD')
        } else if (currency == 'USD') {
            setSalaryCurrency('USD')
        } else {
            setSalaryCurrency('PHP')
        }
    }, [currency])

    useEffect(() => {
        const jobId = new URLSearchParams(location.search).get('job')
        if (jobId) {
            setFilters({
                ...filters,
                jobId,
            })
        }
    }, [location])

    const navigate = useNavigate()

    useEffect(() => {
        if (jobSeekers?.length === 0) {
            setHideTutorial(true)
        } else {
            setHideTutorial(false)
        }
    }, [jobSeekers])

    const getJobSeekers = (
        filters,
        changeJobOnly = null,
        append = false,
        onFinish = () => {}
    ) => {
        let payload = filters || {}
        payload.limit = '24'
        if (!!jobfairData && Object.keys(jobfairData).length > 0) {
            try {
                jobFairRequest
                    .getJobSeekers(jobfairData?._id, payload)
                    .then((res) => {
                        setTotalData(res.data.totalCount)
                        let data = res?.data?.data || []
                        if (changeJobOnly) {
                            const lastJS = jobSeekers[jobSeekers.length - 1]
                            data = _.filter(
                                data,
                                (obj) => obj?._id !== lastJS?._id
                            )
                            if (!!lastJS) data = [...data, lastJS]
                        } else {
                            //do nothing
                        }
                        data = _.uniqBy(data, 'userId')
                        if (!changeJobOnly) {
                            setPage(1)
                            setJobSeekers(data)
                        }
                        setIsLoading(false)
                    })
                    .finally(() => {
                        setIsLoading(false)
                        onFinish && onFinish()
                    })
            } catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        } else {
            jobApplicantRequest
                .getJobSeekers(payload)
                .then((resp) => {
                    setTotalData(resp.data.totalCount)
                    let data = resp?.data?.data || []
                    if (changeJobOnly) {
                        const lastJS = jobSeekers[jobSeekers.length - 1]
                        data = _.filter(data, (obj) => obj?._id !== lastJS?._id)
                        if (!!lastJS) data = [...data, lastJS]
                    } else {
                        //do nothing
                    }
                    data = _.uniqBy(data, 'userId')

                    if (!changeJobOnly) {
                        // setPage(1)
                        if (append) {
                            let temp = [...jobSeekers, ...data]
                            if (temp?.length <= 96) {
                                data = uniqBy(temp, 'userId')
                            } else {
                                //do not append instead replace to reset

                                const card = document.getElementById(
                                    'infinite-scroll-top'
                                )
                                card.scrollIntoView(true)
                                window.scrollTo({ top: 0 })
                                showAlert({
                                    type: 'info',
                                    message:
                                        'We have loaded new job seekers from the top to optimize your talent search',
                                    duration: 7000,
                                    position: 'top',
                                })
                            }
                        } else {
                            window.scrollTo({ top: 0 })
                        }
                        setJobSeekers(data)
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                })
                .finally(() => {
                    setTimeout(() => {
                        setIsLoading(false)
                        onFinish && onFinish()
                    }, 2000)
                })
        }
    }

    const loadMore = () => {
        console.log('paylaods ========', currPage, currentPage, totalData)
        if (currPage <= Math.ceil(totalData / 24)) {
            setCurrentPage(1)
            let filter = { ...filters }
            let page = currPage ? currPage + 1 : offset
            page = parseInt(page) + 1
            setCurrPage(currPage ? currPage + 1 : 1)

            console.log(page)
            filter.offset = page.toString()
            delete filter.jobId
            getJobSeekers(filter, false)
        }
    }

    const [selectedCard, setSelectedCard] = useState(null)
    const [cardIndex, setCardIndex] = useState(null)

    const onSwipeFulfilled = (data, index, dir, new_class, type, itemIndex) => {
        //perform api call here
        const jobId = filters.jobId
        const card = document.getElementById(`card-${type}-${index}`)
        setSelectedCard(`card-${type}-${index}`)
        setCardIndex(itemIndex)
        console.log('card', card, 'index', itemIndex, new_class)

        if (!!jobsList.length === false) {
            if (dir === defaultActions.right || dir === defaultActions.top) {
                setModalHeaderTitle('')
                setModalVariant('noJobPosts')
            } else {
                card.classList.add('bring-forward')
                card.classList.add(new_class)
                let seekers = jobSeekers
                getUser()
                setTimeout(() => {
                    card.classList.add('d-none')
                    seekers.splice(itemIndex, 1)
                    setJobSeekers([...seekers])
                }, 1000)
            }
        } else {
            if (!!jobId && data !== 'advertisement') {
                jobMatchRequest
                    .employerSwipe({
                        jobId: filters.jobId,
                        applicantId: data.user._id,
                        action: dir,
                    })
                    .then((res) => {
                        console.log(res.data)
                        if (
                            res.data.message ===
                            errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
                        ) {
                            setModalVariant('outOfLikes')
                            setModalOpen(true)
                            setModalHeaderTitle('')
                            // setSwipeLimitTitle('You have run out of likes today!')
                            // setSwipeLimitText('Go back tomorrow to start liking again.')
                            // setShowSwipeLimit(true)
                        } else if (
                            res.data.message ===
                            errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
                        ) {
                            setModalVariant('outOfMonthlyLikes')
                            setModalOpen(true)
                            setModalHeaderTitle('')
                        } else if (
                            res.data.message ===
                            errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
                        ) {
                            setModalVariant('outOfSuperLikes')
                            setModalOpen(true)
                            setModalHeaderTitle('')
                        } else if (
                            res.data.message ===
                            errorSwipeActions.LIKE_NOT_ALLOWED
                        ) {
                            setModalVariant('upgradeAccount')
                            setModalOpen(true)
                            setModalHeaderTitle('')
                        } else if (
                            res.data.message ===
                            errorSwipeActions.SUPER_LIKE_NOT_ALLOWED
                        ) {
                            setShowPremiumModal(true)
                        } else if (
                            res.data.message ===
                            errorSwipeActions.SUPER_LIKE_ZERO_CREDITS
                        ) {
                            setShowOutOfSuperLike(true)
                        } else if (
                            res.data.message ===
                            errorSwipeActions.LIKE_CREDITS_EXCEEDED
                        ) {
                            setShowOutOfLike(true)
                        } else {
                            if (res.data == true) {
                                setMessageError(false)
                                setSendMessageError(false)
                                setSelectedApplicant(data)
                                setMessage(null)
                                setShowConfirmModal(true)
                                return
                            }
                            card.classList.add('bring-forward')
                            card.classList.add(new_class)
                            let seekers = jobSeekers

                            getUser()
                            setTimeout(() => {
                                card.classList.add('d-none')

                                seekers.splice(itemIndex, 1)
                                setJobSeekers([...seekers])
                            }, 1000)
                        }

                        if (res.data.matchStatus === 'match') {
                            const images = {
                                employer: res.data.profileUrl_employer,
                                applicant: res.data.profileUrl_applicant,
                            }

                            setMatchImages(images)
                            setModalVariant('matchModal')
                            setModalOpen(true)
                            setApplicantName(
                                data.firstName + ' ' + data.lastName
                            )
                            setJobTitle(filters.jobTitle)
                        }
                        // navigation.goBack()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                if (
                    dir === defaultActions.right ||
                    dir === defaultActions.top
                ) {
                    setModalVariant('noJobsSelected')
                    setModalHeaderTitle('Select Job Post')
                } else {
                    console.log('normal swiping')
                    card.classList.add('bring-forward')
                    card.classList.add(new_class)
                    let seekers = jobSeekers

                    setTimeout(() => {
                        seekers.splice(itemIndex, 1)
                        card.classList.add('d-none')
                        setJobSeekers([...seekers])
                    }, 1000)
                }
            }
        }
    }

    useEffect(() => {
        if (modalVariant !== null) {
            setModalOpen(true)
        }
    }, [modalVariant])

    const disableBody = () => disableScroll.on()

    const enableBody = () => {
        setShowTutorial(false)
        setTourOpen(false)
        disableScroll.off()
    }

    const addCommas = (num) =>
        num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, '')

    const removeComma = (num) => {
        let copy = num?.toString()
        let num1
        if (copy?.indexOf(',') != -1) {
            num1 = parseFloat(num?.replace(/,/g, ''))
            return num1
        } else {
            return parseFloat(copy)
        }
    }

    const handleSalaryMin = (event) => {
        const num = addCommas(removeNonNumeric(event.target.value))
        setFilters({ ...filters, salaryMin: num })
        setSalaryMin(num)
    }

    const handleSalaryMax = (event) => {
        const num = addCommas(removeNonNumeric(event.target.value))
        setFilters({ ...filters, salaryMax: num })
        setSalaryMax(num)
    }

    const handleFilter = (event, changeJobOnly) => {
        setIsLoading(true)
        let sMin = removeComma(salaryMin)
        let sMax = removeComma(salaryMax)

        setPage(1)
        setCurrentPage(1)

        let filter = { ...filters }
        filter.jobTitle = jobTitleQuery ? jobTitleQuery : null
        filter.employmentType =
            filter?.workPreference?.length > 0
                ? filter?.workPreference.join(',')
                : null
        filter.workSetup =
            filters?.workSetup?.length > 0 ? filters?.workSetup.join(',') : null
        filter.yearsOfExp = yearsOfExp ? yearsOfExp : null
        if (salaryMin && salaryMax && salaryCurrency) {
            filter.salaryRange = sMin?.toString() + '-' + sMax?.toString()
            filter.salaryCurrency = salaryCurrency
        }

        if (filter?.workPreference) delete filter.workPreference
        if (filter?.salaryMin) delete filter.salaryMin
        if (filter?.salaryMax) delete filter.salaryMax
        let payload = {
            ...filters,
        }

        if (!filter.jobId) {
            delete payload.jobId
            delete filter.jobId
        }
        getJobSeekers(filter, changeJobOnly)
    }

    useEffect(() => {
        try {
            jobsRequest.getJobsForLists().then((res) => {
                setJobsList(res?.data?.jobs || [])
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        if (filters.jobId) handleFilter(null, true)
    }, [filters.jobId])

    useEffect(() => {
        let payload = {
            limit: '24',
            offset,
        }
        if (!jobfair_id) getJobSeekers(payload)
    }, [])

    const [workPref, setWorkPref] = useState([])
    const [workSetup, setWorkSetup] = useState([])

    useEffect(() => {
        setFilters({ ...filters, yearsOfExp })
    }, [yearsOfExp])

    const handleSetWorkPref = (e) => {
        var workPrefCopy = [...workPref]
        if (e.target?.checked) {
            workPrefCopy = [...workPref, e.target.value]
        } else {
            workPrefCopy.splice(workPref.indexOf(e.target.value), 1)
        }
        setFilters({ ...filters, workPreference: workPrefCopy })
        setWorkPref(workPrefCopy)
    }

    const handleUpdateFirstTimeLogin = () => {
        userRequest
            .updateEmployer({
                firstTimeLoggedIn: false,
            })
            .then(() => {
                setShowFreeCreditsModal(false)
                getUser()
            })
    }

    const handleWorkSetup = (e) => {
        var workSetupCopy = [...workSetup]
        if (e.target?.checked) {
            workSetupCopy = [...workSetup, e.target.value]
        } else {
            workSetupCopy.splice(workSetup.indexOf(e.target.value), 1)
        }
        setWorkSetup(workSetupCopy)
        setFilters({ ...filters, workSetup: workSetupCopy })
    }

    const handleYearsOfExp = (e) => {
        let copy = filters
    }

    return (
        <>
            <MessageSentModal
                showSentModal={showSentModal}
                handleCloseSentModal={handleCloseSentModal}
            />
            <SuperLikeModal
                showConfirmModal={showConfirmModal}
                handleCloseConfirmModal={handleCloseConfirmModal}
                selectedApplicant={selectedApplicant}
                sendMessage={sendMessage}
                setMessage={setMessage}
                sendMessageError={sendMessageError}
                messageError={messageError}
                message={message}
                getUser={getUser}
            />
            <div className="row d-flex justify-content-center mx-0 position-relative">
                {/* SINGLE EVENTS */}
                {!jobfairData && !isMobile && (
                    <JobFairBanner
                        jobfairs={JobFairs?.jobfairs}
                        show={
                            !!JobFairs?.jobfairs?.totalCount > 0 &&
                            !!JobFairs.showBanner
                        }
                        setShowBanner={setShowBanner}
                    />
                )}
                {/* MULTIPLE EVENTS */}

                <div className="row d-flex justify-content-center mx-0 px-0 d-relative">
                    <TourProvider
                        steps={_.map(tourSteps, (obj, index) => {
                            return {
                                ...obj,
                                position: isMobile && index !== 3 && 'center',
                            }
                        })}
                        afterOpen={disableBody}
                        beforeClose={enableBody}
                        showBadge={false}
                        showPrevNextButtons={false}
                        showNavigation={false}
                        position="left"
                        showCloseButton={true}
                        onClickClose={enableBody}
                        disableInteraction={true}
                        enableBody={enableBody}
                        onClickMask={() => {}}
                        className=""
                        styles={
                            !isMobile
                                ? {
                                      popover: (base, state) => {
                                          return {
                                              ...base,
                                              borderRadius: 10,
                                              ...doArrow(
                                                  state.position,
                                                  state.verticalAlign,
                                                  state.horizontalAlign
                                              ),
                                          }
                                      },
                                  }
                                : {
                                      popover: (base, state) => {
                                          return { ...base, borderRadius: 10 }
                                      },
                                  }
                        }
                    >
                        {jobfairData && (
                            <div
                                className="col-12 row shadow-sm d-flex justify-content-center px-0 py-3 mx-0"
                                style={{
                                    background: jobfairData
                                        ? jobfairData?.brandColor
                                        : null,
                                }}
                            >
                                <div className="col-12 col-md-9 row m-0">
                                    <div className="row p-0 m-0 p-0">
                                        {jobfairData && (
                                            <div
                                                className="col-12 col-md-3 text-light d-flex"
                                                role="button"
                                                onClick={() => {
                                                    setShowBackToDashboad(
                                                        !showbackToDashboard
                                                    )
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    className="bi bi-arrow-left my-auto me-2 "
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                                    />
                                                </svg>
                                                <p className="text-light my-auto">
                                                    {' '}
                                                    Back to Dashboard
                                                </p>
                                            </div>
                                        )}

                                        {/* <div className="col-md-4 col-sm-12 mb-2 mb-md-0 ps-0">
                                    <div className="" id="-tour-step-2">
                                        <SelectJobPost
                                            onChange={(value, item) => {
                                                console.log(value, item)
                                                setFilters({
                                                    ...filters,
                                                    jobId: item?.value,
                                                    jobTitle: item?.name,
                                                })
                                            }}
                                            value={filters?.jobId}
                                            placeholder="Select job post to view applicants"
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${
                                        jobfairData
                                            ? 'col-md-5'
                                            : 'col-md-8 col-sm-12 '
                                    } d-flex justify-content-end p-0`}
                                >
                                    <CreateJobButton />
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        )}

                        <ExploreV2
                            currency={currency}
                            setSalaryCurrency={setSalaryCurrency}
                            setSalaryMin={setSalaryMin}
                            setSalaryMax={setSalaryMax}
                            setYearsOfExp={setYearsOfExp}
                            handleWorkSetup={handleWorkSetup}
                            handleSetWorkPref={handleSetWorkPref}
                            workPref={workPref}
                            setJobTitleQuery={setJobTitleQuery}
                            jobSeekers={jobSeekers}
                            setJobSeekers={setJobSeekers}
                            setExpanded={setExpanded}
                            expanded={expanded}
                            onSwipeFulfilled={onSwipeFulfilled}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setFilters={setFilters}
                            filters={filters}
                            handleFilter={handleFilter}
                            appState={AppState}
                            tourOpen={tourOpen}
                            setShowTutorial={setShowTutorial}
                            getJobSeekers={getJobSeekers}
                            isFirstTimeUser={isFirstTimeUser}
                            User={User}
                            setDoNotShowTips={setDoNotShowTips}
                            postsPerPage={postsPerPage}
                            totalData={jobSeekers.length}
                            paginate={paginate}
                            setPage={setPage}
                            currentList={jobSeekers}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            loadMore={loadMore}
                            handleSalaryMin={handleSalaryMin}
                            handleSalaryMax={handleSalaryMax}
                            salaryMin={salaryMin}
                            salaryMax={salaryMax}
                            totalCount={totalData}
                            scrollerRef={scrollerRef}
                            handleClearFilters={handleClearFilters}
                        />

                        {!!noJobs && !isLoading && (
                            <div className="first-job-container">
                                <div className="lets-make-your-first-job d-flex">
                                    <div className="m-auto d-flex flex-column">
                                        <h2 className="poppins text-center">
                                            Let’s make your first <br />
                                            job post
                                        </h2>
                                        <button
                                            onClick={() =>
                                                navigate('/create-job')
                                            }
                                            className="btn btn-primary mx-auto mb-2"
                                        >
                                            + Post a job
                                        </button>
                                        <TourButton
                                            setTourOpen={setTourOpen}
                                            setIsNoJobs={setIsNoJobs}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </TourProvider>
                </div>
                <Dialog
                    open={showPremiumModal}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={() => {
                        setShowPremiumModal(false)
                    }}
                    style={{ zIndex: '99999999' }}
                >
                    <Box
                        style={{
                            background: `url('${premiumBg}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            // display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                textAlign: 'right',
                                padding: '0.5rem',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseWhite}
                                width={36}
                                onClick={() => {
                                    setShowPremiumModal(false)
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                padding: '0 3rem 3rem 3rem',
                            }}
                        >
                            <JobluTypography.H2
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                Oops! Your current plan does not include
                                Superlike credits.
                            </JobluTypography.H2>
                            {/* <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 20 }}
                        >
                            Our premium plan offers unlimited job postings!
                        </JobluTypography.H4> */}
                            <JobluTypography.H6
                                semiBold
                                style={{ marginBottom: 20, color: '#D7A048' }}
                            >
                                Go Premium to instantly match and message
                                candidates.
                            </JobluTypography.H6>
                            <Button
                                variant="contained"
                                style={{
                                    background: '#D7A048',
                                    color: '#fff',
                                    padding: '1rem 3rem',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    navigate('/pricing')
                                }}
                            >
                                Get Premium
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    open={showOutOfSuperLike}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={() => {
                        setShowOutOfSuperLike(false)
                    }}
                    style={{ zIndex: '99999999' }}
                >
                        <Box
                            style={{
                                background: `url('${premiumBg}')`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                // display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                style={{
                                    textAlign: 'right',
                                    padding: '0.5rem',
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    src={CloseWhite}
                                    width={36}
                                    onClick={() => {
                                        setShowOutOfSuperLike(false)
                                    }}
                                />
                            </Box>
                            <Box
                                style={{
                                    color: '#fff',
                                    textAlign: 'center',
                                    padding: '0 3rem 3rem 3rem',
                                }}
                            >
                                <JobluTypography.H2
                                    semiBold
                                    style={{ marginBottom: '1rem' }}
                                >
                                 {
                                     User?.user?.talentFinder === true
                                     ? "Uh-oh! You've run out of Superlike credits!"
                                     : "Sorry, but the free plan does not include Superlikes."
                                 }
                                </JobluTypography.H2>
                                <JobluTypography.H6
                                    semiBold
                                    style={{
                                        marginBottom: 20,
                                        color: '#EBCFA3',
                                    }}
                                >
                                     {
                                     User?.user?.talentFinder === true
                                     ? "But don't worry, you can always top up to get more credits so you can instantly match and directly chat your desired candidates."
                                     : "Don't let the lack of superlikes hold you back! Upgrade to Premium plan now to superlike candidates and instantly match and message them so you can hire the best and the brightest before anyone else."
                                    }
                                  
                                </JobluTypography.H6>
                                <Button
                                    variant="contained"
                                    style={{
                                        background: '#D7A048',
                                        color: '#fff',
                                        padding: '1rem 3rem',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        navigate('/pricing', {
                                            state: { talentFinder: User?.user?.talentFinder === true },
                                        })
                                    }}
                                >
                                    {
                                     User?.user?.talentFinder === true
                                     ? "Get more credits"
                                     : "Go Premium"
                                    }
                                </Button>
                            </Box>
                        </Box>
                </Dialog>
                <Dialog
                    open={showFreeCreditsModal}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={() => {
                        handleUpdateFirstTimeLogin()
                    }}
                    style={{ zIndex: '99999999' }}
                >
                    <Box
                        style={{
                            background: `url('${premiumBg}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            // display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                textAlign: 'right',
                                padding: '0.5rem',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseWhite}
                                width={36}
                                onClick={() => {
                                    handleUpdateFirstTimeLogin()
                                }}
                                alt=""
                            />
                        </Box>
                        <Box
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                padding: '0 3rem 3rem 3rem',
                            }}
                        >
                            <JobluTypography.H1
                                semiBold
                                style={{
                                    marginBottom: '1rem',
                                    fontWeight: 800,
                                }}
                            >
                                Enjoy 25 Complimentary Credits! 🌟
                            </JobluTypography.H1>
                            <JobluTypography.H6
                                style={{
                                    marginBottom: 20,
                                    color: '#fff',
                                    fontWeight: 400,
                                }}
                            >
                                Unlock the power to "Like" up to 25 amazing
                                candidates and boost your talent search!
                            </JobluTypography.H6>
                            <JobluTypography.H6
                                semiBold
                                style={{
                                    marginBottom: 20,
                                    color: '#EBCFA3',
                                }}
                            >
                                Dive in and find the perfect candidate!
                            </JobluTypography.H6>
                            <Button
                                variant="contained"
                                style={{
                                    background: '#009CDE',
                                    color: '#fff',
                                    padding: '1rem 3rem',
                                    textTransform: 'none',
                                    minWidth: 330,
                                    fontSize: '24px',
                                }}
                                onClick={() => {
                                    handleUpdateFirstTimeLogin()
                                }}
                            >
                                Let’s Go!
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    open={showOutOfLike}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={() => {
                        setShowOutOfLike(false)
                    }}
                    style={{ zIndex: '99999999' }}
                >
                    <Box
                        style={{
                            background: `url('${premiumBg}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            // display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                textAlign: 'right',
                                padding: '0.5rem',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseWhite}
                                width={36}
                                onClick={() => {
                                    setShowOutOfLike(false)
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                padding: '0 3rem 3rem 3rem',
                            }}
                        >
                            <JobluTypography.H2
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                Out of likes? No problem!
                            </JobluTypography.H2>
                            <JobluTypography.H6
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                You've used up all your Like credits, but the
                                perfect candidate is still out there! With Joblu
                                Premium, not only you will enjoy unlimited job
                                postings but you can also like more talent and
                                get up to 300 matches per job post.
                            </JobluTypography.H6>
                            {/* <JobluTypography.H6
                                semiBold
                                style={{ marginBottom: 20, color: '#D7A048' }}
                            >
                                Discover stress-free hiring for less.
                            </JobluTypography.H6> */}
                            <Button
                                variant="contained"
                                style={{
                                    background: '#D7A048',
                                    color: '#fff',
                                    padding: '1rem 3rem',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    navigate('/pricing')
                                }}
                            >
                                Upgrade and Unlock UNLIMITED Likes
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                <ExploreModal
                    showModal={modalOpen && modalVariant !== 'upgradeAccount'}
                    variant={modalVariant}
                    setModalVariant={setModalVariant}
                    setShowModal={setModalOpen}
                    headerTitle={modalHeaderTitle}
                    filters={filters}
                    setFilters={setFilters}
                    handleFilter={handleFilter}
                    setShowPremiumModal={setShowPremiumModal}
                />

                {modalVariant === 'upgradeAccount' && (
                    <SubscriptionPopup
                        open={modalOpen}
                        setOpen={() => setModalVariant(false)}
                    />
                )}
                <WelcomeTourModal
                    show={AppState.showTutorial}
                    setTourOpen={setTourOpen}
                    tourOpen={tourOpen}
                />
                <LeaveModal
                    show={showbackToDashboard}
                    setSignedInJobFair={setSignedInJobFair}
                />
                {isMobile &&
                    !!JobFairs.jobfairs?.data &&
                    !!JobFairs.showBanner && (
                        <div className="floating-action-button">
                            <img
                                alt=""
                                style={{ width: '100%' }}
                                src={
                                    JobFairs.jobfairs.totalCount > 1
                                        ? multiplefair
                                        : JobFairs.jobfairs?.data[0]?.logo
                                }
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    console.log(JobFairs)
                                    JobFairs.jobfairs.totalCount !== 0 &&
                                    JobFairs.jobfairs?.totalCount === 1
                                        ? navigate(
                                              `/job-fair/register/${JobFairs.jobfairs?.data[0]?._id}`
                                          )
                                        : navigate('/job-fair/events')
                                }}
                            />
                            <div
                                className="position-absolute top-0"
                                style={{ right: 1 }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="white"
                                    className="my-auto bi bi-x ms-5 bg-dark"
                                    viewBox="0 0 16 16"
                                    role="button"
                                    onClick={() => {
                                        setShowBanner(false)
                                    }}
                                    style={{ borderRadius: '50%' }}
                                >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                    )}
            </div>

            <Dialog
                open={url_params.get('payment') === 'confirmed'}
                maxWidth="xl"
                style={{ zIndex: '99999999' }}
            >
                <Box padding={5}>
                    <Box
                        justifyContent="center"
                        display="flex"
                        paddingBottom={5}
                    >
                        <img
                            src={require('../../assets/img/createJob/job-edit-message.png')}
                            alt="logo"
                            style={{ maxWidth: 204 }}
                        />
                    </Box>
                    <JobluTypography.H2
                        align="center"
                        style={{ color: '#D7A048' }}
                    >
                        {url_params.get('trial') ? 'Woohoo!' : 'You did it!'}
                    </JobluTypography.H2>

                    <JobluTypography.H4
                        align="center"
                        style={{ color: '#009CDE', marginBottom: 30 }}
                        gutterBottom={4}
                    >
                        {url_params.get('trial') ? (
                            <>
                                You've just gained access to a 7-day free trial
                                of
                                <br />
                                our premium features.
                            </>
                        ) : (
                            <>
                                You've just unlocked premium features with{' '}
                                <br /> your recent purchase.
                            </>
                        )}
                    </JobluTypography.H4>
                    <JobluTypography.H6 align="center" primary semiBold>
                        {url_params.get('trial') ? (
                            <>
                                Make the most of this limited time offer and
                                watch your dream team unfold!
                            </>
                        ) : (
                            <>
                                We can't wait to see your team flourish with
                                your new recruits. Keep an eye on <br />
                                your inbox for more details.
                            </>
                        )}
                    </JobluTypography.H6>
                    <Box display="flex" justifyContent="center" padding={3}>
                        <img
                            src={require('../../assets/premium/premium-badge.png')}
                            alt="badge"
                            width={120}
                        />
                    </Box>
                    <Box display="flex" justifyContent="center" padding={3}>
                        <Button
                            variant="contained"
                            style={{
                                textTransform: 'none',
                                fontWeight: 600,
                                fontSize: 20,
                                borderRadius: 4,
                                textTransform: 'none',
                            }}
                            color="primary"
                            onClick={() => {
                                navigate('/dashboard')
                            }}
                        >
                            Start Hiring
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={url_params.get('payment') === 'TF'}
                maxWidth="xl"
                style={{ zIndex: '99999999' }}
            >
                <Box padding={5} paddingY={7}>
                    <Box
                        justifyContent="center"
                        display="flex"
                        paddingBottom={5}
                    >
                        <img
                            src={require('../../assets/img/createJob/job-edit-message.png')}
                            alt="logo"
                            style={{ maxWidth: 204 }}
                        />
                    </Box>
                    <JobluTypography.H2
                        align="center"
                        style={{ color: '#D7A048', marginBottom: 30 }}
                    >
                        You have received 100 credits!
                    </JobluTypography.H2>

                    {/* <JobluTypography.H4
                        align="center"
                        style={{ color: '#009CDE', marginBottom: 30 }}
                        gutterBottom={4}
                    >
                    
                    </JobluTypography.H4> */}
                    <JobluTypography.H6 align="center" primary semiBold>
                        You've just unlocked a whole new world of hiring
                        possibilities. <br/>With Joblu and its smart features, you're
                        one step closer to uncovering <br/>a treasure trove of
                        extraordinary candidates.
                    </JobluTypography.H6>
                    <Box display="flex" justifyContent="center" padding={3}>
                        <Button
                            variant="contained"
                            style={{
                                textTransform: 'none',
                                fontWeight: 600,
                                fontSize: 20,
                                borderRadius: 4,
                            }}
                            color="primary"
                            onClick={() => {
                                navigate('/dashboard')
                            }}
                        >
                            Start Superliking Now!
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
        AppState: state.Generals,
        JobFairs: state.JobFairs,
    }
}

const mapDispatchToProps = {
    setShowTutorial: appActions.setShowTutorial,
    setDoNotShowTips: userActions.setDoNotShowTips,
    setHideTutorial: appActions.setHideTutorial,
    setSignedInJobFair: jobfairActions.setSignIn,
    setShowBanner: jobfairActions.setShowBanner,
    getUser: userActions.getUser,
    showAlert: appActions.showAlert,
}
export default connect(mapStateToProps, mapDispatchToProps)(Explore)
