import jbHorizontal from '../../assets/joblu-logos/jb-horizontal.png'
import jobluwhitefooter from '../../assets/joblu-logos/joblu-white-footer.png'
import d_android from '../../assets/btn-download-android.png'
import d_ios from '../../assets/btn-download-ios.png'
export const Footer = () => {
    return (
        <>
            <div
                className="col-12 row mx-0 pb-3"
                style={{ background: '#27323C' }}
            >
                <div className="col-12 col-sm-4 d-flex justify-content-center">
                    <div className="p-5">
                        <img src={jobluwhitefooter} alt="" className="mb-3" />
                        <p
                            style={{
                                color: '#fff',
                                fontFamily: 'poppins',
                                fontWeight: 500,
                                fontSize: 16,
                                lineHeight: '24px',
                            }}
                            className="text-center text-md-start mt-3"
                        >
                       Jobs and learning for you.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-sm-8 ">
                    <div className="pt-sm-5 row">
                        <div className="col-12 col-md-2 mb-3 mb-md-0">
                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/jobseekers/'
                                }}
                                role="button"
                            >
                                For Job Seekers
                            </p>
                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/companies/'
                                }}
                                role="button"
                            >
                                For Companies
                            </p>
                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/partnerships/'
                                }}
                                role="button"
                            >
                                Partnerships
                            </p>
                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/request-a-demo/'
                                }}
                                role="button"
                            >
                                Request a Demo
                            </p>
                        </div>
                        <div className="col-12 col-md-2 mb-3 mb-md-0">
                            {/* <p className="text-light p-new">About Us</p>
                            <p className="text-light p-new">Resources</p> */}

                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/about-us/'
                                }}
                                role="button"
                            >
                                About Us
                            </p>
                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/resources/'
                                }}
                                role="button"
                            >
                                Resources
                            </p>
                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/contact-us/'
                                }}
                                role="button"
                            >
                                Contact Us
                            </p>

                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/faq/'
                                }}
                                role="button"
                            >
                                FAQs
                            </p>
                        </div>
                        <div className="col-12 col-md-2 mb-3 mb-md-0">
                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/terms-of-use/'
                                }}
                                role="button"
                            >
                                Terms of Use
                            </p>

                            <p
                                className="text-light text-center text-md-start p-new"
                                onClick={() => {
                                    window.location.href =
                                        'https://joblu.io/privacy-policy/'
                                }}
                                role="button"
                            >
                                Privacy Policy
                            </p>
                        </div>
                        <div className="col-12 col-sm-6 mt-5 mt-sm-0">
                            <p className="text-light text-center text-md-start p-new mb-2">
                                Find jobs or talents on the go!
                            </p>
                            <div className="d-flex justify-content-center justify-content-md-start">
                                <div className="m-1 ms-0">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.joblu&pli=1"
                                        target="_blank"
                                    >
                                        <img src={d_android} alt="" />
                                    </a>
                                </div>
                                <div className="m-1">
                                    <a
                                        href="https://apps.apple.com/us/app/joblu/id1598914661"
                                        target="_blank"
                                    >
                                        <img src={d_ios} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{ color: '#fff' }} />
                    <div className="row">
                        <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-md-start">
                            <p
                                className="p-new text-center text-md-start me-3"
                                style={{ color: '#fff', fontWeight: 700 }}
                            >
                                Connect with us
                            </p>
                            <div className="d-flex">
                                <a
                                    href="https://www.tiktok.com/@jobluapp"
                                    target="_blank"
                                >
                                    <strong>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="currentColor"
                                            class="bi bi-tiktok text-white me-2"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                                        </svg>
                                    </strong>
                                </a>
                                <a
                                    href="https://discord.gg/e3avvrVnSA"
                                    target="_blank"
                                >
                                    <strong>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="currentColor"
                                            class="bi bi-discord text-white me-2"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                                        </svg>
                                    </strong>
                                </a>

                                <a
                                    href="https://www.instagram.com/jobluapp/"
                                    target="_blank"
                                >
                                    <strong>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="currentColor"
                                            class="bi bi-instagram text-white me-2"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                        </svg>
                                    </strong>
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/jobluapp/"
                                    target="_blank"
                                >
                                    <strong>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="currentColor"
                                            class="bi bi-linkedin text-white me-2"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                        </svg>
                                    </strong>
                                </a>
                                <a
                                    href="https://web.facebook.com/Joblu.io"
                                    target="_blank"
                                >
                                    <strong>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="currentColor"
                                            class="bi bi-facebook text-white me-2"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                        </svg>
                                    </strong>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mt-3 mt-md-0">
                            <div className="d-flex justify-content-center justify-content-md-center">
                                <div>
                                    <p
                                        className="p-new text-center text-md-start"
                                        style={{ color: '#fff' }}
                                    >
                                        © 2023 Joblu. All rights reserved.{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
