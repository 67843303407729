const api = {
    // AUTH

    USER_SIGN_IN: '/auth/login',
    USER_SIGN_OUT: '/auth/logout',
    USER_SIGN_UP: '/auth/register',
    USER_PHONE_AUTH: '/auth/phone',
    USER_CONFIRM_PHONE_CODE: '/auth/confirm-phone-code',
    USER_VERIFY_GOOGLE_LOGIN: '/auth/verify/google/login',
    USER_VERIFY_GOOGLE_REGISTER_APPLICANT:
        '/auth/verify/google/register-applicant',
    USER_VERIFY_GOOGLE_REGISTER_EMPLOYER:
        '/auth/verify/google/register-employer',
    USER_VERIFY_APPLE_LOGIN: '/auth/verify/apple/login',
    USER_VERIFY_APPLE_REGISTER_APPLICANT:
        '/auth/verify/apple/register-applicant',
    USER_VERIFY_APPLE_REGISTER_EMPLOYER: '/auth/verify/apple/register-employer',
    USER_VERIFY_EMAIL: '/auth/verify-email',
    USER_RETRIEVE_APPLE_EMAIL: '/auth/retrieve/apple/email',
    REQUEST_INVITE_CODE: '/invite-code/request',
    CONTACT_US: '/invite-code/contact-us',
    VALIDATE_EMAIL: '/auth/email/validate',
    REQUEST_TALENT_SEARCH: '/auth/talent-search/interest',

    // USER
    TALENT_SEARCH: '/users/access/talent-search',
    GET_USER: '/users/profile',
    UPDATE_USER: '/users',
    CREATE_APPLICANT: '/users/applicant',
    CREATE_EMPLOYER: '/users/employer',
    UPDATE_APPLICANT: '/users/applicant',
    CHANGE_PASSWORD: '/users/change-password',
    RESET_PASSWORD: '/auth/forgot-password',
    CHANGE_RESET_PASSWORD: '/auth/reset-password',
    EMPLOYER_DASHBOARD: '/users/employer/dashboard',
    RESEND_VERIFICATION_EMAIL: '/auth/resend-email-verification',
    DOWNLOAD_PROFILE: '/users/profile/download',
    GET_SHOWCASE_LINKS: '/users/companyshowcase/generate/links',

    SEND_TOKEN: '/users/device-token',
    SEND_NOTIFICATION: '/users/push-notification',

    GET_NOTIFICATIONS: '/users/notifications',
    UPDATE_NOTIFICATION: '/users/notifications',
    READ_ALL_NOTIFICATIONS: '/users/notifications/readall',

    UPDATE_EMPLOYER: '/users/employer',

    //FOR THE CARDS
    GET_RECOMMENDED_JOBS: '/users/jobs/recommended',
    GET_RECOMMENDER_JOBS_JOBFAIR: '/users/jobfair/recommended',
    GET_JOB_SEEKERS: '/users/candidates', //getting applicants for the swiping cards
    GET_JOB_SEEKERS_JOBFAIR: '/users/jobfair/candidates',

    GET_JOBS: '/jobs/status/published',
    GET_SPECIFIC_JOB: '/jobs',
    GET_JOB_IDS: '/jobs/status/ids',
    GET_JOBS_FOR_LISTS: '/jobs/status/jobs-for-list',
    GET_PUBLISHED_JOBS: '/jobs/status/published',
    GET_ARCHIVED_JOBS: '/jobs/status/archived',
    GET_DRAFT_JOBS: '/jobs/status/draft',
    GET_PAUSED_JOBS: '/jobs/status/paused',
    GET_CLOSED_JOBS: '/jobs/status/closed',
    CREATE_JOB: '/jobs',
    GET_APPLIED_JOBS: '/users/jobs/applied',
    GET_APPLIED_ACTIVE_JOBS: '/users/jobs/active',
    GET_APPLIED_SHORTLISTED_JOBS: '/users/jobs/shortlisted',
    GET_APPLIED_REJECTED_JOBS: '/users/jobs/rejected',
    GET_SAVED_JOBS: '/users/jobs/saved',
    UNMATCH_APPLICANT: '/job-match/unmatch',
    EXPORT_JOBS: '/job-match/exports/jobs',
    EXPORT_APPLICANTS: '/job-match/exports/applicants',

    EMPLOYER_SUPER_LIKE: 'job-match/employer/superlike',

    RENEW_ACCESS_TOKEN: '/auth/refresh',
    SEARCH_JOB: '/jobs/search',

    //RECOMMENDATION
    GET_RECOMMENDATION_REF: '/jobs/recommendation/reference',
    GET_RECOMMENDATION_LIST: '/jobs/recommendation/list',

    //RATING AND REVIEW
    RATE_AND_REVIEW: '/ratings-reviews',

    //PAYMENT INTEGRATIONS
    PROCESS_PAYMENT: '/payments/process',
    CHECK_PAYMENT_STATUS: '/payments/applicants',
    GET_PAYMENTS: '/users/payments',
    CANCEL_PAYMENT: '/payments/applicants/cancel',

    //CONFIG/DYNAMIC DATA
    GET_INDUSTRIES: '/job-category',
    GET_JOB_FUNCTIONS: '/job-category/function/all',
    SEARCH_SKILLS: '/job-category/skills',

    //JOB MATCH ACTIONS
    EMPLOYER_SWIPE: '/job-match/employer',
    GET_JOB_MATCHES: '/job-match',
    GET_LIKED_CANDIDATES: '/job-match/liked-candidates',
    GET_LIKED_MY_JOBS: '/job-match/liked-my-jobs',
    UPDATE_JOB_MATCH: '/job-match/status',

    //GET APPLICANT ACTIONS

    //JOB FAIR ACTIONS
    GET_ONGOING_JOBFAIRS: '/job-fair/ongoing',
    GET_UPCOMING_JOBFAIRS: '/job-fair/upcoming',
    GET_ACTIVE_JOBFAIRS: '/job-fair/active',
    REGISTER_AND_ENTER_JOBFAIR: '/job-fair/register',
    SEND_JOBFAIR_INTEREST: '/job-fair/interest',

    //GENERAL SETTINGS
    GET_CURRENCY: '/general-settings/currencies',

    //PAYMENTS
    GET_SUBSCRIPTIONS_LIST_IN_PHP: '/payments/stripe/subscriptions/php',
    GET_SUBSCRIPTIONS_LIST_IN_USD: '/payments/stripe/subscriptions/usd',
    STRIPE_ONE_TIME_PAYMENTS: '/payments/stripe/one-payment',
    GET_SUBSCRIPTIONS_LIST: '/payments/stripe/subscriptions',
    SUBSCRIBE_TRIAL: '/payments/stripe/subscribe-trial',
    GET_PAYMENT_INTENT: '/payments/stripe/subscribe',
    CANCEL_SUBSCRIPTION: '/payments/stripe/cancel',
    GET_ACTIVE_SUBSCRIPTION: '/payments/subscriptions/active',

    GET_SHOWCASE_EMPLOYER_DATA: 'users/employerdata',
    SUBMIT_EMPLOYER_REVIEW: 'users/companyshowcase/employee-review',
    SUBMIT_VIDEO_FROM_HEART: 'users/companyshowcase/vidfromheart',

    //CONNECTED APPS
    CALENDAR_AUTH_URLS: '/calendar/event/web-auth-urls',
    CALENDAR_CONNECTIONS: '/calendar/connections',
    CALENDAR_CONNECT_INTEGRATION: '/calendar/connect/web',
    CALENDAR_REMOVE_INTERGATION: '/calendar/connect',
    CREATE_EVENT: 'calendar/event/create',
    CANCEL_EVENT: '/calendar/cancel',
    CALENDAR_PROVIDER_AVAILABILITY:
        '/general-settings/integrations/availability',
}

export default api
