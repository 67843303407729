import { Modal } from 'react-bootstrap'
import DefaultImg from '../../../assets/img/defaultImg.png'
export const ConfirmModal = (props) => {
    const {
        showConfirmModal,
        handleCloseConfirmModal,
        modalHeader,
        modalType,
        selectedApplicant,
        archiveApplicant,
        cancelInterview,
        hireApplicant,
        unmatchApplicant,
        selectedJobData,
        restoreToShortlist,
        moveToInterview,
    } = props

    return (
        <>
            <Modal
                show={showConfirmModal}
                centered
                onHide={handleCloseConfirmModal}
            >
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {modalHeader}
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-3" style={{ fontSize: 20 }}>
                            You are about to{' '}
                            <strong>
                                {modalType == 'archive' && (
                                    <span className="text-danger">
                                        archive,
                                    </span>
                                )}
                                {modalType == 'move_to_interview' && (
                                    <span className="text-danger">move,</span>
                                )}
                                {modalType == 'hire' && (
                                    <span className="text-primary">hire,</span>
                                )}
                                {modalType == 'unmatch' && (
                                    <span className="text-danger">
                                        unmatch,
                                    </span>
                                )}
                                {modalType == 'restore' && (
                                    <span className="text-primary">
                                        restore,
                                    </span>
                                )}
                                {modalType == 'cancel_interview' && (
                                    <>
                                        <span className="text-danger">
                                            cancel{' '}
                                        </span>
                                        <span>interview for,</span>
                                    </>
                                )}
                            </strong>
                        </p>
                        <div className="mb-5">
                            <>
                                <img
                                    src={
                                        selectedApplicant?.profileUrl ||
                                        DefaultImg
                                    }
                                    alt=""
                                    className="rounded-circle mb-3"
                                    style={{
                                        width: 90,
                                        height: 90,
                                        objectFit: 'cover',
                                    }}
                                />
                            </>
                            <div className="mb-4">
                                <p
                                    className=""
                                    style={{ fontSize: 30, fontWeight: 600 }}
                                >
                                    {selectedApplicant?.firstName}{' '}
                                    {selectedApplicant?.lastName}
                                </p>
                                {modalType == 'hire' && (
                                    <>
                                        <p style={{ fontSize: 24 }}>as</p>
                                        <p
                                            style={{
                                                fontSize: 24,
                                                color: '#009CDE',
                                            }}
                                        >
                                            {selectedJobData?.jobTitle}
                                        </p>
                                    </>
                                )}
                                {modalType == 'restore' && (
                                    <>
                                        <p
                                            style={{
                                                fontSize: 24,
                                                color: '#009CDE',
                                            }}
                                        >
                                            to shortlisted
                                        </p>
                                    </>
                                )}

                                {modalType == 'move_to_interview' && (
                                    <>
                                        <p
                                            style={{
                                                fontSize: 24,
                                                color: '#009CDE',
                                            }}
                                        >
                                            to interview
                                        </p>
                                    </>
                                )}
                            </div>
                            <p className="">would you like to proceed?</p>
                        </div>

                        <div className="d-flex">
                            <button
                                className={`btn text-center ${
                                    modalType == 'archive' ||
                                    modalType == 'unmatch'
                                        ? 'btn-outline-danger'
                                        : 'btn-outline-primary'
                                } w-50 me-2`}
                                onClick={() => {
                                    handleCloseConfirmModal()
                                }}
                            >
                                No
                            </button>
                            {modalType == 'archive' && (
                                <button
                                    className="btn btn-danger w-50"
                                    onClick={archiveApplicant}
                                >
                                    Yes
                                </button>
                            )}
                            {modalType == 'hire' && (
                                <button
                                    className="btn btn-primary w-50"
                                    onClick={hireApplicant}
                                >
                                    Yes
                                </button>
                            )}
                            {modalType == 'unmatch' && (
                                <button
                                    className="btn btn-danger w-50"
                                    onClick={unmatchApplicant}
                                >
                                    Yes
                                </button>
                            )}
                            {modalType == 'cancel_interview' && (
                                <button
                                    className="btn btn-danger w-50"
                                    onClick={cancelInterview}
                                >
                                    Yes
                                </button>
                            )}
                            {modalType == 'restore' && (
                                <button
                                    className="btn btn-primary w-50"
                                    onClick={restoreToShortlist}
                                >
                                    Yes
                                </button>
                            )}

                            {modalType == 'move_to_interview' && (
                                <button
                                    className="btn btn-primary w-50"
                                    onClick={moveToInterview}
                                >
                                    Yes
                                </button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
