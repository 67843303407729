import React, { useEffect, useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import DefaultImg from '../../assets/img/defaultImg.png'
import FlatList from '../../components/flatlist/FlatList'
import Profile from '../../components/reusables/Profile/Profile'
import jobMatchAction from '../../redux/job-matches/action'
import { jobMatchRequest, userRequest } from '../../service/requests'
import { errorSwipeActions } from '../../utils/constants'
import ExploreModal from '../explore/components/ExploreModal'
import ApplicantItem from './components/ApplicantItem'
import { Box, Button, Dialog } from '@material-ui/core'
import JobluTypography from '../../components/Typography'

import premiumBg from '../../assets/img/createJob/Go_Premium_BG.png'
import CloseWhite from '../../assets/img/CloseWhite.png'
import './styles.css'
const MessageSentModal = (props) => {
    const { showSentModal, handleCloseSentModal } = props
    return (
        <>
            <Modal show={showSentModal} centered onHide={handleCloseSentModal}>
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {/* {modalHeader} */}
                            Candidate has been <strong>Superliked</strong>
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-4  " style={{ fontSize: 20 }}>
                            <strong>
                                <span className="text-primary">
                                    Message Sent!
                                </span>
                            </strong>
                        </p>
                        <div className="mb-4">
                            <p style={{ fontSize: 20 }}>
                                Now let’s just wait for the candidate’s
                                response.
                            </p>
                        </div>

                        <div className="d-flex justify-content-center">
                            <button
                                className={`btn text-center btn-primary w-50`}
                                onClick={() => {
                                    handleCloseSentModal()
                                }}
                            >
                                Okay
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
const SuperLikeModal = (props) => {
    const {
        selectedApplicant,
        handleCloseConfirmModal,
        showConfirmModal,
        sendMessage,
        setMessage,
        sendMessageError,
        messageError,
        message,
    } = props
    return (
        <>
            <Modal
                show={showConfirmModal}
                centered
                onHide={handleCloseConfirmModal}
            >
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {/* {modalHeader} */}
                            Superlike Candidate
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-3" style={{ fontSize: 20 }}>
                            You are about to{' '}
                            <strong>
                                <span className="text-primary">Superlike</span>
                            </strong>
                        </p>
                        <div className="mb-3">
                            <>
                                <img
                                    src={
                                        selectedApplicant?.profileUrl ||
                                        DefaultImg
                                    }
                                    alt=""
                                    className="rounded-circle mb-3"
                                    style={{
                                        width: 90,
                                        height: 90,
                                        objectFit: 'cover',
                                    }}
                                />
                            </>
                            <div className="mb-3">
                                <p
                                    className="text-capitalize"
                                    style={{ fontSize: 30, fontWeight: 600 }}
                                >
                                    {selectedApplicant?.firstName}{' '}
                                    {selectedApplicant?.lastName}
                                </p>
                                <>
                                    <p
                                        style={{
                                            fontSize: 20,
                                            color: '#009CDE',
                                        }}
                                    >
                                        {selectedApplicant?.jobFunction}
                                        {/* {selectedJobData?.jobTitle} */}
                                    </p>
                                </>
                            </div>
                        </div>

                        <div className="mb-4 text-start">
                            <label htmlFor="">
                                <small className="text-muted">
                                    Send a message!
                                </small>
                            </label>
                            <textarea
                                name=""
                                className={`form-control ${
                                    messageError && 'border-danger'
                                }`}
                                id=""
                                cols="30"
                                rows="3"
                                value={message}
                                onChange={(e) => {
                                    if (e.target.value.length <= 240) {
                                        setMessage(e.target.value)
                                    }
                                }}
                            ></textarea>
                            <div className="text-end">
                                <span className="text-muted">
                                    <small>{message?.length || 0}/240</small>
                                </span>
                            </div>
                            {messageError && (
                                <>
                                    <span className="error-helper">
                                        Message for applicant is required
                                    </span>
                                </>
                            )}
                            {sendMessageError && (
                                <>
                                    <div
                                        class="alert alert-danger text-center mt-2"
                                        role="alert"
                                    >
                                        <small>
                                            Something went wrong while trying to
                                            send a message.
                                        </small>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="d-flex">
                            <button
                                className={`btn text-center btn-outline-primary w-50 me-2`}
                                onClick={() => {
                                    handleCloseConfirmModal()
                                }}
                            >
                                Cancel
                            </button>

                            <button
                                className="btn btn-primary w-50"
                                onClick={() => {
                                    sendMessage()
                                }}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
const Likes = (props) => {
    const { User, JobMatches, getMyLikedCandidates, getLikedMyJobs } = props
    const { user } = User
    const navigate = useNavigate()
    const { myLikedCandidates, likedMyJobs } = JobMatches
    const [showProfile, setShowProfile] = useState(false)
    const [loadMoreLikes, setLoadMoreLikes] = useState(false)
    const [loadMoreLikedMe, setLoadMoreLikedMe] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const location = useLocation()
    const [showSwipeLimit, setShowSwipeLimit] = useState(false)
    const [swipeLimitTitle, setSwipeLimitTitle] = useState(null)
    const [swipeLimitText, setSwipeLimitText] = useState(null)
    const [jobTitle, setJobTitle] = useState(null)

    const [limitReached, setLimitReached] = useState(false)
    const [showOutOfLike, setShowOutOfLike] = useState(false)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [matchImages, setMatchImages] = useState(null)
    const [applicantName, setApplicantName] = useState(null)

    const [loadingLikedMyJobs, setLoadingLikedMyJobs] = useState(false)
    const [loadingLikedCandidates, setLoadingLikedCandidates] = useState(false)

    const [currentTab, setCurrentTab] = useState('likes')

    const [talentSearch, setTalentSearch] = useState(false)

    useEffect(() => {
        if (User?.user) {
            userRequest
                .talentSearchStatus()
                .then((res) => {
                    console.log('taelent search', res.data)
                    setTalentSearch(true)
                })
                .catch(() => {
                    setTalentSearch(false)
                })
        }
    }, [User])

    const viewProfile = (item) => {
        setSelectedUser(item)
        setShowProfile(!showProfile)
        document.getElementsByTagName('BODY')[0].style.overflow = 'hidden'
        // setTimeout(() => {
        //     let profile = document.getElementById('view-profile')
        //     let backdrop = document.getElementById('backdrop')
        //     profile.classList.add('slide-in-right')
        //     profile.classList.add('d-block')
        //     backdrop.classList.add('d-block')
        // }, 500);
    }

    useEffect(() => {
        getLikedMyJobs()
        getMyLikedCandidates()
    }, [])

    useEffect(() => {
        const tab = location?.state?.tab
        if (tab) {
            setCurrentTab(tab)
        }
    }, [location])

    const renderLikes = (item, index) => {
        return (
            <ApplicantItem
                key={index}
                handleView={() => viewProfile(item?.matchApplicant)}
                data={item}
                itemId={index}
                getMyLikedCandidates={getMyLikedCandidates}
                talentSearch={talentSearch}
            />
        )
    }

    const renderLikedMyJobs = (item, index) => {
        return (
            <ApplicantItem
                key={index}
                handleView={() => viewProfile(item?.matchApplicant)}
                data={item}
                itemId={index}
                hasSwipeActions
                swipeAction={(payload) => swipeAction(payload)}
                isLikedMe
                talentSearch={talentSearch}
                hideContact={currentTab === 'liked_me'}
            />
        )
    }

    const [
        isGettingMoredata_likedCandidates,
        setIsGettingMoreData_likedCandidates,
    ] = useState(false)
    const [isGettingMoredata_likedMyJobs, setIsGettingMoreData_likedMyJobs] =
        useState(false)

    const getMoreData_LikedCandidates = () => {
        if (!isGettingMoredata_likedCandidates) {
            setLoadingLikedCandidates(true)
            // console.log(myLikedCandidates.totalCount)
            // console.log(myLikedCandidates.data?.length)
            const totalCount = myLikedCandidates?.totalCount
            const currentCount = myLikedCandidates?.data?.length
            const currentPage = myLikedCandidates?.page
            // console.log('PAGE:::',currentPage)
            const limit = myLikedCandidates?.limit
            if (totalCount > currentCount && currentCount < 100) {
                setIsGettingMoreData_likedCandidates(true)
                getMyLikedCandidates({ offset: currentPage + 1 })
                setTimeout(() => {
                    setIsGettingMoreData_likedCandidates(false)
                    setLoadingLikedCandidates(false)
                }, 1000)
            }
        }
    }

    const getMoreData_likedMyJobs = () => {
        if (!isGettingMoredata_likedMyJobs) {
            setLoadingLikedMyJobs(true)
            // console.log(myLikedCandidates.totalCount)
            // console.log(myLikedCandidates.data?.length)
            const totalCount = likedMyJobs?.totalCount
            const currentCount = likedMyJobs?.data?.length
            const currentPage = likedMyJobs?.page
            // console.log('PAGE:::',currentPage)
            const limit = likedMyJobs?.limit
            if (totalCount > currentCount && currentCount < 100) {
                setIsGettingMoreData_likedMyJobs(true)
                getLikedMyJobs({ offset: currentPage + 1 })
                setTimeout(() => {
                    setIsGettingMoreData_likedMyJobs(false)
                    setLoadingLikedMyJobs(false)
                }, 1000)
            }
        }
    }
    const [message, setMessage] = useState(null)
    const [messageError, setMessageError] = useState(false)
    const [jobId, setJobId] = useState(null)
    const [applicantId, setApplicantId] = useState(null)
    const [sendMessageError, setSendMessageError] = useState(false)
    const sendMessage = () => {
        if (!message) {
            setMessageError(true)
            return
        }
        let card = document.getElementById(selectedCard)
        setMessageError(false)
        const payload = {
            action: 'superLike',
            applicantId: selectedApplicant.userId,
            jobId: selectedJobId,
            message,
        }
        userRequest
            .employerSuperLike(payload)
            .then((res) => {
                setShowConfirmModal(false)
                setTimeout(() => {
                    card.classList.add('slide-out-top')
                    getLikedMyJobs()
                    getMyLikedCandidates()
                }, 500)

                setTimeout(() => {
                    card.classList.add('d-none')
                    setShowSentModal(true)
                    setSendMessageError(false)
                    setMessage(null)
                }, 1000)
            })
            .catch((err) => {
                console.log(err)
                setSendMessageError(true)
            })
    }

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const [showSentModal, setShowSentModal] = useState(false)
    const handleCloseSentModal = () => setShowSentModal(false)
    const [selectedApplicant, setSelectedApplicant] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null)
    const [cardIndex, setCardIndex] = useState(null)
    const [selectedJobId, setSelectedJobId] = useState(null)
    const swipeAction = (payload) => {
        const { job, applicant, action, itemId } = payload
        let applicant_item = document.getElementById(`${itemId}`)
        setSelectedCard(`${itemId}`)
        setSelectedJobId(job._id)
        jobMatchRequest
            .employerSwipe({
                jobId: job._id,
                applicantId: applicant.userId,
                action: action,
            })
            .then((res) => {
                console.log(res.data)
                if (
                    res.data.message ===
                    errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfLikes')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle('You have run out of likes today!')
                    // setSwipeLimitText('Go back tomorrow to start liking again.')
                    // setShowSwipeLimit(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfMonthlyLikes')
                    setModalOpen(true)
                    // alert(res.data.message)
                    // setSwipeLimitTitle(
                    //     'You have run out of likes for this month!'
                    // )
                    // setSwipeLimitText('You can like again next month.')
                    // setShowSwipeLimit(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfSuperLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED
                ) {
                    setModalVariant('upgradeAccount')
                    setModalOpen(true)
                } else if (
                    res.data.message === errorSwipeActions.LIKE_CREDITS_EXCEEDED
                ) {
                    setShowOutOfLike(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.JOB_MATCH_LIMIT_EXCEEDED
                ) {
                    setLimitReached(true)
                } else {
                    if (res.data == true) {
                        setMessageError(false)
                        setSendMessageError(false)
                        setSelectedApplicant(applicant)
                        setShowConfirmModal(true)
                        setMessage(null)
                        return
                    }
                    if (action === 'not_interested') {
                        applicant_item.classList.add('slide-out-left')
                    } else if (action === 'superLike') {
                        applicant_item.classList.add('slide-out-top')
                    } else if (action === 'like') {
                        applicant_item.classList.add('slide-out-right')
                    }
                    setTimeout(() => {
                        getLikedMyJobs()
                        getMyLikedCandidates()
                    }, 500)
                }

                if (res.data.matchStatus === 'match') {
                    // alert('MATCHED!!')
                    const images = {
                        employer: res.data.profileUrl_employer,
                        applicant: res.data.profileUrl_applicant,
                    }

                    setMatchImages(images)
                    setModalVariant('matchModal')
                    setModalOpen(true)
                    setApplicantName(
                        applicant.firstName + ' ' + applicant.lastName
                    )
                    setJobTitle(job.jobTitle)
                }
                // navigation.goBack()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            <MessageSentModal
                showSentModal={showSentModal}
                handleCloseSentModal={handleCloseSentModal}
            />
            <SuperLikeModal
                showConfirmModal={showConfirmModal}
                handleCloseConfirmModal={handleCloseConfirmModal}
                selectedApplicant={selectedApplicant}
                sendMessage={sendMessage}
                setMessage={setMessage}
                sendMessageError={sendMessageError}
                messageError={messageError}
                message={message}
            />
            <div className="row justify-content-center p-0 mx-0">
                <div className="border-bottom ">
                    <div className="container ">
                        <div className="justify-content-between align-items-center row">
                            <div className="col-sm-12 col-md-3 mt-3 mb-0 mb-md-3">
                                <h5 className="text-body fw500 mb-2 ">Likes</h5>
                            </div>
                            {/* <div className="col-sm-12 col-md-3 text-right pb-3"></div> */}
                        </div>
                    </div>
                </div>
                <Profile
                    showProfile={showProfile}
                    setShowProfile={setShowProfile}
                    selectedProfile={selectedUser}
                    currentTab={currentTab}
                />
                <div className="col-12 col-md-8 border mt-2 mb-5">
                    <div className="mt-3">
                        <ul
                            className="nav nav-pills nav-fill my-3"
                            id="myTab"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${
                                        currentTab === 'likes' && 'active'
                                    }`}
                                    id="likes-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#likes"
                                    type="button"
                                    role="tab"
                                    aria-controls="likes"
                                    aria-selected="true"
                                    onClick={() => setCurrentTab('likes')}
                                >
                                    Shortlisted
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link border-start ${
                                        currentTab === 'liked_me' && 'active'
                                    }`}
                                    id="liked-me-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#liked-me"
                                    type="button"
                                    role="tab"
                                    aria-controls="liked-me"
                                    aria-selected="false"
                                    onClick={() => setCurrentTab('liked_me')}
                                >
                                    Applied
                                </button>
                            </li>
                        </ul>
                    </div>
                    <Tabs activeKey={currentTab}>
                        <Tab eventKey={'likes'}>
                            <FlatList
                                renderItem={renderLikes}
                                data={myLikedCandidates.data.filter(
                                    (item) =>
                                        item.matchStatus === 'waiting' &&
                                        (item.employer.action === 'like' ||
                                            item.employer.action ===
                                                'superLike')
                                )}
                                loadMoreItems={() => alert('hello')}
                                hasMoreItems={loadMoreLikedMe}
                                list={'likes'}
                            />
                            {!loadMoreLikes &&
                                myLikedCandidates?.data?.length <
                                    myLikedCandidates?.totalCount && (
                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary my-3 text-center"
                                            onClick={() =>
                                                getMoreData_LikedCandidates()
                                            }
                                        >
                                            {loadingLikedCandidates && (
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                            Load More
                                        </button>
                                    </div>
                                )}
                        </Tab>
                        <Tab eventKey={'liked_me'}>
                            <FlatList
                                renderItem={renderLikedMyJobs}
                                data={likedMyJobs.data.filter(
                                    (item) =>
                                        item.matchStatus === 'waiting' &&
                                        (item.applicant.action === 'like' ||
                                            item.applicant.action ===
                                                'superLike')
                                )}
                                loadMoreItems={() => alert('hello')}
                                hasMoreItems={loadMoreLikedMe}
                                list={'likedMyJobs'}
                            />

                            {!loadMoreLikedMe &&
                                likedMyJobs?.data?.length <
                                    likedMyJobs?.totalCount && (
                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary mt-3 my-3 text-center"
                                            onClick={() =>
                                                getMoreData_likedMyJobs()
                                            }
                                        >
                                            {loadingLikedMyJobs && (
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                            Load More
                                        </button>
                                    </div>
                                )}
                        </Tab>
                    </Tabs>
                </div>
                <ExploreModal
                    showModal={modalOpen}
                    variant={modalVariant}
                    matchImages={matchImages}
                    setModalVariant={setModalVariant}
                    setShowModal={setModalOpen}
                    applicantName={applicantName}
                    jobTitle={jobTitle}
                />
                <Dialog
                    open={limitReached}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={() => {
                        setLimitReached(false)
                    }}
                    style={{ zIndex: '99999999' }}
                >
                    <Box
                        style={{
                            background: `url('${premiumBg}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            // display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                textAlign: 'right',
                                padding: '0.5rem',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseWhite}
                                width={36}
                                onClick={() => {
                                    setLimitReached(false)
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                padding: '0 3rem 3rem 3rem',
                            }}
                        >
                            <JobluTypography.H2
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                Reached your 50-matching limit?
                            </JobluTypography.H2>
                            <JobluTypography.H4
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                Experience the Joblu Premium and engage with up
                                to 300 outstanding candidates per job posting!
                            </JobluTypography.H4>
                            <JobluTypography.H6
                                semiBold
                                style={{ marginBottom: 20, color: '#D7A048' }}
                            >
                                Revolutionize your hiring process and unlock
                                access to a wider talent pool!
                            </JobluTypography.H6>
                            <Button
                                variant="contained"
                                style={{
                                    background: '#D7A048',
                                    color: '#fff',
                                    padding: '1rem 3rem',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    navigate('/pricing')
                                }}
                            >
                                Go Premium!
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    open={showOutOfLike}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={() => {
                        setShowOutOfLike(false)
                    }}
                    style={{ zIndex: '99999999' }}
                >
                    <Box
                        style={{
                            background: `url('${premiumBg}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            // display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                textAlign: 'right',
                                padding: '0.5rem',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseWhite}
                                width={36}
                                onClick={() => {
                                    setShowOutOfLike(false)
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                padding: '0 3rem 3rem 3rem',
                            }}
                        >
                            <JobluTypography.H2
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                Out of likes? No problem!
                            </JobluTypography.H2>
                            <JobluTypography.H6
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                You've used up all your Like credits, but the
                                perfect candidate is still out there! With Joblu
                                Premium, not only you will enjoy unlimited job
                                postings but you can also like more talent and
                                get up to 300 matches per job post.
                            </JobluTypography.H6>
                            {/* <JobluTypography.H6
                                semiBold
                                style={{ marginBottom: 20, color: '#D7A048' }}
                            >
                                Discover stress-free hiring for less.
                            </JobluTypography.H6> */}
                            <Button
                                variant="contained"
                                style={{
                                    background: '#D7A048',
                                    color: '#fff',
                                    padding: '1rem 3rem',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    navigate('/pricing')
                                }}
                            >
                                Unlock UNLIMITED Likes
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
        JobMatches: state.JobMatches,
    }
}
const mapDispatchToProps = {
    getLikedMyJobs: jobMatchAction.getLikedMyJobs,
    getMyLikedCandidates: jobMatchAction.getMyLikedCandidates,
}
export default connect(mapStateToProps, mapDispatchToProps)(Likes)
