import { Tooltip } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import infoIcon from '../assets/img/pricing-page/info.png'
export const baseURL = process.env.REACT_APP_BASE_URL
export const SECRET = process.env.REACT_APP_SECRET
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK
export const STRIPE_SECRET = process.env.REACT_APP_STRIPE_SECRET
export const STRIPE_BASE_URL = 'https://api.stripe.com/v1/'
export const BEARER = 'Bearer '
export const CONSTANT = 'CONSTANT'
export const API_KEY = '1d049c1815e9467c98c8d2606350ba4b'
export const APP_VERSION = '4.2.0'
export const GOOGLE_PLAY_VERSION = '32.0'
export const APPSTORE_ID = '1568716553'
export const APPSTORE_PACKAGE_NAME = 'com.joblu'
export const CURRENT_VERSION_DATE = '11/23/2021'

export const PAYPAL_CLIENT_TOKEN = 'TOKEN_HERE'
export const PAYPAL_SANDBOX_TOKEN = 'sandbox_d5n52bpx_4cyxgbvnmwndh94t'
export const STRIPE_CLIENT_TOKEN =
    'pk_live_51IuaLpLOeMtmhM3P9jw70UP6V7UFw9YO4znnQctwJOUjhoFfXuOKxea8rfM5cAPov7EWA8eLrrc094dv2QfOLrsu007FVVZOBd'

export const GUEST = {
    email: 'guest.joblu@gmail.com',
    username: 'guestJ0blU',
    password: 'J0blu2021_guest',
}

export const paymentPrices = {
    priorityApplicant: 500,
    preferredApplicant1: 10,
    preferredApplicant2: 100,
}
export const paymentTypes = {
    AGENCY_FEE: 'agency_fee',
    PRIORITY_APPLICANT_FEE: 'priority_applicant',
    PREFERRED_APPLICANT_DETAILED_REPORT_FEE:
        'preferred_applicant_detailed_report_fee',
    PREFERRED_APPLICANT_ELIGIBILITY_REPORT_FEE:
        'preferred_applicant_eligibility_report_fee',
    SUBSCRIPTION_FEE: 'subscription_fee',
}
export const config = {
    PROFILE_COMPLETION: 48,
}

export const errorSwipeActions = {
    LIKE_NOT_ALLOWED: '410000: Please upgrade account to like',
    DAILY_LIKE_LIMIT_EXCEEDED: '410001: Daily likes limit already exceeded',
    MONTHLY_LIKE_LIMIT_EXCEEDED: '410002: Monthly likes limit already exceeded',
    SUPER_LIKE_LIMIT_EXCEEDED: '410003: Superlikes limit already exceeded',
    JOB_POST_LIMIT_EXCEEDED: '410004: Monthly job posts limit already exceeded',
    SUPER_LIKE_ZERO_CREDITS: '410005: No more Superlikes credits',
    SUPER_LIKE_NOT_ALLOWED: '410006: Please upgrade account to superlike',
    LIKE_CREDITS_EXCEEDED: '410007: No more like credits',
    JOB_MATCH_LIMIT_EXCEEDED: '410009: Job Match limit already exceeded',
}

export const creds = {
    GOOGLE_CLIENT_ID:
        '779889662046-b4v5btkp3807a8qiak2v9dlst7aurs0g.apps.googleusercontent.com',
    GOOGLE_RECAPTCHA_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY,
}

export const profilePlaceholder_Applicant =
    'https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/profile.png'
export const profilePlaceholder_Employer =
    'https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/profile.png'

export const defaultSwipeActions = {
    right: 'like',
    left: 'not_interested',
    top: 'superLike',
}
export const salaryRange = [
    { label: 'Less than symbol_ 40,000', value: ' <40000' },
]

export const images = {
    JOBLU_ICON: `${process.env.REACT_APP_S3_BUCKET}/joblu-icon.png`,
}

export const salaryRanges = {
    COMPETIIVE_SALARY: process.env.REACT_APP_SALARY_RANGE_EXTRA_1,
    INDUSTRY_STANDARD: process.env.REACT_APP_SALARY_RANGE_EXTRA_2,
}

export const premium_features = [
    'Unlimited profile views ',
    'Unlimited chat access',
    'Free Candidate recommendations',
    'Company Culture showcase',
    'Unlimited Likes',
    '3 super likes/day',
    'Featured Employer cards',
    'Who’s liked your job',
    'Stealth mode',
    '1 Job Fair every month',
    'Undo swipe',
    'Unlimited Job Posts',
]

export const COMPANY_CULTURE_LENGTH = 10

export const JobApplication_Status = {
    SHORTLISTED: 'shortlisted',
    HIRED: 'hired',
    ARCHIVED: 'archived',
    APPLIED: 'applied',
    RECONSIDERED: 'reconsidered',
}

const check_styles = { coloe: '#009CDE' }
const close_styles = { color: '#EB5757' }
export const product_pricing_features = [
    {
        label: 'Job Posting',
        tooltip: (
            <>
                <Tooltip
                    title="Create and manage job postings effortlessly wherever, whenever. "
                    placement="right"
                >
                    <img
                        src={infoIcon}
                        width={16}
                        height={16}
                        style={{ objectFit: 'contain' }}
                    />
                </Tooltip>
            </>
        ),
        free: '2',
        premium: 'Unlimited',
        enterprise: 'Unlimited',
    },
    // {
    //     label: 'User Seat',
    //     tooltip: (
    //         <>
    //             <Tooltip
    //                 title="Save time and work efficiently as a team with extra user seats so you can collaborate smoothly."
    //                 placement="right"
    //             >
    //                 <img
    //                     src={infoIcon}
    //                     width={16}
    //                     height={16}
    //                     style={{ objectFit: 'contain' }}
    //                 />
    //             </Tooltip>
    //         </>
    //     ),
    //     free: '1',
    //     premium: '3',
    //     enterprise: 'Customizable',
    // },
    {
        label: 'Candidate Matching',
        tooltip: (
            <Tooltip
                title="Like or swipe right on the job applicants to take them to the next level of the hiring process."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: '50 Matches',
        premium: 'Up to 300 matches per job post',
        enterprise: 'Unlimited',
    },
    {
        label: 'Candidate Unmatching',
        tooltip: (
            <Tooltip
                title="Easily refine your candidate pool by unmatching prospects with a click."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Close style={close_styles} fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
    {
        label: 'Applicant Management',
        tooltip: (
            <Tooltip
                title="Streamline your hiring process with a comprehensive applicant tracking system."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Check style={check_styles} color="secondary" fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
    {
        label: 'Workspace Spotlight',
        tooltip: (
            <Tooltip
                title="Showcase your company culture and star employees to attract top talent."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Check style={check_styles} color="secondary" fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
    {
        label: 'Interview Scheduler',
        tooltip: (
            <Tooltip
                title="Schedule and conduct video interviews with Joblu's seamless third-party integrations."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Check style={check_styles} color="secondary" fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
    {
        label: 'Candidate Messaging',
        tooltip: (
            <Tooltip
                title="Communicate directly with candidates using our built-in messaging feature."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: '50 Messages',
        premium: 'Up to 300 per job post',
        enterprise: 'Unlimited',
    },
    {
        label: 'Job Blast',
        tooltip: (
            <Tooltip
                title="Share your job postings across popular social media platforms in just a few clicks without leaving Joblu."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Check style={check_styles} color="secondary" fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
    {
        label: 'Job Mailbox',
        tooltip: (
            <Tooltip
                title="Organize and centralize your recruitment communication in one dedicated mailbox."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Close style={close_styles} fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
    {
        label: 'Instant Data Exporter',
        tooltip: (
            <Tooltip
                title="Easily export candidate information and CVs with convenient CSV downloads."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Close style={close_styles} fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
    {
        label: 'Exclusive Recruitment Drive',
        tooltip: (
            <Tooltip
                title="Access a secure and centralized storage space for all your prospects."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Close style={close_styles} fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
    {
        label: 'Instant Alerts',
        tooltip: (
            <Tooltip
                title="Stay informed and take immediate action as needed with real-time updates."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: 'In-app Only',
        premium: 'In-app & Email',
        enterprise: 'In-app & Email',
    },
    {
        label: 'Talent Finder',
        tooltip: (
            <Tooltip
                title="Locate top talent swiftly with our advanced candidate search capabilities."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: 'Add-on',
        premium: '100 Superlikes',
        enterprise: 'Customizable',
    },
    {
        label: 'ATS Integration',
        tooltip: (
            <Tooltip
                title="Enhance your existing recruitment workflow with seamless ATS integration."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Close style={close_styles} fontSize="large" />,
        premium: 'Basic',
        enterprise: 'Advanced',
    },
    {
        label: 'Marketing Promotions',
        tooltip: (
            <Tooltip
                title="Boost your company's visibility and employer brand through targeted marketing promotions."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Close style={close_styles} fontSize="large" />,
        premium: '1 Social Media Post on Joblu’s pages + community',
        enterprise: 'Customizable',
    },
    {
        label: 'Dedicated Account Manager',
        tooltip: (
            <Tooltip
                title="Receive expert guidance and personalized support from your own account manager."
                placement="right"
            >
                <img
                    src={infoIcon}
                    width={16}
                    height={16}
                    style={{ objectFit: 'contain' }}
                />
            </Tooltip>
        ),
        free: <Close style={close_styles} fontSize="large" />,
        premium: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
        enterprise: (
            <Check style={check_styles} color="secondary" fontSize="large" />
        ),
    },
]
