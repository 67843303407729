import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { userRequest } from '../../service/requests'
import Loading from '../../components/reusables/Loading'

const VerifyEmail = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const [verifySuccess, setVerifySuccess] = useState()
    useEffect(() => {
        const token = searchParams.get("token")
        if(token)
            userRequest.verifyEmail({
                token: token
            })
            .then(res => {
                if(res?.data?.accessToken){
                    if(res?.data?.role === 'EMPLOYER')
                        navigate('/login', { state: { verify: true }})
                    else
                        setVerifySuccess(true)
                }
            })
            .catch(e => {
                navigate('/login')
            })
        else{

        }
    }, [])

    return (
        <div className="d-flex" style={{ height: '100vh' }}>
            <div className="m-auto">
                {
                    verifySuccess
                    ?
                    <div className="card login-card">
                        <div className="card-body text-center">
                            <h4 className="card-title text-center mb-4">You have successfully verified your account!</h4>
                            <p className="card-text mb-2 text-center">
                            Go back to the mobile app and login!
                            </p>
                        </div>
                    </div>
                :
                    <Loading show />
                }
            </div>
        </div>
    )
}
export default VerifyEmail
