import { default as FlatList2 } from 'flatlist-react'
import React, { useEffect, useState } from 'react'
import { default as FlatList_ } from 'flatlist-react'
import EmptyState from '../reusables/EmptyState'
import NoLikesImg from '../../assets/img/noLikesImg.png'
import likesImg from '../../assets/img/likes/likesImg.png'
import { connect } from 'react-redux'
import { userRequest } from '../../service/requests'

const FlatList = (props) => {
    const {
        data,
        renderItem,
        renderWhenEmpty,
        groupBy,
        sortBy,
        loadMoreItems,
        hasMoreItems,
        list,
        User,
    } = props

    const [talentSearch, setTalentSearch] = useState(true)
    useEffect(() => {
        if (User?.user) {
            userRequest
                .talentSearchStatus()
                .then((res) => {
                    console.log('taelent search', res.data)
                    setTalentSearch(true)
                })
                .catch(() => {
                    setTalentSearch(false)
                })
        }
    }, [User])
    return (
        <FlatList2
            list={data}
            renderItem={renderItem}
            renderWhenEmpty={() => (
                <div>
                    {list === 'likedMyJobs' && (
                        <EmptyState
                            emptyImg={likesImg}
                            title={
                                talentSearch
                                    ? 'Give it some time!'
                                    : 'Uh-oh, your applied page is looking a little empty.'
                            }
                            body={
                                talentSearch
                                    ? 'Tap on Explore to find new candidates '
                                    : "Upgrade to Joblu Premium to access unlimited candidate search. With our premium plan, you'll have the power to handpick the best of the best. Trust us, it's like having a cheat code for hiring!"
                            }
                            subText={talentSearch ? null : null}
                            link={talentSearch ? '/explore ' : '/pricing'}
                            linkType={'button'}
                            linkTitle={talentSearch ? 'Explore ' : 'Go Premium'}
                        />
                    )}
                    {list === 'likes' && (
                        <EmptyState
                            emptyImg={likesImg}
                            title={
                                talentSearch
                                    ? 'Like someone first!'
                                    : `Feeling stuck with an empty shortlisted candidates page?`
                            }
                            body={
                                talentSearch
                                    ? 'Tap on Explore to find new candidates'
                                    : 'Upgrade to Joblu Premium and unlock access to candidate search. No more settling for less than the best.'
                            }
                            subText={talentSearch ? null : null}
                            link={talentSearch ? '/explore ' : '/pricing'}
                            linkType={'button'}
                            linkTitle={talentSearch ? 'Explore ' : 'Go Premium'}
                        />
                    )}
                    {list === 'message' && (
                        <EmptyState
                            body={'No conversation yet'}
                            link={'/dashboard'}
                            linkType={'link'}
                            linkTitle={'Explore'}
                        />
                    )}

                    {list === 'recommendations' && (
                        <EmptyState
                            emptyImg={likesImg}
                            title={'There are no recommendations yet'}
                            body={'Tap on Explore to find new candidates'}
                            link={'/dashboard'}
                            linkType={'button'}
                            linkTitle={'Explore'}
                        />
                    )}
                </div>
            )}
            // sortBy={["firstName", {key: "lastName", descending: true}]}
            // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
            loadMoreItems={loadMoreItems}
            hasMoreItems={hasMoreItems}
            paginationLoadingIndicator={
                <div style={{ alignSelf: 'center' }}>Getting more items...</div>
            }
            paginationLoadingIndicatorPosition={'center'}
        />
    )
}

const mapStateToProps = (state) => {
    return { User: state.User }
}

export default connect(mapStateToProps, null)(FlatList)
