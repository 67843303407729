import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { STRIPE_PK } from '../../utils/constants'
import { thousendSeparator } from '../../utils/helpers'
import CheckoutForm from './CheckoutForm'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK)

const StripeCheckout = () => {
    const location = useLocation()
    const [options, setOptions] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        if (location.state?.intent?.paymentIntent) {
            setOptions({
                clientSecret: location.state.intent.paymentIntent,
            })
        }
        console.log(location)
    }, [location.state])
    return (
        <>
            {options && (
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm
                        trialUsed={location.state.intent.trialUsed}
                        paymentIntent={location.state.intent.paymentIntent}
                        payload={location.state.payload}
                        customerId={location.state.intent.customerId}
                        amount={thousendSeparator(
                            location.state?.intent?.plan.unit_amount / 100
                        )}
                        plan={location.state?.intent?.plan}
                    />
                </Elements>
            )}
        </>
    )
}

export default StripeCheckout
