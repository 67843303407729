import actions from './actions'

export const initAppState = {
    showSignupSuccessModal: false,
    currency: 'PHP',
    currencyList: {},
    showTutorial: false,
    hideTriggerTutorial: false,
    showNav: true,
    showAlert: false,
}

const generalReducer = (state = initAppState, action) => {
    // console.log(action.type)
    switch (action.type) {
        case actions.SHOW_SIGNUP_SUCCESS:
            return { ...state, showSignupSuccessModal: true }
        case actions.SET_CURRENCY:
            return { ...state, currency: action.payload }
        case actions.SET_CURRENCY_LIST:
            return { ...state, currencyList: action.payload }
        case actions.SET_SHOW_TUTORIAL:
            return { ...state, showTutorial: action.payload }
        case actions.SET_HIDE_TUTORIAL:
            return { ...state, hideTriggerTutorial: action.payload }
        case actions.SET_SHOW_SIGNUP_MODAL:
            return { ...state, showSignupSuccessModal: action.payload }
        case actions.SET_SHOW_MAIN_NAVIGATION_BAR:
            return { ...state, showNav: action.payload }
        case actions.SET_SHOW_ALERT:
            if (!action?.payload?.message) return { ...state, showAlert: false }
            return { ...state, showAlert: action.payload }
        default:
            return state
    }
}

export default generalReducer
