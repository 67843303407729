import csvDownload from 'json-to-csv-export'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useLocation, useNavigate } from 'react-router-dom'
import DefaultImg from '../../assets/img/defaultImg.png'
import ArchiveIcon from '../../assets/img/matches/archiveIcon.png'
import JobluCircle from '../../assets/img/my-jobs/joblu-icon-circle.png'
import WorkableCircle from '../../assets/img/my-jobs/workable-icon-circle.png'
import CustomTooltip from '../../components/reusables/CustomTooltip'
import SelectJobPost from '../../components/reusables/SelectJobPost'
import {
    jobApplicantRequest,
    jobMatchRequest,
    jobsRequest,
    userRequest,
} from '../../service/requests'

import { Box, Button, Dialog } from '@material-ui/core'
import { connect } from 'react-redux'
import CloseWhite from '../../assets/img/CloseWhite.png'
import premiumBg from '../../assets/img/createJob/Go_Premium_BG.png'
import JobluTypography from '../../components/Typography'
import { errorSwipeActions } from '../../utils/constants'
import ExploreModal from '../explore/components/ExploreModal'
import Applicants from './components/Applicants'

import './styles.css'
import useWindowDimensions from '../../utils/hooks'
const MessageSentModal = (props) => {
    const { showSentModal, handleCloseSentModal } = props
    return (
        <>
            <Modal show={showSentModal} centered onHide={handleCloseSentModal}>
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {/* {modalHeader} */}
                            Candidate has been <strong>Superliked</strong>
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-4  " style={{ fontSize: 20 }}>
                            <strong>
                                <span className="text-primary">
                                    Message Sent!
                                </span>
                            </strong>
                        </p>
                        <div className="mb-4">
                            <p style={{ fontSize: 20 }}>
                                Now let’s just wait for the candidate’s
                                response.
                            </p>
                        </div>

                        <div className="d-flex justify-content-center">
                            <button
                                className={`btn text-center btn-primary w-50`}
                                onClick={() => {
                                    handleCloseSentModal()
                                }}
                            >
                                Okay
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
const SuperLikeModal = (props) => {
    const {
        selectedApplicant,
        handleCloseConfirmModal,
        showConfirmModal,
        sendMessage,
        setMessage,
        sendMessageError,
        messageError,
        message,
    } = props
    return (
        <>
            <Modal
                show={showConfirmModal}
                centered
                onHide={handleCloseConfirmModal}
            >
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            {/* {modalHeader} */}
                            Superlike Candidate
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        <p className="mb-3" style={{ fontSize: 20 }}>
                            You are about to{' '}
                            <strong>
                                <span className="text-primary">Superlike</span>
                            </strong>
                        </p>
                        <div className="mb-3">
                            <>
                                <img
                                    src={
                                        selectedApplicant?.profileUrl ||
                                        DefaultImg
                                    }
                                    alt=""
                                    className="rounded-circle mb-3"
                                    style={{
                                        width: 90,
                                        height: 90,
                                        objectFit: 'cover',
                                    }}
                                />
                            </>
                            <div className="mb-3">
                                <p
                                    className="text-capitalize"
                                    style={{ fontSize: 30, fontWeight: 600 }}
                                >
                                    {selectedApplicant?.firstName}{' '}
                                    {selectedApplicant?.lastName}
                                </p>
                                <>
                                    <p
                                        style={{
                                            fontSize: 20,
                                            color: '#009CDE',
                                        }}
                                    >
                                        {selectedApplicant?.jobFunction}
                                        {/* {selectedJobData?.jobTitle} */}
                                    </p>
                                </>
                            </div>
                        </div>

                        <div className="mb-4 text-start">
                            <label htmlFor="">
                                <small className="text-muted">
                                    Send a message!
                                </small>
                            </label>
                            <textarea
                                name=""
                                className={`form-control ${
                                    messageError && 'border-danger'
                                }`}
                                id=""
                                cols="30"
                                rows="3"
                                value={message}
                                onChange={(e) => {
                                    if (e.target.value.length <= 240) {
                                        setMessage(e.target.value)
                                    }
                                }}
                            ></textarea>
                            <div className="text-end">
                                <span className="text-muted">
                                    <small>{message?.length || 0}/240</small>
                                </span>
                            </div>
                            {messageError && (
                                <>
                                    <span className="error-helper">
                                        Message for applicant is required
                                    </span>
                                </>
                            )}
                            {sendMessageError && (
                                <>
                                    <div
                                        class="alert alert-danger text-center mt-2"
                                        role="alert"
                                    >
                                        <small>
                                            Something went wrong while trying to
                                            send a message.
                                        </small>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="d-flex">
                            <button
                                className={`btn text-center btn-outline-primary w-50 me-2`}
                                onClick={() => {
                                    handleCloseConfirmModal()
                                }}
                            >
                                Cancel
                            </button>

                            <button
                                className="btn btn-primary w-50"
                                onClick={() => {
                                    sendMessage()
                                }}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const DownloadCSVModal = (props) => {
    const {
        setJobsDataFilter,
        jobsDataFilter,
        handleCloseExportModal,
        showExportModal,
        jobsData,
        jobsDataLoading,
        exportApplicants,
        defaultLoading,
        workableLoading,
    } = props
    return (
        <>
            <Modal
                show={showExportModal}
                centered
                onHide={handleCloseExportModal}
            >
                <Modal.Header className="bg-primary justify-content-center">
                    <div>
                        <h5 className="text-center text-white mb-0">
                            Download CSV
                        </h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center px-3">
                        <div className="row">
                            <div className="col-6 text-start">Select All</div>
                            <div className="col-6 text-end">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (jobsDataFilter.all === '1') {
                                                console.log('haha')
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    all: '0',
                                                    applied: '0',
                                                    shortlisted: '0',
                                                    interview: '0',
                                                    archived: '0',
                                                    hired: '0',
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    all: '1',
                                                    applied: '1',
                                                    shortlisted: '1',
                                                    interview: '1',
                                                    archived: '1',
                                                    hired: '1',
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all === '1' ||
                                            (jobsDataFilter.applied == '1' &&
                                                jobsDataFilter.shortlisted ==
                                                    '1' &&
                                                jobsDataFilter.interview ==
                                                    '1' &&
                                                jobsDataFilter.archived ==
                                                    '1' &&
                                                jobsDataFilter.hired == '1')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6 text-start mb-3">Applied</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        onChange={() => {
                                            if (
                                                jobsDataFilter.applied === '1'
                                            ) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    applied: '0',
                                                    all: '0',
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    applied: '1',
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == '1' ||
                                            jobsDataFilter.applied === '1'
                                        }
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                    />
                                </div>
                            </div>
                            <div className="col-6 text-start mb-3">
                                Shortlisted
                            </div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (
                                                jobsDataFilter.shortlisted ===
                                                '1'
                                            ) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    shortlisted: '0',
                                                    all: '0',
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    shortlisted: '1',
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == '1' ||
                                            jobsDataFilter.shortlisted === '1'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-6 text-start mb-3">
                                Interview
                            </div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (
                                                jobsDataFilter.interview === '1'
                                            ) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    interview: '0',
                                                    all: '0',
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    interview: '1',
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == '1' ||
                                            jobsDataFilter.interview === '1'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-6 text-start mb-3">Archive</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (
                                                jobsDataFilter.archived === '1'
                                            ) {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    archived: '0',
                                                    all: '0',
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    archived: '1',
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == '1' ||
                                            jobsDataFilter.archived === '1'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-6 text-start mb-3">Hired</div>
                            <div className="col-6 text-end mb-3">
                                <div>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                        onChange={() => {
                                            if (jobsDataFilter.hired === '1') {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    hired: '0',
                                                    all: '0',
                                                })
                                            } else {
                                                setJobsDataFilter({
                                                    ...jobsDataFilter,
                                                    hired: '1',
                                                })
                                            }
                                        }}
                                        checked={
                                            jobsDataFilter.all == '1' ||
                                            jobsDataFilter.hired === '1'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="d-flex">
                                    {defaultLoading ? (
                                        <>
                                            <button
                                                className="btn btn-outline-primary me-2 text-center w-100 me-2"
                                                disabled={defaultLoading}
                                            >
                                                <p>Generating CSV</p>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() => {
                                                    exportApplicants('default')
                                                }}
                                                className="btn btn-outline-primary me-2 nh w-100 text-center"
                                                disabled={
                                                    jobsDataFilter.applied ==
                                                        '0' &&
                                                    jobsDataFilter.shortlisted ==
                                                        '0' &&
                                                    jobsDataFilter.interview ==
                                                        '0' &&
                                                    jobsDataFilter.archived ==
                                                        '0' &&
                                                    jobsDataFilter.hired ==
                                                        '0' &&
                                                    jobsDataFilter.all == '0'
                                                }
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <img
                                                        src={JobluCircle}
                                                        style={{ width: 25 }}
                                                        alt=""
                                                        className="me-2"
                                                    />
                                                    <div
                                                        className="text-primary"
                                                        style={{ fontSize: 14 }}
                                                    >
                                                        Default Template
                                                    </div>
                                                </div>
                                            </button>
                                        </>
                                    )}
                                    {workableLoading ? (
                                        <>
                                            <button
                                                className="btn btn-outline-primary text-center w-100"
                                                disabled={workableLoading}
                                            >
                                                <p>Generating CSV</p>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() => {
                                                    exportApplicants('workable')
                                                }}
                                                className="btn btn-outline-success nh text-success w-100 text-center"
                                                style={{
                                                    borderColor: '#00756A',
                                                }}
                                                disabled={
                                                    jobsDataFilter.applied ==
                                                        '0' &&
                                                    jobsDataFilter.shortlisted ==
                                                        '0' &&
                                                    jobsDataFilter.interview ==
                                                        '0' &&
                                                    jobsDataFilter.archived ==
                                                        '0' &&
                                                    jobsDataFilter.hired ==
                                                        '0' &&
                                                    jobsDataFilter.all == '0'
                                                }
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <img
                                                        src={WorkableCircle}
                                                        style={{ width: 25 }}
                                                        alt=""
                                                        className="me-2"
                                                    />
                                                    <div
                                                        style={{
                                                            color: '#00756A',
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        Workable Template
                                                    </div>
                                                </div>
                                            </button>
                                        </>
                                    )}
                                </div>
                                <div className="mt-3">
                                    <button
                                        className="btn btn-outline-primary nh w-100 text-center"
                                        onClick={handleCloseExportModal}
                                    >
                                        <span style={{ fontSize: 14 }}>
                                            Cancel
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

function Matches(props) {
    const { User } = props
    const PAGE_LIMIT = 20
    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const [selectedJobPost, setSelectedJobPost] = useState('')
    const [currentTab, setCurrentTab] = useState('Applied')
    const [appliedApplicants, setAppliedApplicants] = useState([])
    const [shortListedApplicants, setShortListedApplicants] = useState([])
    const [archivedApplicants, setArchivedApplicants] = useState([])
    const [interviewApplicants, setInterviewApplicants] = useState([])
    const [hiredApplicants, setHiredApplicants] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const tabs = ['Applied', 'Shortlisted', 'Interviews', 'Hired']

    const [limitReached, setLimitReached] = useState(false)
    const [showOutOfLike, setShowOutOfLike] = useState(false)

    const [jobTitle, setJobTitle] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVariant, setModalVariant] = useState(null)
    const [matchImages, setMatchImages] = useState(null)
    const [applicantName, setApplicantName] = useState(null)
    const [search_keyword, setSearchKeyword] = useState('')
    const [pagination_loading, setPaginationLoading] = useState(false)
    const [pageOffsets, setPageOffsets] = useState({
        Applied: 1,
        Shortlisted: 1,
        Interviews: 1,
        Hired: 1,
    })

    const [totalMatches, setTotalMatches] = useState(0)
    const [hasMoreData, setHasMoreData] = useState({
        Applied: false,
        Shortlisted: false,
        Interviews: false,
        Hired: false,
    })

    const [totalCounts, setTotalCounts] = useState({
        Applied: 1,
        Shortlisted: 1,
        Interviews: 1,
        Hired: 1,
    })

    useEffect(() => {
        if (location?.state?.tab) {
            setCurrentTab(location.state.tab)
        }
    }, [location])

    const [talentSearch, setTalentSearch] = useState(true)
    const [downloadCSVPremium, setDownloadCSVPremium] = useState(false)

    useEffect(() => {
        if (User?.user) {
            userRequest
                .talentSearchStatus()
                .then((res) => {
                    console.log('taelent search', res.data)
                    setTalentSearch(true)
                })
                .catch(() => {
                    setTalentSearch(false)
                })
        }
    }, [User])

    useEffect(() => {
        const jobId = new URLSearchParams(location.search).get('job')
        const jobIdFromLocation = location?.state?.data?.data?.jobId
        console.log('jobbb', location?.state?.data?.data)
        if (!!jobId) {
            setSelectedJobPost(jobId)
        } else if (!!jobIdFromLocation) {
            setSelectedJobPost(jobIdFromLocation)
        }
    }, [location])

    const [appliedCount, setAppliedCount] = useState(0)
    const [shortlistedCount, setShortListedCount] = useState(0)
    const [interviewsCount, setInterviewsCount] = useState(0)
    const [hiredCount, setHiredCount] = useState(0)
    const getApplicants = (search = null, append = undefined) => {
        if (!!selectedJobPost) {
            !append ? setIsLoading(true) : setPaginationLoading(true)
            let payload = { limit: PAGE_LIMIT }
            if (search) payload.search = search

            try {
                if (currentTab === 'Applied') {
                    payload.offset = !!append ? pageOffsets.Applied : 1
                    jobApplicantRequest
                        .getJobApplicants_Applied(selectedJobPost, payload)
                        .then((res) => {
                            setAppliedCount(res?.data?.stats?.applicants)
                            setShortListedCount(res?.data?.stats?.shortlisted)
                            setInterviewsCount(res?.data?.stats?.interviews)
                            setHiredCount(res?.data?.stats?.hired)
                            setTotalMatches(res?.data?.stats?.totalMatches)
                            setTotalCounts({
                                ...totalCounts,
                                Applied: res?.data?.totalCount,
                            })
                            if (
                                appliedApplicants.length <
                                    res.data?.totalCount &&
                                pagination_loading === false
                            ) {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Applied: true,
                                })
                            } else {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Applied: false,
                                })
                            }
                            if (!!append) {
                                setAppliedApplicants([
                                    ...appliedApplicants,
                                    ...res?.data?.data,
                                ])
                            } else {
                                setAppliedApplicants(res?.data?.data)
                            }
                            setTimeout(() => {
                                setIsLoading(false)
                                setPaginationLoading(false)
                            }, 1000)
                        })
                }
                if (currentTab === 'Shortlisted') {
                    payload.offset = !!append ? pageOffsets.Shortlisted : 1

                    console.log('payload shortlisted', payload)
                    jobApplicantRequest
                        .getJobApplicants_Shortlisted(selectedJobPost, payload)
                        .then((res) => {
                            setAppliedCount(res?.data?.stats?.applicants)
                            setShortListedCount(res?.data?.stats?.shortlisted)
                            setInterviewsCount(res?.data?.stats?.interviews)
                            setHiredCount(res?.data?.stats?.hired)
                            setTotalMatches(res?.data?.stats?.totalMatches)
                            setTotalCounts({
                                ...totalCounts,
                                Shortlisted: res?.data?.totalCount,
                            })
                            if (
                                shortListedApplicants.length <
                                    res.data?.totalCount &&
                                pagination_loading === false
                            ) {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Shortlisted: true,
                                })
                            } else {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Shortlisted: false,
                                })
                            }
                            if (!!append) {
                                setShortListedApplicants([
                                    ...shortListedApplicants,
                                    ...res?.data?.data,
                                ])
                            } else {
                                setShortListedApplicants(res?.data?.data)
                            }
                            setTimeout(() => {
                                setIsLoading(false)
                                setPaginationLoading(false)
                            }, 1500)
                        })
                }
                if (currentTab === 'Interviews') {
                    payload.offset = !!append ? pageOffsets.Interviews : 1

                    jobApplicantRequest
                        .getJobApplicants_Interview(selectedJobPost, payload)
                        .then((res) => {
                            setAppliedCount(res?.data?.stats?.applicants)
                            setShortListedCount(res?.data?.stats?.shortlisted)
                            setInterviewsCount(res?.data?.stats?.interviews)
                            setHiredCount(res?.data?.stats?.hired)
                            setTotalMatches(res?.data?.stats?.totalMatches)
                            setTotalCounts({
                                ...totalCounts,
                                Interviews: res?.data?.totalCount,
                            })
                            if (
                                interviewApplicants.length <
                                    res.data?.totalCount &&
                                pagination_loading === false
                            ) {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Interviews: true,
                                })
                            } else {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Interviews: false,
                                })
                            }
                            if (!!append) {
                                setInterviewApplicants([
                                    ...interviewApplicants,
                                    ...res?.data?.data,
                                ])
                            } else {
                                setInterviewApplicants(res?.data?.data)
                            }
                            setTimeout(() => {
                                setIsLoading(false)
                                setPaginationLoading(false)
                            }, 1000)
                        })
                }
                if (currentTab === 'Hired') {
                    payload.offset = !!append ? pageOffsets.Hired : 1

                    jobApplicantRequest
                        .getJobApplicants_Hired(selectedJobPost, payload)
                        .then((res) => {
                            setAppliedCount(res?.data?.stats?.applicants)
                            setShortListedCount(res?.data?.stats?.shortlisted)
                            setInterviewsCount(res?.data?.stats?.interviews)
                            setHiredCount(res?.data?.stats?.hired)
                            setTotalMatches(res?.data?.stats?.totalMatches)
                            setTotalCounts({
                                ...totalCounts,
                                Hired: res?.data?.totalCount,
                            })
                            if (
                                hiredApplicants.length < res.data?.totalCount &&
                                pagination_loading === false
                            ) {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Hired: true,
                                })
                            } else {
                                setHasMoreData({
                                    ...hasMoreData,
                                    Hired: false,
                                })
                            }
                            if (!!append) {
                                setHiredApplicants([
                                    ...hiredApplicants,
                                    ...res?.data?.data,
                                ])
                            } else {
                                setHiredApplicants(res?.data?.data)
                            }
                            setTimeout(() => {
                                setIsLoading(false)
                                setPaginationLoading(false)
                            }, 1000)
                        })
                }
                if (currentTab === 'Archived') {
                    jobApplicantRequest
                        .getJobApplicants_Rejected(selectedJobPost, payload)
                        .then((res) => {
                            setArchivedApplicants(res?.data?.data)
                            setIsLoading(false)
                        })
                }
            } catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        getApplicants()
    }, [selectedJobPost, currentTab])

    const [message, setMessage] = useState(null)
    const [messageError, setMessageError] = useState(false)
    const [jobId, setJobId] = useState(null)
    const [applicantId, setApplicantId] = useState(null)
    const [sendMessageError, setSendMessageError] = useState(false)

    const sendMessage = () => {
        if (!message) {
            setMessageError(true)
            return
        }
        let card = document.getElementById(selectedCard)
        setMessageError(false)
        const payload = {
            action: 'superLike',
            applicantId: selectedApplicant.userId,
            jobId: selectedJobId,
            message,
        }
        userRequest
            .employerSuperLike(payload)
            .then((res) => {
                setShowConfirmModal(false)
                setTimeout(() => {
                    card.classList.add('slide-out-top')
                    getApplicants()
                }, 500)

                setTimeout(() => {
                    card.classList.add('d-none')
                    setShowSentModal(true)
                    setSendMessageError(false)
                    setMessage(null)
                }, 1000)
            })
            .catch((err) => {
                console.log(err)
                setSendMessageError(true)
            })
    }

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const handleCloseConfirmModal = () => setShowConfirmModal(false)
    const [showSentModal, setShowSentModal] = useState(false)
    const handleCloseSentModal = () => setShowSentModal(false)
    const [selectedApplicant, setSelectedApplicant] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null)
    const [cardIndex, setCardIndex] = useState(null)
    const [selectedJobId, setSelectedJobId] = useState(null)

    const swipeAction = (payload) => {
        const { job, applicant, action, itemId } = payload
        let applicant_item = document.getElementById(`${itemId}`)
        setSelectedCard(`${itemId}`)
        setSelectedJobId(job._id)
        jobMatchRequest
            .employerSwipe({
                jobId: job._id,
                applicantId: applicant.userId,
                action: action,
            })
            .then((res) => {
                console.log('rawr', res.data?.message)

                if (
                    res.data.message ===
                    errorSwipeActions.DAILY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.MONTHLY_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfMonthlyLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.SUPER_LIKE_LIMIT_EXCEEDED
                ) {
                    setModalVariant('outOfSuperLikes')
                    setModalOpen(true)
                } else if (
                    res.data.message === errorSwipeActions.LIKE_NOT_ALLOWED
                ) {
                    setModalVariant('upgradeAccount')
                    setModalOpen(true)
                } else if (
                    res.data.message === errorSwipeActions.LIKE_CREDITS_EXCEEDED
                ) {
                    setShowOutOfLike(true)
                } else if (
                    res.data.message ===
                    errorSwipeActions.JOB_MATCH_LIMIT_EXCEEDED
                ) {
                    setLimitReached(true)
                } else {
                    if (res.data == true) {
                        setMessageError(false)
                        setSendMessageError(false)
                        setSelectedApplicant(applicant)
                        setShowConfirmModal(true)
                        setMessage(null)
                        return
                    }
                    if (action === 'not_interested') {
                        applicant_item.classList.add('slide-out-left')
                    } else if (action === 'superLike') {
                        applicant_item.classList.add('slide-out-top')
                    } else if (action === 'like') {
                        applicant_item.classList.add('slide-out-right')
                    }
                    setTimeout(() => {
                        getApplicants()
                    }, 500)

                    if (res.data.matchStatus === 'match') {
                        // alert('MATCHED!!')
                        const images = {
                            employer: res.data.profileUrl_employer,
                            applicant: res.data.profileUrl_applicant,
                        }

                        setMatchImages(images)
                        setModalVariant('matchModal')
                        setModalOpen(true)
                        setApplicantName(
                            applicant.firstName + ' ' + applicant.lastName
                        )
                        setJobTitle(job.jobTitle)
                    }
                }

                // navigation.goBack()
            })
            .catch((err) => {})
    }

    let typingTimer
    let doneInterval = 2000

    const search = () => {
        setIsLoading(true)
        clearTimeout(typingTimer)
        typingTimer = setTimeout(
            () => getApplicants(search_keyword),
            doneInterval
        )
    }
    const handleSearchApplicants = (val) => {
        setSearchKeyword(val)
    }

    useEffect(() => {
        search()
    }, [search_keyword])

    const reset_scroll_changes = (tab) => {
        setAppliedApplicants([])
        setShortListedApplicants([])
        setInterviewApplicants([])
        setHiredApplicants([])
        // if (tab === 'Archived')
        //     data = archivedApplicants
        setPageOffsets({
            Applied: 1,
            Shortlisted: 1,
            Interviews: 1,
            Hired: 1,
        })
        setHasMoreData({
            Applied: false,
            Shortlisted: false,
            Interviews: false,
            Hired: false,
        })
    }
    useEffect(() => {
        console.log('hasMoreData', hasMoreData)
        console.log('pageOffsets', pageOffsets)
        console.log('totalCounts', totalCounts)
    }, [hasMoreData, pageOffsets, totalCounts])

    const handleLoadMore = (tab) => {
        const pages = Math.ceil(totalCounts[tab] / PAGE_LIMIT)
        console.log('pages', pages)
        const total_pages = !isNaN(pages) && isFinite(pages) ? pages : 1
        const does_not_exceed = pageOffsets[tab] + 1 <= total_pages
        console.log(
            'pages',
            total_pages,
            'next offset => ',
            pageOffsets[tab] + 1
        )
        if (
            hasMoreData[tab] &&
            !pagination_loading &&
            !isLoading &&
            does_not_exceed
        ) {
            setPageOffsets({
                ...pageOffsets,
                [tab]: pageOffsets[tab] + 1,
            })
        } else {
            setHasMoreData({
                ...hasMoreData,
                [tab]: false,
            })
        }
    }

    useEffect(()=>{
console.log('UUUU', User)
    }, [User])
    useEffect(() => {
        if (interviewApplicants?.length >= totalCounts?.Interviews) {
            setHasMoreData((hasMoreData) => ({
                ...hasMoreData,
                Interviews: false,
            }))
        }
        if (appliedApplicants?.length >= totalCounts?.Applied) {
            setHasMoreData((hasMoreData) => ({
                ...hasMoreData,
                Applied: false,
            }))
        }
        if (shortListedApplicants?.length >= totalCounts?.Shortlisted) {
            setHasMoreData((hasMoreData) => ({
                ...hasMoreData,
                Shortlisted: false,
            }))
        }
        if (hiredApplicants?.length >= totalCounts?.Hired) {
            setHasMoreData((hasMoreData) => ({
                ...hasMoreData,
                Hired: false,
            }))
        }
    }, [
        interviewApplicants,
        appliedApplicants,
        shortListedApplicants,
        hiredApplicants,
        totalCounts,
    ])

    useEffect(() => {
        !pagination_loading && getApplicants(search_keyword, true)
    }, [pageOffsets])

    useEffect(() => {
        console.log('hired', appliedApplicants)
        console.log('hired', hiredApplicants)
    }, [hiredApplicants, appliedApplicants])

    const [showExportModal, setShowExportModal] = useState(false)
    const handleCloseExportModal = () => setShowExportModal(false)

    const [jobsDataLoading, setJobsDataLoading] = useState(false)
    const [jobsData, setJobsData] = useState([])
    const [exportTemplate, setExportTemplate] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [jobsDataFilter, setJobsDataFilter] = useState({
        all: '0',
        applied: '0',
        shortlisted: '0',
        interview: '0',
        archived: '0',
        hired: '0',
    })
    const handleOpenExportModal = () => {
        exportApplicants()
        setJobsDataFilter({
            ...jobsDataFilter,
            all: '0',
            applied: '0',
            shortlisted: '0',
            interview: '0',
            archived: '0',
            hired: '0',
        })
        setShowExportModal(true)
    }
    const dataToConvert = {
        data: jobsData,
        filename: fileName,
        delimiter: ',',
        // headers: ['IP', "Full Name", "IP Address"]/
    }

    const [workableLoading, setWorkableLoading] = useState(false)
    const [defaultLoading, setDefaultLoading] = useState(false)
    const exportApplicants = (template) => {
        setExportTemplate(template)

        if (template == 'workable') {
            setWorkableLoading(true)
        }
        if (template == 'default') {
            setDefaultLoading(true)
        }
        const jobId = selectedJobPost
        jobsRequest
            .exportApplicants(jobId, template, jobsDataFilter)
            .then((res) => {
                console.log(res.data)
                // if(res.data.)
                const dataToConvert = {
                    data: res.data,
                    filename:
                        template == 'workable'
                            ? `WB_Exported Applicants - (${moment().format(
                                  'MM-DD-YYYY HMM'
                              )})`
                            : `DF_Exported Applicants - (${moment().format(
                                  'MM-DD-YYYY HMM'
                              )})`,
                    delimiter: ',',
                }
                setTimeout(() => {
                    setWorkableLoading(false)
                    setDefaultLoading(false)
                    csvDownload(dataToConvert)

                    setJobsDataLoading(false)
                }, 3000)
            })
            .catch((err) => {
                setWorkableLoading(false)
                setDefaultLoading(false)
                console.log(err)
            })
    }

    return (
        <>
            {/* @JUDE DOWNLOAD CSV PREMIUM MODAL*/}
            <Dialog
                open={downloadCSVPremium}
                fullWidth
                maxWidth={'sm'}
                onClose={() => {
                    setDownloadCSVPremium(false)
                }}
                style={{ zIndex: '99999999' }}
            >
                <Box
                    style={{
                        background: `url('${premiumBg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        // display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        style={{
                            textAlign: 'right',
                            padding: '0.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={CloseWhite}
                            width={36}
                            onClick={() => {
                                setDownloadCSVPremium(false)
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            padding: '0 3rem 3rem 3rem',
                        }}
                    >
                        <JobluTypography.H1
                            semiBold
                            style={{ marginBottom: '1rem' }}
                        >
                            Want to export applicant data?
                        </JobluTypography.H1>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 0 }}
                        >
                            Don't let missing candidate
                        </JobluTypography.H4>
                        <JobluTypography.H4
                            semiBold
                            style={{ marginBottom: 20 }}
                        >
                            data hold you back.
                        </JobluTypography.H4>
                        <JobluTypography.H6
                            semiBold
                            style={{ marginBottom: 20, color: '#D7A048' }}
                        >
                            Upgrade to Joblu Premium to export crucial data in
                            and get the insights you need to make smart hiring
                            decisions.
                        </JobluTypography.H6>
                        <Button
                            variant="contained"
                            style={{
                                background: '#D7A048',
                                color: '#fff',
                                padding: '1rem 3rem',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                navigate('/pricing')
                            }}
                        >
                            Get Premium
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <MessageSentModal
                showSentModal={showSentModal}
                handleCloseSentModal={handleCloseSentModal}
            />
            <SuperLikeModal
                showConfirmModal={showConfirmModal}
                handleCloseConfirmModal={handleCloseConfirmModal}
                selectedApplicant={selectedApplicant}
                sendMessage={sendMessage}
                setMessage={setMessage}
                sendMessageError={sendMessageError}
                messageError={messageError}
                message={message}
            />
            <DownloadCSVModal
                showExportModal={showExportModal}
                handleCloseExportModal={handleCloseExportModal}
                jobsData={jobsData}
                jobsDataLoading={jobsDataLoading}
                jobsDataFilter={jobsDataFilter}
                setJobsDataFilter={setJobsDataFilter}
                exportApplicants={exportApplicants}
                defaultLoading={defaultLoading}
                workableLoading={workableLoading}
            />
            <div className="row justify-content-center p-0 mx-0">
                <div className="border-bottom ">
                    <div className="container ">
                        <div className="justify-content-between align-items-center row">
                            <div className="col-6 col-md-6 my-3">
                                <h5 className="text-body fw500 mb-2 ">
                                    Applicants
                                </h5>
                            </div>
                            <div className="col-6 col-md-6 text-end d-flex justify-content-end align-items-center">
                                {width > 640 && (
                                    <>
                                        <div className="me-3">
                                            <JobluTypography.H6
                                                class={`${
                                                    totalMatches < 50
                                                        ? 'text-muted'
                                                        : 'text-danger'
                                                } mb-0`}
                                            >
                                                {totalMatches}{' '}
                                                {User?.subscription?.status !==
                                                'active' && User?.subscription?.status !== 'trialing'
                                                    ? 'out of 50'
                                                    : null}{' '}
                                                Matches
                                            </JobluTypography.H6>
                                        </div>
                                    </>
                                )}

                                <button
                                    className="btn btn-primary text-nowrap "
                                    style={{ fontSize: 14 }}
                                    onClick={() => {
                                        console.log('XXX', User?.subscription?.status)
                                        if (User?.subscription?.status !== 'active' && User?.subscription?.status !== 'trialing') {
                                            setDownloadCSVPremium(true)
                                        } else {
                                            handleOpenExportModal()
                                        }
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-cloud-download"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                        <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                    </svg>{' '}
                                    Download Now
                                </button>
                            </div>
                            <div className="col-md-12 d-block d-md-none">
                                <div className="text-center mb-3">
                                    <JobluTypography.H6
                                        class={`${
                                            totalMatches < 50
                                                ? 'text-muted'
                                                : 'text-danger'
                                        } mb-0`}
                                    >
                                        {totalMatches}{' '}
                                        {User?.subscription?.status !== 'active' && User?.subscription?.status !== 'trialing'
                                            ? 'out of 50'
                                            : null}{' '}
                                        Matches
                                    </JobluTypography.H6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-8 d-flex px-3 px-sm-0 ">
                    <div
                        role="button"
                        onClick={() => {
                            navigate('/jobs')
                        }}
                        className="w-100 p-3"
                        style={{ fontSize: 16 }}
                    >
                        <CustomTooltip title="Go back" placement="left">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-arrow-left"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                />
                            </svg>
                        </CustomTooltip>{' '}
                        My Jobs
                    </div>
                </div>
                <div className="col-12 col-md-8 d-flex my-3 px-3 px-sm-0 justify-content-between align-items-center">
                    <SelectJobPost
                        onChange={(value) => setSelectedJobPost(value)}
                        value={selectedJobPost}
                        placeholder="Select job post to view applicants"
                    />
                    <div>
                        <img
                            src={ArchiveIcon}
                            alt=""
                            className="hand"
                            onClick={() => {
                                navigate(`/archived?job=${selectedJobPost}`, {
                                    state: {
                                        tab: 'Archived',
                                    },
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-8 border mt-2 mb-5 ">
                    <div className="mt-3">
                        <ul
                            className="nav nav-pills flex-nowrap nav-fill my-3 m-0"
                            id="myTab"
                            role="tablist"
                            style={{ overflowY: 'auto' }}
                        >
                            {tabs.map((tab) => (
                                <li
                                    className="nav-item"
                                    role="presentation"
                                    key={tab}
                                >
                                    <button
                                        className={`nav-link px-3 px-md-0 text-nowrap ${
                                            tab === currentTab && 'active'
                                        }`}
                                        data-bs-toggle="tab"
                                        data-bs-target="#active"
                                        type="button"
                                        role="tab"
                                        aria-controls="active"
                                        aria-selected="true"
                                        onClick={() => {
                                            reset_scroll_changes(tab)
                                            setCurrentTab(tab)
                                        }}
                                    >
                                        <span className="me-2">{tab}</span>{' '}
                                        <>
                                            {tab == 'Applied' && appliedCount > 0 && (
                                                <span
                                                    className="px-2 text-primary"
                                                    style={{
                                                        border: '1px solid #002e5d',
                                                        borderRadius: 16,
                                                        backgroundColor: '#fff',
                                                    }}
                                                >
                                                    {appliedCount}
                                                </span>
                                            )}
                                            {tab == 'Shortlisted' && shortlistedCount > 0 && (
                                                <span
                                                    className="px-2 text-primary"
                                                    style={{
                                                        border: '1px solid #002e5d',
                                                        borderRadius: 16,
                                                        backgroundColor: '#fff',
                                                    }}
                                                >
                                                    {shortlistedCount}
                                                </span>
                                            )}
                                            {tab == 'Interviews' && interviewsCount > 0 && (
                                                <span
                                                    className="px-2 text-primary"
                                                    style={{
                                                        border: '1px solid #002e5d',
                                                        borderRadius: 16,
                                                        backgroundColor: '#fff',
                                                    }}
                                                >
                                                    {interviewsCount}
                                                </span>
                                            )}
                                             {tab == 'Hired' && hiredCount > 0 && (
                                                <span
                                                    className="px-2 text-primary"
                                                    style={{
                                                        border: '1px solid #002e5d',
                                                        borderRadius: 16,
                                                        backgroundColor: '#fff',
                                                    }}
                                                >
                                                    {hiredCount}
                                                </span>
                                            )}
                                        </>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="mt-1 mx-1">
                        <Tabs activeKey={currentTab} className="border-none">
                            {tabs.map((tab) => {
                                let data = appliedApplicants
                                if (tab === 'Applied') data = appliedApplicants
                                if (tab === 'Shortlisted')
                                    data = shortListedApplicants
                                if (tab === 'Interviews')
                                    data = interviewApplicants
                                if (tab === 'Archived')
                                    data = archivedApplicants
                                if (tab === 'Hired') data = hiredApplicants
                                return (
                                    <Tab
                                        eventKey={tab}
                                        key={tab}
                                        className="test"
                                    >
                                        <div className="row">
                                            <div className="my-2 col-12 col-sm-6 col-md-4">
                                                <div class="input-group">
                                                    <span
                                                        class="input-group-text"
                                                        id="basic-addon1"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            class="bi bi-search"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Search"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={(e) => {
                                                            handleSearchApplicants(
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <div className="d-flex py-5">
                                                <div
                                                    className="spinner-border text-primary m-auto"
                                                    role="status"
                                                >
                                                    <span class="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <Applicants
                                                    talentSearch={talentSearch}
                                                    applicants={data}
                                                    selectedJob={
                                                        selectedJobPost
                                                    }
                                                    tab={tab}
                                                    getApplicants={
                                                        getApplicants
                                                    }
                                                    isLoading={
                                                        isLoading ||
                                                        pagination_loading
                                                    }
                                                    swipeAction={swipeAction}
                                                    hasMoreItems={false}
                                                    // loadMoreItems={() =>
                                                    //     handleLoadMore(tab)
                                                    // }
                                                    jobTitle={jobTitle}
                                                    isFromExplore={
                                                        tab === 'Applied'
                                                    }
                                                />
                                                {hasMoreData?.[tab] && (
                                                    <div className="d-flex justify-content-center py-3">
                                                        <button
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => {
                                                                handleLoadMore(
                                                                    tab
                                                                )
                                                            }}
                                                        >
                                                            {' '}
                                                            Load more
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div>
                </div>
                <ExploreModal
                    showModal={modalOpen}
                    variant={modalVariant}
                    matchImages={matchImages}
                    setModalVariant={setModalVariant}
                    setShowModal={setModalOpen}
                    applicantName={applicantName}
                    jobTitle={jobTitle}
                />
                <Dialog
                    open={limitReached}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={() => {
                        setLimitReached(false)
                    }}
                    style={{ zIndex: '99999999' }}
                >
                    <Box
                        style={{
                            background: `url('${premiumBg}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            // display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                textAlign: 'right',
                                padding: '0.5rem',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseWhite}
                                width={36}
                                onClick={() => {
                                    setLimitReached(false)
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                padding: '0 3rem 3rem 3rem',
                            }}
                        >
                            <JobluTypography.H2
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                Reached your 50-matching limit?
                            </JobluTypography.H2>
                            <JobluTypography.H4
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                Experience the Joblu Premium and engage with up
                                to 300 outstanding candidates per job posting!
                            </JobluTypography.H4>
                            <JobluTypography.H6
                                semiBold
                                style={{ marginBottom: 20, color: '#D7A048' }}
                            >
                                Revolutionize your hiring process and unlock
                                access to a wider talent pool!
                            </JobluTypography.H6>
                            <Button
                                variant="contained"
                                style={{
                                    background: '#D7A048',
                                    color: '#fff',
                                    padding: '1rem 3rem',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    navigate('/pricing')
                                }}
                            >
                                Go Premium!
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    open={showOutOfLike}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={() => {
                        setShowOutOfLike(false)
                    }}
                    style={{ zIndex: '99999999' }}
                >
                    <Box
                        style={{
                            background: `url('${premiumBg}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            // display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                textAlign: 'right',
                                padding: '0.5rem',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseWhite}
                                width={36}
                                onClick={() => {
                                    setShowOutOfLike(false)
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                padding: '0 3rem 3rem 3rem',
                            }}
                        >
                            <JobluTypography.H2
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                Out of likes? No problem!
                            </JobluTypography.H2>
                            <JobluTypography.H4
                                semiBold
                                style={{ marginBottom: '1rem' }}
                            >
                                You've used up all your Like credits, but the
                                perfect candidate is still out there! With Joblu
                                Premium, not only you will enjoy unlimited job
                                postings but you can also like more talent and
                                get up to 300 matches per job post.
                            </JobluTypography.H4>
                            {/* <JobluTypography.H6
                                semiBold
                                style={{ marginBottom: 20, color: '#D7A048' }}
                            >
                                Discover stress-free hiring for less.
                            </JobluTypography.H6> */}
                            <Button
                                variant="contained"
                                style={{
                                    background: '#D7A048',
                                    color: '#fff',
                                    padding: '1rem 3rem',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    navigate('/pricing')
                                }}
                            >
                                Upgrade and Unlock UNLIMITED Likes
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return { User: state.User }
}

export default connect(mapStateToProps, null)(Matches)
