import jbHorizontal from '../../assets/joblu-logos/jb-horizontal.png'
export const Header = () => {
    const headerlinks = [
        {
            label: 'FOR JOB SEEKERS',
            url: 'https://joblu.io/jobseekers/',
        },
        {
            label: 'FOR COMPANIES',
            url: 'https://joblu.io/companies/',
        },
        {
            label: 'PARTNERSHIPS',
            url: 'https://joblu.io/partnerships/',
        },
        {
            label: 'RESOURCES',
            url: 'https://joblu.io/resources/',
        },
    ]
    return (
        <>
            <div className="row gx-0">
                <div className="col-1"></div>
                <div
                    className="col-12 col-md-10 border-bottom py-3 mx-0"
                    style={{ background: '#fff', maxHeight: '80px' }}
                >
                    <div className="w-100 d-flex justify-content-between justify-content-md-between px-3 px-md-0 align-items-center">
                        <a href="https://joblu.io">
                            <img
                                src={jbHorizontal}
                                alt=""
                                style={{ width: '192.44px' }}
                                className="ms-0 ms-md-5"
                            />
                        </a>
                        <div className="d-block d-md-none">
                            <div class="dropdown">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="white"
                                        className="bi bi-list text-white"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                                        />
                                    </svg>
                                </button>
                                <ul
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    {headerlinks.map((nav) => {
                                        return (
                                            <li>
                                                <span
                                                    class="dropdown-item"
                                                    onClick={() => {
                                                        window.location.href =
                                                            nav.url
                                                    }}
                                                >
                                                    {nav.label}
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="d-none d-sm-flex pe-5">
                            {headerlinks.map((nav) => {
                                return (
                                    <div className="d-flex">
                                        <button
                                            className="btn btn-text my-auto"
                                            style={{
                                                color: '#27323C',
                                                fontWeight: 500,
                                                fontFamily: 'poppins',
                                                lineHeight: '24px',
                                                fontSize: 16,
                                            }}
                                            onClick={() => {
                                                window.location.href = nav.url
                                            }}
                                        >
                                            {nav.label}
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="col-1"></div>
            </div>
        </>
    )
}
