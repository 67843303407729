import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import ReactPlayer from 'react-player'
import fullscreenIcon from '../../../assets/video-player-icons/fullscreen.svg'
import videoVolume from '../../../assets/video-player-icons/video_volume.svg'
import videoVolumeMutedIcon from '../../../assets/video-player-icons/video_volume_muted.png'
export const CustomVideoPlayer = ({
    src,
    width,
    height,
    play,
    onPlayPause,
    videoIndex,
    mWidth,
    handleFullscreen,
    setIsFullscreen,
    isFullscreen
}) => {
    const [muted, setMuted] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)

    const [isPip, setIsPip] = useState(false)

    const playPause = () => {
        if (isPlaying) {
            setIsPlaying(false)
            onPlayPause && onPlayPause(videoIndex, false)
        } else {
            setIsPlaying(true)
            onPlayPause && onPlayPause(videoIndex, true)
        }
    }

    useEffect(() => {
        play ? setIsPlaying(true) : setIsPlaying(false)
    }, [play])

    useEffect(() => {
        if (mWidth < 640) {
            handleFullscreen.enter()
            setIsFullscreen(true)
        }
    }, [mWidth])

    return (
        <div className="position-relative" style={{ zIndex: 99999999999 }}>
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    playPause()
                }}
            >
                <FullScreen
                    handle={handleFullscreen}
                    onChange={(e) => setIsFullscreen(e)}
                >
                    <ReactPlayer
                        url={src}
                        width={width || 370}
                        height={isFullscreen ? '100%' : height || 700}
                        muted={muted}
                        playing={isPlaying}
                        loop={true}
                        pip={isPip}
                        onDisablePIP={() => setIsPip(false)}
                        // stopOnUnmount={true}
                    />
                </FullScreen>
            </div>
            <div
                className="position-absolute d-flex justify-content-end w-100 px-2"
                style={{ bottom: 10, zIndex: 999999 }}
            >
                <div
                    style={{
                        width: 40,
                        height: 40,
                        background: 'hsla(0, 100%, 0%, 0.5)',
                    }}
                    className="d-flex me-1"
                    role="button"
                    onClick={(e) => {
                        // e.preventDefault()
                        // e.stopPropagation()
                        setMuted(!muted)
                    }}
                >
                    {!muted ? (
                        <img src={videoVolume} alt="" className="m-auto" />
                    ) : (
                        <img
                            src={videoVolumeMutedIcon}
                            alt=""
                            className="m-auto"
                        />
                    )}
                </div>
                <div
                    style={{
                        width: 40,
                        height: 40,
                        background: 'hsla(0, 100%, 0%, 0.5)',
                    }}
                    className="d-flex me-1"
                    role="button"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleFullscreen.enter()
                    }}
                >
                    <img
                        src={fullscreenIcon}
                        alt=""
                        className="m-auto"
                        style={{ width: 18, height: 18 }}
                    />
                </div>
                {!isPip && (
                    <div
                        style={{
                            width: 40,
                            height: 40,
                            background: 'hsla(0, 100%, 0%, 0.5)',
                        }}
                        className="d-flex me-1"
                        role="button"
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsPip(true)
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="white"
                            className="bi bi-pip m-auto"
                            viewBox="0 0 16 16"
                        >
                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                            <path d="M8 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-3z" />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    )
}
function JobluVideoPlayer(props) {
    const {
        src,
        width,
        height,
        play,
        setPlayVideo,
        fromCultureFiles,
        vidHeight,
        mWidth,
        culture,
    } = props

    const [isFullscreen, setIsFullscreen] = useState(false)
    const handleFullscreen = useFullScreenHandle()
    if (!src) return null
    return (
        <>
            <div onClick={(e) => e.stopPropagation()}>
                <Modal
                    show={play}
                    centered
                    contentClassName={`${
                        fromCultureFiles ? 'bg-dark' : 'bg-transparent'
                    } border-0`}
                    onHide={(e) => {
                        setPlayVideo(false)
                    }}
                    // style={{ zIndex: 1 }}
                >
                    <Modal.Body
                        bsPrefix="bg-none"
                        className={`${
                            fromCultureFiles ? 'bg-dark' : 'bg-none '
                        }d-flex justify-content-center p-0`}
                    >
                        <div className="position-relative">
                            <div
                                className="position-absolute d-flex"
                                style={{
                                    borderRadius: '50%',
                                    width: 50,
                                    height: 50,
                                    right: -23,
                                    top: -23,
                                    zIndex: 999,
                                    background: 'hsla(0, 100%, 0%, 0.5)',
                                }}
                                role="button"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setPlayVideo(false)
                                }}
                            >
                                <div className="m-auto">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        fill="white"
                                        className="bi bi-x-lg"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="player">
                                <CustomVideoPlayer
                                    src={src}
                                    //put resizeMode cover like style
                                    width={
                                        culture
                                            ? mWidth < 640
                                                ? '100%'
                                                : isFullscreen? '100%': '980px'
                                            : '100%'
                                    }
                                    height={
                                        culture
                                            ? mWidth < 640
                                                ? 'auto '
                                                : isFullscreen? '100%' : '550px '
                                            : height || 700
                                    }
                                    play
                                    mWidth={mWidth}
                                    setIsFullscreen={setIsFullscreen}
                                    isFullscreen={isFullscreen}
                                    handleFullscreen={handleFullscreen}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
export default JobluVideoPlayer
